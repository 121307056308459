import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject, interval, Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray, ValidationErrors } from '@angular/forms';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import PopOverTools from 'appshared/popover-tools';

import { MyQuestionsService } from '../my-questions.service';
import { AuthService } from 'client/app/services/auth.service';
import { UsersService } from '../../users/users.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-my-questions-main',
	templateUrl: './my-questions-main.component.html',
	styleUrls: ['./my-questions-main.component.scss']
})
export class MyQuestionsMainComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	textTools = TextTools;
	miscTools = MiscTools;
	popOverTools = PopOverTools;

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;
	storageKey: string = 'cp-ask-zixi';

	now = new Date();

	debug: boolean = true;

	// 'standard' list stuff
	questions: Models.ZixiQuestion[];
	viewableQuestions: Models.ZixiQuestion[];
	questionsToShow: Models.ZixiQuestion[];

	loading = true;
	checking = false;

	// other stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	questionForm: UntypedFormGroup;

	refreshTimer: any = null;
	refreshRefreshTimeSecs = 5;

	numAnswers: number = 0;

	numRefreshes: number = 0;
	maxRefreshes: number = 48;

	constructor(
		private myQuestionsService: MyQuestionsService,
		private uiAlertsService: UiAlertsService,
		private usersService: UsersService,
		private authService: AuthService,
	) { }

	// ************************************************************************************
	ngOnInit(): void {
		this.loading = true;

		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.authUser = authUser;
			this.loadData();
		});
	} // ngOnInit

	// ************************************************************************************
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
		if (this.refreshTimer) {
			clearTimeout(this.refreshTimer);
			this.refreshTimer = null;
		} // if
	} // ngOnDestroy

	// ************************************************************************************
	async loadData(skipLoad: boolean = false) {
		this.loading = true;

		if (!skipLoad) this.questions = await this.myQuestionsService.getAll();

		const viewableQuestions: Models.ZixiQuestion[] = [];
		for (const q of this.questions) {
			if (q.response && q.response.textResponse && q.response.textResponse !== '')
				q['__answerToShow'] = q.response.textResponse;
			else
				q['__answerToShow'] = '';

			let sources: string[] = [];
			if (q.response && q.response.sources) {
				for (const source of q.response.sources)
					if (source.title && q['__answerToShow'].includes(source.title))
						sources.push(source.title);
			} // if

			// sources = MiscTools.removeDuplicates(sources);
			q['__numSources'] = sources.length;
			q['__sources'] = sources;

			// for (const source of sources) {
			// 	let url: string = '';
			// 	url = 'https://zixidocumentation.atlassian.net/';
			// 	if (url !== '')
			// 		q['__answerToShow'] = (q['__answerToShow'] as string).replace(source, '<a href="' + url + '" target="_blank">' + source + '</a>');
			// } // for

			if (q.hidden === 0) {
				viewableQuestions.push(q);
			} // if
		} // for
		this.viewableQuestions = viewableQuestions;

		this.numAnswers = this.countAnswers(this.questions);

		if (this.debug) console.log('numAnswers=' + this.numAnswers + ' / ' + this.questions.length);

		if (!this.questionForm) {
			this.questionForm = new UntypedFormGroup({
				question: new UntypedFormControl(null, [Validators.required])
			});
		} // if

		await MiscTools.delay(500);

		this.focusOn('question');

		this.pagingSearchBar.setupPaging(this.viewableQuestions, [], this.storageKey, 'added_on', 'desc');

		this.loading = false;
	} // loadData

	// ************************************************************************************
	async checkForAnswers() {
		this.numRefreshes++;
		if (this.numRefreshes > this.maxRefreshes) {
			if (this.refreshTimer) {
				clearTimeout(this.refreshTimer);
				this.refreshTimer = null;
			} // if
			return;
		}
		this.checking = true;
		this.questions = await this.myQuestionsService.getAll();
		const newNumAnswers: number = this.countAnswers(this.questions);

		if (this.debug) console.log('numAnswers=' + this.numAnswers + ' / ' + this.questions.length + ' newNumAnswers=' + newNumAnswers);

		if (newNumAnswers >= this.questions.length) {
			this.uiAlertsService.clearMsgByCode('got_it')
			if (this.refreshTimer) {
				clearTimeout(this.refreshTimer);
				this.refreshTimer = null;
			} // if
			this.numRefreshes = 0;
			await this.loadData(true);
		} // if


		this.checking = false;
	} // checkForAnswers

	// ************************************************************************************
	countAnswers(questions: Models.ZixiQuestion[]) {
		let numAnswers = 0;
		for (const q of questions)
			if (q.response && q.response.textResponse && q.response.textResponse !== '')
				numAnswers++;
		return numAnswers;
	} // countAnswers

	// ************************************************************************************
	async askQuestion() {
		if (this.loading) return;

		// get from text box
		let question: string = this.questionForm.value.question;
		if (!question || question.trim() === '') return;

		const idx: number = MiscTools.findIndexGeneric(this.viewableQuestions, 'question', question.trim());
		if (idx !== -1) {
			this.uiAlertsService.addMsg('You\'ve already asked that question.',
				'warning', '', true, AppConstants.standardMessageAutoCloseTimeSecs);
			return;
		} // if

		this.loading = true;

		const newQ: Models.ZixiQuestion = new Models.ZixiQuestion(0, 0, '', question.trim());

		// update num answers before submitting question
		this.numAnswers = this.countAnswers(this.questions);
		if (this.debug) console.log('numAnswers=' + this.numAnswers + ' / ' + this.questions.length);

		// send question to the server
		await this.myQuestionsService.addQuestion(question);

		// set page to 1

		this.questionForm = new UntypedFormGroup({
			question: new UntypedFormControl(null, [Validators.required])
		});

		// loadData
		await MiscTools.delay(500);

		this.uiAlertsService.addMsg('Your question has been submitted.  We should have an answer for you shortly.',
			'info', 'got_it', true, AppConstants.standardMessageAutoCloseTimeSecs);
		await this.loadData();

		// rebuild form
		this.questionForm = new UntypedFormGroup({
			question: new UntypedFormControl(null, [Validators.required])
		});

		this.refreshTimer = setInterval(this.checkForAnswers.bind(this), this.refreshRefreshTimeSecs * 1000);

		this.loading = false;
	} // askQuestion

	// ************************************************************************************
	async hideQuestion(id: number) {
		if (!confirm('Are you sure that you\'re done reading this answer?')) return;
		const idx: number = MiscTools.findIndex(this.viewableQuestions, id);
		if (idx !== -1) {
			this.viewableQuestions.splice(idx, 1);
			await this.myQuestionsService.setHidden(id, 1);
			this.pagingSearchBar.setupPaging(this.viewableQuestions, [], this.storageKey, 'added_on', 'desc');
		} // if
	} // hideQuestion

	// ************************************************************************************
	async rateQuestion(id: number, helpful: number) {
		const idx: number = MiscTools.findIndex(this.viewableQuestions, id);
		if (idx !== -1) {
			this.viewableQuestions[idx].helpful = helpful;
			await this.myQuestionsService.setHelpful(id, helpful);
		} // if
	} // rateQuestion

	// ************************************************************************************
	focusOn(id: string) {
		if (document.getElementById(id)) {
			document.getElementById(id).focus();
		} // if
	} // focusOn

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.ZixiQuestion[] = [];
		for (const item of this.viewableQuestions) {
			let match: boolean = true;
			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.question.toLowerCase().includes(w)
						|| (item.response && item.response.textResponse && item.response.textResponse.toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.questionsToShow = itemsToShow;
				// this.displayOptions = displayOptions;
			}
		}
	}


}
