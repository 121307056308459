import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { PlatformsRoutingModule } from './platforms-routing.module';
import { PlatformComponent } from './platform/platform.component';
import { PlatformListComponent } from './platform-list/platform-list.component';
import { PlatformFormComponent } from './platform-form/platform-form.component';


@NgModule({
	declarations: [PlatformComponent, PlatformListComponent, PlatformFormComponent],
	imports: [
		PlatformsRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		SharedModule,
		FontAwesome
	]
})
export class PlatformsModule { }
