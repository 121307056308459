<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="ac.sectionIcons.builds" class="cp-admin-nav-item me-1" size="sm"></fa-icon>
				Builds
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div *ngIf="canAdd" class="btn-group me-2 dropstart">
				<button class="btn btn-outline-primary dropdown-toggle" type="button" id="newBuildButton" data-bs-toggle="dropdown"
					aria-expanded="false">
					<fa-icon [icon]="['fas', 'plus']" size="sm" ngbTooltip="New Build" placement="start"></fa-icon>
					<!-- <span class="cp-action-button-text">New Build</span> -->
				</button>
				<div class="dropdown-menu cp-scrollable-menu" aria-labelledby="newBuildButton">
					<a *ngFor="let item of productsForNew" class="dropdown-item" href="#" [routerLink]="['new', item.id]">{{
						item.name }}</a>
				</div>
			</div>

			<button *ngIf="canAdd" type="button" class="btn btn-outline-primary me-2" routerLink="auto-build">
				<fa-icon [icon]="['fas', 'robot']" size="sm"></fa-icon>
				<span class="cp-action-button-text">Auto-Build</span>
			</button>

			<button type="button" class="btn btn-outline-secondary me-2" (click)="toggleFilters()">
				<fa-icon icon="filter" size="sm" ngbTooltip="Filter" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Filter</span> -->
			</button>

			<button type="button" class="btn btn-outline-secondary" (click)="refresh()">
				<fa-icon icon="sync-alt" size="sm" [spin]="refreshing" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<div class="list-wrapper">
	<form [formGroup]="theForm" *ngIf="showFilters">
		<div class="row ps-2 pe-2">
			<div class="cp-filter-block-2">
				<ng-select [items]="enabledChoices" bindValue="value" bindLabel="label" id="enabledFilters" formControlName="enabledFilters"
					[multiple]="true" (change)="onFormChange()" placeholder="Enabled/Disabled">
				</ng-select>
			</div>
			<div class="cp-filter-block-2">
				<ng-select [items]="visibiltyChoices" bindValue="value" bindLabel="label" id="visibiltyFilters"
					formControlName="visibiltyFilters" [multiple]="true" (change)="onFormChange()" placeholder="Private/Public(GA)">
				</ng-select>
			</div>

			<div class="cp-filter-block-2">
				<ng-select [items]="retiredChoices" bindValue="value" bindLabel="label" id="retiredFilters" formControlName="retiredFilters"
					[multiple]="true" (change)="onFormChange()" placeholder="Retired/Archived">
				</ng-select>
			</div>

			<div class="cp-filter-block-3">
				<ng-select [items]="productChoices" bindValue="value" bindLabel="label" id="productFilters" formControlName="productFilters"
					[multiple]="true" (change)="onFormChange()" placeholder="Product">
				</ng-select>
			</div>

			<div class="cp-filter-block-3">
				<ng-select [items]="platformChoices" bindValue="value" bindLabel="label" id="platformFilters"
					formControlName="platformFilters" [multiple]="true" (change)="onFormChange()" placeholder="Platform">
				</ng-select>
			</div>
		</div>
	</form>

	<div *ngIf="loading || refreshing" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<app-builds-table #buildsTable1></app-builds-table>

</div>