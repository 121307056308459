import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';

import { BundlesService } from '../bundles.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { UsersService } from '../../users/users.service';
import { UserGroupsService } from '../../users/user-groups.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-bundle-form',
	templateUrl: './bundle-form.component.html',
	styleUrls: ['./bundle-form.component.scss']
})
export class BundleFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;
	loading = true;

	id: number;
	bundle: Models.Bundle;
	errors: string[] = [];
	editMode = false;
	theForm: UntypedFormGroup;
	saving = false;

	btypeSelections: any[] = [];
	userSelections: any[] = [];
	userGroupSelections: any[] = [];
	orgSelections: any[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private organizationsService: OrganizationsService,
		private usersService: UsersService,
		private userGroupsService: UserGroupsService,
		private bundlesService: BundlesService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.loading = false;
		});
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			if (authUser) {
				this.authUser = authUser;
				// this.canManage = (authUser && ValidationTools.checkAccess(authUser, 'manage-license-key-setup'));
				this.initForm();
			} // if
		});

	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async initForm() {
		if (this.id && this.id !== 0) {
			this.bundle = await this.bundlesService.getOne(this.id);
			if (!this.bundle || this.bundle == null || this.bundle.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
				return;
			}
			this.editMode = true;
		} else {
			this.bundle = new Models.Bundle(0, '', 'sharing', '', '');
			this.editMode = false;
		}

		this.btypeSelections = [];
		for (const btype of AppConstants.bundleTypes)
			if (ValidationTools.checkAccess(this.authUser, 'create-' + btype.value + '-bundle'))
				this.btypeSelections.push(btype);

		const allUsers = this.usersService.getAll();
		const allOrgs = this.organizationsService.getAll();
		const allUserGroups = this.userGroupsService.getAll();

		// find groups that this user is a member of...
		this.userGroupSelections = [];
		const idx = MiscTools.findIndex(allUsers, this.authUser.id);
		if (idx !== -1 && allUsers[idx].group_ids && allUsers[idx].group_ids.length > 0) {
			for (const userGroup of allUserGroups)
				if (allUsers[idx].group_ids.includes(userGroup.id))
					this.userGroupSelections.push({ id: userGroup.id, name: userGroup.name });
		}

		this.userSelections = [];
		for (const user of allUsers) {
			let nameToShow = user.name + ' (' + user.email + ')';
			this.userSelections.push({ id: user.id, name: nameToShow });
		}

		this.orgSelections = [];
		for (const org of allOrgs) {
			this.orgSelections.push({ id: org.id, name: org.name });
		} // for

		if (!this.bundle.staff_id || this.bundle.staff_id === 0) this.bundle.staff_id = null;
		if (!this.bundle.org_id || this.bundle.org_id === 0) this.bundle.org_id = null;

		this.theForm = new UntypedFormGroup({
			btype: new UntypedFormControl(this.bundle.btype, [Validators.required]),
			information: new UntypedFormControl(this.bundle.information),
			staff_id: new UntypedFormControl(this.bundle.staff_id),
			org_id: new UntypedFormControl(this.bundle.org_id),
			user_ids: new UntypedFormControl(this.bundle.user_ids)
		});
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		this.bundle.btype = this.theForm.value.btype;
		this.bundle.information = this.theForm.value.information;
		this.bundle.staff_id = +this.theForm.value.staff_id;
		this.bundle.org_id = +this.theForm.value.org_id;
		this.bundle.user_ids = this.theForm.value.user_ids;

		if (this.bundle.btype.startsWith('group') && (isNaN(this.bundle.staff_id) || this.bundle.staff_id === 0))
			this.errors.push('You must choose a Group.');

		if ((isNaN(this.bundle.org_id) || this.bundle.org_id === 0)
			&& (!this.bundle.user_ids || this.bundle.user_ids.length === 0))
			this.errors.push('You must choose an Organization or at least one User.');

		if (!isNaN(this.bundle.org_id) && this.bundle.org_id !== 0
			&& this.bundle.user_ids && this.bundle.user_ids.length !== 0)
			this.errors.push('You can choose an Organization or Specific Users, not both.');

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		try {
			let retBundle: Models.Bundle = null;
			if (this.editMode)
				retBundle = await this.bundlesService.updateOne(this.bundle);
			else
				retBundle = await this.bundlesService.addOne(this.bundle);

			if (retBundle) {
				this.router.navigate([AppConstants.urls.bundles + '/' + retBundle.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}

		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	get btype() { return this.theForm.get('btype'); }

}
