<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.products" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" routerLink="..">Products</a>
			</li>

			<li *ngIf="product && !loading" class="breadcrumb-item">
				{{ product.name }}
			</li>

			<li class="breadcrumb-item active" aria-current="page">
				Notify
			</li>
		</ol>
	</nav>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				Message
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-users-tab"
				aria-controls="nav-users" data-bs-target="#nav-users">
				Users
				<span *ngIf="!loading" class="cp-tab-badge">
					{{ numSubscribed | number }} / {{ users.length | number }}</span>
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<form *ngIf="!loading && theForm" [formGroup]="theForm" class="tab-form">

		<div class="tab-content" id="nav-tabContent">
			<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">
				<div class="cp-general-width-limit">

					<div class="form-group">
						<label for="name">Subject</label>
						<input type="text" id="subject" formControlName="subject" class="form-control" />
					</div>

					<div class="form-group">
						<label for="message">Message*</label>
						<textarea id="message" formControlName="message" class="form-control" rows="4"></textarea>
					</div>

					<!-- <div class="form-group mb-0" *ngIf="!saving">
						<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
							<fa-icon [icon]="ac.sectionIcons.mynotifications"></fa-icon> Send
						</button>
						<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
							<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
						</button>
					</div> -->
				</div>
			</div>

			<div class="tab-pane" id="nav-users" role="tabpanel" aria-labelledby="nav-users-tab">

				<div *ngIf="!loading && users.length === 0" class="cp-light-message">
					No Users have access to this product.
				</div>

				<div *ngIf="!loading && users.length !== 0" class="cp-light-message">
					The following users have access to this product and can potentially receive
					notifications. The following table shows the users and whether or not
					the user will receive a notification.
				</div>

				<app-notification-users-table #notificationUsersTable1></app-notification-users-table>

			</div>
		</div>
	</form>
</div>

<div class="cp-form-padding"></div>

<div *ngIf="!loading && theForm" class="cp-form-buttons">
	<div class="cp-form-buttons-inner">
		<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
			<fa-icon [icon]="ac.sectionIcons.mynotifications"></fa-icon> Send
		</button>
		<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
			<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
		</button>

		<span class="gj-75 ps-2">* Required</span>
	</div>
</div>