import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class SystemMessagesService {
	messages: Observable<Models.SystemMessage[]>;
	loaded = false;
	private debug = false;
	private _messages: ReplaySubject<Models.SystemMessage[]>;
	private dataStore: {
		messages: Models.SystemMessage[];
	};

	constructor(private http: HttpClient) {
		this.dataStore = {
			messages: []
		};
		this._messages = new ReplaySubject(1) as ReplaySubject<Models.SystemMessage[]>;
		this.messages = this._messages.asObservable();
	}

	// add/update an item in the datastore
	private updateStore(newMessage: Models.SystemMessage): void {
		const idx = this.dataStore.messages.findIndex(message => message.id === newMessage.id);
		if (idx === -1) {
			this.dataStore.messages.push(newMessage);
			return;
		} else {
			this.dataStore.messages[idx] = newMessage;
		}
	}

	// get a fresh copy of the entire set
	refreshAll(): Observable<Models.SystemMessage[]> {
		const messages$ = this.http.get<Models.SystemMessage[]>(AppConstants.apiUrl + AppConstants.apiUrls.systemmessages).pipe(share());
		messages$.subscribe(
			data => {
				const messages: Models.SystemMessage[] = data;
				// remove ones from the store that aren't in the response (they've been deleted)
				this.dataStore.messages.forEach((existingMessage, existingIndex) => {
					const newIndex = messages.findIndex(message => message.id === existingMessage.id);
					if (newIndex === -1) this.dataStore.messages.splice(existingIndex, 1);
				});
				// add/update all the ones that came back
				messages.forEach(message => this.updateStore(message));
				this._messages.next(Object.assign({}, this.dataStore).messages);
				this.loaded = true;
			},
			error => { /* console.log(error) */ }
		);
		return messages$;
	}

	// get a fresh copy of a single item
	refreshOne(id: number): Observable<Models.SystemMessage> {
		const message$ = this.http.get<Models.SystemMessage>(AppConstants.apiUrl + AppConstants.apiUrls.systemmessages + '/' + id).pipe(share());
		message$.subscribe(
			data => {
				const message: Models.SystemMessage = data;
				// add/update the one that came back
				this.updateStore(message);
				this._messages.next(Object.assign({}, this.dataStore).messages);
			},
			error => { /* console.log(error) */ }
		);
		return message$;
	}

	// return the whole list
	getAll(): Models.SystemMessage[] {
		return this.dataStore.messages.slice();
	}

	// grab a single item from the datastore
	getOne(id: number): Models.SystemMessage {
		return this.dataStore.messages.find(message => message.id === id);
	}

	// call back-end to add an item
	async addOne(message: Models.SystemMessage) {
		try {
			const result = await this.http.post<Models.SystemMessage>(AppConstants.apiUrl + AppConstants.apiUrls.systemmessages, message).toPromise();
			const returnedMessage: Models.SystemMessage = result;
			this.updateStore(returnedMessage);
			this._messages.next(Object.assign({}, this.dataStore).messages);
			return returnedMessage;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update an item
	async updateOne(message: Models.SystemMessage) {
		try {
			const result = await this.http.put<Models.SystemMessage>(AppConstants.apiUrl + AppConstants.apiUrls.systemmessages + '/' + message.id, message).toPromise();
			const returnedMessage: Models.SystemMessage = result;
			this.updateStore(returnedMessage);
			this._messages.next(Object.assign({}, this.dataStore).messages);
			return returnedMessage;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to delete an item
	async deleteOne(id: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl + AppConstants.apiUrls.systemmessages + '/' + id).toPromise();
			const idx = this.dataStore.messages.findIndex(message => message.id === id);
			if (idx !== -1) this.dataStore.messages.splice(idx, 1);
			this._messages.next(Object.assign({}, this.dataStore).messages);
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// ********************************************************
	// other non-standard methods
	// ********************************************************

	// call back-end to enable/disable
	async toggleEnabled(id: number) {
		try {
			const result = await this.http.put<Models.SystemMessage>(AppConstants.apiUrl + AppConstants.apiUrls.systemmessages + '/' + id + '/toggle-enabled', {}).toPromise();
			const returnedMessage: Models.SystemMessage = result;
			this.updateStore(returnedMessage);
			this._messages.next(Object.assign({}, this.dataStore).messages);
			return returnedMessage;
		} catch (error) {
		}
	}

	// call back-end to update an item
	async sendNotification(subject: string, message: string, userIds: number[] = []) {
		try {
			const result = await this.http.put(AppConstants.apiUrl
				+ AppConstants.apiUrls.systemmessages + '/notify', { subject, message, userIds, async: 'yes' }).toPromise();
			return;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

}
