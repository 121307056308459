<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.licensingadmin" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Licensing
			</li>
			<li class="breadcrumb-item active" aria-current="page">Templates</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div *ngIf="canManageTemplates" class="btn-group me-1 dropstart">
				<button class="btn btn-outline-primary dropdown-toggle" type="button" id="newTemplateButton" data-bs-toggle="dropdown"
					aria-haspopup="true" aria-expanded="false">
					<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
					<span class="cp-action-button-text">New Template</span>
				</button>
				<div class="dropdown-menu cp-scrollable-menu" aria-labelledby="newTemplateButton">
					<a *ngFor="let item of products" class="dropdown-item" href="#" [routerLink]="['new', item.name]">
						{{ item.label }} ({{ item.name }})
					</a>
				</div>
			</div>

			<button type="button" class="btn btn-outline-secondary" (click)="getData(true)">
				<fa-icon icon="sync-alt" size="sm" [spin]="loading" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<div class="list-wrapper">
	<div *ngIf="loading" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

	<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
		<thead>
			<tr>
				<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-center cp-pre-text cp-pointer"
					[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
					[hidden]="!fieldsToShow.includes(cd.field)">
					<span *ngIf="cd.label" [innerHTML]="cd.label"
						[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
						[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
					<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
				</th>
			</tr>
		</thead>

		<tbody *ngIf="itemsToShow && !loading">
			<tr *ngFor="let item of itemsToShow">
				<td class="cp-icon-cell">
					<fa-icon *ngIf="!item.settings.editOnly" [icon]="['fas', 'plus']" size="sm"
						ngbTooltip="Can be used to add and/or edit keys" placement="bottom-start">
					</fa-icon>
					<fa-icon *ngIf="item.settings.editOnly" [icon]="['fas', 'pencil']" size="sm"
						ngbTooltip="Cannot be used to create new keys" placement="bottom-start">
					</fa-icon>
				</td>
				<td class="text-center">
					{{ item.num_keys | number }}
				</td>
				<td class="cp-icon-cell">
					<fa-icon *ngIf="userTemplateIDs.includes(item.id)" [icon]="['fas', 'check']" size="sm"
						ngbTooltip="You can use this template" placement="bottom-start">
					</fa-icon>
				</td>
				<td class="text-nowrap">
					<a [routerLink]="[item.id]" [ngbPopover]="templatePopupContent" popoverClass="cp-popover-std"
						triggers="mouseenter:mouseleave" container="body">
						{{ item.name }}
					</a>
				</td>
				<!-- <td>{{ template.description }}</td> -->
				<td class="text-nowrap">{{ niceKeyProduct(item.product) }}</td>
				<td class="text-center">
					<span [ngbTooltip]="item['__types_full']">
						{{ item['__types'] }}
					</span>
				</td>
				<td class="text-nowrap">
					{{ item.added_on | date: ac.tableDateFmt }}
				</td>
				<td class="text-nowrap">
					{{ item.edited_on | date: ac.tableDateFmt }}
				</td>

				<ng-template #templatePopupContent>
					<div class="cp-popover-banner">{{ item.name }}</div>
					<div class="cp-pre-text">{{
						popOverTools.getKeyTemplatePopoverLines(item).join('\n')}}</div>
				</ng-template>


			</tr>
		</tbody>
	</table>

</div>