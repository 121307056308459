<nav class="navbar navbar-dark bg-dark" *ngIf="loggedIn">
	<a class="navbar-brand" href="javascript:void(0)">
		<button type="button" class="btn btn-link btn-nav-toggle d-lg-none" (click)="toggleNav()">
			<fa-icon class="expand-icon" icon="bars" size="lg"></fa-icon>
		</button>
		<img width="75" alt="Zixi logo" src="assets/images/zixi-white.png" />
	</a>
	<span class="app-title d-none d-xs-inline-block">&nbsp;{{ ac.appName }}</span>

	<button type="button" class="ms-auto btn btn-link navbar-text d-inline-block" [routerLink]="['/' + ac.urls.mysettings]">
		<fa-icon [icon]="ac.userRoleIcons[authUser.role]"></fa-icon>
		<span class="cp-action-button-text">{{ authUser && authUser.name }}</span>
	</button>
	<button type="button" class="btn btn-outline-navbar logout-button ms-2 ms-xs-0" (click)="logout()">
		<fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon>
		<span class="d-none d-lg-inline-block ms-1">Logout</span>
	</button>
</nav>