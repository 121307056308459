import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share, map, tap } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class BundlesService {
	debug = false;

	constructor(private http: HttpClient) { }

	async getAll(): Promise<Models.Bundle[]> {
		const result = await this.http.get<Models.Bundle[]>(AppConstants.apiUrl
			+ AppConstants.apiUrls.bundles).toPromise();
		const arr: Models.Bundle[] = result;
		return arr;
	}

	async getOne(id: number): Promise<Models.Bundle> {
		const result = await this.http.get<Models.Bundle>(AppConstants.apiUrl
			+ AppConstants.apiUrls.bundles + '/' + id).toPromise();
		const obj: Models.Bundle = result;
		return obj;
	}

	// async updateBundleFiles(id: number) {
	// 	this.needsRefresh = true;
	// 	const result = await this.http.put<any>(AppConstants.apiUrl
	// 		+ AppConstants.apiUrls.bundles + '/' + id + '/files', {}).toPromise();
	// 	const obj: any = result;
	// 	return obj;
	// }

	// call back-end to add an item
	async addOne(bundle: Models.Bundle) {
		try {
			const result = await this.http.post<Models.Bundle>(
				AppConstants.apiUrl + AppConstants.apiUrls.bundles, bundle).toPromise();
			const returnedBundle: Models.Bundle = result;
			return returnedBundle;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update an item
	async updateOne(bundle: Models.Bundle) {
		try {
			const result = await this.http.put<Models.Bundle>(
				AppConstants.apiUrl + AppConstants.apiUrls.bundles + '/' + bundle.id, bundle).toPromise();
			const returnedBundle: Models.Bundle = result;
			return returnedBundle;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to delete an item
	async deleteOne(id: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl + AppConstants.apiUrls.bundles + '/' + id).toPromise();

			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}





}
