import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share, map, tap } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class MyPackagesService {
	private myPackages: Models.Package[] = null;
	private availablePackages: Models.Package[] = null;

	constructor(private http: HttpClient) { }

	wipe() {
		this.myPackages = null;
		this.availablePackages = null;
	}

	async getMyPackages(forceRefresh = false) {
		if (!this.myPackages || forceRefresh)
			this.myPackages = await this.fetchMyPackages();
		return this.myPackages.slice();
	}

	async getAvailablePackages(forceRefresh = false) {
		if (!this.availablePackages || forceRefresh)
			this.availablePackages = await this.fetchAvailablePackages();
		return this.availablePackages.slice();
	}

	async fetchAvailablePackages() {
		try {
			const result = await this.http.get<Models.Package[]>(AppConstants.apiUrl + AppConstants.apiUrls.mysettings
				+ '/available-packages').toPromise();
			const arr: Models.Package[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchMyPackages() {
		try {
			const result = await this.http.get<Models.Package[]>(AppConstants.apiUrl + AppConstants.apiUrls.mysettings
				+ '/user-packages').toPromise();
			const arr: Models.Package[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async subscribeToPackages(ids: number[], skipLoad: boolean = false) {
		try {
			const result = await this.http.post<boolean>(AppConstants.apiUrl + AppConstants.apiUrls.mysettings
				+ '/packages', { ids: ids }).toPromise();

			if (!skipLoad) {
				this.myPackages = await this.fetchMyPackages();
				this.availablePackages = await this.fetchAvailablePackages();
			}

			return result;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async unsubscribeFromPackages(ids: number[], skipLoad: boolean = false) {
		try {
			const result = await this.http.delete<boolean>(AppConstants.apiUrl + AppConstants.apiUrls.mysettings
				+ '/packages/' + encodeURIComponent(ids.join(','))).toPromise();

			if (!skipLoad) {
				this.myPackages = await this.fetchMyPackages();
				this.availablePackages = await this.fetchAvailablePackages();
			} // if

			return result;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}


}
