<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.zencustomers" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">ZEN Master Sites</a>
			</li>
			<li *ngIf="!!zenCustomer" class="breadcrumb-item active" aria-current="page">
				{{ zenCustomer.name }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group" *ngIf="!!zenCustomer">
				<button *ngIf="canEdit" type="button" class="btn btn-outline-primary" [routerLink]="['edit']"
					ngbTooltip="Edit - Organization, Type, Marketplace" placement="bottom-end">
					<fa-icon icon="pencil" size="sm"></fa-icon>
				</button>

				<button *ngIf="canAdmin" type="button" class="btn btn-outline-primary" [routerLink]="['admin']"
					ngbTooltip="Edit - Administation" placement="bottom-end">
					<fa-icon icon="cog" size="sm"></fa-icon>
				</button>

				<button *ngIf="canConfigureMarketplace" type="button" class="btn btn-outline-primary" [routerLink]="['marketplace']"
					ngbTooltip="Edit - Set/Unset Marketplace Reporting" placement="bottom-end">
					<fa-icon [icon]="ac.sectionIcons.marketplace" size="sm"></fa-icon>
				</button>

				<button *ngIf="canSuspend" type="button" class="btn btn-outline-warning" (click)="prepDisable()"
					ngbTooltip="Disable/Suspend (Shut Down)" placement="bottom-end">
					<fa-icon [icon]="['fas', 'ban']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Disable</span> -->
				</button>

				<button *ngIf="canQuickResume" type="button" class="btn btn-outline-success" data-bs-toggle="modal"
					data-bs-target="#enableModal" ngbTooltip="Enable/Start Up - no changes" placement="bottom-end">
					<fa-icon [icon]="['fas', 'bolt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Enable</span> -->
				</button>

				<!-- <button *ngIf="canFullResume" type="button" class="btn btn-outline-success" [routerLink]="['enable']"
					ngbTooltip="Enable/Start Up - update instance type" placement="bottom-end">
					<fa-icon [icon]="['fas', 'power-off']" size="sm"></fa-icon>
				</button> -->

				<button *ngIf="canDelete" type="button" class="btn btn-outline-danger" (click)="prepDelete()" ngbTooltip="Delete"
					placement="bottom-end">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>

				<button *ngIf="canImpersonate" type="button" class="btn btn-outline-primary" (click)="manageClick()"
					ngbTooltip="Impersonate an Admin Level User on this ZEN Master Site (requires support access and that you're already logged into ZEN)"
					placement="bottom-end">
					<fa-icon icon="external-link-square-alt" size="sm"></fa-icon>
					<span class="cp-action-button-text">Manage</span>
				</button>

				<button type="button" class="btn btn-outline-primary" (click)="loginClick()"
					ngbTooltip="Go to this ZEN Master Site's Login Page" placement="bottom-end">
					<fa-icon icon="external-link-square-alt" size="sm"></fa-icon>
					<span class="cp-action-button-text">Login</span>
				</button>

			</div>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-users-tab"
				aria-controls="nav-users" data-bs-target="#nav-users">
				<fa-icon [icon]="ac.sectionIcons.zencustomers" size="sm"></fa-icon>
				Users
				<span *ngIf="!loading" class="cp-tab-badge">
					{{ zenUsers.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-downloads-builds-tab" aria-controls="nav-downloads-builds" data-bs-target="#nav-downloads-builds">
				<fa-icon [icon]="ac.sectionIcons.zencustomers" size="sm"></fa-icon>
				Cloud Broadcaster Builds
				<span *ngIf="!loading" class="cp-tab-badge">
					{{ zenBuilds.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-stats-tab"
				aria-controls="nav-stats" data-bs-target="#nav-stats">
				Stats/Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-marketplace-log-tab" aria-controls="nav-marketplace-log" data-bs-target="#nav-marketplace-log"
				ngbTooltip="Marketplace Log">
				<fa-icon [icon]="ac.sectionIcons.marketplace" size="sm"></fa-icon>
				<!-- marketplace -->
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-journal-tab"
				aria-controls="nav-journal" data-bs-target="#nav-journal" ngbTooltip="Journal Entries">
				<fa-icon [icon]="ac.sectionIcons.journals" size="sm"></fa-icon>
				<!-- Journal -->
				<span *ngIf="journals && journals.length > 0" class="cp-tab-badge ms-1">
					{{ journals.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-admin-log-tab"
				aria-controls="nav-admin-log" data-bs-target="#nav-admin-log">
				<fa-icon [icon]="ac.sectionIcons.adminlogs" size="sm"></fa-icon>
				<!-- Log -->
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">

			<div *ngIf="zenCustomer && !organization" class="cp-warning-message">
				<fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>
				This ZEN Master Site/Domain is not linked to an Organization.
			</div>

			<div *ngIf="showNotificationWarning" class="cp-warning-message">
				<fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>
				This ZEN Master Site/Domain does not have at least one user that will receive a ZCP notification about
				ZEN Master.<br />
				See the Users tab for more information.
			</div>

			<div *ngIf="zenCustomer" class="cp-property-list">
				<div class="cp-property-block">
					<span class="cp-property-label">Enabled:</span>
					<span class="cp-property-value" [ngClass]="{'text-danger': zenCustomer.is_enabled === 0}">
						<fa-icon *ngIf="zenCustomer.is_enabled === 1" [icon]="['fas', 'power-off']" size="sm" class="text-success"
							ngbTooltip="Enabled" placement="end"></fa-icon>
						<fa-icon *ngIf="zenCustomer.is_enabled === 0" [icon]="['fas', 'ban']" size="sm" class="text-danger"
							ngbTooltip="Disabled" placement="end"></fa-icon>
						{{ zenCustomer.is_enabled === 1 ? 'Yes' : 'No' }}
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Environment:</span>
					<span class="cp-property-value">{{ zenCustomer.environment }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Name:</span>
					<span class="cp-property-value">{{ zenCustomer.name }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">DNS Prefix:</span>
					<span class="cp-property-value">{{ zenCustomer.dns_prefix }}</span>
				</div>

				<div *ngIf="organization" class="cp-property-block">
					<span class="cp-property-label">Organization:</span>
					<span class="cp-property-value">
						<a [routerLink]="['/' + ac.urls.organizations, organization.id]" [ngbPopover]="organizationPopupContent"
							popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							{{ organization.name }}</a>
						<fa-icon *ngIf="organization.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
							ngbTooltip="Organization is not enabled"></fa-icon>
						<ng-template #organizationPopupContent>
							<div class="cp-popover-banner">{{ organization.name }}</div>
							<div class="cp-pre-text">{{
								popOverTools.getOrganizationPopoverLines(organization).join('\n')}}</div>
						</ng-template>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="organization">
					<span class="cp-property-label">Account Owner:</span>
					<span class="cp-property-value">
						<span *ngIf="!sfOwnerUser">
							{{ organization.salesforce_account_owner }}
						</span>
						<span *ngIf="sfOwnerUser">
							<a [routerLink]="['/' + ac.urls.users, sfOwnerUser.id]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ organization.salesforce_account_owner }}
							</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ sfOwnerUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(sfOwnerUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="organization">
					<span class="cp-property-label">SE:</span>
					<span class="cp-property-value">
						<span *ngIf="!sfSEUser">
							{{ organization.salesforce_se }}
						</span>
						<span *ngIf="sfSEUser">
							<a [routerLink]="['/' + ac.urls.users, sfSEUser.id]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ organization.salesforce_se }}
							</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ sfSEUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(sfSEUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="organization">
					<span class="cp-property-label">TAM:</span>
					<span class="cp-property-value">
						<span *ngIf="!sfTAMUser">
							{{ organization.salesforce_tam }}
						</span>
						<span *ngIf="sfTAMUser">
							<a [routerLink]="['/' + ac.urls.users, sfTAMUser.id]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ organization.salesforce_tam }}
							</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ sfTAMUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(sfTAMUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>


				<div *ngIf="zenCustomer.zcp_type && zenCustomer.zcp_type !== ''" class="cp-property-block">
					<span class="cp-property-label">Type:</span>
					<span class="cp-property-value">{{ ac.zenMasterTypeLabels[zenCustomer.zcp_type]
						}}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Assessment:</span>
					<span class="cp-property-value">{{ zenCustomer.assessment }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Generic ZEN Master Usage Key:</span>
					<span *ngIf="!zenCustomer.generic_zm_usage_key || zenCustomer.generic_zm_usage_key === ''" class="cp-property-value">
						not created
					</span>

					<span *ngIf="zenCustomer.generic_zm_usage_key && zenCustomer.generic_zm_usage_key !== '' && zenmasterKeyID === 0"
						class="cp-property-value">
						{{ zenCustomer.generic_zm_usage_key }}
					</span>
					<span *ngIf="zenCustomer.generic_zm_usage_key && zenCustomer.generic_zm_usage_key !== '' && zenmasterKeyID !== 0"
						class="cp-property-value">
						<a [routerLink]="['/' + ac.urls.licensing, zenmasterKeyID]">
							{{ zenCustomer.generic_zm_usage_key }}</a>
					</span>
					<span *ngIf="zenmasterUsage !== 0" class="cp-property-value"
						[ngbTooltip]="'Total Generic Usage Last ' + ac.assessmentThresholdDays  + ' Days'">
						{{ textTools.formattedMB(zenmasterUsage) }}
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">AWS MediaConnect Usage Key:</span>
					<span *ngIf="!zenCustomer.aws_mediaconnect_usage_key || zenCustomer.aws_mediaconnect_usage_key === ''"
						class="cp-property-value">
						not created
					</span>

					<span
						*ngIf="zenCustomer.aws_mediaconnect_usage_key && zenCustomer.aws_mediaconnect_usage_key !== '' && mediaconnectKeyID === 0"
						class="cp-property-value">
						{{ zenCustomer.aws_mediaconnect_usage_key }}
					</span>
					<span
						*ngIf="zenCustomer.aws_mediaconnect_usage_key && zenCustomer.aws_mediaconnect_usage_key !== '' && mediaconnectKeyID !== 0"
						class="cp-property-value">
						<a [routerLink]="['/' + ac.urls.licensing, mediaconnectKeyID]">
							{{ zenCustomer.aws_mediaconnect_usage_key }}</a>
					</span>

					<span *ngIf="mediaconnectUsage !== 0" class="cp-property-value"
						[ngbTooltip]="'Total Usage Last ' + ac.assessmentThresholdDays  + ' Days'">
						{{ textTools.formattedMB(mediaconnectUsage) }}
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">CQA:</span>
					<span class="cp-property-value">{{ zenCustomer.content_analysis === 1 ? 'Yes' : 'No' }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Automation:</span>
					<span class="cp-property-value">{{ zenCustomer.automation === 1 ? 'Yes' : 'No' }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Traceroute History:</span>
					<span class="cp-property-value">{{ zenCustomer.traceroute_history === 1 ? 'Yes' : 'No' }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Live Events:</span>
					<span class="cp-property-value">{{ zenCustomer.live_events === 1 ? 'Yes' : 'No' }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Allow Agent Z:</span>
					<span class="cp-property-value">{{ zenCustomer.allow_agentz === 1 ? 'Yes' : 'No' }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Allow Non-SSO Login:</span>
					<span class="cp-property-value">{{ zenCustomer.allow_non_sso_login === 1 ? 'Yes' : 'No' }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Created:</span>
					<span class="cp-property-value">{{ zenCustomer.created_days | number }} day(s) ago
						({{ zenCustomer.created_at | date: ac.pageDateFmt }})</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Last Login:</span>
					<span class="cp-property-value" *ngIf="zenCustomer.last_login_days != null">
						{{ zenCustomer.last_login_days }} day(s) ago</span>
					<span class="cp-property-value" *ngIf="zenCustomer.last_login_days == null">Never</span>
				</div>

				<!-- <li *ngIf="organization && !!organization.salesforce_account_id && organization.salesforce_account_id !== ''
					&& organization.salesforce_account_owner && organization.salesforce_account_owner !== ''">
					<span class="cp-property-label">Salesforce Account Owner:</span>
					<span class="cp-property-value">
						{{ organization.salesforce_account_owner }}
					</span>
				</li> -->
			</div>

			<div *ngIf="marketplaceLabel !== ''" class="cp-property-list">
				<div class="cp-property-block">
					<span class="cp-property-label">Marketplace:</span>
					<span class="cp-property-value">{{ marketplaceLabel }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">{{ marketPlaceAccountIdLabel }}:</span>
					<span class="cp-property-value">{{ marketplaceAccountIdentifier }}</span>
				</div>

				<div *ngIf="marketplaceProducts && marketplaceProducts.length > 0" class="cp-property-block">
					<span class="cp-property-label">Marketplace Product(s):</span>
					<span class="cp-property-value">
						<ul class="mb-0">
							<li *ngFor="let marketplaceProduct of marketplaceProducts">{{ marketplaceProduct}}</li>
						</ul>
					</span>
				</div>

				<div *ngIf="lastMarketplaceUsageReported" class="cp-property-block">
					<span class="cp-property-label">Marketplace Last Reported:</span>
					<span class="cp-property-value">
						{{ lastMarketplaceUsageReported | date: ac.pageDateTimeFmt }}
					</span>
				</div>

				<button *ngIf="canReportToMarketplace" type="button" class="btn btn-outline-primary btn-sm" data-bs-toggle="modal"
					data-bs-target="#manualMarketplaceReportModal">
					<fa-icon [icon]="ac.sectionIcons.marketplace" size="sm"></fa-icon>
					Manually Submit Marketplace Usage
				</button>

			</div>

			<button *ngIf="canSendShutdownNotice" type="button" class="btn btn-outline-primary" (click)="sendZenCustomerShutdownNotice()"
				ngbTooltip="Send the account team for this ZEN Master (see above) an e-mail telling them it will be shutdown soon"
				placement="end">
				<fa-icon [icon]="ac.sectionIcons.mynotifications" size="sm"></fa-icon>
				Send Shutdown Notification to Account Team
			</button>

			<button *ngIf="canRunPullTargetsReport" type="button" class="btn btn-outline-primary" (click)="runPullTargetsReport()">
				<fa-icon [icon]="['fas', 'file-excel']" size="sm"></fa-icon>
				Run Specialized Pull Targets Report
			</button>

		</div>

		<div class="tab-pane" id="nav-users" role="tabpanel" aria-labelledby="nav-users-tab">
			<div *ngIf="!loading && (!!zenCustomer && zenUsers.length === 0)" class="cp-light-message">
				This ZEN Master Site doens't have any Users.
			</div>

			<table *ngIf="zenUsers.length > 0" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<th class="cp-icon-header">
							<fa-icon [icon]="['fas', 'power-off']" size="sm" placement="end" ngbTooltip="Enabled/Disabled">
							</fa-icon>
						</th>
						<th class="cp-icon-header">
							<fa-icon [icon]="['fas', 'sign-in-alt']" size="sm" placement="end" ngbTooltip="Single Sign On">
							</fa-icon>
						</th>

						<th class="cp-icon-header">
							<fa-icon [icon]="['fas', 'hat-wizard']" class="text-primary" size="sm" placement="end" ngbTooltip="Site Admin">
							</fa-icon>
						</th>
						<th *ngIf="showZixiColumns" class="cp-icon-header">
							<fa-icon [icon]="['fas', 'hat-wizard']" class="text-success" size="sm" placement="end"
								ngbTooltip="Zixi Support">
							</fa-icon>
						</th>
						<th *ngIf="showZixiColumns" class="cp-icon-header">
							<fa-icon [icon]="['fas', 'hat-wizard']" class="text-info" size="sm" placement="end" ngbTooltip="Zixi Admin">
							</fa-icon>
						</th>

						<th>Name</th>
						<th>E-Mail</th>
						<th class="cp-icon-header">
							<fa-icon [icon]="['fas', 'clock']" size="sm" ngbTooltip="Days since added" placement="bottom">
							</fa-icon>
						</th>
						<th class="cp-icon-header">
							<fa-icon [icon]="['fas', 'sign-in-alt']" size="sm" ngbTooltip="Days since last login" placement="bottom">
							</fa-icon>
						</th>
						<th>ZCP Notify</th>
					</tr>
				</thead>
				<tbody *ngIf="!zenUsers || zenUsers.length">
					<tr *ngFor="let item of zenUsers">

						<td class="cp-icon-cell" [attr.data-order]="item.is_enabled">
							<fa-icon *ngIf="item.is_enabled === 1" [icon]="['fas', 'power-off']" size="sm" class="text-success"
								placement="end" ngbTooltip="User Account Enabled - login permitted"></fa-icon>
							<fa-icon *ngIf="item.is_enabled === 0" [icon]="['fas', 'ban']" size="sm" class="text-danger" placement="end"
								ngbTooltip="User Account Disabled - login not permitted"></fa-icon>
						</td>

						<td class="cp-icon-cell" [attr.data-order]="item.sso_id">
							<fa-icon *ngIf="item.sso_id && item.sso_id !== ''" [icon]="['fas', 'sign-in-alt']" size="sm" placement="end"
								ngbTooltip="This User authenticated via Single Sign On">
							</fa-icon>
						</td>

						<td class="cp-icon-cell" [attr.data-order]="item.is_admin">
							<fa-icon *ngIf="item.is_admin && item.is_admin === 1" [icon]="['fas', 'hat-wizard']" class="text-primary"
								size="sm" placement="end" ngbTooltip="Site Admin">
							</fa-icon>
						</td>

						<td *ngIf="showZixiColumns" class="cp-icon-cell" [title]="validationTools.getZenUserSupportLabel(item, false)">
							{{ validationTools.getZenUserSupportLabel(item, true) }}
						</td>

						<td *ngIf="showZixiColumns" class="cp-icon-cell"
							[attr.data-order]="item.is_zixi_admin && item.is_zixi_admin == 1 ? 'y' : 'n'">
							<fa-icon *ngIf="item.is_zixi_admin && item.is_zixi_admin === 1" [icon]="['fas', 'hat-wizard']" class="text-info"
								size="sm" placement="end" ngbTooltip="Zixi Admin">
							</fa-icon>
						</td>

						<td class="text-nowrap" [attr.data-order]="item.name">
							<span *ngIf="!matchedPortalUsers[item.id]">{{ item.name }}</span>
							<a *ngIf="matchedPortalUsers[item.id]" [routerLink]="['/' + ac.urls.users, matchedPortalUsers[item.id].id]"
								placement="end" ngbTooltip="This ZEN Master User is also a ZCP User">
								{{ item.name}}
							</a>
							<fa-icon *ngIf="diffOrgUsers[item.id]" [icon]="['fas', 'exclamation-triangle']"
								ngbTooltip="The ZCP User is not linked to the Organization that is linked to this ZEN Master site.">
							</fa-icon>
							<fa-icon *ngIf="noOrgUsers[item.id]" [icon]="['fas', 'exclamation-triangle']" class="text-warning"
								ngbTooltip="The ZCP User is not linked to an Organization.">
							</fa-icon>
						</td>
						<td>{{ item.email }}</td>

						<td class="text-center" [attr.data-order]="textTools.formatDateTimeUTC(item.created_at)">
							<span *ngIf="daysSinceCreated[item.id]">
								{{ daysSinceCreated[item.id] | number }}</span>
						</td>
						<td class="text-center" [attr.data-order]="textTools.formatDateTimeUTC(item.last_login_at)">
							<span *ngIf="daysSinceLoggedIn[item.id] != null">
								{{ daysSinceLoggedIn[item.id] | number }}</span>
						</td>
						<td><span *ngIf="notificationStates[item.id]">{{ notificationStates[item.id] }}</span></td>

					</tr>
				</tbody>

			</table>
		</div>

		<div class="tab-pane" id="nav-downloads-builds" role="tabpanel" aria-labelledby="nav-downloads-builds-tab">

			<div *ngIf="allClusterVersionsInUse.length !== 0" class="cp-light-message">
				Broadcaster Versions Assigned to Broadcaster Clusters with at least one Broadcaster:
				<div *ngFor="let v of allClusterVersionsInUse" class="fw-bold">
					{{ v }}

					<fa-icon *ngIf="!clusterVersionsHasBuild[v]" [icon]="['fas', 'exclamation-triangle']" size="sm" placement="end"
						class="text-warning" ngbTooltip="Version Not Available">
					</fa-icon>
					<fa-icon *ngIf="clusterVersionsHasBuild[v]" [icon]="['fas', 'check']" size="sm" placement="end" class="text-success"
						ngbTooltip="Version Available">
					</fa-icon>
				</div>
			</div>

			<div *ngIf="allClusterVersionsNotInUse.length !== 0" class="cp-light-message">
				Broadcaster Versions Assigned to empty Broadcaster Clusters:
				<div *ngFor="let v of allClusterVersionsNotInUse" class="fw-bold">
					{{ v }}
					<fa-icon *ngIf="!clusterVersionsHasBuild[v]" [icon]="['fas', 'exclamation-triangle']" size="sm" placement="end"
						class="text-warning" ngbTooltip="Version Not Available">
					</fa-icon>
					<fa-icon *ngIf="clusterVersionsHasBuild[v]" [icon]="['fas', 'check']" size="sm" placement="end" class="text-success"
						ngbTooltip="Version Available">
					</fa-icon>
				</div>
			</div>

			<div *ngIf="!loading && (!zenBuilds || zenBuilds.length === 0)" class="cp-light-message">
				No builds available for this ZEN Master Domain/Site.

				<span *ngIf="!organization">
					This Zen Master Site isn't associated with an Organization.
					To gain access to Broadcaster Builds, the site must be associated with an Organization
					and that Organization must have access to at least one Broadcaster Build.
				</span>

				<span *ngIf="organization && organization.is_enabled === 0">
					The Organization is disabled.
				</span>
				<!-- *ngIf="organization" -->
			</div>

			<div class="cp-light-message">
				Below are the Broadcaster versions available to this ZEN Master site for launching cloud Broadcasters.
			</div>

			<app-builds-table #buildsTable1></app-builds-table>
		</div>

		<div class="tab-pane" id="nav-stats" role="tabpanel" aria-labelledby="nav-stats-tab">
			<table *ngIf="!!zenCustomer" class="cp-std-table ms-4" style="max-width: 300px">
				<tbody *ngFor="let mode of ac.zenMasterReportModes" class="mb-2">
					<tr>
						<td colspan="3" class="fw-bold">{{ ac.zenMasterReportModeLabels[mode] }}</td>
					</tr>

					<tr *ngFor="let stat of zenInfoBlocks[mode]">
						<td></td>
						<td *ngIf="isNumber(stat.value)" class="cp-property-value text-end">{{ stat.value | number }}
						</td>
						<td *ngIf="!isNumber(stat.value)" class="cp-property-value text-end">{{ stat.value }}</td>
						<td>{{ stat.label }}</td>
					</tr>

					<tr *ngIf="zenInfoBlocks[mode] && zenInfoBlocks[mode].length === 0">
						<td></td>
						<td class="cp-property-value text-end">-</td>
						<td>None</td>
					</tr>

				</tbody>
			</table>
		</div>

		<div class="tab-pane" id="nav-admin-log" role="tabpanel" aria-labelledby="nav-admin-log-tab">
			<div *ngIf="!loading && adminLogs.length === 0" class="cp-light-message">
				No system/change logs for this ZEN Master site.
			</div>

			<app-logs-table #logsTable1 [expandNames]="true"></app-logs-table>
		</div>

		<div class="tab-pane" id="nav-journal" role="tabpanel" aria-labelledby="nav-journal-tab">
			<div *ngIf="!loading && (!journals || journals.length === 0)" class="cp-light-message">
				No journal entries for this ZEN Master site.
			</div>

			<button *ngIf="canAddJournals" type="button" class="btn btn-outline-primary btn-sm mb-2 me-1"
				[routerLink]="['/' + ac.urls.adminlogs, 'journal', 'zenmaster', id]">
				<fa-icon [icon]="ac.sectionIcons.journals"></fa-icon> Add Journal Entry
			</button>

			<app-journals-table #journalsTable1></app-journals-table>
		</div>

		<div class="tab-pane" id="nav-marketplace-log" role="tabpanel" aria-labelledby="nav-marketplace-log-tab">
			<div *ngIf="!loading && marketplaceLogs.length === 0" class="cp-light-message">
				No marketplace logs for this ZEN Master site.
			</div>

			<app-marketplace-logs-table></app-marketplace-logs-table>
		</div>
	</div>
</div>

<div class="modal" id="enableModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Enable/Resume ZEN Master Site</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeEnableModalButton">

				</button>
			</div>

			<div class="modal-body">
				<form *ngIf="enableForm" [formGroup]="enableForm" autocomplete="off">

					<div class="form-group">
						<label for="reason">Reason for Enabling (Who requested it? Opportunity URL, etc.)</label>
						<textarea id="reason" formControlName="reason" class="form-control" rows="4"></textarea>
					</div>

					<div class="text-center mt-2">
						<button *ngIf="canQuickResume" type="button" [disabled]="!enableForm.valid" (click)="enable()"
							class="btn btn-sm btn-success">
							<fa-icon [icon]="['fas', 'bolt']" size="sm"></fa-icon>
							Enable/Resume this ZEN Master Site
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>

<div class="modal" id="manualMarketplaceReportModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Manually Submit Marketplace Report</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeManualMarketplaceReportModal">

				</button>
			</div>

			<div class="modal-body">

				<form *ngIf="manualMarketplaceForm" [formGroup]="manualMarketplaceForm" autocomplete="off">
					<div class="form-group">
						<label for="amountGB">Amount to Report (GB)</label>
						<input type="text" id="amountGB" formControlName="amountGB" class="form-control" />
					</div>

					<div class="form-group">
						<label for="reportStart">Start of Report (yyyy/mm/dd hh:mm UTC)</label>
						<input type="text" id="reportStart" formControlName="reportStart" class="form-control" />
					</div>

					<div class="form-group">
						<label for="reportEnd">End of Report (yyyy/mm/dd hh:mm UTC)</label>
						<input type="text" id="reportEnd" formControlName="reportEnd" class="form-control" />
					</div>

					<div class="text-center mt-2">
						<button type="button" (click)="manualMarketplaceZenReport()" class="btn btn-sm btn-outline-secondary"
							[disabled]="!manualMarketplaceForm.valid">
							<fa-icon [icon]="['fas', 'link']" size="sm"></fa-icon>
							Report Usage to Marketplace
						</button>
					</div>
				</form>

			</div>
		</div>
	</div>
</div>

<app-popup-box [parentApi]="getParentMethod()"></app-popup-box>