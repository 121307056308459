import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import { RoleGuard } from 'client/app/guards/role.guard';

import { MyPartnersComponent } from './my-partners/my-partners.component';

const routes: Routes = [
	{
		path: '',
		component: MyPartnersComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole },
		resolve: []
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class MyPartnersRoutingModule { }
