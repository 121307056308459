import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';

import { PartnerComponent } from './partner/partner.component';
import { PartnerListComponent } from './partner-list/partner-list.component';
import { PartnerFormComponent } from './partner-form/partner-form.component';

import { PartnersResolverService } from './partners-resolver.service';
import { UsersResolverService } from '../users/users-resolver.service';
import { OrganizationsResolverService } from '../organizations/organizations-resolver.service';

const routes: Routes = [
	{
		path: '',
		component: PartnerListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [PartnersResolverService, OrganizationsResolverService]
	}, {
		path: 'new',
		component: PartnerFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [PartnersResolverService, OrganizationsResolverService]
	}, {
		path: ':id',
		component: PartnerComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [PartnersResolverService, OrganizationsResolverService, UsersResolverService]
	}, {
		path: ':id/edit',
		component: PartnerFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [PartnersResolverService, OrganizationsResolverService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PartnerAdminRoutingModule { }
