import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class DownloadLogsService {

	constructor(private http: HttpClient) {
	}

	// call back-end to get last X logs
	async getLogs(startDate: Date = null, endDate: Date = null) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.downloadlogs + '?a=b';
			if (startDate && startDate !== null) url += '&startDate=' + encodeURIComponent(startDate + '');
			if (endDate && endDate !== null) url += '&endDate=' + encodeURIComponent(endDate + '');

			const result = await this.http.get<Models.DownloadLog[]>(url).toPromise();
			const arr: Models.DownloadLog[] = result;

			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get last X logs for a user
	async getLogsForUser(id: number) {
		try {
			const result = await this.http.get<Models.DownloadLog[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.downloadlogs + '/user/' + id).toPromise();
			const arr: Models.DownloadLog[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get last X logs for a user
	async getLogsForOrganization(id: number) {
		try {
			const result = await this.http.get<Models.DownloadLog[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.downloadlogs + '/organization/' + id).toPromise();
			const arr: Models.DownloadLog[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get last X logs for a user
	async getLogsForFile(id: number) {
		try {
			const result = await this.http.get<Models.DownloadLog[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.downloadlogs + '/file/' + id).toPromise();
			const arr: Models.DownloadLog[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get last X logs for a user
	async getLogsForBuild(id: number) {
		try {
			const result = await this.http.get<Models.DownloadLog[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.downloadlogs + '/build/' + id).toPromise();
			const arr: Models.DownloadLog[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get last X logs for a user
	async getLogsForBundle(id: number) {
		try {
			const result = await this.http.get<Models.DownloadLog[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.downloadlogs + '/bundle/' + id).toPromise();
			const arr: Models.DownloadLog[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

}
