import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import ValidationTools from 'appshared/validation-tools';

import { AuthService } from 'client/app/services/auth.service';
import { LicensingAdminService } from '../licensing-admin.service';

@Component({
	selector: 'app-licensing-product-list',
	templateUrl: './licensing-product-list.component.html',
	styleUrls: ['./licensing-product-list.component.scss']
})
export class LicensingProductListComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;
	loading = true;

	products: Models.LicenseProduct[] = [];

	canManage = false;

	constructor(
		private licensingAdminService: LicensingAdminService,
		private authService: AuthService) { }

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;
			this.canManage = (authUser && ValidationTools.checkAccess(authUser, 'manage-license-key-setup'));
			this.getData();
			// this.loadTables();
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async getData() {
		this.products = await this.licensingAdminService.getProducts();

		this.products.sort((a, b) => (a.name > b.name) ? 1 : -1);


		this.loading = false;
	}

}
