import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxDropzoneModule } from 'ngx-dropzone';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { FilesRoutingModule } from './files-routing.module';
import { FileComponent } from './file/file.component';
import { FileListComponent } from './file-list/file-list.component';
import { FileUploadFormComponent } from './file-upload-form/file-upload-form.component';
import { FileFormComponent } from './file-form/file-form.component';


@NgModule({
	declarations: [FileComponent, FileListComponent, FileUploadFormComponent, FileFormComponent],
	imports: [
		FilesRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		SharedModule,
		FontAwesome,
		ClipboardModule,
		NgxDropzoneModule
	]
})
export class FilesModule { }
