import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';

import { PackagesService } from '../packages.service';
import { LicensingAdminService } from '../../licensing-admin/licensing-admin.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-package-key-template-form',
	templateUrl: './package-key-template-form.component.html',
	styleUrls: ['./package-key-template-form.component.scss']
})
export class PackageKeyTemplateFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	id: number;
	id2: number;
	package: Models.Package;
	packageKeyTemplate: Models.PackageKeyTemplate;

	templates: Models.KeyTemplate[] = [];

	errors: string[] = [];
	editMode = false;
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	// other stuff
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private licensingAdminService: LicensingAdminService,
		private packagesService: PackagesService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.id2 = +params.get('id2');
			if (this.id && this.id !== 0) {
				this.package = this.packagesService.getOne(this.id);
				if (!this.package || this.package == null || this.package.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
				} else if (this.id2 && this.id2 !== 0) {
					for (const ar of this.package.key_templates) {
						if (ar.id === this.id2)
							this.packageKeyTemplate = ar;
					}
					if (!this.packageKeyTemplate) {
						this.router.navigate([AppConstants.urls.notfound]);
					} else {
						this.editMode = true;
					}
				}
			} else {
				this.router.navigate([AppConstants.urls.notfound]);
			}
			this.loading = false;
			this.initForm();
		});
	}

	ngOnInit(): void {
	}

	ngOnDestroy() {
	}

	async initForm() {
		const allTemplates: Models.KeyTemplate[] = await this.licensingAdminService.getTemplates();

		this.templates = [];
		for (const template of allTemplates)
			if (!template.settings.editOnly && template.settings.quickKey)
				this.templates.push(template);

		if (!this.packageKeyTemplate) {
			this.packageKeyTemplate = new Models.PackageKeyTemplate(0, this.id, 0, '', 1);
		}

		let pkValidators = [Validators.required];
		if (this.editMode) pkValidators = [];

		if (this.packageKeyTemplate.template_id === 0) this.packageKeyTemplate.template_id = null;

		this.theForm = new UntypedFormGroup({
			name: new UntypedFormControl(this.packageKeyTemplate.name, [Validators.required]),
			template_id: new UntypedFormControl(this.packageKeyTemplate.template_id, [Validators.required]),
			allow_multiple: new UntypedFormControl(this.packageKeyTemplate.allow_multiple)
		});

	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		this.packageKeyTemplate.name = this.theForm.value.name;
		this.packageKeyTemplate.template_id = +this.theForm.value.template_id;
		this.packageKeyTemplate.allow_multiple = +this.theForm.value.allow_multiple;

		try {
			let retPkg: Models.Package;
			if (this.editMode)
				retPkg = await this.packagesService.updateOneKeyTemplate(this.packageKeyTemplate);
			else
				retPkg = await this.packagesService.addOneKeyTemplate(this.packageKeyTemplate);

			if (retPkg) {
				this.router.navigate([AppConstants.urls.packages, this.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate([AppConstants.urls.packages, this.id]);
	}

	get name() { return this.theForm.get('name'); }
	get key_product() { return this.theForm.get('key_product'); }
	get key_type() { return this.theForm.get('key_type'); }
	get private_key() { return this.theForm.get('private_key'); }

}
