import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject, interval, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';

import { SystemMessagesService } from '../system-messages.service';
import { AuthService } from 'client/app/services/auth.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-system-messages-list',
	templateUrl: './system-messages-list.component.html',
	styleUrls: ['./system-messages-list.component.scss']
})
export class SystemMessagesListComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	miscTools = MiscTools;
	textTools = TextTools;

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	// 'standard' list stuff
	loading = true;
	refreshing = false;

	items: Models.SystemMessage[] = [];
	itemsToShow: Models.SystemMessage[] = [];

	// other stuff
	private userSubscription: Subscription;
	canAdd = false;

	canSendNotifications = false;

	// new table stuff
	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = 'cp-system-messages-list';

	fieldsToShow: string[] = [];

	columnDefs: any[] = [
		{
			field: 'is_enabled',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Enabled/Disabled',
			icon: 'power-off'
		}, {
			field: '__time_window',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Has Time Window',
			icon: 'calendar-alt'
		}, {
			field: '__more_info',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Has \'More Info\' Link',
			icon: 'link'
		}, {
			field: 'staff_only',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Staff Only',
			icon: 'lock'
		}, {
			field: 'added_on',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Added',
			toolTip: null,
			icon: null
		}, {
			field: 'start_at',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Not Before',
			toolTip: null,
			icon: null
		}, {
			field: 'end_at',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Not After',
			toolTip: null,
			icon: null
		}, {
			field: '__message',
			type: 'baseText',
			sortType: 'text',
			label: 'Message',
			toolTip: null,
			icon: null
		}
	];

	constructor(
		private systemMessagesService: SystemMessagesService,
		private authService: AuthService,
	) { }

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		this.loading = true;

		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.canAdd = (authUser && ValidationTools.checkAccess(authUser, 'manage-system-messages'));
			this.canSendNotifications = (authUser && ValidationTools.checkAccess(authUser, 'send-general-notifications'));
			this.loadData();
		});
	}

	// ------------------------------------------------------------------------
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ------------------------------------------------------------------------
	async loadData(refresh: boolean = false) {
		this.loading = true;
		if (refresh)
			await this.systemMessagesService.refreshAll().toPromise();

		this.items = this.systemMessagesService.getAll();

		for (const item of this.items) {
			if (!item.start_at && !item.end_at)
				item['__time_window'] = 3;
			else
				if (this.inDisplayWindow(item))
					item['__time_window'] = 1;
				else
					item['__time_window'] = 0;

			if (item.url)
				item['__more_info'] = 1;
			else
				item['__more_info'] = 0;

			item['__message'] = this.chopMessage(item);

		} // for

		this.fieldsToShow = [];
		for (const cd of this.columnDefs)
			this.fieldsToShow.push(cd.field);

		this.loading = false;

		await MiscTools.delay(100);
		if (this.pagingSearchBar)
			this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'added_on', 'desc');
	}

	// ------------------------------------------------------------------------
	chopMessage(systemMessage: Models.SystemMessage) {
		const chopLength = 100;
		if (systemMessage.message.length > chopLength)
			return systemMessage.message.substring(0, chopLength) + '...';
		else
			return systemMessage.message;
	}

	// ------------------------------------------------------------------------
	inDisplayWindow(systemMessage: Models.SystemMessage) {
		const now = new Date();
		if (systemMessage.start_at && (new Date(systemMessage.start_at)).getTime() > now.getTime())
			return false;

		if (systemMessage.end_at && (new Date(systemMessage.end_at)).getTime() < now.getTime())
			return false;

		return true;
	}

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.SystemMessage[] = [];
		for (const item of this.items) {
			let match: boolean = true;

			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.message.toLowerCase().includes(w)
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}

}
