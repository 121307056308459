<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.quizzes" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" (click)="onCancel()">Quizzes + Polls</a>
			</li>
			<li *ngIf="pageMode === 'edit'" class="breadcrumb-item active" aria-current="page">
				Edit
			</li>
			<li *ngIf="pageMode === 'new'" class="breadcrumb-item active" aria-current="page">
				New
			</li>
		</ol>
	</nav>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info/Permissions
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-questions-tab"
				aria-controls="nav-questions" data-bs-target="#nav-questions">
				<fa-icon [icon]="ac.sectionIcons.quizzes" size="sm"></fa-icon>
				Questions
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<form *ngIf="theForm && !loading" [formGroup]="theForm" class="tab-form">
		<div class="tab-content" id="nav-tabContent">
			<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">
				<div *ngIf="quiz && quiz.config" class="cp-general-width-limit">

					<div class="cp-compact-form-group">
						<label for="name">
							Name*
							<!-- <fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="This is the name staff will see when selecting a template.">
							</fa-icon> -->
						</label>
						<input type='text' class="form-control" id="name" formControlName="name" />
					</div>

					<div class="cp-compact-form-group">
						<label for="description">
							Description
							<!-- <fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Optional.  Extra information that appears with question via a question icon with a tool tip">
							</fa-icon> -->
						</label>
						<textarea id="description" formControlName="description" class="form-control" rows="4"></textarea>
					</div>

					<div class="cp-compact-form-group">
						<label for="keyTypes">
							Type*
							<!-- <fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="The key types that template supports.">
							</fa-icon> -->
						</label>

						<div *ngFor="let typeSelection of ac.quizTypeSelections" class="form-check ms-1 me-1">
							<input class="form-check-input" type="radio" [id]="'quizType_' + typeSelection.value" formControlName="quizType"
								[value]="typeSelection.value" />
							<label class="form-check-label" [for]="'quizType_' + typeSelection.value">
								{{ typeSelection.label }}
							</label>
						</div>
					</div>
					<div class="cp-compact-form-group">
						<label for="keyTypes">
							Status*
							<!-- <fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="The key types that template supports.">
							</fa-icon> -->
						</label>

						<div *ngFor="let statusSelection of ac.quizStatusSelections" class="form-check ms-1 me-1">
							<input class="form-check-input" type="radio" [id]="'status_' + statusSelection.value" formControlName="status"
								[value]="statusSelection.value" />
							<label class="form-check-label" [for]="'status_' + statusSelection.value">
								{{ statusSelection.label }}
							</label>
						</div>
					</div>

					<div class="cp-compact-form-group">
						<label for="adminIds">
							Admin Delegates
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Individual users that don't have quiz admin access, but can manage this quiz.">
							</fa-icon>
						</label>
						<ng-select [items]="staffUserSelections" bindValue="id" bindLabel="name" id="adminIds" formControlName="adminIds"
							[multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="allStaff" formControlName="allStaff" />
						<label class="form-check-label" for="allStaff">
							All Staff Can Participate
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="When checked, this quiz will be available to any staff member.">
							</fa-icon>
						</label>
					</div>

					<div class="cp-compact-form-group">
						<label for="userIds">
							Participants (Users)
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Individual users can participate in (answer) this quiz.">
							</fa-icon>
						</label>
						<ng-select [items]="staffUserSelections" bindValue="id" bindLabel="name" id="userIds" formControlName="userIds"
							[multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

					<div class="cp-compact-form-group">
						<label for="groupIds">
							Participants (Groups)
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Groups of users can participate in (answer) this quiz.">
							</fa-icon>
						</label>
						<ng-select [items]="allUserGroups" bindValue="id" bindLabel="name" id="groupIds" formControlName="groupIds"
							[multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>
				</div>
				<div class="cp-form-padding"></div>
			</div>

			<div class="tab-pane" role="tabpanel" id="nav-questions" aria-labelledby="nav-questions-tab">
				<div *ngIf="quiz && quiz.config" class="cp-general-width-limit">

					<div class="mb-1 pb-1 border-bottom">
						<button *ngFor="let q of quiz.config.questions; let i = index" class="btn mb-1 me-1" type="button"
							(click)="currentQuestion = i"
							[ngClass]=" { 'btn-outline-primary': currentQuestion !== i, 'btn-primary': currentQuestion === i  }">
							{{ (i + 1) }}
						</button>

						<button class="btn btn-outline-primary me-1" type="button" (click)="addNewQuestion()" ngbTooltip="Add Question">
							<fa-icon [icon]="['fas', 'plus']"></fa-icon>
							<span *ngIf="!quiz.config.questions || quiz.config.questions.length === 0">
								Add Question
							</span>
						</button>

						<button class="btn btn-outline-primary me-1" type="button" data-bs-toggle="modal"
							data-bs-target="#bulkQuestionsModal" ngbTooltip="Add Questions">
							<fa-icon [icon]="['fas', 'plus']"></fa-icon>
							<fa-icon [icon]="['fas', 'plus']"></fa-icon>
							<span *ngIf="!quiz.config.questions || quiz.config.questions.length === 0">
								Add Questions
							</span>
						</button>

					</div>

					<div *ngFor="let q of quiz.config.questions; let i = index" class="border-bottom pb-2 mb-2"
						[hidden]="currentQuestion !== i">
						<div>
							Question #{{ (i + 1) }}
							<fa-icon [icon]="['fas', 'trash-alt']" class="text-danger" (click)="removeQuestion(q)"
								ngbTooltip="Remove this question"></fa-icon>
						</div>

						<div class="cp-compact-form-group">
							<label [for]="'q_text' + q.id">
								Answer Type
							</label>
							<div *ngFor="let answerTypeSelection of ac.quizAnswerTypeSelections" class="form-check ms-1 me-1">
								<input class="form-check-input" type="radio" [id]="'q_answerType' + q.id + '_' + answerTypeSelection.value"
									[formControlName]="'q_answerType' + q.id" [value]="answerTypeSelection.value" />
								<label class="form-check-label" [for]="'q_answerType' + q.id + '_' + answerTypeSelection.value">
									{{ answerTypeSelection.label }}
								</label>
							</div>
						</div>
						<div class="cp-compact-form-group">
							<label [for]="'q_text' + q.id">
								Question
							</label>
							<div class="input-group">
								<textarea [id]="'q_text' + q.id" [formControlName]="'q_text' + q.id" class="form-control"
									rows="4"></textarea>
							</div>
						</div>

						<div class="cp-compact-form-group">
							<label [for]="'q_moreInfo' + q.id">
								Extra Information
								<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
									ngbTooltip="Optional.  Extra information that appears with question via a question icon with a tool tip">
								</fa-icon>
							</label>
							<div class="input-group">
								<textarea [id]="'q_moreInfo' + q.id" [formControlName]="'q_moreInfo' + q.id" class="form-control"
									rows="4"></textarea>
							</div>
						</div>

						<!-- choices if Answer Type !== text -->
						<div *ngIf="['multi-choice-single', 'multi-choice-multi'].includes(getAnswerType(q))" class="ms-2 p-2 border">
							Choices
							<button class="btn btn-outline-primary me-1" type="button" (click)="addNewChoice(q)"
								ngbTooltip="Add New Choice">
								<fa-icon [icon]="['fas', 'plus']"></fa-icon>
							</button>

							<button class="btn btn-outline-primary me-1" type="button" data-bs-toggle="modal"
								data-bs-target="#bulkChoicesModal" ngbTooltip="Add Choices">
								<fa-icon [icon]="['fas', 'plus']"></fa-icon>
								<fa-icon [icon]="['fas', 'plus']"></fa-icon>
							</button>

							<div *ngFor="let a of q.choices; let j = index" class="cp-compact-form-group">
								<label [for]="'q_choice' + q.id + '_' + j">
									Choice #{{ (j + 1) }}
								</label>
								<div class="input-group">
									<input class="form-control" [id]="'q_choice' + q.id + '_' + j"
										[formControlName]="'q_choice' + q.id + '_' + j" />
								</div>
							</div>
							<div *ngIf="q.choices.length > 0" class="cp-65">
								To remove a choice, just leave it blank
							</div>
						</div>

						<!-- answers -->
						<div *ngIf="quizType.value && quizType.value === 'quiz'" class="ms-2 p-2 border">
							Answer(s)
							<button class="btn btn-outline-primary me-1" type="button" (click)="addNewAnswer(q)"
								ngbTooltip="Add New Answer">
								<fa-icon [icon]="['fas', 'plus']"></fa-icon>
							</button>

							<button class="btn btn-outline-primary me-1" type="button" data-bs-toggle="modal"
								data-bs-target="#bulkAnswersModal" ngbTooltip="Add Answers">
								<fa-icon [icon]="['fas', 'plus']"></fa-icon>
								<fa-icon [icon]="['fas', 'plus']"></fa-icon>
							</button>

							<div *ngFor="let a of q.answers; let j = index" class="cp-compact-form-group">
								<label [for]="'q_answer' + q.id + '_' + j">
									Answer #{{ (j + 1) }}
								</label>
								<div class="input-group">
									<input *ngIf="getAnswerType(q)!=='long-text'" class="form-control" [id]="'q_answer' + q.id + '_' + j"
										[formControlName]="'q_answer' + q.id + '_' + j" />
									<textarea *ngIf="getAnswerType(q)==='long-text'" [id]="'q_answer' + q.id + '_' + j"
										[formControlName]="'q_answer' + q.id + '_' + j" class="form-control" rows="4"></textarea>
								</div>
							</div>
							<div *ngIf="q.answers.length > 0" class="cp-65">
								To remove an answer, just leave it blank
							</div>
						</div>

						<div *ngIf="quizType.value && quizType.value === 'quiz'" class="cp-compact-form-group">
							<label [for]="'q_moreInfo' + q.id">
								Explanation
							</label>
							<div class="input-group">
								<textarea [id]="'q_explanation' + q.id" [formControlName]="'q_explanation' + q.id" class="form-control"
									rows="4"></textarea>
							</div>
						</div>
					</div>
				</div>

				<div class="cp-form-padding"></div>
			</div>
		</div>
	</form>
</div>

<div *ngIf="!loading && theForm" class="cp-form-buttons">
	<div class="cp-form-buttons-inner">
		<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
			<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
		</button>
		<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
			<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
		</button>

		<span class="gj-75 ps-2">* Required</span>
	</div>
</div>

<div class="modal" id="bulkQuestionsModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Add Multiple Questions</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeBulkQuestionsModalButton">

				</button>
			</div>

			<div class="modal-body">
				<form *ngIf="bulkQuestionsForm" [formGroup]="bulkQuestionsForm" autocomplete="off">

					<div class="cp-compact-form-group">
						<label for="description">
							Questions
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Enter one or more questions. Put each question on its own line.">
							</fa-icon>
						</label>
						<textarea id="questions" formControlName="questions" class="form-control" rows="8"></textarea>
					</div>

					<div class="cp-compact-form-group">
						<label for="answerType">
							Answer Type (Can be changed after add)
						</label>
						<div *ngFor="let answerTypeSelection of ac.quizAnswerTypeSelections" class="form-check ms-1 me-1">
							<input class="form-check-input" type="radio" [id]="'answerType_' + answerTypeSelection.value"
								formControlName="answerType" [value]="answerTypeSelection.value" />
							<label class="form-check-label" [for]="'answerType' + answerTypeSelection.value">
								{{ answerTypeSelection.shortLabel }}
							</label>
						</div>
					</div>

					<div class="text-center mt-2">
						<button type="button" [disabled]="loading" (click)="bulkAddQuestions()" class="btn btn-sm btn-outline-primary">
							<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
							Add Questions
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>

<div class="modal" id="bulkChoicesModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Add Multiple Choices</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeBulkChoicesModalButton">

				</button>
			</div>

			<div class="modal-body">
				<form *ngIf="bulkChoicesForm" [formGroup]="bulkChoicesForm" autocomplete="off">

					<div class="cp-compact-form-group">
						<label for="description">
							Choices
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Enter one or more choices. Put each choice on its own line.">
							</fa-icon>
						</label>
						<textarea id="choices" formControlName="choices" class="form-control" rows="8"></textarea>
					</div>

					<div class="text-center mt-2">
						<button type="button" [disabled]="loading" (click)="bulkAddChoices()" class="btn btn-sm btn-outline-primary">
							<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
							Add Choices
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>

<div class="modal" id="bulkAnswersModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Add Multiple Answers</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeBulkAnswersModalButton">

				</button>
			</div>

			<div class="modal-body">
				<form *ngIf="bulkAnswersForm" [formGroup]="bulkAnswersForm" autocomplete="off">

					<div class="cp-compact-form-group">
						<label for="description">
							Answers
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Enter one or more answers. Put each answer on its own line.">
							</fa-icon>
						</label>
						<textarea id="answers" formControlName="answers" class="form-control" rows="8"></textarea>
					</div>

					<div class="text-center mt-2">
						<button type="button" [disabled]="loading" (click)="bulkAddAnswers()" class="btn btn-sm btn-outline-primary">
							<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
							Add Answers
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>