

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
// import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';

import { FilesService } from '../files.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-file-upload-form',
	templateUrl: './file-upload-form.component.html',
	styleUrls: ['./file-upload-form.component.scss']
})
export class FileUploadFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	// theForm: FormGroup;
	files: any[] = [];

	error: string;
	debug = false;

	showClassic = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private filesService: FilesService) {
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;
			this.initForm();
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async initForm() {
		// this.theForm = new FormGroup({
		// 	filesToUpload: new FormControl('', [Validators.required])
		// });
	}

	removeItem(idx) {
		this.files.splice(idx, 1);
	}

	async classicUpload(event) {
		await this.processAddedFiles(event.target.files);
	}

	async dragDropUpload(event) {
		await this.processAddedFiles(event.addedFiles);
	}

	async processAddedFiles(files: any[]) {
		for (const selectedFile of files) {
			const fileObj: Models.File = await this.filesService.checkForFile(selectedFile.name);
			let note = '';
			if (fileObj) {
				note = 'A ' + TextTools.formatNumber(fileObj.size) + ' byte file with that name already exists in the system.';
				if (fileObj.is_deleted === 1)
					note += '\nThe previous copy of this file was deleted (masked).  It will be restored and overwritten with this new file.';
				else
					note += '\nThis new file will overwrite the existing one.';
			}

			if (selectedFile.size > AppConstants.maxFileSize) {
				this.uiAlertsService.addMsg('That file (' + selectedFile.name + ') is too large.',
					'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

			} else {
				this.files.push(
					{
						obj: selectedFile,
						note,
						uploading: false,
						complete: false,
						progress: 0,
						lastPing: Date
					});
			}
		}
	}

	async uploadFile(idx: number) {
		if (idx >= this.files.length) return;
		if (this.files[idx].uploading) return;
		if (this.files[idx].complete) return;

		const pingIntervalMins = 5;

		this.files[idx].uploading = true;
		this.files[idx].lastPing = new Date();

		const file = this.files[idx].obj;

		// console.log(file.name);
		// console.log(file.type);

		const presignedPost = await this.filesService.createPresignedPost(file.name);
		// console.log('presignedPost');
		// console.log(presignedPost);

		const formData = new FormData();
		Object.entries(presignedPost.fields).forEach(([k, v]) => {
			formData.append(k, v + '');
		});

		formData.append("file", file);

		this.filesService.doPresignedPost(presignedPost.url, formData).subscribe(async (res) => {
			const event = res;
			switch (event.type) {
				case HttpEventType.Sent: // 0
					if (this.debug) { console.log('HttpEventType.Sent'); }
					break;

				case HttpEventType.UploadProgress: // 1
					this.files[idx].progress = Math.round(100 * event.loaded / event.total);

					let minsSinceLastPing: number = 0;
					if (this.files[idx].lastPing)
						minsSinceLastPing = ((new Date()).getTime() - this.files[idx].lastPing.getTime()) / 1000 / 60;

					if (minsSinceLastPing > pingIntervalMins) {
						this.files[idx].lastPing = new Date();
						await this.filesService.checkForFile(this.files[idx].obj.name);
					}

					// // if (event.loaded === event.total) {
					// // 	this.files[idx].uploading = false;
					// // 	this.files[idx].complete = true;
					// // }

					if (this.debug) { console.log('(' + file.name + ') HttpEventType.UploadProgress - ' + this.files[idx].progress + '%'); }
					break;

				case HttpEventType.ResponseHeader, HttpEventType.Response: // 2
					if (this.debug) { console.log('(' + file.name + ') HttpEventType.ResponseHeader:'); }
					if (this.debug) { console.log(event); }

					if (event.status === 204) {
						if (this.files[idx].progress === 100) {
							await this.filesService.addFile(this.files[idx].obj.name, this.files[idx].obj.size);
							this.files[idx].uploading = false;
							this.files[idx].complete = true;
						}
						// expected...
					} else if (event.status === 200) {
						this.files[idx].uploading = false;
						this.files[idx].complete = true;
					} else if (event.status === 413) {
						alert('There was a problem with the upload.  Possibly due to the files being too large.');
						this.files[idx].uploading = false;
						this.files[idx].complete = false;
					} else {
						alert('There was a problem with the upload. ' + event.status);
						this.files[idx].uploading = false;
						this.files[idx].complete = false;
					}
					break;

				case HttpEventType.DownloadProgress: // 3
					if (this.debug) { console.log('(' + file.name + ') HttpEventType.DownloadProgress:'); }
					if (this.debug) { console.log(event); }
					break;

				default:
					if (this.debug) { console.log('(' + file.name + ') Unknown event type ' + event.type); }
					if (this.debug) { console.log(event); }
					break;
			}
		}, (err) => this.error = err);
	}

	async uploadAll() {
		for (let i = 0; i < this.files.length; i++)
			if (!this.files[i].uploading && !this.files[i].complete)
				await this.uploadFile(i);
	}

	anyUploading() {
		for (const file of this.files)
			if (file.uploading) return true;
		return false;
	}

	anyNotComplete() {
		for (const file of this.files)
			if (!file.complete) return true;
		return false;
	}

	anyNotStarted() {
		for (const file of this.files)
			if (file.progress === 0) return true;
		return false;
	}

	async finishUp() {
		if (this.files.length > 0) {

			if (this.anyNotStarted() && !confirm('You have not uploaded all the files.  Are you sure you are done?')) return;

			const loopWaitSecs = 5;
			const maxLoops = 60 / loopWaitSecs * 30; // max wait of 30 minutes
			let counter = 0;
			while (this.anyUploading() && !this.anyNotComplete() && counter < maxLoops) {
				counter++;
				// console.log('sleeping ' + loopWaitSecs + ' sec');
				await MiscTools.delay(loopWaitSecs * 1000);
			} // while
		} // if

		// console.log('routing');
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	// get filesToUpload() { return this.theForm.get('filesToUpload'); }
	// get f() { return this.theForm.controls; }
}
