<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.packages" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" routerLink="..">Packages</a>
			</li>
			<li *ngIf="editMode" class="breadcrumb-item active" aria-current="page">
				{{ package.name }}
			</li>
			<li *ngIf="!editMode" class="breadcrumb-item active" aria-current="page">
				New Package
			</li>
		</ol>
	</nav>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-products-platforms-tab" aria-controls="nav-products-platforms" data-bs-target="#nav-products-platforms">
				Products &amp; Platforms
				<span class="cp-tab-badge">
					{{ counter | number }}
				</span>
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<form [formGroup]="theForm" class="tab-form">
		<div class="tab-content" id="nav-tabContent">
			<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">
				<div class="cp-general-width-limit">
					<div class="form-group form-check">
						<input type="checkbox" class="form-check-input" id="is_enabled" formControlName="is_enabled" />
						<label class="form-check-label" for="is_enabled">This package is enabled/active (its users
							can download files).</label>
					</div>

					<div class="form-group form-check">
						<input type="checkbox" class="form-check-input" id="allow_subscribe" formControlName="allow_subscribe" />
						<label class="form-check-label" for="allow_subscribe">Users may subscribe to this package after
							registration.</label>
					</div>

					<div class="form-group">
						<label for="ptype">Type*</label>
						<div class="form-check" *ngFor="let ptype of ac.packageTypes">
							<input class="form-check-input" type="radio" formControlName="ptype" name="ptype" [id]="'ptype_' + ptype"
								[value]="ptype" />
							<label class="form-check-label" [for]="'ptype_' + ptype">
								{{ ac.packageTypeLabels[ptype] }}
							</label>
						</div>
					</div>

					<div class="form-group">
						<label for="name">Name*</label>
						<input type="text" id="name" formControlName="name" class="form-control" />
					</div>

					<div class="form-group">
						<label for="access_code">Access Code*</label>
						<input type="text" id="access_code" formControlName="access_code" class="form-control" />
					</div>

					<div class="form-group">
						<label for="information">Information (internal)</label>
						<textarea id="information" formControlName="information" class="form-control" rows="4"></textarea>
					</div>

					<div class="form-group">
						<label for="user_description">Description</label>
						<textarea id="user_description" formControlName="user_description" class="form-control" rows="4"></textarea>
					</div>
				</div>
			</div>

			<div class="tab-pane" id="nav-products-platforms" role="tabpanel" aria-labelledby="nav-products-platforms-tab">
				<ul class="list-unstyled">
					<li *ngFor="let productType of ac.productTypes" class="mb-3">
						<strong><input type="checkbox" [id]="'ptype_' + productType" (click)="checkAllForProductType($event, productType)"
								class="align-middle mb-1 me-1" />
							<label [for]="'ptype_' + productType" class="mb-0">{{
								ac.productTypeLabels[productType] }}
								Products</label></strong>
						<ul class="list-unstyled ms-3">
							<li *ngFor="let product of subListOfProducts(productType)" class="">
								<input type="checkbox" [id]="'p_' + product.id" (click)="checkAllForProduct($event, product.id)"
									class="align-middle mb-1 me-1" />
								<label [for]="'p_' + product.id" class="mb-0">
									{{ product.name }}</label>
								<ul class="list-inline ms-3">
									<li *ngFor="let platform of subListOfPlatforms(product.id)" class="list-inline-item">
										<label class="form-check-label ps-2 pe-2" [for]="product.id + '_' + platform.id"
											[ngClass]="{ 'cp-selected-primary': isChecked( product.id,platform.id)}">
											<input type="checkbox" [id]="product.id + '_' + platform.id"
												[formControlName]="'pp_' + product.id + '_' + platform.id" class="align-middle mb-1"
												(click)="adjustCounter($event)" />
											{{ platform.name }}
										</label>
									</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>

		<div *ngIf="!loading && theForm" class="cp-form-buttons">
			<div class="cp-form-buttons-inner">
				<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
					<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
				</button>
				<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
					<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
				</button>

				<span class="gj-75 ps-2">* Required</span>
			</div>
		</div>
	</form>
</div>