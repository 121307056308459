import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';


import { ResetPasswordService } from './reset-password.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
// import { PasswordStrengthValidator } from '../../validators/password-strength.validator';
import { LoginService } from '../login/login.service';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
	// 'standard' edit stuff
	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	private _token = '';
	private _email = '';
	supportURL = AppConstants.supportURL;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private resetPasswordService: ResetPasswordService,
		private loginService: LoginService,
		private uiAlertsService: UiAlertsService
	) {
		this.route.paramMap.subscribe(params => {
			this._token = params.get('token');
			this._email = params.get('email');
			this.loading = false;
		});
	}

	ngOnInit(): void {
		// make sure the user's logged out...
		this.loginService.logout();
		this.initForm();
	}

	ngOnDestroy() {
	}

	initForm() {
		this.theForm = new UntypedFormGroup({
			email: new UntypedFormControl(this._email, [Validators.required, Validators.email, Validators.maxLength(255)]),
			password: new UntypedFormControl('', [Validators.required]),
			password2: new UntypedFormControl('', [Validators.required])
		});
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const email = this.theForm.value.email.trim();
		const password = this.theForm.value.password.trim();
		const password2 = this.theForm.value.password2.trim();

		const passwordCheckmsg = TextTools.checkPasswordStrength(password);
		if (password !== password2)
			this.errors.push('Passwords do not match.');
		else if (passwordCheckmsg && passwordCheckmsg !== '')
			this.errors.push(passwordCheckmsg);

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		try {
			const ret = await this.resetPasswordService.resetPassword(email, this._token, password, password2);
			if (ret) {
				this.uiAlertsService.addMsg('Your password has been updated.  Please login with your e-mail and new password.',
					'info', 'LOGIN', false, AppConstants.standardMessageAutoCloseTimeSecs * 6);

				this.router.navigate([AppConstants.urls.login]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}

	}

	onCancel() {
		this.router.navigate(['/login']);
	}

	get email() { return this.theForm.get('email'); }
	get password() { return this.theForm.get('password'); }
	get password2() { return this.theForm.get('password2'); }

}
