import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { LicensingRoutingModule } from './licensing-routing.module';

import { ActivationSearchComponent } from './activation-search/activation-search.component';
import { ActivationFormComponent } from './activation-form/activation-form.component';
import { SetTemplateFormComponent } from './set-template-form/set-template-form.component';
import { OfflineSearchComponent } from './offline-search/offline-search.component';
import { OfflineAddFormComponent } from './offline-add-form/offline-add-form.component';
import { ActivationMultiComponent } from './activation-multi/activation-multi.component';
import { SearchFilterInfoComponent } from './search-filter-info/search-filter-info.component';
import { WriteAccessFormComponent } from './write-access-form/write-access-form.component';
import { CommercialFormComponent } from './commercial-form/commercial-form.component';
import { ActivationMarketplaceFormComponent } from './activation-marketplace-form/activation-marketplace-form.component';

@NgModule({
	declarations: [
		ActivationSearchComponent,
		ActivationFormComponent,
		SetTemplateFormComponent,
		OfflineSearchComponent,
		OfflineAddFormComponent,
		ActivationMultiComponent,
		SearchFilterInfoComponent,
		WriteAccessFormComponent,
		CommercialFormComponent,
		ActivationMarketplaceFormComponent
	],
	imports: [
		LicensingRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		SharedModule,
		FontAwesome,
		ClipboardModule
	]
})
export class LicensingModule { }
