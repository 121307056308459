import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';

import { RouterModule } from '@angular/router';

// Ng Bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Ng Select
import { NgSelectModule } from '@ng-select/ng-select';

// Clipboard
import { ClipboardModule } from 'ngx-clipboard';

// XXXXX
import { DragDropModule } from '@angular/cdk/drag-drop';

// Forms
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

// Other Modules
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FontAwesome } from '../font-awesome/font-awesome.module';

// My Modules/Components
import { LoadingSpinnerComponent } from '../../components/shared/loading-spinner/loading-spinner.component';

import { PagingSearchBarComponent } from '../../components/shared/paging-search-bar/paging-search-bar.component';

import { LogsTableComponent } from '../../components/shared/logs-table/logs-table.component';
import { JournalsTableComponent } from '../../components/shared/journals-table/journals-table.component';
import { LoginsTableComponent } from '../../components/shared/logins-table/logins-table.component';
import { DownloadsTableComponent } from '../../components/shared/downloads-table/downloads-table.component';
import { ClicksTableComponent } from '../../components/shared/clicks-table/clicks-table.component';
import { NotificationsTableComponent } from '../../components/shared/notifications-table/notifications-table.component';
import { DownloadableBuildsTableComponent } from '../../components/shared/downloadable-builds-table/downloadable-builds-table.component';
import { DownloadableDocumentsTableComponent } from '../../components/shared/downloadable-documents-table/downloadable-documents-table.component';
import { UsersTableComponent } from '../../components/shared/users-table/users-table.component';
import { NotificationUsersTableComponent } from '../../components/shared/notification-users-table/notification-users-table.component';
import { OrganizationsTableComponent } from '../../components/shared/organizations-table/organizations-table.component';
import { KeysTableComponent } from '../../components/shared/keys-table/keys-table.component';
import { LicensesTableComponent } from '../../components/shared/licenses-table/licenses-table.component';
import { BuildsTableComponent } from '../../components/shared/builds-table/builds-table.component';
import { DocumentsTableComponent } from '../../components/shared/documents-table/documents-table.component';
import { MarketplaceLogsTableComponent } from '../../components/shared/marketplace-logs-table/marketplace-logs-table.component';
import { KeyFeaturesComponent } from '../../components/shared/key-features/key-features.component';
import { KeyMetersComponent } from '../../components/shared/key-meters/key-meters.component';
import { PopupBoxComponent } from '../../components/shared/popup-box/popup-box.component';


// Pipes
import { BytesPipe } from 'client/app/pipes/bytes.pipe';
import { AmpPipe } from 'client/app/pipes/amp.pipe';

@NgModule({
	declarations: [
		LoadingSpinnerComponent,
		PagingSearchBarComponent,
		LogsTableComponent,
		JournalsTableComponent,
		LoginsTableComponent,
		DownloadsTableComponent,
		ClicksTableComponent,
		NotificationsTableComponent,
		DownloadableBuildsTableComponent,
		DownloadableDocumentsTableComponent,
		UsersTableComponent,
		NotificationUsersTableComponent,
		OrganizationsTableComponent,
		KeysTableComponent,
		LicensesTableComponent,
		BuildsTableComponent,
		DocumentsTableComponent,
		MarketplaceLogsTableComponent,
		KeyFeaturesComponent,
		KeyMetersComponent,
		PopupBoxComponent,
		BytesPipe,
		AmpPipe
	],
	imports: [
		CommonModule,
		NgbModule,
		FormsModule,
		ReactiveFormsModule,
		FontAwesomeModule,
		NgSelectModule,
		ClipboardModule,
		FontAwesome,
		DragDropModule,
		RouterModule
	],
	exports: [
		LoadingSpinnerComponent,
		PagingSearchBarComponent,
		LogsTableComponent,
		JournalsTableComponent,
		LoginsTableComponent,
		DownloadsTableComponent,
		ClicksTableComponent,
		NotificationsTableComponent,
		DownloadableBuildsTableComponent,
		DownloadableDocumentsTableComponent,
		UsersTableComponent,
		NotificationUsersTableComponent,
		OrganizationsTableComponent,
		KeysTableComponent,
		LicensesTableComponent,
		BuildsTableComponent,
		DocumentsTableComponent,
		MarketplaceLogsTableComponent,
		KeyFeaturesComponent,
		KeyMetersComponent,
		PopupBoxComponent,
		CommonModule,
		DecimalPipe,
		BytesPipe,
		AmpPipe
	],
	providers: [
		DecimalPipe,
		BytesPipe,
		AmpPipe
	]
})

export class SharedModule { }
