import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class PartnerAdminService {
	partnerships: Observable<Models.Partnership[]>;
	loaded = false;
	private debug = false;
	private _partnerships: ReplaySubject<Models.Partnership[]>;
	private dataStore: {
		partnerships: Models.Partnership[];
	};

	constructor(private http: HttpClient) {
		this.dataStore = {
			partnerships: []
		};
		this._partnerships = new ReplaySubject(1) as ReplaySubject<Models.Partnership[]>;
		this.partnerships = this._partnerships.asObservable();
	}

	// add/update an item in the datastore
	private updateStore(newPartnership: Models.Partnership): void {
		const idx = this.dataStore.partnerships.findIndex(partnership => partnership.id === newPartnership.id);
		if (idx === -1) {
			this.dataStore.partnerships.push(newPartnership);
			return;
		} else {
			this.dataStore.partnerships[idx] = newPartnership;
		}
	}

	// get a fresh copy of the entire set
	refreshAll(): Observable<Models.Partnership[]> {
		const partnerships$ = this.http.get<Models.Partnership[]>(AppConstants.apiUrl + AppConstants.apiUrls.partneradmin).pipe(share());
		partnerships$.subscribe(
			data => {
				const partnerships: Models.Partnership[] = data;
				// remove ones from the store that aren't in the response (they've been deleted)
				this.dataStore.partnerships.forEach((existingPartnership, existingIndex) => {
					const newIndex = partnerships.findIndex(partnership => partnership.id === existingPartnership.id);
					if (newIndex === -1) this.dataStore.partnerships.splice(existingIndex, 1);
				});
				// add/update all the ones that came back
				partnerships.forEach(partnership => this.updateStore(partnership));
				this._partnerships.next(Object.assign({}, this.dataStore).partnerships);
				this.loaded = true;
			},
			error => { /* console.log(error) */ }
		);
		return partnerships$;
	}

	// get a fresh copy of a single item
	refreshOne(id: number): Observable<Models.Partnership> {
		const partnership$ = this.http.get<Models.Partnership>(AppConstants.apiUrl + AppConstants.apiUrls.partneradmin + '/' + id).pipe(share());
		partnership$.subscribe(
			data => {
				const partnership: Models.Partnership = data;
				// add/update the one that came back
				this.updateStore(partnership);
				this._partnerships.next(Object.assign({}, this.dataStore).partnerships);
			},
			error => { /* console.log(error) */ }
		);
		return partnership$;
	}

	// return the whole list
	getAll(): Models.Partnership[] {
		return this.dataStore.partnerships.slice();
	}

	// grab a single item from the datastore
	getOne(id: number): Models.Partnership {
		return this.dataStore.partnerships.find(partnership => partnership.id === id);
	}

	getPartnershipFieldValues(field: string): string[] {
		const items: string[] = [];
		for (const p of this.dataStore.partnerships) {
			const val = p.extras && p.extras[field] ? p.extras[field] : p.extras[field];
			if (val && val !== '' && !items.includes(val))
				items.push(val);
		}
		items.sort();
		return items;
	}

	// call back-end to add an item
	async addOne(partnership: Models.Partnership) {
		try {
			const result = await this.http.post<Models.Partnership>(AppConstants.apiUrl + AppConstants.apiUrls.partneradmin, partnership).toPromise();
			const returnedPartnership: Models.Partnership = result;
			this.updateStore(returnedPartnership);
			this._partnerships.next(Object.assign({}, this.dataStore).partnerships);
			return returnedPartnership;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update an item
	async updateOne(partnership: Models.Partnership) {
		try {
			const result = await this.http.put<Models.Partnership>(AppConstants.apiUrl + AppConstants.apiUrls.partneradmin + '/' + partnership.id, partnership).toPromise();
			const returnedPartnership: Models.Partnership = result;
			this.updateStore(returnedPartnership);
			this._partnerships.next(Object.assign({}, this.dataStore).partnerships);
			return returnedPartnership;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to delete an item
	async deleteOne(id: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl + AppConstants.apiUrls.partneradmin + '/' + id).toPromise();
			const idx = this.dataStore.partnerships.findIndex(partnership => partnership.id === id);
			if (idx !== -1) this.dataStore.partnerships.splice(idx, 1);
			this._partnerships.next(Object.assign({}, this.dataStore).partnerships);
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// ********************************************************
	// other non-standard methods
	// ********************************************************

	// call back-end to enable/disable
	async toggleListed(id: number) {
		try {
			const result = await this.http.put<Models.Partnership>(AppConstants.apiUrl + AppConstants.apiUrls.partneradmin + '/' + id + '/toggle-listed', {}).toPromise();
			const returnedPartnership: Models.Partnership = result;
			this.updateStore(returnedPartnership);
			this._partnerships.next(Object.assign({}, this.dataStore).partnerships);
			return returnedPartnership;
		} catch (error) {
		}
	}
}
