import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import ValidationTools from 'appshared/validation-tools';

import { PackagesService } from '../packages.service';
import { PlatformsService } from '../../platforms/platforms.service';
import { ProductsService } from '../../products/products.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-package-form',
	templateUrl: './package-form.component.html',
	styleUrls: ['./package-form.component.scss']
})
export class PackageFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	id: number;
	package: Models.Package;
	errors: string[] = [];
	editMode = false;
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;
	private listSubscription: Subscription;

	// other stuff
	allProducts: Models.Product[] = [];
	allPlatforms: Models.Platform[] = [];
	allProductPlatforms: Models.ProductPlatform[] = [];
	counter = 0;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private packagesService: PackagesService,
		private productsService: ProductsService,
		private platformsService: PlatformsService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			if (this.id && this.id !== 0) {
				this.package = this.packagesService.getOne(this.id);
				if (!this.package || this.package == null || this.package.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
				} else {
					this.packagesService.refreshOne(this.id);
					this.editMode = true;
				}
			} else {
				this.package = new Models.Package(0, AppConstants.defaultPackageType, '', '', null, 1);
			}
			this.loadExtras();
			this.loading = false;
		});
	}

	ngOnInit(): void {
		if (this.editMode) {
			this.listSubscription = this.packagesService.packages.subscribe(packages => {
				this.package = packages.find((pkg: Models.Package) => pkg.id === this.id);
				this.initForm();
			});
		} else {
			this.initForm();
		}
	}

	ngOnDestroy() {
		if (this.listSubscription) this.listSubscription.unsubscribe();
	}

	initForm() {
		// using form control names that match object's property names makes
		// saving easier down the line...
		this.theForm = new UntypedFormGroup({
			is_enabled: new UntypedFormControl(this.package.is_enabled),
			allow_subscribe: new UntypedFormControl(this.package.allow_subscribe),
			ptype: new UntypedFormControl(this.package.ptype, [Validators.required]),
			name: new UntypedFormControl(this.package.name, [Validators.required]),
			access_code: new UntypedFormControl(this.package.access_code, [Validators.required]),
			information: new UntypedFormControl(this.package.information),
			user_description: new UntypedFormControl(this.package.user_description)
		});

		// add form controls for each of the check boxes...
		this.counter = 0;
		for (const productType of AppConstants.productTypes) {
			for (const product of this.subListOfProducts(productType)) {
				for (const platform of this.subListOfPlatforms(product.id)) {
					const checked = this.findProductPlatform(this.package.product_platforms, product.id, platform.id) !== -1;
					if (checked) this.counter++;
					this.theForm.addControl('pp_' + product.id + '_' + platform.id, new UntypedFormControl(checked));
				}
			}
		}
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		this.package.is_enabled = this.theForm.value.is_enabled;
		this.package.allow_subscribe = this.theForm.value.allow_subscribe;
		this.package.ptype = this.theForm.value.ptype;
		this.package.name = this.theForm.value.name;
		this.package.access_code = this.theForm.value.access_code;
		this.package.information = this.theForm.value.information;
		this.package.user_description = this.theForm.value.user_description;

		const productPlatforms: Models.ProductPlatform[] = [];
		for (const productType of AppConstants.productTypes) {
			for (const product of this.subListOfProducts(productType)) {
				for (const platform of this.subListOfPlatforms(product.id)) {
					const val = this.theForm.value['pp_' + product.id + '_' + platform.id];
					if (val) {
						productPlatforms.push({ product_id: product.id, platform_id: platform.id });
					}
				}
			}
		}
		this.package.product_platforms = productPlatforms;

		try {
			let retPkg: Models.Package;
			if (this.editMode)
				retPkg = await this.packagesService.updateOne(this.package);
			else
				retPkg = await this.packagesService.addOne(this.package);

			if (retPkg) {
				this.router.navigate([AppConstants.urls.packages + '/' + retPkg.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	loadExtras() {
		this.allPlatforms = this.platformsService.getAll();
		this.allProducts = this.productsService.getAll();
		this.allProductPlatforms = this.productsService.getAllProductPlatforms();
	}

	isChecked(productID: number, platformID: number): boolean {
		// this.theForm.controls['pp_' + productID + '_' + platform.id].setValue(newValue);
		const val = this.theForm.value['pp_' + productID + '_' + platformID];
		return val;
	}

	subListOfProducts(productType: string) {
		const sublist: Models.Product[] = [];
		for (const product of this.allProducts) {
			if (!ValidationTools.hasFlag(product, 'private_only') && product.ptype === productType) {
				sublist.push(product);
			}
		}
		return sublist;
	}

	subListOfPlatforms(productID: number) {
		const sublist: Models.Platform[] = [];
		for (const platform of this.allPlatforms) {
			if (this.findProductPlatform(this.allProductPlatforms, productID, platform.id) !== -1) {
				sublist.push(platform);
			}
		}
		return sublist;
	}

	findProductPlatform(arr: Models.ProductPlatform[], productID: number, platformID: number): number {
		for (const idx in arr) {
			if (arr[idx].product_id === productID && arr[idx].platform_id === platformID) {
				return +idx;
			}
		}
		return -1;
	}

	adjustCounter(e) {
		if (e.target.checked) {
			this.counter++;
		} else {
			this.counter--;
		}
	}

	checkAllForProductType(e, productType) {
		// console.log('productType=' + productType);
		// console.log(e.target.checked);
		for (const product of this.subListOfProducts(productType)) {
			this.setValueForProduct(product.id, e.target.checked);
		}
	}

	checkAllForProduct(e, productID) {
		this.setValueForProduct(productID, e.target.checked);
	}

	setValueForProduct(productID: number, newValue: boolean) {
		// console.log('productID=' + productID);
		// console.log(newValue);
		for (const platform of this.subListOfPlatforms(productID)) {
			const curVal = this.theForm.value['pp_' + productID + '_' + platform.id];
			if (curVal !== newValue) {
				if (newValue) {
					this.counter++;
				} else {
					this.counter--;
				}
				this.theForm.controls['pp_' + productID + '_' + platform.id].setValue(newValue);
			} // if
		}
	}

	get ptype() { return this.theForm.get('ptype'); }
	get name() { return this.theForm.get('name'); }
	get access_code() { return this.theForm.get('access_code'); }
}
