<button type="button" [id]="'open-popup-box-modal' + uniqueId" [hidden]="true" data-bs-toggle="modal"
	[attr.data-bs-target]="'#popup-box-modal' + uniqueId">
</button>
<div class="modal" [id]="'popup-box-modal' + uniqueId" data-backdrop="static" tabindex="-1" aria-hidden="true"
	(keypress)="popUpKeyPress($event)">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">
					<fa-icon *ngIf="icon" [icon]="icon"></fa-icon>
					{{ banner }}
				</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
					[id]="'close-popup-box-modal' + uniqueId"></button>
			</div>

			<div class="modal-body">
				<form *ngIf="popupForm" [formGroup]="popupForm" autocomplete="off">
					<div *ngIf="message && message !== ''" class="cp-form-group-other cp-pre-text"
						[ngClass]="popupMode==='confirm-text'?'text-start':'text-center'">
						<div [innerHTML]="message"></div>
					</div>

					<div *ngIf="popupMode === 'confirm-text'" class="cp-form-group-other">
						If you are sure, type <span class="fw-bold">{{ confirmText }}</span> in the box below and press
						the button.
					</div>

					<div *ngIf="showWrongTextMsg" class="cp-warning-message">
						You must type <span class="fw-bold">{{ confirmText }}</span> in the box below.
					</div>

					<div *ngIf="popupMode === 'confirm-text'" class="cp-form-floating">
						<input type="text" id="confirmation" formControlName="confirmation" class="form-control" required />
						<label for="confirmation">Enter '{{ confirmText }}' to confirm</label>
					</div>

					<div class="text-center mt-2">
						<button *ngIf="confirmText && confirmText === 'delete'" type="button" (click)="confirmAction()"
							class="cp-form-delete-button">
							<fa-icon [icon]="ac.actionIcons.delete"></fa-icon>
							{{ confirmButtonText }}
						</button>

						<button *ngIf="!confirmText || confirmText !== 'delete'" type="button" (click)="confirmAction()"
							class="cp-form-submit-button">
							<fa-icon [icon]="['fas', 'check']"></fa-icon>
							{{ confirmButtonText }}
						</button>

						<button *ngIf="popupMode !== 'alert'" type="button" (click)="reject()" class="cp-form-cancel-button">
							<fa-icon [icon]="['fas', 'xmark']"></fa-icon>
							{{ rejectButtonText }}
						</button>
					</div>

					<!-- <div class="gj-75 text-center mt-2 pt-2 border-top">
						<span class="text-info-emphasis">enter/return
							key</span> to copy the character
					</div> -->
				</form>
			</div>
		</div>
	</div>
</div>

<div [hidden]="!showAlternatePopup" class="position-fixed top-50 start-50 translate-middle" style="z-index: 1900">
	<div class="row justify-content-center">
		<div class="col-auto">
			<div class="border border-white p-2 m-2 cp-gray-bg rounded-2" style="min-width:300px;">
				<div class="text-center">
					<fa-icon *ngIf="icon" [icon]="icon"></fa-icon>
					{{ banner }}
				</div>

				<div *ngIf="message && message !== ''" class="cp-form-group-other text-center">
					<div [innerHTML]="message"></div>
				</div>

				<div class="text-center mt-2">
					<button *ngIf="confirmText && confirmText === 'delete'" type="button" (click)="confirmAction()"
						class="cp-form-delete-button">
						<fa-icon [icon]="ac.actionIcons.delete"></fa-icon>
						{{ confirmButtonText }}
					</button>

					<button *ngIf="!confirmText || confirmText !== 'delete'" type="button" (click)="confirmAction()"
						class="cp-form-submit-button">
						<fa-icon [icon]="['fas', 'check']"></fa-icon>
						{{ confirmButtonText }}
					</button>

					<button *ngIf="popupMode !== 'alt-alert'" type="button" (click)="reject()" class="cp-form-cancel-button">
						<fa-icon [icon]="['fas', 'xmark']"></fa-icon>
						{{ rejectButtonText }}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>