<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.licensingadmin" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Licensing
			</li>
			<li class="breadcrumb-item">
				<a href="javascript:void(0)" (click)="onCancel()">Products</a>
			</li>
			<li *ngIf="editMode" class="breadcrumb-item active" aria-current="page">
				Edit Product
			</li>
			<li *ngIf="!editMode" class="breadcrumb-item active" aria-current="page">
				New Product
			</li>
		</ol>
	</nav>
</div>

<form *ngIf="!loading" [formGroup]="theForm" autocomplete="false">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div class="form-group">
			<label for="name">
				Name*
				<fa-icon [icon]="['fas', 'question-circle']" ngbTooltip="Internal value for the 'product' column in the database."
					placement="end"></fa-icon>
			</label>
			<input type="text" id="name" formControlName="name" class="form-control" />
		</div>

		<div class="form-group">
			<label for="label">
				Label (for staff)*
				<fa-icon [icon]="['fas', 'question-circle']" ngbTooltip="Label for a key's product that\'s shown to staff."
					placement="end"></fa-icon>
			</label>
			<input type="text" id="label" formControlName="label" class="form-control" />
		</div>

		<div class="form-group">
			<label for="basic_label">
				Label (for basic users)*
				<fa-icon [icon]="['fas', 'question-circle']" ngbTooltip="Label for a key's product that\'s shown to non-staff."
					placement="end"></fa-icon>
			</label>
			<input type="text" id="basic_label" formControlName="basic_label" class="form-control" />
		</div>

		<div class="form-group">
			<div class="form-group">
				<label for="description">
					Description
					<!-- <fa-icon [icon]="['fas', 'question-circle']" placement="end"></fa-icon> -->
				</label>
				<textarea id="description" formControlName="description" class="form-control" rows="4"></textarea>
			</div>
		</div>

		<div class="form-group">
			<label for="show_meters">
				Meter Stats*
				<fa-icon [icon]="['fas', 'question-circle']"
					ngbTooltip="Does this key product support meters and if it does, should the meter data be shown to non-staff."
					placement="end"></fa-icon>
			</label>
			<div class="form-check ms-2" *ngFor="let keyProductStatsValue of ac.keyProductStatsValues; let i = index">
				<input class="form-check-input" type="radio" formControlName="show_meters" name="show_meters" [id]="'show_meters' + i"
					[value]="i" />
				<label class="form-check-label" [for]="'show_meters' + i">{{ keyProductStatsValue }}</label>
			</div>
		</div>

		<div class="form-group">
			<label for="show_protocols">
				Protocol Stats*
				<fa-icon [icon]="['fas', 'question-circle']"
					ngbTooltip="Does this key product support protocol usage data and if it does, should that data be shown to non-staff."
					placement="end"></fa-icon>
			</label>
			<div class="form-check ms-2" *ngFor="let keyProductStatsValue of ac.keyProductStatsValues; let i = index">
				<input class="form-check-input" type="radio" formControlName="show_protocols" name="show_protocols"
					[id]="'show_protocols' + i" [value]="i" />
				<label class="form-check-label" [for]="'show_protocols' + i">{{ keyProductStatsValue }}</label>
			</div>
		</div>

		<div class="form-group">
			<label for="meter_products">
				Meter Products
				<fa-icon [icon]="['fas', 'question-circle']" ngbTooltip="Types of meters supported." placement="end">
				</fa-icon>
			</label>
			<ng-select [items]="meterProductSelections" bindValue="value" bindLabel="label" id="meter_products"
				formControlName="meter_products" [multiple]="true">
			</ng-select>
		</div>

		<div class="form-group">
			<label for="build_product_id">
				Build Product*
				<fa-icon [icon]="['fas', 'question-circle']" ngbTooltip="TBD - potentially to be used for suggesting upgrades."
					placement="end"></fa-icon>
			</label>
			<ng-select [items]="products" bindValue="id" bindLabel="name" id="build_product_id" formControlName="build_product_id">
			</ng-select>
		</div>

		<div class="form-group">
			<label for="license_filename">
				Name for License File
				<fa-icon [icon]="['fas', 'question-circle']" ngbTooltip="Name of the license file generated from these keys."
					placement="end"></fa-icon>
			</label>
			<input type="text" id="license_filename" formControlName="license_filename" class="form-control" />
		</div>

		<div class="form-group">
			<label for="min_version">
				Minimum Version
				<fa-icon [icon]="['fas', 'question-circle']" ngbTooltip="Minimum product version (eg 11.0.0) support for these keys."
					placement="end">
				</fa-icon>
			</label>
			<input type="text" id="min_version" formControlName="min_version" class="form-control" />
		</div>

		<div class="form-group">
			<label for="fulfillment_version">
				License Version
				<fa-icon [icon]="['fas', 'question-circle']" ngbTooltip="Version that goes into the license file (fullfillment)."
					placement="end"></fa-icon>
			</label>
			<input type="text" id="fulfillment_version" formControlName="fulfillment_version" class="form-control" />
		</div>

		<div class="form-group">
			<label for="fulfillment_product">
				License Product
				<fa-icon [icon]="['fas', 'question-circle']" ngbTooltip="Product that goes into the license file (fullfillment)."
					placement="end"></fa-icon>
			</label>
			<input type="text" id="fulfillment_product" formControlName="fulfillment_product" class="form-control" />
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="include_refresh" formControlName="include_refresh" />
			<label class="form-check-label" for="include_refresh">
				License file includes 'refresh_key' entry
				<fa-icon [icon]="['fas', 'question-circle']" ngbTooltip="Should the license file include 'refresh_key'."
					placement="end"></fa-icon>
			</label>
		</div>

		<!-- <div class="form-group mb-2 small">* Required</div>

		<div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div>

		<div class="cp-form-padding"></div> -->

	</div>

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>

</form>