import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share, map, tap } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class MyDocumentsService {
	private documents: Models.DownloadableDocument[] = null;

	constructor(private http: HttpClient) { }

	wipe() {
		this.documents = null;
	}


	async getDocuments(forceRefresh = false) {
		if (!this.documents || forceRefresh)
			this.documents = await this.fetchDocuments();
		return this.documents.slice();
	}

	async fetchDocuments() {
		try {
			const result = await this.http.get<Models.DownloadableDocument[]>(AppConstants.apiUrl + AppConstants.apiUrls.downloads + '/documents').toPromise();
			const arr: Models.DownloadableDocument[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// grab a single item from the datastore
	async getOneDocument(id: number) {
		if (!this.documents) this.documents = await this.fetchDocuments();
		if (!this.documents) return null;
		return this.documents.find(document => document.document.id === id);
	}

	async getProductDocuments(productID: number) {
		if (!this.documents) this.documents = await this.fetchDocuments();
		const docs: Models.DownloadableDocument[] = [];
		for (const doc of this.documents) {
			if (doc.document.product_id === productID)
				docs.push(doc);
		}
		return docs;
	}

	async fetchDocumentsForUserFromDB(id: number) {
		try {
			const result = await this.http.get<Models.DownloadableDocument[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.downloads + '/documents?userid=' + id).toPromise();
			const arr: Models.DownloadableDocument[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchDocumentsForOrgFromDB(id: number) {
		try {
			const result = await this.http.get<Models.DownloadableDocument[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.downloads + '/documents?orgid=' + id).toPromise();
			const arr: Models.DownloadableDocument[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchDocumentsForGroupFromDB(id: number) {
		try {
			const result = await this.http.get<Models.DownloadableDocument[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.downloads + '/documents?groupid=' + id).toPromise();
			const arr: Models.DownloadableDocument[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchDocumentsForPackageFromDB(id: number) {
		try {
			const result = await this.http.get<Models.DownloadableDocument[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.downloads + '/documents?packageid=' + id).toPromise();
			const arr: Models.DownloadableDocument[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getOpenLink(id: number, dltype: string = '', refid: number = 0) {
		let url: string = AppConstants.apiUrl
			+ AppConstants.apiUrls.downloads + '/' + id + '/open'
			+ '?dltype=' + encodeURIComponent(dltype)
			+ '&refid=' + refid;
		return url;
	} // 

	async getDownloadLink(id: number, dltype: string = '', refid: number = 0) {
		try {
			let url: string = AppConstants.apiUrl
				+ AppConstants.apiUrls.downloads + '/' + id + '/link'
				+ '?dltype=' + encodeURIComponent(dltype)
				+ '&refid=' + refid;
			const result = await this.http.get<Models.DownloadLink>(url).toPromise();
			const linkObj: Models.DownloadLink = result;
			return linkObj;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

}
