import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';
import { UserComponent } from './user/user.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserFormComponent } from './user-form/user-form.component';
import { AddLicenseKeysForUserFormComponent } from './add-license-keys-for-user-form/add-license-keys-for-user-form.component';
import { UserAccessFormComponent } from './user-access-form/user-access-form.component';
import { AccessSummaryComponent } from './access-summary/access-summary.component';
import { UserNotificationsFormComponent } from './user-notifications-form/user-notifications-form.component';

import { UserGroupComponent } from './user-group/user-group.component';
import { UserGroupListComponent } from './user-group-list/user-group-list.component';
import { UserGroupFormComponent } from './user-group-form/user-group-form.component';
import { UserGroupAccessFormComponent } from './user-group-access-form/user-group-access-form.component';

import { UsersResolverService } from './users-resolver.service';
import { OrganizationsResolverService } from '../organizations/organizations-resolver.service';
import { PackagesResolverService } from '../packages/packages-resolver.service';
import { UserGroupsResolverService } from './user-groups-resolver.service';
import { ProductsResolverService } from '../products/products-resolver.service';
import { ZenmasterResolverService } from '../zen-customers/zenmaster-resolver.service';

const routes: Routes = [
	{
		path: 'groups',
		component: UserGroupListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UserGroupsResolverService]
	}, {
		path: 'groups/new',
		component: UserGroupFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UserGroupsResolverService, UsersResolverService]
	}, {
		path: 'groups/:id',
		component: UserGroupComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UserGroupsResolverService, UsersResolverService]
	}, {
		path: 'groups/:id/edit',
		component: UserGroupFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UserGroupsResolverService, UsersResolverService]
	}, {
		path: 'groups/:id/access',
		component: UserGroupAccessFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UserGroupsResolverService]
	}, {
		path: '',
		component: UserListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, PackagesResolverService, OrganizationsResolverService]
	}, {
		path: 'access-summary',
		component: AccessSummaryComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, UserGroupsResolverService]
	}, {
		path: 'new',
		component: UserFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, OrganizationsResolverService]
	}, {
		path: 'new/:orgid',
		component: UserFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, OrganizationsResolverService]
	}, {
		path: ':id',
		component: UserComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, PackagesResolverService, OrganizationsResolverService, UserGroupsResolverService, ProductsResolverService, ZenmasterResolverService]
	}, {
		path: ':id/edit',
		component: UserFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, OrganizationsResolverService]
	}, {
		path: ':id/add-keys',
		component: AddLicenseKeysForUserFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, OrganizationsResolverService]
	}, {
		path: ':id/access',
		component: UserAccessFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, OrganizationsResolverService]
	}, {
		path: ':id/notifications',
		component: UserNotificationsFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, OrganizationsResolverService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class UsersRoutingModule { }
