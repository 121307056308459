import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share, map, tap } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class MyBuildsService {

	private builds: Models.DownloadableBuild[] = null;

	constructor(private http: HttpClient) { }

	wipe() {
		this.builds = null;
	}

	async getBuilds(forceRefresh = false) {
		if (!this.builds || forceRefresh)
			this.builds = await this.fetchBuilds();
		return this.builds.slice();
	}

	async fetchBuilds() {
		try {
			const result = await this.http.get<Models.DownloadableBuild[]>(AppConstants.apiUrl + AppConstants.apiUrls.downloads + '/builds').toPromise();
			const arr: Models.DownloadableBuild[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// grab a single item from the datastore
	async getOneBuild(id: number) {
		if (!this.builds) this.builds = await this.fetchBuilds();
		if (!this.builds) return null;
		return this.builds.find(build => build.build.id === id);
	}

	async fetchBuildsForUserFromDB(id: number) {
		try {
			const result = await this.http.get<Models.DownloadableBuild[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.downloads + '/builds?userid=' + id).toPromise();
			const arr: Models.DownloadableBuild[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchBuildsForOrgFromDB(id: number) {
		try {
			const result = await this.http.get<Models.DownloadableBuild[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.downloads + '/builds?orgid=' + id).toPromise();
			const arr: Models.DownloadableBuild[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchBuildsForGroupFromDB(id: number) {
		try {
			const result = await this.http.get<Models.DownloadableBuild[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.downloads + '/builds?groupid=' + id).toPromise();
			const arr: Models.DownloadableBuild[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchBuildsForPackageFromDB(id: number) {
		try {
			const result = await this.http.get<Models.DownloadableBuild[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.downloads + '/builds?packageid=' + id).toPromise();
			const arr: Models.DownloadableBuild[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getDownloadLink(id: number, dltype: string = '', refid: number = 0) {
		try {
			const result = await this.http.get<Models.DownloadLink>(AppConstants.apiUrl
				+ AppConstants.apiUrls.downloads + '/' + id + '/link'
				+ '?dltype=' + encodeURIComponent(dltype)
				+ '&refid=' + refid).toPromise();
			const linkObj: Models.DownloadLink = result;
			return linkObj;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

}
