<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.licensing" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a [routerLink]="['/' + ac.urls.licensing]">Key Search</a>
			</li>
			<li *ngIf="activation" class="breadcrumb-item">
				{{ activation.key }}
			</li>
			<li class="breadcrumb-item active" aria-current="page">Offline Licenses</li>
		</ol>
	</nav>
</div>

<div *ngIf="loading || saving" style="text-align: center;">
	<app-loading-spinner></app-loading-spinner>
</div>

<form *ngIf="theForm && !loading" [formGroup]="theForm">
	<div class="cp-general-width-limit">
		<div class="form-group">
			<label for="hostids">Host/ZLM ID(s)*</label>
			<textarea id="hostids" formControlName="hostids" class="form-control" rows="8"></textarea>
		</div>

		<div class="form-group">
			Enter host ID(s) or ZLM ID(s) (for ZLM licenses) in the box above. One per line.
			<!-- You can add a maximum of {{ ac.maxOfflineHostIDsPerShot }} each time. -->
		</div>

		<div class="form-check ms-1 mb-1" *ngFor="let licenseFormatSelection of licenseFormatSelections; let i = index">
			<input class="form-check-input" type="radio" formControlName="format" name="format" [id]="'format_' + i"
				[value]="licenseFormatSelection.value" />
			<label class="form-check-label" [for]="'format_' + i">
				{{ licenseFormatSelection.label }}
			</label>
		</div>

		<!-- <div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="useAlternateServer"
				formControlName="useAlternateServer" />
			<label class="form-check-label" for="useAlternateServer">Use Alternative License Back-end
				(experimental)</label>
		</div> -->

		<!-- <div class="form-group mb-2 small">* Required</div>

		<div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'link']"></fa-icon> Generate Licenses
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div> -->
	</div>
</form>

<div class="cp-form-padding"></div>

<div *ngIf="!loading && theForm" class="cp-form-buttons">
	<div class="cp-form-buttons-inner">
		<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
			<fa-icon [icon]="['fas', 'link']"></fa-icon> Generate Licenses
		</button>
		<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
			<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
		</button>

		<span class="gj-75 ps-2">* Required</span>
	</div>
</div>