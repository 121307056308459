import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Params, ActivatedRoute, Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';
import LicenseValidationTools from 'appshared/license-validation-tools';
import PopOverTools from 'appshared/popover-tools';

import { AuthService } from 'client/app/services/auth.service';
import { DashboardService } from '../dashboard.service';
import { MyBuildsService } from '../../my-builds/my-builds.service';
import { MyDocumentsService } from '../../my-documents/my-documents.service';
import { MyKeysService } from '../../my-keys/my-keys.service';
import { LinkLogsService } from '../../link-logs/link-logs.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { LicensingAdminService } from '../../licensing-admin/licensing-admin.service';

@Component({
	selector: 'app-main-dashboard',
	templateUrl: './main-dashboard.component.html',
	styleUrls: ['./main-dashboard.component.scss']
})
export class MainDashboardComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	popOverTools = PopOverTools;
	miscTools = MiscTools;

	refreshing = true;
	private userSubscription: Subscription;
	private authUser: Models.AuthUser;

	isStaff = false;
	showWelcome = false;

	splitDownloads = false;

	allBuilds: Models.DownloadableBuild[] = [];
	allDocuments: Models.DownloadableDocument[] = [];

	buildsToShow: Models.DownloadableBuild[] = [];
	documentsToShow: Models.DownloadableDocument[] = [];

	systemMessages: Models.SystemMessage[] = [];
	recentAckedMessages: Models.SystemMessage[] = [];
	messagesToShow: Models.SystemMessage[] = [];
	canAckAll = false;

	generalNotifications: string[] = [];
	generalWarnings: string[] = [];

	userKeys: Models.UserLicenseKey[] = [];
	keysWithWarnings: Models.UserLicenseKey[] = [];
	keyMessages = {};

	keyProducts: Models.LicenseProduct[] = [];

	// favoriteOrgsDownloading: Models.Organization[] = [];

	// private recentItemsDays = 30;
	// private maxItemsDays = 120;
	// private recentCount = 10;

	// private keyWarningDays = 30;

	restoreButtonPopup = 'Recover messages acknowledged/dismissed over the last ' + AppConstants.dashboardMessageRecoverDays + ' days';

	showVolume: boolean = false;
	audioLevel: number = 5;

	constructor(
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private dashboardService: DashboardService,
		private myBuildsService: MyBuildsService,
		private myDocumentsService: MyDocumentsService,
		private licensingAdminService: LicensingAdminService,
		private myKeysService: MyKeysService,
		private linkLogsService: LinkLogsService
	) { }

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.authUser = authUser;
			if (authUser) {
				this.isStaff = ValidationTools.checkRole(authUser.role, AppConstants.staffUserRole);
				this.showWelcome = !localStorage.getItem('cp-dashboard.showWelcome') || localStorage.getItem('cp-dashboard.showWelcome') !== 'false';

				this.showVolume = ValidationTools.checkRole(authUser.role, AppConstants.adminUserRole);

				if (localStorage.getItem(AppConstants.volumeStorageKey)) {
					this.audioLevel = +localStorage.getItem(AppConstants.volumeStorageKey);
					if (isNaN(this.audioLevel)) this.audioLevel = 0;
				} // if

				this.loadData();
			}
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData(forceRefresh = false) {
		this.refreshing = true;

		this.dashboardService.checkVersion();

		this.keyProducts = await this.licensingAdminService.getProducts();

		this.userKeys = await this.myKeysService.getUserKeys(false, forceRefresh);
		this.prepKeys();

		this.systemMessages = await this.dashboardService.getSystemMessages(false, forceRefresh);
		this.prepMessages();
		this.recentAckedMessages = await this.dashboardService.getSystemMessages(true, forceRefresh);

		this.generalNotifications = await this.dashboardService.getGeneralNotifications(forceRefresh);
		this.generalWarnings = await this.dashboardService.getGeneralWarnings(forceRefresh);

		this.allBuilds = await this.myBuildsService.getBuilds(forceRefresh);
		this.prepBuilds();

		this.allDocuments = await this.myDocumentsService.getDocuments(forceRefresh);
		this.prepDocs();

		this.refreshing = false;
	}

	prepBuilds() {
		this.allBuilds.sort((a, b) => (a.build.id < b.build.id) ? 1 : -1);

		this.buildsToShow = [];

		const now = new Date();
		for (const dBuild of this.allBuilds) {
			const addedOn = new Date(dBuild.build.added_on);
			const daysSinceAdded = (now.getTime() - addedOn.getTime()) / 1000 / 60 / 60 / 24;
			if (daysSinceAdded < AppConstants.dashboardRecentItemsDays
				|| ((this.buildsToShow.length) < AppConstants.dashboardRecentCount
					&& daysSinceAdded < AppConstants.dashboardMaxItemsDays))
				this.buildsToShow.push(dBuild);
		} // for
	}

	prepDocs() {
		this.allDocuments.sort((a, b) => ((new Date(a.document.edited_on)).getTime() < (new Date(b.document.edited_on)).getTime()) ? 1 : -1);

		this.documentsToShow = [];
		const now = new Date();
		for (const dDocument of this.allDocuments) {
			const editedOn = new Date(dDocument.document.edited_on);
			const daysSinceEdit = (now.getTime() - editedOn.getTime()) / 1000 / 60 / 60 / 24;

			if (daysSinceEdit < AppConstants.dashboardRecentItemsDays
				|| ((this.documentsToShow.length) < AppConstants.dashboardRecentCount
					&& daysSinceEdit < AppConstants.dashboardMaxItemsDays))
				this.documentsToShow.push(dDocument);
		} // for
	}

	prepKeys() {
		this.keysWithWarnings = [];
		this.keyMessages = {};
		for (const userKey of this.userKeys) {
			const warnings = LicenseValidationTools.getKeyWarnings(userKey.activation, 'dashboard');
			if (warnings.length > 0) {
				this.keysWithWarnings.push(userKey);
				this.keyMessages[userKey.id] = warnings;
			}
		}
	}

	prepMessages() {
		this.systemMessages.sort((a, b) => ((new Date(a.added_on)).getTime() < (new Date(b.added_on)).getTime()) ? 1 : -1);
		this.canAckAll = false;
		for (const systemMessage of this.systemMessages) {
			if (systemMessage.can_ack === 1) this.canAckAll = true;
		}

		this.messagesToShow = this.systemMessages.slice();
	}

	async refresh() {
		this.refreshing = true;
		this.loadData(true);
	}

	async ackMessage(systemMessage: Models.SystemMessage) {
		await this.dashboardService.ackMessage(systemMessage);
		this.systemMessages = await this.dashboardService.getSystemMessages(false, true);
		this.prepMessages();

		this.recentAckedMessages = await this.dashboardService.getSystemMessages(true, true);
	}

	async ackAll() {
		if (confirm('Are you sure?')) {
			this.refreshing = true;
			for (const systemMessage of this.systemMessages) {
				if (+systemMessage.can_ack === 1) {
					await this.dashboardService.ackMessage(systemMessage);
				}
			} // for

			this.systemMessages = await this.dashboardService.getSystemMessages(false, true);
			this.prepMessages();

			this.recentAckedMessages = await this.dashboardService.getSystemMessages(true, true);
			this.refreshing = false;
		}
	}

	async unAckRecentMessages() {
		this.refreshing = true;
		for (const systemMessage of this.recentAckedMessages) {
			await this.dashboardService.unAckMessage(systemMessage);
		}
		this.recentAckedMessages = [];

		this.systemMessages = await this.dashboardService.getSystemMessages(false, true);
		this.prepMessages();
		this.refreshing = false;
	}

	getPlatformIDsFromPlatformFiles(platformFiles: Models.PlatformFile[]): number[] {
		const ids = [];
		for (const pf of platformFiles)
			ids.push(pf.platform_id);
		return ids;
	}

	openLink(url: string) {
		if (url.startsWith('/')) {
			this.router.navigate([url]);
		} else {
			window.open(url, '_blank');
			this.linkLogsService.addOne(new Models.LinkLog(0, null, url, ''));
		}
	}

	testMsg(closeTime: number = 0) {
		this.uiAlertsService.addMsg('This is a test.  This is a vert long message that should wrap. So there.\nAnd there.', 'warning', '', false, closeTime);

	}

	setShowWelcome(opened: boolean) {
		this.showWelcome = opened;
		if (this.showWelcome)
			localStorage.setItem('cp-dashboard.showWelcome', 'true');
		else
			localStorage.setItem('cp-dashboard.showWelcome', 'false');
	}

	// --------------------------------------------------------------------
	setVolume(newVolume: number) {
		if (newVolume < 0) newVolume = 0;
		if (newVolume > 10) newVolume = 10;
		this.audioLevel = newVolume;
		localStorage.setItem(AppConstants.volumeStorageKey, this.audioLevel.toString());
		if (this.audioLevel > 0) PopOverTools.playSound('Air Plane Ding-SoundBible.com-496729130.mp3', newVolume);
	} // setVolume

	// --------------------------------------------------------------------
	setVolumeFromRange() {
		let newVolume: number = 0;
		if (document.getElementById('volumeSlider') && document.getElementById('volumeSlider')['value'])
			newVolume = +document.getElementById('volumeSlider')['value'];
		if (isNaN(newVolume)) newVolume = 0;
		this.setVolume(newVolume);
	} // setVolume
}
