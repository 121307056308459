<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.users" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" routerLink="..">Users</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				Access Summary
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
		</div>
	</div>
</div>

<div class="page-wrapper">
	<div class="row">
		<div class="col-5 border p-2 overflow-auto" style="max-height: calc(100vh - 200px);">
			Click on an access privilege to see users, groups and roles with access.
			<hr class="m-0 p-0" />

			<div *ngFor="let accessControlTagGrouping of ac.accessControlTagGroupings" class="mt-1">
				<span class="fw-bold">{{ accessControlTagGrouping.grouping }}</span>
				<div *ngFor="let tag of accessControlTagGrouping.tags" class="ms-4 ps-1 pe-1 cp-pointer" (click)="selectTag(tag)"
					[ngClass]="{'cp-selected-primary': selectedTag === tag}">
					{{ ac.accessControlTags[tag] }}
				</div>
			</div>
		</div>

		<div *ngIf="selectedTag !== ''" class="col-5 p-2 ms-2 border overflow-auto" style="max-height: calc(100vh - 200px);">
			Access Information for:<br />
			<span class="fw-bold">
				{{ ac.accessControlTags[selectedTag] }}
			</span>
			<span class="fw-bold">
				<hr class="mt-2 mb-0 p-0" />
				User Groups with this Access
			</span>
			<div *ngFor="let group of userGroupsToShow" class="ms-2">
				<a [routerLink]="['/' + ac.urls.usergroups, group.id]">{{ group.name }}</a>
				<fa-icon *ngIf="group.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
					ngbTooltip="Group is not enabled"></fa-icon>

				<fa-icon *ngIf="group.user_ids.length > 0 && showUsersInGroup[group.id] === 'no'" [icon]="['fas', 'plus-square']"
					class="ms-1" (click)="toggleUsersInGroup(group.id)" ngbTooltip="Show Users in this Group"></fa-icon>
				<fa-icon *ngIf="group.user_ids.length > 0 && showUsersInGroup[group.id] === 'yes'" [icon]="['fas', 'minus-square']"
					class="ms-1" (click)="toggleUsersInGroup(group.id)" ngbTooltip="Hide Users in this Group"></fa-icon>

				<div *ngIf="showUsersInGroup[group.id] && showUsersInGroup[group.id] === 'yes'" class="ms-2">
					<div *ngFor="let user of usersInGroup[group.id]">
						<a [routerLink]="['/' + ac.urls.users, user.id]" [ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
							triggers="mouseenter:mouseleave" container="body">
							{{ user.name }}
						</a>
						<fa-icon *ngIf="user.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
							ngbTooltip="User is not enabled"></fa-icon>
						<ng-template #userPopupContent>
							<div class="cp-popover-banner">{{ user.name }}</div>
							<div class="cp-pre-text">{{
								popOverTools.getUserPopoverLines(user).join('\n')}}</div>
						</ng-template>
					</div>
				</div>
			</div>
			<div *ngIf="userGroupsToShow.length === 0">None</div>

			<span class="fw-bold">
				<hr class="mt-2 mb-0 p-0" />
				Users with this Access
			</span>
			<div *ngFor="let user of usersToShow" class="ms-2">
				<a [routerLink]="['/' + ac.urls.users, user.id]" [ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
					triggers="mouseenter:mouseleave" container="body">
					{{ user.name }}
				</a>
				<fa-icon *ngIf="user.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
					ngbTooltip="User is not enabled"></fa-icon>
				<ng-template #userPopupContent>
					<div class="cp-popover-banner">{{ user.name }}</div>
					<div class="cp-pre-text">{{
						popOverTools.getUserPopoverLines(user).join('\n')}}</div>
				</ng-template>
			</div>
			<div *ngIf="usersToShow.length === 0">None</div>

			<span class="fw-bold">
				<hr class="mt-2 mb-0 p-0" />
				Roles with this Access
			</span>
			<div class="ms-2">{{ adminRoleLabel }}
				<fa-icon *ngIf="!showAdminUsers" [icon]="['fas', 'plus-square']" class="ms-1" (click)="toggleAdminUsers()"
					ngbTooltip="Show Users with this Role"></fa-icon>
				<fa-icon *ngIf="showAdminUsers" [icon]="['fas', 'minus-square']" class="ms-1" (click)="toggleAdminUsers()"
					ngbTooltip="Show Users with this Role"></fa-icon>

				<div *ngIf="showAdminUsers" class="ms-2">
					<div *ngFor="let user of adminUsers">
						<a [routerLink]="['/' + ac.urls.users, user.id]" [ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
							triggers="mouseenter:mouseleave" container="body">
							{{ user.name }}
						</a>
						<fa-icon *ngIf="user.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
							ngbTooltip="User is not enabled"></fa-icon>
						<ng-template #userPopupContent>
							<div class="cp-popover-banner">{{ user.name }}</div>
							<div class="cp-pre-text">{{
								popOverTools.getUserPopoverLines(user).join('\n')}}</div>
						</ng-template>
					</div>
				</div>
			</div>

		</div>

	</div>