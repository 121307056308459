import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';

import { SystemMessageComponent } from './system-message/system-message.component';
import { SystemMessagesListComponent } from './system-messages-list/system-messages-list.component';
import { SystemMessageFormComponent } from './system-message-form/system-message-form.component';
import { GeneralNotificationFormComponent } from './general-notification-form/general-notification-form.component';

import { SystemMessagesResolverService } from './system-messages-resolver.service';
import { UsersResolverService } from '../users/users-resolver.service';

const routes: Routes = [
	{
		path: '',
		component: SystemMessagesListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [SystemMessagesResolverService]
	}, {
		path: 'new',
		component: SystemMessageFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [SystemMessagesResolverService]
	}, {
		path: 'notify',
		component: GeneralNotificationFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService]
	}, {
		path: ':id',
		component: SystemMessageComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [SystemMessagesResolverService, UsersResolverService]
	}, {
		path: ':id/edit',
		component: SystemMessageFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [SystemMessagesResolverService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SystemMessagesRoutingModule { }
