import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';
import { AppSettingsComponent } from './app-settings.component';
import { AppSettingsFormComponent } from './app-settings-form/app-settings-form.component';

const routes: Routes = [
	{ path: '', component: AppSettingsComponent, canActivate: [RoleGuard], data: { role: AppConstants.staffUserRole } },
	{ path: 'edit', component: AppSettingsFormComponent, canActivate: [RoleGuard], data: { role: AppConstants.staffUserRole } }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AppSettingsRoutingModule { }
