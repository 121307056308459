import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject, interval, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';

import { PackagesService } from '../packages.service';
import { AuthService } from 'client/app/services/auth.service';

@Component({
	selector: 'app-package-list',
	templateUrl: './package-list.component.html',
	styleUrls: ['./package-list.component.scss']
})
export class PackageListComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' list stuff
	packages: Models.Package[];
	loading = true;
	refreshing = false;
	private listSubscription: Subscription;

	// other stuff
	private userSubscription: Subscription;
	canAdd = false;

	constructor(
		private packagesService: PackagesService,
		private authService: AuthService,
	) { }

	ngOnInit(): void {
		this.loading = true;

		this.listSubscription = this.packagesService.packages.subscribe(packages => {
			this.packages = packages;
			if (this.packages) {
				this.packages.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
				this.loading = false;
			}
		});

		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.canAdd = authUser && ValidationTools.checkAccess(authUser, 'manage-packages');
		});

		// Start List Auto Refresh
		// this.startListRefresh();
	}

	ngOnDestroy() {
		if (this.listSubscription) this.listSubscription.unsubscribe();
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async refresh() {
		this.refreshing = true;
		try {
			await this.packagesService.refreshAll().toPromise();
		} catch (err) {
			// console.error('Caught an error refreshing list');
		}
		this.refreshing = false;
	}
}
