import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import { ConfirmEmailService } from './confirm-email.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { LoginService } from '../login/login.service';

@Component({
	selector: 'app-confirm-email',
	templateUrl: './confirm-email.component.html',
	styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {
	message = '';
	private _token = '';

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private confirmEmailService: ConfirmEmailService,
		private loginService: LoginService,
		private uiAlertsService: UiAlertsService
	) {
		this.route.paramMap.subscribe(params => {
			this._token = params.get('token');
		});
	}

	ngOnInit(): void {
		this.confirmChange();
	}

	async confirmChange() {
		try {
			const ret = await this.confirmEmailService.confirmEmailChange(this._token);
			if (ret) {
				this.message = 'Your e-mail address change has been confirmed.  Please login with your new e-mail address.';
				// this.uiAlertsService.addMsg('Your e-mail address change has been confirmed.  Please login with your new e-mail address.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs * 10);
				this.loginService.logout().subscribe();
			} else {
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			// this.message = e.message;
		}
	}
}
