<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.mykeys" class="cp-user-nav-item me-1" size="sm"></fa-icon>
				<a href="javascript:void(0)" routerLink="..">License Keys</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">Share Your License Keys</li>
		</ol>
	</nav>
</div>

<div *ngIf="loading || saving" style="text-align: center;">
	<app-loading-spinner></app-loading-spinner>
</div>

<form *ngIf="theForm && !loading" [formGroup]="theForm">
	<div class="form-group cp-general-width-limit">
		<label for="user_ids">Users to Share With*</label>
		<ng-select [items]="userSelections" bindValue="id" bindLabel="name" id="user_ids" formControlName="user_ids" [multiple]="true">
		</ng-select>
	</div>

	<div class="form-group mb-0">
		<label>License Keys to Share</label>
	</div>

	<!-- <div *ngFor="let userKey of userKeys" class="form-group form-check mb-1 mt-0">
		<input type="checkbox" class="form-check-input" [id]="'key_' + userKey.id"
			[formControlName]="'key_' + userKey.id" />
		<label class="form-check-label" [for]="'key_' + userKey.id">
			<span class="cp-key fw-bolder">{{ userKey.activation_key }}</span>
			- {{ userKey.activation.count }} / {{ userKey.activation.max }}
			- {{ niceKeyProduct(userKey.activation.product) }}
			<span *ngIf="userKey.label && userKey.label !== ''">- {{ userKey.label }}</span>
		</label>
	</div> -->

	<table class="cp-std-table cp-auto-width">
		<thead>
			<tr>
				<th>-</th>
				<th>Key</th>
				<th>Label</th>
				<th># Act.</th>
				<th>Product</th>
				<th>Type</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let item of userKeys">
				<td><input type="checkbox" [id]="'key_' + item.id" [formControlName]="'key_' + item.id" /></td>
				<td><label class="form-check-label" [for]="'key_' + item.id">{{ item.activation_key }}</label></td>
				<td *ngIf="item.label && item.label !== ''"><label class="form-check-label" [for]="'key_' + item.id">{{
						item.label }}</label></td>
				<td *ngIf="!item.label || item.label === ''" class="font-italic"><label class="form-check-label"
						[for]="'key_' + item.id">Not set</label></td>
				<td class="text-end">{{ item.activation.count | number }} / {{ item.activation.max | number }}</td>
				<td>{{ niceKeyProduct(item.activation.product) }}</td>
				<td>{{ item.activation.type | titlecase }}</td>
			</tr>
		</tbody>
	</table>

	<hr />
	<div class="form-group form-check">
		<input type="checkbox" class="form-check-input" id="notify" formControlName="notify" />
		<label class="form-check-label" for="notify">
			Send user(s) e-mail with information about key(s).
			<fa-icon [icon]="['fas', 'question-circle']"
				ngbTooltip="The e-mail with contain base information about the key(s) - type, product, # activations.  The key itself will be truncated for security."
				placement="bottom-start">
			</fa-icon>
		</label>
	</div>

	<!-- <div class="form-group mb-2 small">* Required</div>

	<div class="form-group mb-0 mt-2" *ngIf="!saving">
		<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
			<fa-icon [icon]="['fas', 'check']"></fa-icon> Share Key(s)
		</button>
		<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
			<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
		</button>
	</div>
	<div class="cp-form-padding"></div> -->

</form>

<div class="cp-form-padding"></div>

<div *ngIf="!loading && theForm" class="cp-form-buttons">
	<div class="cp-form-buttons-inner">
		<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
			<fa-icon [icon]="['fas', 'check']"></fa-icon> Share Key(s)
		</button>
		<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
			<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
		</button>

		<span class="gj-75 ps-2">* Required</span>
	</div>
</div>