import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share, map, tap } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class AdminLogsService {

	constructor(private http: HttpClient) {
	}

	// call back-end to get last X logs
	async getLogs(
		objectTypes: string[] = [], id: number = 0, actions: string[] = [],
		startDate: Date = null, endDate: Date = null, userIDs: number[] = []) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.adminlogs + '?a=b';
			if (objectTypes && objectTypes.length !== 0) url += '&types=' + encodeURIComponent(objectTypes.join(','));
			if (id && id !== 0) url += '&id=' + encodeURIComponent(id);
			if (actions && actions.length !== 0) url += '&actions=' + encodeURIComponent(actions.join(','));

			if (startDate && startDate !== null) url += '&startDate=' + encodeURIComponent(startDate + '');
			if (endDate && endDate !== null) url += '&endDate=' + encodeURIComponent(endDate + '');

			if (userIDs && userIDs.length !== 0) url += '&userIDs=' + encodeURIComponent(userIDs.join(','));

			const result = await this.http.get<Models.AdminLog[]>(url).toPromise();
			const arr: Models.AdminLog[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get last X logs
	async getEmailFailureLogs() {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.adminlogs + '/email-failures';
			const result = await this.http.get<Models.MailFailure[]>(url).toPromise();
			const arr: Models.MailFailure[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getJournals(objectType: string, objectId: number) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.adminlogs
				+ '/journals/' + encodeURIComponent(objectType) + '/' + encodeURIComponent(objectId);
			const result = await this.http.get<Models.JournalEntry[]>(url).toPromise();
			const arr: Models.JournalEntry[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async addJournal(objectType: string, id: number, entry: string) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.adminlogs + '/journals';
			const result = await this.http.post<any>(url, { objectType, id, entry }).toPromise();
			return result;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to delete an item
	async deleteJournal(id: number) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.adminlogs + '/journals/' + encodeURIComponent(id);
			const result = await this.http.delete<any>(url).toPromise();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

}
