import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';
import PopOverTools from 'appshared/popover-tools';

import TrackRecent from '../../../../helpers/track-recent';

import { ZenCustomersService } from '../zen-customers.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { UsersService } from '../../users/users.service';
import { AuthService } from 'client/app/services/auth.service';
import { AdminLogsService } from '../../admin-logs/admin-logs.service';
import { MarketplaceService } from '../../marketplace/marketplace.service';
import { LicensingService } from '../../licensing/licensing.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { ProductsService } from '../../products/products.service';
import { ReportsService } from '../../reports/reports.service';
import { BuildsService } from '../../builds/builds.service';

import { LogsTableComponent } from 'client/app/components/shared/logs-table/logs-table.component';
import { JournalsTableComponent } from 'client/app/components/shared/journals-table/journals-table.component';
import { MarketplaceLogsTableComponent } from 'client/app/components/shared/marketplace-logs-table/marketplace-logs-table.component';
import { BuildsTableComponent } from 'client/app/components/shared/builds-table/builds-table.component';
import { PopupBoxComponent } from 'client/app/components/shared/popup-box/popup-box.component';

@Component({
	selector: 'app-zen-customer',
	templateUrl: './zen-customer.component.html',
	styleUrls: ['./zen-customer.component.scss']
})
export class ZenCustomerComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	textTools = TextTools;
	miscTools = MiscTools;
	popOverTools = PopOverTools;
	validationTools = ValidationTools;

	@ViewChild('logsTable1') logsTable1: LogsTableComponent = null;
	@ViewChild('journalsTable1') journalsTable1: JournalsTableComponent = null;
	@ViewChild(MarketplaceLogsTableComponent) marketPlaceLogsTable: MarketplaceLogsTableComponent = null;
	@ViewChild('buildsTable1') buildsTable1: BuildsTableComponent = null;
	@ViewChild(PopupBoxComponent) popupBox: PopupBoxComponent = null;

	loading = true;
	// showPostLoadElements = false;

	// 'standard' view stuff
	id: number;
	zenCustomer: Models.ZenMasterCustomer;

	organization: Models.Organization;
	users: Models.User[] = [];

	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	// other stuff
	zenBuilds: Models.ZenBuild[] = [];
	zenUsers: Models.ZenMasterUser[] = [];
	builds: Models.Build[] = [];

	notificationStates: any = {};
	matchedPortalUsers: any = {};
	daysSinceCreated: any = {};
	daysSinceLoggedIn: any = {};
	noOrgUsers: any = {};
	diffOrgUsers: any = {};

	showNotificationWarning: boolean = false;

	zenInfoBlocks = {};

	showZixiColumns = false;
	sfOwnerUser: Models.User = null;
	sfSEUser: Models.User = null;
	sfTAMUser: Models.User = null;

	allClusterVersions: string[] = [];
	allClusterVersionsNotInUse: string[] = [];
	allClusterVersionsInUse: string[] = [];

	clusterVersionsHasBuild = {};

	canAdmin = false;
	canSuspend = false;
	canFullResume = false;
	canQuickResume = false;
	canDelete = false;
	canConfigureMarketplace = false;

	canEdit = false; // just aux. info - type, org, marketplace stuff...
	canSendShutdownNotice = false;
	canImpersonate = false;

	adminLogs: Models.AdminLog[] = [];
	marketplaceLogs: Models.MarketplaceUsageReport[] = [];

	canAddJournals = false;
	journals: Models.JournalEntry[] = [];

	marketplaceLabel: string = '';
	marketplaceAccountIdentifier: string = '';
	marketPlaceAccountIdLabel: string = 'ID';
	marketplaceProducts: string[] = [];
	lastMarketplaceUsageReported: Date = null;
	productCache: any = {};

	mediaconnectKeyID: number = 0;
	zenmasterKeyID: number = 0;

	mediaconnectUsage: number = 0;
	zenmasterUsage: number = 0;

	enableForm: UntypedFormGroup;

	canRunPullTargetsReport = false;
	pullTargetReportForm: UntypedFormGroup;

	canReportToMarketplace: boolean = false;
	manualMarketplaceForm: UntypedFormGroup;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private zenCustomersService: ZenCustomersService,
		private organizationsService: OrganizationsService,
		private adminLogsService: AdminLogsService,
		private authService: AuthService,
		private usersService: UsersService,
		private uiAlertsService: UiAlertsService,
		private productsService: ProductsService,
		private reportsService: ReportsService,
		private marketplaceService: MarketplaceService,
		private buildsService: BuildsService
	) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
		});
	}

	// ************************************************************************************
	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.authUser = authUser;

			TrackRecent.addRecent(this.id, 'zenmaster');
			this.loadData();
		});
	}

	// ************************************************************************************
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ************************************************************************************
	async loadData() {
		this.loading = true;

		this.zenCustomer = this.zenCustomersService.getOne(this.id);
		if (!this.zenCustomer || this.zenCustomer == null || this.zenCustomer.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
		}
		this.users = this.usersService.getAll();

		this.canAddJournals = ValidationTools.checkAccess(this.authUser, 'add-journals');
		this.canEdit = ValidationTools.checkAccess(this.authUser, 'manage-zen-master');
		this.canRunPullTargetsReport = ValidationTools.checkAccess(this.authUser, 'run-zen-pull-targets-report');
		this.canConfigureMarketplace = ValidationTools.checkAccess(this.authUser, 'configure-zen-for-marketplace');

		this.sfOwnerUser = null;
		this.sfSEUser = null;
		this.sfTAMUser = null;
		if (this.zenCustomer.zcp_org_id && this.zenCustomer.zcp_org_id !== 0) {
			this.organization = this.organizationsService.getOne(this.zenCustomer.zcp_org_id);

			if (this.organization.salesforce_account_owner_id && this.organization.salesforce_account_owner_id !== 0)
				this.sfOwnerUser = this.usersService.getOne(this.organization.salesforce_account_owner_id);

			if (this.organization.salesforce_se_id && this.organization.salesforce_se_id !== 0)
				this.sfSEUser = this.usersService.getOne(this.organization.salesforce_se_id);

			if (this.organization.salesforce_tam_id && this.organization.salesforce_tam_id !== 0)
				this.sfTAMUser = this.usersService.getOne(this.organization.salesforce_tam_id);
		}

		this.canAdmin = this.organization && ValidationTools.checkAccess(this.authUser, 'admin-zen-master');
		this.canSuspend = this.organization && this.zenCustomer.is_enabled === 1
			&& (ValidationTools.checkAccess(this.authUser, 'disable-zen-master')
				|| (ValidationTools.checkAccess(this.authUser, 'disable-zen-master-as-delegate')
					&& this.organization.salesforce_se_id === this.authUser.id));

		this.canFullResume = this.organization && this.zenCustomer.is_enabled === 0
			&& ValidationTools.checkAccess(this.authUser, 'enable-zen-master');

		this.canQuickResume = this.organization && this.zenCustomer.is_enabled === 0
			&& (this.canFullResume || (ValidationTools.checkAccess(this.authUser, 'enable-zen-master-as-delegate')
				&& this.organization.salesforce_se_id === this.authUser.id));

		this.canDelete = this.organization && ValidationTools.checkAccess(this.authUser, 'delete-zen-master');

		this.canImpersonate = this.zenCustomer.is_enabled === 1
			&& (this.zenCustomer.allow_zixi_support === 1 || this.zenCustomer.allow_zixi_support_write === 1);

		// this.canSendShutdownNotice = this.organization && ValidationTools.checkAccess(this.authUser, 'send-zen-master-shutdown-notice');
		// TBD - hack to disable
		this.canSendShutdownNotice = false;

		this.enableForm = new UntypedFormGroup({
			reason: new UntypedFormControl(null, [Validators.required])
		});

		this.pullTargetReportForm = new UntypedFormGroup({
			apiKey: new UntypedFormControl(null, [Validators.required])
		});

		this.canReportToMarketplace = false;

		this.marketplaceLabel = '';
		this.marketplaceAccountIdentifier = '';
		this.marketplaceProducts = [];
		this.lastMarketplaceUsageReported = null;
		if (this.zenCustomer.marketplace != null && this.zenCustomer.marketplace.marketplace !== ''
			&& this.zenCustomer.marketplace.accountIdentifier && this.zenCustomer.marketplace.accountIdentifier !== ''
			&& this.zenCustomer.marketplace.productIdentifiers && this.zenCustomer.marketplace.productIdentifiers.length > 0) {

			this.marketplaceAccountIdentifier = this.zenCustomer.marketplace.accountIdentifier;
			this.marketplaceLabel = this.zenCustomer.marketplace.marketplace;
			let productSelections: any[] = [];
			const idx1 = MiscTools.findIndexGeneric(AppConstants.marketPlaceSelections, 'value', this.zenCustomer.marketplace.marketplace);
			if (idx1 !== -1) {
				this.marketplaceLabel = AppConstants.marketPlaceSelections[idx1].label + ' (' + this.zenCustomer.marketplace.marketplace + ')';
				productSelections = AppConstants.marketPlaceSelections[idx1].selections;
				this.marketPlaceAccountIdLabel = AppConstants.marketPlaceSelections[idx1].accountLabel;
			} // if

			for (const prodCode of this.zenCustomer.marketplace.productIdentifiers) {
				const idx = MiscTools.findIndexGeneric(productSelections, 'productCode', prodCode);
				if (idx === -1)
					this.marketplaceProducts.push('Unknown product code (' + prodCode + ')');
				else
					this.marketplaceProducts.push(productSelections[idx].label + '(' + prodCode + ')');
			} // for

			if (this.zenCustomer.marketplace.latestReport != null)
				this.lastMarketplaceUsageReported = new Date(this.zenCustomer.marketplace.latestReport);
			if (this.lastMarketplaceUsageReported != null && isNaN(this.lastMarketplaceUsageReported.getTime())) this.lastMarketplaceUsageReported = null;

			this.canReportToMarketplace = ValidationTools.checkAccess(this.authUser, 'report-marketplace-usage');

			this.manualMarketplaceForm = new UntypedFormGroup({
				amountGB: new UntypedFormControl('', [Validators.required]),
				reportStart: new UntypedFormControl('', [Validators.required]),
				reportEnd: new UntypedFormControl('', [Validators.required])
			});
		} // if

		await this.zenCustomersService.updateKeyUsage();

		this.mediaconnectUsage = await this.zenCustomersService.getUsage(this.zenCustomer, 'mediaconnect');
		this.zenmasterUsage = await this.zenCustomersService.getUsage(this.zenCustomer, 'zenmaster');;

		this.mediaconnectKeyID = await this.zenCustomersService.getUsageKeyID(this.zenCustomer, 'mediaconnect');
		this.zenmasterKeyID = await this.zenCustomersService.getUsageKeyID(this.zenCustomer, 'zenmaster');;

		this.zenBuilds = await this.organizationsService.fetchZenBuilds(this.zenCustomer.dns_prefix, true);

		this.builds = [];
		const allBuilds: Models.Build[] = this.buildsService.getAll();
		const buildsDone: number[] = [];
		for (const zenBuild of this.zenBuilds) {
			if (!buildsDone.includes(zenBuild.build_id)) {
				const theBuild: Models.Build = MiscTools.pickItem(allBuilds, 'id', zenBuild.build_id);
				if (theBuild)
					this.builds.push(theBuild);
				buildsDone.push(zenBuild.build_id);
			} // if
		} // for

		if (this.buildsTable1)
			this.buildsTable1.updateContent(this.builds, 'cp-zen-builds', { showProduct: true });

		this.marketplaceLogs = await this.marketplaceService.getMarketplaceLogs('zenmaster', this.id);
		if (this.marketPlaceLogsTable)
			this.marketPlaceLogsTable.updateContent(this.marketplaceLogs, 'cp-zen-mktplace-logs', { showObject: false });

		this.adminLogs = await this.adminLogsService.getLogs(['zenmaster'], this.id);
		if (this.logsTable1)
			this.logsTable1.updateContent(this.adminLogs, 'cp-zen-customer-view-admin-logs', { showUserInfo: true, showObjectInfo: false, linkObject: false, linkUser: true });

		this.journals = await this.adminLogsService.getJournals('zenmaster', this.id);
		if (this.journalsTable1)
			this.journalsTable1.updateContent(this.journals);


		const allClusters = await this.zenCustomersService.getZenClusters();
		for (const cluster of allClusters) {
			if (cluster.customer_id === this.id) {
				const cleanVersion = TextTools.cleanVersion(cluster.version);

				if (!this.allClusterVersions.includes(cleanVersion))
					this.allClusterVersions.push(cleanVersion);
				if (cluster.primary_size > 0 && !this.allClusterVersionsInUse.includes(cleanVersion))
					this.allClusterVersionsInUse.push(cleanVersion);
			}
		}

		for (const v of this.allClusterVersions) {
			if (!this.allClusterVersionsInUse.includes(v))
				this.allClusterVersionsNotInUse.push(v);

			// let altVersion = '';
			// if (v.startsWith('1.'))
			// 	altVersion = v.substr(2);

			let hasVersion = true;
			const idx1 = MiscTools.findIndexGeneric(this.zenBuilds, 'version', v);
			// const idx2 = MiscTools.findIndexGeneric(this.zenBuilds, 'version', altVersion);
			if (idx1 === -1)
				hasVersion = false;

			// 	clusterVersionsHasBuild = {};

			this.clusterVersionsHasBuild[v] = hasVersion;
		}

		this.allClusterVersions.sort();
		this.allClusterVersionsInUse.sort();
		this.allClusterVersionsNotInUse.sort();

		// maybe get all users if the domain isn't tied to an org to make a suggestion
		// cache the stats blocks...
		for (const mode of AppConstants.zenMasterReportModes) {
			const modeInfo = await this.zenCustomersService.getZenCustomerInfo(mode);

			let headings = [];
			if (modeInfo.headings)
				headings = modeInfo.headings;

			let columns = [];
			if (modeInfo.data) {
				const idx = MiscTools.findIndex(modeInfo.data, this.id);
				if (idx !== -1 && modeInfo.data[idx].columns)
					columns = modeInfo.data[idx].columns;
			}

			const chunks = [];
			if (headings.length === columns.length) {
				// tslint:disable-next-line: prefer-for-of
				for (let i = 0; i < headings.length; i++) {
					if (columns[i] + '' !== '0')
						chunks.push({ label: headings[i], value: columns[i] });
				}
			}
			this.zenInfoBlocks[mode] = chunks;
		}

		const allProducts = this.productsService.getAll();
		let zenID = -1;
		for (const prod of allProducts)
			if (prod.name === 'ZEN Master')
				zenID = prod.id;

		let zenOrgIDs: number[] = [];
		if (zenID !== -1) {
			const allOrgs = this.organizationsService.getAll();
			for (const org of allOrgs)
				if (MiscTools.findIndexGeneric(org.product_platforms, 'product_id', zenID) !== -1)
					zenOrgIDs.push(org.id);
		} // if


		this.showNotificationWarning = false;

		this.notificationStates = {};
		this.matchedPortalUsers = {};
		this.daysSinceCreated = {};
		this.daysSinceLoggedIn = {};
		this.noOrgUsers = {};
		this.diffOrgUsers = {};

		this.zenUsers = await this.zenCustomersService.getZenUsers(this.id);
		let subscribedCount = 0;
		for (const zenUser of this.zenUsers) {
			this.daysSinceCreated[zenUser.id] = null;
			if (zenUser.created_at) this.daysSinceCreated[zenUser.id] = MiscTools.daysSince(zenUser.created_at, true);

			this.daysSinceLoggedIn[zenUser.id] = null;
			if (zenUser.last_login_at) this.daysSinceLoggedIn[zenUser.id] = MiscTools.daysSince(zenUser.last_login_at, true);

			this.noOrgUsers[zenUser.id] = false;
			this.diffOrgUsers[zenUser.id] = false;

			const idx = MiscTools.findIndexGeneric(this.users, 'email', zenUser.email.trim().toLowerCase());
			if (idx !== -1) {
				this.matchedPortalUsers[zenUser.id] = this.users[idx];
				if (this.users[idx].org_id && this.users[idx].org_id !== 0) {
					if (idx !== -1 && this.users[idx].org_id !== this.zenCustomer.zcp_org_id)
						this.diffOrgUsers[zenUser.id] = true;
				} else {
					this.noOrgUsers[zenUser.id] = true;
				} // if
				this.notificationStates[zenUser.id] = ValidationTools.getZenUserNotificationState(this.users, zenOrgIDs, zenUser);

			} else {
				this.matchedPortalUsers[zenUser.id] = null;
				// zenUser.zcp_user_id = 0;
				// zenUser.zcp_user_org_id = 0;
				this.notificationStates[zenUser.id] = 'No ZCP Account';
			}

			if (zenUser.is_zixi_support && zenUser.is_zixi_support === 1)
				this.showZixiColumns = true;
			if (zenUser.is_zixi_admin && zenUser.is_zixi_admin === 1)
				this.showZixiColumns = true;

			if (this.notificationStates[zenUser.id] === 'Subscribed') subscribedCount++;
		} /// for
		this.showNotificationWarning = subscribedCount === 0;

		this.loading = false;
	}

	// ************************************************************************************
	async sendZenCustomerShutdownNotice() {
		if (confirm('Are you sure?'))
			this.zenCustomersService.sendZenCustomerShutdownNotice(this.id);
	}

	// ************************************************************************************
	loginClick() {
		const url = AppConstants.zenLoginUrlTemplate.replace(AppConstants.zenTemplateToken, this.zenCustomer.dns_prefix);
		window.open(url, '_blank');
	}

	// ************************************************************************************
	manageClick() {
		const url = AppConstants.zenImpersonateUrlTemplate.replace(AppConstants.zenTemplateToken, this.zenCustomer.dns_prefix);
		window.open(url, '_blank');
	}

	// ************************************************************************************
	isNumber(value: any) {
		if (value) {
			const type = typeof value;
			if (type === 'number')
				return true;
		}
		return false;
	}

	// ************************************************************************************
	getMarketPlaceProductName(log: Models.MarketplaceUsageReport) {
		const key = log.marketplace + ':' + log.product_id;
		if (!this.productCache[key]) {
			const idx1 = MiscTools.findIndexGeneric(AppConstants.marketPlaceSelections, 'value', log.marketplace);
			if (idx1 !== -1) {
				const idx2 = MiscTools.findIndexGeneric(AppConstants.marketPlaceSelections[idx1].selections, 'productCode', log.product_id);
				if (idx2 !== -1)
					this.productCache[key] = AppConstants.marketPlaceSelections[idx1].selections[idx2].label;
			} // if
		} // if

		if (this.productCache[key])
			return this.productCache[key]

		return log.product_id;
	}

	// ************************************************************************************
	async enable() {
		// if (!confirm('Are you sure you want to enable/resume this ZEN Master?')) return;

		this.loading = true;

		const name = this.zenCustomer.name;
		let reason = '';
		if (this.enableForm.value.reason) reason = this.enableForm.value.reason;

		if (!reason || reason.trim() === '') {
			this.uiAlertsService.addMsg('Need a reason', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.loading = false;
			return;
		} // if

		if (document.getElementById("closeEnableModalButton"))
			document.getElementById("closeEnableModalButton").click();

		try {
			await this.zenCustomersService.adminQuickEnable(this.id, reason);
		} catch (e) {
			this.loading = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			return;
		} // 

		// this.zenCustomersService.refreshAll();
		this.zenCustomer.is_enabled = 1;
		this.canSuspend = this.organization && this.zenCustomer.is_enabled === 1
			&& (ValidationTools.checkAccess(this.authUser, 'disable-zen-master')
				|| (ValidationTools.checkAccess(this.authUser, 'disable-zen-master-as-delegate')
					&& this.organization.salesforce_se_id === this.authUser.id));

		this.canFullResume = false;
		this.canQuickResume = false;

		this.uiAlertsService.addMsg(name + ' - has been enabled.', 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		this.loading = false;

	}

	// ------------------------------------------------------------------------
	prepDisable() {
		this.popupBox.openPopup('confirm-text', 'disable', [], 'Disable/Suspend ZEN Master Site',
			'If you disable this ZEN Master Site, users will no longer be able to login and workflows will not be monitored.',
			'ban',
			{ confirmButtonText: 'Disable/Suspend this ZEN Master Site', rejectButtonText: 'Cancel', confirmText: 'disable' });
	}

	// ************************************************************************************
	async disable() {
		this.loading = true;

		const name = this.zenCustomer.name;

		try {
			await this.zenCustomersService.adminDisable(this.id);
		} catch (e) {
			this.loading = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			return;
		} // 

		// this.zenCustomersService.refreshAll();
		this.zenCustomer.is_enabled = 0;
		this.canSuspend = false;

		this.canFullResume = this.organization && this.zenCustomer.is_enabled === 0
			&& ValidationTools.checkAccess(this.authUser, 'enable-zen-master');

		this.canQuickResume = this.organization && this.zenCustomer.is_enabled === 0
			&& (this.canFullResume || (ValidationTools.checkAccess(this.authUser, 'enable-zen-master-as-delegate')
				&& this.organization.salesforce_se_id === this.authUser.id));

		this.uiAlertsService.addMsg(name + ' - has been disabled/suspended.', 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		this.loading = false;
	}

	// ------------------------------------------------------------------------
	prepDelete() {
		this.popupBox.openPopup('confirm-text', 'delete', [], 'Delete ZEN Master Site',
			'If you delete this ZEN Master Site, it will be permanently deleted and cannot be recovered.',
			null,
			{ confirmButtonText: 'Delete ZEN Master Site', rejectButtonText: 'Cancel', confirmText: 'delete' });
	}

	// ------------------------------------------------------------------------
	async delete() {
		this.loading = true;

		const name = this.zenCustomer.name;

		try {
			await this.zenCustomersService.adminDelete(this.id);
		} catch (e) {
			this.loading = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			return;
		} // 

		// this.zenCustomersService.refreshAll();
		// await MiscTools.delay(2000);

		this.uiAlertsService.addMsg(name + ' - has been deleted.', 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		this.router.navigate([AppConstants.urls.zencustomers]);
	}


	// ************************************************************************************
	async runPullTargetsReport() {
		this.loading = true;

		try {
			await this.reportsService.runReport('ZenPullTargetsReport', 'id=' + this.id);
		} catch (e) {
			this.loading = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			return;
		} // 

		this.loading = false;
	}

	getUsersName(id: number): string {
		return this.usersService.getUsersName(id);
	}



	// *********************************************************
	async manualMarketplaceZenReport() {

		const errors: string[] = [];
		// check form elements to get args

		let amountGB: number = +this.manualMarketplaceForm.value.amountGB;
		let reportStart: Date = new Date(this.manualMarketplaceForm.value.reportStart + ' UTC');
		let reportEnd: Date = new Date(this.manualMarketplaceForm.value.reportEnd + ' UTC');

		if (isNaN(reportStart.getTime())) reportStart = null;
		if (isNaN(reportEnd.getTime())) reportEnd = null;

		if (isNaN(amountGB) || amountGB <= 0)
			errors.push('Amount (GB) must be a postive number.');

		if (reportStart == null)
			errors.push('Start of Report is not a valid date/time.');

		if (reportEnd == null)
			errors.push('End of Report is not a valid date/time.');

		if (errors.length > 0) {
			this.uiAlertsService.addMsgs(errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			return;
		} // if

		if (document.getElementById("closeManualMarketplaceReportModal"))
			document.getElementById("closeManualMarketplaceReportModal").click();

		try {
			const ret = await this.marketplaceService.manualZenReport(this.id, reportStart, reportEnd, amountGB);

			this.uiAlertsService.addMsg('The usage has been reported.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		} catch (e) {
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}


	// ------------------------------------------------------------------------
	// Back and Forth with components
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			popupCallBack: async (callBack: string, args: any) => {
				if (callBack === 'delete' && args.length === 0)
					this.delete();
				else if (callBack === 'disable' && args.length === 0)
					this.disable();

				else
					this.uiAlertsService.addMsg('Unknown callBack (' + callBack + ') or bad number of arguments (' + args.length + ').', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		}
	} // getParentMethod
}
