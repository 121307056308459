import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';
import LicenseValidationTools from 'appshared/license-validation-tools';

import { AuthService } from 'client/app/services/auth.service';
import { LicensingService } from '../licensing.service';
import { LicensingAdminService } from '../../licensing-admin/licensing-admin.service';
import { UsersService } from '../../users/users.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-write-access-form',
	templateUrl: './write-access-form.component.html',
	styleUrls: ['./write-access-form.component.scss']
})
export class WriteAccessFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	id: number; // activation ID
	activation: Models.LPActivation;

	// 'standard' edit stuff

	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	staffUserSelections: any[] = [];

	usersWithAccess: Models.User[] = [];

	// other stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private usersService: UsersService,
		private licensingAdminService: LicensingAdminService,
		private licensingService: LicensingService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
		});

	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;
			if (!ValidationTools.checkAccess(this.authUser, 'manage-keys') || !ValidationTools.checkAccess(authUser, 'set-key-write-access')) {
				this.onCancel();
				return;
			};
			this.loadData();
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData() {

		this.activation = await this.licensingService.getOne(this.id);
		if (!this.activation || this.activation == null || this.activation.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		} // if

		// check to to see if logged in user has access (via template) to this key
		// const userTemplateIDs = await this.licensingAdminService.getUserTemplateIDs();

		const userIdsWithTemplateAccess = await this.licensingAdminService.getAllUserIdsForTemplate(this.activation.zcp_template_id);
		if (!userIdsWithTemplateAccess || !userIdsWithTemplateAccess.includes(this.authUser.id)) {
			this.onCancel();
			return;
		} // if

		const userIdsWithTagAccess = await this.usersService.getAllUserIdsForAccessTag('manage-keys');

		const userWriteAccessArr: Models.KeyWriteAccess[] = await this.licensingService.getKeyWriteAccess(this.id);
		const userIDs: number[] = [];
		for (const userWriteAccess of userWriteAccessArr)
			userIDs.push(userWriteAccess.user_id);

		this.usersWithAccess = [];
		const staff: Models.User[] = this.usersService.getForRole(AppConstants.staffUserRole);
		this.staffUserSelections = [];
		for (const user of staff) {
			if (userIdsWithTemplateAccess.includes(user.id) && userIdsWithTagAccess.includes(user.id)) {
				this.usersWithAccess.push(user);
			} // if

			if ((user.is_enabled === 1 && !userIdsWithTemplateAccess.includes(user.id) && userIdsWithTagAccess.includes(user.id)) || userIDs.includes(user.id)) {
				let nameToShow = user.name;
				if (user.is_enabled === 0) nameToShow += ' (disabled)';
				this.staffUserSelections.push({ id: user.id, name: nameToShow });
			} // if
		} // staff

		this.theForm = new UntypedFormGroup({
			userIDs: new UntypedFormControl(userIDs, [])
		});

		this.loading = false;
	}



	async onSubmit() {
		this.saving = true;
		const errors: string[] = [];

		let userIDs: number[] = this.theForm.value.userIDs;
		if (!userIDs) userIDs = [];

		// if (this.errors.length > 0) {
		// 	this.uiAlertsService.addMsg('Problems were found with this key.  See the \'Errors\' tab for more details,',
		// 		'error', 'no-edit', false, AppConstants.standardMessageAutoCloseTimeSecs);
		// 	this.saving = false;
		// 	return;
		// }

		if (errors.length > 0) {
			this.uiAlertsService.addMsgs(errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		try {
			await this.licensingService.setKeyWriteAccess(this.id, userIDs);
			this.onCancel();
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['/' + AppConstants.urls.licensing, 'activation', this.id]);
	}

}
