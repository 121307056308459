import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import PopOverTools from 'appshared/popover-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { FilesService } from '../files.service';
import { MyDocumentsService } from '../../my-documents/my-documents.service';
import { DownloadLogsService } from '../../download-logs/download-logs.service';
import { UsersService } from '../../users/users.service';
import { AdminLogsService } from '../../admin-logs/admin-logs.service';
import { ReportsService } from '../../reports/reports.service';

import { LogsTableComponent } from 'client/app/components/shared/logs-table/logs-table.component';
import { DownloadsTableComponent } from 'client/app/components/shared/downloads-table/downloads-table.component';
import { PopupBoxComponent } from 'client/app/components/shared/popup-box/popup-box.component';

@Component({
	selector: 'app-file',
	templateUrl: './file.component.html',
	styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	popOverTools = PopOverTools;
	textTools = TextTools;
	miscTools = MiscTools;

	@ViewChild('logsTable1') logsTable1: LogsTableComponent = null;
	@ViewChild('downloadsTable1') downloadsTable1: DownloadsTableComponent = null;
	@ViewChild(PopupBoxComponent) popupBox: PopupBoxComponent = null;

	loading = true;
	// showPostLoadElements = false;

	// 'standard' view stuff
	id: number;
	file: Models.File;

	// other stuff
	builds: Models.Build[] = [];
	documents: Models.Document[] = [];
	freeUrl = '';
	downloadLogs: Models.DownloadLog[] = [];
	adminLogs: Models.AdminLog[] = [];
	downloadLink: Models.DownloadLink;
	addedByUser: Models.User = null;
	editedByUser: Models.User = null;

	private userSubscription: Subscription;

	canEdit = false;
	canDelete = false;

	canDownload = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private filesService: FilesService,
		private usersService: UsersService,
		private myDocumentsService: MyDocumentsService,
		private downloadLogsService: DownloadLogsService,
		private reportsService: ReportsService,
		private adminLogsService: AdminLogsService
	) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.file = this.filesService.getOne(this.id);
			if (!this.file || this.file == null || this.file.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
			} else {
				this.userSubscription = this.authService.user.subscribe((authUser) => {
					if (authUser) {
						this.canEdit = ValidationTools.checkAccess(authUser, 'manage-files');

						this.canDelete = this.canEdit &&
							(ValidationTools.checkAccess(authUser, 'delete-files')
								|| (!this.file.last_download && ValidationTools.checkAccess(authUser, 'delete-unused-files')))
							;
						this.canDownload = ValidationTools.checkAccess(authUser, 'access-all-builds-and-documents');
					}
					this.loadData();
				});
			}
		});
	}

	ngOnInit(): void {
		// downloads
	}
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData() {
		this.loading = true;

		this.builds = await this.filesService.fetchBuildsFromDB(this.id);
		this.documents = await this.filesService.fetchDocumentsFromDB(this.id);

		if (this.file.free_access_token && this.file.free_access_token !== '' && this.file.free_access_token !== AppConstants.freeTokenMask)
			this.freeUrl = await this.filesService.getFreeUrl(this.id);

		if (!this.canDownload && this.freeUrl !== '') this.canDownload = true;

		this.addedByUser = null;
		if (this.file.added_by && this.file.added_by !== 0)
			this.addedByUser = this.usersService.getOne(this.file.added_by);

		this.editedByUser = null;
		if (this.file.edited_by && this.file.edited_by !== 0)
			this.editedByUser = this.usersService.getOne(this.file.edited_by);

		this.downloadLogs = await this.downloadLogsService.getLogsForFile(this.id);
		this.adminLogs = await this.adminLogsService.getLogs(['file'], this.id);

		this.loading = false;

		await MiscTools.delay(100);
		if (this.logsTable1)
			this.logsTable1.updateContent(this.adminLogs, 'cp-file-view-admin-logs', { showUserInfo: true, showObjectInfo: false, linkObject: false, linkUser: true });
		if (this.downloadsTable1)
			this.downloadsTable1.updateContent(this.downloadLogs, 'cp-file-view-download-logs', { showUserInfo: true, linkUser: true });

	}

	// ------------------------------------------------------------------------
	prepDelete() {
		this.popupBox.openPopup('confirm-text', 'delete', [], 'Delete File',
			'If you delete this File, it will be permanently deleted and cannot be recovered.',
			null,
			{ confirmButtonText: 'Delete File', rejectButtonText: 'Cancel', confirmText: 'delete' });
	}

	// ------------------------------------------------------------------------
	async delete() {
		this.loading = true;

		const result = await this.filesService.deleteOne(this.file.id);
		if (result) {
			this.uiAlertsService.addMsg('The file (' + this.file.name + ') has been deleted.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.router.navigate([AppConstants.urls.files]);
		} else {
			return false;
		}
	}

	async disableAutobuild() {
		if (confirm('Are you sure you want to disable Auto-Build for this file?')) {
			const result = await this.filesService.toggleAutoBuild(this.file.id);
			if (result) {
				this.file = result;
				this.loadData();
			} else {
				return false;
			}
		}
	}

	async enableAutobuild() {
		if (confirm('Are you sure you want to enable Auto-Build for this file?')) {
			const result = await this.filesService.toggleAutoBuild(this.file.id);
			if (result) {
				this.file = result;
				this.loadData();
			} else {
				return false;
			}
		}
	}


	getBuildsURL() {
		return '/' + AppConstants.urls.builds;
	}

	getDocumentsURL() {
		return '/' + AppConstants.urls.documents;
	}

	// proxyDownloadLink() {
	// 	return AppConstants.apiUrl + AppConstants.apiUrls.downloads + '/' + this.file.id + '/download';
	// }

	async openDownload(id: number, dltype: string = '', refid: number = 0) {
		const dlObj = await this.myDocumentsService.getDownloadLink(id, dltype, refid);
		if (dlObj)
			window.open(dlObj.url, '_blank');
	}

	async grabDownloadInfo(id: number, dltype: string = '', refid: number = 0) {
		const dlObj = await this.myDocumentsService.getDownloadLink(id, dltype, refid);
		if (dlObj) this.downloadLink = dlObj;
	}

	// *********************************************************
	async runDownloadsReport() {
		await this.reportsService.runReport('AllDownloadsReport', 'objType=file&objId=' + this.id);
	}
	// --------------------------------------------------------------------
	copyToClipboardAlert(item: string = '') {
		this.uiAlertsService.copyToClipboardAlert(item);
	}

	// ------------------------------------------------------------------------
	// Back and Forth with components
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			popupCallBack: async (callBack: string, args: any) => {
				if (callBack === 'delete' && args.length === 0)
					this.delete();
				else if (callBack === 'XXXXX' && args.length === 1)
					console.log('args=' + args);

				else
					this.uiAlertsService.addMsg('Unknown callBack (' + callBack + ') or bad number of arguments (' + args.length + ').', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		}
	} // getParentMethod

}
