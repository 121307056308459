// Core
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// Interceptors
import {
	HttpClient,
	HttpClientModule,
	HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { ErrorInterceptor } from './helpers/error.interceptor';

// shared
import { SharedModule } from './modules/shared/shared.module';

// Ng Bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Forms
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

// Other Modules
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxDropzoneModule } from 'ngx-dropzone';

// My Modules
import { FontAwesome } from './modules/font-awesome/font-awesome.module';

// Components
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { SelfRegistrationComponent } from './components/self-registration/self-registration.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SizeDetectorComponent } from './components/size-detector/size-detector.component';
import { SsoComponent } from './components/login/sso/sso.component';
import { UiAlertsComponent } from './components/ui-alerts/ui-alerts.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';
import { AwsMarketplaceRegistrationComponent } from './components/aws-marketplace-registration/aws-marketplace-registration.component';
import { AzureMarketplaceRegistrationComponent } from './components/azure-marketplace-registration/azure-marketplace-registration.component';
import { GcpMarketplaceRegistrationComponent } from './components/gcp-marketplace-registration/gcp-marketplace-registration.component';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Services
import { ResizeService } from './services/resize.service';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { DatePipe } from '@angular/common';

@NgModule({
	imports: [
		AppRoutingModule,
		BrowserModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		FontAwesomeModule,
		NgbModule,
		NgSelectModule,
		NgxDropzoneModule,

		SharedModule,
		FontAwesome,
		NoopAnimationsModule
	],
	declarations: [
		AppComponent,
		LoginComponent,
		SelfRegistrationComponent,
		ForgotPasswordComponent,
		NotFoundComponent,
		TopBarComponent,
		NavigationComponent,
		SsoComponent,
		ResetPasswordComponent,
		SizeDetectorComponent,
		ConfirmEmailComponent,
		UiAlertsComponent,
		UnsubscribeComponent,
		AwsMarketplaceRegistrationComponent,
		AzureMarketplaceRegistrationComponent,
		GcpMarketplaceRegistrationComponent
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		ResizeService,
		DatePipe
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
