import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-users-table',
	templateUrl: './users-table.component.html',
	styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnInit {
	ac = AppConstants;
	textTools = TextTools;
	miscTools = MiscTools;
	now = new Date();

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	/*
		pass in
			users 
			storageKey
			options
			- showOrganization : true
	*/

	authUser: Models.AuthUser = null;
	loading: boolean = false;

	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = '';

	showOrganization: boolean = true;
	linkUser: boolean = false;

	items: Models.User[] = [];
	itemsToShow: Models.User[] = [];

	columnDefs: any[] = [
		{
			field: 'is_enabled',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Enabled/Disabled',
			icon: 'power-off'
		}, {
			field: '__onboard_status',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'On-boarding Status',
			icon: 'exclamation-triangle'
		}, {
			field: 'name',
			type: 'baseText',
			sortType: 'text',
			label: 'Name',
			toolTip: null,
			icon: null
		}, {
			field: 'org_name',
			type: 'baseText',
			sortType: 'text',
			label: 'Organization',
			toolTip: null,
			icon: null
		}, {
			field: 'email',
			type: 'baseText',
			sortType: 'text',
			label: 'E-Mail',
			toolTip: null,
			icon: null
		}, {
			field: 'role',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Role',
			icon: 'user'
		}, {
			field: '__notifications',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Notifications',
			icon: AppConstants.sectionIcons.mynotifications
		}, {
			field: '__num_packages',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Number of Packages',
			icon: AppConstants.sectionIcons.mypackages
		}, {
			field: '__num_keys',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Number of Keys',
			icon: AppConstants.sectionIcons.mykeys
		}, {
			field: '__num_groups',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Number of Groups',
			icon: AppConstants.sectionIcons.usergroups
		}, {
			field: '__days_since_add',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Days Since Added',
			icon: 'clock'
		}, {
			field: '__days_since_login',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Days Since Last Login',
			icon: 'sign-in-alt'
		}, {
			field: '__days_since_download',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Days Since Last Download',
			icon: 'file-download'
		}
	];

	fieldsToShow: string[] = [];

	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService) {
	}

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const sessionUser: Models.AuthUser = this.authService.getUser();
		if (sessionUser != null && sessionUser.id !== 0) {
			this.authUser = sessionUser;
			this.setups();
		} // if
	}

	// ------------------------------------------------------------------------
	async setups() {

	}

	// ------------------------------------------------------------------------
	updateContent(items: Models.User[], storageKey: string, options: any = {}) {
		this.loading = true;

		this.items = items;
		this.storageKey = storageKey;

		let optionKeys: string[] = [];
		if (options) optionKeys = Object.keys(options);

		if (optionKeys.includes('showOrganization')) this.showOrganization = options.showOrganization;

		this.fieldsToShow = [];

		this.fieldsToShow.push('is_enabled');
		this.fieldsToShow.push('__onboard_status');
		this.fieldsToShow.push('name');
		if (this.showOrganization) this.fieldsToShow.push('org_name');
		this.fieldsToShow.push('email');
		this.fieldsToShow.push('role');
		this.fieldsToShow.push('__notifications');
		this.fieldsToShow.push('__num_packages');
		this.fieldsToShow.push('__num_keys');
		this.fieldsToShow.push('__num_groups');
		this.fieldsToShow.push('__days_since_add');
		this.fieldsToShow.push('__days_since_login');
		this.fieldsToShow.push('__days_since_download');

		for (const item of this.items) {
			if (item.is_onboarded === 0)
				item['__onboard_status'] = ValidationTools.getUserIssues(item, true).join(' ');
			else
				item['__onboard_status'] = 'On-boarded';

			if (item.notifications)
				item['__notifications'] = 'User has setup notifications';
			else
				item['__notifications'] = 'User has not setup notifications';

			item['__num_packages'] = item.package_ids.length;
			item['__num_keys'] = item.num_keys;
			item['__num_groups'] = item.group_ids.length;
			item['__days_since_add'] = MiscTools.diffDays(this.now, item.added_on, true);

			if (item.last_login)
				item['__days_since_login'] = MiscTools.diffDays(this.now, item.last_login, true);
			else
				item['__days_since_login'] = 99999999;

			if (item.last_download)
				item['__days_since_download'] = MiscTools.diffDays(this.now, item.last_download, true);
			else
				item['__days_since_download'] = 99999999;

		} // for

		this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'name', 'asc');


		this.loading = false;
	} // updateContent

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {

		const tmpList: Models.User[] = [];
		for (const item of this.items) {
			let match: boolean = true;
			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.name.toLowerCase().includes(w)
						|| item.email.toLowerCase().includes(w)
						|| item.role.toLowerCase().includes(w)
						|| (this.showOrganization && item.org_name.toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for

		return tmpList;
	} // filterItems

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}

}
