import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import PopOverTools from 'appshared/popover-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { LicensingAdminService } from '../licensing-admin.service';
import { AdminLogsService } from '../../admin-logs/admin-logs.service';
import { UsersService } from '../../users/users.service';
import { ProductsService } from '../../products/products.service';

import { LogsTableComponent } from 'client/app/components/shared/logs-table/logs-table.component';
import { PopupBoxComponent } from 'client/app/components/shared/popup-box/popup-box.component';

@Component({
	selector: 'app-licensing-product',
	templateUrl: './licensing-product.component.html',
	styleUrls: ['./licensing-product.component.scss']
})

export class LicensingProductComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	popOverTools = PopOverTools;
	textTools = TextTools;

	@ViewChild('logsTable1') logsTable1: LogsTableComponent = null;
	@ViewChild(PopupBoxComponent) popupBox: PopupBoxComponent = null;

	loading = true;
	// showPostLoadElements = false;

	// 'standard' view stuff
	authUser: Models.AuthUser;
	id: number;
	product: Models.LicenseProduct;
	productProperties: Models.LicenseProductProperty[] = [];

	addableProperties: Models.LicenseProperty[] = [];

	buildProduct: Models.Product;

	booleanProps: Models.LicenseProductProperty[] = [];
	numberProps: Models.LicenseProductProperty[] = [];
	otherProps: Models.LicenseProductProperty[] = [];

	showAddPropButton = false;

	// other stuff
	private userSubscription: Subscription;

	adminLogs: Models.AdminLog[] = [];
	addedByUser: Models.User = null;
	editedByUser: Models.User = null;

	canManage = false;

	productTemplates: Models.KeyTemplate[] = [];
	otherProducts: Models.LicenseProduct[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private productsService: ProductsService,
		private licensingAdminService: LicensingAdminService,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private usersService: UsersService,
		private adminLogsService: AdminLogsService
	) {

		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.userSubscription = this.authService.user.subscribe((authUser) => {
				this.authUser = authUser;
				this.canManage = (authUser && ValidationTools.checkAccess(authUser, 'manage-license-key-setup'));

				this.loadData();
			});
		});
	}

	ngOnInit(): void {
		// changes
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData() {
		this.loading = true;


		this.product = await this.licensingAdminService.getProduct(this.id, false);
		if (!this.product || this.product == null || this.product.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
		}

		const allTemplates: Models.KeyTemplate[] = await this.licensingAdminService.getTemplates();
		this.productTemplates = [];
		for (const template of allTemplates)
			if (template.product === this.product.name)
				this.productTemplates.push(template);

		this.otherProducts = [];
		const allProducts: Models.LicenseProduct[] = await this.licensingAdminService.getProducts();
		for (const product of allProducts)
			if (product.id !== this.product.id && product.fulfillment_product === this.product.fulfillment_product)
				this.otherProducts.push(product);

		this.productProperties = await this.licensingAdminService.getProductProperties(false, this.id);

		this.productProperties.sort((a, b) => (a.property.sort_order > b.property.sort_order
			|| (a.property.sort_order === b.property.sort_order && a.property.label > b.property.label)) ? 1 : -1);

		this.booleanProps = this.getProductPropertiesByType('boolean');
		this.numberProps = this.getProductPropertiesByType('number');
		this.otherProps = this.getProductPropertiesByType('other');

		const allProperties = await this.licensingAdminService.getProperties();
		allProperties.sort((a, b) => (a.label > b.label) ? 1 : -1);

		this.addableProperties = [];
		for (const property of allProperties)
			if (MiscTools.findIndexGeneric(this.productProperties, 'property_id', property.id) === -1)
				this.addableProperties.push(property);

		this.addableProperties.sort((a, b) => (a.ptype > b.ptype || (a.ptype === b.ptype && a.label > b.label)) ? 1 : -1);

		this.showAddPropButton = this.addableProperties.length !== 0;

		this.buildProduct = this.productsService.getOne(this.product.build_product_id);

		this.addedByUser = null;
		if (this.product.added_by && this.product.added_by !== 0)
			this.addedByUser = this.usersService.getOne(this.product.added_by);

		this.editedByUser = null;
		if (this.product.edited_by && this.product.edited_by !== 0)
			this.editedByUser = this.usersService.getOne(this.product.edited_by);

		this.adminLogs = await this.adminLogsService.getLogs(['license-product'], this.id);

		this.loading = false;

		await MiscTools.delay(100);
		if (this.logsTable1)
			this.logsTable1.updateContent(this.adminLogs, 'cp-key-product-view-admin-logs', { showUserInfo: true, showObjectInfo: false, linkObject: false, linkUser: true });

	}

	async clone() {
		if (confirm('Are you sure you want to copy/clone this License Product?')) {
			this.loading = true;
			try {
				const result = await this.licensingAdminService.cloneProduct(this.product);

				this.loading = false;
				if (result) {
					this.router.navigate([AppConstants.urls.licensingadmin, 'products', result.id]);
				} else {
					return false;
				}
			} catch (e) {
				this.loading = false;
				this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		}
	}

	// ------------------------------------------------------------------------
	prepDelete() {
		this.popupBox.openPopup('confirm-text', 'delete', [], 'Delete License Product',
			'If you delete this License Product, it will be permanently deleted and cannot be recovered.',
			null,
			{ confirmButtonText: 'Delete License Product', rejectButtonText: 'Cancel', confirmText: 'delete' });
	}

	// ------------------------------------------------------------------------
	async delete() {
		this.loading = true;

		const result = await this.licensingAdminService.deleteProduct(this.product.id);
		if (result) {
			this.uiAlertsService.addMsg('The license product (' + this.product.name + ') has been deleted.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.router.navigate([AppConstants.urls.licensingadmin, 'products']);
		} else {
			return false;
		}
	}

	async deleteProperty(id: number) {
		if (confirm('Are you sure you want to delete this License Product Property?')) {
			const result = await this.licensingAdminService.deleteProductProperty(this.product.id, id);
			if (result) {
				await this.loadData();
			} else {
				return false;
			}
		}
	}

	getProductPropertiesByType(type: string) {
		const sublist: Models.LicenseProductProperty[] = [];
		for (const pp of this.productProperties)
			if (pp.property.ptype === type) sublist.push(pp);
		return sublist;
	}

	chopString(str: string) {
		return TextTools.chopString(str, 15, '...');
	}

	niceDefault(productProperty: Models.LicenseProductProperty): string {
		if (productProperty.property.ptype === 'boolean')
			return this.niceBoolean(productProperty.default_value_num);
		else if (productProperty.property.ptype === 'number' && productProperty.default_value_text !== 'unlimited')
			return productProperty.default_value_num + '';
		else if (productProperty.default_value_text && productProperty.default_value_text !== '')
			return productProperty.default_value_text;
		else
			return '';
	}

	niceBoolean(value: number): string {
		if (value === 0)
			return 'No';
		else if (value === 1)
			return 'Yes';
		else
			return '???-' + value;
	}

	niceShowUser(value: number): string {
		if (value >= 0 && value < AppConstants.keyPropShowUserValues.length)
			return AppConstants.keyPropShowUserValues[value];
		else
			return '???-' + value;
	}

	niceStatRule(value: number): string {
		if (value >= 0 && value < AppConstants.keyProductStatsValues.length)
			return AppConstants.keyProductStatsValues[value];
		else
			return '???-' + value;
	}

	// ------------------------------------------------------------------------
	// Back and Forth with components
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			popupCallBack: async (callBack: string, args: any) => {
				if (callBack === 'delete' && args.length === 0)
					this.delete();
				else if (callBack === 'XXXXX' && args.length === 1)
					console.log('args=' + args);

				else
					this.uiAlertsService.addMsg('Unknown callBack (' + callBack + ') or bad number of arguments (' + args.length + ').', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		}
	} // getParentMethod
}
