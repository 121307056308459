<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page" id="top">
				<fa-icon [icon]="ac.sectionIcons.mypartners" class="cp-user-nav-item me-1" size="sm">
				</fa-icon>
				Partners
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button type="button" class="btn btn-outline-secondary me-2" (click)="openReport()">
				<fa-icon icon="file-excel" size="sm"></fa-icon>
				<span class="cp-action-button-text">Report</span>
			</button>

			<button *ngIf="canShowExtras && !showExtras" type="button" class="btn btn-outline-secondary me-2" (click)="toggleExtras()"
				ngbTooltip="Change to the staff view - shows hidden/locked entries, provides links to organization + partnership">
				<fa-icon [icon]="['fas', 'glasses']" size="sm"></fa-icon>
				<span class="cp-action-button-text">Staff View</span>
			</button>

			<button *ngIf="canShowExtras && showExtras" type="button" class="btn btn-outline-secondary me-2" (click)="toggleExtras()"
				ngbTooltip="Change to the user view - hide hidden/locked entries, no links to organization + partnership">
				<fa-icon [icon]="['fas', 'glasses']" size="sm"></fa-icon>
				<span class="cp-action-button-text">User View</span>
			</button>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true"
				id="nav-by-type-tab" aria-controls="nav-by-type" data-bs-target="#nav-by-type">
				<fa-icon [icon]="['fas', 'list']" size="sm"></fa-icon>
				By Type
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-by-partner-tab"
				aria-controls="nav-by-partner" data-bs-target="#nav-by-partner">
				<fa-icon [icon]="['fas', 'list']" size="sm"></fa-icon>
				By Partner
			</button>
		</li>

		<li *ngIf="zmPartnersToShow && zmPartnersToShow.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-zm-certified-tab" aria-controls="nav-zm-certified" data-bs-target="#nav-zm-certified">
				<fa-icon [icon]="['fas', 'list']" size="sm"></fa-icon>
				ZEN Master Certified
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-table-tab"
				aria-controls="nav-table" data-bs-target="#nav-table">
				<fa-icon [icon]="['fas', 'table']" size="sm"></fa-icon>
				Table
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>


	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-by-type" role="tabpanel" aria-labelledby="nav-by-type-tab">

			<div class="cp-width-limit-1000">
				<!-- <button *ngFor="let dtype of dtypes" type="button" class="btn btn-outline-primary btn-sm mb-1 me-1"
					(click)="scrollToSpot('dtype_' + dtype)" [ngbTooltip]="'Jump to ' + dtype">
					{{ dtype }}</button> -->

				<button *ngFor="let dtype of dtypes" type="button" class="btn btn-outline-primary btn-sm mb-1 me-1"
					[ngClass]="{'btn-primary': byTypeFilter === dtype, 'btn-outline-primary': byTypeFilter !== dtype }"
					(click)="setByTypeFilter(dtype)">
					{{ dtype }}
				</button>
			</div>
			<hr />

			<ul>
				<li *ngFor="let dtype of dtypes" id="dtype_{{ dtype }}" [hidden]="byTypeFilter !== '' && byTypeFilter !== dtype">
					<span class="fw-bold cp-125">{{ dtype }}</span>
					<fa-icon icon="arrow-alt-to-top" size="sm" class="ms-1" (click)="scrollToSpot('top')" ngbTooltip="Back to Top"
						[hidden]="byTypeFilter !== ''">
					</fa-icon>

					<ul>
						<li *ngFor="let item of integrationsByType(dtype)">
							<fa-icon *ngIf="showExtras && item.partnership.is_listed===0" [icon]="['fas', 'lock']" class="text-warning me-1"
								ngbTooltip="Unpublished" placement="start"></fa-icon>

							{{ item.org_name }}

							<a *ngIf="item.partnership.extras.link && item.partnership.extras.link !== ''"
								(click)="openLink(item.partnership.extras.link)" href="javascript:void(0)">
								{{ item.partnership.name }} <fa-icon [icon]="['fas', 'external-link-square-alt']">
								</fa-icon>
							</a>
							<span *ngIf="!item.partnership.extras.link || item.partnership.extras.link === ''">
								{{ item.partnership.name }}
							</span>

							<span *ngIf="item.partnership.extras.itype && item.partnership.extras.itype !== ''">
								({{ item.partnership.extras.itype }}</span>)

							<div *ngIf="item.partnership.information && item.partnership.information !== ''" class="cp-pre-text ms-2">
								{{ item.partnership.information }}
							</div>
							<div *ngIf="item.partnership.extras.sdk_features && item.partnership.extras.sdk_features !== ''"
								class="cp-pre-text ms-2">
								Zixi Features: {{ item.partnership.extras.sdk_features }}
							</div>

							<div *ngIf="item.partnership.extras.document_link && item.partnership.extras.document_link !== ''" class="ms-2">
								<a (click)="openLink(item.partnership.extras.document_link)" href="javascript:void(0)">
									<fa-icon [icon]="['fas', 'external-link-square-alt']"></fa-icon>
									More Info
								</a>
							</div>

						</li>
					</ul>
				</li>
			</ul>

		</div>

		<div class="tab-pane" id="nav-by-partner" role="tabpanel" aria-labelledby="nav-by-partner-tab">
			<div class="cp-width-limit-1000">
				<!-- <button *ngFor="let orgLetter of orgLetters" type="button"
					class="btn btn-outline-primary btn-sm mb-1 me-1"
					(click)="scrollToSpot('orgLetter_' + (orgLetter === '#' ? 'NUM' : orgLetter))"
					[ngbTooltip]="'Jump to ' + orgLetter">
					{{ orgLetter }}</button> -->

				<button *ngFor="let orgLetter of orgLetters" type="button" class="btn btn-outline-primary btn-sm mb-1 me-1"
					[ngClass]="{'btn-primary': byLetterFilter === orgLetter, 'btn-outline-primary': byLetterFilter !== orgLetter }"
					(click)="setByLetterFilter(orgLetter)">
					{{ orgLetter }}
				</button>
			</div>
			<hr />

			<ul>
				<li *ngFor="let orgLetter of orgLetters" id="orgLetter_{{ (orgLetter === '#' ? 'NUM' : orgLetter) }}"
					[hidden]="byLetterFilter !== '' && byLetterFilter !== orgLetter">
					<span class="fw-bold cp-125">{{ orgLetter }}</span>
					<fa-icon icon="arrow-alt-to-top" size="sm" class="ms-1" (click)="scrollToSpot('top')" ngbTooltip="Back to Top"
						[hidden]="byLetterFilter !== ''">
					</fa-icon>

					<ul>
						<li *ngFor="let org of organizationsByLetter(orgLetter)">
							<span class="fw-bold">{{ org }}</span>
							<ul>
								<li *ngFor="let item of integrationsByOrg(org)">
									<fa-icon *ngIf="showExtras && item.partnership.is_listed===0" [icon]="['fas', 'lock']"
										class="text-warning me-1" ngbTooltip="Unpublished" placement="start"></fa-icon>

									<a *ngIf="item.partnership.extras.link && item.partnership.extras.link !== ''"
										(click)="openLink(item.partnership.extras.link)" href="javascript:void(0)">
										{{ item.partnership.name }} <fa-icon [icon]="['fas', 'external-link-square-alt']">
										</fa-icon>
									</a>
									<span *ngIf="!item.partnership.extras.link || item.partnership.extras.link === ''">
										{{ item.partnership.name }}
									</span>
									({{ item.partnership.extras.dtype }}
									<span *ngIf="item.partnership.extras.itype && item.partnership.extras.itype !== ''">
										- {{ item.partnership.extras.itype }}</span>)

									<div *ngIf="item.partnership.information && item.partnership.information !== ''"
										class="cp-pre-text ms-2">
										{{ item.partnership.information }}
									</div>

									<div *ngIf="item.partnership.extras.sdk_features && item.partnership.extras.sdk_features !== ''"
										class="cp-pre-text ms-2">
										Zixi Features: {{ item.partnership.extras.sdk_features }}
									</div>

									<div *ngIf="item.partnership.extras.document_link && item.partnership.extras.document_link !== ''"
										class="ms-2">
										<a (click)="openLink(item.partnership.document_link)" href="javascript:void(0)">
											<fa-icon [icon]="['fas', 'external-link-square-alt']"></fa-icon>
											More Info
										</a>
									</div>

								</li>
							</ul>
						</li>
					</ul>
				</li>
			</ul>

		</div>


		<div class="tab-pane" id="nav-zm-certified" role="tabpanel" aria-labelledby="nav-zm-certified-tab">
			<img src="assets/images/zm-certified.png" class="cp-zm-cert-large mb-2" />
			<div class="cp-width-limit-1000">
				<!-- <button *ngFor="let dtype of zmDtypes" type="button" class="btn btn-outline-primary btn-sm mb-1 me-1"
					(click)="scrollToSpot('zmDtypes_' + dtype)" [ngbTooltip]="'Jump to ' + dtype">
					{{ dtype }}</button> -->

				<button *ngFor="let dtype of zmDtypes" type="button" class="btn btn-outline-primary btn-sm mb-1 me-1"
					[ngClass]="{'btn-primary': zmByTypeFilter === dtype, 'btn-outline-primary': zmByTypeFilter !== dtype }"
					(click)="setZMByTypeFilter(dtype)">
					{{ dtype }}
				</button>
			</div>
			<hr />

			<ul>
				<li *ngFor="let dtype of zmDtypes" id="zmDtypes_{{ dtype }}" [hidden]="zmByTypeFilter !== '' && zmByTypeFilter !== dtype">
					<span class="fw-bold cp-125">{{ dtype }}</span>
					<fa-icon icon="arrow-alt-to-top" size="sm" class="ms-1" (click)="scrollToSpot('top')" ngbTooltip="Back to Top"
						[hidden]="zmByTypeFilter !== ''">
					</fa-icon>

					<ul>
						<li *ngFor="let item of integrationsByType(dtype)">
							<fa-icon *ngIf="showExtras && item.partnership.is_listed===0" [icon]="['fas', 'lock']" class="text-warning me-1"
								ngbTooltip="Unpublished" placement="start"></fa-icon>

							{{ item.org_name }}

							<a *ngIf="item.partnership.extras.link && item.partnership.extras.link !== ''"
								(click)="openLink(item.partnership.extras.link)" href="javascript:void(0)">
								{{ item.partnership.name }} <fa-icon [icon]="['fas', 'external-link-square-alt']">
								</fa-icon>
							</a>
							<span *ngIf="!item.partnership.extras.link || item.partnership.extras.link === ''">
								{{ item.partnership.name }}
							</span>

							<span *ngIf="item.partnership.extras.itype && item.partnership.extras.itype !== ''">
								({{ item.partnership.extras.itype }}</span>)

							<div *ngIf="item.partnership.information && item.partnership.information !== ''" class="cp-pre-text ms-2">
								{{ item.partnership.information }}
							</div>

							<div *ngIf="item.partnership.extras.sdk_features && item.partnership.extras.sdk_features !== ''"
								class="cp-pre-text ms-2">
								Zixi Features: {{ item.partnership.extras.sdk_features }}
							</div>

							<div *ngIf="item.partnership.extras.document_link && item.partnership.extras.document_link !== ''" class="ms-2">
								<a (click)="openLink(item.partnership.extras.document_link)" href="javascript:void(0)">
									<fa-icon [icon]="['fas', 'external-link-square-alt']"></fa-icon>
									More Info
								</a>
							</div>

						</li>
					</ul>
				</li>
			</ul>
		</div>

		<div class="tab-pane" id="nav-table" role="tabpanel" aria-labelledby="nav-table-tab">
			<div class="cp-width-limit-1400">

				<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

				<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
					<thead>
						<tr>
							<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-center cp-pre-text cp-pointer"
								[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
								[hidden]="!fieldsToShow.includes(cd.field)">
								<span *ngIf="cd.label" [innerHTML]="cd.label"
									[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
									[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
								<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
							</th>
						</tr>
					</thead>

					<tbody *ngIf="itemsToShow && !loading">
						<tr *ngFor="let item of itemsToShow">
							<td [style]="'max-width:' + maxPartner + 'ex'">
								<a *ngIf="showExtras" [routerLink]="['/' + ac.urls.organizations, item.partnership.org_id]">
									{{ item.org_name }}</a>
								<span *ngIf="!showExtras">{{ item.org_name }}</span>
							</td>
							<td [style]="'max-width:' + maxName + 'ex'">
								<fa-icon *ngIf="showExtras && item.partnership.is_listed===0" [icon]="['fas', 'lock']"
									class="text-warning me-1" ngbTooltip="Unpublished" placement="start"></fa-icon>
								<a *ngIf="showExtras" [routerLink]="['/' + ac.urls.partneradmin, item.partnership.id]"
									[ngbPopover]="partnerPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
									container="body">
									{{ item.partnership.name }}</a>
								<span *ngIf="!showExtras">{{ item.partnership.name }}</span>
							</td>
							<td>
								<button *ngIf="item.partnership.extras.link && item.partnership.extras.link !== ''" type="button"
									class="cp-link-btn text-nowrap" (click)="openLink(item.partnership.extras.link)">
									<fa-icon [icon]="['fas', 'external-link-square-alt']"></fa-icon>
									Link
								</button>
							</td>
							<td>
								<button *ngIf="item.partnership.extras.document_link && item.partnership.extras.document_link !== ''"
									type="button" class="cp-link-btn text-nowrap" (click)="openLink(item.partnership.extras.document_link)">
									<fa-icon [icon]="['fas', 'external-link-square-alt']"></fa-icon>
									Link
								</button>
							</td>
							<td [style]="'max-width:' + maxIntType + 'ex'">{{ item.partnership.extras.dtype }}</td>
							<td [style]="'max-width:' + maxSDKType + 'ex'">{{ item.partnership.extras.itype }}</td>
							<td>
								<span
									*ngIf="item.partnership && item.partnership.extras && item.partnership.extras.zm_certified && item.partnership.extras.zm_certified === 1">
									Yes
								</span>
							</td>
							<td style="min-width:150px" class="cp-pre-text">
								{{ item.partnership.information }}
								<div *ngIf="item.partnership.extras.sdk_features && item.partnership.extras.sdk_features !== ''">
									Zixi Features: {{ item.partnership.extras.sdk_features }}
								</div>
							</td>

							<ng-template #partnerPopupContent>
								<div class="cp-popover-banner">{{ item.name }}</div>
								<div *ngIf="canShowExtras" class="cp-pre-text">{{
									popOverTools.getPartnershipPopoverLines(item.partnership, []).join('\n')}}</div>
							</ng-template>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>

</div>