import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class ConfirmEmailService {

	constructor(private http: HttpClient) { }

	// call back-end to add an item
	async confirmEmailChange(token: string) {
		try {
			const result = await this.http.post<Models.User>(AppConstants.apiUrl + AppConstants.apiUrls.auth
				+ AppConstants.apiUrls.confirmEmail, { token }).toPromise();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

}
