<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.files" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">Files</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page" [title]="file.name">
				{{ file.name }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group">
				<!-- <button type="button" class="btn btn-sm btn-outline-secondary" [routerLink] = "['edit']">Edit</button> -->
				<button *ngIf="canDownload" type="button" class="btn btn-outline-primary" (click)="openDownload(id)" ngbTooltip="Download"
					placement="start">
					<fa-icon [icon]="['fas', 'cloud-download-alt']"></fa-icon>
					<!-- Download ({{ file.size | bytes: "1.0-0" }}) -->
				</button>

				<button *ngIf="canEdit" type="button" class="btn btn-outline-primary" [routerLink]="['edit']" ngbTooltip="Edit"
					placement="start">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button *ngIf="canEdit && file.disable_autobuild === 0" type="button" class="btn btn-outline-warning"
					(click)="disableAutobuild()" ngbTooltip="Disabling auto-build will exclude this file from future auto-build runs">
					<fa-icon [icon]="['fas', 'ban']" size="sm"></fa-icon>
					<span class="cp-action-button-text">Disable Auto-Build</span>
				</button>

				<button *ngIf="canEdit && file.disable_autobuild === 1" type="button" class="btn btn-outline-success"
					(click)="enableAutobuild()">
					<fa-icon [icon]="['fas', 'power-off']" size="sm"></fa-icon>
					<span class="cp-action-button-text">Enable Auto-Build</span>
				</button>

				<button *ngIf="canDelete && file.uses.length  === 0 && (!freeUrl || freeUrl !== '')" type="button"
					class="btn btn-outline-danger" (click)="prepDelete()" ngbTooltip="Delete" placement="start">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>
			</div>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-download-log-tab" aria-controls="nav-download-log" data-bs-target="#nav-download-log">
				<fa-icon [icon]="ac.sectionIcons.downloadlogs" size="sm"></fa-icon>
				<!-- Downloads -->
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-admin-log-tab"
				aria-controls="nav-admin-log" data-bs-target="#nav-admin-log">
				<fa-icon [icon]="ac.sectionIcons.adminlogs" size="sm"></fa-icon>
				<!-- Log -->
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">

			<div *ngIf="!!file" class="cp-property-list">

				<div class="cp-property-block">
					<span class="cp-property-label">Name:</span>
					<span class="cp-property-value">{{ file.name }}</span>
				</div>

				<div class="cp-property-block" *ngIf="file.disable_autobuild === 1">
					<span class="cp-property-label">Auto-Build:</span>
					<span class="badge bg-danger">
						<fa-icon [icon]="['fas', 'ban']"></fa-icon> Disabled - this file is ignored by the auto-build
					</span>
				</div>

				<div class="cp-property-block" *ngIf="file.free_access_token && file.free_access_token !== ''">
					<span class="cp-property-label">Free Access Token:</span>
					<span class="cp-property-value">{{ file.free_access_token }}</span>
				</div>

				<div class="cp-property-block" *ngIf="freeUrl && freeUrl !== ''">
					<span class="cp-property-label">Free Access Link:</span>
					<span class="cp-property-value">
						<a [href]="freeUrl" target="_blank">{{ freeUrl }}</a>
						&nbsp;<fa-icon [icon]="['fas', 'copy']" ngxClipboard (click)="copyToClipboardAlert('')" [cbContent]="freeUrl"
							ngbTooltip="Copy to Clipboard"></fa-icon>
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Size:</span>
					<span *ngIf="file.size > 1000" class="cp-property-value">{{ file.size | bytes: "1.0-0" }} /
						{{ file.size | number }}
						bytes</span>
					<span *ngIf="file.size <= 1000" class="cp-property-value">{{ file.size | number }}
						bytes</span>
				</div>

				<!-- <div class="cp-property-block" *ngIf="file.md5 && file.md5 !== ''">
					<span class="cp-property-label">MD5:</span>
					<span class="cp-property-value">{{ file.md5 }}</span>
				</div> -->

				<div class="cp-property-block" *ngIf="file.last_download">
					<span class="cp-property-label">Last Download:</span>
					<span class="cp-property-value">
						{{ file.last_download | date: ac.pageDateTimeFmt }}
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label"># Downloads:</span>
					<span class="cp-property-value">
						{{ file.downloads | number }}
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Added:</span>
					<span class="cp-property-value">
						{{ file.added_on | date: ac.pageDateTimeFmt }}
						<span *ngIf="addedByUser"> by
							<a [routerLink]="['/' + ac.urls.users, addedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ addedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ addedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(addedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="file.added_on !== file.edited_on">
					<span class="cp-property-label">Edited:</span>
					<span class="cp-property-value">
						{{ file.edited_on | date: ac.pageDateTimeFmt }}
						<span *ngIf="editedByUser"> by
							<a [routerLink]="['/' + ac.urls.users, editedByUser.id]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ editedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ editedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(editedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="builds && builds.length != 0">
					<span class="cp-property-label">Builds</span>
					<ul class="mb-0">
						<li *ngFor="let item of builds">
							<fa-icon *ngIf="item.is_private === 1" [icon]="['fas', 'lock']" class="text-warning me-1"
								ngbTooltip="Private - Limited Access"></fa-icon>
							<fa-icon *ngIf="item.is_private === 0" [icon]="['fas', 'globe']" size="sm" class="text-success me-1"
								ngbTooltip="Public - Generally Available"></fa-icon>

							<fa-icon *ngIf="item.is_retired === 1" [icon]="['fas', 'snooze']" class="text-info me-1"
								ngbTooltip="Archived - no longer downloadable without direct access"></fa-icon>

							<fa-icon *ngIf="item.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Build is not enabled"></fa-icon>

							<a [routerLink]="[getBuildsURL(), item.id]" [ngbPopover]="buildPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ item.prod_name }} - {{ item.version }}
								<span *ngIf="item.label && item.label !== ''">({{ item.label }})</span>
							</a>
							<ng-template #buildPopupContent>
								<div class="cp-popover-banner">{{ item.version }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getBuildPopoverLines(item).join('\n')}}</div>
							</ng-template>
						</li>
					</ul>
				</div>

				<div class="cp-property-block" *ngIf="documents && documents.length != 0">
					<span class="cp-property-label">Documents</span>
					<ul class="mb-0">
						<li *ngFor="let item of documents">
							<fa-icon *ngIf="item.is_private === 1" [icon]="['fas', 'lock']" class="text-warning me-1"
								ngbTooltip="Private - Limited Access"></fa-icon>
							<fa-icon *ngIf="item.is_private === 0" [icon]="['fas', 'globe']" size="sm" class="text-success me-1"
								ngbTooltip="Public - Generally Available"></fa-icon>
							<fa-icon *ngIf="item.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Document is not enabled"></fa-icon>
							<a [routerLink]="[getDocumentsURL(), item.id]" [ngbPopover]="docPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ item.label }}
							</a>
							<ng-template #docPopupContent>
								<div class="cp-popover-banner">{{ item.label }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getDocumentPopoverLines(item).join('\n')}}</div>
							</ng-template>

						</li>
					</ul>
				</div>
			</div>

			<!-- <button type="button" class="btn btn-outline-info" (click)="grabDownloadInfo(id)">Get DL Info</button> -->

			<!-- <ul *ngIf="!!downloadLink">
				<li>
					<span class="cp-property-label">Expires:</span>
					<span class="cp-property-value">{{ downloadLink.expires| date: "medium" }} (in
						{{ downloadLink.expiresMinutes }} minutes)</span>
				</li>

				<li>
					<span class="cp-property-label">Command:</span>
					<span class="cp-property-value">{{ downloadLink.sampleCommand }}&nbsp;<fa-icon
							[icon]="['fas', 'copy']" ngxClipboard [cbContent]="downloadLink.sampleCommand"
							ngbTooltip="Copy to Clipboard"></fa-icon></span>
				</li>
			</ul> -->
		</div>

		<div class="tab-pane" id="nav-download-log" role="tabpanel" aria-labelledby="nav-download-log-tab">
			<div *ngIf="downloadLogs.length === 0" class="cp-light-message">
				No download logs of this File.
			</div>

			<button *ngIf="!loading && downloadLogs.length !== 0" type="button" (click)="runDownloadsReport()"
				class="btn btn-sm btn-outline-secondary mb-1">
				<fa-icon icon="file-excel" size="sm"></fa-icon>
				Generate Report
			</button>

			<app-downloads-table #downloadsTable1></app-downloads-table>
		</div>

		<div class="tab-pane" id="nav-admin-log" role="tabpanel" aria-labelledby="nav-admin-log-tab">

			<div *ngIf="!loading && (!!file && adminLogs.length === 0)" class="cp-light-message">
				No system/change Logs for this File.
			</div>

			<app-logs-table #logsTable1 [expandNames]="true"></app-logs-table>
		</div>


	</div>
</div>

<app-popup-box [parentApi]="getParentMethod()"></app-popup-box>