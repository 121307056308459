import { Component, OnInit, OnDestroy } from '@angular/core';
import {
	Event,
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router,
} from '@angular/router';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';

import AppConstants from 'appshared/app-constants';

import { AuthService } from './services/auth.service';
import * as Models from 'appshared/shared-models';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	title = AppConstants.appName;
	private userSubscription: Subscription;
	authUser: Models.AuthUser;
	loggedIn = false;

	loaded = false;
	private isLoading = new BehaviorSubject<boolean>(true);
	get isLoadingRoute() {
		return this.isLoading.asObservable();
	}
	setLoading(val: boolean) {
		this.isLoading.next(val);
	}

	constructor(private authService: AuthService, private router: Router) {
		this.loaded = true;

		// Show spinner while waiting for navigation to end
		this.router.events.subscribe((event: Event) => {
			switch (true) {
				case event instanceof NavigationStart: {
					this.setLoading(true);
					break;
				}

				case event instanceof NavigationEnd:
				case event instanceof NavigationCancel:
				case event instanceof NavigationError: {
					this.setLoading(false);
					break;
				}
				default: {
					break;
				}
			}
		});
	}

	ngOnInit() {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;
			this.loggedIn = authUser && authUser.id !== 0 && authUser.role !== '';
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}
}
