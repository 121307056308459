import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject, interval, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';
import PopOverTools from 'appshared/popover-tools';

import { PartnerAdminService } from '../partner-admin.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { AuthService } from 'client/app/services/auth.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-partner-list',
	templateUrl: './partner-list.component.html',
	styleUrls: ['./partner-list.component.scss']
})
export class PartnerListComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	textTools = TextTools;
	miscTools = MiscTools;
	popOverTools = PopOverTools;

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	now = new Date();

	// 'standard' list stuff
	loading = true;
	refreshing = false;

	items: Models.Partnership[] = [];
	itemsToShow: Models.Partnership[] = [];

	// other stuff
	private userSubscription: Subscription;
	canAdd = false;

	organizations: Models.Organization[] = [];

	// new table stuff
	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = 'cp-partners-list';

	fieldsToShow: string[] = [];

	columnDefs: any[] = [
		{
			field: 'is_listed',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Listed/Hidden',
			icon: 'power-off'
		}, {
			field: '__organization',
			type: 'baseText',
			sortType: 'text',
			label: 'Organization',
			toolTip: null,
			icon: null
		}, {
			field: 'name',
			type: 'baseText',
			sortType: 'text',
			label: 'Name',
			toolTip: null,
			icon: null
		}, {
			field: '__status',
			type: 'baseText',
			sortType: 'text',
			label: 'Status',
			toolTip: null,
			icon: null
		}, {
			field: '__dtype',
			type: 'baseText',
			sortType: 'text',
			label: 'Integration Type',
			toolTip: null,
			icon: null
		}, {
			field: '__itype',
			type: 'baseText',
			sortType: 'text',
			label: 'SDK Type',
			toolTip: null,
			icon: null
		}, {
			field: '__days_since_add',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Days Since Added',
			icon: 'clock'
		}, {
			field: '__days_since_update',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Days Since Updated',
			icon: 'pencil'
		}
	];

	constructor(
		private partnerAdminService: PartnerAdminService,
		private organizationsService: OrganizationsService,
		private authService: AuthService,
	) { }

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		this.loading = true;

		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.canAdd = (authUser && ValidationTools.checkAccess(authUser, 'manage-partnerships'));
			this.loadData();
		});
	}

	// ------------------------------------------------------------------------
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ------------------------------------------------------------------------
	async loadData(refresh: boolean = false) {
		this.loading = true;

		if (refresh)
			await this.partnerAdminService.refreshAll().toPromise();

		this.organizations = this.organizationsService.getAll();
		this.items = this.partnerAdminService.getAll();

		for (const item of this.items) {
			item['__organization'] = this.getOrgName(item.org_id);
			item['__status'] = item.extras.status;
			item['__dtype'] = item.extras.dtype;
			item['__itype'] = item.extras.itype;
			item['__days_since_add'] = MiscTools.diffDays(this.now, item.added_on, true);
			if (item.edited_on)
				item['__days_since_update'] = MiscTools.diffDays(this.now, item.edited_on, true);
			else
				item['__days_since_update'] = 99999999;
		} // for

		this.fieldsToShow = [];
		for (const cd of this.columnDefs)
			this.fieldsToShow.push(cd.field);

		this.loading = false;

		await MiscTools.delay(100);
		if (this.pagingSearchBar)
			this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'name', 'asc');

	}

	// ------------------------------------------------------------------------
	getOrgName(id: number) {
		const idx = MiscTools.findIndex(this.organizations, id);
		if (idx !== -1) return this.organizations[idx].name
		return '';
	}

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.Partnership[] = [];
		for (const item of this.items) {
			let match: boolean = true;

			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.name.toLowerCase().includes(w)
						|| (item.extras.status && item.extras.status.toLowerCase().includes(w))
						|| (item.extras.dtype && item.extras.dtype.toLowerCase().includes(w))
						|| (item.extras.itype && item.extras.itype.toLowerCase().includes(w))
						|| (item['__organization'] && item['__organization'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}

}
