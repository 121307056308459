import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import { LoginService } from './login.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import MiscTools from 'appshared/misc-tools';

@Component({
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	ac = AppConstants;

	loginMode = true;
	isLoading = false;
	showForm = false;
	error: string = null;
	ssoButtonLabel = 'Staff Login';

	staffIcon: any = null;
	staffIconIdx: number = -1;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private loginService: LoginService,
		private uiAlertsService: UiAlertsService) {
	}

	ngOnInit(): void {
		this.randomStaffLogin();
		this.setup();
	}

	randomStaffLogin() {
		// this.staffIcon = this.staffIcons[MiscTools.getRandomMaxInt(this.staffIcons.length)];
		if (AppConstants.loginPageStaffIcons && AppConstants.loginPageStaffIcons.length > 0) {
			this.staffIconIdx = MiscTools.getRandomMaxInt(AppConstants.loginPageStaffIcons.length);
			this.staffIcon = AppConstants.loginPageStaffIcons[this.staffIconIdx];
		} else {
			this.staffIcon = 'hat-wizard';
		} // if
	}

	nextStaffLogin() {
		if (this.staffIconIdx !== -1) {
			this.staffIconIdx++;
			if (this.staffIconIdx >= AppConstants.loginPageStaffIcons.length)
				this.staffIconIdx = 0;
			this.staffIcon = AppConstants.loginPageStaffIcons[this.staffIconIdx];
		} // if
	}

	setup() {
		this.isLoading = true;

		this.loginService.autoLogin()
			.subscribe(responseData => {
				// console.log('in login comp autolo');
				// console.log(responseData);
				if (responseData) {
					let lastUrl = this.loginService.getLastURL();
					if (!lastUrl || lastUrl === '' || lastUrl === '/' + AppConstants.urls.login) {
						lastUrl = AppConstants.defaultLandingPage;
					}
					// console.log('in auto lasturl is ' + lastUrl);
					this.loginService.setLastURL('');
					this.router.navigate([lastUrl]);
					this.isLoading = false;
				} else {
					this.isLoading = false;
					this.showForm = true;
				}
			}, () => {
				this.isLoading = false;
				this.showForm = true;
			});
	}

	onSubmit(authForm: NgForm) {
		if (!authForm.valid) {
			return;
		}
		this.isLoading = true;
		// console.log('login onsubmit');

		const email = authForm.value.email;
		const password = authForm.value.password;

		this.loginService.login(email, password)
			.subscribe(responseData => {
				// console.log(responseData);
				this.error = null;
				this.isLoading = false;
				authForm.reset();

				// console.log('lastUrl from loginService = ' + this.loginService.lastUrl);
				// console.log('kickedOutOf from loginService = ' + this.loginService.kickedOutOf);

				let lastUrl = this.loginService.getLastURL();

				// console.log('lastUrl from loginService = ' + lastUrl);

				if (!lastUrl || lastUrl === '' || lastUrl === '/' + AppConstants.urls.login) {
					// this.router.navigate([AppConstants.urls.dashboard]);
					lastUrl = AppConstants.defaultLandingPage;
				}
				// console.log('in login lasturl is ' + lastUrl);
				this.loginService.setLastURL('');

				this.uiAlertsService.clearMsgByCode('LOGIN');
				this.showForm = false;

				// console.log('redir to = ' + lastUrl);

				this.router.navigate([lastUrl]);
			}, error => {
				this.uiAlertsService.addMsg(error, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
				// this.error = error; // for the *ngIf way...
				this.isLoading = false;
			});
	}

	getCurrentYear() {
		return (new Date()).getUTCFullYear();
	}

	testMsg(closeTime: number = 0) {
		this.uiAlertsService.addMsg('This is a test.  This is a vert long message that should wrap. So there.\nAnd there.', 'warning', '', false, closeTime);

	}
}
