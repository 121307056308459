import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';

import { UserGroupsService } from '../user-groups.service';
import { AuthService } from 'client/app/services/auth.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-user-group-access-form',
	templateUrl: './user-group-access-form.component.html',
	styleUrls: ['./user-group-access-form.component.scss']
})
export class UserGroupAccessFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	id: number;
	group: Models.UserGroup;

	tags: string[] = [];

	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	// other stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private userGroupsService: UserGroupsService,
		private authService: AuthService
	) {
		this.route.paramMap.subscribe((params) => {
			this.id = +params.get('id');
			if (this.id && this.id !== 0) {
				this.group = this.userGroupsService.getOne(this.id);
			}
			if (!this.group || this.group == null || this.group.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
			}

			// this.tags = Object.keys(AppConstants.accessControlTags);

			this.loading = false;
		});
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			if (!authUser || !ValidationTools.checkRole(authUser.role, AppConstants.adminUserRole))
				this.onCancel();

			this.initForm();
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	initForm() {
		// using form control names that match object's property names makes
		// saving easier down the line...
		this.theForm = new UntypedFormGroup({
		});

		for (const accessControlTagGrouping of AppConstants.accessControlTagGroupings) {
			for (const tag of accessControlTagGrouping.tags) {
				const checked = this.group.accessTags.includes(tag);
				this.theForm.addControl(tag, new UntypedFormControl(checked));
			}
		}
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const userTags = [];
		for (const accessControlTagGrouping of AppConstants.accessControlTagGroupings)
			for (const tag of accessControlTagGrouping.tags)
				if (this.theForm.value[tag]) userTags.push(tag);

		// console.log(userTags);

		try {
			const retGroup = await this.userGroupsService.setAccessTags(this.id, userTags);
			if (retGroup) {
				this.router.navigate([AppConstants.urls.usergroups + '/' + retGroup.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['/' + AppConstants.urls.usergroups, this.id], { relativeTo: this.route });
	}

	isChecked(tag: string) {
		return this.theForm.value[tag];
	}


}
