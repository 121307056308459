import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share, map, tap } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';

import { AuthService } from 'client/app/services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class MyNotificationsService {
	private myUser: Models.User;

	constructor(
		private http: HttpClient,
		private authService: AuthService) { }

	wipe() {
		this.myUser = null;
	}

	async getUser(forceRefresh = false) {
		if (!this.myUser || forceRefresh)
			this.myUser = await this.fetchUser();
		return this.myUser;
	}

	async fetchUser() {
		try {
			const result = await this.http.get<Models.User>(AppConstants.apiUrl + AppConstants.apiUrls.mysettings).toPromise();
			const obj: Models.User = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async updateNotificationSettings(settings: Models.NotificationSettings) {
		try {
			const result = await this.http.put<Models.User>(AppConstants.apiUrl + AppConstants.apiUrls.mysettings
				+ '/notification-settings', settings).toPromise();
			const updatedUser: Models.User = result;
			const newAuthUser = new Models.AuthUser(updatedUser.id, updatedUser.email, updatedUser.name, updatedUser.role, '');
			for (const key of AppConstants.authObjectSettings) {
				const val = TextTools.getUserPropValue(updatedUser, key);
				if (val && val !== '')
					newAuthUser.settings.push(new Models.UserSetting(0, key, val));
			}
			newAuthUser.mergedAccessTags = updatedUser.mergedAccessTags;

			this.authService.setAuthUser(newAuthUser);
			this.myUser = updatedUser;
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchTimezones() {
		try {
			const result = await this.http.get<string[]>(AppConstants.apiUrl + AppConstants.apiUrls.mysettings
				+ '/timezones').toPromise();
			const arr: string[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}



}
