import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class OrganizationGroupsService {
	groups: Observable<Models.OrganizationGroup[]>;
	loaded = false;
	private debug = false;
	private _groups: ReplaySubject<Models.OrganizationGroup[]>;
	private dataStore: {
		groups: Models.OrganizationGroup[];
	};

	constructor(private http: HttpClient) {
		this.dataStore = {
			groups: []
		};
		this._groups = new ReplaySubject(1) as ReplaySubject<Models.OrganizationGroup[]>;
		this.groups = this._groups.asObservable();
	}

	// add/update an item in the datastore
	private updateStore(newGroup: Models.OrganizationGroup): void {
		const idx = this.dataStore.groups.findIndex(group => group.id === newGroup.id);
		if (idx === -1) {
			this.dataStore.groups.push(newGroup);
			return;
		} else {
			this.dataStore.groups[idx] = newGroup;
		}
	}

	// get a fresh copy of the entire set
	refreshAll(): Observable<Models.OrganizationGroup[]> {
		const groups$ = this.http.get<Models.OrganizationGroup[]>(AppConstants.apiUrl + AppConstants.apiUrls.organizationgroups).pipe(share());
		groups$.subscribe(
			data => {
				const groups: Models.OrganizationGroup[] = data;
				// remove ones from the store that aren't in the response (they've been deleted)
				this.dataStore.groups.forEach((existingGroup, existingIndex) => {
					const newIndex = groups.findIndex(group => group.id === existingGroup.id);
					if (newIndex === -1) this.dataStore.groups.splice(existingIndex, 1);
				});
				// add/update all the ones that came back
				groups.forEach(group => this.updateStore(group));
				this._groups.next(Object.assign({}, this.dataStore).groups);
				this.loaded = true;
			},
			error => { /* console.log(error) */ }
		);
		return groups$;
	}

	// get a fresh copy of a single item
	refreshOne(id: number): Observable<Models.OrganizationGroup> {
		const group$ = this.http.get<Models.OrganizationGroup>(AppConstants.apiUrl + AppConstants.apiUrls.organizationgroups + '/' + id).pipe(share());
		group$.subscribe(
			data => {
				const group: Models.OrganizationGroup = data;
				// add/update the one that came back
				this.updateStore(group);
				this._groups.next(Object.assign({}, this.dataStore).groups);
			},
			error => { /* console.log(error) */ }
		);
		return group$;
	}

	// return the whole list
	getAll(): Models.OrganizationGroup[] {
		return this.dataStore.groups.slice();
	}

	// grab a single item from the datastore
	getOne(id: number): Models.OrganizationGroup {
		return this.dataStore.groups.find(group => group.id === id);
	}

	// call back-end to add an item
	async addOne(group: Models.OrganizationGroup) {
		try {
			const result = await this.http.post<Models.OrganizationGroup>(AppConstants.apiUrl + AppConstants.apiUrls.organizationgroups, group).toPromise();
			const returnedGroup: Models.OrganizationGroup = result;
			this.updateStore(returnedGroup);
			this._groups.next(Object.assign({}, this.dataStore).groups);
			return returnedGroup;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update an item
	async updateOne(group: Models.OrganizationGroup) {
		try {
			const result = await this.http.put<Models.OrganizationGroup>(AppConstants.apiUrl + AppConstants.apiUrls.organizationgroups + '/' + group.id, group).toPromise();
			const returnedGroup: Models.OrganizationGroup = result;
			this.updateStore(returnedGroup);
			this._groups.next(Object.assign({}, this.dataStore).groups);
			return returnedGroup;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to delete an item
	async deleteOne(id: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl + AppConstants.apiUrls.organizationgroups + '/' + id).toPromise();
			const idx = this.dataStore.groups.findIndex(group => group.id === id);
			if (idx !== -1) this.dataStore.groups.splice(idx, 1);
			this._groups.next(Object.assign({}, this.dataStore).groups);
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// ********************************************************
	// other non-standard methods
	// ********************************************************

	// call back-end to enable/disable
	async toggleEnabled(id: number) {
		try {
			const result = await this.http.put<Models.OrganizationGroup>(AppConstants.apiUrl + AppConstants.apiUrls.organizationgroups + '/' + id + '/toggle-enabled', {}).toPromise();
			const returnedGroup: Models.OrganizationGroup = result;
			this.updateStore(returnedGroup);
			this._groups.next(Object.assign({}, this.dataStore).groups);
			return returnedGroup;
		} catch (error) {
		}
	}
}
