<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.products" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" routerLink="..">Products</a>
			</li>
			<li *ngIf="editMode" class="breadcrumb-item active" aria-current="page">
				{{ product.name }}
			</li>
			<li *ngIf="!editMode" class="breadcrumb-item active" aria-current="page">
				New Product
			</li>
		</ol>
	</nav>
	<!--<h1 class="h3" *ngIf="editMode">Update Product</h1>
  <h1 class="h3" *ngIf="!editMode">New Product</h1>-->
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-platforms-tab"
				aria-controls="nav-platforms" data-bs-target="#nav-platforms">
				Platforms
				<span class="cp-tab-badge">{{ counter | number }}</span>
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<form [formGroup]="theForm" class="tab-form">

		<div class="tab-content" id="nav-tabContent">
			<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">
				<div class="cp-general-width-limit">
					<div class="form-group">
						<label for="ptype">Type</label>
						<div class="form-check" *ngFor="let ptype of ac.productTypes">
							<input class="form-check-input" type="radio" formControlName="ptype" name="ptype" [id]="'ptype_' + ptype"
								[value]="ptype" />
							<label class="form-check-label" [for]="'ptype_' + ptype">
								{{ ac.productTypeLabels[ptype] }}
							</label>
						</div>
					</div>

					<div class="form-group">
						<label for="name">Name*</label>
						<input type="text" id="name" formControlName="name" class="form-control" />
					</div>

					<div class="form-group">
						<label for="filename_prefix">Filename Prefix (for auto-build)</label>
						<input type="text" id="filename_prefix" formControlName="filename_prefix" class="form-control" />
					</div>

					<div class="form-group">
						<label for="information">Information</label>
						<textarea id="information" formControlName="information" class="form-control" rows="4"></textarea>
					</div>

					<div class="form-group">
						<label for="download_build_description">Download List Description (Build):</label>
						<textarea id="download_build_description" formControlName="download_build_description" class="form-control"
							rows="4"></textarea>
					</div>

					<div class="form-group">
						<label for="download_document_description">Download List Description (Document):</label>
						<textarea id="download_document_description" formControlName="download_document_description" class="form-control"
							rows="4"></textarea>
					</div>

					<div *ngFor="let flag of ac.productFlags" class="form-group form-check mb-1">
						<input type="checkbox" class="form-check-input" [id]="'flag_' + flag.key" [formControlName]="'flag_' + flag.key" />
						<label class="form-check-label" [for]="'flag_' + flag.key">{{ flag.label }}</label>
					</div>
				</div>
			</div>

			<div class="tab-pane" id="nav-platforms" role="tabpanel" aria-labelledby="nav-platforms-tab">
				<div class="cp-general-width-limit">
					<ul class="list-unstyled">
						<li *ngFor="let platformType of ac.platformTypes" class="ms-4">
							<label>
								<input type="checkbox" (click)="checkAllForPlatformType($event, platformType)" class="align-middle mb-1" />
								{{ ac.platformTypeLabels[platformType] }}
							</label>
							<ul class="list-unstyled">
								<li *ngFor="let platform of subListOfPlatforms(platformType)" class="ms-4">
									<label>
										<input type="checkbox" [id]="platform.id" [formControlName]="
									'plat_' + platform.id
									" class="align-middle mb-1" (click)="adjustCounter($event)" />
										{{ platform.name }}
									</label>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div *ngIf="!loading && theForm" class="cp-form-buttons">
			<div class="cp-form-buttons-inner">
				<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
					<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
				</button>
				<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
					<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
				</button>

				<span class="gj-75 ps-2">* Required</span>
			</div>
		</div>
	</form>
</div>