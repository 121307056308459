import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import PopOverTools from 'appshared/popover-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { QuizzesService } from '../quizzes.service';

@Component({
	selector: 'app-reorder-questions-form',
	templateUrl: './reorder-questions-form.component.html',
	styleUrls: ['./reorder-questions-form.component.scss']
})

export class ReorderQuestionsFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	popOverTools = PopOverTools;
	textTools = TextTools;
	miscTools = MiscTools;

	loading = true;
	saving = false;
	loadingResults = false;

	// 'standard' view stuff
	authUser: Models.AuthUser;
	id: number;
	quiz: Models.Quiz;
	private userSubscription: Subscription;

	numChanges: number = 0;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private quizzesService: QuizzesService,
		private uiAlertsService: UiAlertsService
	) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.userSubscription = this.authService.user.subscribe((authUser) => {
				this.authUser = authUser;
				this.loadData();
			});
		});
	}

	// ************************************************************************************
	ngOnInit(): void {
		// changes

	}

	// ************************************************************************************
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ************************************************************************************
	async loadData() {
		this.loading = true;

		this.quiz = MiscTools.deepClone(await this.quizzesService.getOne(this.id));
		if (!this.quiz || this.quiz == null || this.quiz.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		} // if

		if (this.quiz.config.questions.length === 0) {
			this.router.navigate([AppConstants.urls.quizzes, this.id]);
			return;
		} // if

		this.loading = false;
	}

	// ************************************************************************************
	drop(event: CdkDragDrop<Models.LicenseProperty[]>) {
		if (event.previousIndex !== event.currentIndex) {
			MiscTools.moveItemInArray(this.quiz.config.questions, event.previousIndex, event.currentIndex);
			this.numChanges++;
		} // if
	}

	// ************************************************************************************
	async onSubmit() {
		this.saving = true;
		try {
			let doRefresh = false;
			if (this.numChanges > 0) {

				await this.quizzesService.updateOne(this.quiz);

				doRefresh = true;
			} // if

			this.uiAlertsService.addMsg('The sort order has been updated.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			this.onCancel();
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	// ************************************************************************************
	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

}
