<div class="login-container">
	<div class="row">
		<div class="col text-center">
			<img width="160" alt="Zixi logo" src="assets/images/zixi-white.png" />
		</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card mt-3 mb-3 text-start mx-auto" [ngStyle]="{ 'max-width.px': 600 }">


				<div *ngIf="!finished" class="card-body">
					<form *ngIf="theForm" [formGroup]="theForm" (ngSubmit)="onSubmit()">
						<div>
							<h3 class="text-center">Zixi on Azure Marketplace</h3>
							<div *ngIf="saving" class="text-center">
								<app-loading-spinner></app-loading-spinner>
							</div>

							<div class="form-group mt-1 mb-1 p-2 border text-center">
								Please provide the following info so that we can setup your account.
							</div>

							<div class="row">
								<div class="form-group mb-1 col-sm">
									<label for="firstname">First Name*</label>
									<input type="text" id="firstname" formControlName="firstname" class="form-control"
										placeholder="First Name" minlength="1" />
								</div>

								<div class="form-group mb-1 col-sm">
									<label for="lastname">Last Name*</label>
									<input type="text" id="lastname" formControlName="lastname" class="form-control" placeholder="Last Name"
										minlength="1" />
								</div>
							</div>

							<div class="row">
								<div class="form-group mb-1 col-sm">
									<label for="email">E-Mail*</label>
									<input type="email" id="email" formControlName="email" class="form-control" placeholder="E-Mail address"
										minlength="1" />
								</div>

								<div class="form-group mb-1 col-sm">
									<label for="phone">Phone*</label>
									<input type="tel" id="phone" formControlName="phone" class="form-control" placeholder="Phone"
										minlength="1" />
								</div>
							</div>

							<div class="row">
								<div class="form-group mb-1 col-sm">
									<label for="company">Company*</label>
									<input type="text" id="company" formControlName="company" class="form-control" placeholder="Company"
										minlength="1" />
								</div>

								<div class="form-group mb-1 col-sm">
									<label for="title">Title/Position*</label>
									<input type="text" id="title" formControlName="title" class="form-control" placeholder="Title/Position"
										minlength="1" />
								</div>
							</div>

							<div class="row">
								<div class="form-group mb-1 col-sm">
									<label for="country">Country*</label>
									<ng-select [items]="ac.countries" bindValue="code" bindLabel="label" id="country"
										formControlName="country" [multiple]="false" placeholder="Country">
									</ng-select>
								</div>

								<div class="col-sm">
									<div *ngIf="country && country.value === 'US'" class="form-group mb-1">
										<label for="title">State*</label>
										<ng-select [items]="ac.usStates" bindValue="code" bindLabel="label" id="state"
											formControlName="state" [multiple]="false" placeholder="US State">
										</ng-select>
									</div>

									<div *ngIf="country && country.value === 'CA'" class="form-group mb-1">
										<label for="title">Province*</label>
										<ng-select [items]="ac.cdnProvinces" bindValue="code" bindLabel="label" id="state"
											formControlName="state" [multiple]="false" placeholder="Canadian Province">
										</ng-select>
									</div>
								</div>
							</div>

							<div *ngIf="subscription" class="form-group mt-1 mb-1 p-2 border text-center">
								<div>Subscription Information</div>
								<div>Subscription ID: {{ subscription.id }}</div>
								<div>Subscription Name: {{ subscription.name }}</div>
								<div>Product/Offer: {{ productName }}</div>
							</div>

							<div class="form-group mt-1 mb-1 p-2 border text-center">
								Zixi will be in touch with you shortly.
							</div>

							<div class="form-group mb-2 text-center">
								<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid || saving">
									<fa-icon [icon]="['fas', 'check']"></fa-icon> Submit Registration
								</button>
							</div>

							<div class="form-group mb-2 small text-center">* Required</div>

							<div class="form-group mb-2 text-center">
								<hr />
							</div>

							<div class="form-group mb-2 small text-center">
								<a [href]="ac.supportURL" target='_blank'>
									<fa-icon [icon]="['fas', 'life-ring']"></fa-icon> Need Help?
								</a>
							</div>

						</div>
					</form>
				</div>

				<div *ngIf="finished" class="card-body">
					Thank you for your registration. You've been sent an confirmation e-mail.<br />
					Zixi will be in touch with you shortly.
				</div>
			</div>
		</div>
	</div>
</div>