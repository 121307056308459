<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="ac.sectionIcons.mysettings" class="cp-user-nav-item me-1" size="sm">
				</fa-icon>
				My Settings
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group" *ngIf="!!user">
				<button type="button" class="btn btn-outline-primary" [routerLink]="['edit']">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<span class="cp-action-button-text">Update Settings</span>
				</button>
				<button *ngIf="user.use_sso === 0" type="button" class="btn btn-outline-primary" [routerLink]="['email']">
					<fa-icon [icon]="['fas', 'at']" size="sm"></fa-icon>
					<span class="cp-action-button-text">Change E-Mail</span>
				</button>
				<button *ngIf="user.use_sso === 0" type="button" class="btn btn-outline-primary" [routerLink]="['password']">
					<fa-icon [icon]="['fas', 'lock']" size="sm"></fa-icon>
					<span class="cp-action-button-text">Change Password</span>
				</button>
			</div>
		</div>
	</div>
</div>


<div class="tab-container">
	<ul *ngIf="user && user.org_id && user.org_id !== 0" class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				My Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-org-tab"
				aria-controls="nav-org" data-bs-target="#nav-org">
				<fa-icon [icon]="ac.sectionIcons.organizations" size="sm"></fa-icon>
				My Organization
			</button>
		</li>

		<app-loading-spinner *ngIf="user && user.org_id && user.org_id !== 0 && loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" role="tabpanel" id="nav-base" aria-labelledby="nav-base-tab">

			<div *ngIf="(!user || !user.org_id || user.org_id === 0) && loading" style="text-align: center;">
				<app-loading-spinner></app-loading-spinner>
			</div>

			<div *ngIf="!!user" class="cp-property-list">
				<div class="cp-property-block">
					<span class="cp-property-label">E-Mail:</span>
					<span class="cp-property-value">{{ user.email }}</span>
				</div>

				<div class="cp-property-block" *ngIf="stagedEmail !== ''">
					<span class="cp-property-label">New E-Mail (to be confirmed):</span>
					<span class="cp-property-value">{{ stagedEmail }}</span>
				</div>

				<div class="cp-property-block" *ngIf="user.use_sso === 0">
					<span class="cp-property-label">Password:</span>
					<span class="cp-property-value">**********</span>
				</div>
				<div class="cp-property-block">
					<span class="cp-property-label">Name:</span>
					<span class="cp-property-value">{{ user.name }}</span>
				</div>
				<div class="cp-property-block" *ngIf="user.title && user.title !== ''">
					<span class="cp-property-label">Title:</span>
					<span class="cp-property-value">{{ user.title }}</span>
				</div>

				<div class="cp-property-block" *ngIf="starPref !== ''">
					<span class="cp-property-label">Icon for Favorites:</span>
					<span class="cp-property-value">
						<fa-icon [icon]="['fas', starPref]"></fa-icon>
					</span>
				</div>
			</div>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-org" aria-labelledby="nav-org-tab">

			<table class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<th>Name</th>
						<th>E-Mail</th>
						<th>Title</th>
						<th>Last Login</th>
						<th>Last Download</th>
					</tr>
				</thead>
				<tbody *ngIf="orgUsers.length !== 0">
					<tr *ngFor="let user of orgUsers">
						<td>{{ user.name }}</td>
						<td>{{ user.email }}</td>
						<td>{{ user.title }}</td>
						<td class="text-nowrap" [attr.data-order]="textTools.formatDateTimeUTC(user.last_login)">
							{{ user.last_login | date: ac.tableDateFmt }}
						</td>
						<td class="text-nowrap" [attr.data-order]="textTools.formatDateTimeUTC(user.last_download)">
							{{ user.last_download | date: ac.tableDateFmt }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>