import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

import { AuthService } from 'client/app/services/auth.service';
import { LicensingAdminService } from '../licensing-admin.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-licensing-property-sort',
	templateUrl: './licensing-property-sort.component.html',
	styleUrls: ['./licensing-property-sort.component.scss']
})

export class LicensingPropertySortComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	id: number;
	properties: Models.LicenseProperty[] = [];

	propertiesByType: any = null;
	typeChanges: any = null;

	errors: string[] = [];
	// theForm: FormGroup;
	loading = true;
	saving = false;

	ptypes = ['boolean', 'number', 'other'];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService,
		private licensingAdminService: LicensingAdminService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
		});
	}

	ngOnInit(): void {
		this.initForm();
	}

	ngOnDestroy() {
	}

	async initForm() {
		this.properties = await this.licensingAdminService.getProperties();
		this.properties.sort((a, b) => (a.sort_order > b.sort_order
			|| (a.sort_order === b.sort_order && a.label > b.label)) ? 1 : -1);

		this.propertiesByType = {};
		this.typeChanges = {};
		for (const property of this.properties) {
			if (!this.propertiesByType[property.ptype]) this.propertiesByType[property.ptype] = [];
			this.propertiesByType[property.ptype].push(property);
		}

		// this.theForm = new FormGroup({ });
		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		try {
			let doRefresh = false;
			for (const ptype of this.ptypes) {
				if (this.typeChanges[ptype]) {
					const ids: number[] = [];
					for (const prop of this.propertiesByType[ptype])
						ids.push(prop.id);
					await this.licensingAdminService.setSortOrderForPropertyType(ptype, ids, false);
					doRefresh = true;
				}
			}
			if (doRefresh) await this.licensingAdminService.refreshObjects();
			this.saving = false;
			this.onCancel();
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	// getPropertiesByType(type: string) {
	// 	const sublist: Models.LicenseProperty[] = [];
	// 	for (const prop of this.properties)
	// 		if (prop.ptype === type) sublist.push(prop);
	// 	return sublist;
	// }

	drop(ptype: string, event: CdkDragDrop<Models.LicenseProperty[]>) {
		if (event.previousIndex !== event.currentIndex) {
			MiscTools.moveItemInArray(this.propertiesByType[ptype], event.previousIndex, event.currentIndex);
			if (!this.typeChanges[ptype]) this.typeChanges[ptype] = true;
			this.typeChanges[ptype]++;
		} // if
	}

	// get message() { return this.theForm.get('message'); }
}
