<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="ac.sectionIcons.myquestions" class="cp-user-nav-item me-1" size="sm" [spin]="loading">
				</fa-icon>
				Ask Zixi... <sup class="badge bg-info text-white cp-75">BETA</sup>
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button type="button" class="btn btn-outline-secondary" (click)="loadData()" [disabled]="loading">
				<fa-icon icon="sync-alt" size="sm" [spin]="loading || checking" ngbTooltip="Refresh" placement="start"></fa-icon>
			</button>
		</div>
	</div>
</div>

<div class="list-wrapper cp-width-limit-1000">
	<form *ngIf="questionForm" [formGroup]="questionForm" class="p-2 m-1">
		<div class="cp-compact-form-group">
			<!-- <label for="question">
				Question
			</label> -->
			<input type="text" id="question" formControlName="question" class="form-control text-center"
				placeholder="Ask your question here" />
		</div>
		<div class="text-center">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!questionForm.valid" (click)="askQuestion()">
				Ask Question
			</button>
		</div>
	</form>

	<div [hidden]="!viewableQuestions || viewableQuestions.length === 0">
		<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>
	</div>

	<div *ngIf="questionsToShow && questionsToShow.length !== 0" class="container">
		<div *ngFor="let item of questionsToShow" class="p-2 border border-dark mb-2 rounded">

			<div *ngIf="item.response && item.response.textResponse && item.response.textResponse !== ''" class="float-end clearfix">
				<button type="button" class="btn-close bg-white" (click)="hideQuestion(item.id)"
					ngbTooltip="Hide this Question and Answer"></button>
			</div>

			<div class="cp-75 lh-sm">
				{{ item.added_on | date: ac.pageDateTimeFmt }}
			</div>

			<div class="fw-bold">
				{{ item.question }}
			</div>

			<div *ngIf="!item.response || !item.response.textResponse">
				Give us a few moments, we're processing your question...
			</div>

			<div *ngIf="item['__answerToShow'] && item['__answerToShow'] !== ''">
				<div class="cp-zixi-answer" [innerHTML]="item['__answerToShow']"></div>
				<!-- [ngbTooltip]="item['__numSources'] + ' sources'" -->
				<!-- <div *ngIf="item['__numSources'] > 0">
					Sources:
					<div *ngFor="let source of item['__sources']" class="ms-1">
						{{ source }}
					</div>
				</div> -->
				<div class="fw-bold mt-1 text-center">
					Was this answer helpful?
					<span class="cp-pointer cp-110 p-1" (click)="rateQuestion(item.id, 2)"
						[ngClass]="{ 'text-info': item.helpful===0, 'text-success border border-success': item.helpful===2, 'text-dark': item.helpful===1 }">
						<fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon>
						Yes
					</span>

					<span class="cp-pointer cp-110 p-1" (click)="rateQuestion(item.id, 1)"
						[ngClass]="{ 'text-info': item.helpful===0, 'text-success border border-success': item.helpful===1, 'text-dark': item.helpful===2 }">
						<fa-icon [icon]="['fas', 'thumbs-down']" transform="down-2"></fa-icon>
						No
					</span>
				</div>
			</div>
		</div>
	</div>
</div>