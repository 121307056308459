import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';

import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { UsageDashboardComponent } from './usage-dashboard/usage-dashboard.component';
import { ChangeLogComponent } from './change-log/change-log.component';

import { OrganizationsResolverService } from '../organizations/organizations-resolver.service';

const routes: Routes = [
	{
		path: '',
		component: MainDashboardComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole },
		resolve: []
	},
	{
		path: 'usage',
		component: UsageDashboardComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService]
	},
	{
		path: 'change-log',
		component: ChangeLogComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: []
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DashboardRoutingModule { }
