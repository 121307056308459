import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share, map, tap } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class MyQuestionsService {

	constructor(private http: HttpClient) {
	}

	// *************************************
	async getAll() {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.myquestions;
			const result = await this.http.get<Models.ZixiQuestion[]>(url).toPromise();
			const arr: Models.ZixiQuestion[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
			return null;
		} // try
	}

	// *************************************
	async getOne(id: number) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.myquestions + '/' + id;
			const result = await this.http.get<Models.ZixiQuestion>(url).toPromise();
			const obj: Models.ZixiQuestion = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
			return null;
		}
	}

	// *************************************
	async addQuestion(question: string) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.myquestions;
			const result = await this.http.post(url, { question }).toPromise();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
			return null;
		}
	}

	// *************************************
	async setHelpful(id: number, helpful: number) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.myquestions + '/' + id + '/helpful/' + helpful;
			const result = await this.http.put(url, {}).toPromise();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
			return null;
		}
	}

	// *************************************
	async setHidden(id: number, hidden: number) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.myquestions + '/' + id + '/hidden/' + hidden;
			const result = await this.http.put(url, {}).toPromise();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
			return null;
		}
	}

}
