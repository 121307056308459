import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';

import { AuthService } from 'client/app/services/auth.service';
import { LicensingService } from '../licensing.service';
import { LicensingAdminService } from '../../licensing-admin/licensing-admin.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-set-template-form',
	templateUrl: './set-template-form.component.html',
	styleUrls: ['./set-template-form.component.scss']
})
export class SetTemplateFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	id: number;
	activation: Models.LPActivation;

	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	// other stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	// template: Models.KeyTemplate;
	templates: Models.KeyTemplate[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private licensingService: LicensingService,
		private licensingAdminService: LicensingAdminService,
		private authService: AuthService
	) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
		});
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;

			if (!ValidationTools.checkAccess(authUser, 'manage-key-templates')) {
				this.onCancel();
				return;
			};

			this.loadData();
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData() {
		this.activation = await this.licensingService.getOne(this.id);
		if (!this.activation || this.activation == null || this.activation.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		}

		const allTemplates: Models.KeyTemplate[] = await this.licensingAdminService.getTemplates();
		// if (this.activation.zcp_template_id && this.activation.zcp_template_id !== 0)
		// 	this.template = await this.licensingAdminService.getTemplate(this.activation.zcp_template_id);

		this.templates = [];
		for (const template of allTemplates)
			if (template.product === this.activation.product)
				this.templates.push(template);
		this.templates.sort((a, b) => (a.name > b.name) ? 1 : -1);

		this.initForm();
	}

	initForm() {
		this.theForm = new UntypedFormGroup({
			zcp_template_id: new UntypedFormControl(this.activation.zcp_template_id, [Validators.required])
		});
		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const templateID = +this.theForm.value.zcp_template_id;

		try {
			if (templateID && templateID !== 0) {
				await this.licensingService.linkKeyToTemplate(this.activation.id, templateID);
				this.onCancel();
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['/' + AppConstants.urls.licensing, 'activation', this.id]);
	}
}
