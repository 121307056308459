import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';

import * as Models from 'appshared/shared-models';
import { OrganizationGroupsService } from './organization-groups.service';

// this thing (a resolve) will run before a route is routed
// basically, grab the items from the service

@Injectable({ providedIn: 'root' })
export class OrganizationGroupsResolverService implements Resolve<Models.OrganizationGroup[]> {
	constructor(private objService: OrganizationGroupsService) { }

	resolve(): Observable<Models.OrganizationGroup[]> | Observable<never> | Models.OrganizationGroup[] {
		// check to see if the items have ever been loaded.  If not, load'em
		if (this.objService.loaded) {
			// console.log('in resolver - the list is alive!');
			return this.objService.getAll();
		} else {
			// console.log('in resolver - need a new list');
			return new Observable((observe: Subscriber<Models.OrganizationGroup[]>) => {
				this.objService.refreshAll().subscribe((items: Models.OrganizationGroup[]) => {
					observe.next(items);
					observe.complete();
				});
			});
		}
	}
}

