import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share, map, tap } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class AppSettingsService {
	salesForceUrl = null;

	constructor(private http: HttpClient) {
	}

	async getSalesForceUrl() {
		if (!this.salesForceUrl)
			await this.fetchSalesForceUrl();
		return this.salesForceUrl;
	}

	async fetchSalesForceUrl() {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.appsettings + '/salesforce-url';
			const result = await this.http.get<any>(url).toPromise();
			if (result && result.url) this.salesForceUrl = result.url;
			return false;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

}
