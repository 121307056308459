import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import { AuthService } from '../services/auth.service';
import { LoginService } from '../components/login/login.service';


@Injectable({
	providedIn: 'root'
})
export class RoleGuard implements CanActivate, CanActivateChild, CanLoad {
	private debug = false;

	constructor(private router: Router, private authService: AuthService, private loginService: LoginService) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const url = state.url;
		// const url = next.url.toString();
		// const url = this.getResolvedUrl(next);
		return (this.checkAuth('canActivate', next.data.role, url));
	}

	canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const url = state.url;
		// const url = next.url.toString();
		// const url = this.getResolvedUrl(next);
		return (this.checkAuth('canActivateChild', next.data.role, url));
	}

	canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
		// const url = `/${route.path}`;
		const url = segments.reduce((path, currentSegment) => {
			return `${path}/${currentSegment.path}`;
		}, '');

		return (this.checkAuth('canLoad', route.data.role, url));
	}

	private checkAuth(method: string, minrole: string, url: string): Observable<boolean> {
		return this.authService.user.pipe(
			take(1), // only take the latest user value then unsubscribe for clean up
			map((user: Models.AuthUser) => {
				if (this.debug) { console.log('Role guard: ' + method + ' : minrole is:' + minrole + ': url:' + url); }

				if (user) {
					// get this from user/auth service...
					const userrole = user.role;

					const uIdx = AppConstants.userRoles.indexOf(userrole);
					const mIdx = AppConstants.userRoles.indexOf(minrole);
					if (this.debug) { console.log('uIdx: ' + uIdx + ' : mIdx:' + mIdx); }

					if (uIdx < mIdx || uIdx === -1 || mIdx === -1) { // is user's role lower than minimum role for auth?
						if (this.debug) { console.log('Role guard: ' + method + ' : DENIED : no access'); }
						return false;
					} else {
						return true;
					}
				}

				if (this.debug) { console.log('Role guard: ' + method + ' : DENIED : not logged in'); }
				// console.log('setting in rg ' + method + ' lastUrl=' + url);
				// this.loginService.lastUrl = url; // grab the url for the login service so it can redirect after login
				// this.loginService.kickedOutOf = 'RoleGuard - ' + method + ' - ' + minrole; // catch where it's happening from...
				this.loginService.setLastURL(url, 'RoleGuard - ' + method + ' - ' + minrole);
				this.router.navigate([AppConstants.urls.login]);

				return false;
			}));
	}

	private getResolvedUrl(route: ActivatedRouteSnapshot): string {
		return route.pathFromRoot
			.map(v => v.url.map(segment => segment.toString()).join('/'))
			.join('/');
	}




}
