import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import DatetimeTools from 'appshared/datetime-tools';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';
import LicenseValidationTools from 'appshared/license-validation-tools';
import PopOverTools from 'appshared/popover-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-keys-table',
	templateUrl: './keys-table.component.html',
	styleUrls: ['./keys-table.component.scss']
})
export class KeysTableComponent implements OnInit {
	ac = AppConstants;
	textTools = TextTools;
	miscTools = MiscTools;
	sharedLicenseTools = SharedLicenseTools;

	now = new Date();

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	@Input() parentApi !: any;

	/*
		pass in
			users 
			storageKey
			options
			- showOrganization : true
	*/

	authUser: Models.AuthUser = null;
	loading: boolean = false;

	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = '';

	expandWarnings: boolean = false;

	staffMode: boolean = false;
	addPopovers: boolean = false;

	showStaffDelete: boolean = true;
	showEnabled: boolean = true;
	showUserLabel: boolean = true;
	showInfo: boolean = true;
	showOrganization: boolean = true;
	showFullProduct: boolean = true;
	showFullType: boolean = true;
	showExpandedActivations: boolean = true;
	showActiveCount: boolean = true;
	showMeterIcon: boolean = true;
	showProtocolIcon: boolean = true;
	showSnoozed: boolean = true;
	showLastTouched: boolean = true;
	showSalesEngineer: boolean = true;
	showNumUsers: boolean = true;
	showCommercialType: boolean = true;
	showMeterIrregularities: boolean = true;

	items: Models.LPActivation[] = [];
	itemsToShow: Models.LPActivation[] = [];

	columnDefs: any[] = [
		{
			field: '__delete_button',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Unlink from User',
			icon: 'trash-alt'
		}, {
			field: 'enabled',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Enabled/Disabled',
			icon: 'power-off'
		}, {
			field: '__user_label',
			type: 'baseText',
			sortType: 'text',
			label: 'Label',
			toolTip: null,
			icon: null
		}, {
			field: 'created_at',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Created',
			toolTip: null,
			icon: null
		}, {
			field: '__expires_at',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Expiry',
			toolTip: null,
			icon: null
		}, {
			field: 'org_name',
			type: 'baseText',
			sortType: 'text',
			label: 'Organization',
			toolTip: null,
			icon: null
		}, {
			field: 'info',
			type: 'baseText',
			sortType: 'text',
			label: AppConstants.keyInfoFieldLabel,
			toolTip: null,
			icon: null
		}, {
			field: '__niceProduct',
			type: 'baseText',
			sortType: 'text',
			label: 'Product',
			toolTip: null,
			icon: null
		}, {
			field: 'type',
			type: 'baseText',
			sortType: 'text',
			label: 'Type',
			toolTip: null,
			icon: null
		}, {
			field: 'key',
			type: 'baseText',
			sortType: 'text',
			label: 'Key',
			toolTip: null,
			icon: null
		}, {
			field: 'count',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: '# Act.',
			toolTip: 'Number of Licenses Issued',
			icon: null
		}, {
			field: '__active_hostids',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: '# Host IDs reporting non-zero traffic over the last ' + AppConstants.recentUsedHostsDays + ' days',
			icon: 'broadcast-tower'
		}, {
			field: 'num_users',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: '# Users With This Key Registered',
			icon: 'user'
		}, {
			field: '__meter_status',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Meter Reporting',
			icon: 'tachometer-alt'
		}, {
			field: '__protocol_status',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Protocol Reporting',
			icon: 'chart-line'
		}, {
			field: '__last_touched_by',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Last Touched By - either a legacy License Portal (LP) or Customer Portal user (CP) or none at all (if API generated)',
			icon: 'pencil'
		}, {
			field: '__sales_engineer',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Organiztion\'s SE',
			icon: 'user-graduate'
		}, {
			field: '__tam',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Organiztion\'s TAM',
			icon: 'user-ninja'
		}, {
			field: '__comm_type',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Commercial Type',
			icon: 'sack-dollar'
		}, {
			field: '__snoozes',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Snoozed',
			icon: 'snooze'
		}, {
			field: '__warnings',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Warnings',
			icon: 'exclamation-triangle'
		}, {
			field: '__irregularities',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Meter Irregularities',
			icon: 'info-circle'
		}
	];

	fieldsToShow: string[] = [];

	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService) {
	}

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const sessionUser: Models.AuthUser = this.authService.getUser();
		if (sessionUser != null && sessionUser.id !== 0) {
			this.authUser = sessionUser;
			this.setups();
		} // if
	}

	// ------------------------------------------------------------------------
	async setups() {

	}

	// ------------------------------------------------------------------------
	updateContent(items: Models.LPActivation[], storageKey: string, options: any = {}) {
		this.loading = true;

		this.items = items;
		this.storageKey = storageKey;

		let optionKeys: string[] = [];
		if (options) optionKeys = Object.keys(options);

		if (optionKeys.includes('staffMode')) this.staffMode = options.staffMode;
		if (optionKeys.includes('addPopovers')) this.addPopovers = options.addPopovers;

		if (optionKeys.includes('showStaffDelete')) this.showStaffDelete = options.showStaffDelete;
		if (optionKeys.includes('showEnabled')) this.showEnabled = options.showEnabled;
		if (optionKeys.includes('showUserLabel')) this.showUserLabel = options.showUserLabel;
		if (optionKeys.includes('showInfo')) this.showInfo = options.showInfo;
		if (optionKeys.includes('showOrganization')) this.showOrganization = options.showOrganization;
		if (optionKeys.includes('showFullProduct')) this.showFullProduct = options.showFullProduct;
		if (optionKeys.includes('showFullType')) this.showFullType = options.showFullType;
		if (optionKeys.includes('showExpandedActivations')) this.showExpandedActivations = options.showExpandedActivations;
		if (optionKeys.includes('showActiveCount')) this.showActiveCount = options.showActiveCount;
		if (optionKeys.includes('showNumUsers')) this.showNumUsers = options.showNumUsers;
		if (optionKeys.includes('showMeterIcon')) this.showMeterIcon = options.showMeterIcon;
		if (optionKeys.includes('showProtocolIcon')) this.showProtocolIcon = options.showProtocolIcon;
		if (optionKeys.includes('showSnoozed')) this.showSnoozed = options.showSnoozed;
		if (optionKeys.includes('showLastTouched')) this.showLastTouched = options.showLastTouched;
		if (optionKeys.includes('showSalesEngineer')) this.showSalesEngineer = options.showSalesEngineer;
		if (optionKeys.includes('showCommercialType')) this.showCommercialType = options.showCommercialType;
		if (optionKeys.includes('showMeterIrregularities')) this.showMeterIrregularities = options.showMeterIrregularities;

		this.fieldsToShow = [];

		if (this.staffMode && this.showStaffDelete) this.fieldsToShow.push('__delete_button');
		if (this.showEnabled) this.fieldsToShow.push('enabled');
		if (this.showUserLabel) this.fieldsToShow.push('__user_label');
		this.fieldsToShow.push('created_at');
		this.fieldsToShow.push('__expires_at');
		if (this.staffMode && this.showOrganization) this.fieldsToShow.push('org_name');
		if (this.staffMode && this.showInfo) this.fieldsToShow.push('info');
		this.fieldsToShow.push('__niceProduct');
		this.fieldsToShow.push('type');
		this.fieldsToShow.push('key');
		this.fieldsToShow.push('count');
		if (this.showActiveCount) this.fieldsToShow.push('__active_hostids');
		if (this.staffMode && this.showNumUsers) this.fieldsToShow.push('num_users');
		if (this.showMeterIcon) this.fieldsToShow.push('__meter_status');
		if (this.showProtocolIcon) this.fieldsToShow.push('__protocol_status');
		if (this.staffMode && this.showLastTouched) this.fieldsToShow.push('__last_touched_by');
		if (this.staffMode && this.showSalesEngineer) this.fieldsToShow.push('__sales_engineer');
		if (this.staffMode && this.showSalesEngineer) this.fieldsToShow.push('__tam');
		if (this.staffMode && this.showCommercialType) this.fieldsToShow.push('__comm_type');
		if (this.showSnoozed) this.fieldsToShow.push('__snoozes');

		this.fieldsToShow.push('__warnings');
		if (this.staffMode && this.showMeterIrregularities) this.fieldsToShow.push('__irregularities');

		if (!this.showFullProduct) {
			const idx = MiscTools.findIndexGeneric(this.columnDefs, 'field', '__niceProduct');
			if (idx !== -1) this.columnDefs[idx].label = 'P';
		} // if

		if (!this.showFullType) {
			const idx = MiscTools.findIndexGeneric(this.columnDefs, 'field', 'type');
			if (idx !== -1) this.columnDefs[idx].label = 'T';
		} // if

		for (const item of this.items) {
			const exp = SharedLicenseTools.getKeyExpiration(item, '', true);
			if (!exp) {
				if (item.duration != null && item.duration !== 0) {
					item['__expires_at_display'] = 'Duration';
					item['__expires_at'] = new Date('2099/01/01');
				} else {
					item['__expires_at_display'] = 'Never';
					item['__expires_at'] = new Date('2100/01/01');
				} // if

			} else {
				item['__expires_at_display'] = DatetimeTools.formatDate(exp, AppConstants.tableDateFmt);
				item['__expires_at'] = exp;
			} // if

			if (this.staffMode)
				item['__route'] = ['/' + AppConstants.urls.licensing, 'activation', item.id];
			else
				item['__route'] = ['/' + AppConstants.urls.mykeys, item['__user_id']];

			item['__niceProduct_short'] = SharedLicenseTools.licenseProductAcronym(item['__niceProduct']);

			if (item.last_meter_report != null) {
				item['__meter_status'] = SharedLicenseTools.getMeterIconToolTip(item);
				item['__meter_status_class'] = SharedLicenseTools.getMeterIconClass(item);
			} else {
				item['__meter_status'] = '';
				item['__meter_status_class'] = '';
			} // if

			if (item.last_protocol_report != null) {
				item['__protocol_status'] = SharedLicenseTools.getProtocolIconToolTip(item);
				item['__protocol_status_class'] = SharedLicenseTools.getProtocolIconClass(item);
			} else {
				item['__protocol_status'] = '';
				item['__protocol_status_class'] = '';
			} // if

			item['__comm_type'] = SharedLicenseTools.getCommericalTypeLabel(item);
			item['__comm_type_acronym'] = SharedLicenseTools.getCommericalTypeAcronym(item);

			let keyWarnings: string[] = [];
			if (this.staffMode) {
				keyWarnings = LicenseValidationTools.getKeyWarnings(item, 'user');
			} else {
				keyWarnings = LicenseValidationTools.getKeyWarnings(item, 'staff-enforced');
				if (!keyWarnings || keyWarnings.length === 0)
					keyWarnings = LicenseValidationTools.getKeyWarnings(item, 'staff-all');
			} // if

			if (item['_extra_warnings'] && item['_extra_warnings'] !== '')
				keyWarnings.push(item['_extra_warnings']);

			item['__warnings'] = keyWarnings;

			if (this.staffMode && this.showMeterIrregularities) {
				const meterWarnings: string[] = LicenseValidationTools.getMeterCommTypeWarnings(item, false, false, false);
				if (meterWarnings && meterWarnings.length > 0)
					item['__irregularities'] = meterWarnings.join('');
			} // if

		} // for

		this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'created_at', 'desc');

		this.loading = false;
	} // updateContent

	// ------------------------------------------------------------------------
	toggleWarnings() {
		this.expandWarnings = !this.expandWarnings;
		const idx = MiscTools.findIndexGeneric(this.columnDefs, 'field', '__warnings');
		if (idx !== -1) {
			if (this.expandWarnings) {
				this.columnDefs[idx].label = 'Warnings';
				this.columnDefs[idx].icon = null;
			} else {
				this.columnDefs[idx].label = null;
				this.columnDefs[idx].icon = 'exclamation-triangle';
			} // if
		} // if
	}

	// ------------------------------------------------------------------------
	async deleteUserKey(userKeyId: number, keyId: number) {
		if (this.parentApi) this.parentApi.deleteUserKey(userKeyId, keyId);
	}

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.LPActivation[] = [];
		for (const item of this.items) {
			let match: boolean = true;
			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.key.toLowerCase().includes(w)
						|| (this.showInfo && item.info && item.info.toLowerCase().includes(w))
						|| (this.showOrganization && item.org_name && item.org_name.toLowerCase().includes(w))
						|| (this.showUserLabel && item['__user_label'] && item['__user_label'].toLowerCase().includes(w))
						|| (this.showLastTouched && item['__last_touched_by'] && item['__last_touched_by'].toLowerCase().includes(w))
						|| (this.showSalesEngineer && item['__sales_engineer'] && item['__sales_engineer'].toLowerCase().includes(w))
						|| (this.showCommercialType && item['__comm_type'] && item['__comm_type'].toLowerCase().includes(w))
						|| (item['__niceProduct_short'] && item['__niceProduct_short'].toLowerCase().includes(w))
						|| (item['__niceProduct'] && item['__niceProduct'].toLowerCase().includes(w))
						|| (item['__warnings'] && item['__warnings'].join('\n').toLowerCase().includes(w))
						|| (this.showMeterIrregularities && item['__irregularities'] && item['__irregularities'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for

		return tmpList;
	} // filterItems

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}

	// --------------------------------------------------------------------
	copyToClipboardAlert(item: string = '') {
		this.uiAlertsService.copyToClipboardAlert(item);
	}

}
