import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';

import { AuthService } from 'client/app/services/auth.service';
import { LicensingAdminService } from '../licensing-admin.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-licensing-product-property-form',
	templateUrl: './licensing-product-property-form.component.html',
	styleUrls: ['./licensing-product-property-form.component.scss']
})
export class LicensingProductPropertyFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	productID: number;
	propertyID: number;
	product: Models.LicenseProduct;
	property: Models.LicenseProperty;
	productProperty: Models.LicenseProductProperty;

	errors: string[] = [];
	editMode = false;
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	showUnlimited = false;

	yesNo = ['No', 'Yes'];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService,
		private licensingAdminService: LicensingAdminService) {
		this.route.paramMap.subscribe(params => {
			this.productID = +params.get('id');
			this.propertyID = +params.get('id2');
		});
	}

	ngOnInit(): void {
		this.initForm();
	}

	ngOnDestroy() {
	}

	async initForm() {
		if (!this.productID || this.productID === 0 || !this.propertyID || this.propertyID === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		}

		this.product = await this.licensingAdminService.getProduct(this.productID);
		if (!this.product || this.product == null || this.product.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		} // if

		this.property = await this.licensingAdminService.getProperty(this.propertyID);
		if (!this.property || this.property == null || this.property.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		} // if

		this.productProperty = new Models.LicenseProductProperty(this.product.id, this.property.id, '', 0, 1, 1, 0, 0, '', 1);
		const allProductProperties = await this.licensingAdminService.getProductProperties();
		for (const pp of allProductProperties) {
			if (pp.product_id === this.productID && pp.property_id === this.propertyID) {
				this.productProperty = pp;
				this.editMode = true;
			}
		}

		this.showUnlimited = this.property.allow_unlimited === 1;

		let unlimited = 0;
		if (this.property.ptype === 'number' && this.productProperty.default_value_text === 'unlimited') {
			this.productProperty.default_value_num = null;
			unlimited = 1;
		}

		this.theForm = new UntypedFormGroup({
			default_value_text: new UntypedFormControl(this.productProperty.default_value_text),
			default_value_num: new UntypedFormControl(this.productProperty.default_value_num),
			unlimited: new UntypedFormControl(unlimited),
			can_be_changed: new UntypedFormControl(this.productProperty.can_be_changed),
			show_user: new UntypedFormControl(this.productProperty.show_user),
			min_value: new UntypedFormControl(this.productProperty.min_value),
			max_value: new UntypedFormControl(this.productProperty.max_value),
			selections: new UntypedFormControl(this.productProperty.selections)
		});
		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		this.productProperty.can_be_changed = +this.theForm.value.can_be_changed;
		this.productProperty.show_user = +this.theForm.value.show_user;

		this.productProperty.min_value = 0;
		this.productProperty.max_value = 0;
		this.productProperty.selections = '';
		if (this.property.ptype === 'boolean') {
			this.productProperty.default_value_num = +this.theForm.value.default_value_num;
			this.productProperty.default_value_text = '';
		} else if (this.property.ptype === 'number') {
			if (this.showUnlimited) {
				const unlimited = +this.theForm.value.unlimited;
				if (unlimited === 1) {
					this.productProperty.default_value_num = 0;
					this.productProperty.default_value_text = 'unlimited';
				} else {
					this.productProperty.default_value_num = +this.theForm.value.default_value_num;
					this.productProperty.default_value_text = '';
				}
			} else {
				this.productProperty.default_value_num = +this.theForm.value.default_value_num;
				this.productProperty.default_value_text = '';
			}

			this.productProperty.min_value = +this.theForm.value.min_value;
			this.productProperty.max_value = +this.theForm.value.max_value;

			if (this.productProperty.min_value > this.productProperty.max_value)
				this.errors.push('Min value must be less than max value.');

		} else if (this.property.ptype === 'other') {
			this.productProperty.default_value_num = 0;
			this.productProperty.default_value_text = this.theForm.value.default_value_text;
			this.productProperty.selections = this.theForm.value.selections;
			if (!this.productProperty.selections || this.productProperty.selections.trim() === '')
				this.errors.push('Selections must be one more or values (comma separated).');
		}

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		try {
			const retPP: Models.LicenseProductProperty = await this.licensingAdminService.updateProductProperty(this.productProperty);
			if (retPP) {
				this.router.navigate([AppConstants.urls.licensingadmin + '/products/' + this.product.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['../../..'], { relativeTo: this.route });
	}

	// get message() { return this.theForm.get('message'); }

}
