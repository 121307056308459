import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';
import LicenseValidationTools from 'appshared/license-validation-tools';
import PopOverTools from 'appshared/popover-tools';

import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-key-meters',
	templateUrl: './key-meters.component.html',
	styleUrls: ['./key-meters.component.scss']
})
export class KeyMetersComponent {
	ac = AppConstants;
	textTools = TextTools;
	popOverTools = PopOverTools;
	sharedLicenseTools = SharedLicenseTools;
	miscTools = MiscTools;

	authUser: Models.AuthUser = null;
	loading: boolean = false;

	@Input() showRawData: boolean = false;

	activation: Models.LPActivation;
	staffMode: boolean = false;

	metersEnforced: boolean = true;
	showMeterSummary: boolean = false;
	anyMeterLabels: boolean = false;

	keyWarnings = [];
	meterWarnings = [];
	nonEnforcedWarnings = [];

	activeMeterTypes: string[] = [];
	meterSummaryInfo: any = {};
	hasMonthlyMeters: boolean = false;
	hasNeverMeters: boolean = false;
	meterLabelsByType: any = {};

	meterHighlites: any = {};

	linkedProtocolSets: Models.ProtocolSet[] = [];
	linkedChronoSets: Models.ProtocolSet[] = [];

	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private uiAlertsService: UiAlertsService) {
	}

	// ------------------------------------------------------------------------
	async updateContent(activation: Models.LPActivation, linkedProtocolSets: Models.ProtocolSet[], linkedChronoSets: Models.ProtocolSet[], options: any = {}) {
		this.loading = true;

		this.activation = activation;
		this.linkedProtocolSets = linkedProtocolSets;
		this.linkedChronoSets = linkedChronoSets;

		let optionKeys: string[] = [];
		if (options) optionKeys = Object.keys(options);

		if (optionKeys.includes('staffMode')) this.staffMode = options.staffMode === true;

		const now: Date = new Date();

		this.keyWarnings = LicenseValidationTools.getKeyWarnings(this.activation, 'staff-enforced', 0, 0);
		if (this.keyWarnings.length === 0)
			this.nonEnforcedWarnings = LicenseValidationTools.getKeyWarnings(this.activation, 'staff-all', 0, 0);
		this.meterWarnings = LicenseValidationTools.getMeterCommTypeWarnings(this.activation);

		this.meterSummaryInfo = SharedLicenseTools.buildMeterSummary(this.activation.meters);

		this.activeMeterTypes = [];
		this.hasMonthlyMeters = false;
		this.hasNeverMeters = false;
		for (const product of AppConstants.meterProducts) {
			if (this.meterSummaryInfo[product + '.never'] || this.meterSummaryInfo[product + '.monthly']) {
				this.activeMeterTypes.push(product);
				if (this.meterSummaryInfo[product + '.never'] && +this.meterSummaryInfo[product + '.never'].limit > 0)
					this.hasNeverMeters = true;
				if (this.meterSummaryInfo[product + '.monthly'] && +this.meterSummaryInfo[product + '.monthly'].limit > 0)
					this.hasMonthlyMeters = true;
			} // if
		} // for

		this.meterLabelsByType = {};
		const countByType: any = {};
		for (const meter of this.activation.meters) {
			if (!countByType[meter.product]) countByType[meter.product] = 0;
			countByType[meter.product]++;
			if (countByType[meter.product] > 1 && this.activeMeterTypes.length > 0) this.showMeterSummary = true;

			let starts = new Date(meter.created_at);
			if (meter.starts_at) starts = new Date(meter.starts_at);
			const exp = new Date(meter.expires_at);
			if (meter.enabled === 1 && exp.getTime() > now.getTime() && starts.getTime() <= now.getTime() && meter.label && meter.label !== '') {
				this.anyMeterLabels = true;
				if (!this.meterLabelsByType[meter.product]) this.meterLabelsByType[meter.product] = [];
				if (!this.meterLabelsByType[meter.product].includes(meter.label)) this.meterLabelsByType[meter.product].push(meter.label);
			} // if
		} // for

		if (!this.activation.parsedParameters)
			this.activation.parsedParameters = SharedLicenseTools.parseRubyHash(this.activation.parameters);

		this.metersEnforced = !(this.activation.parsedParameters.dont_enforce_meters && +this.activation.parsedParameters.dont_enforce_meters === 1);

		// this.linkedProtocolSets = [];
		// this.linkedChronoSets = [];

		// const linkedSets: Models.ActivationProtocolSet[] = await this.licensingService.getActivationProtocolSets(this.id);
		// for (const linkedSet of linkedSets) {
		// 	const idx = MiscTools.findIndex(this.availableSets, linkedSet.set_id);
		// 	if (idx !== -1) {
		// 		if (!isNaN(linkedSet.projected))
		// 			this.availableSets[idx]['projected'] = linkedSet.projected;
		// 		if (linkedSet.data_type === 'protocol-data')
		// 			this.linkedProtocolSets.push(this.availableSets[idx]);
		// 		else if (linkedSet.data_type === 'protocol-time')
		// 			this.linkedChronoSets.push(this.availableSets[idx]);
		// 	} // if
		// } // for



		this.loading = false;
	} // updateContent

	// *********************************************************
	makeDataTip(amt: number, canCopy: boolean = true) {
		if (amt == null || isNaN(amt) || amt === 0) return '';
		let toolTip = TextTools.formatNumber(Math.floor(amt)) + ' megabytes';
		if (canCopy) toolTip += ' - click to copy'
		return toolTip;
	}

	// *********************************************************
	showDataValue(amt: number, product: string = '') {
		if (!amt || Math.abs(amt) < 0.1) return '0';

		const isNeg: boolean = amt < 0;
		const absAmt: number = Math.abs(amt);

		let ret: string = '';
		// limit decimal places
		if (this.showRawData)
			ret = TextTools.formatNumber(+Math.max(absAmt, 0.1).toFixed(1));
		else
			ret = TextTools.formattedMB(absAmt);

		if (isNeg)
			return ('-' + ret);
		else
			return (ret);

	}

	// *********************************************************
	hasMeterOfCertainType(meterProduct: string, resets: string) {
		const now = new Date();

		for (const meter of this.activation.meters) {
			let starts = new Date(now);
			if (meter.starts_at) starts = new Date(meter.starts_at);
			const exp = new Date(meter.expires_at);
			if (meter.product === meterProduct && meter.resets === resets
				&& meter.enabled === 1 && exp.getTime() > now.getTime() && starts.getTime() <= now.getTime()) {
				return true;
			} // if
		} // for

		return false;
	}

	// --------------------------------------------------------------------
	highliteMeters(product: string, resets: string) {
		this.meterHighlites = {};
		for (const meter of this.activation.meters) {
			if (meter.product === product && meter.resets === resets)
				this.meterHighlites[meter.id] = 'yes';
		} // for
	}

	// --------------------------------------------------------------------
	resetMeterHighlites() {
		this.meterHighlites = {};
	}

	// *********************************************************
	warningMeter(meter: Models.LPMeter, projected: boolean = false): boolean {
		if (projected) {
			if (!meter.projected || +meter.projected === 0) return false;
			const perc = (meter.used / meter.projected * 100);
			return perc < 100 && perc >= AppConstants.keyWarningUsageThreshold;
		} else {
			const perc = (meter.used / meter.limit * 100);
			return perc < 100 && perc >= AppConstants.keyWarningUsageThreshold;
		} // if
	} // 

	// *********************************************************
	usagePercOfMaximum(meter: Models.LPMeter): string {
		if (+meter.limit > 0)
			return (+meter.used / +meter.limit * 100).toFixed(1);
		else
			return '-';
	}

	// *********************************************************
	usagePercOfProjected(meter: Models.LPMeter): string {
		if (+meter.projected > 0)
			return (+meter.used / +meter.projected * 100).toFixed(1);
		else
			return '-';
	}

	// *********************************************************
	meterStatus(meter: Models.LPMeter) {
		if (SharedLicenseTools.hasNotStarted(meter.starts_at)) {
			return 'Not Started';
		} else if (MiscTools.hasExpired(meter.expires_at)) {
			return 'Expired';
		} else {
			const perc = (meter.used / meter.limit * 100);
			if (perc === 100) {
				return 'At Maximum';
			} else if (perc > 100) {
				return 'Over Maximum';
			} else if (perc >= AppConstants.keyWarningUsageThreshold) {
				return 'Over ' + AppConstants.keyWarningUsageThreshold + '% of Maximum';
			} else {
				if (meter.projected && !isNaN(meter.projected) && +meter.projected > 0) {
					const projPerc = (meter.used / meter.projected * 100);
					if (projPerc === 100) {
						return 'At Projected';
					} else if (projPerc > 100) {
						return 'Over Projected';
					} else if (projPerc >= AppConstants.keyWarningUsageThreshold) {
						return 'Over ' + AppConstants.keyWarningUsageThreshold + '% of Projected';
					} // if
				} // if
			} // if
		} // if
		return 'OK';
	}

	// --------------------------------------------------------------------
	copyToClipboardAlert(item: string = '') {
		this.uiAlertsService.copyToClipboardAlert(item);
	}


}
