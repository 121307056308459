<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.users" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" routerLink="..">Users</a>
			</li>
			<li class="breadcrumb-item">
				{{ user.name }}
			</li>
			<li class="breadcrumb-item active" aria-current="page">Register Keys</li>
		</ol>
	</nav>
</div>

<div *ngIf="loading || saving" style="text-align: center;">
	<app-loading-spinner></app-loading-spinner>
</div>

<form [formGroup]="theForm">
	<div class="cp-general-width-limit">
		<div class="form-group">
			<label for="keyList">License Key(s)*</label>
			<textarea id="keyList" formControlName="keyList" class="form-control" rows="8"></textarea>
		</div>

		<div class="form-group">
			Enter license keys in the box above. One per line. You can add a maximum of {{
			ac.maxKeysToRegisterPerSubmit }} each time.
		</div>

		<div class="form-group">
			Adding a space and some text after a key will set a label for that key.
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="notify" formControlName="notify" />
			<label class="form-check-label" for="notify">
				Send user an e-mail with information about key(s).
				<fa-icon [icon]="['fas', 'question-circle']"
					ngbTooltip="The e-mail with contain base information about the key(s) - type, product, # activations.  The key itself will be truncated for security."
					placement="bottom-start">
				</fa-icon>
			</label>
		</div>

		<!-- <div class="form-group mb-2 small">* Required</div>

		<div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Register Keys
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div> -->
	</div>
</form>

<div class="cp-form-padding"></div>

<div *ngIf="!loading && theForm" class="cp-form-buttons">
	<div class="cp-form-buttons-inner">
		<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
			<fa-icon [icon]="['fas', 'check']"></fa-icon> Register Keys
		</button>
		<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
			<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
		</button>

		<span class="gj-75 ps-2">* Required</span>
	</div>
</div>