import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import PopOverTools from 'appshared/popover-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { LicensingAdminService } from '../licensing-admin.service';
import { AdminLogsService } from '../../admin-logs/admin-logs.service';
import { UsersService } from '../../users/users.service';

import { LogsTableComponent } from 'client/app/components/shared/logs-table/logs-table.component';
import { PopupBoxComponent } from 'client/app/components/shared/popup-box/popup-box.component';

@Component({
	selector: 'app-licensing-property',
	templateUrl: './licensing-property.component.html',
	styleUrls: ['./licensing-property.component.scss']
})

export class LicensingPropertyComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	popOverTools = PopOverTools;
	textTools = TextTools;

	@ViewChild('logsTable1') logsTable1: LogsTableComponent = null;
	@ViewChild(PopupBoxComponent) popupBox: PopupBoxComponent = null;

	loading = true;
	// showPostLoadElements = false;

	// 'standard' view stuff
	authUser: Models.AuthUser;
	id: number;
	property: Models.LicenseProperty;

	productProperties: Models.LicenseProductProperty[] = [];
	templateProperties: Models.LicenseProductProperty[] = [];

	pariedPropertyLabel = '';

	// other stuff
	private userSubscription: Subscription;

	adminLogs: Models.AdminLog[] = [];
	addedByUser: Models.User = null;
	editedByUser: Models.User = null;

	canManage = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private licensingAdminService: LicensingAdminService,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private usersService: UsersService,
		private adminLogsService: AdminLogsService
	) {

		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.userSubscription = this.authService.user.subscribe((authUser) => {
				this.authUser = authUser;
				this.canManage = (authUser && ValidationTools.checkAccess(authUser, 'manage-license-key-setup'));
				this.loadData();
			});
		});
	}

	ngOnInit(): void {
		// changes

	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData() {
		this.loading = true;

		this.property = await this.licensingAdminService.getProperty(this.id, false);
		if (!this.property || this.property == null || this.property.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
		}

		const allProductProperties = await this.licensingAdminService.getProductProperties();
		const allProducts: Models.LicenseProduct[] = await this.licensingAdminService.getProducts();

		for (const pp of allProductProperties)
			if (pp.property_id === this.id)
				this.productProperties.push(pp);

		this.productProperties.sort((a, b) => (a.product!.label > b.product!.label) ? 1 : -1);

		this.templateProperties = [];

		const templates = await this.licensingAdminService.getTemplates();
		for (const template of templates) {
			for (const pp of template.settings.productProperties) {
				if (pp.property_id === this.property.id) {
					pp.property = this.property;
					const idx = MiscTools.findIndex(allProducts, pp.product_id);
					if (idx !== -1) pp.product = allProducts[idx];
					pp['templateId'] = template.id;
					pp['templateName'] = template.name;
					this.templateProperties.push(pp);
				} // if
			} /// for
		} // for


		if (this.property.paired_with && this.property.paired_with !== 0) {
			const pairedProp = await this.licensingAdminService.getProperty(this.property.paired_with);
			if (pairedProp) this.pariedPropertyLabel = pairedProp.label + ' (' + pairedProp.name + ')';
		}

		this.addedByUser = null;
		if (this.property.added_by && this.property.added_by !== 0)
			this.addedByUser = this.usersService.getOne(this.property.added_by);

		this.editedByUser = null;
		if (this.property.edited_by && this.property.edited_by !== 0)
			this.editedByUser = this.usersService.getOne(this.property.edited_by);

		this.adminLogs = await this.adminLogsService.getLogs(['license-property'], this.id);

		this.loading = false;

		await MiscTools.delay(100);
		if (this.logsTable1)
			this.logsTable1.updateContent(this.adminLogs, 'cp-key-property-view-admin-logs', { showUserInfo: true, showObjectInfo: false, linkObject: false, linkUser: true });

	}

	// ------------------------------------------------------------------------
	prepDelete() {
		this.popupBox.openPopup('confirm-text', 'delete', [], 'Delete License Property',
			'If you delete this License Property, it will be permanently deleted and cannot be recovered.',
			null,
			{ confirmButtonText: 'Delete License Property', rejectButtonText: 'Cancel', confirmText: 'delete' });
	}

	// ------------------------------------------------------------------------
	async delete() {
		this.loading = true;

		const result = await this.licensingAdminService.deleteProperty(this.property.id);
		if (result) {
			this.uiAlertsService.addMsg('The license property (' + this.property.name + ') has been deleted.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.router.navigate([AppConstants.urls.licensingadmin, 'properties']);
		} else {
			return false;
		}
	}

	niceDefault(productProperty: Models.LicenseProductProperty): string {
		if (productProperty.property.ptype === 'boolean')
			return this.niceBoolean(productProperty.default_value_num);
		else if (productProperty.property.ptype === 'number' && productProperty.default_value_text !== 'unlimited')
			return productProperty.default_value_num + '';
		else if (productProperty.default_value_text && productProperty.default_value_text !== '')
			return productProperty.default_value_text;
		else
			return '';
	}

	niceBoolean(value: number): string {
		if (value === 0)
			return 'No';
		else if (value === 1)
			return 'Yes';
		else
			return '???-' + value;
	}

	niceShowUser(value: number): string {
		if (value >= 0 && value < AppConstants.keyPropShowUserValues.length)
			return AppConstants.keyPropShowUserValues[value];
		else
			return '???-' + value;
	}

	// ------------------------------------------------------------------------
	// Back and Forth with components
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			popupCallBack: async (callBack: string, args: any) => {
				if (callBack === 'delete' && args.length === 0)
					this.delete();
				else if (callBack === 'XXXXX' && args.length === 1)
					console.log('args=' + args);

				else
					this.uiAlertsService.addMsg('Unknown callBack (' + callBack + ') or bad number of arguments (' + args.length + ').', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		}
	} // getParentMethod
}
