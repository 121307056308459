<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.dashboard" class="me-1" size="sm"></fa-icon>
				<a routerLink="..">Dashboard</a>
			</li>
			<li class="breadcrumb-item">Change Log</li>
			<li *ngIf="lastBuild" class="breadcrumb-item active" aria-current="page">
				Last Build: {{ lastBuild | date: ac.shortPageDateTimeFmt }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
		</div>
	</div>
</div>



<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-2024-tab"
				aria-controls="nav-2024" data-bs-target="#nav-2024">
				2024
			</button>
		</li>

		<li *ngFor="let y of [2023, 2022, 2021, 2020]" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				[id]="'nav-' + y + '-tab'" [attr.aria-controls]="'nav-' + y" [attr.data-bs-target]="'#nav-' + y">
				{{ y }}
			</button>
		</li>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" role="tabpanel" id="nav-2024" aria-labelledby="nav-2024-tab">
			<ul class="cp-general-width-limit">

				<!-- 
				<li>
					January 2023
					<ul>
						<li>XXXXXXXX</li>
						<li>XXXXXXXX</li>
					</ul>
				</li>
				-->

				<li>
					January 2024
					<ul>
						<li>Show extra properties set via new (v16+) license backend when viewing details for a
							license/host ID</li>
						<li>Show extra properties set via new (v16+) license backend in pop-over for license/host ID
						</li>
						<li>In new (v16+) license backend, track first, current, previous IP addresses reported against
							a host ID.
						</li>
						<li>Capture extra properties via new (v16+) license backend and store in DB.
						</li>
					</ul>
				</li>
			</ul>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-2023" aria-labelledby="nav-2023-tab">
			<ul class="cp-general-width-limit">

				<li>
					December 2023
					<ul>
						<li>Let users choose if they wish to see all protocol usage or just the totals (incl protocol
							sets and billing code)</li>
						<li>If a key is linked to a protocol set and that set has a monthly projected limit, show
							row/column per month with % used and, if % > 100, show overage</li>
						<li>Add Option to run a saved search from key search without toggling to show saved searches
						</li>
					</ul>
				</li>

				<li>
					November 2023
					<ul>
						<li>Ability to set a projected monthly limit on linked protocol sets</li>
						<li>Add new advanced/special filters for key search : keys linked to a protocol set with a
							projected limit</li>
						<li>Add new advanced/special filters for key search : keys linked to a protocol set without
							projected limit</li>
					</ul>
				</li>

				<li>
					October 2023
					<ul>
						<li>Added an active hosts tab when viewing a key</li>
						<li>Set default to only showing last 12 months of protocol usage in UI with button to show all
							months (if there's more than 12)</li>
						<li>Show extra properties (if any) when viewing details for a host ID or IP</li>
						<li>Show failed pen tests tab for an organization's keys</li>
						<li>Let staff generate a pen test report for an organization</li>
					</ul>
				</li>

				<li>
					September 2023
					<ul>
						<li>Change X264 report to include Nvidia stats and renamed to transcode report</li>
						<li>Added management and reporting of GCP marketplace products</li>
						<li>Added marketplace info page with summary of GCP Entitlements and Azure Subscriptions</li>
						<li>GCP marketplace integration</li>
					</ul>
				</li>

				<li>
					August 2023
					<ul>
						<li>Added quiz/poll tool for staff</li>
						<li>Added new tab/sheet to commercial analysis report that summarizes by organization</li>
						<li>Re-worked dashboard top usage to cache results in database nigthtly to improve UI
							performance</li>
						<li>Added optional external label + notes to keys. These are visible to non-staff users.</li>
						<li>Added new column to license key search results that shows the initials of the key's
							organization's SE.</li>
						<li>Added button on meters tab when adding/editing keys that sets meter labels when a commercial
							type has been selected and the labels are blank</li>
						<li>Showing license file format in list of licenses when viewing a key and list of licenses and
							when viewing a host id</li>
						<li>Added new field to partner entries - Zixi Features</li>
						<li>Changed "jump to" buttons to "filter by" buttons for Software, Documentation and Partners
						</li>
						<li>Added 'limit' type properties to key search</li>
						<li>Added Salesforce lead owner to users report</li>
					</ul>
				</li>

				<li>
					July 2023
					<ul>
						<li>License key search results are saved and when viewing a key from last search results,
							previous and next buttons show up that allow for cycling through search results</li>
						<li>Added new special filter to find keys where commercial type isn't set and there's a
							recommendation for one to set</li>
						<li>When viewing protocol usage data, added new sub-totals for total in+out traffic</li>
						<li>When viewing protocol usage data, added additional sub-totals for any billing codes used
						</li>
						<li>Re-worked billing code filter selection</li>
						<li>Added new commercial analysis report to report on enabled/not expired keys with a commercial
							type set showing a summary of usage over a period of time and identifying potential
							reporting issues</li>
						<li>Added overage column when viewing month to month meter usage for a monthly reset meter with
							a projected limit</li>
						<li>Added H-264 report</li>
					</ul>
				</li>

				<li>
					June 2023
					<ul>
						<li>Added option to configure marketplace reporting on broadcaster keys + backend changes to
							automate that reporting</li>
						<li>Added new key template options: requires commercial type, key must be shared with users,
							requires meter labels + default meter label</li>
						<li>Added parent organization property to organizations to allow for sharing keys more easily
							with users in the parent organiztion (this feature will primarily be used for internal
							sub-organizations)</li>
						<li>Add option to offline license key generation to select format (RLM, ZLM or both)</li>
						<li>Added Azure Marketplace as an option when configuring a ZEN Master site or Broadcaster key
							to report traffic for a cloud marketplace</li>
						<li>Added new special search options to be able to find keys where the commercial notes field is
							set or not set</li>
						<li>Added option for administrators to explicitly set sort order of products to control how they
							show up on build/doc downloads screens</li>
					</ul>
				</li>

				<li>
					May 2023
					<ul>
						<li>Added option to let staff (with access assigned) send e-mail notifications to users in an
							organization or in the organizations in an organization group</li>
						<li>Added a new special filters to find keys linked/not linked to a protocol set</li>
						<li>Added new column for license key search results that shows and acronym (with tool tip) that
							shows the key's commercial type (if set)</li>
					</ul>
				</li>

				<li>
					April 2023
					<ul>
						<li>Create new report type for grabbing one or more types of data (meter, specific protocols,
							protocol sub-totals and/or protocol sets) for a key and all of its activated host Ids</li>
						<li>Peeled off Packages from My Settings to have its own menu item</li>
						<li>Add new welcome message to dashboard that describes basic user (non staff) sections</li>
						<li>Added new column to user list that indicates if a user has/hasn't setup notifications</li>
						<li>Start tracking total # of downloads per file and display it in file view, file list and
							build view </li>
						<li>Let staff run report that includes all downloads for a file, build, user and organziation
						</li>
						<li>Switch non-staff key usage reports to process in the background; also expose billing code
							usage in protocol version of the report</li>
						<li>Allow non-staff to run the key licenses report</li>
					</ul>
				</li>

				<li>
					March 2023
					<ul>
						<li>New option in key templates that can exclude specific features and limits from the "check
							all" action</li>
						<li>New report that summarizes all key/host ID/IPs that either failed a pen test (open to
							internet + default username/password) or are just open to the internet</li>
						<li>New view in licensing by IP address. When viewing activations for a host ID, IP can be
							clicked on to show all activations for an IP address.</li>
						<li>Change the workflow for adding ZEN Master sites to force use of an Organization user, hide
							the password and send the new user their password in an e-mail.</li>
						<li>Let staff trigger welcome e-mail immediately upon adding new user (via form, Salesforce
							import or ZEN Master import)</li>
					</ul>
				</li>

				<li>
					February 2023
					<ul>
						<li>When viewing a key's list of activations (issued licenses), a new column was added that
							indicates which (if any) meter types are included in the license.</li>
						<li>Two new key properties (commercial type + info) were added to keys.</li>
						<li>Added a new special filters to find keys with/without the commercial type set.</li>
						<li>When staff are viewing a broadcaster key, show any potential irregularities related to
							metering. </li>
						<li>In key search results, added a new column that has icon indicating that a key has potential
							irregularities related to metering.</li>
						<li>Added a new special filters to find keys with any potential irregularities related to
							metering.</li>
						<li>Added a new key search filter - commercial type</li>
						<li>Added a new property to key templates - default commercial type</li>
						<li>When viewing a broadcaster key or host ID, show if any of the activations have failed a
							penetration test (open to the internet and uses default username and password).</li>
					</ul>
				</li>

				<li>
					January 2023
					<ul>
						<li>Added new special filters to license search to find keys where meter/protocol usage
							has/hasn't been reported.</li>
						<li>When staff are viewing a key, show if users that key has been shared with have access to
							protocol stats.</li>
						<li>When staff are viewing a key, show if users in key's organization that aren't linked to
							(shared
							with) the key and offer a button to do so.</li>
						<li>When staff are viewing a user, show keys that are part of the user's organization that are
							linked to/shared with the user and offer a button to do so.</li>
						<li>When staff are viewing a user, show keys that have neem disabled and/or have expired in a
							second section/tab.</li>
						<li>Added a new special filter to key searches that simplifies finding keys that might need
							attention. </li>
						<li>Exposed new ZM properties - Live Events, Allow Agent Z, Allow Non-SSO Logins</li>
					</ul>
				</li>
			</ul>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-2022" aria-labelledby="nav-2022-tab">
			<ul class="cp-general-width-limit">
				<li>
					December 2022
					<ul>
						<li>Added new button when viewing a key that, when clicked, shows any issues the key might have
							regarding its compliance with the template it's linked to.</li>
						<li>Added buttons for increasing number of activations by set increments when adding/editing a
							key.</li>
						<li>Added modal popup that lists a Salesforce account's opportuniies when adding/editing a key.
						</li>
						<li>Let staff (with write access) update a basic user's notification settings.</li>
					</ul>
				</li>

				<li>
					November 2022
					<ul>
						<li>Added option for staff to delete journal entries they created.</li>
						<li>Added option for staff to break down meter/protocol traffic by quarter or year</li>
						<li>When staff are viewing a key and the user(s) that are linked to it, added the user's
							notifications settings pertaining to license keys.</li>
						<li>When a user is viewing their license keys, now breaking out keys that are expired/disabled
							and deleted into their own tabs.</li>
						<li>Added new notification option for users to have a usage summary report for their keys
							emailed to them daily, weekly or monthly.</li>
						<li>When viewing a key that usage data tagged with billing codes, show a new tab with a
							breakdown of which Host IDs use the billing code(s)</li>
						<li>Added option for users to set/update all/any of their keys' labels from the list page</li>
						<li>Add protocol sets for use when viewing a key's protocol usage data.</li>
						<li>Added new E-Mail domains field to Organizations. When users self register and their e-mail
							address matches an Organization's domain, the SE(s) from the Organization(s) receives an
							e-mail.
						</li>
						<li>Added new option that lets users snooze notifications for a key.</li>
						<li>Add new section for viewing ZEN Master incidents.</li>
					</ul>
				</li>

				<li>
					October 2022
					<ul>
						<li>Added new property to key meters - projected usage limit - that will allow for alerts and
							notifications when a meter's usage gets close to or beyond a set amount.</li>
						<li>Added option to load an Organization's active (not expired, not disabled) keys into the
							aggregate usage tool directly from the Organization's Keys tab</li>
						<li>Added option to load an keys from a key search (where # results is less than 100) into the
							aggregate usage tool</li>
						<li>Added option to add labels to license key meters</li>
					</ul>
				</li>
				<li>
					September 2022
					<ul>
						<li>New option for users to change the sort order of their file bundles.</li>
						<li>Integrated specialized targets report for customer into portal to remove need for separate
							external
							script.</li>
						<li>Added list of templates and how a property is setup with that template when viewing a
							license
							property.</li>
						<li>Added option, when viewing keys and host IDs, to toggele between shortened data amounts (in
							GB, TB, PB) to show raw amount in MB.</li>

					</ul>
				</li>
				<li>
					August 2022
					<ul>
						<li>New key search for offline keys and/or standard keys</li>
						<li>Added new filter options to organization list for account owner and/or SE/Tech-Rep</li>
						<li>Added new key type - Lab</li>
						<li>Added option for staff with the ability to register/link keys to a user account to also
							remove keys from a user account</li>
						<li>When viewing an organization, split keys into two tabs - one for non-expired+enabled keys
							and one for expired and/or disabled keys</li>
						<li>Show any customer user labels set for a host id when viewing details for the host id</li>
						<li>Moved management of organization's SE/Tech Rep to Salesforce. Portal now syncs with SF for
							SE just like it does for account owner.</li>
						<li>Changed mechanism for resticted organizations from a specific organization type to an
							organization flag</li>
						<li>Collapsed Service Provider and Integrator organization types into single Partner
							organization type </li>
					</ul>
				</li>

				<li>
					July 2022
					<ul>
						<li>Added new option for license keys where designated users have the ability to grant write
							access to the key to other users who don't have write access/</li>
						<li>Added new special filters to license search to find keys where write access has been granted
						</li>
						<li>Renamed 'Logs' Menu item to 'Logs/Reports'</li>
						<li>Added new Reports section to allow for running specialized 'background' reports.</li>
						<li>If a key's opportunity has a POC Expiration Date and/or a Contract End Date, make them
							available for setting meter expiration dates</li>
						<li>When viewing a daily breakdown of meter or protocol usage, show days with no usage. Also
							show abbreviated day of the week with date.</li>
						<li>Added option to generate a user report from a user group that only has the group's members
						</li>
						<li>Added modal dialogs when deleting objects instead of simple confirm pop-ups</li>
					</ul>
				</li>

				<li>
					June 2022
					<ul>
						<li>Added option to use new license backend for offline licenses</li>
						<li>Let staff create users from ZEN Master user accounts</li>
						<li>Add option to use new license backend for offline licenses</li>
						<li>Include contract end date in opp info window when viewing and creating/editing a key</li>
						<li>Convert various reports to use new background task mechanism</li>
						<li>Let basic organization users start a file bundle on their own that can be sent to support or
							one of the members of their account team</li>
						<li>Re-worked selection mechanism for special filters in license key search</li>
						<li>Added new special filters to license search: No activations, At least one activation, All
							activations used, Activations available</li>
						<li>Added new help page with info on license key search filters/fields</li>
					</ul>
				</li>

				<li>
					May 2022
					<ul>
						<li>Add ability to import users to an Organization from ZEN Master</li>
						<li>Add summary of meters to Meters tab for basic users when viewing one of their keys</li>
						<li>Let staff see what, if any, host ID labels a users has setup</li>
						<li>Add notification info/settings to user report</li>
						<li>Add new column to ZEN Master report that shows if Organization has ZEN Master product
							selected</li>
						<li>Add activity tab for users that shows log of their actions</li>
					</ul>
				</li>

				<li>
					April 2022
					<ul>
						<li>New option to show CLI download commands for files related to file sharing bundles.</li>
						<li>Added breakdown Bx counts for a ZEN Master site by cloud provider.</li>
						<li>Track recently viewed orgs, users, and ZEN Master sites and show links at top of list pages
						</li>
						<li>Updated to ZEN Master object counts to better reflect current state of ZEN Master</li>
						<li>Capture reason for adding or enabling a ZEN Master site and add journal entry</li>
						<li>Add new advanced/special filters for key search : keys shared with at least one user</li>
						<li>Add new advanced/special filters for key search : keys not shared with at least one user
						</li>
						<li>Show shared user count per key in search results and in organization's key list</li>
						<li>Add alert icon to ZEN Master list and Staff report if none of a ZEN Master site's users are
							setup for ZCP notifications for ZEN Master</li>
						<li>Add indicator to ZEN Master view's user list and ZEN Master Users report of whether or not a
							ZEN Master user is setup for ZCP notifications for ZEN Master and if not, why</li>
					</ul>
				</li>

				<li>
					March 2022
					<ul>
						<li>New option to allow specific users (typically group managers that onboard new staff) the
							option to promote a user from "basic" to "staff".</li>
						<li>Added functionality to grant Zixi personel "staff" access to ZEN Master (read or read/write)
							via the portal..</li>
						<li>Added functionality to delegate the ability for Organization tech reps to enable/disable ZEN
							Master sites linked to their Organizations.</li>
						<li>Added functionality to add, disable, enable and delete ZEN Master sites from portal.</li>
						<li>Added new automated notification to alert on systems (host IDs) that have recently stopped
							reporting their meter usage.</li>
						<li>Added new warning when viewing a key that shows when a monthly meter may fill up before the
							end of the month.</li>
						<li>Added special search option to to find keys with monthly meters that may fill up before end
							of month.</li>
						<li>Updated layout of Dashboard; Added pop-overs.</li>
						<li>Added summary of meters to roll up totals and max's across multiple active meters.</li>
						<li>Added option in database to tag specific keys so they're licenses are only kept in the
							database for a limited time.</li>
					</ul>
				</li>

				<li>
					February 2022
					<ul>
						<li>Updates to include ZEC Products</li>
						<li>Updates to AWS Marketplace to include third product</li>
						<li>Broke out build and download display modes to tabs and added 3rd mode (when applicable) to
							show recent additions (last 30 days).</li>
						<li>Broke out partner list display modes to tabs and added 3rd mode - list by type.</li>
						<li>Re-worked license key search form to break out special filters.</li>
						<li>Added special search option to to find keys expired or not expired keys.</li>
						<li>Added pop-overs (info blocks that pop-up when you hover over things like links) for keys,
							host IDs, users and organizations.</li>
					</ul>
				</li>

				<li>
					January 2022
					<ul>
						<li>Added option to bulk add billing codes to an Organization.</li>
						<li>Added option to export billing code information for an Organization to a spreadsheet.</li>
						<li>New option to let users set labels for Host IDs used activated against their license keys.
						</li>
						<li>Updates to handle time-based usage data (added for ZEN Master tracking on CDI/JPEG-XS)</li>
					</ul>
				</li>
			</ul>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-2021" aria-labelledby="nav-2021-tab">
			<ul class="cp-general-width-limit">
				<li>
					December 2021
					<ul>
						<li>Added automated process to create special license keys for tracking ZEN Master usaage.</li>
						<li>Added new information related to special usage keys when viewing a ZEN Master site</li>
						<li>Added recent usage (last 90 days) column to ZEN Master list</li>
						<li>New option for allowing non-staff users to see protocol usage stats for their keys</li>
					</ul>
				</li>

				<li>
					November 2021
					<ul>
						<li>Added AWS Marketplace registration workflow</li>
						<li>Added automated AWS Marketplace usage reporting</li>
						<li>Added AWS Marketplace usage logs section</li>
					</ul>
				</li>

				<li>
					October 2021
					<ul>
						<li>Changed behaviour of expiration quick set buttons when adding/editing keys.</li>
						<li>Added special search option to to find keys with specfic meter reset types (monthly or
							never)</li>
					</ul>
				</li>

				<li>
					September 2021
					<ul>
						<li>Changed key/meter expiry helper buttons to add a time period instead of advancing to a fixed
							time period.</li>
						<li>Add new tab to dashboard usage page with breakdown by Organization.</li>
						<li>Added registration info (country, company name, etc.) to user report export.</li>
						<li>Added option to run the Organization's protocol usage report (for the previous month) from
							the Organization page.</li>
						<li>Added option to run the Organization's metered keys report (for the previous month) from the
							Organization page.</li>
						<li>Added option to download license file with host ID in the name.</li>
					</ul>
				</li>

				<li>
					August 2021
					<ul>
						<li>Added new staff-only report for getting monthly (1st day of month) meter report for an
							Organization's metered keys</li>
						<li>Added automated notification to file bundle creator to warn them if the file(s) in the
							bundle haven't been downloaded by the recipient 4 days before deletion.</li>
						<li>Added option to CC or BCC the person sending a file bundle notification</li>
						<li>Improved key registration on behalf of users to allow for updating labels</li>
						<li>Reworked in-app messaging to use toasts</li>
						<li>New aggregate view for license keys that allows for viewing meter and/or protocol stats
							across multiple keys and/or host IDs</li>
						<li>Addded total bandwidth to b/w calculator</li>
						<li>Improvements to back-end logging</li>
					</ul>
				</li>

				<li>
					July 2021
					<ul>
						<li>Improved exception logging for automated routines.</li>
						<li>Added logic to detect possible issues with user names (all lower case, all upper case, same
							as e-mail, etc)</li>
						<li>Added new report for downloads by product</li>
						<li>Merged daily processing of meter stats with protocol stats to retire meter stats reporting
							from legacy license portal</li>
					</ul>
				</li>

				<li>
					June 2021
					<ul>
						<li>Added average daily traffic and % change row when viewing daily meter or protocol usage
							stats for a key or host ID</li>
						<li>Added new % change column when viewing daily meter or protocol usage stats for a key or host
							ID</li>
						<li>Added new filter options to user list related to user being linked/not linked to a
							Salesforce lead/contact</li>
						<li>When viewing a user, show key templates they can access directly and/or as part of a user
							group</li>
						<li>When viewing a user, show groups they're manager of</li>
						<li>Now showing # of managers in a user group in user group list</li>
						<li>Added automated daily clean up of unused user accounts and Organizations (deleted after 60
							days)
						</li>
					</ul>
				</li>

				<li>
					May 2021
					<ul>
						<li>Added new license key search special filter that treats special filters and OR conditions
							instead of AND conditions</li>
						<li>Added new license key search fields for Organization, Billing Code and Template</li>
						<li>Added option to Unused ZEN Master Report to filter by site type</li>
						<li>Added option for staff to share keys (and send notification) with users when creating new
							keys.</li>
					</ul>
				</li>

				<li>
					April 2021
					<ul>
						<li>Added Unused ZEN Master Report for staff as an optional automated report to run</li>
						<li>Users have the option, when sharing keys with other users, to send them an e-mail
							notification</li>
						<li>Added optional days of week for daily reports</li>
						<li>Added option for staff to skip key reports if they're emtpy</li>
						<li>Added new license key search special filters related finding keys where the number of
							activations is more than 80% maximum</li>
						<li>Let basic users (customers/parters) see usage information (metering only for now) for a
							specific host ID.</li>
						<li>Added drag and drop file upload</li>
						<li>Short term file sharing (&lt;2 weeks - max. file size 5 GB) feature added</li>
						<li>Re-worked navigation menu for staff single menu with basic portal functions (downloads,
							partners, etc) under sub-menu</li>
					</ul>

				<li>
					March 2021
					<ul>
						<li>Added automated notifications for expiring keys, keys going over usage limit and staff key
							reports</li>
						<li>Added option to broadcast general and product specific notifications</li>
						<li>Added new timeframes (1/15/30/60 minutes) to traffic calculator</li>
						<li>Added option to broadcast notifications for Builds and Documents</li>
						<li>Added new notification configuration page</li>
						<li>Added new underlying framework for queuing e-mail notifications</li>
						<li>Added new license key search special filters related to meter usage</li>
						<li>Added new license key search special filters related to templates</li>
						<li>Added key search options by : created by (in ZCP), last edited by (in ZCP) and Organization
							Type</li>
						<li>Added option to add journal entries to more objects (build + documents)</li>
						<li>Added option to filter protocol traffic by billing codes for keys and host IDs if the
							key/host ID has any traffic tagged with a billing code.</li>
						<li>Added alternate name for key products for basic users (customers/partners)</li>
						<li>Changed key type and product from full words to acronyms to save space in table lists
						</li>
						<li>Re-worked display of protocol traffic for keys and host IDs; Added color coding for
							in/transcode/out traffic; Added sub-total for transcode traffic</li>
					</ul>
				</li>

				<li>
					Feburary 2021
					<ul>
						<li>Added special filters to user and organization lists</li>
						<li>Added color coding to object lists to indicate disabled status</li>
						<li>Added new search form for finding offline licenses (licenses created without a key for
							devices not connected to the internet)</li>
						<li>Added ability to see daily totals for overal total and in/out sub-totals when viewing
							protocol usage stats.</li>
						<li>Added in/out sub-totals when viewing protocol usage stats.</li>
						<li>Added option to key search to hide extra search options. New buttons to show/hide advanced
							search options.</li>
						<li>Added option for user and organization groups to have assigned managers that can manage the
							group's membership</li>
						<li>Added count of recent (reported non-zero traffic in last week) Bx count when viewing a user
							or organization's list of keys</li>
						<li>Moved management of ZEN Master from Organization to its own section</li>
						<li>Moved management of Partners from Organization to its own section</li>
						<li>Collapsed Logs tab to just icon for multiple objects</li>
						<li>Added option to add journal entries to some objects (users, organizations, keys, ZEN Master
							sites, partnerships)</li>
						<li>Added column indicating if key is enabled/disabled when viewing an Organization or User's
							keys</li>
						<li>Added toggle button when viewing a key's activations to switch between first meter/protocol
							report/usage dates</li>
						<li>Added traffic calculator for staff</li>
						<li>Added option to download recent (last 2 days) licenses issues for a key in zip filr</li>
						<li>Added filter options to user and organization lists</li>
						<li>Added average daily bit rate when viewing key usage for a month</li>
						<li>Added copy key button to key lists for users and organizations</li>
						<li>Exposed the 'Match Keys Report' via the UI; Generates a spreadsheet with keys that
							could/should be matched to Organizations</li>
						<li>Added new route for direct linking to the New Organization form passing in a Salesforce
							Account ID </li>
						<li>Added new mechanism to search for Organizations to link to based on a key's customer field
							and/or Salesforce opportunity</li>
						<li>Added option for dashboard messages for staff only</li>
						<li>Added new option with nav menu to have merged (admin + user) menu for staff</li>
						<li>Changed nav menu to support upcoming key templates feature</li>
						<li>Expand User's key report to include meter usage</li>
						<li>Added option to run Organization key report with meter usage</li>
						<li>Added new key search options based on feature(s) enabled</li>
						<li>Added Daily Totals tab to Usage Dashboard</li>
						<li>Re-worked 'Other' Organization type to be split into 'Restricted' and 'Internal'</li>
						<li>Added special flags to Organizations to prevent using 'special/internal' organizations from
							being used unintentionally.
						</li>
					</ul>
				</li>

				<li>
					January 2021
					<ul>
						<li>Added basic framework for Billing codes</li>
						<li>Added option to top usage dashboard to select key type(s)</li>
						<li>Added filter options to build list</li>
						<li>Added sub-section to system logs for reviewing recent e-mail delivery issues.</li>
						<li>Expanded log entries when updating objects to include details on changes.</li>
						<li>Expanded Organization Key Report to include protocol usage (merged, aggregate, aggregate by
							type, each key).</li>
						<li>Included failed logins in global login logs view</li>
						<li>Added tracking user's timezone offset and code during login and display code in login logs.
						</li>
						<li>Exposed new log sections for Downloads, Clicks and Logins.</li>
						<li>Re-organization of menu items. Created two sub-menus - Objects & Logs</li>
						<li>Changed user registration to push new lead to Marketo instead of Salesforce</li>
						<li>Retired "frozen" build functionality in favour of access tags for deleting Builds</li>
						<li>Added new access tags related to deleting several object types (Platforms, Products,
							Packages, User Groups, Organization Groups, Builds, Documents, Files) for more granular
							access assignment/containment</li>
						<li>Added new system warnings to dashboard for unverified license keys and disabled tech reps
						</li>
						<li>Added Access Summary page for auditing access assigned to groups and users</li>
						<li>Added option to download the license file for any license key activation</li>
						<li>Shifted management of User Groups, Organization Groups, Packages, Products, Platforms,
							Files, Builds + Documents from Roles-based control to assigned access (direct or via group)
						</li>
						<li>Removed 'Staff-CS' role in favor of assigned access</li>
						<li>Removed 'Staff-Publisher' role in favor of assigned access</li>
						<li>Added new User Group object to the system to replace access inheritance by role</li>
						<li>Moved Groups nav menu item to button within Organizations</li>
						<li>Added button to admin view of Builds and Documents that links to the user's view of those
							objects.
						</li>
						<li>New option with package to allow/disallow registered users from subscribing.</li>
						<li>Updated meter expiry warnings to only show impending expiry warnings when no other future
							meters exist. </li>
						<li>Added links/logos to Twitter and Linked-In from nav menu</li>
						<li>Exposed Files in Read-only mode to all Staff</li>
						<li>Exposed Platforms and Products in Read-only mode to all Staff</li>
						<li>Exposed Packages and Organization Groups in Read-only mode to all Staff</li>
						<li>Changed role based permission for managing Organizations, Users and Keys to be based on
							assigned access</li>
						<li>Removed 'Staff-Coordinator' role in favor of assigned access</li>
						<li>Changed terminology from Integrations to Partners/Partnerships</li>
						<li>Changed out write access to parts of the application from being role based to
							assigned/inherited
							access based</li>
					</ul>
				</li>
			</ul>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-2020" aria-labelledby="nav-2020-tab">
			<ul class="cp-general-width-limit">
				<li>
					December 2020
					<ul>
						<li>Added new tab with organizations that share the same Salesforce account in organizations
							report spreadsheet</li>
						<li>Added # keys to organizations report spreadsheet</li>
						<li>Expose change log from dashboard for staff</li>
						<li>Added Top 50 Traffic by Keys/Host IDs from dashboard for staff</li>
						<li>Added month total for all stats (total traffic) when viewing a key/host ID's protocol
							traffic</li>
						<li>Create direct link page for downloadable documents</li>
						<li>Added option to view features/limits for each license issued to a host ID</li>
						<li>Added new license key search special filters related to permanent keys</li>
						<li>Added month total when viewing detailed day by day meter/protocol stats</li>
						<li>Added "needs upgrade to v13+" icon to host ID in 'Activations' tab when viewing a key</li>
						<li>Sort usage stats protocols to show "in" fields, non "in/out" fields and then "out" fields
						</li>
						<li>Added link to go admin's view of a key when viewing key in user section if staff user</li>
						<li>Added link to go user's view of a key when viewing key in admin section</li>
						<li>Build replacement pages for license portal's key summary and details pages</li>
						<li>Moved lists of direct access builds and documents to new tab when viewing an organization
							and the list is long</li>
						<li>Added option to files to 'disable auto-build'</li>
					</ul>
				</li>

				<li>
					November 2020
					<ul>
						<li>Added "recently active" icon to host ID in 'Activations' tab when viewing a key</li>
						<li>Added 'test mode' to auto-build</li>
						<li>Added acronym of Account Owner to organization list</li>
						<li>Added "Private Only" flag to Products; Used for special/SDK products</li>
						<li>Updated license keys view of features and limits to be driven from new key definition
							objects</li>
						<li>Added infrastructure/UI for defining license key products and properties</li>
						<li>Expanded key warnings/messages to show meter warnings if metering is not enforced</li>
						<li>Where Salesforce IDs are shown (users, organizations, keys), let user fetch details</li>
						<li>Added report spreadsheet for an organization's linked keys</li>
						<li>Added new license key search special filters for enabled/disabled keys</li>
						<li>Change 'welcome' token timeout to 24 hours</li>
						<li>Added new license key search special filters related to tech reps and account owners for
							linked organizations</li>
					</ul>
				</li>

				<li>
					October 2020
					<ul>
						<li>Added 'Keys' tab to organizations</li>
						<li>Added new license key search special filters related to linked/not linked/not verified keys
						</li>
						<li>Let staff link license keys to organizations</li>
						<li>Added geographic fields (country + state/province for US/Canada) to user registration form
							and add those fields to Salesforce contact</li>
						<li>Let users save license key searches for quick access later</li>
						<li>Added host ID search to staff and user view of keys with large (500+) number of activations
						</li>
						<li>Showing color coded icons in license key search results indicating meter/protocol
							reporting/usage </li>
						<li>In User Documents view, offer table view/list view toggle</li>
						<li>Added new license key search special filters related to expiring keys/meters</li>
						<li>Option to save license key search results as spreadsheet</li>
						<li>Improvements to user and organization report spreadsheets</li>
						<li>Added new license key search options related to recent reporting/usage</li>
						<li>Added usage report spreadsheet for license keys and host IDs</li>
						<li>Added 'Version History' tab to host ID</li>
						<li>Show first/latest usage/traffic for both meters and protocol stats when viewing a license
							key</li>
						<li>Show first/latest usage/traffic for both meters and protocol stats when viewing a host ID
						</li>
						<li>Added 'Protocol Traffic' tab to license key</li>
						<li>Added new tracking for first/latest usage/traffic for both meters and protocol stats along
							with reported version (via protocol stats)</li>
						<li>Created routine that periodically checks for change in Salesforce account ownership</li>
					</ul>
				</li>

				<li>
					September 2020
					<ul>
						<li>Added Object Search for ZEN Master</li>
						<li>Expanded Salesforce account selection list in organization form to include owner + ID</li>
						<li>Show parsed version of browser info in login history</li>
						<li>Exposed meter usage to user's view of their registered keys</li>
						<li>Made having at least one tech rep mandatory for enabled organizations</li>
						<li>Made linking organizations to Salesforce mandatory for enabled organizations</li>
						<li>Added new options for builds 'is frozen', 'is retired'</li>
						<li>Added 'Downloads' tab to builds</li>
						<li>Added 'ZEN Master' tab to builds to show what ZEN Master site(s) are using this Bx build for
							a cloud launched Bx</li>
						<li>Add 'Cloud Broadcaster Builds' tab when viewing a ZEN Master site; shows available Bx builds
							to that ZEN Master site</li>
						<li>Added 'Users' tab to license key to see user(s) that have registered the key</li>
						<li>General searching and viewing of license keys for staff</li>
					</ul>
				</li>

				<li>
					August 2020
					<ul>
						<li>Added system info (general stats) to dashboard for staff</li>
						<li>Added system messages; show on dashboard</li>
						<li>Added dashboard (showing recent builds + documents, key warnings)</li>
						<li>Added 'label' field to builds for easier identification of special/SDK builds</li>
						<li>Added 'Keys' tab to user to staff can see keys that users have registered</li>
						<li>Let staff register license keys on behalf of users</li>
						<li>Let users register their license keys for access to key details</li>
						<li>Added 'Clicks' tab to users</li>
						<li>Added date filtering options in System Logs</li>
					</ul>
				</li>

				<li>
					July 2020
					<ul>
						<li>Split nav menu to Admin and User items with toggle button</li>
						<li>Added 'Logins' tab to users</li>
						<li>Added 'Downloads' tab to users</li>
						<li>Added 'Downloads' tab to organizations</li>
						<li>Added 'Downloads' tab to files</li>
						<li>Added extra description field to Packages that users can see when subscribing</li>
						<li>Let staff generate organization report spreadsheet</li>
						<li>Let staff generate user report spreadsheet</li>
						<li>Cache the name of the account owner from Salesforce with the organization</li>
						<li>Added ability to link one or more staff members as 'Tech Rep' for an organization</li>
						<li>Migrate ZEN Master Reporting from Zixi Executive Dashboard</li>
					</ul>
				</li>

				<li>
					June 2020
					<ul>
						<li>Expanded filtering options in System Logs</li>
						<li>Started tracking user clicks on links to external sites</li>
						<li>Let ZEN Master query available builds for an organization to dictate what versions are
							available in ZEN Master for launching cloud broadcasters</li>
						<li>Added option to link a ZEN Master site to an organization</li>
						<li>New user role (Read-Only) to let for sales and management view objects in the system</li>
						<li>Added organizations groups to allow for assigning builds and documents to groups of
							organizations for initiatives like Beta Customers</li>
						<li>Show platform (Windows, Linux, etc) icons throughout app</li>
						<li>Option for staff to flag organizations as 'favorite' for easy sorting</li>
						<li>Added 'Log' tab to organizations and Users</li>
						<li>User self-registration and optional sign-up for package (like MediaConnect) access</li>
						<li>Option to generate CLI commands (curl, wget) to download installer files</li>
						<li>New user role (Coordinator) to handle managing integrators</li>
						<li>Started tracking user downloads</li>
						<li>Portal launched with base functionality - Organizations, Users, Platforms, Products, Files,
							Builds, Documents (June 10)</li>
					</ul>
				</li>
			</ul>

			<button type="button" *ngIf="canImpersonate && !impersonateEnabled" (click)="enableImpersonate()"
				class="btn btn-outline-info">Enable Impersonate</button>

		</div>
	</div>
</div>