import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';

import { OrganizationsService } from '../organizations.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-organization-billing-codes-form',
	templateUrl: './organization-billing-codes-form.component.html',
	styleUrls: ['./organization-billing-codes-form.component.scss']
})
export class OrganizationBillingCodesFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	id: number;
	organization: Models.Organization;

	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	private userSubscription: Subscription;

	// other stuff
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private organizationsService: OrganizationsService,
		private authService: AuthService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			if (this.id && this.id !== 0) {
				this.organization = this.organizationsService.getOne(this.id);
				if (!this.organization || this.organization == null || this.organization.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
				}
			} else {
				this.router.navigate([AppConstants.urls.notfound]);
			}
			this.loading = false;
		});
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe(authUser => {

		});

		this.setup();
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async setup() {
		this.initForm();
	}

	initForm() {
		this.theForm = new UntypedFormGroup({
			labels: new UntypedFormControl('', [Validators.required])
		});
	}

	async onSubmit() {
		this.saving = true;

		const orgBillingCodes = await this.organizationsService.fetchBillingCodes(this.id);

		const labels = this.theForm.value.labels;

		const registerErrors = [];

		let numProcessed = 0;
		let numAdded = 0;
		let array: string[] = labels.match(/[^\r\n]+/g);
		const size1 = array.length;

		array = MiscTools.removeDuplicates(array);
		const size2 = array.length;

		if (size1 !== size2)
			this.uiAlertsService.addMsg('Skipping ' + (size2 - size1) + ' duplicates.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

		for (const label of array) {
			if (label.trim() !== '') {
				numProcessed++;
				try {
					const idx = MiscTools.findIndexGeneric(orgBillingCodes, 'label', label.trim());
					if (idx !== -1) {
						this.uiAlertsService.addMsg('"' + label.trim() + '" has already been used.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
					} else {
						const billingCode: Models.BillingCode = new Models.BillingCode(0, this.id, '', '', label.trim(), 1);
						const retBc = await this.organizationsService.addBillingCode(this.id, billingCode);
						numAdded++;
					} // if

				} catch (e) {
					registerErrors.push(e.message);
				}
			}
		}

		for (const err of registerErrors)
			this.uiAlertsService.addMsg(err, 'error');

		if (numAdded === 1)
			this.uiAlertsService.addMsg('Created one billing code.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		else if (numAdded > 1)
			this.uiAlertsService.addMsg('Created ' + numAdded + ' billing code.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

		this.saving = false;
		if (registerErrors.length === 0 && numAdded > 0) this.onCancel();
	}

	onCancel() {
		this.router.navigate([AppConstants.urls.organizations, this.id]);
	}

	get label() { return this.theForm.get('label'); }
}
