import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';

import { AuthService } from 'client/app/services/auth.service';
import { LicensingAdminService } from '../licensing-admin.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-licensing-property-form',
	templateUrl: './licensing-property-form.component.html',
	styleUrls: ['./licensing-property-form.component.scss']
})

export class LicensingPropertyFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	id: number;
	property: Models.LicenseProperty;
	errors: string[] = [];
	editMode = false;
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	canPairWithProperties: Models.LicenseProperty[] = [];

	propTypeSelections: any[] = [];


	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService,
		private licensingAdminService: LicensingAdminService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
		});
	}

	ngOnInit(): void {
		this.initForm();
	}

	ngOnDestroy() {
	}

	async initForm() {
		if (this.id && this.id !== 0) {
			this.property = await this.licensingAdminService.getProperty(this.id);

			if (!this.property || this.property == null || this.property.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
				return;
			} // if
			this.editMode = true;
		} else {
			this.property = new Models.LicenseProperty(0, '', 'boolean', '', '', '', 0, 0, 1, 0);
		}

		const allProperties = await this.licensingAdminService.getProperties();

		const pairedIDs = [];
		// find all props that have been paired with another prop
		for (const prop of allProperties) {
			if (prop.paired_with && prop.paired_with !== 0) {
				pairedIDs.push(prop.paired_with);
				pairedIDs.push(prop.id);
			}
		}

		this.canPairWithProperties = [];
		// for (const prop of allProperties) {
		// 	if (!pairedIDs.includes(prop.id) || (this.property.paired_with && prop.id === this.property.paired_with)) {
		// 		const propCopy = { ...prop };
		// 		propCopy.label = propCopy.label + ' (' + propCopy.name + ') ' + propCopy.ptype;
		// 		this.canPairWithProperties.push(propCopy);
		// 	}
		// }

		if (this.property.paired_with === 0) this.property.paired_with = null;

		this.propTypeSelections = [];

		for (const propType of AppConstants.keyPropertyTypes)
			this.propTypeSelections.push({
				value: propType,
				label: AppConstants.keyPropertyTypeLabels[propType] + ' (' + propType + ')'
			});

		this.theForm = new UntypedFormGroup({
			label: new UntypedFormControl(this.property.label, [Validators.required]),
			name: new UntypedFormControl(this.property.name, [Validators.required]),
			ptype: new UntypedFormControl(this.property.ptype, [Validators.required]),
			description: new UntypedFormControl(this.property.description),
			units: new UntypedFormControl(this.property.units),
			allow_unlimited: new UntypedFormControl(this.property.allow_unlimited),
			special_property: new UntypedFormControl(this.property.special_property),
			fulfillment_name: new UntypedFormControl(this.property.fulfillment_name),
		});
		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		this.property.label = this.theForm.value.label;
		this.property.name = this.theForm.value.name;
		this.property.description = this.theForm.value.description;
		this.property.ptype = this.theForm.value.ptype;
		this.property.units = this.theForm.value.units;
		this.property.allow_unlimited = this.theForm.value.allow_unlimited;
		this.property.special_property = this.theForm.value.special_property;
		this.property.fulfillment_name = this.theForm.value.fulfillment_name;
		// this.property.paired_with = +this.theForm.value.paired_with;

		try {
			let retProperty: Models.LicenseProperty;
			if (this.editMode)
				retProperty = await this.licensingAdminService.updateProperty(this.property);
			else
				retProperty = await this.licensingAdminService.addProperty(this.property);

			if (retProperty) {
				this.router.navigate([AppConstants.urls.licensingadmin + '/properties/' + retProperty.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	// get message() { return this.theForm.get('message'); }
	get ptype() { return this.theForm.get('ptype'); }

}
