import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import { ProductsService } from '../products.service';
import { PlatformsService } from '../../platforms/platforms.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-product-form',
	templateUrl: './product-form.component.html',
	styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	id: number;
	product: Models.Product;
	errors: string[] = [];
	editMode = false;
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;
	private listSubscription: Subscription;

	// other stuff
	allPlatforms: Models.Platform[] = [];
	counter = 0;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private productsService: ProductsService,
		private platformsService: PlatformsService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			if (this.id && this.id !== 0) {
				this.product = this.productsService.getOne(this.id);
				if (!this.product || this.product == null || this.product.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
				} else {
					this.productsService.refreshOne(this.id);
					this.editMode = true;
				}
			} else {
				this.product = new Models.Product(0, AppConstants.defaultProductType, '', '', '');
			}

			this.loadExtras();
			this.loading = false;
		});
	}

	ngOnInit(): void {
		if (this.editMode) {
			this.listSubscription = this.productsService.products.subscribe(products => {
				this.product = products.find((product: Models.Product) => product.id === this.id);
				this.initForm();
			});
		} else {
			this.initForm();
		}
	}

	ngOnDestroy() {
		if (this.listSubscription) this.listSubscription.unsubscribe();
	}

	initForm() {
		this.theForm = new UntypedFormGroup({
			ptype: new UntypedFormControl(this.product.ptype, [Validators.required]),
			name: new UntypedFormControl(this.product.name, [Validators.required]),
			filename_prefix: new UntypedFormControl(this.product.filename_prefix),
			information: new UntypedFormControl(this.product.information),
			download_build_description: new UntypedFormControl(this.product.download_build_description),
			download_document_description: new UntypedFormControl(this.product.download_document_description),
		});

		this.counter = 0;
		for (const platformType of AppConstants.platformTypes) {
			for (const platform of this.subListOfPlatforms(platformType)) {
				const checked = this.product.platform_ids.indexOf(platform.id) !== -1;
				if (checked) this.counter++;
				this.theForm.addControl('plat_' + platform.id, new UntypedFormControl(checked));
			}
		}

		for (const flag of AppConstants.productFlags) {
			let checked = (this.product.flags && this.product.flags![flag.key]
				&& +this.product.flags[flag.key] === 1);
			this.theForm.addControl('flag_' + flag.key, new UntypedFormControl(checked));
		}
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		this.product.ptype = this.theForm.value.ptype;
		this.product.name = this.theForm.value.name;
		this.product.filename_prefix = this.theForm.value.filename_prefix;
		this.product.information = this.theForm.value.information;
		this.product.download_build_description = this.theForm.value.download_build_description;
		this.product.download_document_description = this.theForm.value.download_document_description;

		this.product.platform_ids = [];
		for (const platformType of AppConstants.platformTypes) {
			for (const platform of this.subListOfPlatforms(platformType)) {
				const val = this.theForm.value['plat_' + platform.id];
				if (val)
					this.product.platform_ids.push(platform.id);
			}
		}

		this.product.flags = {};
		for (const flag of AppConstants.productFlags) {
			const val = this.theForm.value['flag_' + flag.key];
			if (val) this.product.flags[flag.key] = 1;
		}

		try {
			let retProduct: Models.Product;
			if (this.editMode)
				retProduct = await this.productsService.updateOne(this.product);
			else
				retProduct = await this.productsService.addOne(this.product);

			if (retProduct) {
				this.router.navigate([AppConstants.urls.products + '/' + retProduct.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	loadExtras() {
		this.allPlatforms = this.platformsService.getAll();
	}

	subListOfPlatforms(platformType: string) {
		const sublist: Models.Platform[] = [];
		for (const platform of this.allPlatforms)
			if (platform.ptype === platformType)
				sublist.push(platform);
		return sublist;
	}


	adjustCounter(e) {
		if (e.target.checked) {
			this.counter++;
		} else {
			this.counter--;
		}
	}

	checkAllForPlatformType(e, platformType: string) {
		const newValue = e.target.checked;
		for (const platform of this.subListOfPlatforms(platformType)) {
			const curVal = this.theForm.value['plat_' + platform.id];
			if (curVal !== newValue) {
				if (newValue) {
					this.counter++;
				} else {
					this.counter--;
				}
				this.theForm.controls['plat_' + platform.id].setValue(newValue);
			} // if
		}
	}

	get ptype() { return this.theForm.get('ptype'); }
	get name() { return this.theForm.get('name'); }
}

