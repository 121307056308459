<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.licensingadmin" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Licensing
			</li>
			<li class="breadcrumb-item">
				<a href="javascript:void(0)" (click)="onCancel()">Protocol Sets</a>
			</li>
			<li *ngIf="editMode" class="breadcrumb-item active" aria-current="page">
				Edit Protocol Sets
			</li>
			<li *ngIf="!editMode" class="breadcrumb-item active" aria-current="page">
				New Protocol Sets
			</li>
		</ol>
	</nav>
</div>

<form *ngIf="!loading" [formGroup]="theForm" autocomplete="false">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div class="form-group">
			<label for="name">
				Name*
			</label>
			<input type="text" id="name" formControlName="name" class="form-control" />
		</div>

		<div class="form-group">
			<div class="form-group">
				<label for="description">
					Description
				</label>
				<textarea id="description" formControlName="description" class="form-control" rows="4"></textarea>
			</div>
		</div>
	</div>

	<div class="container">
		<div class="row">
			<div *ngFor="let protocolBlock of protocolBlocks; let i = index" class="col-auto">
				<div class="form-group form-check mb-1 mt-0 border-bottom">
					<input type="checkbox" class="form-check-input" [id]="'check_all' + i"
						(click)="checkAllHandler($event, protocolBlock.protocols)" />
					<label class="form-check-label fw-bold cp-pre-text " [for]="'check_all' + i">
						{{ protocolBlock.label }}
					</label>
				</div>
				<div *ngFor="let protocol of protocolBlock.protocols; let i = index" class="form-group form-check mb-0 mt-0">
					<input type="checkbox" class="form-check-input" [id]="'protocol_' + protocol.toLowerCase().replace('-', '_')"
						[formControlName]="'protocol_' + protocol.toLowerCase().replace('-', '_')" />
					<label class="form-check-label" [for]="'protocol_' + protocol.toLowerCase().replace('-', '_')">
						{{ sharedLicenseTools.niceProtocol(protocol, false).replace('MediaConnect ', '').replace('ZM ',
						'') }}
					</label>
				</div>
			</div>
		</div>
	</div>

	<!-- <div class="cp-general-width-limit">

		<div class="form-group mb-2 small">* Required</div>

		<div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div>

		<div class="cp-form-padding"></div>

	</div> -->

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>

</form>