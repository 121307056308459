<div *ngIf="licenseKey" class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.mykeys" class="cp-user-nav-item me-1" size="sm"></fa-icon>
				License Keys
			</li>
			<li class="breadcrumb-item">
				<a href="javascript:void(0)" [routerLink]="['/' + ac.urls.mykeys, licenseKey.id]">
					{{ licenseKey.activation.key }}
				</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				Snooze/Pause Notifications
			</li>
		</ol>
	</nav>
</div>

<div *ngIf="loading || saving" style="text-align: center;">
	<app-loading-spinner></app-loading-spinner>
</div>

<form *ngIf="theForm" [formGroup]="theForm">
	<div class="cp-general-width-limit">

		<div class="cp-compact-form-group">
			<label for="expires_at">
				Resume Notifications On
				<fa-icon [icon]="['fas', 'question-circle']" placement="end" ngbTooltip="When should this snooze/pause expire?">
				</fa-icon>
				<!-- <span *ngIf="maxExpiryDate" class="ms-1">
					(Max. {{ maxExpiryDate | date: ac.shortPageDateFmt: 'UTC' }})
				</span> -->
			</label>
			<div class="input-group">
				<button class="btn btn-outline-secondary" (click)="expires_at.toggle()" type="button">
					<fa-icon icon="calendar-alt"></fa-icon>
				</button>

				<input class="form-control" placeholder="yyyy-mm-dd" id="expires_at" formControlName="expires_at" ngbDatepicker
					firstDayOfWeek="7" #expires_at="ngbDatepicker" style="max-width:14ch" />

				<button class="btn btm-sm btn-outline-secondary" (click)="increaseExpiry(-1, 'next-month')" type="button"
					ngbTooltip="Set the date to the first day of next month">
					Start of Next Month
				</button>

				<button *ngFor="let dateMathButton of ac.dateMathButtons" class="btn btm-sm btn-outline-secondary"
					(click)="increaseExpiry(dateMathButton.amount, dateMathButton.unit)" type="button"
					ngbTooltip="Add this amount of time to the entered date">
					{{ dateMathButton.label }}
				</button>
			</div>
		</div>

		<div class="form-group">
			<label for="snooze_type">Type of Notifications to Snooze/Pause</label>
			<div class="form-check" *ngFor="let selection of ac.keySnoozeTypes">
				<input class="form-check-input" type="radio" formControlName="snooze_type" name="snooze_type"
					[id]="'snooze_type_' + selection.value" [value]="selection.value" />
				<label class="form-check-label" [for]="'snooze_type_' + selection.value">
					<strong>{{ selection.label }}</strong> : {{ selection.description }}
				</label>
			</div>
		</div>

		<!-- <div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div> -->
	</div>

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>

</form>