import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

// import AppConstants from 'appshared/constants/app-constants';
import * as Models from "appshared/shared-models";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	user = new BehaviorSubject<Models.AuthUser>(null); // a BehaviorSubject acts like a subject, but can also returns the latest value of the subject

	setAuthUser(authUser: Models.AuthUser) {
		// console.log('setting user to :');
		// console.log(authUser);
		this.user.next(authUser);
	}

	toggle$ = new Subject<void>();

	constructor() { }


	getUser() {
		return this.user.getValue();
	}
}
