import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import { RoleGuard } from 'client/app/guards/role.guard';

import { LicensingProductListComponent } from './licensing-product-list/licensing-product-list.component';
import { LicensingPropertyListComponent } from './licensing-property-list/licensing-property-list.component';
import { KeyTemplateListComponent } from './key-template-list/key-template-list.component';

import { LicensingProductComponent } from './licensing-product/licensing-product.component';
import { LicensingPropertyComponent } from './licensing-property/licensing-property.component';
import { LicensingProductFormComponent } from './licensing-product-form/licensing-product-form.component';
import { LicensingPropertyFormComponent } from './licensing-property-form/licensing-property-form.component';
import { LicensingProductPropertyFormComponent } from './licensing-product-property-form/licensing-product-property-form.component';
import { LicensingPropertySortComponent } from './licensing-property-sort/licensing-property-sort.component';
import { KeyTemplateComponent } from './key-template/key-template.component';
import { KeyTemplateFormComponent } from './key-template-form/key-template-form.component';
import { ProtocolSetListComponent } from './protocol-set-list/protocol-set-list.component';
import { ProtocolSetComponent } from './protocol-set/protocol-set.component';
import { ProtocolSetFormComponent } from './protocol-set-form/protocol-set-form.component';

import { ProductsResolverService } from '../products/products-resolver.service';
import { UsersResolverService } from '../users/users-resolver.service';
import { UserGroupsResolverService } from '../users/user-groups-resolver.service';
import { OrganizationsResolverService } from '../organizations/organizations-resolver.service';
import { OrganizationGroupsResolverService } from '../organizations/organization-groups-resolver.service';

const routes: Routes = [
	{
		path: 'products',
		component: LicensingProductListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: []
	}, {
		path: 'properties',
		component: LicensingPropertyListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: []
	}, {
		path: 'templates',
		component: KeyTemplateListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: []
	}, {
		path: 'protocol-sets',
		component: ProtocolSetListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: []
	}, {
		path: 'properties/sort-properties',
		component: LicensingPropertySortComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: []
	}, {
		path: 'products/new',
		component: LicensingProductFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [ProductsResolverService]
	}, {
		path: 'properties/new',
		component: LicensingPropertyFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: []
	}, {
		path: 'templates/new/:product',
		component: KeyTemplateFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, UserGroupsResolverService, OrganizationsResolverService, OrganizationGroupsResolverService]
	}, {
		path: 'protocol-sets/new',
		component: ProtocolSetFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: []
	}, {
		path: 'products/:id',
		component: LicensingProductComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, ProductsResolverService]
	}, {
		path: 'properties/:id',
		component: LicensingPropertyComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService]
	}, {
		path: 'templates/:id',
		component: KeyTemplateComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, UserGroupsResolverService, OrganizationsResolverService, OrganizationGroupsResolverService]
	}, {
		path: 'protocol-sets/:id',
		component: ProtocolSetComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService]
	}, {
		path: 'products/:id/edit',
		component: LicensingProductFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [ProductsResolverService]
	}, {
		path: 'products/:id/property/:id2/edit',
		component: LicensingProductPropertyFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: []
	}, {
		path: 'properties/:id/edit',
		component: LicensingPropertyFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: []
	}, {
		path: 'templates/:id/edit',
		component: KeyTemplateFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, UserGroupsResolverService, OrganizationsResolverService, OrganizationGroupsResolverService]
	}, {
		path: 'protocol-sets/:id/edit',
		component: ProtocolSetFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: []
	}, {
		path: 'templates/:id/clone',
		component: KeyTemplateFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, UserGroupsResolverService, OrganizationsResolverService, OrganizationGroupsResolverService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class LicensingAdminRoutingModule { }
