import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';

import { MyQuestionsMainComponent } from './my-questions-main/my-questions-main.component';

const routes: Routes = [
	{
		path: '',
		component: MyQuestionsMainComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole },
		resolve: []
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class MyQuestionsRoutingModule { }
