import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share, map, tap } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class FilesService {
	files: Observable<Models.File[]>;
	loaded = false;
	private debug = false;
	private _files: ReplaySubject<Models.File[]>;
	private dataStore: {
		files: Models.File[];
	};

	constructor(private http: HttpClient) {
		this.dataStore = {
			files: []
		};
		this._files = new ReplaySubject(1) as ReplaySubject<Models.File[]>;
		this.files = this._files.asObservable();
	}

	// add/update an item in the datastore
	private updateStore(newFile: Models.File): void {
		const idx = this.dataStore.files.findIndex(file => file.id === newFile.id);
		if (idx === -1) {
			this.dataStore.files.push(newFile);
			return;
		} else {
			this.dataStore.files[idx] = newFile;
		}
	}

	// get a fresh copy of the entire set
	refreshAll(): Observable<Models.File[]> {
		const files$ = this.http.get<Models.File[]>(AppConstants.apiUrl + AppConstants.apiUrls.files).pipe(share());
		files$.subscribe(
			data => {
				const files: Models.File[] = data;
				// remove ones from the store that aren't in the response (they've been deleted)
				this.dataStore.files.forEach((existingFile, existingIndex) => {
					const newIndex = files.findIndex(file => file.id === existingFile.id);
					if (newIndex === -1) this.dataStore.files.splice(existingIndex, 1);
				});
				// add/update all the ones that came back
				files.forEach(file => this.updateStore(file));
				this._files.next(Object.assign({}, this.dataStore).files);
				this.loaded = true;
			},
			error => { /* console.log(error) */ }
		);
		return files$;
	}

	// get a fresh copy of a single item
	refreshOne(id: number): Observable<Models.File> {
		const file$ = this.http.get<Models.File>(AppConstants.apiUrl + AppConstants.apiUrls.files + '/' + id).pipe(share());
		file$.subscribe(
			data => {
				const file: Models.File = data;
				// add/update the one that came back
				this.updateStore(file);
				this._files.next(Object.assign({}, this.dataStore).files);
			},
			error => { /* console.log(error) */ }
		);
		return file$;
	}

	// return the whole list
	getAll(): Models.File[] {
		return this.dataStore.files.slice();
	}

	// grab a single item from the datastore
	getOne(id: number): Models.File {
		return this.dataStore.files.find(file => file.id === id);
	}

	// createPresignedPost
	async createPresignedPost(filename: string): Promise<any> {
		const result = await this.http.get<any>(AppConstants.apiUrl
			+ AppConstants.apiUrls.files + '/pre-signed-post/' + encodeURIComponent(filename)).toPromise();
		const obj: any = result;
		return obj;
	}

	// doPresignedPost
	doPresignedPost(url: string, formData: any) {
		if (this.debug) { console.log('about to upload to server'); }
		return this.http
			.post<any>(url, formData, { reportProgress: true, observe: 'events' })
			.pipe(
				map((event) => {
					return event;
				}),
				tap(() => {
					if (this.debug) { console.log('back from from server, done.'); }
				}));
	}

	// addFile - add/update file entry
	async addFile(filename: string, filesize: number): Promise<any> {
		const result = await this.http.post<any>(AppConstants.apiUrl
			+ AppConstants.apiUrls.files, { filename, filesize }).toPromise();
		const obj: any = result;
		this.updateStore(obj);
		return obj;
	}

	// call back-end to set token
	async findByToken(token: string) {
		try {
			const result = await this.http.get<Models.File>(AppConstants.apiUrl + AppConstants.apiUrls.files
				+ '/findbytoken/' + encodeURIComponent(token)).toPromise();
			const returnedFile: Models.File = result;
			return returnedFile;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to set token
	async setToken(id: number, token: string) {
		try {
			const result = await this.http.put<Models.File>(AppConstants.apiUrl + AppConstants.apiUrls.files + '/' + id, { free_access_token: token }).toPromise();
			const returnedFile: Models.File = result;
			this.updateStore(returnedFile);
			this._files.next(Object.assign({}, this.dataStore).files);
			return returnedFile;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to delete an item
	async deleteOne(id: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl + AppConstants.apiUrls.files + '/' + id).toPromise();
			const idx = this.dataStore.files.findIndex(file => file.id === id);
			if (idx !== -1) this.dataStore.files.splice(idx, 1);
			this._files.next(Object.assign({}, this.dataStore).files);
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to delete an item
	async getFreeUrl(id: number) {
		try {
			const result = await this.http.get<{ url: string; }>(AppConstants.apiUrl + AppConstants.apiUrls.files + '/' + id + '/free-url').toPromise();

			const url: string = result.url;
			return url;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to enable/disable
	async toggleAutoBuild(id: number) {
		try {
			const result = await this.http.put<Models.File>(AppConstants.apiUrl + AppConstants.apiUrls.files + '/' + id + '/toggle-autobuild', {}).toPromise();
			const returnedFile: Models.File = result;
			this.updateStore(returnedFile);
			this._files.next(Object.assign({}, this.dataStore).files);
			return returnedFile;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchUnassignedFromDB(): Promise<Models.File[]> {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.files + '?unassigned=yes';
			const result = await this.http.get<Models.File[]>(url).toPromise();
			const arr: Models.File[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async checkForFile(filename: string): Promise<Models.File> {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.files + '/findbyname/' + encodeURIComponent(filename);
			const result = await this.http.get<Models.File>(url).toPromise();
			const obj: Models.File = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchBuildsFromDB(id: number): Promise<Models.Build[]> {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.files + '/' + id + '/builds';
			const result = await this.http.get<Models.Build[]>(url).toPromise();
			const arr: Models.Build[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchDocumentsFromDB(id: number): Promise<Models.Document[]> {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.files + '/' + id + '/documents';
			const result = await this.http.get<Models.Document[]>(url).toPromise();
			const arr: Models.Document[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}
}
