import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import { RoleGuard } from 'client/app/guards/role.guard';

import { MyPackagesComponent } from './my-packages/my-packages.component';

const routes: Routes = [
	{
		path: '',
		component: MyPackagesComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole },
		resolve: []
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class MyPackagesRoutingModule { }
