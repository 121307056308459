import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';

import { UsersResolverService } from '../users/users-resolver.service';
import { UserGroupsResolverService } from '../users/user-groups-resolver.service';

import { SearchLogsComponent } from './search-logs/search-logs.component';
import { AddJournalFormComponent } from './add-journal-form/add-journal-form.component';

const routes: Routes = [
	{
		path: '', component: SearchLogsComponent, canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole }, resolve: [UsersResolverService, UserGroupsResolverService]
	}, {
		path: 'journal/:objecttype/:id', component: AddJournalFormComponent, canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole }, resolve: []
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AdminLogsRoutingModule { }
