import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';

import { MarketplaceLogsComponent } from './marketplace-logs/marketplace-logs.component';

import { UsersResolverService } from '../users/users-resolver.service';
import { ZenmasterResolverService } from '../zen-customers/zenmaster-resolver.service';
import { MarketplaceInfoComponent } from './marketplace-info/marketplace-info.component';

const routes: Routes = [
	{
		path: 'logs', component: MarketplaceLogsComponent, canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole }, resolve: [UsersResolverService, ZenmasterResolverService]
	},
	{
		path: 'info', component: MarketplaceInfoComponent, canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole }, resolve: [UsersResolverService, ZenmasterResolverService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class MarketplaceRoutingModule { }
