import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import { SystemMessagesService } from '../system-messages.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-system-message-form',
	templateUrl: './system-message-form.component.html',
	styleUrls: ['./system-message-form.component.scss']
})
export class SystemMessageFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	id: number;
	systemMessage: Models.SystemMessage;
	errors: string[] = [];
	editMode = false;
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;
	private listSubscription: Subscription;

	// other stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	startAt: NgbDateStruct = null;
	endAt: NgbDateStruct = null;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService,
		private systemMessagesService: SystemMessagesService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			if (this.id && this.id !== 0) {
				this.systemMessage = this.systemMessagesService.getOne(this.id);
				if (!this.systemMessage || this.systemMessage == null || this.systemMessage.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
				} else {
					this.systemMessagesService.refreshOne(this.id);
					this.editMode = true;
				}
			} else {
				this.systemMessage = new Models.SystemMessage(0, '', '', null, null, 1, 1, 0);
			}
			this.loading = false;
		});
	}

	ngOnInit(): void {

		this.userSubscription = this.authService.user.subscribe((authUser) => {
			if (this.editMode) {
				this.listSubscription = this.systemMessagesService.messages.subscribe(messages => {
					this.systemMessage = messages.find((message: Models.SystemMessage) => message.id === this.id);
					this.initForm();
				});
			} else {
				this.initForm();
			}
		});
	}

	ngOnDestroy() {
		if (this.listSubscription) this.listSubscription.unsubscribe();
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	initForm() {
		if (this.systemMessage.start_at) {
			const startAtDate = new Date(this.systemMessage.start_at);
			this.startAt = { day: startAtDate.getUTCDate(), month: startAtDate.getUTCMonth() + 1, year: startAtDate.getUTCFullYear() };
		}

		if (this.systemMessage.end_at) {
			const endAtDate = new Date(this.systemMessage.end_at);
			this.endAt = { day: endAtDate.getUTCDate(), month: endAtDate.getUTCMonth() + 1, year: endAtDate.getUTCFullYear() };
		}

		this.theForm = new UntypedFormGroup({
			is_enabled: new UntypedFormControl(this.systemMessage.is_enabled),
			staff_only: new UntypedFormControl(this.systemMessage.staff_only),
			can_ack: new UntypedFormControl(this.systemMessage.can_ack),
			message: new UntypedFormControl(this.systemMessage.message, [Validators.required]),
			url: new UntypedFormControl(this.systemMessage.url),
			start_at: new UntypedFormControl(this.startAt),
			end_at: new UntypedFormControl(this.endAt),
		});
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		this.startAt = this.theForm.value.start_at;
		let startAtDate: Date = null;
		if (this.startAt) {
			startAtDate = new Date(this.startAt.year + '/' + this.startAt.month + '/' + this.startAt.day);
			if (isNaN(startAtDate.getTime())) startAtDate = null;
		}

		this.endAt = this.theForm.value.end_at;
		let endAtDate: Date = null;
		if (this.endAt) {
			endAtDate = new Date(this.endAt.year + '/' + this.endAt.month + '/' + this.endAt.day);
			if (isNaN(endAtDate.getTime())) endAtDate = null;
		}

		this.systemMessage.is_enabled = this.theForm.value.is_enabled;
		this.systemMessage.staff_only = this.theForm.value.staff_only;
		this.systemMessage.can_ack = this.theForm.value.can_ack;
		this.systemMessage.message = this.theForm.value.message;
		this.systemMessage.url = this.theForm.value.url;
		this.systemMessage.start_at = startAtDate;
		this.systemMessage.end_at = endAtDate;

		try {
			let retMessage: Models.SystemMessage;
			if (this.editMode)
				retMessage = await this.systemMessagesService.updateOne(this.systemMessage);
			else
				retMessage = await this.systemMessagesService.addOne(this.systemMessage);

			if (retMessage) {
				// ******************************************
				this.router.navigate([AppConstants.urls.systemmessages + '/' + retMessage.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	get message() { return this.theForm.get('message'); }

}
