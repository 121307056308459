import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import { UiAlertsService } from './ui-alerts.service';

@Component({
	selector: 'app-ui-alerts',
	templateUrl: './ui-alerts.component.html',
	styleUrls: ['./ui-alerts.component.scss']
})
export class UiAlertsComponent implements OnInit, OnDestroy {
	private listsub: Subscription;
	messages: Models.UiAlert[];

	constructor(private uiAlertsService: UiAlertsService) { }

	ngOnInit(): void {
		this.messages = this.uiAlertsService.getAll();
		// watch for the list for updates
		this.listsub = this.uiAlertsService.listChanged.subscribe(
			(messages: Models.UiAlert[]) => {
				this.messages = messages;
			}
		);
	}

	ngOnDestroy() {
		this.listsub.unsubscribe();
	}

	closeMessage(id: string): void {
		this.uiAlertsService.clearMsgByCode(id);
	} // ngOnDestroy

	closeAll(): void {
		this.uiAlertsService.clearAll();
	} // closeAll

	// ------------------------------------------------------------------------*********************************
	getBorderVisClass = (msg: Models.UiAlert) => {
		if (msg.msgClass && msg.msgClass !== ''
			&& ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'].includes(msg.msgClass))
			return 'alert alert-' + msg.msgClass;
		else
			return 'alert alert-primary';
	};



	// ************************************************************************************************
	getBorderClass = (msg: Models.UiAlert) => {
		if (msg.msgClass && msg.msgClass !== ''
			&& ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'].includes(msg.msgClass))
			return 'alert-' + msg.msgClass;
		else
			return 'alert-primary';
	};


	// clearMsg(idx: number) {
	// 	this.uiAlertsService.clearMsg(idx);
	// }

	// clearMsgByCode(code: string) {
	// 	this.uiAlertsService.clearMsgByCode(code);
	// }

	// clearAll() {
	// 	this.uiAlertsService.clearAll();
	// }

	// getClass(idx: number) {
	// 	if (idx > this.messages.length - 1) {
	// 		return 'cp-light-message m-2';
	// 	} else if (this.messages[idx].msgClass.toLowerCase() === 'error') {
	// 		return 'cp-danger-message m-2';
	// 	} else if (this.messages[idx].msgClass.toLowerCase() === 'warning') {
	// 		return 'cp-warning-message m-2';
	// 	} else {
	// 		return 'cp-info-message m-2';
	// 	}
	// }
}



