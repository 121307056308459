import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';
import PopOverTools from 'appshared/popover-tools';

import { DocumentsService } from '../documents.service';
import { ProductsService } from '../../products/products.service';
import { FilesService } from '../../files/files.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { MyBuildsService } from '../../my-builds/my-builds.service';
import { MyDocumentsService } from '../../my-documents/my-documents.service';
import { PackagesService } from '../../packages/packages.service';
import { OrganizationGroupsService } from '../../organizations/organization-groups.service';
import { UsersService } from '../../users/users.service';
import { AuthService } from 'client/app/services/auth.service';
import { DownloadLogsService } from '../../download-logs/download-logs.service';
import { AdminLogsService } from '../../admin-logs/admin-logs.service';
import { LinkLogsService } from '../../link-logs/link-logs.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

import { LogsTableComponent } from 'client/app/components/shared/logs-table/logs-table.component';
import { ClicksTableComponent } from 'client/app/components/shared/clicks-table/clicks-table.component';
import { DownloadsTableComponent } from 'client/app/components/shared/downloads-table/downloads-table.component';
import { JournalsTableComponent } from 'client/app/components/shared/journals-table/journals-table.component';
import { PopupBoxComponent } from 'client/app/components/shared/popup-box/popup-box.component';

@Component({
	selector: 'app-document',
	templateUrl: './document.component.html',
	styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	popOverTools = PopOverTools;
	textTools = TextTools;
	miscTools = MiscTools;

	@ViewChild('logsTable1') logsTable1: LogsTableComponent = null;
	@ViewChild('clicksTable1') clicksTable1: ClicksTableComponent = null;
	@ViewChild('downloadsTable1') downloadsTable1: DownloadsTableComponent = null;
	@ViewChild('journalsTable1') journalsTable1: JournalsTableComponent = null;
	@ViewChild(PopupBoxComponent) popupBox: PopupBoxComponent = null;

	loading = true;
	// showPostLoadElements = false;
	authUser: Models.AuthUser;

	// 'standard' view stuff
	id: number;
	document: Models.Document;
	file: Models.File;

	// other stuff
	private userSubscription: Subscription;
	releaseNotesFile: Models.File;
	product: Models.Product;
	organizations: Models.Organization[] = [];
	groups: Models.OrganizationGroup[] = [];
	packages: Models.Package[] = [];
	addedByUser: Models.User = null;
	editedByUser: Models.User = null;

	canAddJournals = false;
	journals: Models.JournalEntry[] = [];

	canEdit = false;
	canDelete = false;

	showDownloadPageLink = false;

	downloadLogs: Models.DownloadLog[] = [];
	linkLogs: Models.LinkLog[] = [];
	adminLogs: Models.AdminLog[] = [];

	canSendNotifications = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private documentsService: DocumentsService,
		private productsService: ProductsService,
		private filesService: FilesService,
		private organizationsService: OrganizationsService,
		private usersService: UsersService,
		private organizationGroupsService: OrganizationGroupsService,
		private packagesService: PackagesService,
		private myBuildsService: MyBuildsService,
		private myDocumentsService: MyDocumentsService,
		private downloadLogsService: DownloadLogsService,
		private adminLogsService: AdminLogsService,
		private linkLogsService: LinkLogsService
	) {

		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.document = this.documentsService.getOne(this.id);
			if (!this.document || this.document == null || this.document.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
			} else {

				this.userSubscription = this.authService.user.subscribe((authUser) => {
					this.authUser = authUser;
					if (authUser) {
						this.canAddJournals = ValidationTools.checkAccess(authUser, 'add-journals');
						this.canEdit = ValidationTools.checkAccess(authUser, 'manage-documents');
						this.canDelete = this.canEdit && ValidationTools.checkAccess(authUser, 'delete-documents');
						this.canSendNotifications = ValidationTools.checkAccess(authUser, 'send-document-notifications');
						this.loadData();
					}
				});

				this.documentsService.refreshOne(this.id);
			}
		});
	}

	ngOnInit(): void {
		// downloads
	}

	ngOnDestroy() {
	}

	async loadData() {
		this.loading = true;

		// get orgs and files related to this document...
		this.product = this.productsService.getOne(this.document.product_id);

		const dDoc = await this.myDocumentsService.getOneDocument(this.id);
		this.showDownloadPageLink = dDoc != null && dDoc.document.id !== 0;

		if (this.document.file_id && this.document.file_id !== 0)
			this.file = this.filesService.getOne(this.document.file_id);

		this.organizations = [];
		for (const orgID of this.document.org_ids) {
			const organization = this.organizationsService.getOne(orgID);
			this.organizations.push(organization);
		}
		this.organizations.sort((a, b) => (a.name > b.name) ? 1 : -1);

		this.groups = [];
		for (const grpID of this.document.group_ids) {
			const group = this.organizationGroupsService.getOne(grpID);
			this.groups.push(group);
		}
		this.groups.sort((a, b) => (a.name > b.name) ? 1 : -1);

		this.packages = [];
		for (const pkgID of this.document.package_ids) {
			const pkg = this.packagesService.getOne(pkgID);
			if (pkg) this.packages.push(pkg);
		}
		this.packages.sort((a, b) => (a.name > b.name) ? 1 : -1);

		this.addedByUser = null;
		if (this.document.added_by && this.document.added_by !== 0)
			this.addedByUser = this.usersService.getOne(this.document.added_by);

		this.editedByUser = null;
		if (this.document.edited_by && this.document.edited_by !== 0)
			this.editedByUser = this.usersService.getOne(this.document.edited_by);

		if (this.document.file_id && this.document.file_id !== 0)
			this.downloadLogs = await this.downloadLogsService.getLogsForFile(this.document.file_id);
		if (this.downloadsTable1)
			this.downloadsTable1.updateContent(this.downloadLogs, 'cp-document-view-download-logs', { showUserInfo: true, linkUser: true });

		if (this.document.link && this.document.link !== '')
			this.linkLogs = await this.linkLogsService.getLogsForURL(this.document.link);
		if (this.clicksTable1)
			this.clicksTable1.updateContent(this.linkLogs, 'cp-document-view-click-logs', { showUserInfo: true, linkUser: true });

		this.adminLogs = await this.adminLogsService.getLogs(['document'], this.id);

		if (this.logsTable1)
			this.logsTable1.updateContent(this.adminLogs, 'cp-document-view-admin-logs', { showUserInfo: true, showObjectInfo: false, linkObject: false, linkUser: true });

		this.journals = await this.adminLogsService.getJournals('document', this.id);
		if (this.journalsTable1)
			this.journalsTable1.updateContent(this.journals);

		this.loading = false;
	}

	// getFileSize(fileID: number): number {
	// 	const idx = MiscTools.findIndex(this.files, fileID);
	// 	if (idx !== -1) {
	// 		return this.files[idx].size;
	// 	}
	// 	return 0;
	// }

	// getFileName(fileID: number): string {
	// 	const idx = MiscTools.findIndex(this.files, fileID);
	// 	if (idx !== -1) {
	// 		return this.files[idx].name;
	// 	}
	// 	return '???';
	// }

	getOrganizationName(orgID: number): string {
		const idx = MiscTools.findIndex(this.organizations, orgID);
		if (idx !== -1) {
			return this.organizations[idx].name;
		}
		return '???';
	}

	// ------------------------------------------------------------------------
	prepDelete() {
		this.popupBox.openPopup('confirm-text', 'delete', [], 'Delete Document',
			'If you delete this Document, it will be permanently deleted and cannot be recovered.',
			null,
			{ confirmButtonText: 'Delete Document', rejectButtonText: 'Cancel', confirmText: 'delete' });
	}

	// ------------------------------------------------------------------------
	async delete() {
		this.loading = true;

		const preDeleteOrgIds: number[] = this.document.org_ids.slice();
		const preDeletePkgIds: number[] = this.document.package_ids.slice();

		const result = await this.documentsService.deleteOne(this.document.id);
		if (result) {
			// update any orgs or pkgs
			for (const orgID of preDeleteOrgIds)
				this.organizationsService.refreshOne(orgID);

			for (const pkgID of preDeletePkgIds)
				this.packagesService.refreshOne(pkgID);

			this.uiAlertsService.addMsg('The document (' + this.document.label + ') has been deleted.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

			this.router.navigate([AppConstants.urls.documents]);
		} else {
			return false;
		}
	}

	async disable() {
		if (confirm('Are you sure you want to disable this Document?')) {
			const result = await this.documentsService.toggleEnabled(this.document.id);
			if (result) {
				this.document = result;
				this.loadData();
			} else {
				return false;
			}
		}
	}

	async enable() {
		if (confirm('Are you sure you want to enable this Document?')) {
			const result = await this.documentsService.toggleEnabled(this.document.id);
			if (result) {
				this.document = result;
				this.loadData();
			} else {
				return false;
			}
		}
	}

	getUsersName(id: number): string {
		return this.usersService.getUsersName(id);
	}

	// ------------------------------------------------------------------------
	// Back and Forth with components
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			popupCallBack: async (callBack: string, args: any) => {
				if (callBack === 'delete' && args.length === 0)
					this.delete();
				else if (callBack === 'XXXXX' && args.length === 1)
					console.log('args=' + args);

				else
					this.uiAlertsService.addMsg('Unknown callBack (' + callBack + ') or bad number of arguments (' + args.length + ').', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		}
	} // getParentMethod

}
