import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share, map, tap } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Injectable({
	providedIn: 'root'
})
export class ReportsService {

	constructor(
		private http: HttpClient,
		private uiAlertsService: UiAlertsService
	) {
	}

	// call back-end to get last X logs
	async runReport(reportType: string, queryParams: string = '') {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.reports + '/report/' + encodeURIComponent(reportType);

			if (queryParams && queryParams !== '') url += '?' + queryParams;

			const result = await this.http.get<any>(url).toPromise();
			// const arr: Models.BackgroundTask[] = result;

			this.uiAlertsService.addMsg('Your report request has been submitted. This may take several minutes.\n'
				+ 'You\'ll received an e-mail when the report is ready.\n'
				+ 'When complete, the report will be available via File Sharing.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get last X logs
	async runReportViaPost(reportType: string, queryParams: string, body: any) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.reports + '/report/' + encodeURIComponent(reportType);

			if (queryParams && queryParams !== '') url += '?' + queryParams;

			const result = await this.http.post<any>(url, body).toPromise();
			// const arr: Models.BackgroundTask[] = result;

			this.uiAlertsService.addMsg('Your report request has been submitted. This may take several minutes.\n'
				+ 'You\'ll received an e-mail when the report is ready.\n'
				+ 'When complete, the report will be available via File Sharing.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}


}
