import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';

import { DocumentsService } from '../documents.service';
import { ProductsService } from '../../products/products.service';
import { UsersService } from '../../users/users.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

import { NotificationUsersTableComponent } from 'client/app/components/shared/notification-users-table/notification-users-table.component';

@Component({
	selector: 'app-document-notification-form',
	templateUrl: './document-notification-form.component.html',
	styleUrls: ['./document-notification-form.component.scss']
})
export class DocumentNotificationFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	textTools = TextTools;

	@ViewChild('notificationUsersTable1') notificationUsersTable1: NotificationUsersTableComponent = null;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	// 'standard' edit stuff
	id: number;
	document: Models.Document;
	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	// other stuff
	product: Models.Product;

	users: Models.User[] = [];
	numSubscribed: number = 0;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService,
		private documentsService: DocumentsService,
		private productsService: ProductsService,
		private usersService: UsersService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			if (this.id && this.id !== 0) {
				this.document = this.documentsService.getOne(this.id);
				if (!this.document || this.document == null || this.document.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
				} else {
					this.userSubscription = this.authService.user.subscribe((authUser) => {
						this.authUser = authUser;
						if (authUser) {

							// this.canSendNotifications = ValidationTools.checkAccess(authUser, 'send-build-notifications');
						}
					});
				}
			} else {
				this.router.navigate([AppConstants.urls.notfound]);
			}
		});
	}

	ngOnInit(): void {
		this.initForm();
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async initForm() {
		this.product = this.productsService.getOne(this.document.product_id);

		const userIDs = await this.documentsService.fetchUserIDs(this.id);
		const allUsers = this.usersService.getAll();
		this.users = [];
		this.numSubscribed = 0;

		for (const user of allUsers) {
			if (userIDs.includes(user.id) && user.is_deleted === 0) {
				user['__reason'] = ValidationTools.getDocumentProductNotificationState(user, this.document.product_id);
				if (user['__reason'] === '') this.numSubscribed++;
				this.users.push(user);
			} // if
		} // for

		// saving easier down the line...
		this.theForm = new UntypedFormGroup({
			message: new UntypedFormControl(null)
		});

		this.loading = false;

		await MiscTools.delay(100);
		if (this.notificationUsersTable1)
			this.notificationUsersTable1.updateContent(this.users, 'cp-document-notification-users');
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const message = this.theForm.value.message;
		if (confirm('Are you sure you want to send a notification for this document to ' + this.numSubscribed + ' users(?)')) {
			try {
				await this.documentsService.sendNotification(this.id, message);
				this.uiAlertsService.addMsg('The notification has been queued.',
					'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
				this.onCancel();
			} catch (e) {
				this.saving = false;
				this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} else {
			this.saving = false;
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	hasFlag = (obj: Models.Organization | Models.User | Models.Product, flag: string): boolean => {
		return ValidationTools.hasFlag(obj, flag);
	};
}
