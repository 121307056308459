<div class="login-container">
	<div class="row">
		<div class="col text-center">
			<img width="160" alt="Zixi logo" src="assets/images/zixi-white.png" />
		</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card mt-3 mb-3 text-start mx-auto" [ngStyle]="{ 'width.px': 320 }">
				<div class="card-body">
					<form>
						<div>
							<h3>E-Mail Change</h3>
							<div class="alert alert-primary">{{ message }}</div>
							<div class="text-center">
								<button class="btn btn-primary me-1" type="button" routerLink="/login">
									<fa-icon [icon]="['fas', 'sign-in-alt']"></fa-icon> Login
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>