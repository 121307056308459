<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.users" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" (click)="onCancel()">Users</a>
			</li>
			<li *ngIf="editMode" class="breadcrumb-item active" aria-current="page">
				{{ user.name }}
			</li>
			<li *ngIf="!editMode" class="breadcrumb-item active" aria-current="page">
				New User
			</li>
		</ol>
	</nav>
</div>

<form [formGroup]="theForm" autocomplete="false">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="is_enabled" formControlName="is_enabled" />
			<label class="form-check-label" for="is_enabled">This user is enabled/active (can login).</label>
		</div>

		<div class="form-group">
			<label for="role">Role*</label>
			<div class="form-check" *ngFor="let role of roles">
				<input class="form-check-input" type="radio" formControlName="role" name="role" [id]="'role_' + role" [value]="role" />
				<label class="form-check-label" [for]="'role_' + role">
					<fa-icon [icon]="ac.userRoleIcons[role]"></fa-icon>&nbsp;
					<strong>{{ ac.userRoleLabels[role] }}</strong>
				</label>
				: {{ ac.userRoleExtras[role] }}
			</div>
		</div>

		<div *ngIf="organizations.length !== 0" class="form-group">
			<label for="org_id">Organization</label>
			<ng-select [items]="organizations" bindValue="id" bindLabel="name" id="org_id" formControlName="org_id">
			</ng-select>
		</div>

		<div class="form-group">
			<label for="name">Name*</label>
			<input type="text" id="name" formControlName="name" class="form-control" />
		</div>

		<div class="form-group">
			<label for="title">Title</label>
			<input type="text" id="title" formControlName="title" class="form-control" />
		</div>

		<div class="form-group">
			<label for="email">E-Mail*</label>
			<input type="email" id="email" formControlName="email" class="form-control" />
		</div>

		<div class="form-group">
			<label for="information">Information</label>
			<textarea id="information" formControlName="information" class="form-control" rows="4"></textarea>
		</div>

		<div *ngFor="let flag of ac.userFlags" class="form-group form-check mb-1">
			<input type="checkbox" class="form-check-input" [id]="'flag_' + flag.key" [formControlName]="'flag_' + flag.key" />
			<label class="form-check-label" [for]="'flag_' + flag.key">{{ flag.label }}</label>
		</div>

		<div *ngIf="!editMode" class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="welcomeUser" formControlName="welcomeUser" />
			<label class="form-check-label" for="welcomeUser">Send a welcome message to this user so they can establish
				their password.</label>
		</div>

		<!-- <div class="form-group mb-2 small">* Required</div>

		<div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>
		</div> -->
	</div>

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>

</form>