import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class LinkLogsService {

	constructor(private http: HttpClient) { }

	// call back-end to get last X logs
	async getLogs(startDate: Date = null, endDate: Date = null) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.linklogs + '?a=b';
			if (startDate && startDate !== null) url += '&startDate=' + encodeURIComponent(startDate + '');
			if (endDate && endDate !== null) url += '&endDate=' + encodeURIComponent(endDate + '');

			const result = await this.http.get<Models.LinkLog[]>(url).toPromise();
			const arr: Models.LinkLog[] = result;

			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to add an item
	async addOne(linkLog: Models.LinkLog) {
		try {
			const result = await this.http.post(AppConstants.apiUrl + AppConstants.apiUrls.linklogs, linkLog).toPromise();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get last X logs for a url
	async getLogsForURL(url: string) {
		try {
			const result = await this.http.get<Models.LinkLog[]>(AppConstants.apiUrl + AppConstants.apiUrls.linklogs + '/link/' + encodeURIComponent(url)).toPromise();
			const arr: Models.LinkLog[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get last X logs for a user
	async getLogsForUser(id: number) {
		try {
			const result = await this.http.get<Models.LinkLog[]>(AppConstants.apiUrl + AppConstants.apiUrls.linklogs + '/user/' + id).toPromise();
			const arr: Models.LinkLog[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}



}
