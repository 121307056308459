import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { AdminLogsRoutingModule } from './admin-logs-routing.module';

import { SearchLogsComponent } from './search-logs/search-logs.component';
import { AddJournalFormComponent } from './add-journal-form/add-journal-form.component';


@NgModule({
	declarations: [SearchLogsComponent, AddJournalFormComponent],
	imports: [
		AdminLogsRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		SharedModule,
		FontAwesome,
		ClipboardModule
	]
})
export class AdminLogsModule { }
