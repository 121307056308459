<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.licensing" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" (click)="onCancel()">Key Search</a>
			</li>
			<li *ngIf="activation" class="breadcrumb-item active" aria-current="page">
				{{ activation.key }}
			</li>
		</ol>
	</nav>
</div>

<form *ngIf="!loading" [formGroup]="theForm">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div class="form-group">
			<label for="zcp_org_id">Template</label>
			<div class="form-check" *ngFor="let template of templates">
				<input class="form-check-input" type="radio" formControlName="zcp_template_id" name="zcp_template_id"
					[id]="'template_' + template.id" [value]="template.id" />
				<label class="form-check-label" [for]="'template_' + template.id">
					<strong>{{ template.name }}</strong>
					<fa-icon *ngIf="template.description && template.description !== ''" [icon]="['fas', 'question-circle']" class="ms-1"
						[ngbTooltip]="template.description" placement="end"></fa-icon>
				</label>
			</div>
		</div>

		<!-- <div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'link']"></fa-icon> Set Template
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div> -->
	</div>
	<!-- <div style="height:300px"></div> -->
</form>

<div class="cp-form-padding"></div>

<div *ngIf="!loading && theForm" class="cp-form-buttons">
	<div class="cp-form-buttons-inner">
		<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
			<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
		</button>
		<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
			<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
		</button>

		<span class="gj-75 ps-2">* Required</span>
	</div>
</div>