import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';

import { BuildComponent } from './build/build.component';
import { BuildListComponent } from './build-list/build-list.component';
import { BuildFormComponent } from './build-form/build-form.component';
import { AutoBuildFormComponent } from './auto-build-form/auto-build-form.component';
import { BuildNotificationFormComponent } from './build-notification-form/build-notification-form.component';

import { BuildsResolverService } from './builds-resolver.service';
import { ProductsResolverService } from '../products/products-resolver.service';
import { FilesResolverService } from '../files/files-resolver.service';
import { PlatformsResolverService } from '../platforms/platforms-resolver.service';
import { OrganizationsResolverService } from '../organizations/organizations-resolver.service';
import { PackagesResolverService } from '../packages/packages-resolver.service';
import { OrganizationGroupsResolverService } from '../organizations/organization-groups-resolver.service';
import { UsersResolverService } from '../users/users-resolver.service';

const routes: Routes = [
	{
		path: '',
		component: BuildListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [BuildsResolverService, ProductsResolverService, PlatformsResolverService]
	}, {
		path: 'auto-build',
		component: AutoBuildFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: []
	}, {
		path: 'new/:product_id',
		component: BuildFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [ProductsResolverService, PlatformsResolverService, FilesResolverService,
			OrganizationsResolverService, PackagesResolverService, OrganizationGroupsResolverService]
	}, {
		path: ':id',
		component: BuildComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [BuildsResolverService, ProductsResolverService, PlatformsResolverService,
			FilesResolverService, OrganizationsResolverService, PackagesResolverService, OrganizationGroupsResolverService,
			UsersResolverService]
	}, {
		path: ':id/edit',
		component: BuildFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [BuildsResolverService, ProductsResolverService, PlatformsResolverService,
			FilesResolverService, OrganizationsResolverService, PackagesResolverService, OrganizationGroupsResolverService]
	}, {
		path: ':id/notify',
		component: BuildNotificationFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [BuildsResolverService, ProductsResolverService, PlatformsResolverService, UsersResolverService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class BuildsRoutingModule { }
