import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class SelfRegistrationService {

	constructor(private http: HttpClient) { }

	// call back-end to add an item
	async addOne(user: Models.User, code = '') {
		try {
			const result = await this.http.post<Models.User>(AppConstants.apiUrl
				+ AppConstants.apiUrls.auth + AppConstants.apiUrls.selfregistration, { code, user }).toPromise();
			const returnedUser: Models.User = result;

			// this.updateStore(returnedUser);
			// this._users.next(Object.assign({}, this.dataStore).users);

			return returnedUser;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to add an item
	async getPackageInfo(code: string) {
		try {
			const url = AppConstants.apiUrl
				+ AppConstants.apiUrls.auth + AppConstants.apiUrls.packageinfo + '/' + encodeURIComponent(code);
			const result = await this.http.get<any>(url).toPromise();
			if (result && result.name && result.description)
				return result;
			return null;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

}
