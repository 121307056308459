<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.licensing" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a [routerLink]="['/' + ac.urls.licensing]">Key Search</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				Offline License Search
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
		</div>
	</div>
</div>

<div class="list-wrapper">

	<div [ngStyle]="{'display': formMode === 'searchForm' ? '' : 'none' }">
		<form [formGroup]="theSearchForm">
			<div class="row ps-2 pe-2">
				<div class="cp-filter-block-4">
					<ng-select [items]="ac.searchableKeyTypesSelections" bindValue="value" bindLabel="label" id="typesFilter"
						formControlName="typesFilter" [multiple]="true" placeholder="Type(s)" placement="bottom"
						ngbTooltip="Filter results by one or more license type">
					</ng-select>
				</div>

				<div class="cp-filter-block-4">
					<ng-select [items]="productsSelections" bindValue="value" bindLabel="label" id="productsFilter"
						formControlName="productsFilter" [multiple]="true" placeholder="Product(s)" placement="bottom"
						ngbTooltip="Filter results by one or more license product">
					</ng-select>
				</div>

				<div class="cp-filter-block-4">
					<ng-select [items]="ac.specialOfflineSearches" bindValue="value" bindLabel="label" id="specialFilter"
						formControlName="specialFilter" [multiple]="true" placeholder="Specialized Filter(s)" placement="bottom"
						ngbTooltip="Special filters/search parameters">
					</ng-select>
				</div>

				<div class="cp-filter-block-4">
					<input type="text" id="hostidFilter" formControlName="hostidFilter" class="form-control" placeholder="Host ID(s)/IP(s)"
						placement="bottom" ngbTooltip="Enter one or more (space separated) host IDs and/or host IPs" />
				</div>

				<div class="cp-filter-block-4">
					<input type="text" id="textFilter" formControlName="textFilter" class="form-control" placeholder="Text - Customer/Notes"
						placement="bottom" ngbTooltip="Text to search for in Customer and notes fields" />
				</div>

				<div class="cp-filter-block-4">
					<ng-select [items]="lpusers" bindValue="id" bindLabel="email" id="userIDs" formControlName="userIDs" [multiple]="true"
						placeholder="Last touched by..." placement="bottom"
						ngbTooltip="Last user from legacy license portal to either add or update this license">
					</ng-select>
				</div>
			</div>

			<div *ngIf="searching" style="text-align: center;">
				<app-loading-spinner></app-loading-spinner>
			</div>

			<div class="form-group text-center" *ngIf="!searching">
				<button class="btn btn-primary" type="button" (click)="doSearch()">
					<fa-icon [icon]="['fas', 'search']"></fa-icon> Search for Offline Licenses
				</button>
				<button *ngIf="showResetButton" class="btn btn-outline-primary ms-1" type="button" (click)="resetForm()">
					Reset Search Parameters
				</button>
			</div>
		</form>
		<div *ngIf="showNoParamsMessage" class="cp-warning-message mt-4">You must supply at least one search parameter
		</div>

		<!-- stretches panel down enough so that autocomplete popups have space -->
		<div *ngIf="!showResults" style="height:300px"></div>
	</div>

	<!-- <div *ngIf="showRecentMessage" class="cp-info-message mt-4">Recently Viewed Keys</div> -->
	<div [ngStyle]="{'display': !showResults ? 'none' : '' }">

		<div *ngIf="showResults" class="container mt-2">
			<div class="row mb-2">
				<div class="col-12 p-0 text-center align-middle">
					<span class="fw-bold large border p-2">
						<span *ngIf="!items || items.length === 0" class="text-warning">
							No Offline Licenses Found
						</span>
						<span *ngIf="items && items.length === 1">
							One Offline License Found
						</span>
						<span *ngIf="items && items.length > 0 && maxMessage === ''">
							{{ items.length }} Offline Licenses Found
						</span>
						<span *ngIf="items && items.length > 0 && maxMessage !== ''" class="text-warning">
							{{ maxMessage }}
						</span>
					</span>
				</div>
			</div>
		</div>

		<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

		<table *ngIf="!searching && itemsToShow.length > 0" class="cp-std-table">
			<thead>
				<tr>
					<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-center cp-pre-text cp-pointer"
						[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
						[hidden]="!fieldsToShow.includes(cd.field)">
						<span *ngIf="cd.label" [innerHTML]="cd.label"
							[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
							[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
						<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
					</th>
				</tr>
			</thead>

			<tbody *ngIf="itemsToShow && !searching">
				<tr *ngFor="let item of itemsToShow">
					<td class="text-nowrap" [attr.data-order]="textTools.formatDateTimeUTC(item.created_at)">
						{{ item.created_at | date: ac.tableDateFmt }}
					</td>
					<td>
						{{ item.customer }}
					</td>
					<td [title]="item.type">
						{{ item.type | titlecase }}
					</td>
					<td class="text-nowrap">
						{{ item['__niceProduct'] }}
					</td>
					<td class="text-nowrap cp-key">
						<a [routerLink]="['/'+ ac.urls.licensing, 'hostid', item.hostid]" [ngbPopover]="popupContent"
							popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							{{ item.hostid }}
						</a>
					</td>
					<td class="text-nowrap" [attr.data-order]="textTools.formatDateTimeUTC(item.expires_at)">
						{{ item.expires_at | date: ac.tableDateFmt }}
					</td>
					<td class="text-nowrap">
						<span *ngIf="item.user_id && item.user_id !== 0">
							{{ item['__lastTouched'] | titlecase }}
						</span>
					</td>

					<ng-template #popupContent>
						<div class="cp-popover-banner">{{ item.hostid }}</div>
						<div class="cp-pre-text">{{ popOverTools.getLicensePopoverLines(item).join('\n')}}</div>
					</ng-template>
				</tr>
			</tbody>
		</table>
	</div>
</div>