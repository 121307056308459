<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
	<thead>
		<tr>
			<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-center cp-pre-text cp-pointer"
				[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
				[hidden]="!fieldsToShow.includes(cd.field)">
				<span *ngIf="cd.label" [innerHTML]="cd.label"
					[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
					[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
				<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
			</th>
		</tr>
	</thead>

	<tbody *ngIf="itemsToShow && !loading">
		<tr *ngFor="let item of itemsToShow">
			<td class="text-nowrap" [attr.data-order]="textTools.formatDateTimeUTC(item.added_on)">
				{{ item.added_on | date: ac.tableDateTimeFmt }}
			</td>
			<td *ngIf="showObject" class="text-nowrap">{{ item.object_type }}</td>
			<td *ngIf="showObject" class="text-nowrap">
				<a *ngIf="item.object_type === 'zenmaster'" [routerLink]="['/' + ac.urls.zencustomers, item.object_id]">
					{{ item.object_name }}</a>
				<a *ngIf="item.object_type === 'activation'" [routerLink]="['/' + ac.urls.licensing, 'activation', item.object_id]">
					{{ item.object_name }}</a>
			</td>
			<td class="text-nowrap">{{ item.marketplace }}</td>
			<td class="text-nowrap">
				<span [ngbTooltip]="item.customer_id">
					{{ textTools.chopString(item.customer_id, 15) }}
				</span>
			</td>
			<td class="text-nowrap">
				<span [ngbTooltip]="item['__product']">
					{{ textTools.chopString(item['__product'], 15) }}
				</span>
			</td>
			<td class="text-nowrap">
				<span [ngbTooltip]="item.dimension_api_name">
					{{ textTools.chopString(item.dimension_api_name, 15) }}
				</span>
			</td>
			<td class="text-nowrap" [attr.data-order]="textTools.formatDateTimeUTC(item.report_start)" [title]="'From ' + textTools.formatDateTimeUTC(item.report_start)
			+ ' to ' + textTools.formatDateTimeUTC(item.report_end) + ' UTC'">
				{{ textTools.formatDateNiceUTC(item.report_start) }}
			</td>
			<td class="text-end">{{ item.reported | number : '1.0-0' }}</td>
			<td>{{ item.receipt }}</td>
		</tr>
	</tbody>
</table>