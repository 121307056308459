<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.users" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Users
			</li>
			<li class="breadcrumb-item">
				<a href="javascript:void(0)" (click)="onCancel()">Groups</a>
			</li>
			<li *ngIf="editMode" class="breadcrumb-item active" aria-current="page">
				{{ group.name }}
			</li>
			<li *ngIf="!editMode" class="breadcrumb-item active" aria-current="page">
				New Group
			</li>
		</ol>
	</nav>
</div>

<form *ngIf="theForm" [formGroup]="theForm">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="is_enabled" formControlName="is_enabled" />
			<label class="form-check-label" for="is_enabled">This group is enabled/active (its member
				Users inherit the group's access tags).</label>
		</div>

		<div class="form-group">
			<label for="name">Name*</label>
			<input type="text" id="name" formControlName="name" class="form-control" />
		</div>

		<div class="form-group">
			<label for="information">Information</label>
			<textarea id="information" formControlName="information" class="form-control" rows="4"></textarea>
		</div>

		<div *ngIf="canSetManagers" class="form-group">
			<label for="manager_ids">Managers</label>
			<ng-select [items]="staffUserSelections" bindValue="id" bindLabel="name" id="manager_ids" formControlName="manager_ids"
				[multiple]="true" [closeOnSelect]="false">
			</ng-select>
		</div>

		<div class="form-group">
			<label for="user_ids">Users</label>
			<ng-select [items]="staffUserSelections" bindValue="id" bindLabel="name" id="user_ids" formControlName="user_ids"
				[multiple]="true" [closeOnSelect]="false">
			</ng-select>
		</div>

		<!-- <div class="form-group mb-2 small">* Required</div>

		<div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>
		</div> -->
	</div>

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>
		</div>
	</div>

</form>