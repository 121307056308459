<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.zencustomers" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">ZEN Master Sites</a>
			</li>
			<li class="breadcrumb-item">
				{{ zenCustomer.name }}
			</li>

			<li class="breadcrumb-item active" aria-current="page">
				Admin Settings
			</li>
		</ol>
	</nav>
</div>

<form *ngIf="theForm" [formGroup]="theForm">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div class="form-group">
			<label for="name">Name*</label>
			<input type="text" id="name" formControlName="name" class="form-control" />
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="content_analysis" formControlName="content_analysis" />
			<label class="form-check-label" for="content_analysis">Enable Content Analysis</label>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="automation" formControlName="automation" />
			<label class="form-check-label" for="automation">Enable Automation</label>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="traceroute_history" formControlName="traceroute_history" />
			<label class="form-check-label" for="traceroute_history">Enable Traceroute History</label>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="live_events" formControlName="live_events" />
			<label class="form-check-label" for="live_events">Enable Live Events</label>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="allow_agentz" formControlName="allow_agentz" />
			<label class="form-check-label" for="allow_agentz">Allow Agent Z</label>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="allow_non_sso_login" formControlName="allow_non_sso_login" />
			<label class="form-check-label" for="allow_non_sso_login">Allow Non-SSO Login</label>
		</div>

		<!-- <div class="form-group mb-2 small">* Required</div>

		<div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div>

		<div class="cp-form-padding"></div> -->

	</div>

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>

</form>