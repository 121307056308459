import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';

import * as Models from 'appshared/shared-models';
import { ZenCustomersService } from './zen-customers.service';

// this thing (a resolve) will run before a route is routed
// basically, grab the items from the service

@Injectable({ providedIn: 'root' })
export class ZenmasterResolverService implements Resolve<Models.ZenMasterCustomer[]> {
	constructor(private objService: ZenCustomersService) { }

	resolve(): Observable<Models.ZenMasterCustomer[]> | Observable<never> | Models.ZenMasterCustomer[] {
		// check to see if the items have ever been loaded.  If not, load'em
		if (this.objService.loaded) {
			// console.log('in resolver - the list is alive!');
			return this.objService.getAll();
		} else {
			// console.log('in resolver - need a new list');
			return new Observable((observe: Subscriber<Models.ZenMasterCustomer[]>) => {
				this.objService.refreshAll().subscribe((items: Models.ZenMasterCustomer[]) => {
					observe.next(items);
					observe.complete();
				});
			});
		}
	}
}



