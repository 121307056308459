import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';

import AppConstants from 'appshared/app-constants';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';

@Component({
	selector: 'app-popup-box',
	templateUrl: './popup-box.component.html',
	styleUrls: []
})
export class PopupBoxComponent {
	ac = AppConstants;

	@Input() parentApi !: any;

	uniqueId: string = TextTools.randomString(10, 'ABCDE0123456789')

	popupMode: string = ''; // confirm, confirm-text, alert, alt-confirm, alt-alert
	callBack: string = '';
	banner: string = 'the banner';
	message: string = '';
	icon: any = null;
	args: any[] = [];

	confirmButtonText: string = 'Yes';
	rejectButtonText: string = 'No';
	confirmText: string = '';

	popupForm: UntypedFormGroup;
	showWrongTextMsg: boolean = false;
	showAlternatePopup: boolean = false;


	// ------------------------------------------------------------------------
	constructor() { }

	// ------------------------------------------------------------------------
	async openPopup(popupMode: string, callBack: string, args: any[], banner: string = '', message: string = '', icon: any = null, options: any = {}) {
		this.popupMode = popupMode;
		this.callBack = callBack;
		this.banner = banner;
		this.message = message;
		this.icon = icon;
		this.args = args;

		this.showWrongTextMsg = false;

		let optionKeys: string[] = [];
		if (options) optionKeys = Object.keys(options);

		if (optionKeys.includes('confirmButtonText')) this.confirmButtonText = options.confirmButtonText;
		if (optionKeys.includes('rejectButtonText')) this.rejectButtonText = options.rejectButtonText;
		if (optionKeys.includes('confirmText')) this.confirmText = options.confirmText;

		this.popupForm = new UntypedFormGroup({
			confirmation: new UntypedFormControl(null, [])
		});

		if (['alert', 'alt-alert'].includes(this.popupMode)) this.confirmButtonText = 'OK';

		if (['alt-confirm', 'confirm', 'confirm-text'].includes(this.popupMode) && (!this.message || this.message === ''))
			this.message = 'Are you sure?';

		if (['alt-confirm', 'alt-alert'].includes(this.popupMode))
			this.showAlternatePopup = true;
		else
			this.clickButton('open-popup-box-modal' + this.uniqueId);

		if (this.popupMode === 'confirm-text') {
			await MiscTools.delay(200);
			this.focusOn('confirmation');
		} // if
	} // 

	// ------------------------------------------------------------------------
	async confirmAction() {
		if (this.popupMode === 'confirm-text') {
			let confirmation: string = '';
			if (this.popupForm && this.popupForm.value.confirmation) confirmation = this.popupForm.value.confirmation;
			if (confirmation.trim().toLowerCase() !== this.confirmText) {
				this.showWrongTextMsg = true;
				return;
			} // if
		} // if

		if (this.parentApi) {
			if (['alt-confirm', 'alt-alert'].includes(this.popupMode))
				this.showAlternatePopup = false;
			else
				this.clickButton('close-popup-box-modal' + this.uniqueId);

			if (!['alert', 'alt-alert'].includes(this.popupMode))
				this.parentApi.popupCallBack(this.callBack, this.args);
		} // if
	} // 

	// ------------------------------------------------------------------------
	async reject() {
		if (this.popupMode === 'alt-confirm')
			this.showAlternatePopup = false;
		else
			this.clickButton('close-popup-box-modal' + this.uniqueId);
	} // 

	// ------------------------------------------------------------------------
	popUpKeyPress(e) {
		// console.log(e);
		if ((e && e.keyCode == 13) || e == 0) {
			if (['confirm', 'alert'].includes(this.popupMode))
				this.confirmAction();
		} // if
	} // popUpKeyPress

	// ------------------------------------------------------------------------
	clickButton(id: string) {
		// this.debugMessage('click ' + id);
		if (document.getElementById(id)) {
			// this.debugMessage('clicking ' + id);
			document.getElementById(id).click();
		} // if
	} // clickButton

	// ------------------------------------------------------------------------
	focusOn(id: string) {
		if (document.getElementById(id)) {
			document.getElementById(id).focus();
		} // if
	} // focusOn


}
