import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import { ForgotPasswordService } from './forgot-password.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private forgotPasswordService: ForgotPasswordService,
		private uiAlertsService: UiAlertsService
	) {
	}

	ngOnInit(): void {
		this.initForm();
	}

	ngOnDestroy() {
	}

	initForm() {
		this.theForm = new UntypedFormGroup({
			email: new UntypedFormControl(null, [Validators.required, Validators.email, Validators.maxLength(255)])
		});
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const email = this.theForm.value.email.trim();

		try {
			const ret = await this.forgotPasswordService.requestPasswordReset(email);
			if (ret) {
				this.uiAlertsService.addMsg('You\'ve been sent an e-mail with a password reset link.',
					'info', 'PASSWORD_RESET', false, AppConstants.standardMessageAutoCloseTimeSecs * 6);

				this.router.navigate([AppConstants.urls.login]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}

	}

	onCancel() {
		this.router.navigate(['/login']);
	}

	get email() { return this.theForm.get('email'); }

}
