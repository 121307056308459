import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share, map, tap } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class MyFileSharingService {
	debug = false;

	private userBundles: Models.Bundle[];
	public needsRefresh = false;

	private bundleFileSyncs: any = {};

	constructor(private http: HttpClient) { }

	wipe() {
		this.userBundles = null;
	}

	async getUserBundles(forceRefresh = false) {
		// console.log('getUserBundles this.needsRefresh');
		// console.log(this.needsRefresh);
		if (!this.userBundles || this.needsRefresh || forceRefresh) {
			const result = await this.http.get<Models.Bundle[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.filesharing + '/user').toPromise();
			this.userBundles = result;
			this.needsRefresh = false;
		}
		return this.userBundles.slice();
	}

	// grab a single item from the datastore
	async getOne(id: number, forceRefresh = false): Promise<Models.Bundle> {
		// console.log('getOne this.needsRefresh');
		// console.log(this.needsRefresh);
		if (!this.userBundles || this.needsRefresh || forceRefresh)
			await this.getUserBundles(true);
		const idx = MiscTools.findIndex(this.userBundles, id);
		if (idx !== -1)
			return this.userBundles[idx];
		return null;
	}

	// createPresignedPost
	async createPresignedPost(id: number, fileName: string): Promise<any> {
		this.needsRefresh = true;
		const result = await this.http.get<any>(AppConstants.apiUrl
			+ AppConstants.apiUrls.filesharing + '/' + id + '/pre-signed-post/' + encodeURIComponent(fileName)).toPromise();
		const obj: any = result;
		return obj;
	}

	// doPresignedPost
	doPresignedPost(url: string, formData: any) {
		this.needsRefresh = true;
		if (this.debug) { console.log('about to upload to server'); }
		return this.http
			.post<any>(url, formData, { reportProgress: true, observe: 'events' })
			.pipe(
				map((event) => {
					return event;
				}),
				tap(() => {
					if (this.debug) { console.log('back from from server, done.'); }
				}));
	}

	async getDownloadLink(id: number, fileID: number) {
		try {
			const result = await this.http.get<Models.DownloadLink>(AppConstants.apiUrl
				+ AppConstants.apiUrls.filesharing + '/' + id + '/url/' + fileID).toPromise();
			const linkObj: Models.DownloadLink = result;
			return linkObj;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async updateBundleFiles(id: number) {
		try {
			this.needsRefresh = true; // flags that the user's cache needs to be updated

			// check to see if this bundle has a tracker, if not make one
			if (!this.bundleFileSyncs[id]) this.bundleFileSyncs[id] = false;

			// if something else is trying to update this bundle's files
			// do a series of sleep/wait loops until it's done
			const loopWaitSecs = 2;
			const maxLoops = 15;
			let counter = 0;
			while (this.bundleFileSyncs[id] && counter < maxLoops) {
				counter++;
				// console.log('file update wait sleeping ' + loopWaitSecs + ' sec');
				await MiscTools.delay(loopWaitSecs * 1000);
			} // while

			this.bundleFileSyncs[id] = true;
			const result = await this.http.put<Models.Bundle>(AppConstants.apiUrl
				+ AppConstants.apiUrls.filesharing + '/' + id + '/files', {}).toPromise();
			const obj: Models.Bundle = result;
			this.bundleFileSyncs[id] = false;

			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async sendNotification(id: number, includeMe: string = '') {
		try {
			this.needsRefresh = true;
			const result = await this.http.put<any>(AppConstants.apiUrl
				+ AppConstants.apiUrls.filesharing + '/' + id + '/notify', { includeMe }).toPromise();
			const obj: any = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async removeFile(id: number, fileID: number) {
		try {
			const result = await this.http.delete<Models.Bundle>(AppConstants.apiUrl
				+ AppConstants.apiUrls.filesharing + '/' + id + '/' + fileID).toPromise();
			const obj: Models.Bundle = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to add an item
	async startBasicSend(destination: string, information: string) {
		try {
			const result = await this.http.post<Models.Bundle>(
				AppConstants.apiUrl + AppConstants.apiUrls.filesharing, { destination, information }).toPromise();
			const returnedBundle: Models.Bundle = result;
			return returnedBundle;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getAccountTeam() {
		const result = await this.http.get<any[]>(AppConstants.apiUrl
			+ AppConstants.apiUrls.filesharing + '/account-team').toPromise();
		const accountTeam: any[] = result;
		return accountTeam;
	}



}
