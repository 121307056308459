<div class="mt-2">
	<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

	<div *ngIf="items.length > 0">
		<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
			<thead>
				<tr>
					<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-start cp-pre-text cp-pointer"
						[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
						[hidden]="!fieldsToShow.includes(cd.field)">
						<span *ngIf="cd.label" [innerHTML]="cd.label"
							[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
							[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
						<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
					</th>
				</tr>
			</thead>
			<tbody *ngIf="!loading && itemsToShow?.length > 0">
				<tr *ngFor="let item of itemsToShow; let i = index">
					<td class="text-nowrap">
						{{ item.clicked_on | date: ac.tableDateTimeFmt }}
					</td>
					<td *ngIf="showUserInfo" class="text-nowrap" [title]="textTools.escapeForTitle(item['__username'])">
						<a *ngIf="linkUser && item.added_by !== 0" [routerLink]=" ['/' + ac.urls.users, item.clicked_by]">
							{{ textTools.chopString(item['__username'], 20) }}
						</a>
						<span *ngIf="!linkUser || item.added_by === 0">
							{{ textTools.chopString(item['__username'], 20) }}
						</span>
					</td>
					<td class="text-nowrap" [title]="item.url">
						{{ textTools.chopString(item.url, 75, '...') }}
					</td>
					<td class="text-nowrap">{{ item.location }}</td>
					<td>{{ item['__browser'] }}</td>
				</tr>
			</tbody>
		</table>
	</div>

</div>