import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';

import { ProductsResolverService } from '../products/products-resolver.service';
import { PlatformsResolverService } from '../platforms/platforms-resolver.service';

import { MySettingsComponent } from './my-settings.component';
import { MySettingsFormComponent } from './my-settings-form/my-settings-form.component';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';
import { ChangeEmailFormComponent } from './change-email-form/change-email-form.component';

const routes: Routes = [
	{
		path: '',
		component: MySettingsComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole }
	}, {
		path: 'edit',
		component: MySettingsFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole }
	}, {
		path: 'password',
		component: ChangePasswordFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole }
	}, {
		path: 'mail',
		component: ChangeEmailFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole }
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class MySettingsRoutingModule { }
