import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';

import { OrganizationsService } from '../../organizations/organizations.service';
import { ZenCustomersService } from '../zen-customers.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-zenmaster-form',
	templateUrl: './zenmaster-form.component.html',
	styleUrls: ['./zenmaster-form.component.scss']
})
export class ZenmasterFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	id: number;
	zenmaster: Models.ZenMasterCustomer;
	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	private userSubscription: Subscription;

	organizations: any[] = [];
	// other stuff
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private organizationsService: OrganizationsService,
		private authService: AuthService,
		private zenCustomersService: ZenCustomersService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			if (this.id && this.id !== 0) {
				this.zenmaster = this.zenCustomersService.getOne(this.id);
				if (!this.zenmaster || this.zenmaster == null || this.zenmaster.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
				}
			} else {
				this.router.navigate([AppConstants.urls.notfound]);
			}
			this.loading = false;
		});
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.initForm();
		});

	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	initForm() {
		// this.organizations = this.organizationsService.getAll();
		const allOrgs = this.organizationsService.getAll();
		this.organizations = [];
		for (const org of allOrgs)
			if (this.zenmaster.zcp_org_id === org.id || !ValidationTools.hasFlag(org, 'no_zen_sites'))
				this.organizations.push({
					id: org.id,
					name: org.name + ' [' + org.otype + '] - ' + org.salesforce_account_owner
				});

		if (+this.zenmaster.zcp_org_id === 0) this.zenmaster.zcp_org_id = null;

		this.theForm = new UntypedFormGroup({
			zcp_type: new UntypedFormControl(this.zenmaster.zcp_type, [Validators.required]),
			zcp_org_id: new UntypedFormControl(this.zenmaster.zcp_org_id, [Validators.required]),
		});
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		this.zenmaster.zcp_type = this.theForm.value.zcp_type;
		this.zenmaster.zcp_org_id = +this.theForm.value.zcp_org_id;

		try {
			const retPkg = await this.zenCustomersService.updateOne(this.zenmaster);
			if (retPkg) {
				this.router.navigate([AppConstants.urls.zencustomers, this.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate([AppConstants.urls.zencustomers, this.id]);
	}

	get zcp_type() { return this.theForm.get('zcp_type'); }
	get zcp_org_id() { return this.theForm.get('zcp_org_id'); }
}
