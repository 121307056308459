import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';

import { UsersResolverService } from '../users/users-resolver.service';
import { UserGroupsResolverService } from '../users/user-groups-resolver.service';

import { MyQuizListComponent } from './my-quiz-list/my-quiz-list.component';
import { MyQuizViewComponent } from './my-quiz-view/my-quiz-view.component';
import { TakeQuizComponent } from './take-quiz/take-quiz.component';

const routes: Routes = [
	{
		path: '',
		component: MyQuizListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, UserGroupsResolverService]
	}, {
		path: ':id',
		component: MyQuizViewComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, UserGroupsResolverService]
	}, {
		path: ':id/take',
		component: TakeQuizComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, UserGroupsResolverService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class MyQuizzesRoutingModule { }
