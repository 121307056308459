import { Pipe, PipeTransform } from '@angular/core';

import TextTools from 'appshared/text-tools';

@Pipe({
	name: 'amp'
})
export class AmpPipe implements PipeTransform {
	constructor() { }

	transform(theText: any): string {
		return TextTools.fixAmp(theText);
	}
}
