<div class="mt-2">
	<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

	<div *ngIf="items.length > 0">
		<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
			<thead>
				<tr>
					<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-start cp-pre-text cp-pointer"
						[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
						[hidden]="!fieldsToShow.includes(cd.field)">
						<span *ngIf="cd.label" [innerHTML]="cd.label"
							[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
							[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
						<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
					</th>
				</tr>
			</thead>
			<tbody *ngIf="!loading && itemsToShow?.length > 0">
				<tr *ngFor="let item of itemsToShow" [ngClass]="{
						'text-danger': item.is_enabled === 0,
						'text-warning': item.is_enabled === 1 && item.is_onboarded === 0 }">

					<td class="cp-icon-cell">
						<span class="cp-hidden-content">{{ item.is_enabled }}</span>
						<fa-icon *ngIf="item.is_enabled === 1" [icon]="['fas', 'power-off']" size="sm" class="text-success" placement="end"
							ngbTooltip="Enabled - login permitted"></fa-icon>
						<fa-icon *ngIf="item.is_enabled === 0" [icon]="['fas', 'ban']" size="sm" class="text-danger" placement="end"
							ngbTooltip="Disabled - login not permitted"></fa-icon>
					</td>

					<td class="cp-icon-cell">
						<span class="cp-hidden-content">{{ item.is_onboarded }}</span>
						<fa-icon *ngIf="item.is_onboarded === 0" [icon]="['fas', 'exclamation-triangle']" size="sm" placement="end"
							class="text-danger" [ngbTooltip]="item['__onboard_status']">
						</fa-icon>
						<fa-icon *ngIf="item.is_onboarded === 1" [icon]="['fas', 'check']" size="sm" placement="end" class="text-success"
							[ngbTooltip]="item['__onboard_status']">
						</fa-icon>
					</td>

					<td class="cp-chopped-column-long">
						<a [routerLink]="['/' + ac.urls.users, item.id]">{{ item.name }}</a>
						<!-- - {{ changeNameToLastFirst(item.name) }} -->
					</td>
					<td *ngIf="showOrganization" class="cp-chopped-column-long">
						{{ item.org_name && item.org_name !== '' ? item.org_name : '-' }}
					</td>
					<td class="cp-chopped-column-long">{{ item.email }}</td>
					<td class="cp-icon-cell">
						<fa-icon [icon]="ac.userRoleIcons[item.role]" size="sm" [ngbTooltip]="ac.userRoleLabels[item.role]"></fa-icon>
					</td>

					<td class="cp-icon-cell">
						<fa-icon *ngIf="item.notifications == null" [icon]="['fas', 'exclamation-triangle']" size="sm" placement="start"
							class="text-danger" [ngbTooltip]="item['__notifications'] ? item['__notifications'] : ''">
						</fa-icon>
						<fa-icon *ngIf="item.notifications != null" [icon]="['fas', 'check']" size="sm" placement="start"
							class="text-success" [ngbTooltip]="item['__notifications'] ? item['__notifications'] : ''">
						</fa-icon>
					</td>

					<td class="text-center">{{ item['__num_packages'] }}</td>
					<td class="text-center">{{ item['__num_keys'] }}</td>
					<td class="text-center">{{ item['__num_groups'] }}</td>

					<td class="text-center" [title]="textTools.formatDateNiceUTC(item.added_on)">
						{{ item['__days_since_add'] | number }}
					</td>
					<td class="text-center">
						<span *ngIf="item.last_login" [title]="textTools.formatDateNiceUTC(item.last_login)">
							{{ item['__days_since_login'] | number }}
						</span>
					</td>
					<td class="text-center">
						<span *ngIf="item.last_download" [title]="textTools.formatDateNiceUTC(item.last_download)">
							{{ item['__days_since_download'] | number }}
						</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>

</div>