<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<a href="javascript:void(0)" routerLink="..">
					<fa-icon [icon]="ac.sectionIcons.documents" class="cp-admin-nav-item me-1" size="sm">
					</fa-icon>
					Documents
				</a>
			</li>
			<li *ngIf="editMode && !loading" class="breadcrumb-item active" aria-current="page">
				{{ document.label }}
			</li>
			<li *ngIf="!editMode && !loading" class="breadcrumb-item active" aria-current="page">
				New Document
			</li>
		</ol>
	</nav>
</div>

<form *ngIf="theForm && !loading" [formGroup]="theForm">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="is_enabled" formControlName="is_enabled" />
			<label class="form-check-label" for="is_enabled">This document is enabled/active (its file can be
				downloaded).</label>
		</div>

		<div class="form-group">
			<label for="product_id">Product*</label>
			<ng-select [items]="products" bindValue="id" bindLabel="name" id="product_id" formControlName="product_id">
			</ng-select>
		</div>

		<div class="form-group">
			<label for="label">Label*</label>
			<input type="text" id="label" formControlName="label" class="form-control" />
		</div>

		<div class="form-group">
			<label for="file_id">File</label>
			<ng-select [items]="this.docFiles" bindValue="id" bindLabel="name" id="file_id" formControlName="file_id">
			</ng-select>
		</div>

		<div class="form-group">
			<label for="link">Link/URL</label>
			<input type="text" id="link" formControlName="link" class="form-control" />
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="is_private" formControlName="is_private" (change)="onPrivateChange()" />
			<label class="form-check-label" for="is_private">This document is private (only Organizations directly
				assign can download its files)</label>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="can_open" formControlName="can_open" />
			<label class="form-check-label" for="can_open">Users get an open button along with a download
				button</label>
		</div>

		<div class="form-group">
			<label for="org_ids">Organizations - for direct access to private documents</label>
			<ng-select [items]="organizations" bindValue="id" bindLabel="name" id="org_ids" formControlName="org_ids" [multiple]="true"
				[closeOnSelect]="false">
			</ng-select>
		</div>

		<div class="form-group">
			<label for="org_ids">Organization Groups - for direct access to private builds</label>
			<ng-select [items]="groups" bindValue="id" bindLabel="name" id="group_ids" formControlName="group_ids" [multiple]="true"
				[closeOnSelect]="false">
			</ng-select>
		</div>

		<div class="form-group">
			<label for="package_ids">Packages - for direct access to private documents</label>
			<ng-select [items]="packages" bindValue="id" bindLabel="name" id="package_ids" formControlName="package_ids" [multiple]="true"
				[closeOnSelect]="false">
			</ng-select>
		</div>

		<!-- <div class="form-group" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div>

		<div class="form-group mb-2 small">* Required</div> -->

	</div>

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>

</form>