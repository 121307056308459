import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';

import { MyDocumentsService } from '../my-documents.service';
import { AuthService } from 'client/app/services/auth.service';
import { LinkLogsService } from '../../link-logs/link-logs.service';

@Component({
	selector: 'app-my-document',
	templateUrl: './my-document.component.html',
	styleUrls: ['./my-document.component.scss']
})
export class MyDocumentComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	private userSubscription: Subscription;
	private paramSub: Subscription;
	id: number;
	downloadableDocument: Models.DownloadableDocument;
	isStaff = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private myDocumentsService: MyDocumentsService,
		private linkLogsService: LinkLogsService,
		private authService: AuthService
	) { }

	ngOnInit(): void {
		this.paramSub = this.route.params.subscribe((params: Params) => {
			this.id = +params.id;
			this.userSubscription = this.authService.user.subscribe((authUser) => {
				this.isStaff = ValidationTools.checkRole(authUser.role, AppConstants.staffUserRole);
				this.getData();
			});
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
		if (this.paramSub) this.paramSub.unsubscribe();
	}

	async getData(forceRefresh: boolean = false) {
		await this.myDocumentsService.getDocuments(forceRefresh);
		this.downloadableDocument = await this.myDocumentsService.getOneDocument(this.id);
		if (!this.downloadableDocument || this.downloadableDocument == null) {
			this.router.navigate([AppConstants.urls.notfound]);
		}
	} // 

	async downloadFile(id: number, dltype: string = '', refid: number = 0) {
		const dlObj = await this.myDocumentsService.getDownloadLink(id, dltype, refid);
		if (dlObj)
			window.open(dlObj.url, '_blank');
	}

	async openFile(id: number, filename: string) {
		const url = '/open-file/' + id + '/' + filename;
		// const url = 'http://localhost:3000/open-file/' + id + '/' + filename;
		// console.log(url);

		if (url && url !== '')
			window.open(url, '_blank');
	}

	openLink(document: Models.DownloadableDocument) {
		window.open(document.document.link, '_blank');
		this.linkLogsService.addOne(new Models.LinkLog(0, null, document.document.link, ''));
	}

	getFileName(theDoc: Models.DownloadableDocument): string {
		if (theDoc.file) return theDoc.file.name;
		return '';
	}

	getFileSize(theDoc: Models.DownloadableDocument): number {
		if (theDoc.file) return theDoc.file.size;
		return 0;
	}

	chopString(str: string) {
		return TextTools.chopString(str, 60, '...');
	}


	// getFileSize(fileID: number): number {
	// 	const idx = MiscTools.findIndexGeneric(this.downloadableDocument.files, 'id', fileID);
	// 	if (idx !== -1) {
	// 		return this.downloadableDocument.files[idx].size;
	// 	}
	// 	return 0;
	// }

}
