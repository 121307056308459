<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.licensingadmin" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Licensing
			</li>
			<li class="breadcrumb-item active" aria-current="page">Protocol Sets</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button *ngIf="canManage" type="button" class="btn btn-outline-primary me-2" [routerLink]="['new']">
				<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
				<span class="cp-action-button-text">New Protocol Set</span>
			</button>
		</div>
	</div>
</div>

<div class="list-wrapper">
	<div *ngIf="loading" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<table *ngIf="!loading && protocolSets.length > 0" class="cp-std-table cp-auto-width">
		<thead>
			<tr>
				<th>Name</th>
				<th>Description</th>
				<th class="cp-icon-header">
					<span ngbTooltip="Number of Protocols">#</span>
				</th>
				<th class="cp-icon-header">
					<fa-icon [icon]="['fas', 'exclamation-triangle']" size="sm" ngbTooltip="Duplicate" placement="start">
					</fa-icon>
				</th>
				<th class="cp-icon-header">
					<fa-icon [icon]="ac.sectionIcons.licensing" size="sm" ngbTooltip="# Keys" placement="start">
					</fa-icon>
				</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let item of protocolSets" [routerLink]="[item.id]">
				<td class="border-end text-nowrap"><a [routerLink]="[item.id]" [ngbPopover]="pSetPopupContent" popoverClass="cp-popover-std"
						triggers="mouseenter:mouseleave" container="body">
						{{ item.name }}
					</a></td>
				<td class="border-end">{{ item.description }}</td>
				<td class="border-end text-center">{{ item.protocolsArr.length | number }}</td>
				<td class="cp-icon-cell">
					<fa-icon *ngIf="dupilicateSetIds.includes(item.id)" [icon]="['fas', 'exclamation-triangle']" size="sm" placement="start"
						class="text-danger" ngbTooltip="It appears that there is another set with the same protocols.">
					</fa-icon>
				</td>
				<td class="border-end text-center">{{ item.num_keys | number }}</td>
				<ng-template #pSetPopupContent>
					<div class="cp-popover-banner">{{ item.name }}</div>
					<div class="cp-pre-text">{{
						popOverTools.getProtocolSetPopoverLines(item, true).join('\n')}}</div>
				</ng-template>
			</tr>
		</tbody>
	</table>

</div>