<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.licensingadmin" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Licensing
			</li>
			<li class="breadcrumb-item">
				<a href="javascript:void(0)" (click)="onCancel()">Products</a>
			</li>
			<li *ngIf="editMode" class="breadcrumb-item active" aria-current="page">
				Edit Product Property
			</li>
			<li *ngIf="!editMode" class="breadcrumb-item active" aria-current="page">
				New Product Property
			</li>
		</ol>
	</nav>
</div>

<form *ngIf="!loading" [formGroup]="theForm" autocomplete="false">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div *ngIf="!!product && !!property" class="cp-property-list">
			<div class="cp-property-block">
				<span class="cp-property-label">Product:</span>
				<span class="cp-property-value cp-pre-text">{{ product.label }} ({{ product.name }})</span>
			</div>

			<div class="cp-property-block">
				<span class="cp-property-label">Property:</span>
				<span class="cp-property-value cp-pre-text">{{ property.label }} ({{ property.name }})</span>
			</div>

			<div class="cp-property-block">
				<span class="cp-property-label">Type:</span>
				<span class="cp-property-value cp-pre-text">{{ property.ptype }}</span>
			</div>
		</div>

		<div class="form-group">
			<label for="can_be_changed">Can Be Changed</label>
			<div class="form-check ms-2" *ngFor="let val of yesNo; let i = index">
				<input class="form-check-input" type="radio" formControlName="can_be_changed" name="can_be_changed"
					[id]="'can_be_changed' + i" [value]="i" />
				<label class="form-check-label" [for]="'can_be_changed' + i">{{ val }}</label>
			</div>
		</div>

		<div class="form-group">
			<label for="show_user">Show User</label>
			<div class="form-check ms-2" *ngFor="let keyPropShowUserValue of ac.keyPropShowUserValues; let i = index">
				<input class="form-check-input" type="radio" formControlName="show_user" name="show_user" [id]="'show_user' + i"
					[value]="i" />
				<label class="form-check-label" [for]="'show_user' + i">{{ keyPropShowUserValue }}</label>
			</div>
		</div>

		<div class="form-group" *ngIf="property.ptype === 'boolean'">
			<label for="default_value_num">Default Value</label>
			<div class="form-check ms-2" *ngFor="let val of yesNo; let i = index">
				<input class="form-check-input" type="radio" formControlName="default_value_num" name="default_value_num"
					[id]="'default_value_num' + i" [value]="i" />
				<label class="form-check-label" [for]="'default_value_num' + i">{{ val }}</label>
			</div>
		</div>

		<div class="form-group" *ngIf="property.ptype === 'other'">
			<label for="default_value_text">Default Value</label>
			<input type="text" id="default_value_text" formControlName="default_value_text" class="form-control" />
		</div>

		<div class="form-group" *ngIf="property.ptype === 'number'">
			<label for="default_value_num">Default Value</label>
			<input type="text" id="default_value_num" formControlName="default_value_num" class="form-control" />

			<div *ngIf="showUnlimited" class="form-group form-check">
				<input type="checkbox" class="form-check-input" id="unlimited" formControlName="unlimited" />
				<label class="form-check-label" for="unlimited">Unlimited</label>
			</div>
		</div>

		<div class="form-group" *ngIf="property.ptype === 'number'">
			<label for="min_value">Min Value</label>
			<input type="text" id="min_value" formControlName="min_value" class="form-control" />
		</div>

		<div class="form-group" *ngIf="property.ptype === 'number'">
			<label for="max_value">Max Value</label>
			<input type="text" id="max_value" formControlName="max_value" class="form-control" />
		</div>

		<div class="form-group" *ngIf="property.ptype === 'other'">
			<label for="selections">Selections (comma separated)</label>
			<input type="text" id="selections" formControlName="selections" class="form-control" />
		</div>

		<!-- 
		<div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div> -->
	</div>

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>

</form>