<div class="mt-2">
	<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

	<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
		<thead>
			<tr>
				<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-center cp-pre-text cp-pointer"
					[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
					[hidden]="!fieldsToShow.includes(cd.field)">
					<span *ngIf="cd.label" [innerHTML]="cd.label"
						[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
						[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
					<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
				</th>
			</tr>
		</thead>

		<tbody *ngIf="itemsToShow && !loading">
			<tr *ngFor="let item of itemsToShow" [ngClass]="{'text-danger': item.is_enabled === 0}">
				<td class="cp-icon-cell">
					<fa-icon *ngIf="item.is_enabled === 1" [icon]="['fas', 'power-off']" size="sm" class="text-success"
						ngbTooltip="Enabled - Available for download" placement="end"></fa-icon>
					<fa-icon *ngIf="item.is_enabled === 0" [icon]="['fas', 'ban']" size="sm" class="text-danger"
						ngbTooltip="Disabled - Not available for download" placement="end"></fa-icon>
				</td>
				<td class="cp-icon-cell">
					<fa-icon *ngIf="!!item.link && item.link !== ''" [icon]="['fas', 'external-link-square-alt']" size="sm"
						class="text-success" ngbTooltip="Has link" placement="end"></fa-icon>
				</td>
				<td class="cp-icon-cell">
					<fa-icon *ngIf="item.file_id !== 0" [icon]="['fas', 'book']" size="sm" class="text-success" ngbTooltip="Has a file"
						placement="end"></fa-icon>
				</td>
				<td class="cp-icon-cell">
					<fa-icon *ngIf="item.is_private === 1" [icon]="['fas', 'lock']" size="sm" class="text-warning"
						ngbTooltip="Private - Limited Access" placement="end"></fa-icon>
					<fa-icon *ngIf="item.is_private === 0" [icon]="['fas', 'globe']" size="sm" class="text-success"
						ngbTooltip="Public - Generally Available" placement="end"></fa-icon>
				</td>

				<td *ngIf="showProduct" class="cp-chopped-column">{{ item.prod_name }}</td>
				<td class="cp-chopped-column-long">
					<a [routerLink]="['/' + ac.urls.documents, item.id]">
						{{ item.label }}
					</a>
				</td>
				<td class="text-center">{{ item.org_ids.length }}</td>
				<td class="text-center">{{ item.group_ids.length }}</td>
				<td class="text-center">{{ item.package_ids.length }}</td>
				<td class="text-center" [title]="textTools.formatDateNiceUTC(item.added_on)">
					{{ item['__days_since_add'] | number }}
				</td>
				<td class="text-center">
					<span *ngIf="item.last_download">
						{{ item['__days_since_download'] | number }}
					</span>
				</td>
			</tr>
		</tbody>
	</table>
</div>