import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';

import { DocumentComponent } from './document/document.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { DocumentFormComponent } from './document-form/document-form.component';
import { DocumentNotificationFormComponent } from './document-notification-form/document-notification-form.component';

import { DocumentsResolverService } from './documents-resolver.service';
import { ProductsResolverService } from '../products/products-resolver.service';
import { FilesResolverService } from '../files/files-resolver.service';
import { OrganizationsResolverService } from '../organizations/organizations-resolver.service';
import { PackagesResolverService } from '../packages/packages-resolver.service';
import { OrganizationGroupsResolverService } from '../organizations/organization-groups-resolver.service';
import { UsersResolverService } from '../users/users-resolver.service';

const routes: Routes = [
	{
		path: '',
		component: DocumentListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [DocumentsResolverService, ProductsResolverService]
	}, {
		path: 'new',
		component: DocumentFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [ProductsResolverService, FilesResolverService, OrganizationsResolverService,
			PackagesResolverService, OrganizationGroupsResolverService]
	}, {
		path: ':id',
		component: DocumentComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [DocumentsResolverService, ProductsResolverService, FilesResolverService,
			OrganizationsResolverService, PackagesResolverService, OrganizationGroupsResolverService, UsersResolverService]
	}, {
		path: ':id/edit',
		component: DocumentFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [DocumentsResolverService, ProductsResolverService, FilesResolverService,
			OrganizationsResolverService, PackagesResolverService, OrganizationGroupsResolverService]
	}, {
		path: ':id/notify',
		component: DocumentNotificationFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [DocumentsResolverService, ProductsResolverService, UsersResolverService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DocumentsRoutingModule { }
