import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import { PlatformsService } from '../platforms.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-platform-form',
	templateUrl: './platform-form.component.html',
	styleUrls: ['./platform-form.component.scss']
})
export class PlatformFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	id: number;
	platform: Models.Platform;
	errors: string[] = [];
	editMode = false;
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;
	private listSubscription: Subscription;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private platformsService: PlatformsService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			if (this.id && this.id !== 0) {
				this.platform = this.platformsService.getOne(this.id);
				if (!this.platform || this.platform == null || this.platform.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
				} else {
					this.platformsService.refreshOne(this.id);
					this.editMode = true;
				}
			} else {
				this.platform = new Models.Platform(0, AppConstants.defaultPlatformType, '', '', '');
			}
			this.loading = false;
		});
	}

	ngOnInit(): void {
		if (this.editMode) {
			this.listSubscription = this.platformsService.platforms.subscribe(platforms => {
				this.platform = platforms.find((platform: Models.Platform) => platform.id === this.id);
				this.initForm();
			});
		} else {
			this.initForm();
		}
	}

	ngOnDestroy() {
		if (this.listSubscription) this.listSubscription.unsubscribe();
	}

	initForm() {
		// using form control names that match object's property names makes
		// saving easier down the line...
		this.theForm = new UntypedFormGroup({
			ptype: new UntypedFormControl(this.platform.ptype, [Validators.required]),
			name: new UntypedFormControl(this.platform.name, [Validators.required]),
			filename_keyword: new UntypedFormControl(this.platform.filename_keyword),
			information: new UntypedFormControl(this.platform.information)
		});
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		this.platform.name = this.theForm.value.name;
		this.platform.ptype = this.theForm.value.ptype;
		this.platform.filename_keyword = this.theForm.value.filename_keyword;
		this.platform.information = this.theForm.value.information;

		try {
			let retPlatform: Models.Platform;
			if (this.editMode)
				retPlatform = await this.platformsService.updateOne(this.platform);
			else
				retPlatform = await this.platformsService.addOne(this.platform);

			if (retPlatform) {
				this.router.navigate([AppConstants.urls.platforms + '/' + retPlatform.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	get ptype() { return this.theForm.get('ptype'); }
	get name() { return this.theForm.get('name'); }

}
