import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
// import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';

import { MyFileSharingService } from '../my-file-sharing.service';
import { AuthService } from 'client/app/services/auth.service';
import { MySettingsService } from '../../my-settings/my-settings.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-add-files-form',
	templateUrl: './add-files-form.component.html',
	styleUrls: ['./add-files-form.component.scss']
})
export class AddFilesFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;
	isStaff = false;

	id: number;
	bundle: Models.Bundle;

	// theForm: FormGroup;
	files: any[] = [];

	error: string;
	debug = false;

	showClassic = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private mySettingsService: MySettingsService,
		private myFileSharingService: MyFileSharingService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
		});
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;
			this.isStaff = ValidationTools.checkRole(authUser.role, AppConstants.staffUserRole);
			this.initForm();
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async initForm() {
		const user = await this.mySettingsService.getUser();

		this.bundle = await this.myFileSharingService.getOne(this.id, true);
		if (!this.bundle || this.bundle == null || this.bundle.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		}

		const isLocked = MiscTools.daysSince(this.bundle.lock_at, false) > 0;

		let isSender = false;
		if (this.bundle.btype.endsWith('-send'))
			isSender = this.isStaff && ((this.bundle.btype === 'staff-send' && this.bundle.staff_id === user.id)
				|| (this.bundle.btype === 'group-send' && user.group_ids.includes(this.bundle.staff_id)));
		else
			isSender = (this.bundle.org_id && this.bundle.org_id !== 0 && this.bundle.org_id === user.org_id)
				|| (this.bundle.user_ids.length !== 0 && this.bundle.user_ids.includes(user.id));

		// check to see if this user can upload to this bundle...
		// check to see if the bundle has expired
		if (isLocked || !isSender) {
			this.router.navigate(['..'], { relativeTo: this.route });
			return;
		}

		// this.theForm = new FormGroup({
		// 	filesToUpload: new FormControl('', [Validators.required])
		// });
	}

	removeItem(idx) {
		this.files.splice(idx, 1);
	}

	async processAddedFiles(files: any[]) {
		for (const selectedFile of files) {
			let note = '';
			const idx = MiscTools.findIndexGeneric(this.bundle.files, 'name', TextTools.getJustFile(selectedFile.name));
			if (idx !== -1)
				note = 'A file with that name is already in this bundle. This new file will overwrite the existing one.';

			if (selectedFile.size > AppConstants.maxFileSize) {
				this.uiAlertsService.addMsg('That file (' + selectedFile.name + ') is too large.',
					'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

			} else {
				this.files.push(
					{
						obj: selectedFile,
						note,
						uploading: false,
						complete: false,
						progress: 0,
						lastPing: Date
					});
			}
		}
	}

	async classicUpload(event) {
		await this.processAddedFiles(event.target.files);
	}

	async dragDropUpload(event) {
		await this.processAddedFiles(event.addedFiles);
	}

	numberWithCommas(x: number) {
		// // return size.toLocaleString();
		// // return size;
		// return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
		return x.toLocaleString();
	}

	async uploadFile(idx: number) {
		if (idx >= this.files.length) return;
		if (this.files[idx].uploading) return;
		if (this.files[idx].complete) return;

		const pingIntervalMins = 5;

		this.files[idx].uploading = true;
		this.files[idx].lastPing = new Date();

		const file = this.files[idx].obj;

		// console.log(file.name);
		// console.log(file.type);

		const presignedPost = await this.myFileSharingService.createPresignedPost(this.id, file.name);
		// console.log('presignedPost');
		// console.log(presignedPost);

		const formData = new FormData();
		Object.entries(presignedPost.fields).forEach(([k, v]) => {
			formData.append(k, v + '');
		});

		formData.append("file", file);

		this.myFileSharingService.doPresignedPost(presignedPost.url, formData).subscribe(async (res) => {
			const event = res;
			switch (event.type) {
				case HttpEventType.Sent: // 0
					if (this.debug) { console.log('HttpEventType.Sent'); }
					break;

				case HttpEventType.UploadProgress: // 1
					this.files[idx].progress = Math.round(100 * event.loaded / event.total);

					let minsSinceLastPing: number = 0;
					if (this.files[idx].lastPing)
						minsSinceLastPing = ((new Date()).getTime() - this.files[idx].lastPing.getTime()) / 1000 / 60;

					if (minsSinceLastPing > pingIntervalMins) {
						this.files[idx].lastPing = new Date();
						await this.myFileSharingService.updateBundleFiles(this.id);
					}

					// // if (event.loaded === event.total) {
					// // 	this.files[idx].uploading = false;
					// // 	this.files[idx].complete = true;
					// // }

					if (this.debug) { console.log('(' + file.name + ') HttpEventType.UploadProgress - ' + this.files[idx].progress + '%'); }
					break;

				case HttpEventType.ResponseHeader, HttpEventType.Response: // 2
					if (this.debug) { console.log('(' + file.name + ') HttpEventType.ResponseHeader:'); }
					if (this.debug) { console.log(event); }

					if (event.status === 204) {
						if (this.files[idx].progress === 100) {
							await this.myFileSharingService.updateBundleFiles(this.id);
							this.files[idx].uploading = false;
							this.files[idx].complete = true;
						}
						// expected...
					} else if (event.status === 200) {
						this.files[idx].uploading = false;
						// this.bundlesService.updateBundleFiles(this.id);
						this.files[idx].complete = true;
						// this.router.navigate(['..'], { relativeTo: this.route });
					} else if (event.status === 413) {
						alert('There was a problem with the upload.  Possibly due to the files being too large.');
						this.files[idx].uploading = false;
						this.files[idx].complete = false;
					} else {
						alert('There was a problem with the upload. ' + event.status);
						this.files[idx].uploading = false;
						this.files[idx].complete = false;
					}
					break;

				case HttpEventType.DownloadProgress: // 3
					if (this.debug) { console.log('(' + file.name + ') HttpEventType.DownloadProgress:'); }
					if (this.debug) { console.log(event); }
					break;

				default:
					if (this.debug) { console.log('(' + file.name + ') Unknown event type ' + event.type); }
					if (this.debug) { console.log(event); }
					break;
			}
		}, (err) => this.error = err);
	}

	async uploadAll() {
		for (let i = 0; i < this.files.length; i++)
			if (!this.files[i].uploading && !this.files[i].complete)
				await this.uploadFile(i);
	}

	anyUploading() {
		for (const file of this.files)
			if (file.uploading) return true;
		return false;
	}

	anyNotComplete() {
		for (const file of this.files)
			if (!file.complete) return true;
		return false;
	}

	anyNotStarted() {
		for (const file of this.files)
			if (file.progress === 0) return true;
		return false;
	}

	async finishUp() {
		let doNotify: boolean = false;

		if (this.files.length > 0) {

			if (this.anyNotStarted() && !confirm('You have not uploaded all the files.  Are you sure you are done?')) return;

			const loopWaitSecs = 5;
			const maxLoops = 60 / loopWaitSecs * 30; // max wait of 30 minutes
			let counter = 0;
			while (this.anyUploading() && !this.anyNotComplete() && counter < maxLoops) {
				counter++;
				// console.log('sleeping ' + loopWaitSecs + ' sec');
				await MiscTools.delay(loopWaitSecs * 1000);
			} // while
		} // if

		// do one last sync in case
		await this.myFileSharingService.updateBundleFiles(this.id);

		if (this.files.length > 0 && confirm('Would you like to notify the recipient(s)?')) {
			await this.myFileSharingService.sendNotification(this.id, '');
			this.uiAlertsService.addMsg('A notification has been sent to the recipient(s) of these files.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		} // if

		// console.log('routing');
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	// get filesToUpload() { return this.theForm.get('filesToUpload'); }
	// get f() { return this.theForm.controls; }


}
