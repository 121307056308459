import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';

import { UsersService } from '../users.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-add-license-keys-for-user-form',
	templateUrl: './add-license-keys-for-user-form.component.html',
	styleUrls: ['./add-license-keys-for-user-form.component.scss']
})
export class AddLicenseKeysForUserFormComponent implements OnInit {
	ac = AppConstants;

	// 'standard' edit stuff
	errors: string[] = [];
	theForm: UntypedFormGroup;

	id: number;
	user: Models.User;

	userKeys: Models.UserLicenseKey[] = [];

	loading = true;
	saving = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private usersService: UsersService,
		private uiAlertsService: UiAlertsService
	) {
		this.route.paramMap.subscribe((params) => {
			this.id = +params.get('id');
			this.user = this.usersService.getOne(this.id);
			if (!this.user || this.user == null || this.user.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
			}
		});
	}

	ngOnInit(): void {
		this.loadData();
		this.initForm();
	}

	async loadData() {
		// this.userKeys = await this.myKeysService.getUserKeys();
	}

	async initForm() {
		this.loading = false;

		this.theForm = new UntypedFormGroup({
			keyList: new UntypedFormControl(null, [Validators.required]),
			notify: new UntypedFormControl(false)
		});
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const keys = this.theForm.value.keyList;
		const notify = this.theForm.value.notify;

		let numProcessed = 0;
		let numSkipped = 0;
		// let numUpdated = 0;
		const array = keys.match(/[^\r\n]+/g);

		const keysToRegister: any[] = [];

		for (const key of array) {
			if (key.trim() !== '') {
				if (numProcessed >= AppConstants.maxKeysToRegisterPerSubmit) {
					numSkipped++;
				} else {
					numProcessed++;

					let justKey = '';
					let justLabel = '';
					if (key.includes(':')) {
						justKey = TextTools.getLabel(key.trim()); // everything before the :
						justLabel = TextTools.getValue(key.trim()); // everything after the :
					} else if (key.includes(' ')) {
						justKey = TextTools.getLabel(key.trim(), ' '); // everything before the space
						justLabel = TextTools.getValue(key.trim(), ' '); // everything after the space
					} else {
						justKey = key.trim();
					}

					const idx = MiscTools.findIndexGeneric(this.userKeys, 'activation_key', justKey);
					const idx2 = MiscTools.findIndexGeneric(keysToRegister, 'key', justKey);
					if (idx !== -1 || idx2 !== -1) {
						const theKey = this.userKeys[idx];
						this.uiAlertsService.addMsg(key.trim() + ' has already been registered.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
					} else {
						keysToRegister.push({ key: justKey, label: justLabel });
					}
				}
			}
		}

		if (keysToRegister.length > 0) {
			try {
				await this.usersService.registerKeys(this.id, keysToRegister, notify, 'zcp-staff-manual');
			} catch (e) {
				this.saving = false;
				this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		}

		if (this.errors.length === 0) {
			if (keysToRegister.length === 1)
				this.uiAlertsService.addMsg('Registered one key.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			else if (keysToRegister.length > 1)
				this.uiAlertsService.addMsg('Registered ' + keysToRegister.length + ' keys.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}

		if (numSkipped === 1)
			this.uiAlertsService.addMsg('Ignoring one key. Maximum # of keys reached.', 'warning');
		else if (numSkipped > 1)
			this.uiAlertsService.addMsg('Ignoring ' + numSkipped + ' keys. Maximum # of keys reached.', 'warning');

		this.saving = false;
		if (this.errors.length === 0 && keysToRegister.length > 0) this.onCancel();
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	get keyList() { return this.theForm.get('keyList'); }
}
