import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share, map, tap } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class QuizzesService {

	constructor(private http: HttpClient) {
	}

	// *************************************
	async getAll() {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.quizzes;
			const result = await this.http.get<Models.Quiz[]>(url).toPromise();
			const arr: Models.Quiz[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
			return null;
		} // try
	}

	// *************************************
	async getOne(id: number) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.quizzes + '/' + id;
			const result = await this.http.get<Models.Quiz>(url).toPromise();
			const obj: Models.Quiz = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
			return null;
		}
	}

	// *************************************
	async addOne(quiz: Models.Quiz) {
		try {

			let url = AppConstants.apiUrl + AppConstants.apiUrls.quizzes;
			const result = await this.http.post<Models.Quiz>(url, quiz).toPromise();
			const obj: Models.Quiz = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
			return null;
		}
	}

	// *************************************
	async updateOne(quiz: Models.Quiz) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.quizzes + '/' + quiz.id;
			const result = await this.http.put<Models.Quiz>(url, quiz).toPromise();
			const obj: Models.Quiz = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
			return null;
		}
	}

	// *************************************
	async changeStatus(id: number, status: string) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.quizzes + '/' + id
				+ '/change-status/' + encodeURIComponent(status);
			const result = await this.http.put<Models.Quiz>(url, {}).toPromise();
			const obj: Models.Quiz = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
			return null;
		}
	}

	// *************************************
	async deleteOne(id: number) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.quizzes + '/' + id;
			const result = await this.http.delete<Models.Quiz>(url).toPromise();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
			return null;
		}
	}
	// *************************************
	async deleteResponse(id: number, userId: number) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.quizzes + '/' + id + '/' + userId;
			const result = await this.http.delete<Models.Quiz>(url).toPromise();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
			return null;
		}
	}
}
