import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share, map, tap } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class EmailQueueService {

	constructor(private http: HttpClient) {
	}

	// call back-end to get last X logs
	async getQueue(
		messageStatuses: string[],
		messageTypes: string[],
		objTypes: string[],
		userIDs: number[],
		startDate: Date,
		endDate: Date,
		readyToSend: boolean) {

		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.emailqueue + '?a=b';
			if (messageStatuses && messageStatuses.length !== 0) url += '&messageStatuses=' + encodeURIComponent(messageStatuses.join(','));
			if (messageTypes && messageTypes.length !== 0) url += '&messageTypes=' + encodeURIComponent(messageTypes.join(','));
			if (objTypes && objTypes.length !== 0) url += '&objTypes=' + encodeURIComponent(objTypes.join(','));
			if (userIDs && userIDs.length !== 0) url += '&userIDs=' + encodeURIComponent(userIDs.join(','));
			if (startDate && startDate !== null) url += '&startDate=' + encodeURIComponent(startDate + '');
			if (endDate && endDate !== null) url += '&endDate=' + encodeURIComponent(endDate + '');
			if (readyToSend) url += '&readyToSend=yes';

			const result = await this.http.get<Models.EmailQueueEntry[]>(url).toPromise();
			const arr: Models.EmailQueueEntry[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}


	async processQueue() {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.emailqueue + '/process';
			await this.http.put(url, {}).toPromise();
			return;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}

	}

	async updateStatuses() {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.emailqueue + '/verify';
			await this.http.put(url, {}).toPromise();
			return;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

}
