<div class="login-container">
	<div class="row">
		<div class="col text-center">
			<img width="160" alt="Zixi logo" src="assets/images/zixi-white.png" />
		</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card mt-3 mb-3 text-start mx-auto" [ngStyle]="{ 'max-width.px': 400 }">
				<div class="card-body">
					<form *ngIf="theForm" [formGroup]="theForm" (ngSubmit)="onSubmit()">
						<div>
							<h3>Password Reset</h3>
							<div *ngIf="saving" class="text-center">
								<app-loading-spinner></app-loading-spinner>
							</div>

							<div class="form-group">
								<label for="email">E-Mail*</label>
								<input type="email" id="email" formControlName="email" class="form-control" placeholder="e-mail address"
									minlength="1" />
							</div>

							<div class="form-group">
								<label for="password">Password*</label>
								<input type="password" id="password" formControlName="password" class="form-control"
									placeholder="Password" />
							</div>

							<div class="form-group">
								<label for="password2">Password (again)*</label>
								<input type="password" id="password2" formControlName="password2" class="form-control"
									placeholder="Password (again)" />
							</div>

							<div class="mt-2 mb-2 text-center">
								<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid || saving">
									<fa-icon [icon]="['fas', 'passport']"></fa-icon> Reset Password
								</button>
								<button [disabled]="saving" class="btn btn-outline-secondary" type="button" (click)="onCancel()">
									<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
								</button>
							</div>

							<div class="mb-2 small text-center">* Required</div>

							<div *ngIf="supportURL && supportURL !== ''" class="mb-0 small text-center mt-2">
								<a [href]="supportURL" target='_blank'>
									<fa-icon [icon]="['fas', 'life-ring']"></fa-icon> Need Help?
								</a>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>