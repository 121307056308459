import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
import { skip } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class UiAlertsService {
	listChanged = new Subject<Models.UiAlert[]>();
	private messages: Models.UiAlert[] = [];
	checkerInterval: any;

	debug = false;

	timerSecs = 1;

	constructor() { }

	// --------------------------------------------------------------------
	copyToClipboardAlert(item: string) {
		let msg = 'Text copied to the clipboard';
		// console.log(item + ' - ' + item.length);
		if (item && item !== '')
			msg = '\"' + TextTools.chopString(item, 10, '...') + '\" copied to the clipboard';
		this.clearMsgByCode('copyToClipboardAlert');
		this.addMsg(msg, 'info', 'copyToClipboardAlert', false, AppConstants.shortMessageAutoCloseTimeSecs);
	}

	// --------------------------------------------------------------------
	pushUpdate() {
		if (this.debug) console.log('pushUpdate ' + this.messages.length);

		this.listChanged.next(this.messages.slice());
		if (!this.messages || this.messages.length === 0) {
			this.stopTimer();
		} else {
			this.startTimer();
		}
	}

	// --------------------------------------------------------------------
	startTimer() {
		if (this.debug) console.log('starting timer');

		if (!this.checkerInterval)
			this.checkerInterval = setInterval(this.checkForExpiredMessage.bind(this), this.timerSecs * 1000);
	}

	// --------------------------------------------------------------------
	stopTimer() {
		if (this.debug) console.log('stopping timer');

		if (this.checkerInterval)
			clearInterval(this.checkerInterval);
		this.checkerInterval = null;
	}

	// --------------------------------------------------------------------
	getAll(): Models.UiAlert[] {
		// console.log(this.messages);
		return this.messages.slice();
	}

	// --------------------------------------------------------------------
	addMsgs(msgs: string[], msgClass = 'info', msgCode = '', skipOnDuplicate = false, autoCloseSeconds = 0) {
		if (this.debug) console.log('addMsgs ' + this.messages.length);
		for (const msg of msgs)
			if (msg && msg !== '')
				this.addMsg(msg, msgClass, msgCode, skipOnDuplicate, autoCloseSeconds);
	}

	// --------------------------------------------------------------------
	addMsg(msg: string, msgClass = 'info', msgCode = '', skipOnDuplicate = false, autoCloseSeconds = 0) {
		if (this.debug) console.log('addMsg ' + this.messages.length);
		if (this.debug) console.log('got a message ' + msg);

		if (skipOnDuplicate) {
			// check to see if the message is already in the list and skip it...maybe an option...
			let foundDuplicate = false;
			for (const otherMsg of this.messages)
				if (otherMsg.msg === msg && otherMsg.msgClass === otherMsg.msgClass
					&& otherMsg.msgCode === otherMsg.msgCode) foundDuplicate = true;
			if (foundDuplicate) {
				this.startTimer();
				return;
			} //if
		}

		if (msgClass === 'error') msgClass = 'danger';
		if (msgCode === '') msgCode = 'msg_' + TextTools.randomString(20);

		let timeMsg = 'Just now';

		this.messages.push({ msg, msgClass, msgCode, msgTime: new Date(), autoCloseSeconds, timeMsg });

		if (this.debug) console.log('addMsg 2 ' + this.messages.length);

		this.pushUpdate();
	}

	// --------------------------------------------------------------------
	checkForExpiredMessage() {
		if (this.messages) {
			if (this.debug) console.log('checkForExpiredMessage ' + this.messages.length);

			if (this.messages.length === 0) this.stopTimer();

			const now = new Date();

			// console.log('checking for expired messages # numMsgs = ' + this.messages.length);
			let changes = false;
			for (let i = this.messages.length - 1; i >= 0; i--) {
				if (this.messages[i].autoCloseSeconds !== 0) {
					const closeTime = new Date();
					closeTime.setTime(this.messages[i].msgTime.getTime() + (this.messages[i].autoCloseSeconds * 1000));
					// console.log(i + ' acs=' + this.messages[i].autoCloseSeconds);
					// console.log(i + ' n=' + now.getTime());
					// console.log(i + ' closeTime=' + closeTime);
					// console.log(i + ' c=' + closeTime.getTime());
					// console.log(i + ' d=' + (closeTime.getTime() - now.getTime()));
					if (closeTime.getTime() <= now.getTime()) {
						this.messages.splice(i, 1);
						changes = true;
					} else {
						this.messages[i].timeMsg = 'Closing in ' + MiscTools.formattedTime(Math.floor((closeTime.getTime() - now.getTime()) / 1000), true);
					} // if
				} else {
					// figure out when it was added...
					const numMinutes = Math.floor((now.getTime() - this.messages[i].msgTime.getTime()) / 1000 / 60);
					if (numMinutes === 1)
						this.messages[i].timeMsg = '1 minute ago';
					else if (numMinutes > 0)
						this.messages[i].timeMsg = numMinutes + ' minutes ago';
					else
						this.messages[i].timeMsg = 'In the last minute';
				} // if
			} // for
			if (changes) this.pushUpdate();
		}
	}

	// --------------------------------------------------------------------
	clearAll(ignoreCode: string = '') {
		if (this.debug) console.log('clearAll ' + this.messages.length);

		for (let i = this.messages.length - 1; i >= 0; i--)
			if (!ignoreCode || ignoreCode === '' || this.messages[i].msgCode !== ignoreCode)
				this.messages.splice(i, 1);
		// this.messages = [];
		this.pushUpdate();
	}

	// --------------------------------------------------------------------
	clearMsg(idx: number) {
		if (this.debug) console.log('clearMsg ' + idx);

		this.messages.splice(idx, 1);
		this.pushUpdate();
	}

	// --------------------------------------------------------------------
	clearMsgByCode(msgCode: string) {
		if (this.debug) console.log('clearMsgByCode ' + msgCode);

		// console.log('cleaning msg ' + msgCode);
		// find any messages with this code and zap 'em
		// used mostly for when someone logs in so
		// all login related messages can get zapped
		let changes = false;
		for (let i = this.messages.length - 1; i >= 0; i--) {
			if (this.messages[i].msgCode === msgCode) {
				this.messages.splice(i, 1);
				changes = true;
			} // if
		} // if
		if (changes) this.pushUpdate();
	}


}
