import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { MyQuestionsRoutingModule } from './my-questions-routing.module';
import { MyQuestionsMainComponent } from './my-questions-main/my-questions-main.component';

@NgModule({
	declarations: [
		MyQuestionsMainComponent
	],
	imports: [
		MyQuestionsRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		SharedModule,
		FontAwesome,
		DragDropModule
	]
})
export class MyQuestionsModule { }
