import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';

import { AuthService } from 'client/app/services/auth.service';
import { MyFileSharingService } from '../my-file-sharing.service';
import { BundlesService } from '../../bundles/bundles.service'
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { MySettingsService } from '../../my-settings/my-settings.service';

@Component({
	selector: 'app-file-sharing-bundle',
	templateUrl: './file-sharing-bundle.component.html',
	styleUrls: ['./file-sharing-bundle.component.scss']
})
export class FileSharingBundleComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	textTools = TextTools;
	miscTools = MiscTools;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;
	loading = true;

	// 'standard' view stuff
	id: number;
	bundle: Models.Bundle;

	isStaff = false;

	canEdit = false;
	canAddFiles = false;
	canDownload = false;
	canNotify = false;

	downloadLink: Models.DownloadLink;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private myFileSharingService: MyFileSharingService,
		private bundlesService: BundlesService,
		private mySettingsService: MySettingsService
	) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
		});
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;
			if (authUser) {
				this.isStaff = ValidationTools.checkRole(authUser.role, AppConstants.staffUserRole);
				this.loadData();
			}
		});

	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData(forceRefresh = false) {
		this.loading = true;

		const user = await this.mySettingsService.getUser();

		this.bundle = await this.myFileSharingService.getOne(this.id, forceRefresh);
		// this bundle may not be to/from a staff member, but they can still view it
		if (this.isStaff && (!this.bundle || this.bundle == null || this.bundle.id === 0))
			this.bundle = await this.bundlesService.getOne(this.id);

		if (!this.bundle || this.bundle == null || this.bundle.id === 0) {
			this.router.navigate(['/' + AppConstants.urls.myfilesharing]);
			return;
		}

		this.bundle.files.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

		const isLocked = MiscTools.daysSince(this.bundle.lock_at, false) > 0;

		let isSender = false;
		if (this.bundle.btype.endsWith('-send'))
			isSender = this.isStaff && ((this.bundle.btype === 'staff-send' && this.bundle.staff_id === user.id)
				|| (this.bundle.btype === 'group-send' && user.group_ids.includes(this.bundle.staff_id)));
		else
			isSender = (this.bundle.org_id && this.bundle.org_id !== 0 && this.bundle.org_id === user.org_id)
				|| (this.bundle.user_ids.length !== 0 && this.bundle.user_ids.includes(user.id));

		// only the creator of the bundle can edit it and only in the first week
		this.canEdit = !isLocked && !this.bundle.deleted_at && (this.bundle.added_by === this.authUser.id)

		// files can be added by the sender(s) during the first week (!locked)
		this.canAddFiles = !isLocked && !this.bundle.deleted_at && isSender;

		// files can be downloaded by anyone that can access the bundle before it's deleted
		this.canDownload = !this.bundle.deleted_at;

		// who can send the recipent(s) a message - TBD
		if (this.bundle.files.length > 0 && !this.bundle.deleted_at && isSender) {
			if (!this.bundle.notified_at) {
				this.canNotify = true;
			} else {
				let anyFileChanges = false;
				const notified = new Date(this.bundle.notified_at);
				for (const file of this.bundle.files) {
					const added = new Date(file.added_on);
					if (added.getTime() > notified.getTime()) this.canNotify = true;
					if (file.edited_on) {
						const edited = new Date(file.edited_on);
						if (edited.getTime() > notified.getTime()) this.canNotify = true;
					}
				} // for
			} // if
		} // if

		this.loading = false;
	}


	async sendNotification(includeMe: string = '') {
		try {
			if (!confirm('Are you sure you want to send a e-mail notification to the recipients?'))
				return;
			this.loading = true;
			this.canNotify = false;
			await this.myFileSharingService.sendNotification(this.id, includeMe);
			this.bundle.notified_at = new Date();
			let msg = 'A notification has been sent to the recipient(s) of these files.';
			if (includeMe === 'cc')
				msg += ' Your e-mail address was included in the CC of the message.'
			else if (includeMe === 'bcc')
				msg += ' Your e-mail address was included in the BCC of the message.'

			this.uiAlertsService.addMsg(msg, 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

			this.loading = false;
		} catch (error) {
			this.uiAlertsService.addMsg(error.message,
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			// this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	async removeFile(fileID: number) {
		if (confirm('Are you sure you want to remove this file?')) {
			this.loading = true;
			this.bundle = await this.myFileSharingService.removeFile(this.id, fileID);
			this.bundle.files.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
			this.loading = false;
		}
	}

	async openDownload(fileID: number) {
		try {
			const dlObj = await this.myFileSharingService.getDownloadLink(this.id, fileID);
			if (dlObj) window.open(dlObj.url, '_blank');
		} catch (error) {
			this.uiAlertsService.addMsg(error.message,
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			// this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	async grabDownloadInfo(fileID: number) {
		// clear old stuff, get new download links...
		this.downloadLink = null;
		try {
			const dlObj = await this.myFileSharingService.getDownloadLink(this.id, fileID);
			if (dlObj) this.downloadLink = dlObj;
		} catch (error) {
			this.uiAlertsService.addMsg(error.message,
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			// this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}
	// --------------------------------------------------------------------
	copyToClipboardAlert(item: string = '') {
		this.uiAlertsService.copyToClipboardAlert(item);
	}
}
