import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { DocumentsRoutingModule } from './documents-routing.module';
import { DocumentComponent } from './document/document.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { DocumentFormComponent } from './document-form/document-form.component';
import { DocumentNotificationFormComponent } from './document-notification-form/document-notification-form.component';

@NgModule({
	declarations: [DocumentComponent, DocumentListComponent, DocumentFormComponent, DocumentNotificationFormComponent],
	imports: [
		DocumentsRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		NgSelectModule,
		NgbModule,
		SharedModule,
		FontAwesome
	]
})
export class DocumentsModule { }
