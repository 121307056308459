import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class LicensingAdminService {
	private products: Models.LicenseProduct[];
	private properties: Models.LicenseProperty[];
	private productProperties: Models.LicenseProductProperty[];
	private templates: Models.KeyTemplate[];
	private protocolSets: Models.ProtocolSet[];
	private usersTemplateIDs: number[];

	constructor(
		private http: HttpClient
	) { }

	async refreshObjects() {
		try {
			const result1 = await this.http.get<Models.LicenseProduct[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/license-products').toPromise();
			this.products = result1;

			const result2 = await this.http.get<Models.LicenseProperty[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/license-properties').toPromise();
			this.properties = result2;

			const result3 = await this.http.get<Models.LicenseProductProperty[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/license-product-properties').toPromise();
			this.productProperties = result3;

			const result4 = await this.http.get<Models.KeyTemplate[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/key-templates').toPromise();
			this.templates = result4;

			const result5 = await this.http.get<number[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/user-key-templates-ids').toPromise();
			this.usersTemplateIDs = result5;

			const result6 = await this.http.get<Models.ProtocolSet[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/protocol-sets').toPromise();
			this.protocolSets = result6;

			for (const productProperty of this.productProperties) {
				const idx1 = MiscTools.findIndex(this.products, productProperty.product_id);
				if (idx1 !== -1) {
					if (!this.products[idx1].num_properties) this.products[idx1].num_properties = 0;
					this.products[idx1].num_properties++;
				}
				const idx2 = MiscTools.findIndex(this.properties, productProperty.property_id);
				if (idx2 !== -1) {
					if (!this.properties[idx2].num_products) this.properties[idx2].num_products = 0;
					this.properties[idx2].num_products++;
				}
			}
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getProducts(forceRefresh = false) {
		try {
			if (forceRefresh || !this.products || !this.properties || !this.productProperties || !this.templates || !this.usersTemplateIDs || !this.protocolSets)
				await this.refreshObjects();

			return this.products.slice();
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getProduct(id: number, forceRefresh = false) {
		try {
			const productsCopy = await this.getProducts(forceRefresh);
			const idx = MiscTools.findIndex(productsCopy, id);
			if (idx !== -1) return productsCopy[idx];
			return null;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getProductByName(name: string, forceRefresh = false) {
		try {
			const productsCopy = await this.getProducts(forceRefresh);
			const idx = MiscTools.findIndexGeneric(productsCopy, 'name', name);
			if (idx !== -1) return productsCopy[idx];
			return null;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getProperties(forceRefresh = false) {
		try {
			if (forceRefresh || !this.products || !this.properties || !this.productProperties || !this.templates || !this.usersTemplateIDs || !this.protocolSets)
				await this.refreshObjects();

			return [...this.properties];
			// return this.properties.slice();
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getProperty(id: number, forceRefresh = false) {
		try {
			const propertiesCopy = await this.getProperties(forceRefresh);
			const idx = MiscTools.findIndex(propertiesCopy, id);
			if (idx !== -1) return propertiesCopy[idx];
			return null;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getProductProperties(forceRefresh = false, productID = 0) {
		try {
			if (forceRefresh || !this.products || !this.properties || !this.productProperties || !this.templates || !this.usersTemplateIDs || !this.protocolSets)
				await this.refreshObjects();

			if (productID && productID !== 0) {
				const sublist: Models.LicenseProductProperty[] = [];
				for (const pp of this.productProperties)
					if (pp.product_id === productID)
						sublist.push(pp);

				return sublist;
			} else {
				return this.productProperties.slice();
			}

		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getTemplates(forceRefresh = false) {
		try {
			if (forceRefresh || !this.products || !this.properties || !this.productProperties || !this.templates || !this.usersTemplateIDs || !this.protocolSets)
				await this.refreshObjects();

			return this.templates.slice();
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getTemplate(id: number, forceRefresh = false) {
		try {
			const templatesCopy = await this.getTemplates(forceRefresh);
			const idx = MiscTools.findIndex(templatesCopy, id);
			if (idx !== -1) return templatesCopy[idx];
			return null;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getUserTemplateIDs(forceRefresh = false) {
		try {
			if (forceRefresh || !this.products || !this.properties || !this.productProperties || !this.templates || !this.usersTemplateIDs || !this.protocolSets)
				await this.refreshObjects();

			return this.usersTemplateIDs.slice();
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getProtocolSets(forceRefresh = false) {
		try {
			if (forceRefresh || !this.products || !this.properties || !this.productProperties || !this.templates || !this.usersTemplateIDs || !this.protocolSets)
				await this.refreshObjects();

			return this.protocolSets.slice();
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getProtocolSet(id: number, forceRefresh = false) {
		try {
			const psetCopy = await this.getProtocolSets(forceRefresh);
			const idx = MiscTools.findIndex(psetCopy, id);
			if (idx !== -1) return psetCopy[idx];
			return null;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to add a product
	async addProduct(product: Models.LicenseProduct) {
		try {
			const result = await this.http.post<Models.LicenseProduct>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/license-products/', product).toPromise();
			const returnedProduct: Models.LicenseProduct = result;
			if (!this.products) await this.refreshObjects();
			this.products.push(returnedProduct);
			return returnedProduct;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update a product
	async updateProduct(product: Models.LicenseProduct) {
		try {
			const result = await this.http.put<Models.LicenseProduct>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/license-products/' + product.id, product).toPromise();
			const returnedProduct: Models.LicenseProduct = result;
			if (!this.products) await this.refreshObjects();
			const idx = MiscTools.findIndex(this.products, returnedProduct.id);
			if (idx !== -1) this.products[idx] = returnedProduct;
			return returnedProduct;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to clone a product
	async cloneProduct(product: Models.LicenseProduct) {
		try {
			const result = await this.http.put<Models.LicenseProduct>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/license-products/' + product.id + '/clone', {}).toPromise();
			const returnedProduct: Models.LicenseProduct = result;
			await this.refreshObjects();
			return returnedProduct;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async deleteProduct(id: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/license-products/' + id).toPromise();
			await this.refreshObjects();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to add a property
	async addProperty(product: Models.LicenseProperty) {
		try {
			const result = await this.http.post<Models.LicenseProperty>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/license-properties/', product).toPromise();
			const returnedProperty: Models.LicenseProperty = result;
			if (!this.properties) await this.refreshObjects();
			this.properties.push(returnedProperty);
			return returnedProperty;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update a property
	async updateProperty(product: Models.LicenseProperty) {
		try {
			const result = await this.http.put<Models.LicenseProperty>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/license-properties/' + product.id, product).toPromise();
			const returnedProperty: Models.LicenseProperty = result;
			if (!this.properties) await this.refreshObjects();
			const idx = MiscTools.findIndex(this.properties, returnedProperty.id);
			if (idx !== -1) this.properties[idx] = returnedProperty;
			return returnedProperty;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async deleteProperty(id: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/license-properties/' + id).toPromise();
			await this.refreshObjects();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update a property
	async updateProductProperty(productProperty: Models.LicenseProductProperty) {
		try {
			const result = await this.http.put<Models.LicenseProductProperty>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/license-product-properties/' + productProperty.product_id + '/' + productProperty.property_id, productProperty).toPromise();
			const returnedPP: Models.LicenseProductProperty = result;
			await this.refreshObjects();
			return returnedPP;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async deleteProductProperty(productID: number, propertyID: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/license-product-properties/' + productID + '/' + propertyID).toPromise();
			await this.refreshObjects();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async setSortOrderForPropertyType(ptype: string, ids: number[], doRefresh = true) {
		try {
			const result = await this.http.put(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/license-properties/sort-order/' + ptype, { ids }).toPromise();
			if (doRefresh) await this.refreshObjects();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to add a product
	async addTemplate(template: Models.KeyTemplate) {
		try {
			const result = await this.http.post<Models.KeyTemplate>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/key-templates/', template).toPromise();
			const returnedTemplate: Models.KeyTemplate = result;
			if (!this.templates) await this.refreshObjects();
			this.templates.push(returnedTemplate);
			return returnedTemplate;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update a product
	async updateTemplate(template: Models.KeyTemplate) {
		try {
			const result = await this.http.put<Models.KeyTemplate>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/key-templates/' + template.id, template).toPromise();
			const returnedTemplate: Models.KeyTemplate = result;
			if (!this.templates) await this.refreshObjects();
			const idx = MiscTools.findIndex(this.templates, returnedTemplate.id);
			if (idx !== -1) this.templates[idx] = returnedTemplate;
			return returnedTemplate;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async deleteTemplate(id: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/key-templates/' + id).toPromise();
			await this.refreshObjects();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getAllUserIdsForTemplate(id: number) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensingadmin + '/key-templates/' + id + '/user-ids';
			const result = await this.http.get<number[]>(url).toPromise();
			const arr: number[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to add a product
	async addProtocolSet(protocolSet: Models.ProtocolSet) {
		try {
			const result = await this.http.post<Models.ProtocolSet>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/protocol-sets/', protocolSet).toPromise();
			const returnedProtocolSet: Models.ProtocolSet = result;
			if (!this.protocolSets) await this.refreshObjects();
			this.protocolSets.push(returnedProtocolSet);
			return returnedProtocolSet;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update a product
	async updateProtocolSet(protocolSet: Models.ProtocolSet) {
		try {
			const result = await this.http.put<Models.ProtocolSet>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/protocol-sets/' + protocolSet.id, protocolSet).toPromise();
			const returnedProtocolSet: Models.ProtocolSet = result;

			if (!this.protocolSets) await this.refreshObjects();
			const idx = MiscTools.findIndex(this.protocolSets, returnedProtocolSet.id);

			if (idx !== -1) this.protocolSets[idx] = returnedProtocolSet;
			return returnedProtocolSet;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async deleteProtocolSet(id: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensingadmin + '/protocol-sets/' + id).toPromise();
			await this.refreshObjects();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}
}
