<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="['fas', 'calculator']" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Traffic Calculator
			</li>
		</ol>
	</nav>
</div>

<div *ngIf="loading" style="text-align: center;">
	<app-loading-spinner></app-loading-spinner>
</div>

<form *ngIf="theForm && !loading" [formGroup]="theForm" class="ms-3">
	<div class="row">
		<div class="cp-filter-block-2 text-center">
			<label for="streams"># Streams</label>
			<input type="text" id="streams" formControlName="streams" class="form-control text-center"
				placeholder="# streams" (keyup)="onSettingChange()" />
		</div>

		<div class="cp-filter-block-2 text-center">
			<label for="bitrate">Bitrate - Mbits/s</label>
			<input type="text" id="bitrate" formControlName="bitrate" class="form-control text-center"
				placeholder="Mbits/s" (keyup)="onSettingChange()" />
		</div>

		<div class="cp-filter-block-2 text-center">
			<label for="price">Cents per GB</label>
			<input type="text" id="price" formControlName="price" class="form-control text-center"
				placeholder="cents/GB" (keyup)="onSettingChange()" />
		</div>
	</div>

	<div *ngIf="dataPerDay && dataPerDay !== 0" class="row">
		<div class="col-lg-6">

			<div class="text-center">
				Total Bitrate: {{ (streams * bitrate) | number }} Mbps
			</div>

			<table class="cp-std-table cp-auto-width mx-auto w-auto">
				<thead>
					<tr>
						<th>Time Period</th>
						<th class="text-center">Data (MB)</th>
						<th class="text-center">Data</th>
						<th *ngIf="price && price !== 0" class="text-center">$</th>
					</tr>
				</thead>

				<tbody>
					<tr *ngFor="let timeframe of timeframes">
						<td class="text-end border-end text-nowrap">Per {{ timeframe.label }}</td>
						<td class="text-end border-end text-nowrap">
							{{ getMB(timeframe) | number }} MB
							<fa-icon [icon]="['fas', 'copy']" ngxClipboard
								(click)="copyToClipboardAlert(getMB(timeframe) + '')" [cbContent]="getMB(timeframe)"
								ngbTooltip="Copy to Clipboard"></fa-icon>
						</td>
						<td class="text-end border-end text-nowrap">
							{{ textTools.formattedMB(getMB(timeframe)) }}
						</td>

						<td class="text-end border-end text-nowrap"
							*ngIf="price && price !== 0 && makeCost(timeframe) > 0">
							<span class="float-start me-1">$</span>
							{{ makeCost(timeframe) | number }}
						</td>
						<td *ngIf="price && price !== 0 && makeCost(timeframe) === 0"></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>

</form>