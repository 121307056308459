import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { LicensingAdminRoutingModule } from './licensing-admin-routing.module';
import { LicensingProductComponent } from './licensing-product/licensing-product.component';
import { LicensingPropertyComponent } from './licensing-property/licensing-property.component';
import { LicensingProductFormComponent } from './licensing-product-form/licensing-product-form.component';
import { LicensingPropertyFormComponent } from './licensing-property-form/licensing-property-form.component';
import { LicensingProductPropertyFormComponent } from './licensing-product-property-form/licensing-product-property-form.component';
import { LicensingPropertySortComponent } from './licensing-property-sort/licensing-property-sort.component';
import { KeyTemplateComponent } from './key-template/key-template.component';
import { KeyTemplateFormComponent } from './key-template-form/key-template-form.component';
import { LicensingProductListComponent } from './licensing-product-list/licensing-product-list.component';
import { LicensingPropertyListComponent } from './licensing-property-list/licensing-property-list.component';
import { KeyTemplateListComponent } from './key-template-list/key-template-list.component';
import { ProtocolSetListComponent } from './protocol-set-list/protocol-set-list.component';
import { ProtocolSetComponent } from './protocol-set/protocol-set.component';
import { ProtocolSetFormComponent } from './protocol-set-form/protocol-set-form.component';

@NgModule({
	declarations: [
		LicensingProductComponent,
		LicensingPropertyComponent,
		LicensingProductFormComponent,
		LicensingPropertyFormComponent,
		LicensingProductPropertyFormComponent,
		LicensingPropertySortComponent,
		KeyTemplateComponent,
		KeyTemplateFormComponent,
		LicensingProductListComponent,
		KeyTemplateListComponent,
		LicensingPropertyListComponent,
		ProtocolSetListComponent,
		ProtocolSetComponent,
		ProtocolSetFormComponent],
	imports: [
		LicensingAdminRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		SharedModule,
		FontAwesome,
		ClipboardModule,
		DragDropModule
	]
})
export class LicensingAdminModule { }
