<div class="position-fixed bottom-0 p-3 start-50 translate-middle-x" style="z-index: 40">
	<ngb-toast *ngFor="let msg of messages; let i = index" [id]="msg.msgCode" [autohide]="false"
		(hidden)="closeMessage(msg.msgCode)" class="p-0 mb-1">
		<div class="overflow-auto m-1 p-2 cp-pre-text" [ngClass]="getBorderVisClass(msg)">
			<div class="float-end text-end">
				<button type="button" class="btn-close" (click)="closeMessage(msg.msgCode)">
				</button>
			</div>
			<span [innerHTML]="msg.msg"></span>

			<div *ngIf="msg.timeMsg !== ''" class="cp-75 text-center border-top pt-1 mt-1">{{ msg.timeMsg }}</div>
		</div>
	</ngb-toast>
	<ngb-toast *ngIf="messages.length > 2" [autohide]="false" class="p-0 mb-1">
		<div class="m-1 p-2 text-center">
			<span class="cp-pointer" (click)="closeAll()">
				Close All
			</span>
		</div>
	</ngb-toast>
</div>