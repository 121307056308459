import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class ProductsService {
	products: Observable<Models.Product[]>;
	productsPlatforms: Observable<Models.ProductPlatform[]>;

	loaded = false;
	private debug = false;
	private _products: ReplaySubject<Models.Product[]>;
	private _productPlatforms: ReplaySubject<Models.ProductPlatform[]>;
	private dataStore: {
		products: Models.Product[];
		productsPlatforms: Models.ProductPlatform[];
	};

	constructor(private http: HttpClient) {
		this.dataStore = {
			products: [],
			productsPlatforms: []
		};
		this._products = new ReplaySubject(1) as ReplaySubject<Models.Product[]>;
		this._productPlatforms = new ReplaySubject(1) as ReplaySubject<Models.ProductPlatform[]>;
		this.products = this._products.asObservable();
		this.productsPlatforms = this._productPlatforms.asObservable();
	}

	// add/update an item in the datastore
	private updateStore(newProduct: Models.Product): void {
		const idx = this.dataStore.products.findIndex(product => product.id === newProduct.id);
		if (idx === -1) {
			this.dataStore.products.push(newProduct);
			return;
		} else {
			this.dataStore.products[idx] = newProduct;
		}
	}

	// get a fresh copy of the entire set
	refreshAll(): Observable<Models.Product[]> {
		const products$ = this.http.get<Models.Product[]>(AppConstants.apiUrl + AppConstants.apiUrls.products).pipe(share());
		products$.subscribe(
			data => {
				const products: Models.Product[] = data;
				// remove ones from the store that aren't in the response (they've been deleted)
				this.dataStore.products.forEach((existingProduct, existingIndex) => {
					const newIndex = products.findIndex(product => product.id === existingProduct.id);
					if (newIndex === -1) this.dataStore.products.splice(existingIndex, 1);
				});
				// add/update all the ones that came back
				products.forEach(product => this.updateStore(product));
				this._products.next(Object.assign({}, this.dataStore).products);
				this.loaded = true;
			},
			error => { /* console.log(error) */ }
		);

		// also refresh product platforms...
		const pp$ = this.http.get<Models.ProductPlatform[]>(AppConstants.apiUrl + AppConstants.apiUrls.products + '/platforms').pipe(share());
		pp$.subscribe(
			data => {
				this.dataStore.productsPlatforms = data;
				this._productPlatforms.next(Object.assign({}, this.dataStore).productsPlatforms);
			},
			error => { /* console.log(error) */ }
		);

		return products$;
	}

	// get a fresh copy of a single item
	refreshOne(id: number): Observable<Models.Product> {
		const product$ = this.http.get<Models.Product>(AppConstants.apiUrl + AppConstants.apiUrls.products + '/' + id).pipe(share());
		product$.subscribe(
			data => {
				const product: Models.Product = data;
				// add/update the one that came back
				this.updateStore(product);
				this._products.next(Object.assign({}, this.dataStore).products);
			},
			error => { /* console.log(error) */ }
		);
		return product$;
	}

	// return the whole list
	getAll(): Models.Product[] {
		return this.dataStore.products.slice();
	}

	// grab a single item from the datastore
	getOne(id: number): Models.Product {
		return this.dataStore.products.find(product => product.id === id);
	}

	getAllProductPlatforms(): Models.ProductPlatform[] {
		return this.dataStore.productsPlatforms.slice();
	}

	// call back-end to add an item
	async addOne(product: Models.Product) {
		try {
			const result = await this.http.post<Models.Product>(AppConstants.apiUrl + AppConstants.apiUrls.products, product).toPromise();
			const returnedProduct: Models.Product = result;
			this.updateStore(returnedProduct);
			this._products.next(Object.assign({}, this.dataStore).products);
			return returnedProduct;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update an item
	async updateOne(product: Models.Product) {
		try {
			const result = await this.http.put<Models.Product>(AppConstants.apiUrl + AppConstants.apiUrls.products + '/' + product.id, product).toPromise();
			const returnedProduct: Models.Product = result;
			this.updateStore(returnedProduct);
			this._products.next(Object.assign({}, this.dataStore).products);
			return returnedProduct;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to delete an item
	async deleteOne(id: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl
				+ AppConstants.apiUrls.products + '/' + id).toPromise();
			const idx = this.dataStore.products.findIndex(product => product.id === id);
			if (idx !== -1) this.dataStore.products.splice(idx, 1);
			this._products.next(Object.assign({}, this.dataStore).products);
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// get IDs of users that can access this doc
	async fetchUserIDs(id: number) {
		try {
			const result = await this.http.get<number[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.products + '/' + id + '/users').toPromise();
			const arr: number[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update an item
	async sendNotification(id: number, subject: string, message: string) {
		try {
			const result = await this.http.put(AppConstants.apiUrl
				+ AppConstants.apiUrls.products + '/' + id + '/notify', { subject, message, async: 'yes' }).toPromise();
			return;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async setSortOrder(ids: number[], doRefresh = true) {
		try {
			const result = await this.http.put(AppConstants.apiUrl
				+ AppConstants.apiUrls.products + '/sort-order', { ids }).toPromise();
			if (doRefresh) this.refreshAll();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

}
