
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';

import { MySettingsService } from '../my-settings.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { AuthService } from 'client/app/services/auth.service';
// import { PasswordStrengthValidator } from 'client/app/validators/password-strength.validator';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-change-password-form',
	templateUrl: './change-password-form.component.html',
	styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent implements OnInit {
	ac = AppConstants;

	// private userSubscription: Subscription;
	user: Models.User;
	loading = true;
	saving = false;
	errors: string[] = [];
	theForm: UntypedFormGroup;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private mySettingsService: MySettingsService) { }

	ngOnInit(): void {
		this.initForm();
	}

	async initForm() {
		this.user = await this.mySettingsService.getUser();
		if (this.user.use_sso === 1)
			this.onCancel();

		this.loading = false;

		this.theForm = new UntypedFormGroup({
			currentpassword: new UntypedFormControl('', [Validators.required]),
			password: new UntypedFormControl('', [Validators.required]),
			password2: new UntypedFormControl('', [Validators.required])
		});
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const password = this.theForm.value.password.trim();
		const password2 = this.theForm.value.password2.trim();

		const passwordCheckmsg = TextTools.checkPasswordStrength(password);
		if (password !== password2)
			this.errors.push('Passwords do not match.');
		else if (passwordCheckmsg && passwordCheckmsg !== '')
			this.errors.push(passwordCheckmsg);

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		try {
			const retUser = await this.mySettingsService.setPassword(this.theForm.value.currentpassword, this.theForm.value.password, this.theForm.value.password2);
			if (retUser) {
				this.router.navigate(['..'], { relativeTo: this.route });
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	get currentpassword() { return this.theForm.get('currentpassword'); }
	get password() { return this.theForm.get('password'); }
	get password2() { return this.theForm.get('password2'); }
}
