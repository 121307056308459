<div class="mt-2">
	<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>
	<div *ngIf="items.length > 0">
		<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
			<thead>
				<tr>
					<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-center cp-pre-text cp-pointer"
						[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
						[hidden]="!fieldsToShow.includes(cd.field)">
						<span *ngIf="cd.label" [innerHTML]="cd.label"
							[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
							[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
						<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
					</th>
				</tr>
			</thead>
			<tbody *ngIf="!loading && itemsToShow?.length > 0">
				<tr *ngFor="let item of itemsToShow"
					[ngClass]="{'text-danger': item.enabled === 0 && !sharedLicenseTools.isSpecialKey(item)}">

					<td *ngIf="staffMode && showStaffDelete" class="text-nowrap">
						<fa-icon [icon]="['fas', 'trash-alt']" class="text-danger" size="sm" placement="end"
							ngbTooltip="Remove Key from User Account" (click)="deleteUserKey(item['__user_id'], item.id)">
						</fa-icon>
					</td>

					<td *ngIf="showEnabled" class="cp-icon-cell">
						<fa-icon *ngIf="item.enabled === 1" [icon]="['fas', 'power-off']" size="sm" class="text-success"
							ngbTooltip="Enabled" placement="end">
						</fa-icon>
						<fa-icon *ngIf="item.enabled === 0 && !sharedLicenseTools.isSpecialKey(item)" [icon]="['fas', 'ban']" size="sm"
							class="text-danger" ngbTooltip="Disabled" placement="end"></fa-icon>
					</td>

					<td *ngIf="showUserLabel">
						<span class="cp-85" [ngbTooltip]="item['__user_label']">
							{{ textTools.chopString(item['__user_label'], 30) }}
						</span>
					</td>

					<td class="text-nowrap">
						{{ item.created_at | date: ac.tableDateFmt }}
					</td>

					<td class="text-nowrap"
						[ngClass]="{'text-danger': item['__expires_at'] && miscTools.hasExpired(item['__expires_at']) }">
						{{ item['__expires_at_display'] }}
					</td>

					<td *ngIf="staffMode && showOrganization">
						<span class="cp-75" [ngbTooltip]="item.org_name">
							{{ textTools.chopString(item.org_name, 30) }}
						</span>
					</td>

					<td *ngIf="staffMode && showInfo">
						<span class="cp-75" [ngbTooltip]="item.info">
							{{ textTools.chopString(item.info, 30) }}
						</span>
						<fa-icon *ngIf="item.opportunity_id && item.opportunity_id.length > 3" [icon]="['fab', 'salesforce']"
							ngbTooltip="Has Salesforce Opportunity ID"></fa-icon>
					</td>

					<td class="text-center" [ngClass]="!showFullProduct?'cp-icon-cell':''">
						<span *ngIf="!showFullProduct" [ngbTooltip]="item['__niceProduct']">
							{{ sharedLicenseTools.licenseProductAcronym(item['__niceProduct']) }}
						</span>
						<span *ngIf="showFullProduct">
							{{ item['__niceProduct'] }}
						</span>
					</td>

					<td class="text-center" [ngClass]="!showFullType?'cp-icon-cell':''">
						<span *ngIf="!showFullType" [ngbTooltip]="textTools.capitalizeFirstLetter(item.type)">
							{{ textTools.acronym(item.type, true) }}
						</span>
						<span *ngIf="showFullType">
							{{ textTools.capitalizeFirstLetter(item.type) }}
						</span>
					</td>

					<td class="text-nowrap">
						<a *ngIf="addPopovers" [routerLink]="item['__route']" [ngbPopover]="popupContent" popoverClass="cp-popover-std"
							triggers="mouseenter:mouseleave" container="body" class="cp-key cp-85">
							{{ item.key }}</a>
						<a *ngIf="!addPopovers" [routerLink]="item['__route']" class="cp-key cp-85">
							{{ item.key }}</a>
						<fa-icon [icon]="['fas', 'copy']" class="cp-pointer ms-1" ngxClipboard (click)="copyToClipboardAlert(item.key)"
							[cbContent]="item.key" ngbTooltip="Copy to Clipboard"></fa-icon>
					</td>

					<td class="text-center">
						<span *ngIf="!showExpandedActivations" [ngbTooltip]="item.count">
							{{ textTools.formatCount(item.count) }}
						</span>
						<span *ngIf="showExpandedActivations"
							[ngbTooltip]="textTools.formatNumber(item.count) + '/' + textTools.formatNumber(item.max)">
							{{ textTools.formatCount(item.count) }} / {{ textTools.formatCount(item.max) }}
						</span>
					</td>

					<td *ngIf="showActiveCount" class="cp-icon-cell text-center">
						{{ item['__active_hostids'] | number}}
					</td>

					<td *ngIf="staffMode && showNumUsers" class="cp-icon-cell text-center">
						{{ item.num_users | number}}
					</td>

					<td *ngIf="showMeterIcon" class="cp-icon-cell text-center">
						<fa-icon *ngIf="item['__meter_status'] !== ''" [icon]="['fas', 'tachometer-alt']" size="sm" placement="start"
							[ngbTooltip]="item['__meter_status']" [class]="item['__meter_status_class']">
						</fa-icon>
					</td>

					<td *ngIf="showProtocolIcon" class="cp-icon-cell text-center">
						<fa-icon *ngIf="item['__protocol_status'] !== ''" [icon]="['fas', 'chart-line']" size="sm" placement="start"
							[ngbTooltip]="item['__protocol_status']" [class]="item['__protocol_status_class']">
						</fa-icon>
					</td>

					<td *ngIf="staffMode && showLastTouched" class="text-nowrap text-center">
						<span class="cp-75" [ngbTooltip]="item['__last_touched_by']">
							{{ textTools.acronym(item['__last_touched_by']) }}
						</span>
					</td>

					<td *ngIf="staffMode && showSalesEngineer" class="text-nowrap text-center">
						<span class="cp-75" [ngbTooltip]="item['__sales_engineer']">
							{{ textTools.acronym(item['__sales_engineer']) }}
						</span>
					</td>

					<td *ngIf="staffMode && showSalesEngineer" class="text-nowrap text-center">
						<span class="cp-75" [ngbTooltip]="item['__tam']">
							{{ textTools.acronym(item['__tam']) }}
						</span>
					</td>

					<td *ngIf="staffMode && showCommercialType" class="text-nowrap text-center">
						<span class="cp-75" [ngbTooltip]="item['__comm_type']">
							{{ item['__comm_type_acronym'] }}
						</span>
					</td>

					<td *ngIf="showSnoozed" class="cp-icon-cell text-center">
						<fa-icon *ngIf="item['__snoozes'] && item['__snoozes'] !== ''" [icon]="['fas', 'snooze']" size="sm"
							placement="start" [ngbTooltip]="item['__snoozes']">
						</fa-icon>
					</td>

					<td [ngClass]="expandWarnings ?'':'cp-icon-cell'">
						<span *ngIf="expandWarnings" class="cp-75">
							{{ item['__warnings'].join('\n') }}
						</span>
						<fa-icon *ngIf="!expandWarnings && item['__warnings'] && item['__warnings'].length !== 0"
							[icon]="['fas', 'exclamation-triangle']" size="sm" class="text-warning" [ngbPopover]="warningsPopup"
							popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
						</fa-icon>
						<ng-template #warningsPopup>
							<div class="cp-popover-banner">{{ item.key }} Warnings</div>
							<div *ngIf="item['__warnings'].length > 0" class="cp-pre-text">
								{{ item['__warnings'].join('\n') }}
							</div>
						</ng-template>
					</td>

					<td *ngIf="staffMode && showMeterIrregularities" class="cp-icon-cell small text-center">
						<fa-icon *ngIf="item['__irregularities'] && item['__irregularities'] !== ''" [icon]="['fas', 'info-circle']"
							size="sm" class="text-warning" [ngbPopover]="meterWarningsPopup" popoverClass="cp-popover-std"
							triggers="mouseenter:mouseleave" container="body">
						</fa-icon>
						<ng-template #meterWarningsPopup>
							<div class="cp-popover-banner">{{ item.key }} Meter Irregularities</div>
							<div class="cp-pre-text">
								{{ item['__irregularities'] }}
							</div>
						</ng-template>
					</td>

					<ng-template #popupContent>
						<div class="cp-popover-banner">{{ item.key }}</div>
						<div class="cp-pre-text">{{ item['__popover'] }}</div>
					</ng-template>
				</tr>
			</tbody>
		</table>
	</div>

</div>