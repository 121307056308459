import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject, interval, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import PopOverTools from 'appshared/popover-tools';

import { QuizzesService } from '../quizzes.service';
import { AuthService } from 'client/app/services/auth.service';
import { UsersService } from '../../users/users.service';

@Component({
	selector: 'app-quiz-list',
	templateUrl: './quiz-list.component.html',
	styleUrls: ['./quiz-list.component.scss']
})
export class QuizListComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	textTools = TextTools;
	miscTools = MiscTools;
	popOverTools = PopOverTools;
	now = new Date();

	// 'standard' list stuff
	quizzes: Models.Quiz[];
	quizzesToShow: Models.Quiz[];

	loading = true;

	// other stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	canAdd = false;

	constructor(
		private quizzesService: QuizzesService,
		private usersService: UsersService,
		private authService: AuthService,
	) { }

	// ************************************************************************************
	ngOnInit(): void {
		this.loading = true;

		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.authUser = authUser;
			this.canAdd = authUser && (ValidationTools.checkAccess(authUser, 'manage-quizzes') || ValidationTools.checkAccess(authUser, 'add-quizzes'));
			this.loadData();
		});
	}

	// ************************************************************************************
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ************************************************************************************
	async loadData() {
		this.loading = true;

		this.quizzes = await this.quizzesService.getAll();
		this.quizzesToShow = this.quizzes;

		this.loading = false;
	}


}
