import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share, map, tap } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Injectable({
	providedIn: 'root'
})
export class MyKeysService {

	private userKeys: Models.UserLicenseKey[];
	private deletedKeys: Models.UserLicenseKey[];

	private hostids: Models.UserHostId[];

	constructor(
		private http: HttpClient,
		private uiAlertsService: UiAlertsService) { }

	wipe() {
		this.userKeys = null;
		this.deletedKeys = null;
		this.hostids = null;
	}

	async getUserKeys(getDeleted = false, forceRefresh = false) {
		if (getDeleted) {
			if (!this.deletedKeys || forceRefresh)
				this.deletedKeys = await this.fetchUserKeys(true);
			return this.deletedKeys.slice();
		} else {
			if (!this.userKeys || forceRefresh)
				this.userKeys = await this.fetchUserKeys(false);
			return this.userKeys.slice();
		}
	}

	async fetchUserKeys(getDeleted = false) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/';
			if (getDeleted) url += '?deleted=yes';

			const result = await this.http.get<Models.UserLicenseKey[]>(url).toPromise();
			const arr: Models.UserLicenseKey[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchKey(id: number) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/' + id;
			const result = await this.http.get<Models.UserLicenseKey>(url).toPromise();
			const obj: Models.UserLicenseKey = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async resolveKey(key: string) {
		try {
			// load user's registered keys
			if (!this.userKeys)
				this.userKeys = await this.fetchUserKeys(false);

			// see if this key is already registered, if so return it
			for (const userKey of this.userKeys) {
				if (userKey.activation_key === key) {
					// need to fetch new copy of key with activations, etc.
					const freshCopy = await this.fetchKey(userKey.id);
					return freshCopy;
				}
			}

			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/' + encodeURIComponent(key) + '/resolve';
			const result = await this.http.get<Models.UserLicenseKey>(url).toPromise();
			const obj: Models.UserLicenseKey = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
			return null;
		}
	}

	async registerKey(key: string, label: string = '', refreshKeys = true) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/register-key';
			const result = await this.http.post<Models.UserLicenseKey>(url, { key, label }).toPromise();
			const obj: Models.UserLicenseKey = result;
			if (refreshKeys) await this.getUserKeys(false, true);
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async updateKey(userKey: Models.UserLicenseKey, refreshKeys = true) {
		try {
			const keyToSend = Object.assign({}, userKey);
			keyToSend.activation.meters = [];
			keyToSend.activation.licenses = [];
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/' + userKey.id;
			const result = await this.http.put<Models.UserLicenseKey>(url, keyToSend).toPromise();
			const obj: Models.UserLicenseKey = result;
			if (refreshKeys) {
				await this.getUserKeys(false, true);
				await this.getUserKeys(true, true);
			}
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async purgeKey(userKey: Models.UserLicenseKey, doRefresh: boolean = true) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/' + userKey.id;
			const result = await this.http.delete(url).toPromise();
			if (doRefresh) {
				await this.getUserKeys(false, true);
				await this.getUserKeys(true, true);
			} // if
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getSharedWithUsers(userKey: Models.UserLicenseKey) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/' + userKey.id + '/shares';
			const result = await this.http.get<number[]>(url).toPromise();
			const ids: number[] = result;
			return ids;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a key's usage records
	async getActivationMonthlyUsage(userKey: Models.UserLicenseKey, metricType: string = 'meter-data', billingCode: string = '') {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys
				+ '/' + encodeURIComponent(userKey.activation_key) + '/monthly-usage';
			url += '?metricType=' + encodeURIComponent(metricType);
			if (billingCode && billingCode !== '') url += '&bcode=' + encodeURIComponent(billingCode);

			const result = await this.http.get<Models.LPMeterReportByMonth[]>(url).toPromise();
			const arr: Models.LPMeterReportByMonth[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// async getActivationDailyUsageForMonth(id: number, product: string, year: number, month: number, billingCode: string = '') {

	// call back-end to get a key's usage records
	async getActivationDailyUsageForMonth(userKey: Models.UserLicenseKey, product: string, year: number, month: number, billingCode: string = '') {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys
				+ '/' + encodeURIComponent(userKey.activation_key) + '/daily-usage'
				+ '?product=' + encodeURIComponent(product) + '&year=' + encodeURIComponent(year) + '&month=' + encodeURIComponent(month);
			if (billingCode && billingCode !== '') url += '&bcode=' + encodeURIComponent(billingCode);

			const result = await this.http.get<Models.LPMeterReportByDay[]>(url).toPromise();
			const arr: Models.LPMeterReportByDay[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a host's monthly usage records
	async getHostMonthlyUsage(userKey: Models.UserLicenseKey, hostid: string, metricType: string, billingCode: string = '') {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys
				+ '/' + encodeURIComponent(userKey.activation_key)
				+ '/hostid-monthly-usage/' + encodeURIComponent(hostid);
			url += '?metricType=' + encodeURIComponent(metricType);
			if (billingCode && billingCode !== '') url += '&bcode=' + encodeURIComponent(billingCode);

			const result = await this.http.get<Models.LPMeterReportByMonth[]>(url).toPromise();
			const arr: Models.LPMeterReportByMonth[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a host's daily usage records for a month
	async getHostDailyUsageForMonth(userKey: Models.UserLicenseKey, hostid: string, product: string, year: number, month: number, billingCode: string = '') {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys
				+ '/' + encodeURIComponent(userKey.activation_key)
				+ '/hostid-daily-usage/' + encodeURIComponent(hostid)
				+ '?product=' + encodeURIComponent(product) + '&year=' + encodeURIComponent(year) + '&month=' + encodeURIComponent(month);
			if (billingCode && billingCode !== '') url += '&bcode=' + encodeURIComponent(billingCode);

			const result = await this.http.get<Models.LPMeterReportByDay[]>(url).toPromise();
			const arr: Models.LPMeterReportByDay[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a host's version history
	async getVersionHistory(userKey: Models.UserLicenseKey, hostid: string) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys
				+ '/' + encodeURIComponent(userKey.activation_key)
				+ '/hostid-versions/' + encodeURIComponent(hostid)
			const result = await this.http.get<Models.LPVersionHistory[]>(url).toPromise();
			const arr: Models.LPVersionHistory[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a key's active hosts
	async getActiveHostIDs(userKey: Models.UserLicenseKey) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys
				+ '/' + encodeURIComponent(userKey.activation_key) + '/active-hosts';
			const result = await this.http.get<string[]>(url).toPromise();
			const arr: string[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get each of the user's key's active host count
	async getActiveHostCounts() {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/active-host-counts';
			const result = await this.http.get<any>(url).toPromise();
			const arr: any = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to find specific activation(s) for a key
	async searchHosts(userKey: Models.UserLicenseKey, hostidFilter: string) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys
				+ '/' + encodeURIComponent(userKey.activation_key) + '/find-hosts'
				+ '?hostids=' + encodeURIComponent(hostidFilter);
			const result = await this.http.get<Models.LPLicense[]>(url).toPromise();
			const arr: Models.LPLicense[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getKeysActiveLicenses(userKey: Models.UserLicenseKey, numDay: number) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys
				+ '/' + encodeURIComponent(userKey.activation_key) + '/active-licenses?numDay=' + encodeURIComponent(numDay);
			const result = await this.http.get<Models.LPLicense[]>(url).toPromise();
			const arr: Models.LPLicense[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getSelfServiceInfo(userKey: Models.UserLicenseKey) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys
				+ '/' + encodeURIComponent(userKey.id)
				+ '/self-service';
			const result = await this.http.get<any>(url).toPromise();
			const obj: any = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async generateKeyViaTemplate(packageID: number, templateID: number) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/package-keys';
			const result = await this.http.post<Models.UserLicenseKey>(url, { package_id: packageID, template_id: templateID }).toPromise();
			const userKey: Models.UserLicenseKey = result;
			await this.getUserKeys(false, true);
			return result;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async addActivationsToKey(userKey: Models.UserLicenseKey) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/' + userKey.id + '/increase';
			const result = await this.http.put<string[]>(url, {}).toPromise();
			await this.getUserKeys(false, true);
			return result;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async shareKeysWithUsers(keyIDs: number[], userIDs: number[], notify = false) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/shared-keys';
			const result = await this.http.post<Models.UserLicenseKey>(url, { key_ids: keyIDs, user_ids: userIDs, notify }).toPromise();
			const obj: Models.UserLicenseKey = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get billing codes used by a key
	async getActivationBillingCodes(userKey: Models.UserLicenseKey) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys
				+ '/' + encodeURIComponent(userKey.activation_key)
				+ '/billing-codes';
			const result = await this.http.get<Models.BillingCode[]>(url).toPromise();
			const arr: Models.BillingCode[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get billing codes used by a host id
	async getHostBillingCodes(userKey: Models.UserLicenseKey, hostid: string) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys
				+ '/' + encodeURIComponent(userKey.activation_key)
				+ '/billing-codes/' + encodeURIComponent(hostid);
			const result = await this.http.get<Models.BillingCode[]>(url).toPromise();
			const arr: Models.BillingCode[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get all or a subset of all of the  billing codes
	async getBillingCodes(codes: string[]) {
		if (!codes || codes.length === 0) return [];

		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/billing-codes';
			url += '?billing_codes=' + encodeURIComponent(codes.join(','))

			const result = await this.http.get<Models.BillingCode[]>(url).toPromise();
			const arr: Models.BillingCode[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getUserHostids(forceRefresh = false) {
		if (!this.hostids || forceRefresh)
			this.hostids = await this.fetchUserHostids();
		return this.hostids.slice();
	}

	async fetchUserHostids() {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/hostids';
			const result = await this.http.get<Models.UserHostId[]>(url).toPromise();
			const arr: Models.UserHostId[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// for now, don't pass in (or on) activation ID
	// updates and returns full list of hostids
	async registerHostid(hostid: string, label: string) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/register-hostid';
			const result = await this.http.post<Models.UserHostId[]>(url, { hostid, label }).toPromise();
			const arr: Models.UserHostId[] = result;
			this.hostids = arr;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async emailUserKeysReport() {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/report?email=yes';
			const result = await this.http.get<any>(url).toPromise();
			this.uiAlertsService.addMsg('Your report request has been submitted. This may take several minutes.\n'
				+ 'You\'ll received an e-mail when the report is ready.\n', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getUserSnoozes() {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/snoozes';
			const result = await this.http.get<Models.UserLicenseKeySnooze[]>(url).toPromise();
			const arr: Models.UserLicenseKeySnooze[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async addUserSnooze(userSnooze: Models.UserLicenseKeySnooze) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/snoozes';
			const result = await this.http.post<Models.UserLicenseKeySnooze>(url, userSnooze).toPromise();
			const obj: Models.UserLicenseKeySnooze = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async updateUserSnooze(userSnooze: Models.UserLicenseKeySnooze) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/snoozes/' + userSnooze.id;
			const result = await this.http.put<Models.UserLicenseKeySnooze>(url, userSnooze).toPromise();
			const obj: Models.UserLicenseKeySnooze = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async deleteUserSnooze(userSnooze: Models.UserLicenseKeySnooze) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/snoozes/' + userSnooze.id;
			const result = await this.http.delete(url).toPromise();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get protocol sets linked to a key
	async getActivationProtocolSets(userKey: Models.UserLicenseKey) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/' + encodeURIComponent(userKey.activation_key) + '/protocol-sets';
			const result = await this.http.get<Models.ActivationProtocolSet[]>(url).toPromise();
			const arr: Models.ActivationProtocolSet[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getBillingCodesOrganizations() {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/org-billing-codes';
			const result = await this.http.get<Models.OrganizationBillingCodes[]>(url).toPromise();
			const arr: Models.OrganizationBillingCodes[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}


	// call back-end to add a billing code
	async addBillingCode(billingCode: Models.BillingCode) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/org-billing-codes';

			const result = await this.http.post<Models.BillingCode>(url, billingCode).toPromise();
			const returnedBC: Models.BillingCode = result;
			return returnedBC;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update add a billing code
	async updateBillingCode(billingCode: Models.BillingCode) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/org-billing-codes/' + encodeURIComponent(billingCode.id);
			const result = await this.http.put<Models.BillingCode>(url, billingCode).toPromise();
			const returnedBC: Models.BillingCode = result;
			return returnedBC;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to reset a billing code's auth code
	async resetBillCodeAuthCode(billingCode: Models.BillingCode) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/org-billing-codes/' + encodeURIComponent(billingCode.id) + '/reset';
			const result = await this.http.put<Models.BillingCode>(url, billingCode).toPromise();
			const returnedBC: Models.BillingCode = result;
			return returnedBC;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}


}
