import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class PlatformsService {
	platforms: Observable<Models.Platform[]>;
	loaded = false;
	private debug = false;
	private _platforms: ReplaySubject<Models.Platform[]>;
	private dataStore: {
		platforms: Models.Platform[];
	};

	constructor(private http: HttpClient) {
		this.dataStore = {
			platforms: []
		};
		this._platforms = new ReplaySubject(1) as ReplaySubject<Models.Platform[]>;
		this.platforms = this._platforms.asObservable();
	}

	// add/update an item in the datastore
	private updateStore(newPlatform: Models.Platform): void {
		const idx = this.dataStore.platforms.findIndex(platform => platform.id === newPlatform.id);
		if (idx === -1) {
			this.dataStore.platforms.push(newPlatform);
			return;
		} else {
			this.dataStore.platforms[idx] = newPlatform;
		}
	}

	// get a fresh copy of the entire set
	refreshAll(): Observable<Models.Platform[]> {
		const platforms$ = this.http.get<Models.Platform[]>(AppConstants.apiUrl + AppConstants.apiUrls.platforms).pipe(share());
		platforms$.subscribe(
			data => {
				const platforms: Models.Platform[] = data;
				// remove ones from the store that aren't in the response (they've been deleted)
				this.dataStore.platforms.forEach((existingPlatform, existingIndex) => {
					const newIndex = platforms.findIndex(platform => platform.id === existingPlatform.id);
					if (newIndex === -1) this.dataStore.platforms.splice(existingIndex, 1);
				});
				// add/update all the ones that came back
				platforms.forEach(platform => this.updateStore(platform));
				this._platforms.next(Object.assign({}, this.dataStore).platforms);
				this.loaded = true;
			},
			error => { /* console.log(error) */ }
		);
		return platforms$;
	}

	// get a fresh copy of a single item
	refreshOne(id: number): Observable<Models.Platform> {
		const platform$ = this.http.get<Models.Platform>(AppConstants.apiUrl + AppConstants.apiUrls.platforms + '/' + id).pipe(share());
		platform$.subscribe(
			data => {
				const platform: Models.Platform = data;
				// add/update the one that came back
				this.updateStore(platform);
				this._platforms.next(Object.assign({}, this.dataStore).platforms);
			},
			error => { /* console.log(error) */ }
		);
		return platform$;
	}

	// return the whole list
	getAll(): Models.Platform[] {
		return this.dataStore.platforms.slice();
	}

	// grab a single item from the datastore
	getOne(id: number): Models.Platform {
		return this.dataStore.platforms.find(platform => platform.id === id);
	}

	// call back-end to add an item
	async addOne(platform: Models.Platform) {
		try {
			const result = await this.http.post<Models.Platform>(AppConstants.apiUrl + AppConstants.apiUrls.platforms, platform).toPromise();
			const returnedPlatform: Models.Platform = result;
			this.updateStore(returnedPlatform);
			this._platforms.next(Object.assign({}, this.dataStore).platforms);
			return returnedPlatform;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update an item
	async updateOne(platform: Models.Platform) {
		try {
			const result = await this.http.put<Models.Platform>(AppConstants.apiUrl + AppConstants.apiUrls.platforms + '/' + platform.id, platform).toPromise();
			const returnedPlatform: Models.Platform = result;
			this.updateStore(returnedPlatform);
			this._platforms.next(Object.assign({}, this.dataStore).platforms);
			return returnedPlatform;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to delete an item
	async deleteOne(id: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl + AppConstants.apiUrls.platforms + '/' + id).toPromise();
			const idx = this.dataStore.platforms.findIndex(platform => platform.id === id);
			if (idx !== -1) this.dataStore.platforms.splice(idx, 1);
			this._platforms.next(Object.assign({}, this.dataStore).platforms);
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}
}
