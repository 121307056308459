<div *ngIf="!!activation && keyWarnings && keyWarnings.length !== 0" class="cp-warning-message mb-2">
	<div *ngFor="let item of keyWarnings">
		<fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>
		{{ item }}
	</div>
</div>

<div *ngIf="staffMode && !!activation && meterWarnings.length !== 0" class="cp-warning-message mb-2">
	<div *ngFor="let item of meterWarnings">
		<fa-icon [icon]="['fas', 'question-circle']"></fa-icon>
		{{ item }}
	</div>
</div>

<div *ngIf="staffMode && !!activation && nonEnforcedWarnings.length !== 0" class="cp-info-message mb-2">
	<div *ngFor="let item of nonEnforcedWarnings">
		<fa-icon [icon]="['fas', 'question-circle']"></fa-icon>
		{{ item }}
	</div>
</div>

<div *ngIf="staffMode && activation && activation.meters.length !== 0" class="cp-light-message">
	<span *ngIf="metersEnforced">Meter limits are enforced.</span>
	<span *ngIf="!metersEnforced">Meter limits are NOT enforced.</span>
</div>

<div *ngIf="showMeterSummary">
	Summary of Active Meters
	<table class="cp-std-table cp-auto-width">
		<thead>
			<tr>
				<th class="cp-usage-left" rowspan="2">Type</th>
				<th *ngIf="hasMonthlyMeters" class="cp-usage-center" colspan="6">
					Resets Monthly
				</th>
				<th *ngIf="hasNeverMeters" class="cp-usage-center" colspan="6">
					Never Resets
				</th>
			</tr>
			<tr>
				<th *ngIf="hasMonthlyMeters" class="cp-usage-left" title="# of active meters of this type">
					#
				</th>
				<th *ngIf="hasMonthlyMeters" class="cp-usage-left">
					Used
					<span *ngIf="showRawData"><br />(MB)</span>
				</th>
				<th *ngIf="hasMonthlyMeters" class="cp-usage-left" title="Projected Usage Threshold">
					Proj.
					<span *ngIf="showRawData"><br />(MB)</span>
				</th>
				<th *ngIf="hasMonthlyMeters" class="cp-usage-left" title="Percent Used of Projected Usage Threshold">
					%-Proj.
				</th>
				<th *ngIf="hasMonthlyMeters" class="cp-usage-left" title="Maximum Limit">
					Max.
					<span *ngIf="showRawData"><br />(MB)</span>
				</th>
				<th *ngIf="hasMonthlyMeters" class="cp-usage-left" title="Percent Used of Maximum Limit">
					%-Max.
					<span *ngIf="showRawData"><br />(MB)</span>
				</th>

				<th *ngIf="hasNeverMeters" class="cp-usage-left" title="# of active meters of this type">
					#
				</th>
				<th *ngIf="hasNeverMeters" class="cp-usage-left">
					Used
					<span *ngIf="showRawData"><br />(MB)</span>
				</th>
				<th *ngIf="hasNeverMeters" class="cp-usage-left" title="Projected Usage Threshold">
					Proj.
					<span *ngIf="showRawData"><br />(MB)</span>
				</th>
				<th *ngIf="hasNeverMeters" class="cp-usage-left" title="Percent Used of Projected Usage Threshold">
					%-Proj.
				</th>
				<th *ngIf="hasNeverMeters" class="cp-usage-left" title="Maximum Limit">
					Max.
					<span *ngIf="showRawData"><br />(MB)</span>
				</th>
				<th *ngIf="hasNeverMeters" class="cp-usage-left" title="Percent Used of Maximum Limit">
					%-Max.
					<span *ngIf="showRawData"><br />(MB)</span>
				</th>
			</tr>
		</thead>
		<tbody>

			<tr *ngFor="let type of activeMeterTypes ">
				<td class="cp-usage-left">
					{{ ac.meterTypeObjects[type].label }}
					<fa-icon [icon]="['fas', 'info-circle']" [ngbTooltip]="ac.meterTypeObjects[type].description">
					</fa-icon>
				</td>

				<!-- monthly -->

				<td *ngIf="hasMonthlyMeters && hasMeterOfCertainType(type, 'monthly')" class="cp-usage-right"
					(mouseover)="highliteMeters(type, 'monthly')" (mouseout)="resetMeterHighlites()">
					{{ meterSummaryInfo[type +'.monthly'].count }}
				</td>
				<td *ngIf="hasMonthlyMeters && hasMeterOfCertainType(type, 'monthly')" class="cp-usage-right"
					(mouseover)="highliteMeters(type, 'monthly')" (mouseout)="resetMeterHighlites()">
					<span [ngbTooltip]="makeDataTip(meterSummaryInfo[type +'.monthly'].used)" ngxClipboard
						(click)="copyToClipboardAlert(meterSummaryInfo[type +'.monthly'].used + '')"
						[cbContent]="meterSummaryInfo[type +'.monthly'].used">
						{{ showDataValue(meterSummaryInfo[type +'.monthly'].used) }}
					</span>
				</td>
				<td *ngIf="hasMonthlyMeters && hasMeterOfCertainType(type, 'monthly')" class="cp-usage-right"
					(mouseover)="highliteMeters(type, 'monthly')" (mouseout)="resetMeterHighlites()">
					<span *ngIf="+meterSummaryInfo[type +'.monthly'].projected > 0"
						[ngbTooltip]="makeDataTip(meterSummaryInfo[type +'.monthly'].projected)" ngxClipboard
						(click)="copyToClipboardAlert(meterSummaryInfo[type +'.monthly'].projected + '')"
						[cbContent]="meterSummaryInfo[type +'.monthly'].projected">
						{{ showDataValue(meterSummaryInfo[type +'.monthly'].projected) }}
					</span>
				</td>
				<td *ngIf="hasMonthlyMeters && hasMeterOfCertainType(type, 'monthly')" class="cp-usage-right"
					(mouseover)="highliteMeters(type, 'monthly')" (mouseout)="resetMeterHighlites()"
					[ngClass]="{'text-danger': +meterSummaryInfo[type + '.monthly'].projected > 0 && +meterSummaryInfo[type + '.monthly'].used >= +meterSummaryInfo[type + '.monthly'].projected, 'text-warning': warningMeter(meterSummaryInfo[type + '.monthly'], true) }">
					{{ usagePercOfProjected(meterSummaryInfo[type + '.monthly']) }}
				</td>
				<td *ngIf="hasMonthlyMeters && hasMeterOfCertainType(type, 'monthly')" class="cp-usage-right"
					(mouseover)="highliteMeters(type, 'monthly')" (mouseout)="resetMeterHighlites()">
					<span [ngbTooltip]="makeDataTip(meterSummaryInfo[type +'.monthly'].limit)" ngxClipboard
						(click)="copyToClipboardAlert(meterSummaryInfo[type +'.monthly'].limit + '')"
						[cbContent]="meterSummaryInfo[type +'.monthly'].limit">
						{{ showDataValue(meterSummaryInfo[type +'.monthly'].limit) }}
					</span>
				</td>
				<td *ngIf="hasMonthlyMeters && hasMeterOfCertainType(type, 'monthly')" class="cp-usage-right"
					(mouseover)="highliteMeters(type, 'monthly')" (mouseout)="resetMeterHighlites()"
					[ngClass]="{'text-danger': +meterSummaryInfo[type + '.monthly'].used >= +meterSummaryInfo[type + '.monthly'].limit, 'text-warning': warningMeter(meterSummaryInfo[type + '.monthly']) }">
					{{ usagePercOfMaximum(meterSummaryInfo[type + '.monthly']) }}
				</td>

				<td *ngIf="hasMonthlyMeters && !hasMeterOfCertainType(type, 'monthly')" class="cp-usage-right" colspan="6">
				</td>

				<!-- Nevers -->
				<td *ngIf="hasNeverMeters && hasMeterOfCertainType(type, 'never')" class="cp-usage-right"
					(mouseover)="highliteMeters(type, 'never')" (mouseout)="resetMeterHighlites()">
					{{ meterSummaryInfo[type +'.never'].count }}
				</td>
				<td *ngIf="hasNeverMeters && hasMeterOfCertainType(type, 'never')" class="cp-usage-right"
					(mouseover)="highliteMeters(type, 'never')" (mouseout)="resetMeterHighlites()">
					<span [ngbTooltip]="makeDataTip(meterSummaryInfo[type +'.never'].used)" ngxClipboard
						(click)="copyToClipboardAlert(meterSummaryInfo[type +'.never'].used + '')"
						[cbContent]="meterSummaryInfo[type +'.never'].used">
						{{ showDataValue(meterSummaryInfo[type +'.never'].used) }}
					</span>
				</td>
				<td *ngIf="hasNeverMeters && hasMeterOfCertainType(type, 'never')" class="cp-usage-right"
					(mouseover)="highliteMeters(type, 'never')" (mouseout)="resetMeterHighlites()">
					<span *ngIf="+meterSummaryInfo[type +'.never'].projected > 0"
						[ngbTooltip]="makeDataTip(meterSummaryInfo[type +'.never'].projected)" ngxClipboard
						(click)="copyToClipboardAlert(meterSummaryInfo[type +'.never'].projected + '')"
						[cbContent]="meterSummaryInfo[type +'.never'].projected">
						{{ showDataValue(meterSummaryInfo[type +'.never'].projected) }}
					</span>
				</td>
				<td *ngIf="hasNeverMeters && hasMeterOfCertainType(type, 'never')" class="cp-usage-right"
					(mouseover)="highliteMeters(type, 'never')" (mouseout)="resetMeterHighlites()"
					[ngClass]="{'text-danger': +meterSummaryInfo[type + '.never'].projected > 0 && +meterSummaryInfo[type + '.never'].used >= +meterSummaryInfo[type + '.never'].projected, 'text-warning': warningMeter(meterSummaryInfo[type + '.never'], true) }">
					{{ usagePercOfProjected(meterSummaryInfo[type + '.never']) }}
				</td>
				<td *ngIf="hasNeverMeters && hasMeterOfCertainType(type, 'never')" class="cp-usage-right"
					(mouseover)="highliteMeters(type, 'never')" (mouseout)="resetMeterHighlites()">
					<span [ngbTooltip]="makeDataTip(meterSummaryInfo[type +'.never'].limit)" ngxClipboard
						(click)="copyToClipboardAlert(meterSummaryInfo[type +'.never'].limit + '')"
						[cbContent]="meterSummaryInfo[type +'.never'].limit">
						{{ showDataValue(meterSummaryInfo[type +'.never'].limit) }}
					</span>
				</td>
				<td *ngIf="hasNeverMeters && hasMeterOfCertainType(type, 'never')" class="cp-usage-right"
					(mouseover)="highliteMeters(type, 'never')" (mouseout)="resetMeterHighlites()"
					[ngClass]="{'text-danger': +meterSummaryInfo[type + '.never'].used >= +meterSummaryInfo[type + '.never'].limit, 'text-warning': warningMeter(meterSummaryInfo[type + '.never']) }">
					{{ usagePercOfMaximum(meterSummaryInfo[type + '.never']) }}
				</td>

				<td *ngIf="hasNeverMeters && !hasMeterOfCertainType(type, 'never')" class="cp-usage-right" colspan="6">
				</td>

			</tr>
		</tbody>
	</table>
</div>

<div *ngIf="activation && activation.meters.length !== 0" class="container ps-0 ms-0">
	Meters
	<table class="cp-std-table cp-auto-width">
		<thead>
			<tr>
				<th class="cp-usage-left">Label</th>
				<th class="cp-usage-left">Type</th>
				<th class="cp-usage-left">Status</th>
				<th class="cp-usage-left">Created</th>
				<th class="cp-usage-left">Starts</th>
				<th class="cp-usage-left">Expires</th>
				<th class="cp-usage-left">
					Used
					<span *ngIf="showRawData">(MB)</span>
				</th>
				<th class="cp-usage-left" title="Projected Usage Threshold">
					Proj.
					<span *ngIf="showRawData">(MB)</span>
				</th>
				<th class="cp-usage-left" title="Percent Used of Projected Usage Threshold">
					%-Proj
				</th>
				<th class="cp-usage-left" title="Amount over Projected Usage Threshold">
					Over.
					<span *ngIf="showRawData">(MB)</span>
				</th>
				<th class="cp-usage-left" title="Maximum Limit">
					Max.
					<span *ngIf="showRawData">(MB)</span>
				</th>
				<th class="cp-usage-left" title="Percent Used of Maximum Limit">%-Max</th>
				<th class="cp-usage-left">Resets</th>
			</tr>
		</thead>

		<tbody *ngIf="activation && activation.meters.length !== 0">
			<tr *ngFor="let item of activation.meters" [ngClass]="{
					'table-info': meterHighlites[item.id] && meterHighlites[item.id] === 'yes',
					'text-danger': miscTools.hasExpired(item.expires_at),
					'text-warning': sharedLicenseTools.hasNotStarted(item.starts_at)}">
				<td class="cp-usage-left">
					<span *ngIf="!item.label || item.label === ''" class="font-italic">
						None
					</span>
					<span *ngIf="item.label && item.label !== ''">
						{{ item.label }}
					</span>
				</td>
				<td class="cp-usage-left">
					{{ ac.meterTypeObjects[item.product].label }}
					<fa-icon [icon]="['fas', 'info-circle']" [ngbTooltip]="ac.meterTypeObjects[item.product].description">
					</fa-icon>
				</td>
				<td class="cp-usage-left">{{ meterStatus(item) }}</td>

				<td class="cp-usage-left">
					{{ item.created_at| date: ac.tableDateFmt:'UTC' }}
				</td>

				<td class="cp-usage-left" [ngClass]="{'text-warning': sharedLicenseTools.hasNotStarted(item.starts_at)}">
					{{ item.starts_at | date: ac.tableDateFmt:'UTC' }}
				</td>
				<td class="cp-usage-left">
					{{ item.expires_at | date: ac.tableDateFmt:'UTC' }}
				</td>

				<td class="cp-usage-right">
					<span [ngbTooltip]="makeDataTip(item.used)" ngxClipboard (click)="copyToClipboardAlert(item.used + '')"
						[cbContent]="item.used">
						{{ showDataValue(item.used) }}
					</span>
				</td>

				<td class="cp-usage-right">
					<span *ngIf="+item.projected > 0" [ngbTooltip]="makeDataTip(item.projected)" ngxClipboard
						(click)="copyToClipboardAlert(item.projected + '')" [cbContent]="item.projected">
						{{ showDataValue(item.projected) }}
					</span>
				</td>
				<td class="cp-usage-right"
					[ngClass]="{'text-danger': +item.projected !== 0 && +item.used >= +item.projected, 'text-warning': warningMeter(item, true) }">
					{{ usagePercOfProjected(item) }}
				</td>

				<td class="cp-usage-right">
					<span *ngIf="+item.projected > 0 && +item.used > +item.projected" [ngbTooltip]="makeDataTip(item.projected)"
						ngxClipboard (click)="copyToClipboardAlert((+item.used - +item.projected) + '')"
						[cbContent]="(+item.used - +item.projected) + ''">
						{{ showDataValue(+item.used - +item.projected) }}
					</span>
				</td>

				<td class="cp-usage-right">
					<span [ngbTooltip]="makeDataTip(item.limit)" ngxClipboard (click)="copyToClipboardAlert(item.limit + '')"
						[cbContent]="item.limit">
						{{ showDataValue(item.limit) }}
					</span>
				</td>
				<td class="cp-usage-right" [ngClass]="{'text-danger': +item.used >= +item.limit, 'text-warning': warningMeter(item) }">
					{{ usagePercOfMaximum(item) }}
				</td>

				<td class="cp-usage-left">{{ item.resets }}</td>
			</tr>
		</tbody>
	</table>
</div>

<div *ngIf="linkedProtocolSets.length !== 0 || linkedChronoSets.length !== 0" class="cp-property-list">
	Linked Protocol Sets

	<div *ngFor="let pSet of linkedProtocolSets" class="cp-property-block">
		<div class="cp-property-label">Linked Protocol (Data) Set:</div>
		<div class="cp-property-value ms-2">
			<a *ngIf="staffMode" [routerLink]="['/' + ac.urls.licensingadmin, 'protocol-sets', pSet.id]" [ngbPopover]="pSetPopupContent"
				popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
				{{ pSet.name }}
			</a>
			<span *ngIf="!staffMode" [ngbPopover]="pSetPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
				container="body" class="fw-bold">
				{{ pSet.name }}
			</span>
			<span *ngIf="pSet['projected'] && pSet['projected'] > 0">
				{{ ac.bullet }} Used: {{ textTools.formattedMB(pSet['current_usage']) }} of
				{{ textTools.formattedMB(pSet['projected']) }} (Projected Monthly Limit)
			</span>
		</div>
		<ng-template #pSetPopupContent>
			<div class="cp-popover-banner">{{ pSet.name }}</div>
			<div class="cp-pre-text">{{
				popOverTools.getProtocolSetPopoverLines(pSet, true).join('\n')}}</div>
		</ng-template>
	</div>

	<div *ngFor="let pSet of linkedChronoSets" class="cp-property-block">
		<div class="cp-property-label">Linked Protocol (Time) Set:</div>
		<div class="cp-property-value ms-2">
			<a *ngIf="staffMode" [routerLink]="['/' + ac.urls.licensingadmin, 'protocol-sets', pSet.id]" [ngbPopover]="pSetPopupContent"
				popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
				{{ pSet.name }}
			</a>
			<span *ngIf="!staffMode" [ngbPopover]="pSetPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
				container="body" class="fw-bold">
				{{ pSet.name }}
			</span>
			<span *ngIf="pSet['current_usage'] && pSet['current_usage'] !== 0" class="ms-1">
				Used: {{ textTools.formattedMB(pSet['current_usage']) }} of
			</span>
			<span *ngIf="pSet['projected'] && pSet['projected'] !== 0" class="ms-1">
				Monthly Projection: {{ textTools.formattedMB(pSet['projected']) }}
			</span>
		</div>
		<ng-template #pSetPopupContent>
			<div class="cp-popover-banner">{{ pSet.name }}</div>
			<div class="cp-pre-text">{{
				popOverTools.getProtocolSetPopoverLines(pSet, true).join('\n')}}</div>
		</ng-template>
	</div>



</div>