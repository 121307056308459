import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import { RoleGuard } from 'client/app/guards/role.guard';

import { MyBuildComponent } from './my-build/my-build.component';
import { MyBuildsListComponent } from './my-builds-list/my-builds-list.component';

const routes: Routes = [
	{
		path: '',
		component: MyBuildsListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole },
		resolve: []
	},
	{
		path: ':id',
		component: MyBuildComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole },
		resolve: []
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class MyBuildsRoutingModule { }
