import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';

import { MyPackagesService } from '../my-packages.service';
import { MyKeysService } from '../../my-keys/my-keys.service';
import { MySettingsService } from '../../my-settings/my-settings.service';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { MyBuildsService } from '../../my-builds/my-builds.service';
import { MyDocumentsService } from '../../my-documents/my-documents.service';

@Component({
	selector: 'app-my-packages',
	templateUrl: './my-packages.component.html',
	styleUrls: ['./my-packages.component.scss']
})
export class MyPackagesComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	textTools = TextTools;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	user: Models.User;
	myPackages: Models.Package[] = [];
	availablePackages: Models.Package[] = [];

	subscribing = false;
	loading = true;
	info = 'Being subscribed to a package gives you access to downloadable software, documentation and/or the ability to generate license keys.';

	canAccessAllFiles: boolean = false;

	constructor(
		private myPackagesService: MyPackagesService,
		private mySettingsService: MySettingsService,
		private myKeysService: MyKeysService,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private myBuildsService: MyBuildsService,
		private myDocumentsService: MyDocumentsService
	) { }

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;

			this.canAccessAllFiles = ValidationTools.checkRole(authUser.role, AppConstants.staffUserRole)
				&& ValidationTools.checkAccess(authUser, 'access-all-builds-and-documents');

			this.getData();
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async getData(forceRefresh = false) {
		this.loading = true;

		this.user = await this.mySettingsService.getUser();
		this.myPackages = await this.myPackagesService.getMyPackages(forceRefresh);
		this.availablePackages = await this.myPackagesService.getAvailablePackages(forceRefresh);

		this.loading = false;
	}

	async subscribeToAllPackages() {
		this.subscribing = true;
		try {
			const ids: number[] = [];
			for (const availablePackage of this.availablePackages)
				ids.push(availablePackage.id);

			await this.myPackagesService.subscribeToPackages(ids, true);
			this.uiAlertsService.addMsg('Excellent.  You\'re now subscribed to all packages.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

			this.subscribing = false;
			await this.getData(true);

			await this.mySettingsService.getProductIDs(true);

			await this.myBuildsService.getBuilds(true);
			await this.myDocumentsService.getDocuments(true);
		} catch (e) {
			this.subscribing = false;
			this.uiAlertsService.addMsg(e.message, 'error');
		}
	}

	async unsubscribeFromAllPackages() {
		if (!confirm('Are you sure you want to unsubscribe from all Packages?')) return;
		this.subscribing = true;
		try {
			const ids: number[] = [];
			for (const myPackage of this.myPackages)
				ids.push(myPackage.id);

			await this.myPackagesService.unsubscribeFromPackages(ids, true);
			this.uiAlertsService.addMsg('Ok.  You\'re now unsubscribed from all packages.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

			this.subscribing = false;
			await this.getData(true);

			await this.mySettingsService.getProductIDs(true);

			await this.myBuildsService.getBuilds(true);
			await this.myDocumentsService.getDocuments(true);
		} catch (e) {
			this.subscribing = false;
			this.uiAlertsService.addMsg(e.message, 'error');
		}
	}

	async subscribeToPackage(id: number) {
		this.subscribing = true;
		try {
			const ret = await this.myPackagesService.subscribeToPackages([id]);
			if (ret) {
				this.uiAlertsService.addMsg('Excellent.  You\'re now subscribed to that package and have access to software and documentation related to it.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
				this.subscribing = false;
				await this.getData();

				await this.mySettingsService.getProductIDs(true);

				await this.myBuildsService.getBuilds(true);
				await this.myDocumentsService.getDocuments(true);

			} else {
				this.subscribing = false;
				// this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.subscribing = false;
			this.uiAlertsService.addMsg(e.message, 'error');
		}
	}


	async unsubscribeFromPackage(id: number) {
		if (!confirm('Are you sure you want to unsubscribe from this Package?')) return;
		this.subscribing = true;
		try {
			const ret = await this.myPackagesService.unsubscribeFromPackages([id]);
			if (ret) {
				this.uiAlertsService.addMsg('Ok.  You\'re now unsubscribed from that package.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
				this.subscribing = false;
				await this.getData();

				await this.mySettingsService.getProductIDs(true);

				await this.myBuildsService.getBuilds(true);
				await this.myDocumentsService.getDocuments(true);

			} else {
				this.subscribing = false;
				// this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.subscribing = false;
			this.uiAlertsService.addMsg(e.message, 'error');
		}
	}
}
