<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.mydocuments" class="cp-user-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">Documentation</a>
			</li>
			<li *ngIf="downloadableDocument" class="breadcrumb-item active" aria-current="page">
				{{ downloadableDocument.document.label }}
			</li>
		</ol>
	</nav>

	<div *ngIf="downloadableDocument && isStaff" class="actions">
		<div class="form-inline">
			<div class="btn-group">
				<button type="button" class="btn btn-outline-secondary"
					[routerLink]="['/' + ac.urls.documents, downloadableDocument.document.id]">
					<fa-icon [icon]="['fas', 'glasses']" size="sm"></fa-icon>
					Staff View
				</button>
			</div>
		</div>
	</div>

</div>

<div *ngIf="!!downloadableDocument" class="page-wrapper">

	<div *ngIf="downloadableDocument.product.download_build_description && downloadableDocument.product.download_build_description !== ''"
		class="cp-light-message cp-pre-text">
		{{ downloadableDocument.product.download_build_description }}
	</div>

	<div class="cp-property-list">
		<div class="cp-property-block">
			<span class="cp-property-label">Product:</span>
			<span class="cp-property-value">{{ downloadableDocument.document.prod_name }}</span>
		</div>

		<div class="cp-property-block">
			<span class="cp-property-label">Added:</span>
			<span class="cp-property-value">
				{{ downloadableDocument.document.added_on | date: ac.pageDateTimeFmt }}
			</span>
		</div>

		<div class="cp-property-block" *ngIf="downloadableDocument.document.added_on !== downloadableDocument.document.edited_on">
			<span class="cp-property-label">Updated:</span>
			<span class="cp-property-value">
				{{ downloadableDocument.document.edited_on | date: ac.pageDateTimeFmt }}
			</span>
		</div>
	</div>

	<div *ngIf="downloadableDocument.document.file_id && downloadableDocument.document.file_id !== 0" class="mb-2">
		<button type="button" class="cp-download-document-btn me-1"
			(click)="downloadFile(downloadableDocument.document.file_id, 'document', downloadableDocument.document.id)">
			<fa-icon [icon]="['fas', 'file-download']"></fa-icon> Download
		</button>

		<button *ngIf="downloadableDocument.document.can_open === 1 && downloadableDocument.file" type="button"
			class="cp-download-document-btn me-1" (click)="openFile(downloadableDocument.document.file_id, downloadableDocument.file.name)">
			<fa-icon [icon]="['fas', 'file']"></fa-icon> Open
		</button>

		{{ getFileName(downloadableDocument) }}
		({{ getFileSize(downloadableDocument) | bytes:'1.0-0' }})
	</div>

	<div *ngIf="downloadableDocument.document.link && downloadableDocument.document.link !== ''" class="mb-2">
		<button type="button" class="cp-link-btn me-1" (click)="openLink(downloadableDocument)">
			<fa-icon [icon]="['fas', 'external-link-square-alt']"></fa-icon>
			Link
		</button>
		<span title="{{ downloadableDocument.document.link }}">
			{{ chopString(downloadableDocument.document.link) }}
		</span>
	</div>

</div>