import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class DocumentsService {
	documents: Observable<Models.Document[]>;
	loaded = false;
	private debug = false;
	private _documents: ReplaySubject<Models.Document[]>;
	private dataStore: {
		documents: Models.Document[];
	};

	constructor(private http: HttpClient) {
		this.dataStore = {
			documents: []
		};
		this._documents = new ReplaySubject(1) as ReplaySubject<Models.Document[]>;
		this.documents = this._documents.asObservable();
	}

	// add/update an item in the datastore
	private updateStore(newUser: Models.Document): void {
		const idx = this.dataStore.documents.findIndex(document => document.id === newUser.id);
		if (idx === -1) {
			this.dataStore.documents.push(newUser);
			return;
		} else {
			this.dataStore.documents[idx] = newUser;
		}
	}

	// get a fresh copy of the entire set
	refreshAll(): Observable<Models.Document[]> {
		const documents$ = this.http.get<Models.Document[]>(AppConstants.apiUrl + AppConstants.apiUrls.documents).pipe(share());
		documents$.subscribe(
			data => {
				const documents: Models.Document[] = data;
				// remove ones from the store that aren't in the response (they've been deleted)
				this.dataStore.documents.forEach((existingUser, existingIndex) => {
					const newIndex = documents.findIndex(document => document.id === existingUser.id);
					if (newIndex === -1) this.dataStore.documents.splice(existingIndex, 1);
				});
				// add/update all the ones that came back
				documents.forEach(document => this.updateStore(document));
				this._documents.next(Object.assign({}, this.dataStore).documents);
				this.loaded = true;
			},
			error => { /* console.log(error) */ }
		);
		return documents$;
	}

	// get a fresh copy of a single item
	refreshOne(id: number): Observable<Models.Document> {
		const document$ = this.http.get<Models.Document>(AppConstants.apiUrl + AppConstants.apiUrls.documents + '/' + id).pipe(share());
		document$.subscribe(
			data => {
				const document: Models.Document = data;
				// add/update the one that came back
				this.updateStore(document);
				this._documents.next(Object.assign({}, this.dataStore).documents);
			},
			error => { /* console.log(error) */ }
		);
		return document$;
	}

	// return the whole list
	getAll(): Models.Document[] {
		return this.dataStore.documents.slice();
	}

	// grab a single item from the datastore
	getOne(id: number): Models.Document {
		return this.dataStore.documents.find(document => document.id === id);
	}

	// call back-end to add an item
	async addOne(document: Models.Document) {
		try {
			const result = await this.http.post<Models.Document>(AppConstants.apiUrl + AppConstants.apiUrls.documents, document).toPromise();
			const returnedUser: Models.Document = result;
			this.updateStore(returnedUser);
			this._documents.next(Object.assign({}, this.dataStore).documents);
			return returnedUser;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update an item
	async updateOne(document: Models.Document) {
		try {
			const result = await this.http.put<Models.Document>(AppConstants.apiUrl + AppConstants.apiUrls.documents + '/' + document.id, document).toPromise();
			const returnedUser: Models.Document = result;
			this.updateStore(returnedUser);
			this._documents.next(Object.assign({}, this.dataStore).documents);
			return returnedUser;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to delete an item
	async deleteOne(id: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl + AppConstants.apiUrls.documents + '/' + id).toPromise();
			const idx = this.dataStore.documents.findIndex(document => document.id === id);
			if (idx !== -1) this.dataStore.documents.splice(idx, 1);
			this._documents.next(Object.assign({}, this.dataStore).documents);
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// ********************************************************
	// other non-standard methods
	// ********************************************************

	// call back-end to enable/disable
	async toggleEnabled(id: number) {
		try {
			const result = await this.http.put<Models.Document>(AppConstants.apiUrl + AppConstants.apiUrls.documents + '/' + id + '/toggle-enabled', {}).toPromise();
			const returnedUser: Models.Document = result;
			this.updateStore(returnedUser);
			this._documents.next(Object.assign({}, this.dataStore).documents);
			return returnedUser;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// get IDs of users that can access this doc
	async fetchUserIDs(id: number) {
		try {
			const result = await this.http.get<number[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.documents + '/' + id + '/users').toPromise();
			const arr: number[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update an item
	async sendNotification(id: number, message: string) {
		try {
			const result = await this.http.put(AppConstants.apiUrl + AppConstants.apiUrls.documents + '/' + id + '/notify', { message, async: 'yes' }).toPromise();
			return;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

}
