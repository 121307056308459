import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';
import { ProductComponent } from './product/product.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductFormComponent } from './product-form/product-form.component';
import { ProductNotificationFormComponent } from './product-notification-form/product-notification-form.component';

import { ProductsResolverService } from './products-resolver.service';
import { PlatformsResolverService } from '../platforms/platforms-resolver.service';
import { BuildsResolverService } from '../builds/builds-resolver.service';
import { DocumentsResolverService } from '../documents/documents-resolver.service';
import { PackagesResolverService } from '../packages/packages-resolver.service';
import { OrganizationsResolverService } from '../organizations/organizations-resolver.service';
import { UsersResolverService } from '../users/users-resolver.service';
import { ProductSortComponent } from './product-sort/product-sort.component';

const routes: Routes = [
	{
		path: '',
		component: ProductListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [ProductsResolverService]
	}, {
		path: 'sort-products',
		component: ProductSortComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [ProductsResolverService]
	}, {
		path: 'new',
		component: ProductFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [PlatformsResolverService]
	}, {
		path: ':id',
		component: ProductComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [ProductsResolverService, PlatformsResolverService, BuildsResolverService,
			DocumentsResolverService, OrganizationsResolverService, PackagesResolverService]
	}, {
		path: ':id/edit', component: ProductFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [ProductsResolverService, PlatformsResolverService]
	}, {
		path: ':id/notify',
		component: ProductNotificationFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [ProductsResolverService, UsersResolverService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ProductsRoutingModule { }
