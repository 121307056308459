import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { UsersRoutingModule } from './users-routing.module';

import { UserComponent } from './user/user.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserFormComponent } from './user-form/user-form.component';
import { AddLicenseKeysForUserFormComponent } from './add-license-keys-for-user-form/add-license-keys-for-user-form.component';
import { UserAccessFormComponent } from './user-access-form/user-access-form.component';
import { AccessSummaryComponent } from './access-summary/access-summary.component';
import { UserNotificationsFormComponent } from './user-notifications-form/user-notifications-form.component';

import { UserGroupComponent } from './user-group/user-group.component';
import { UserGroupListComponent } from './user-group-list/user-group-list.component';
import { UserGroupFormComponent } from './user-group-form/user-group-form.component';
import { UserGroupAccessFormComponent } from './user-group-access-form/user-group-access-form.component';


@NgModule({
	declarations: [
		UserComponent,
		UserListComponent,
		UserFormComponent,
		AddLicenseKeysForUserFormComponent,
		UserAccessFormComponent,
		AccessSummaryComponent,
		UserNotificationsFormComponent,
		UserGroupComponent,
		UserGroupListComponent,
		UserGroupFormComponent,
		UserGroupAccessFormComponent],
	imports: [
		UsersRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		NgSelectModule,
		NgbModule,
		SharedModule,
		FontAwesome,
		ClipboardModule
	]
})
export class UsersModule { }
