import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class AwsMarketplaceRegistrationService {

	constructor(private http: HttpClient) { }

	// call back-end to add an item
	async submitRegistration(props: any) {
		try {
			const result = await this.http.post<Models.User>(AppConstants.apiUrl
				+ AppConstants.apiUrls.auth + AppConstants.apiUrls.aws_marketplaceregistration, props).toPromise();

			return result;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

}
