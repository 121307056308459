import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { SystemMessagesRoutingModule } from './system-messages-routing.module';
import { SystemMessageComponent } from './system-message/system-message.component';
import { SystemMessagesListComponent } from './system-messages-list/system-messages-list.component';
import { SystemMessageFormComponent } from './system-message-form/system-message-form.component';
import { GeneralNotificationFormComponent } from './general-notification-form/general-notification-form.component';

@NgModule({
	declarations: [SystemMessageComponent, SystemMessagesListComponent, SystemMessageFormComponent, GeneralNotificationFormComponent],
	imports: [
		SystemMessagesRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		SharedModule,
		FontAwesome
	]
})
export class SystemMessagesModule { }
