<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="ac.sectionIcons.platforms" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Platforms
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button *ngIf="canAdd" type="button" class="btn btn-outline-primary me-2" routerLink="new">
				<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
				<span class="cp-action-button-text">New Platform</span>
			</button>

			<button type="button" class="btn btn-outline-secondary" (click)="refresh()">
				<fa-icon icon="sync-alt" size="sm" [spin]="refreshing" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<div class="list-wrapper">
	<div *ngIf="loading || refreshing" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<table class="cp-std-table cp-auto-width">
		<thead>
			<tr>
				<th>Platform</th>
				<th>Type</th>
				<th>File Keyword</th>
				<th>Products</th>
				<th>Added</th>
				<th>Edited</th>
			</tr>
		</thead>
		<tbody *ngIf="!loading">
			<tr *ngFor="let item of platforms">
				<td><a [routerLink]="[item.id]">{{ item.name }}</a></td>
				<td>{{ ac.platformTypeLabels[item.ptype] }}</td>
				<td>{{ item.filename_keyword }}</td>
				<td>
					<span *ngIf="!item.product_ids || item.product_ids.length === 0">No products</span>
					<span *ngIf="!!item.product_ids && item.product_ids.length !== 0">
						{{ item.product_ids.length }} product<span *ngIf="item.product_ids.length > 1">s</span>
					</span>
				</td>
				<td class="text-nowrap" [attr.data-order]="textTools.formatDateTimeUTC(item.added_on)">
					{{ item.added_on | date: ac.tableDateFmt }}
				</td>
				<td class="text-nowrap" [attr.data-order]="textTools.formatDateTimeUTC(item.edited_on)">
					{{ item.edited_on | date: ac.tableDateFmt }}
				</td>
			</tr>
		</tbody>
	</table>
</div>