import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
	name: 'bytes'
})
export class BytesPipe implements PipeTransform {
	constructor(private decimalPipe: DecimalPipe) {}

	transform(bytes: any, precision: any): string {
			if (bytes === 0 || isNaN(parseFloat(bytes)) || !isFinite(bytes)) { return '-'; }
			if (typeof precision === 'undefined') { precision = 2; }
			const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
			const num = Math.floor(Math.log(bytes) / Math.log(1000));
			return this.decimalPipe.transform(bytes / Math.pow(1000, Math.floor(num)), precision) + ' ' + units[num];
	}
}
