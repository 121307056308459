<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.systemmessages" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" (click)="onCancel()">Dashboard Messages</a>
			</li>
			<li *ngIf="editMode" class="breadcrumb-item active" aria-current="page">
				Edit Message
			</li>
			<li *ngIf="!editMode" class="breadcrumb-item active" aria-current="page">
				New Message
			</li>
		</ol>
	</nav>
</div>

<form [formGroup]="theForm" autocomplete="false">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="is_enabled" formControlName="is_enabled" />
			<label class="form-check-label" for="is_enabled">This message is enabled/active (visible to users).</label>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="can_ack" formControlName="can_ack" />
			<label class="form-check-label" for="can_ack">Users can acknowledge/dismiss this message.</label>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="staff_only" formControlName="staff_only" />
			<label class="form-check-label" for="staff_only">Staff Only - do not show basic (customers/partners)
				users.</label>
		</div>

		<div class="form-group">
			<label for="message">Message*</label>
			<textarea id="message" formControlName="message" class="form-control" rows="8"></textarea>
		</div>

		<div class="form-group">
			<label for="name">More Info URL</label>
			<input type="text" id="url" formControlName="url" class="form-control" />
		</div>

		<div class="form-group">
			<label for="start_at">Don't Show Before</label>
			<div class="input-group">
				<button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
					<fa-icon icon="calendar-alt"></fa-icon>
				</button>
				<input class="form-control" placeholder="yyyy-mm-dd" id="start_at" formControlName="start_at" ngbDatepicker
					firstDayOfWeek="7" #d1="ngbDatepicker">
			</div>
		</div>

		<div class="form-group">
			<label for="end_at">Don't Show After</label>
			<div class="input-group">
				<button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
					<fa-icon icon="calendar-alt"></fa-icon>
				</button>
				<input class="form-control" placeholder="yyyy-mm-dd" id="end_at" formControlName="end_at" ngbDatepicker firstDayOfWeek="7"
					#d2="ngbDatepicker">
			</div>
		</div>

		<!-- <div class="form-group mb-2 small">* Required</div>

		<div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>
		</div> -->

		<div class="cp-form-padding"></div>

	</div>
	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>
</form>