import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import { UnsubscribeService } from './unsubscribe.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { LoginService } from '../login/login.service';

@Component({
	selector: 'app-unsubscribe',
	templateUrl: './unsubscribe.component.html',
	styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit, OnDestroy {
	// 'standard' edit stuff
	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	private _code = '';
	supportURL = AppConstants.supportURL;

	validCode = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private unsubscribeService: UnsubscribeService,
		private loginService: LoginService,
		private uiAlertsService: UiAlertsService
	) {
		this.route.paramMap.subscribe(params => {
			this._code = params.get('code');
			this.loading = false;
		});
	}

	ngOnInit(): void {
		this.checkCode();
		// make sure the user's logged out...
		// this.loginService.logout();
		// this.initForm();
	}

	ngOnDestroy() {
	}

	async checkCode() {
		this.loading = true;
		this.validCode = await this.unsubscribeService.checkUnsubCode(this._code);
		this.loading = false;
	}

	async unsubscribe() {
		this.saving = true;
		this.errors = [];

		try {
			const ret = await this.unsubscribeService.unsubscribe(this._code);
			if (ret) {
				this.uiAlertsService.addMsg('You have been unsubscribed from all e-mail notifications.',
					'info', 'LOGIN', false, AppConstants.standardMessageAutoCloseTimeSecs * 6);
				this.router.navigate([AppConstants.urls.login]);
				this.saving = false;
			} else {
				this.saving = false;
				// this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			// this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}

	}

	onCancel() {
		this.router.navigate(['/login']);
	}
}
