<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="ac.sectionIcons.marketplace" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Marketplace Usage Reports
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="btn-group">
			<button type="button" class="btn btn-outline-secondary" (click)="loadLogs()">
				<fa-icon icon="sync-alt" size="sm" [spin]="loading || refreshing" ngbTooltip="Refresh" placement="start">
				</fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<div class="list-wrapper">
	<form [formGroup]="theForm" *ngIf="showFilters">
		<div class="row justify-content-center">
			<div class="cp-filter-block-4">
				<div class="input-group">
					<button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
						<fa-icon icon="calendar-alt"></fa-icon>
					</button>
					<input class="form-control" placeholder="Not Before yyyy-mm-dd" id="notBefore" formControlName="notBefore"
						firstDayOfWeek="7" ngbDatepicker #d1="ngbDatepicker" (change)="onFormChange()" (dateSelect)="onFormChange()">
				</div>
			</div>

			<div class="cp-filter-block-4">
				<div class="input-group">
					<button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
						<fa-icon icon="calendar-alt"></fa-icon>
					</button>

					<input class="form-control" placeholder="Not After yyyy-mm-dd" id="notAfter" formControlName="notAfter" ngbDatepicker
						firstDayOfWeek="7" #d2="ngbDatepicker" (change)="onFormChange()" (dateSelect)="onFormChange()">
				</div>
			</div>
		</div>
	</form>

	<div *ngIf="loading || refreshing" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<div *ngIf="maxMessage !== ''" class="cp-warning-message">{{ maxMessage }}</div>

	<app-marketplace-logs-table></app-marketplace-logs-table>
</div>