<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.licensing" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Key Search
			</li>
			<li *ngIf="activation" class="breadcrumb-item">
				<a href="javascript:void(0)" (click)="onCancel()">{{ activation.key }}</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">Commercial</li>
		</ol>
	</nav>
</div>

<div *ngIf="loading || saving" style="text-align: center;">
	<app-loading-spinner></app-loading-spinner>
</div>

<form *ngIf="theForm" [formGroup]="theForm">
	<div class="cp-general-width-limit">
		<div>
			<label>Commercial Type</label>
			<button type="button" class="btn btn-outline-secondary btn-sm ms-1" data-bs-toggle="modal"
				data-bs-target="#commercialTypesModal">
				<fa-icon [icon]="['fas', 'question-circle']"></fa-icon>
				Info
			</button>
		</div>
		<div class="form-check ms-1 mb-1" *ngFor="let commercialTypeSelection of commercialTypeSelections; let i = index">
			<input class="form-check-input" type="radio" formControlName="commercial_type" name="commercial_type"
				[id]="'commercial_type_' + commercialTypeSelection.value" [value]="commercialTypeSelection.value" />
			<label class="form-check-label" [for]="'commercial_type_' + commercialTypeSelection.value">
				<span *ngIf="i > 0">{{ i }}.</span> {{ commercialTypeSelection.label }}
				<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="commercialTypePopupContent" popoverClass="cp-popover-std"
					triggers="mouseenter:mouseleave" container="body"></fa-icon>
			</label>

			<ng-template #commercialTypePopupContent>
				<div class="cp-popover-banner">{{ (i + 1) }}. {{ commercialTypeSelection.label }}</div>
				<ul class="ps-1 ms-1">
					<li *ngFor="let line of commercialTypeSelection.info.trim().split('\n')" [ngClass]="{ 'ms-4': line.startsWith('\t') }">
						{{ line }}
					</li>
				</ul>
			</ng-template>
		</div>

		<div class="cp-compact-form-group border-top mt-2 pt-2">
			<label for="commercial_info">
				Commercial Notes
				<fa-icon [icon]="['fas', 'question-circle']" placement="end"
					ngbTooltip="Information about commercials.  What is billing based on?  Specific protocols?">
				</fa-icon>
			</label>
			<textarea id="commercial_info" formControlName="commercial_info" class="form-control" rows="10"
				placeholder="Information about commercials"></textarea>
		</div>

		<!-- <div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div> -->
	</div>
	<!-- <div class="cp-form-padding"></div> -->

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>
</form>

<div class="modal" id="commercialTypesModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-lg modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Commercial Types</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeCommercialTypesModal">

				</button>
			</div>
			<div class="modal-body">
				<div class="mb-1" *ngFor="let keyCommercialType of ac.keyCommercialTypes; let i = index">
					<div class="fw-bold">{{ (i + 1) }}. {{ keyCommercialType.label }}</div>
					<ul class="ms-3 ps-2">
						<li *ngFor="let line of keyCommercialType.info.trim().split('\n')" [ngClass]="{ 'ms-4': line.startsWith('\t') }">
							{{ line }}
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>