import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { QuizzesRoutingModule } from './quizzes-routing.module';
import { QuizListComponent } from './quiz-list/quiz-list.component';
import { QuizViewComponent } from './quiz-view/quiz-view.component';
import { QuizFormComponent } from './quiz-form/quiz-form.component';
import { ReorderQuestionsFormComponent } from './reorder-questions-form/reorder-questions-form.component';

@NgModule({
	declarations: [
		QuizListComponent,
		QuizViewComponent,
		QuizFormComponent,
		ReorderQuestionsFormComponent
	],
	imports: [
		QuizzesRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		SharedModule,
		FontAwesome,
		DragDropModule
	]
})
export class QuizzesModule { }
