import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share, map, tap } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class MyPartnersService {
	private parters: Models.PartnerDirectoryItem[] = null;

	constructor(private http: HttpClient) { }

	wipe() {
		this.parters = null;
	}

	async getPartners(forceRefresh = false) {
		if (!this.parters || forceRefresh)
			this.parters = await this.fetchPartners();
		return this.parters.slice();
	}

	async fetchPartners() {
		try {
			const result = await this.http.get<Models.PartnerDirectoryItem[]>(AppConstants.apiUrl + AppConstants.apiUrls.partners).toPromise();
			const arr: Models.PartnerDirectoryItem[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}
}
