import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

import { MyKeysService } from '../my-keys.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { AuthService } from 'client/app/services/auth.service';

@Component({
	selector: 'app-hostid-form',
	templateUrl: './hostid-form.component.html',
	styleUrls: ['./hostid-form.component.scss']
})
export class HostidFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;
	hostids: Models.UserHostId[] = [];

	// id: number;
	key: string;
	hostid: string;

	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private myKeysService: MyKeysService,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService) {

		this.route.paramMap.subscribe(params => {
			this.key = params.get('key');
			this.hostid = params.get('hostid');
		});
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;
			this.initForm();
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async initForm() {
		this.hostids = await this.myKeysService.getUserHostids(true);

		const idx = MiscTools.findIndexGeneric(this.hostids, 'hostid', this.hostid);
		let currentLabel = '';
		if (idx !== -1) currentLabel = this.hostids[idx].label;

		this.theForm = new UntypedFormGroup({
			label: new UntypedFormControl(currentLabel, [Validators.maxLength(255)])
		});
		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const newLabel = this.theForm.value.label.trim();

		try {
			const ret = await this.myKeysService.registerHostid(this.hostid, newLabel);
			if (ret) {
				// this.router.navigate([AppConstants.urls.mykeys + '/' + this.licenseKey.id]);
				this.onCancel();
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	get label() { return this.theForm.get('label'); }

}
