<div *ngIf="itemCount > 0" class="row justify-content-center gx-1 mb-1 pb-1">
	<div class="col-auto">
		<button type="button" class="btn btn-sm btn-outline-info me-1 dropdown-toggle" id="perPageButton" [disabled]="loading"
			data-bs-toggle="dropdown" aria-expanded="false">
			{{ textTools.formatNumber(displayOptions.perPage) }} Per Page
		</button>
		<ul class="dropdown-menu cp-scrollable-menu" aria-labelledby="perPageButton">
			<li *ngFor="let p of ac.perPageSelections; let i = index" class="dropdown-item small text-info cp-pointer"
				(click)="setPerPage(p)" [ngClass]="{ 'border-top': i > 0, 'bg-info text-black': displayOptions.perPage === p }">
				{{ p === -1 ? 'Show Everything' : textTools.formatNumber(p) + ' Per Page' }}
			</li>
		</ul>
	</div>

	<div *ngIf="pagingOptions.pages.length > 0" class="col-auto">
		<nav>
			<ul class="pagination mb-0">
				<li class="page-item" [ngClass]="{ 'disabled': displayOptions.pageNum <= 1 }">
					<a class="page-link" href="javascript:void(0)" (click)="setPageNum(displayOptions.pageNum - 1)" aria-label="Previous">
						<fa-icon [icon]="['fas', 'angle-left']"></fa-icon>
					</a>
				</li>
				<li *ngFor="let p of pagingOptions.pages" [ngClass]="{ 'fw-bolder': p === displayOptions.pageNum, 'disabled': p === -1 }"
					class="page-item">
					<span *ngIf="p === -1" class="page-link">
						<fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
					</span>
					<a *ngIf="p !== -1" class="page-link" href="javascript:void(0)" (click)="setPageNum(p)"
						[ngClass]="{'disabled': pagingOptions.numPages <= 1}">
						{{ p }}
					</a>
				</li>
				<li class="page-item" [ngClass]="{ 'disabled': displayOptions.pageNum >= pagingOptions.numPages }">
					<a class="page-link" href="javascript:void(0)" (click)="setPageNum(displayOptions.pageNum + 1)" aria-label="Next">
						<fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
					</a>
				</li>
			</ul>
		</nav>
	</div>

	<div *ngIf="itemCount > 0" class="col-auto">
		<div [ngClass]="{'border border-info': displayOptions.textFilter && displayOptions.textFilter !== ''}" style="min-width: 200px">
			<div class="input-group">
				<input type="text" [id]="'textFilter' + ranCode" class="form-control form-control-sm" (keyup)="setTextFilter()"
					placeholder="Search" [value]="displayOptions.textFilter"
					[ngClass]="{'bg-info': displayOptions.textFilter && displayOptions.textFilter !== ''}" />
				<button type="button" class="btn btn-close bg-transparent" style="padding-top: 14px; margin-left: -25px; z-index: 100;"
					(click)="resetFilters()" ngbTooltip="Clear Search"
					[hidden]="!displayOptions.textFilter || displayOptions.textFilter === ''">
					<!-- <fa-icon [icon]="['fas', 'trash-alt']" class="text-dark"></fa-icon> -->
				</button>
			</div>
		</div>
	</div>
	<div *ngIf="itemCount > 0" class="col-auto">
		<div class="cp-75 lh-1" [innerHTML]="pagingOptions.pagingInfo"></div>
	</div>
</div>

<div *ngIf="itemCount > 0 && filteredCount === 0" class="text-center my-3">
	<span class="border p-2 m-2">
		No Matches to Filter(s) -
		<a href="javascript:void(0)" (click)="resetFilters()">
			Clear/Reset Filters
		</a>
	</span>
</div>