<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.licensingadmin" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Licensing
			</li>
			<li class="breadcrumb-item">
				<a href="javascript:void(0)" (click)="onCancel()">Properties</a>
			</li>
			<li *ngIf="editMode" class="breadcrumb-item active" aria-current="page">
				Edit Property
			</li>
			<li *ngIf="!editMode" class="breadcrumb-item active" aria-current="page">
				New Property
			</li>
		</ol>
	</nav>
</div>

<form *ngIf="!loading" [formGroup]="theForm" autocomplete="false">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div class="form-group">
			<label for="name">
				Name*
				<fa-icon [icon]="['fas', 'question-circle']" ngbTooltip="Internal name of property stored in database."
					placement="end"></fa-icon>
			</label>
			<input type="text" id="name" formControlName="name" class="form-control" />
		</div>

		<div class="form-group">
			<label for="label">
				Label*
				<fa-icon [icon]="['fas', 'question-circle']"
					ngbTooltip="Label for property that is shown when viewing a key/license via the portal." placement="end"></fa-icon>
			</label>
			<input type="text" id="label" formControlName="label" class="form-control" />
		</div>

		<div class="form-group">
			<label for="ptype">
				Type*
				<fa-icon [icon]="['fas', 'question-circle']"
					ngbTooltip="Type of property - boolean (on/off), number (a value or unlimited), other (special options)"
					placement="end"></fa-icon>
			</label>
			<div class="form-check" *ngFor="let ptype of propTypeSelections">
				<input class="form-check-input" type="radio" formControlName="ptype" name="ptype" [id]="'ptype_' + ptype.value"
					[value]="ptype.value" />
				<label class="form-check-label" [for]="'ptype_' + ptype.value">{{ ptype.label }}</label>
			</div>
		</div>

		<div class="form-group">
			<div class="form-group">
				<label for="description">
					Description
					<fa-icon [icon]="['fas', 'question-circle']"
						ngbTooltip="Extra information about the property to offer some insight into its use." placement="end"></fa-icon>
				</label>
				<textarea id="description" formControlName="description" class="form-control" rows="4"></textarea>
			</div>
		</div>

		<div class="form-group">
			<label for="units">
				Units (for number properties)
				<fa-icon [icon]="['fas', 'question-circle']"
					ngbTooltip="Optional text that will indicate what units the property's value should be." placement="end"></fa-icon>
			</label>
			<input type="text" id="units" formControlName="units" class="form-control" />
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="allow_unlimited" formControlName="allow_unlimited" />
			<label class="form-check-label" for="allow_unlimited">
				Allow unlimited (for number properties)
				<fa-icon [icon]="['fas', 'question-circle']"
					ngbTooltip="For number properties, does the property support having a setting of unlimited." placement="end"></fa-icon>
			</label>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="special_property" formControlName="special_property" />
			<label class="form-check-label" for="special_property">
				Special Property
				<fa-icon [icon]="['fas', 'question-circle']"
					ngbTooltip="If checked, do not show this property with other properties of the same type.  It will be handled separately."
					placement="end"></fa-icon>
			</label>
		</div>

		<div class="form-group">
			<label for="name">
				License Property Name
				<fa-icon [icon]="['fas', 'question-circle']"
					ngbTooltip="Name of property that\'s used in the license file.  Can be different than the Name."
					placement="end"></fa-icon>
			</label>
			<input type="text" id="fulfillment_name" formControlName="fulfillment_name" class="form-control" />
		</div>
		<!-- 
		<div class="form-group mb-2 small">* Required</div>

		<div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div>
		<div class="cp-form-padding"></div> -->

	</div>

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>

</form>