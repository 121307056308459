import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { BackgroundTasksRoutingModule } from './background-tasks-routing.module';
import { SearchTasksComponent } from './search-tasks/search-tasks.component';

@NgModule({
	declarations: [SearchTasksComponent],
	imports: [
		BackgroundTasksRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		SharedModule,
		FontAwesome
	]
})
export class BackgroundTasksModule { }
