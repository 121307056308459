
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';

import { UsersResolverService } from '../users/users-resolver.service';
import { UserGroupsResolverService } from '../users/user-groups-resolver.service';

import { QuizListComponent } from './quiz-list/quiz-list.component';
import { QuizViewComponent } from './quiz-view/quiz-view.component';
import { QuizFormComponent } from './quiz-form/quiz-form.component';
import { ReorderQuestionsFormComponent } from './reorder-questions-form/reorder-questions-form.component';

const routes: Routes = [
	{
		path: '',
		component: QuizListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, UserGroupsResolverService]
	}, {
		path: 'new',
		component: QuizFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, UserGroupsResolverService]
	}, {
		path: ':id',
		component: QuizViewComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, UserGroupsResolverService]
	}, {
		path: ':id/edit',
		component: QuizFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, UserGroupsResolverService]
	}, {
		path: ':id/copy',
		component: QuizFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService, UserGroupsResolverService]
	}, {
		path: ':id/sort',
		component: ReorderQuestionsFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: []
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class QuizzesRoutingModule { }
