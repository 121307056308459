// tslint:disable: no-string-literal

// various utility functions used by both front and back end...
// import { HttpErrorResponse } from '@angular/common/http';
// import { UAParser } from 'ua-parser-js';

import * as Models from './shared-models';
import AppConstants from './app-constants';

import MiscTools from './misc-tools';
import TextTools from './text-tools';
import ValidationTools from './validation-tools';
import SharedLicenseTools from './shared-license-tools';

class LicenseValidationTools {
	// ************************************************************************************************
	static getExpiryMode = (activation: Models.LPActivation) => {
		// figure out this.expireMode
		if (activation.meters && activation.meters.length > 0)
			return 'meter';
		else if (activation.expires_at)
			return 'date';
		else if (!activation.duration || activation.duration === 0)
			return 'never';
		else if (activation.duration && activation.duration !== 0)
			return 'duration';
		return '';
	};

	// ************************************************************************************************
	static getMeterCommTypeWarnings = (activation: Models.LPActivation, checkForCommercialType: boolean = true, checkForLabels: boolean = true, checkForBothTypes: boolean = true): string[] => {
		const now = new Date();
		const expiry = SharedLicenseTools.getKeyExpiration(activation);

		// skip null activations, ones that have expired, ones that aren't enabled and non-broadcaster keys
		if (!activation || (expiry && expiry.getTime() < now.getTime())
			|| activation.enabled === 0
			|| !activation.product.startsWith('broadcaster')
			|| !['production', 'backup'].includes(activation.type)) return [];

		const incorrectMsg = ' This key might need to be adjusted.';

		let warnings: string[] = [];
		if (activation.meters.length !== 0)
			warnings = LicenseValidationTools.getMeterGapWarnings(activation);

		let numAllOutputs = 0;
		let allOutputsMax = 0;
		let allOutputsProj = 0;
		let maxExpAllOutputs: Date = null;

		let numProtected = 0;
		let protectedMax = 0;
		let protectedProj = 0;
		let maxExpProtected: Date = null;

		let numProtocolSetsWithLimit: number = 0;
		for (const linkedSet of activation.protocolSets)
			if (linkedSet.projected > 0)
				numProtocolSetsWithLimit++;

		const meterProducts = [];
		let metersWithLabels: number = 0;
		for (const meter of activation.meters) {
			if (meter.enabled === 1 && !meterProducts.includes(meter.product))
				meterProducts.push(meter.product);

			let starts = new Date(now);
			if (meter.starts_at) starts = new Date(meter.starts_at);
			const meterExp = new Date(meter.expires_at);
			if (meter.enabled === 1) {
				if (meterExp && meterExp.getTime() > now.getTime() && starts.getTime() <= now.getTime()) {
					if (['output_mb', 'output_mb_meter'].includes(meter.product)) {
						numAllOutputs++;
						allOutputsMax += +meter.limit;
						allOutputsProj += +meter.projected;
					} else if (['protected_mb'].includes(meter.product)) {
						numProtected++;
						protectedMax += +meter.limit;
						protectedProj += +meter.projected;
					} // if
				} // if

				if (['output_mb', 'output_mb_meter'].includes(meter.product)) {
					if (maxExpAllOutputs == null || maxExpAllOutputs.getTime() < meterExp.getTime())
						maxExpAllOutputs = meterExp;

				} else if (['protected_mb'].includes(meter.product)) {
					if (maxExpProtected == null || maxExpProtected.getTime() < meterExp.getTime())
						maxExpProtected = meterExp;
				} // if

				if (meter.label && meter.label !== '') metersWithLabels++;
			} // if
		} // for

		if (checkForBothTypes && meterProducts.length > 0 && (numAllOutputs === 0 || numProtected === 0)) {
			// warnings.push('Broadcaster keys should have at least one meter of each type.');

		} else if (meterProducts.length === 2 && maxExpAllOutputs && maxExpProtected && maxExpAllOutputs.getTime() !== maxExpProtected.getTime()) {
			warnings.push('Among the All Output meter(s) for this key, maximum expiration is ' + TextTools.formatDateNiceUTC(maxExpAllOutputs) + '.'
				+ ' Among the Protected meter(s) for this key, maximum expiration is ' + TextTools.formatDateNiceUTC(maxExpProtected) + '.'
				+ ' The key will expire on ' + TextTools.formatDateNiceUTC(expiry) + '.' + incorrectMsg);
		} // if

		if (numAllOutputs > 0 && numProtected > 0) {
			if (allOutputsProj !== 0 && allOutputsProj === protectedProj) {
				warnings.push('Among active meters, both meter types have the same projected limit (' + TextTools.formattedMB(allOutputsProj) + ').' + incorrectMsg);

			} else if (allOutputsMax === protectedMax && allOutputsMax < AppConstants.maxMeterLimit) {
				warnings.push('Among active meters, both meter types have the same maximum limit (' + TextTools.formattedMB(allOutputsMax) + ').' + incorrectMsg);

			} else if (numAllOutputs > 1 && numProtected > 1) {
				warnings.push('Multiple active meters of both types.' + incorrectMsg);

			} else if (allOutputsMax < AppConstants.maxMeterLimit && protectedMax < AppConstants.maxMeterLimit) {
				warnings.push('Among active meters, both meter types have the a maximum limit below ' + TextTools.formattedMB(AppConstants.maxMeterLimit)
					+ ' (' + TextTools.formattedMB(allOutputsMax) + ' & ' + TextTools.formattedMB(protectedMax) + ').' + incorrectMsg);

			} else if (allOutputsProj !== 0 && protectedProj != 0) {
				warnings.push('Among active meters, both meter types have a projected limit (' + TextTools.formattedMB(allOutputsProj) + ' & ' + TextTools.formattedMB(protectedProj) + ').' + incorrectMsg);

			} // if
		} // if

		if (activation.commercial_type && activation.commercial_type !== '') {
			if (activation.commercial_type === 'no-bitcount-billing') {
				// TBD - only show this is template states that comm type is required
				if (['production', 'backup'].includes(activation.type) && (!activation.commercial_info || activation.commercial_info === ''))
					warnings.push('The commercial notes field SHOULD have some information about why the usage with this key isn\'t being billed.');

				// check other meter things max's should be max'd and there shouldn't be any projected amounts...
				if (protectedProj !== 0)
					warnings.push('Based on the commerical type, the PROTECTED meter(s) SHOULD NOT have a projected usage threshold set.');

				if (numProtected > 0 && protectedMax < AppConstants.maxMeterLimit)
					warnings.push('Based on the commerical type, the PROTECTED meter(s) SHOULD NOT have a maximum limit set to anything other than the upper limit (9 ZB).');

				if (allOutputsProj !== 0)
					warnings.push('Based on the commerical type, the ALL OUTPUTS meter(s) SHOULD NOT have a projected usage threshold set.');

				if (numAllOutputs > 0 && allOutputsMax < AppConstants.maxMeterLimit)
					warnings.push('Based on the commerical type, the ALL OUTPUTS meter(s) SHOULD NOT have a maximum limit set to anything other than the upper limit (9 ZB).');

			} else if (activation.commercial_type === 'meters-all-outputs-max-limit') {
				if (numAllOutputs === 0) {
					warnings.push('Based on the commerical type, the key SHOULD have at least one ALL OUTPUTS meter.');
				} else {
					if (allOutputsMax >= AppConstants.maxMeterLimit)
						warnings.push('Based on the commerical type, the ALL OUTPUTS meter(s) SHOULD have a maximum limit set to anything other than the upper limit (9 ZB).');

					if (allOutputsProj !== 0)
						warnings.push('Based on the commerical type, the ALL OUTPUTS meter(s) SHOULD NOT have a projected usage threshold set.');

					if (protectedProj !== 0)
						warnings.push('Based on the commerical type, the PROTECTED meter(s) SHOULD NOT have a projected usage threshold set.');

					if (numProtected > 0 && protectedMax < AppConstants.maxMeterLimit)
						warnings.push('Based on the commerical type, the PROTECTED meter(s) SHOULD NOT have a maximum limit set to anything other than the upper limit (9 ZB).');
				} // if

			} else if (activation.commercial_type === 'meters-protected-max-limit') {
				if (numProtected === 0) {
					warnings.push('Based on the commerical type, the key SHOULD have at least one PROTECTED meter.');
				} else {
					if (protectedMax >= AppConstants.maxMeterLimit)
						warnings.push('Based on the commerical type, the PROTECTED meter(s) SHOULD have a maximum limit set to anything other than the upper limit (9 ZB).');

					if (protectedProj !== 0)
						warnings.push('Based on the commerical type, the PROTECTED meter(s) SHOULD NOT have a projected usage threshold set.');
				} // if

				if (allOutputsProj !== 0)
					warnings.push('Based on the commerical type, the ALL OUTPUTS meter(s) SHOULD NOT have a projected usage threshold set.');

				if (numAllOutputs > 0 && allOutputsMax < AppConstants.maxMeterLimit)
					warnings.push('Based on the commerical type, the ALL OUTPUTS meter(s) SHOULD NOT have a maximum limit set to anything other than the upper limit (9 ZB).');

			} else if (activation.commercial_type === 'meters-all-outputs-proj-limit') {
				if (numAllOutputs === 0) {
					warnings.push('Based on the commerical type, the key SHOULD have at least one ALL OUTPUTS meter.');
				} else {
					if (allOutputsProj === 0)
						warnings.push('Based on the commerical type, the ALL OUTPUTS meter(s) SHOULD have a projected usage threshold set.');

					if (allOutputsMax < AppConstants.maxMeterLimit)
						warnings.push('Based on the commerical type, the ALL OUTPUTS meter(s) SHOULD NOT have a maximum limit set to anything other than the upper limit (9 ZB).');
				} // if

				if (numProtected > 0 && protectedMax < AppConstants.maxMeterLimit)
					warnings.push('Based on the commerical type, the PROTECTED meter(s) SHOULD NOT have a maximum limit set to anything other than the upper limit (9 ZB).');

				if (protectedProj !== 0)
					warnings.push('Based on the commerical type, the PROTECTED meter(s) SHOULD NOT have a projected usage threshold set.');
			} else if (activation.commercial_type === 'meters-protected-proj-limit') {
				if (numProtected === 0) {
					warnings.push('Based on the commerical type, the key SHOULD have at least one PROTECTED meter.');
				} else {
					if (protectedProj === 0)
						warnings.push('Based on the commerical type, the PROTECTED meter(s) SHOULD have a projected usage threshold set.');

					if (protectedMax < AppConstants.maxMeterLimit)
						warnings.push('Based on the commerical type, the PROTECTED meter(s) SHOULD NOT have a maximum limit set to anything other than the upper limit (9 ZB).');
				} // if

				if (numAllOutputs > 0 && allOutputsMax < AppConstants.maxMeterLimit)
					warnings.push('Based on the commerical type, the ALL OUTPUTS meter(s) SHOULD NOT have a maximum limit set to anything other than the upper limit (9 ZB).');

				if (allOutputsProj !== 0)
					warnings.push('Based on the commerical type, the ALL OUTPUTS meter(s) SHOULD NOT have a projected usage threshold set.');
			} else if (activation.commercial_type === 'protocol-data') {
				if (!activation.commercial_info || activation.commercial_info === '')
					warnings.push('The commercial notes field SHOULD have some information about which protocols should be used for billing.');

				if (activation.protocolSets.length === 0)
					warnings.push('The key should be linked to at least one protocol set that indicates which protocols are used for billing.');

				// check other meter things  max's should be max'd and there shouldn't be any projected amounts...
				if (protectedProj !== 0)
					warnings.push('Based on the commerical type, the PROTECTED meter(s) SHOULD NOT have a projected usage threshold set.');

				if (numProtected > 0 && protectedMax < AppConstants.maxMeterLimit)
					warnings.push('Based on the commerical type, the PROTECTED meter(s) SHOULD NOT have a maximum limit set to anything other than the upper limit (9 ZB).');

				if (allOutputsProj !== 0)
					warnings.push('Based on the commerical type, the ALL OUTPUTS meter(s) SHOULD NOT have a projected usage threshold set.');

				if (numAllOutputs > 0 && allOutputsMax < AppConstants.maxMeterLimit)
					warnings.push('Based on the commerical type, the ALL OUTPUTS meter(s) SHOULD NOT have a maximum limit set to anything other than the upper limit (9 ZB).');
			} // if

			if (activation.commercial_type !== 'protocol-data' && numProtocolSetsWithLimit !== 0) {
				warnings.push('Based on the commerical type, the linked protocol set(s) SHOULD NOT have a projected usage threshold set.');
			} // if
		} // if

		// TBD - only bother with warning about commercial type if template says so
		// TBD - only bother with meter label warnings if template says so

		// // check to see if the comm type is set...
		// if (!activation.commercial_type || activation.commercial_type === '') {
		// 	if (checkForCommercialType && ['production', 'backup'].includes(activation.type))
		// 		warnings.push('Production keys should have a commercial type set.');

		// 	// if (checkForLabels && ['production', 'backup'].includes(activation.type) && metersWithLabels < activation.meters.length)
		// 	// 	warnings.push('All meters for Production keys should have labels to indicate their purpose.');
		// } else {
		// 	if (checkForLabels && ['production', 'backup'].includes(activation.type) && metersWithLabels < activation.meters.length
		// 		&& activation.commercial_type.startsWith('meters-'))
		// 		warnings.push('All meters for Production keys should have labels to indicate their purpose.');
		// } // if

		return warnings;
	};

	// ************************************************************************************************
	static getMeterGapWarnings = (activation: Models.LPActivation): string[] => {
		if (!activation || activation.enabled === 0 || activation.meters.length === 0) return [];

		const meterProducts = [];
		for (const meter of activation.meters)
			if (meter.enabled === 1 && !meterProducts.includes(meter.product))
				meterProducts.push(meter.product);

		const warnings: string[] = [];
		const now = new Date();

		for (const product of meterProducts) {
			const gaps: any[] = SharedLicenseTools.findGaps(activation, product, false);

			for (const gap of gaps) {
				const start = new Date(gap['start']);
				const end = new Date(gap['end']);

				let extra = 'will be';
				if (end.getTime() < now.getTime())
					extra = 'was'
				else if (start.getTime() < now.getTime() && end.getTime() > now.getTime())
					extra = 'is'

				warnings.push('Among the ' + SharedLicenseTools.niceProtocol(product, true)
					+ ' meters, there ' + extra + ' a time gap without meter coverage between '
					+ TextTools.formatDateNiceUTC(start, true) + ' and ' + TextTools.formatDateNiceUTC(end, true) + '.');
			} // for
		} // for
		return warnings;
	};

	// ************************************************************************************************
	static getKeyWarnings = (
		activation: Models.LPActivation,
		mode: string,
		expiryDays: number = 0,
		usageWarningPercent: number = 0,
		stripCodes: boolean = true): string[] => {

		if (!expiryDays || expiryDays === 0) expiryDays = AppConstants.keyExpiryWarningDays;
		if (!usageWarningPercent || usageWarningPercent === 0) usageWarningPercent = AppConstants.keyWarningUsageThreshold;

		// !!!! FYI - the wording of these warnings are important
		// The special search filter that looks for keys that are about to expire
		// first gets all keys with the key or one of its meters expiring in the next 30 days
		// and then filters that list based on warnings it has from this function.
		// It looks for the word 'expires' in any of the warnings.

		// could, theorettically add a check to this that looks for gaps
		// coming up in the next 30 days
		// current logic implements that if there's currently an good meter (past start and not expired)
		// then don't show any warnings unless it's close to expiring AND there isn't a later meter
		// it doesn't take into account an upcoming gap...

		/*
		modes
		- user - warnings suitable for a user's view of a key
		- dashboard - warnings approp. for dashboard list
		- staff-all - show warnings regardless of whether key is enforced or not
		- staff-enforced - only show meter warnings if meters are enforced
		*/

		// const keyExpiringKeyword = 'expires';
		// const keyExpiredKeyword = 'expired';
		// const keyWarningUsageKeyword = 'usage limit';
		const keyWarningUsageBeforeEOMKeyword = 'end of the month';

		let includeExpiredMessages = true;
		let includeDisabledMessages = true;
		let includePermanentMessage = true;
		let includeUneforcedMeterWarnings = false;
		let tweakUneforcedMeterWarnings = true;

		if (mode === 'user') {
			includePermanentMessage = false;

		} else if (mode === 'dashboard') {
			includeExpiredMessages = false;
			includeDisabledMessages = false;
			includePermanentMessage = false;

		} else if (mode === 'staff-all') {
			includeUneforcedMeterWarnings = true;

		} else if (mode === 'staff-enforced') {
			// use all defaults

		} // if

		let warnings: string[] = [];
		if (!activation) return [];

		// const keyProperties = parseRubyHash(activation.parameters);
		if (!activation.parsedParameters)
			activation.parsedParameters = SharedLicenseTools.parseRubyHash(activation.parameters);

		if (activation.enabled === 0) {
			if (includeDisabledMessages && !SharedLicenseTools.isSpecialKey(activation))
				warnings.push(AppConstants.keyWarningPrefixDisabled + ':This key has been disabled.');
		} else {
			const enforcedMeters: boolean = !(activation.parsedParameters.dont_enforce_meters && +activation.parsedParameters.dont_enforce_meters === 1);

			const includeMaxUsageMessages: boolean = includeUneforcedMeterWarnings || enforcedMeters;

			const expireMode: string = SharedLicenseTools.getExpiryMode(activation);

			const keyExpiration: Date = SharedLicenseTools.getKeyExpiration(activation);
			const daysSince: number = MiscTools.daysSince(keyExpiration, false);
			const intVal: number = Math.floor(Math.abs(daysSince));

			const now = new Date();
			let numDaysToBaseOn: number = MiscTools.daysInMonth(now.getUTCMonth() + 1, now.getUTCFullYear());

			// if the key expires this month, use the expiry day
			// not including this (for now) - isn't really needed
			// if (keyExpiration.getUTCFullYear() === now.getUTCFullYear() && keyExpiration.getUTCMonth() === now.getUTCMonth())
			// 	numDaysToBaseOn = keyExpiration.getUTCDate();

			const secondsInTimePeriod = numDaysToBaseOn * 24 * 60 * 60;

			const currentSeconds = ((((now.getUTCDate() - 1) * 24) + now.getUTCHours()) * 60 * 60)
				+ (now.getUTCMinutes() * 60) + now.getUTCSeconds();
			const percTime = currentSeconds / secondsInTimePeriod * 100;

			if (expireMode === 'date' && keyExpiration == null && includePermanentMessage)
				warnings.push(AppConstants.keyWarningPrefixPermanent + ':This is a permanent key.');

			let includeTimeInMonth: boolean = false;

			if (keyExpiration != null) {
				if (expireMode === 'date') {
					if (daysSince > 0 && includeExpiredMessages) { // has expired
						warnings.push(AppConstants.keyWarningPrefixKeyExpired + ':Key expired ' + TextTools.niceDaysText(keyExpiration) + '.');
					} else if (intVal <= expiryDays) { // expiring soon
						warnings.push(AppConstants.keyWarningPrefixKeyExpiring + ':Key expires ' + TextTools.niceDaysText(keyExpiration) + '.');
					} // if

				} else if (expireMode === 'meter') {
					if (daysSince > 0 && includeExpiredMessages) { // has expired
						warnings.push(AppConstants.keyWarningPrefixMeterExpired + ':Metering for key expired ' + TextTools.niceDaysText(keyExpiration) + '.');
					} else if (intVal <= expiryDays) { // expiring soon
						warnings.push(AppConstants.keyWarningPrefixMeterExpiring + ':Metering for key expires ' + TextTools.niceDaysText(keyExpiration) + '.');
					} // if

					// if started and not expired, look at meter usage and limits
					if (daysSince < 0) {

						const meterSummaryInfo = SharedLicenseTools.buildMeterSummary(activation.meters);

						const activeMeterTypes: string[] = [];
						for (const product of AppConstants.meterProducts) {
							if (meterSummaryInfo[product + '.never'] || meterSummaryInfo[product + '.monthly']) {
								activeMeterTypes.push(product);
							} // if
						} // for

						for (const product of activeMeterTypes) {
							let usageMsgs: string[] = [];

							let neverTotal: number = 0;
							let neverProjected: number = 0;
							let neverUsed: number = 0;

							if (meterSummaryInfo[product + '.never']) {
								neverTotal = meterSummaryInfo[product + '.never'].limit;
								neverProjected = meterSummaryInfo[product + '.never'].projected;
								neverUsed = meterSummaryInfo[product + '.never'].used;
							} // if

							if (neverTotal > 0) { // has at least one active never-reset meter of this type/product (has started and hasn't expired)
								let meterLabel = 'No-Reset Meter (' + SharedLicenseTools.niceProtocol(product, true) + ')';
								if (!enforcedMeters) meterLabel = 'Unenforced ' + meterLabel;

								if (includeMaxUsageMessages) {
									const usagePerc = (neverUsed / neverTotal) * 100;
									if (usagePerc === 100) {
										usageMsgs.push(AppConstants.keyWarningPrefixMeterMaximum + ':' + meterLabel + ' at (100%) maximum (' + TextTools.formattedMB(neverTotal) + ').');
									} else if (usagePerc > 100) {
										usageMsgs.push(AppConstants.keyWarningPrefixMeterMaximum + ':' + meterLabel + ' over maximum (' + TextTools.formattedMB(neverTotal) + ') ' + AppConstants.bullet + ' '
											+ usagePerc.toFixed(1) + '% (' + TextTools.formattedMB(neverUsed) + ').');
									} else if (usageWarningPercent !== 0 && usagePerc > usageWarningPercent) {
										usageMsgs.push(AppConstants.keyWarningPrefixMeterMaximum + ':' + meterLabel + ' at ' + usagePerc.toFixed(1) + '% (' + TextTools.formattedMB(neverUsed) + ')'
											+ ' of maximum (' + TextTools.formattedMB(neverTotal) + ').');
									} // if
								} // if

								if (neverProjected !== 0) {
									const projPerc = (neverUsed / neverProjected) * 100;
									if (projPerc === 100) {
										usageMsgs.push(AppConstants.keyWarningPrefixMeterProjected + ':' + meterLabel + ' at (100%) projected threshold (' + TextTools.formattedMB(neverProjected) + ').');
									} else if (projPerc > 100) {
										usageMsgs.push(AppConstants.keyWarningPrefixMeterProjected + ':' + meterLabel + ' over projected threshold (' + TextTools.formattedMB(neverProjected) + ') ' + AppConstants.bullet + ' '
											+ projPerc.toFixed(1) + '% (' + TextTools.formattedMB(neverUsed) + ').');
									} else if (usageWarningPercent !== 0 && projPerc > usageWarningPercent) {
										usageMsgs.push(AppConstants.keyWarningPrefixMeterProjected + ':' + meterLabel + ' at ' + projPerc.toFixed(1) + '% (' + TextTools.formattedMB(neverUsed) + ')'
											+ ' of projected threshold (' + TextTools.formattedMB(neverProjected) + ').');
									} // if
								} // if
							} // if

							let monthlyTotal: number = 0;
							let monthlyProjected: number = 0;
							let monthlyUsed: number = 0;
							if (meterSummaryInfo[product + '.monthly']) {
								monthlyTotal = meterSummaryInfo[product + '.monthly'].limit;
								monthlyProjected = meterSummaryInfo[product + '.monthly'].projected;
								monthlyUsed = meterSummaryInfo[product + '.monthly'].used;
							} // if

							if (monthlyTotal > 0) { // has at least one active monthly meter of this type/product (has started and hasn't expired)
								let lastUpdated: Date = null;
								let numActiveNevers: number = 0;
								for (const meter of activation.meters) {
									if (meter.product === product) {
										if (meter.resets === 'monthly' && meter.updated_at) {
											const meterUpdated = new Date(meter.updated_at);
											if (lastUpdated == null || meterUpdated.getTime() > lastUpdated.getTime())
												lastUpdated = meterUpdated;
										} // if

										if (meter.resets === 'never') {
											let starts = new Date(now);
											if (meter.starts_at) starts = new Date(meter.starts_at);
											const exp = new Date(meter.expires_at);
											if (meter.enabled === 1 && exp.getTime() > now.getTime() && starts.getTime() <= now.getTime()) {
												numActiveNevers++;
											} // if
										} // if
									} // if
								} // for

								let lastUpdatedDays: number = -1;
								if (lastUpdated) lastUpdatedDays = MiscTools.daysSince(lastUpdated);

								let meterLabel: string = 'Monthly Meter (' + SharedLicenseTools.niceProtocol(product, true) + ')';
								if (!enforcedMeters) meterLabel = 'Unenforced ' + meterLabel;

								let extraNote: string = '';
								if (neverTotal > 0) {
									if (numActiveNevers === 1)
										extraNote = ' The key also has a ' + SharedLicenseTools.niceProtocol(product, true) + ' meter that never resets'
									else
										extraNote = ' The key also has ' + numActiveNevers + ' ' + SharedLicenseTools.niceProtocol(product, true) + ' meters that never reset'

									if (neverUsed < neverTotal)
										if (numActiveNevers === 1)
											extraNote += ' and it has ' + TextTools.formattedMB(neverTotal - neverUsed) + ' remaining.'
										else
											extraNote += ' and they have ' + TextTools.formattedMB(neverTotal - neverUsed) + ' remaining.'
									else
										if (numActiveNevers === 1)
											extraNote += ' and it is at or over its maximum.'
										else
											extraNote += ' and they are at or over their maximum.'
								} // 

								if (lastUpdatedDays >= 32)
									extraNote += ' This meter may no longer be in use.';

								if (includeMaxUsageMessages) {
									const usagePerc: number = (monthlyUsed / monthlyTotal) * 100;
									if (usagePerc === 100) {
										usageMsgs.push(AppConstants.keyWarningPrefixMeterMaximum + ':' + meterLabel + ' at (100%) maximum (' + TextTools.formattedMB(monthlyTotal) + ').' + extraNote);
									} else if (usagePerc > 100) {
										usageMsgs.push(AppConstants.keyWarningPrefixMeterMaximum + ':' + meterLabel + ' over maximum (' + TextTools.formattedMB(monthlyTotal) + ') ' + AppConstants.bullet + ' '
											+ usagePerc.toFixed(1) + '% (' + TextTools.formattedMB(monthlyUsed) + ').' + extraNote);
									} else if (usageWarningPercent !== 0 && usagePerc > usageWarningPercent) {
										usageMsgs.push(AppConstants.keyWarningPrefixMeterMaximum + ':' + meterLabel + ' at ' + usagePerc.toFixed(1) + '% (' + TextTools.formattedMB(monthlyUsed) + ')'
											+ ' of maximum (' + TextTools.formattedMB(monthlyTotal) + ').' + extraNote);
									} // if

									if (lastUpdatedDays < 32 && now.getUTCDate() >= AppConstants.monthlyMeterFillUpMinDays && usagePerc < 100 && usagePerc > percTime) {
										usageMsgs.push(AppConstants.keyWarningPrefixMeterMaximumEndOfMonth + ':' + meterLabel + ' may reach its maximum before the end of the month.'
											+ ' Meter is at ' + usagePerc.toFixed(1) + '% (' + TextTools.formattedMB(monthlyUsed) + ') of maximum (' + TextTools.formattedMB(monthlyTotal) + ').'
											+ extraNote);
										includeTimeInMonth = true;
									} // if
								} // if

								if (monthlyProjected !== 0) {
									const projPerc: number = (monthlyUsed / monthlyProjected) * 100;
									if (projPerc === 100) {
										usageMsgs.push(AppConstants.keyWarningPrefixMeterProjected + ':' + meterLabel + ' at (100%) projected threshold (' + TextTools.formattedMB(monthlyProjected) + ').');
									} else if (projPerc > 100) {
										usageMsgs.push(AppConstants.keyWarningPrefixMeterProjected + ':' + meterLabel + ' over projected threshold (' + TextTools.formattedMB(monthlyProjected) + ') ' + AppConstants.bullet + ' '

											+ projPerc.toFixed(1) + '% (' + TextTools.formattedMB(monthlyUsed) + ').');
									} else if (usageWarningPercent !== 0 && projPerc > usageWarningPercent) {
										usageMsgs.push(AppConstants.keyWarningPrefixMeterProjected + ':' + meterLabel + ' at ' + projPerc.toFixed(1) + '% (' + TextTools.formattedMB(monthlyUsed) + ')'
											+ ' of projected threshold (' + TextTools.formattedMB(monthlyProjected) + ').');
									} // if

									if (lastUpdatedDays < 32 && now.getUTCDate() >= AppConstants.monthlyMeterFillUpMinDays && projPerc < 100 && projPerc > percTime) {
										usageMsgs.push(AppConstants.keyWarningPrefixMeterProjectedEndOfMonth + ':' + meterLabel + ' may reach/pass its projected threshold before the end of the month.'
											+ ' Meter is at ' + projPerc.toFixed(1) + '% (' + TextTools.formattedMB(monthlyUsed) + ') of projected threshold (' + TextTools.formattedMB(monthlyProjected) + ').');
										includeTimeInMonth = true;
									} // if
								} // if
							} // if

							if (usageMsgs.length > 0) warnings = warnings.concat(usageMsgs);
						} // for

					} // if the key's enabled, has meters and they're enforced
				} // if
			} // if

			// check protocol sets with a projected limit...
			if (activation.protocolSets.length > 0 && (keyExpiration == null || daysSince < 0)) {

				let usageMsgs: string[] = [];
				for (const linkedSet of activation.protocolSets) {
					if (linkedSet.projected && linkedSet.projected > 0) {
						const projPerc: number = (linkedSet.current_usage / linkedSet.projected) * 100;

						let lastUpdatedDays: number = -1;
						if (linkedSet.last_usage_update) lastUpdatedDays = MiscTools.daysSince(linkedSet.last_usage_update);

						if (projPerc === 100) {
							usageMsgs.push(AppConstants.keyWarningPrefixProtocolProjected + ':A linked protocol set is at (100%) projected threshold (' + TextTools.formattedMB(linkedSet.projected) + ').');
						} else if (projPerc > 100) {
							usageMsgs.push(AppConstants.keyWarningPrefixProtocolProjected + ':A linked protocol set is over the projected threshold (' + TextTools.formattedMB(linkedSet.projected) + ') ' + AppConstants.bullet + ' '
								+ projPerc.toFixed(1) + '% (' + TextTools.formattedMB(linkedSet.current_usage) + ').');
						} else if (usageWarningPercent !== 0 && projPerc > usageWarningPercent) {
							usageMsgs.push(AppConstants.keyWarningPrefixProtocolProjected + ':A linked protocol set is at ' + projPerc.toFixed(1) + '% (' + TextTools.formattedMB(linkedSet.current_usage) + ')'
								+ ' of projected threshold (' + TextTools.formattedMB(linkedSet.projected) + ').');
						} // if

						if (lastUpdatedDays < 32 && now.getUTCDate() >= AppConstants.monthlyMeterFillUpMinDays && projPerc < 100 && projPerc > percTime) {
							usageMsgs.push(AppConstants.keyWarningPrefixProtocolProjectedEndOfMonth + ':A linked protocol set may reach/pass its projected threshold before the end of the month.'
								+ ' Usage is at ' + projPerc.toFixed(1) + '% (' + TextTools.formattedMB(linkedSet.current_usage) + ') of projected threshold (' + TextTools.formattedMB(linkedSet.projected) + ').');
							includeTimeInMonth = true;
						} // if
					} // if
				} // for

				if (usageMsgs.length > 0) warnings = warnings.concat(usageMsgs);
			} // if

			if (includeTimeInMonth) warnings.push('Based on time, it is ' + percTime.toFixed(1) + '% through the month.');
		} // if

		warnings = MiscTools.removeDuplicates(warnings);

		if (stripCodes) {
			const stripped: string[] = [];
			for (const warning of warnings)
				stripped.push(TextTools.getValue(warning));
			return stripped;
		} // if

		return warnings;
	};


	/******************************************************************************
	 * checkActivation
	 ******************************************************************************/
	static checkActivation = (activation: Models.LPActivation, template: Models.KeyTemplate,
		product: Models.LicenseProduct, productProperties: Models.LicenseProductProperty[],
		organization: Models.Organization, fixMode: boolean = true, doUserCheck: boolean = false): string[] => {
		// validate everything thorougly...
		const errors: string[] = [];

		if (!product)
			errors.push('Invalid key product object.');

		if (!template)
			errors.push('Invalid key template object.');

		if (!productProperties)
			errors.push('Invalid key product properties object.');

		if (!activation.product || activation.product === '')
			errors.push('Invalid key product.');

		if (template.id !== activation.zcp_template_id)
			errors.push('The key\'s template ID does not match the template\'s ID.');

		if (activation.product !== template.product)
			errors.push('The key\'s product does not match the template\'s product.');

		if (!activation.type || activation.type === '' || !AppConstants.keyTypes.includes(activation.type))
			errors.push('Invalid key type.');

		// bail now...
		// if (errors.length > 0) return errors;

		if (!activation.customer || activation.customer.trim() === '')
			errors.push('The Customer field must have a value.');

		if (!activation.info || activation.info.trim() === '')
			errors.push('The ' + AppConstants.keyInfoFieldLabel + ' field must have a value.');

		if (isNaN(activation.max) || !activation.max || activation.max < 0 || activation.max > AppConstants.maxActivationMax)
			errors.push('The key must have a valid number of activations.');

		const expireMode = SharedLicenseTools.getExpiryMode(activation);
		if (expireMode === '') errors.push('Invalid expiry/duration for key.');

		// clean everything up based on expiry mode
		if (expireMode === 'meter') {
			activation.duration = 0;
			activation.expires_at = null;
		} else if (expireMode === 'date') {
			activation.duration = null;
		} else if (expireMode === 'never') {
			activation.expires_at = null;
		} // if

		let counter = 0;
		for (const meter of activation.meters) {
			counter++;
			const label = 'Meter #' + counter;
			if (!meter.product || meter.product === '')
				errors.push(label + ' - No product/type.');
			else if (product && !product.meterProductsArr.includes(meter.product))
				errors.push(label + ' - Invalid product/type.');

			if (meter.starts_at) meter.starts_at = new Date(meter.starts_at);
			if (meter.expires_at) meter.expires_at = new Date(meter.expires_at);

			if (meter.expires_at == null)
				errors.push(label + ' - Invalid Expiration Date.');
			else if (meter.starts_at && new Date(meter.starts_at).getTime() > new Date(meter.expires_at).getTime())
				errors.push(label + ' - Expiration Date is before the Start Date.');

			if (meter.limit && !isNaN(meter.limit) && +meter.limit > 0) {
				if (meter.limit > AppConstants.maxMeterLimit)
					errors.push(label + ' - Maximum limit cannot be bigger than ' + TextTools.formatNumber(AppConstants.maxMeterLimit) + ' MB.');

				if (meter.projected && !isNaN(meter.projected) && +meter.projected > 0 && +meter.projected >= +meter.limit)
					errors.push(label + ' - Projected usage threshold (' + TextTools.formattedMB(meter.projected)
						+ ') cannot be greater than or equal to the maximum (' + TextTools.formattedMB(meter.limit) + ').');
			} else {
				errors.push(label + ' - Invalid maximum limit (null, zero or less).');
			} // if
		} // for

		// verify against the template...
		if (template.settings.requiresOrganization && (!activation.zcp_org_id || activation.zcp_org_id === 0))
			errors.push('The key must have an Organization.');

		if (organization && ValidationTools.hasFlag(organization, 'no_keys'))
			errors.push('That Organization cannot have license keys.');

		if (activation.id === 0 && template.settings.editOnly)
			errors.push('This Template cannot be used with new keys.');

		if (activation.type && activation.type !== '' && !template.settings.keyTypes.includes(activation.type))
			errors.push('This Template does not support ' + activation.type + ' keys.');

		// checks for whether it's include (direct or part of a group), excluded and/or the right type
		if (organization && !LicenseValidationTools.isOrganizationOkForTemplate(template, organization))
			errors.push('That Organization cannot be used with keys using this Template.');

		if (template.settings.requiresSalesforceOpportunity && (!activation.opportunity_id || activation.opportunity_id === ''))
			errors.push('The key must be linked to a Salesforce Opportunity.');

		if (!isNaN(activation.max) && template.settings.minActivations !== 0 && +activation.max < template.settings.minActivations)
			errors.push('Number of activations must be at least ' + template.settings.minActivations + '.');

		if (!isNaN(activation.max) && template.settings.maxActivations !== 0 && +activation.max > template.settings.maxActivations)
			errors.push('Number of activations (' + activation.max + ') cannot be more than ' + template.settings.maxActivations + '.');

		if (template.settings.requiresCommercialType && (!activation.commercial_type || activation.commercial_type === ''))
			errors.push('The key must have a commercial type set.');

		if (template.settings.expiryModes && template.settings.expiryModes.length > 0 && !template.settings.expiryModes.includes(expireMode))
			errors.push('This Template does not support that expiry mode (' + expireMode + ').');

		let maxExpiryDate: Date;
		if (template.settings.maxExpirationCount && template.settings.maxExpirationCount > 0
			&& template.settings.maxExpirationUnit && template.settings.maxExpirationUnit !== '')
			if (activation.id === 0)
				maxExpiryDate = MiscTools.dateIntervalAdd(new Date(),
					template.settings.maxExpirationCount, template.settings.maxExpirationUnit);
			else
				maxExpiryDate = MiscTools.dateIntervalAdd(activation.created_at,
					template.settings.maxExpirationCount, template.settings.maxExpirationUnit);

		// if (template.settings.maxExpirationCount) console.log('template.settings.maxExpirationCount = ' + template.settings.maxExpirationCount);
		// if (template.settings.maxExpirationUnit) console.log('template.settings.maxExpirationUnit = ' + template.settings.maxExpirationUnit);
		// if (maxExpiryDate) console.log('maxExpiryDate = ' + maxExpiryDate);

		if (expireMode === 'date') {
			let activationExpires: Date;
			if (activation.expires_at) {
				activationExpires = new Date(activation.expires_at);
				if (isNaN(activationExpires.getTime())) activationExpires = null;
			} // if

			if (activationExpires && maxExpiryDate
				&& !isNaN(activationExpires.getTime()) && activationExpires.getTime() > maxExpiryDate.getTime())
				errors.push('The key\'s expiration cannot be later than ' + TextTools.formatDateNiceUTC(maxExpiryDate) + '.');

		} else if (expireMode === 'meter') {
			let counter = 0;
			for (const meter of activation.meters) {
				counter++;
				const label = 'Meter #' + counter;

				let meterExpires: Date;
				if (meter.expires_at) {
					meterExpires = new Date(meter.expires_at);
					if (isNaN(meterExpires.getTime())) meterExpires = null;
				} // if

				// if (meterExpires) console.log(counter + ' meterExpires = ' + meterExpires);

				if (meterExpires && maxExpiryDate
					&& !isNaN(meterExpires.getTime()) && meterExpires.getTime() > maxExpiryDate.getTime())
					errors.push(label + ' - Expiration cannot be later than ' + TextTools.formatDateNiceUTC(maxExpiryDate) + '.');

				if (template.settings.limitMeterProducts && template.settings.limitMeterProducts.length > 0
					&& meter.product && !template.settings.limitMeterProducts.includes(meter.product))
					errors.push(label + ' - This Template does not support that meter type/product (' + meter.product + ').');

				if (template.settings.limitMeterReset && template.settings.limitMeterReset.length > 0
					&& meter.resets && !template.settings.limitMeterReset.includes(meter.resets))
					errors.push(label + ' - This Template does not support that meter reset (' + meter.resets + ').');

				let setLabel: string = meter.label;
				if (template.settings.requiresMeterLabels && (!setLabel || setLabel === null || setLabel.trim() === ''))
					errors.push(label + ' - all meters must have a label.');
			} // for

			if (template.settings.requiredMeterProducts && template.settings.requiredMeterProducts.length !== 0) {
				for (const requiredMeterProduct of template.settings.requiredMeterProducts) {
					let meterCount = 0;
					for (const meter of activation.meters)
						if (meter.product === requiredMeterProduct)
							meterCount++;
					if (meterCount === 0)
						errors.push('This Template requires that the key has at least one '
							+ AppConstants.meterTypeObjects[requiredMeterProduct].label + ' meter.');
				} // for
			} // if
		} // if

		// expect that whatever is calling this has include product and property objects
		let propErrors = false;
		for (const pp of productProperties) {
			if (!pp.product) {
				errors.push('Product property in base array does not have product object. (' + pp.product_id + '/' + pp.property_id + ')');
				propErrors = true;
			} // if
			if (!pp.property) {
				errors.push('Product property in base array does not have property object. (' + pp.product_id + '/' + pp.property_id + ')');
				propErrors = true;
			} // if
		} // if

		// for (const pp of template.settings.productProperties) {
		// 	if (!pp.product) {
		// 		errors.push('Product property in template array does not have product object. (' + pp.product_id + '/' + pp.property_id + ')');
		// 		propErrors = true;
		// 	}
		// 	if (!pp.property) {
		// 		errors.push('Product property in template array does not have property object. (' + pp.product_id + '/' + pp.property_id + ')');
		// 		propErrors = true;
		// 	}
		// }

		// props aren't passed in as .parameters, error
		// TBD - does this need to be done for a key with no options??? receivers???
		// if (!activation.parameters || activation.parameters === '')
		// 	errors.push('The key has an invalid parameter block.');

		// parse and check props-
		const keyProperties = SharedLicenseTools.parseRubyHash(activation.parameters);

		if (!propErrors) {
			let adjustments = 0;
			for (const pp of productProperties) {
				// get the corresponding pp from the template
				const idx = MiscTools.findIndexGenericDouble(template.settings.productProperties,
					'product_id', pp.product_id, 'property_id', pp.property_id);

				let templatePP: Models.LicenseProductProperty;
				if (idx !== -1) templatePP = template.settings.productProperties[idx];

				const label = pp.property.label + ' [' + pp.property.name + ']';

				let canEditBase: boolean = pp.can_be_changed === 1;
				let canEditTemplate: boolean = templatePP && templatePP.can_be_changed === 1;

				// get the prop's default from property
				let defValue: any;
				if (pp.property.ptype === 'boolean') {
					defValue = pp.default_value_num;
				} else if (pp.property.ptype === 'number') {
					if (pp.default_value_text === 'unlimited')
						defValue = pp.default_value_text;
					else
						defValue = pp.default_value_num;
				} else if (pp.property.ptype === 'other') {
					defValue = pp.default_value_text;
				} // if

				// get the prop's default from template if base
				// defintion says it can be edited
				if (canEditBase && templatePP) {
					// if (!canEditTemplate) canEditBase = false;
					if (pp.property.ptype === 'boolean') {
						defValue = templatePP.default_value_num;
					} else if (pp.property.ptype === 'number') {
						if (templatePP.default_value_text === 'unlimited')
							defValue = templatePP.default_value_text;
						else
							defValue = templatePP.default_value_num;
					} else if (pp.property.ptype === 'other') {
						defValue = templatePP.default_value_text;
					} // if
				} // if

				let savedValue = keyProperties[pp.property.name];
				if (savedValue == null || savedValue == undefined) { // if nothing provided, set to default or zero/''
					if (fixMode) {
						savedValue = defValue;
						keyProperties[pp.property.name] = defValue;
						adjustments++;
					} else {
						if (pp.property.ptype === 'boolean' || pp.property.ptype === 'number')
							savedValue = 0;
						else
							savedValue = '';
					}
				} // if

				if (!canEditBase || !canEditTemplate) { // if the prop isn't editable (either from product or template)
					let valid = true;
					if ((pp.property.ptype === 'boolean' && +savedValue !== +defValue)
						|| (pp.property.ptype === 'number' && defValue === 'unlimited' && savedValue !== defValue)
						|| (pp.property.ptype === 'number' && defValue !== 'unlimited' && +savedValue !== +defValue)
						|| (pp.property.ptype === 'other' && +savedValue !== +defValue))
						valid = false;

					if (!valid) {
						if (fixMode) {
							savedValue = defValue;
							keyProperties[pp.property.name] = defValue;
							adjustments++;
						} else {
							errors.push(label + ' cannot have a value of \'' + savedValue + '\' (no-edit/default=' + defValue + ').');
						}
					}

				} else if (canEditBase && canEditTemplate) { // if the property is editable, check the value
					if (pp.property.ptype === 'boolean') {
						if (+savedValue !== 0 && +savedValue !== 1)
							errors.push(label + ' cannot have a value of \'' + savedValue + '\' (boolean).');

					} else if (pp.property.ptype === 'number') {
						if (savedValue === 'unlimited') {
							if (pp.property.allow_unlimited === 0)
								errors.push(label + ' cannot be set to \'unlimited\' (base).');
							if (templatePP && templatePP.allow_unlimited === 0)
								errors.push(label + ' cannot be set to \'unlimited\' (template).');
						} else {
							if (templatePP) {
								if (templatePP.min_value && templatePP.min_value > 0 && +savedValue < templatePP.min_value)
									errors.push(label + ' cannot have a value less than ' + templatePP.min_value + ' (template).');
								if (templatePP.max_value && templatePP.max_value > 0 && +savedValue > templatePP.max_value)
									errors.push(label + ' cannot have a value greater than ' + templatePP.max_value + ' (template).');
							} else {
								if (pp.min_value && pp.min_value > 0 && +savedValue < pp.min_value)
									errors.push(label + ' cannot have a value less than ' + pp.min_value + ' (base).');
								if (pp.max_value && pp.max_value > 0 && +savedValue > pp.max_value)
									errors.push(label + ' cannot have a value greater than ' + pp.max_value + ' (base).');
							}
						}
					} else if (pp.property.ptype === 'other') {
						const otherPropSelections = pp.selections.split(',');
						if (savedValue && savedValue !== '' && !otherPropSelections.includes(savedValue))
							errors.push(label + ' cannot have a value of \'' + savedValue + '\' (other).');
					} // if
				} // if
			} // for

			if (doUserCheck && activation.num_users === 0 && template.settings.requiresLinkedUsers && activation.enabled === 1)
				errors.push('The key must be shared with at least one user.');

			if (adjustments)
				activation.parameters = SharedLicenseTools.encodeRubyHash(productProperties, keyProperties);
		} // if

		return errors;
	};

	/******************************************************************************
	 * isOrganizationOkForTemplate
	 ******************************************************************************/
	static isOrganizationOkForTemplate = (template: Models.KeyTemplate, organization: Models.Organization): boolean => {
		let ok = false;
		if (!ValidationTools.hasFlag(organization, 'no_keys')) {
			ok = true;
			if ((template.settings.limitOrgIDs && template.settings.limitOrgIDs.length > 0)
				|| (template.settings.limitOrgGroupIDs && template.settings.limitOrgGroupIDs.length > 0)) {
				ok = false;

				if (template.settings.limitOrgIDs && template.settings.limitOrgIDs.length > 0
					&& template.settings.limitOrgIDs.includes(organization.id))
					ok = true;

				if (template.settings.limitOrgGroupIDs && template.settings.limitOrgGroupIDs.length > 0)
					for (const limitOrgGroupID of template.settings.limitOrgGroupIDs)
						if (organization.group_ids.includes(limitOrgGroupID))
							ok = true;
			}

			if (template.settings.excludeOrgIDs && template.settings.excludeOrgIDs.length > 0
				&& template.settings.excludeOrgIDs.includes(organization.id))
				ok = false;

			if (template.settings.excludeOrgGroupIDs && template.settings.excludeOrgGroupIDs.length > 0)
				for (const excludeOrgGroupID of template.settings.excludeOrgGroupIDs)
					if (organization.group_ids.includes(excludeOrgGroupID))
						ok = false;

			if (ok && template.settings.orgTypes && template.settings.orgTypes.length > 0
				&& !template.settings.orgTypes.includes(organization.otype))
				ok = false;
		}
		return ok;
	};


	/******************************************************************************
	 * workOutCommercialType
	 ******************************************************************************/
	static workOutCommercialType = (activation: Models.LPActivation): any => {

		const maxToUse = 500000000000000;

		let commericialGuesses: string[] = []; // array???
		let commericialExtras: string = '';

		let numAllOutputs = 0;
		let allOutputsMax = 0;
		let allOutputsProj = 0;

		let numProtected = 0;
		let protectedMax = 0;
		let protectedProj = 0;

		const now = new Date();

		const meterProducts = [];
		for (const meter of activation.meters) {
			if (meter.enabled === 1 && !meterProducts.includes(meter.product))
				meterProducts.push(meter.product);

			let starts = new Date(now);
			if (meter.starts_at) starts = new Date(meter.starts_at);
			const meterExp = new Date(meter.expires_at);
			if (meter.enabled === 1) {
				if (meterExp && meterExp.getTime() > now.getTime() && starts.getTime() <= now.getTime()) {
					if (['output_mb', 'output_mb_meter'].includes(meter.product)) {
						numAllOutputs++;
						allOutputsMax += +meter.limit;
						allOutputsProj += +meter.projected;
					} else if (['protected_mb'].includes(meter.product)) {
						numProtected++;
						protectedMax += +meter.limit;
						protectedProj += +meter.projected;
					} // if
				} // if
			} // if
		} // for

		if (numAllOutputs === 0 && numProtected === 0) {
			commericialGuesses = ['protocol-data', 'no-bitcount-billing'];
			commericialExtras = 'No meters to evaluate';

		} else if (numAllOutputs === 0 && numProtected !== 0) {
			if (protectedProj !== 0)
				commericialGuesses = ['meters-protected-proj-limit'];
			else
				commericialGuesses = ['meters-protected-max-limit'];
			commericialExtras = 'Only has protected meter(s)';

		} else if (numAllOutputs !== 0 && numProtected === 0) {
			if (allOutputsProj !== 0)
				commericialGuesses = ['meters-all-outputs-proj-limit'];
			else
				commericialGuesses = ['meters-all-outputs-max-limit'];
			commericialExtras = 'Only has all-outputs meter(s)';

		} else {
			commericialGuesses = [];
			commericialExtras = 'Has both types of meters';

			if (protectedProj === 0 && allOutputsProj === 0 && protectedMax >= maxToUse && allOutputsMax >= maxToUse) {
				commericialGuesses = ['protocol-data', 'no-bitcount-billing'];
				commericialExtras = 'Has both types of meters, but neither have a maximum set and none have projected limit';

			} else if (allOutputsProj === 0 && allOutputsMax >= maxToUse && protectedProj !== 0 && protectedMax >= maxToUse) {
				commericialGuesses = ['meters-protected-proj-limit'];
				commericialExtras = 'Has both types of meters, but protected has a projected limit, so seems likely';

			} else if (allOutputsProj === 0 && allOutputsMax >= maxToUse && protectedProj === 0 && protectedMax < maxToUse) {
				commericialGuesses = ['meters-protected-max-limit'];
				commericialExtras = 'Has both types of meters, but protected has a maximum, so seems likely';

			} else if (protectedProj === 0 && protectedMax >= maxToUse && allOutputsProj !== 0 && allOutputsMax >= maxToUse) {
				commericialGuesses = ['meters-all-outputs-proj-limit'];
				commericialExtras = 'Has both types of meters, but all-outputs has a projected limit, so seems likely';

			} else if (protectedProj === 0 && protectedMax >= maxToUse && allOutputsProj === 0 && allOutputsMax < maxToUse) {
				commericialGuesses = ['meters-all-outputs-max-limit'];
				commericialExtras = 'Has both types of meters, but all-outputs has a maximum, so seems likely';

			} // if
		} // if
		return { commericialGuesses, commericialExtras };
	};
}

export default LicenseValidationTools;
