import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';

import { ZenCustomersListComponent } from './zen-customers-list/zen-customers-list.component';
import { ZenCustomerComponent } from './zen-customer/zen-customer.component';
import { ZenCustomersReportsComponent } from './zen-customers-reports/zen-customers-reports.component';
import { ZenCustomersReportUsersComponent } from './zen-customers-report-users/zen-customers-report-users.component';
import { ZenCustomersReportClustersComponent } from './zen-customers-report-clusters/zen-customers-report-clusters.component';
import { ZenCustomersReportSourcesComponent } from './zen-customers-report-sources/zen-customers-report-sources.component';
import { ZenCustomersReportTargetsComponent } from './zen-customers-report-targets/zen-customers-report-targets.component';
import { ZenCustomersReportChannelsComponent } from './zen-customers-report-channels/zen-customers-report-channels.component';
import { ZenCustomersReportStaffComponent } from './zen-customers-report-staff/zen-customers-report-staff.component';
import { ZenCustomersReportObjectsComponent } from './zen-customers-report-objects/zen-customers-report-objects.component';
import { ZenmasterFormComponent } from './zenmaster-form/zenmaster-form.component';
import { ZenmasterAdminFormComponent } from './zenmaster-admin-form/zenmaster-admin-form.component';
import { ZenmasterAddFormComponent } from './zenmaster-add-form/zenmaster-add-form.component';
import { SearchIncidentsComponent } from './search-incidents/search-incidents.component';
import { ZenmasterMarketplaceFormComponent } from './zenmaster-marketplace-form/zenmaster-marketplace-form.component';

import { OrganizationsResolverService } from '../organizations/organizations-resolver.service';
import { UsersResolverService } from '../users/users-resolver.service';
import { ZenmasterResolverService } from './zenmaster-resolver.service';
import { ProductsResolverService } from '../products/products-resolver.service';
import { BuildsResolverService } from '../builds/builds-resolver.service';
import { PlatformsResolverService } from '../platforms/platforms-resolver.service';

const routes: Routes = [
	{
		path: '',
		component: ZenCustomersListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, ZenmasterResolverService, UsersResolverService, ProductsResolverService]
	},
	{
		path: 'incidents',
		component: SearchIncidentsComponent,
		canActivate: [RoleGuard],
		resolve: [OrganizationsResolverService, UsersResolverService, ZenmasterResolverService]
	},
	{
		path: 'reports',
		component: ZenCustomersReportsComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: []
	},
	{
		path: 'reports/users',
		component: ZenCustomersReportUsersComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, ZenmasterResolverService, UsersResolverService, ProductsResolverService]
	},
	{
		path: 'reports/clusters',
		component: ZenCustomersReportClustersComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, ZenmasterResolverService]
	},
	{
		path: 'reports/objects',
		component: ZenCustomersReportObjectsComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, ZenmasterResolverService]
	},
	{
		path: 'reports/sources',
		component: ZenCustomersReportSourcesComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, ZenmasterResolverService]
	},
	{
		path: 'reports/targets',
		component: ZenCustomersReportTargetsComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, ZenmasterResolverService]
	},
	{
		path: 'reports/channels',
		component: ZenCustomersReportChannelsComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, ZenmasterResolverService]
	},
	{
		path: 'reports/staff',
		component: ZenCustomersReportStaffComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, ZenmasterResolverService, UsersResolverService, ProductsResolverService]
	},
	{
		path: 'new',
		component: ZenmasterAddFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService, ZenmasterResolverService]
	},
	{
		path: ':id/admin',
		component: ZenmasterAdminFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService, ZenmasterResolverService]
	},
	{
		path: ':id/marketplace',
		component: ZenmasterMarketplaceFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [ZenmasterResolverService]
	},
	{
		path: ':id/edit',
		component: ZenmasterFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService, ZenmasterResolverService]
	},
	{
		path: ':id',
		component: ZenCustomerComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService, ZenmasterResolverService, ProductsResolverService, BuildsResolverService, PlatformsResolverService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ZenCustomersRoutingModule { }
