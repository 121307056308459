import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { PackagesRoutingModule } from './packages-routing.module';
import { PackageComponent } from './package/package.component';
import { PackageListComponent } from './package-list/package-list.component';
import { PackageFormComponent } from './package-form/package-form.component';
import { PackageKeyTemplateFormComponent } from './package-key-template-form/package-key-template-form.component';

@NgModule({
	declarations: [PackageComponent, PackageListComponent, PackageFormComponent, PackageKeyTemplateFormComponent],
	imports: [
		PackagesRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		SharedModule,
		FontAwesome,
		ClipboardModule
	]
})
export class PackagesModule { }
