import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import PopOverTools from 'appshared/popover-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';
import LicenseValidationTools from 'appshared/license-validation-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { LicensingService } from '../../licensing/licensing.service';
import { LicensingAdminService } from '../licensing-admin.service';
import { AdminLogsService } from '../../admin-logs/admin-logs.service';
import { UsersService } from '../../users/users.service';
import { ProductsService } from '../../products/products.service';

import { LogsTableComponent } from 'client/app/components/shared/logs-table/logs-table.component';
import { KeysTableComponent } from 'client/app/components/shared/keys-table/keys-table.component';
import { PopupBoxComponent } from 'client/app/components/shared/popup-box/popup-box.component';

@Component({
	selector: 'app-protocol-set',
	templateUrl: './protocol-set.component.html',
	styleUrls: ['./protocol-set.component.scss']
})

export class ProtocolSetComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	popOverTools = PopOverTools;
	textTools = TextTools;
	miscTools = MiscTools;
	sharedLicenseTools = SharedLicenseTools;

	@ViewChild('logsTable1') logsTable1: LogsTableComponent = null;
	@ViewChild('keysTable1') keysTable1: KeysTableComponent = null;
	@ViewChild(PopupBoxComponent) popupBox: PopupBoxComponent = null;

	loading = true;
	// showPostLoadElements = false;

	// 'standard' view stuff
	authUser: Models.AuthUser;
	id: number;
	protocolSet: Models.ProtocolSet;

	// other stuff
	private userSubscription: Subscription;

	adminLogs: Models.AdminLog[] = [];
	addedByUser: Models.User = null;
	editedByUser: Models.User = null;

	keys: Models.LPActivation[] = [];
	keyWarnings = {};
	keyExpiries = {};
	nonEnforcedWarnings = {};
	keyMeterWarnings = {};
	expandWarnings = false;
	keyProducts: Models.LicenseProduct[] = [];

	canManage = false;

	protocolBlocks: any[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private productsService: ProductsService,
		private licensingService: LicensingService,
		private licensingAdminService: LicensingAdminService,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private usersService: UsersService,
		private adminLogsService: AdminLogsService
	) {

		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.userSubscription = this.authService.user.subscribe((authUser) => {
				this.authUser = authUser;
				this.canManage = (authUser && ValidationTools.checkAccess(authUser, 'manage-protocol-sets'));

				this.loadData();
			});
		});
	}

	ngOnInit(): void {
		// changes

		const expandWarnings = localStorage.getItem('cp-protocolset.keys.expandWarnings');
		this.expandWarnings = (expandWarnings && expandWarnings === 'true');
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData() {
		this.loading = true;

		this.protocolSet = await this.licensingAdminService.getProtocolSet(this.id, false);
		if (!this.protocolSet || this.protocolSet == null || this.protocolSet.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
		}

		const protocols: string[] = MiscTools.deepClone(this.protocolSet.protocolsArr);
		protocols.sort(SharedLicenseTools.protocolSort);

		const bxProtocols: string[] = [];
		const privProtocols: string[] = [];
		const transcodeProtocols: string[] = [];
		const mcProtocols: string[] = [];
		const zmProtocols: string[] = [];

		for (const protocol of protocols) {
			if (protocol.startsWith('mediaconnect_')) {
				mcProtocols.push(SharedLicenseTools.niceProtocol(protocol, false));
			} else if (protocol.startsWith('zm_')) {
				zmProtocols.push(SharedLicenseTools.niceProtocol(protocol, false));
			} else {
				if (protocol.includes('transcode'))
					transcodeProtocols.push(SharedLicenseTools.niceProtocol(protocol, false));
				else if (protocol.startsWith('private_'))
					privProtocols.push(SharedLicenseTools.niceProtocol(protocol, false));
				else
					bxProtocols.push(SharedLicenseTools.niceProtocol(protocol, false));
			} // if
		} // for

		if (bxProtocols.length > 0)
			this.protocolBlocks.push({
				label: 'Broadcaster - Non-Private',
				protocols: bxProtocols
			});

		if (privProtocols.length > 0)
			this.protocolBlocks.push({
				label: 'Broadcaster - Private',
				protocols: privProtocols
			});

		if (transcodeProtocols.length > 0)
			this.protocolBlocks.push({
				label: 'Broadcaster - Transcode',
				protocols: transcodeProtocols
			});

		if (mcProtocols.length > 0)
			this.protocolBlocks.push({
				label: 'ZEN Master Tracking - MediaConnect',
				protocols: mcProtocols
			});

		if (zmProtocols.length > 0)
			this.protocolBlocks.push({
				label: 'ZEN Master Tracking - Non-MediaConnect',
				protocols: zmProtocols
			});

		this.addedByUser = null;
		if (this.protocolSet.added_by && this.protocolSet.added_by !== 0)
			this.addedByUser = this.usersService.getOne(this.protocolSet.added_by);

		this.editedByUser = null;
		if (this.protocolSet.edited_by && this.protocolSet.edited_by !== 0)
			this.editedByUser = this.usersService.getOne(this.protocolSet.edited_by);

		this.adminLogs = await this.adminLogsService.getLogs(['protocol-set'], this.id);

		// get keys....
		this.keys = [];
		if (this.protocolSet.num_keys > 0) {
			this.keyProducts = await this.licensingAdminService.getProducts();

			const searchSettings: Models.KeySearchSettings = new Models.KeySearchSettings();
			searchSettings.protocolSetFilter = [this.id];
			const tempKeys = await this.licensingService.searchKeys(SharedLicenseTools.buildKeySearchArgs(searchSettings));

			for (const item of tempKeys) {
				item['__niceProduct'] = this.niceKeyProduct(item.product);
				item['__popover'] = PopOverTools.getKeyPopoverLines(item, this.keyProducts, [], [], false).join('\n');
				this.keys.push(item);
			} // for
		} // if

		this.loading = false;

		await MiscTools.delay(100);

		if (this.keysTable1) {
			if (this.expandWarnings && !this.keysTable1.expandWarnings) this.keysTable1.toggleWarnings();

			this.keysTable1.updateContent(this.keys, 'cp-view-pset',
				{
					staffMode: true,
					addPopovers: true,

					showStaffDelete: false,
					showEnabled: true,
					showUserLabel: false,
					showInfo: true,
					showOrganization: true,
					showFullProduct: false,
					showFullType: false,
					showExpandedActivations: true,
					showNumUsers: true,
					showActiveCount: false,
					showMeterIcon: true,
					showProtocolIcon: true,
					showSnoozed: false,
					showLastTouched: false,
					showSalesEngineer: false,
					showCommercialType: true,
					showMeterIrregularities: true
				});
		} // if


		if (this.logsTable1)
			this.logsTable1.updateContent(this.adminLogs, 'cp-protocol-set-view-admin-logs', { showUserInfo: true, showObjectInfo: false, linkObject: false, linkUser: true });

	}

	// ------------------------------------------------------------------------
	prepDelete() {
		this.popupBox.openPopup('confirm-text', 'delete', [], 'Delete Protocol Set',
			'If you delete this Protocol Set, it will be permanently deleted and cannot be recovered.',
			null,
			{ confirmButtonText: 'Delete Protocol Set', rejectButtonText: 'Cancel', confirmText: 'delete' });
	}

	// ------------------------------------------------------------------------
	async delete() {
		this.loading = true;

		const result = await this.licensingAdminService.deleteProtocolSet(this.protocolSet.id);
		if (result) {
			this.uiAlertsService.addMsg('The protocol set (' + this.protocolSet.name + ') has been deleted.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.router.navigate([AppConstants.urls.licensingadmin, 'protocol-sets']);
		} else {
			return false;
		}
	}

	// ************************************************************************************************************************
	toggleWarnings() {
		this.expandWarnings = !this.expandWarnings;
		if (this.keysTable1) this.keysTable1.toggleWarnings();
		localStorage.setItem('cp-protocolset.keys.expandWarnings', this.expandWarnings.toString());
	}

	// ************************************************************************************************************************
	niceKeyProduct(product: string) {
		const idx = MiscTools.findIndexGeneric(this.keyProducts, 'name', product);
		if (idx === -1)
			return product;
		else
			return this.keyProducts[idx].label;
	}

	// ************************************************************************************************************************
	getColumnSubList(column: number, protocols: string[]) {
		let sublist: string[] = [];

		const cuttoff: number = 5;

		// console.log('-------');
		// console.log('protocols=' + protocols.length);

		let col1: number = -1;
		let col2: number = -1;
		let col3: number = -1;

		if (protocols.length <= cuttoff) { // one column
			col1 = protocols.length;
		} else if (protocols.length <= (cuttoff * 2)) { // two columns
			const per = Math.floor(protocols.length / 2);
			console.log('2 per=' + per);
			col1 = per + (protocols.length % 2);
			col2 = col1 + per;
		} else { // three columns
			const per = Math.floor(protocols.length / 3);
			col1 = per + (protocols.length % 3);
			col2 = col1 + per;
			col3 = col2 + per;
		} // if
		// console.log('column=' + column + ' col1=' + col1 + ' col2=' + col2 + ' col3=' + col3);

		if (column === 1 && col1 > 0) {
			sublist = protocols.slice(0, col1);
		} else if (column === 2 && col2 > 0) {
			sublist = protocols.slice(col1, col2);
		} else if (column === 3 && col3 > 0) {
			sublist = protocols.slice(col2, protocols.length);
		} // if
		// console.log('sublist=' + sublist.length);


		return sublist;
	}

	// ------------------------------------------------------------------------
	// Back and Forth with components
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			popupCallBack: async (callBack: string, args: any) => {
				if (callBack === 'delete' && args.length === 0)
					this.delete();
				else if (callBack === 'XXXXX' && args.length === 1)
					console.log('args=' + args);

				else
					this.uiAlertsService.addMsg('Unknown callBack (' + callBack + ') or bad number of arguments (' + args.length + ').', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		}
	} // getParentMethod
}
