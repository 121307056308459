import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class UnsubscribeService {

	constructor(private http: HttpClient) { }

	async checkUnsubCode(code: string): Promise<boolean> {
		try {
			const result = await this.http.get<any>(AppConstants.apiUrl + AppConstants.apiUrls.auth
				+ AppConstants.apiUrls.unsubscribe + '/' + encodeURIComponent(code)).toPromise();
			const returnObj: any = result;
			if (returnObj && returnObj.result && returnObj.result === true)
				return true;
			else
				return false;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async unsubscribe(code: string): Promise<boolean> {
		try {
			const result = await this.http.post<any>(AppConstants.apiUrl + AppConstants.apiUrls.auth
				+ AppConstants.apiUrls.unsubscribe, { code }).toPromise();

			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

}
