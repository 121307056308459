<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.users" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" (click)="onCancel()">Users</a>
			</li>
			<li class="breadcrumb-item">
				{{ user.name }}
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				Notifications
			</li>
		</ol>
	</nav>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-user-tab"
				aria-controls="nav-user" data-bs-target="#nav-user">
				<fa-icon [icon]="['fas', 'check-square']"></fa-icon>
				Types/Options
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-time-tab"
				aria-controls="nav-time" data-bs-target="#nav-time">
				<fa-icon [icon]="['fas', 'clock']">
				</fa-icon>
				Timing
			</button>
		</li>

		<li *ngIf="showStaffSettings" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-staff-tab"
				aria-controls="nav-staff" data-bs-target="#nav-staff">
				<fa-icon [icon]="['fas', 'user-graduate']">
				</fa-icon>
				Staff Settings
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<form *ngIf="theForm && !loading" [formGroup]="theForm" class="tab-form">
		<div class="tab-content" id="nav-tabContent">
			<div class="tab-pane show active" role="tabpanel" id="nav-user" aria-labelledby="nav-user-tab">

				<div class="cp-general-width-limit">
					<div class="cp-compact-form-group form-check pb-2 border-bottom">
						<input type="checkbox" class="form-check-input" id="autoSubscribe" formControlName="autoSubscribe" />
						<label class="form-check-label" for="autoSubscribe">
							{{ ac.notificationPropLabels.autoSubscribe }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="autoSubscribePopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #autoSubscribePopupContent>
						<div class="cp-popover-banner">{{ ac.notificationPropLabels.autoSubscribe }}</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.autoSubscribe }}</div>
					</ng-template>

					<!-- ************************************************ -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveGenerallMessages"
							formControlName="receiveGenerallMessages" />
						<label class="form-check-label" for="receiveGenerallMessages">
							{{ ac.notificationPropLabels.receiveGenerallMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveGenerallMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #receiveGenerallMessagesPopupContent>
						<div class="cp-popover-banner">{{ ac.notificationPropLabels.receiveGenerallMessages }}
						</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.receiveGenerallMessages }}</div>
					</ng-template>

					<!-- ************************************************ -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveBuildMessages" formControlName="receiveBuildMessages" />
						<label class="form-check-label" for="receiveBuildMessages">
							{{ ac.notificationPropLabels.receiveBuildMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveBuildMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
						<button *ngIf="!showBuildFilters" [disabled]="!receiveBuildMessages.value" type="button"
							class="btn btn-outline-secondary btn-sm ms-1" (click)="showBuildFilters = true" placement="bottom-start"
							ngbTooltip="Optional - if you want to choose specific products to receive notifications for or to exclude specific products">
							<fa-icon icon="filter" size="sm"></fa-icon>
							<!-- Set Filters -->
						</button>
					</div>

					<ng-template #receiveBuildMessagesPopupContent>
						<div class="cp-popover-banner">{{ ac.notificationPropLabels.receiveBuildMessages }}
						</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.receiveBuildMessages }}</div>
					</ng-template>

					<div *ngIf="showBuildFilters && receiveBuildMessages.value && buildProductSelections.length > 0"
						class="cp-compact-form-group ms-4">
						<label for="includedBuildProductIDs">
							{{ ac.notificationPropLabels.includedBuildProductIDs }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="includedBuildProductIDsPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
						<ng-select [items]="buildProductSelections" bindValue="value" bindLabel="label" id="includedBuildProductIDs"
							formControlName="includedBuildProductIDs" [multiple]="true" [closeOnSelect]="false"
							placeholder="Optional - otherwise get notified for all products">
						</ng-select>
					</div>

					<ng-template #includedBuildProductIDsPopupContent>
						<div class="cp-popover-banner">{{ ac.notificationPropLabels.includedBuildProductIDs }}
						</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.includedBuildProductIDs }}</div>
					</ng-template>

					<div *ngIf="showBuildFilters && receiveBuildMessages.value && buildProductSelections.length > 0"
						class="cp-compact-form-group ms-4">
						<label for="excludedBuildProductIDs">
							{{ ac.notificationPropLabels.excludedBuildProductIDs }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="excludedBuildProductIDsPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
						<ng-select [items]="buildProductSelections" bindValue="value" bindLabel="label" id="excludedBuildProductIDs"
							formControlName="excludedBuildProductIDs" [multiple]="true" [closeOnSelect]="false"
							placeholder="Optional - otherwise get notified for all products">
						</ng-select>
					</div>

					<ng-template #excludedBuildProductIDsPopupContent>
						<div class="cp-popover-banner">{{ ac.notificationPropLabels.excludedBuildProductIDs }}
						</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.excludedBuildProductIDs }}</div>
					</ng-template>

					<!-- ************************************************ -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveDocumentMessages"
							formControlName="receiveDocumentMessages" />
						<label class="form-check-label" for="receiveDocumentMessages">
							{{ ac.notificationPropLabels.receiveDocumentMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveDocumentMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
						<button *ngIf="!showDocumentFilters" [disabled]="!receiveDocumentMessages.value" type="button"
							class="btn btn-outline-secondary btn-sm ms-1" (click)="showDocumentFilters = true" placement="bottom-start"
							ngbTooltip="Optional - if you want to choose specific products to receive notifications for or to exclude specific products">
							<fa-icon icon="filter" size="sm"></fa-icon>
							<!-- Set Filters -->
						</button>
					</div>

					<ng-template #receiveDocumentMessagesPopupContent>
						<div class="cp-popover-banner">{{ ac.notificationPropLabels.receiveDocumentMessages }}
						</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.receiveDocumentMessages }}</div>
					</ng-template>

					<div *ngIf="showDocumentFilters && receiveDocumentMessages.value && docProductSelections.length > 0"
						class="cp-compact-form-group ms-4">
						<label for="includedDocumentProductIDs">
							{{ ac.notificationPropLabels.includedDocumentProductIDs }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="includedDocumentProductIDsPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
						<ng-select [items]="docProductSelections" bindValue="value" bindLabel="label" id="includedDocumentProductIDs"
							formControlName="includedDocumentProductIDs" [multiple]="true" [closeOnSelect]="false"
							placeholder="Optional - otherwise get notified for all products">
						</ng-select>
					</div>

					<ng-template #includedDocumentProductIDsPopupContent>
						<div class="cp-popover-banner">{{ ac.notificationPropLabels.includedDocumentProductIDs
							}}
						</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.includedDocumentProductIDs }}
						</div>
					</ng-template>

					<div *ngIf="showDocumentFilters && receiveDocumentMessages.value && docProductSelections.length > 0"
						class="cp-compact-form-group ms-4">
						<label for="excludedDocumentProductIDs">
							{{ ac.notificationPropLabels.excludedDocumentProductIDs }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="excludedDocumentProductIDsPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
						<ng-select [items]="docProductSelections" bindValue="value" bindLabel="label" id="excludedDocumentProductIDs"
							formControlName="excludedDocumentProductIDs" [multiple]="true" [closeOnSelect]="false"
							placeholder="Optional - otherwise get notified for all products">
						</ng-select>
					</div>

					<ng-template #excludedDocumentProductIDsPopupContent>
						<div class="cp-popover-banner">{{ ac.notificationPropLabels.excludedDocumentProductIDs
							}}
						</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.excludedDocumentProductIDs }}
						</div>
					</ng-template>

					<!-- ************************************************ -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveKeyExpirationMessages"
							formControlName="receiveKeyExpirationMessages" />
						<label class="form-check-label" for="receiveKeyExpirationMessages">
							{{ ac.notificationPropLabels.receiveKeyExpirationMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveKeyExpirationMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #receiveKeyExpirationMessagesPopupContent>
						<div class="cp-popover-banner">{{
							ac.notificationPropLabels.receiveKeyExpirationMessages
							}}</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.receiveKeyExpirationMessages }}
						</div>
					</ng-template>

					<div *ngIf="receiveKeyExpirationMessages.value" class="cp-compact-form-group ms-4">
						<ng-select [items]="expirySelections" bindValue="value" bindLabel="label" id="expirationDays"
							formControlName="expirationDays" [clearable]="false">
						</ng-select>
					</div>

					<!-- ************************************************ -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveKeyUsageMessages"
							formControlName="receiveKeyUsageMessages" />
						<label class="form-check-label" for="receiveKeyUsageMessages">
							{{ ac.notificationPropLabels.receiveKeyUsageMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveKeyUsageMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #receiveKeyUsageMessagesPopupContent>
						<div class="cp-popover-banner">{{ ac.notificationPropLabels.receiveKeyUsageMessages }}
						</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.receiveKeyUsageMessages }}</div>
					</ng-template>

					<div *ngIf="receiveKeyUsageMessages.value" class="cp-compact-form-group ms-4">
						<ng-select [items]="usageSelections" bindValue="value" bindLabel="label" id="usagePercentage"
							formControlName="usagePercentage" [clearable]="false">
						</ng-select>
					</div>

					<!-- ************************************************ -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveProjectedKeyUsageMessages"
							formControlName="receiveProjectedKeyUsageMessages" />
						<label class="form-check-label" for="receiveProjectedKeyUsageMessages">
							{{ ac.notificationPropLabels.receiveProjectedKeyUsageMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveProjectedKeyUsageMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #receiveProjectedKeyUsageMessagesPopupContent>
						<div class="cp-popover-banner">{{
							ac.notificationPropLabels.receiveProjectedKeyUsageMessages }}
						</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.receiveProjectedKeyUsageMessages
							}}</div>
					</ng-template>

					<div *ngIf="receiveProjectedKeyUsageMessages.value" class="cp-compact-form-group ms-4">
						<ng-select [items]="usageSelections" bindValue="value" bindLabel="label" id="projectedPercentage"
							formControlName="projectedPercentage" [clearable]="false">
						</ng-select>
					</div>

					<!-- ************************************************ -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveProtocolKeyUsageMessages"
							formControlName="receiveProtocolKeyUsageMessages" />
						<label class="form-check-label" for="receiveProtocolKeyUsageMessages">
							{{ ac.notificationPropLabels.receiveProtocolKeyUsageMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveProtocolKeyUsageMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #receiveProtocolKeyUsageMessagesPopupContent>
						<div class="cp-popover-banner">{{
							ac.notificationPropLabels.receiveProtocolKeyUsageMessages }}
						</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.receiveProtocolKeyUsageMessages
							}}</div>
					</ng-template>

					<div *ngIf="receiveProtocolKeyUsageMessages.value" class="cp-compact-form-group ms-4">
						<ng-select [items]="usageSelections" bindValue="value" bindLabel="label" id="protocolPercentage"
							formControlName="protocolPercentage" [clearable]="false">
						</ng-select>
					</div>

					<!-- ************************************************ -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveOfflineHostIDsMessages"
							formControlName="receiveOfflineHostIDsMessages" />
						<label class="form-check-label" for="receiveOfflineHostIDsMessages">
							{{ ac.notificationPropLabels.receiveOfflineHostIDsMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveOfflineHostIDsMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #receiveOfflineHostIDsMessagesPopupContent>
						<div class="cp-popover-banner">{{
							ac.notificationPropLabels.receiveOfflineHostIDsMessages }}
						</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.receiveOfflineHostIDsMessages }}
						</div>
					</ng-template>

					<!-- ************************************************ -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveKeysReportMessages"
							formControlName="receiveKeysReportMessages" />
						<label class="form-check-label" for="receiveKeysReportMessages">
							{{ ac.notificationPropLabels.receiveKeysReportMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveKeysReportMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #receiveKeysReportMessagesPopupContent>
						<div class="cp-popover-banner">{{
							ac.notificationPropLabels.receiveKeysReportMessages }}
						</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.receiveKeysReportMessages
							}}</div>
					</ng-template>

					<div *ngIf="receiveKeysReportMessages.value" class="cp-compact-form-group ms-4">
						<label class="form-check-label" for="keysReportFrequency">
							{{ ac.notificationPropLabels.keysReportFrequency }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="keysReportFrequencyPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>

						<ng-select [items]="ac.keysReportFrequencySelections" bindValue="value" bindLabel="label" id="keysReportFrequency"
							formControlName="keysReportFrequency" [clearable]="false">
						</ng-select>
					</div>

					<ng-template #keysReportFrequencyPopupContent>
						<div class="cp-popover-banner">{{
							ac.notificationPropLabels.keysReportFrequency }}
						</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.keysReportFrequency
							}}</div>
					</ng-template>

				</div>
				<!-- <div class="cp-form-padding"></div> -->
			</div>

			<div class="tab-pane" role="tabpanel" id="nav-time" aria-labelledby="nav-time-tab">

				<div class="cp-general-width-limit">
					<div class="cp-compact-form-group">
						<label for="name">
							{{ ac.notificationPropLabels.deliveryMode }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="deliveryModePopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
						<div class="form-check" *ngFor="let notificationDeliveryMode of ac.notificationDeliveryModes">
							<input class="form-check-input" type="radio" formControlName="deliveryMode" name="deliveryMode"
								[id]="'deliveryMode_' + notificationDeliveryMode.value" [value]="notificationDeliveryMode.value" />
							<label class="form-check-label" [for]="'deliveryMode_' + notificationDeliveryMode.value">
								{{ notificationDeliveryMode.label }}
							</label>
						</div>
					</div>

					<div *ngIf="deliveryMode.value === 'window'" class="cp-compact-form-group ms-4">
						<ng-select [items]="startHourSelections" bindValue="value" bindLabel="label" id="startHour"
							formControlName="startHour" [clearable]="false">
						</ng-select>
					</div>

					<div *ngIf="deliveryMode.value === 'window'" class="cp-compact-form-group ms-4">
						<ng-select [items]="endHourSelections" bindValue="value" bindLabel="label" id="endHour" formControlName="endHour"
							[clearable]="false">
						</ng-select>
					</div>

					<ng-template #deliveryModePopupContent>
						<div class="cp-popover-banner">{{ ac.notificationPropLabels.deliveryMode }}</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.deliveryMode }}</div>
					</ng-template>

					<div class="cp-compact-form-group">
						<label for="timezone">
							{{ ac.notificationPropLabels.timezone }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="timezonePopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
							<a href="https://www.iana.org/time-zones" target="_blank">
								<fa-icon [icon]="['fas', 'clock']" class="ms-1" placement="end"
									ngbTooltip="Learn more about the IANA Time Zone Database">
								</fa-icon>
							</a>
						</label>
						<ng-select [items]="tzSelections" bindValue="value" bindLabel="label" id="timezone" class="ms-4"
							formControlName="timezone" [clearable]="false">
						</ng-select>
					</div>

					<ng-template #timezonePopupContent>
						<div class="cp-popover-banner">{{ ac.notificationPropLabels.timezone }}</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.timezone }}</div>
					</ng-template>

					<div class="cp-compact-form-group">
						<label>
							{{ ac.notificationPropLabels.daysOfWeek }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="daysOfWeekPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>

						<div *ngFor="let dow of ac.daysOfWeek">
							<label class="form-check-label ms-4" [for]="'dow_' + dow">
								<input type="checkbox" [id]="'dow_' + dow" [formControlName]="'dow_' + dow" class="align-middle mb-1" />
								{{ dow }}
							</label>
						</div>
					</div>

					<ng-template #daysOfWeekPopupContent>
						<div class="cp-popover-banner">{{ ac.notificationPropLabels.daysOfWeek }}</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.daysOfWeek }}</div>
					</ng-template>
				</div>
				<!-- <div class="cp-form-padding"></div> -->
			</div>

			<div *ngIf="showStaffSettings" class="tab-pane" role="tabpanel" id="nav-staff" aria-labelledby="nav-staff-tab">

				<div class="cp-general-width-limit">
					<!-- skip empty -->
					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="skipEmptyReports" formControlName="skipEmptyReports" />
						<label class="form-check-label" for="skipEmptyReports">
							{{ ac.notificationPropLabels.skipEmptyReports }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="skipEmptyReportsPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #skipEmptyReportsPopupContent>
						<div class="cp-popover-banner">{{ ac.notificationPropLabels.skipEmptyReports }}</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.skipEmptyReports }}</div>
					</ng-template>

					<!-- saved searches -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveStaffKeyReportMessages"
							formControlName="receiveStaffKeyReportMessages" />
						<label class="form-check-label" for="receiveStaffKeyReportMessages">
							{{ ac.notificationPropLabels.receiveStaffKeyReportMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveStaffKeyReportMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #receiveStaffKeyReportMessagesPopupContent>
						<div class="cp-popover-banner">{{
							ac.notificationPropLabels.receiveStaffKeyReportMessages
							}}</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.receiveStaffKeyReportMessages }}
						</div>
					</ng-template>

					<div *ngIf="receiveStaffKeyReportMessages.value" class="cp-compact-form-group ms-4">
						<label for="keyReportSavedSearches">
							{{ ac.notificationPropLabels.keyReportSavedSearches }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="keyReportSavedSearchesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>

						<div *ngIf="savedSearchSelections.length == 0">
							To use this feature, you must create at least one saved search via <a
								[routerLink]="['/' + ac.urls.licensing]">Licensing / Search</a>
						</div>

						<ng-select *ngIf="savedSearchSelections.length > 0" [items]="savedSearchSelections" bindValue="value"
							bindLabel="label" id="keyReportSavedSearches" formControlName="keyReportSavedSearches" [multiple]="true"
							[closeOnSelect]="false" placeholder="Select one or more saved searches">
						</ng-select>
					</div>

					<ng-template #keyReportSavedSearchesPopupContent>
						<div class="cp-popover-banner">{{ ac.notificationPropLabels.keyReportSavedSearches }}
						</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.keyReportSavedSearches }}</div>
					</ng-template>

					<!-- zen master reports -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveStaffZENMasterReportMessages"
							formControlName="receiveStaffZENMasterReportMessages" />
						<label class="form-check-label" for="receiveStaffZENMasterReportMessages">
							{{ ac.notificationPropLabels.receiveStaffZENMasterReportMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveStaffZENMasterReportMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
						<button *ngIf="!showZenTypeFilters" [disabled]="!receiveStaffZENMasterReportMessages.value" type="button"
							class="btn btn-outline-secondary btn-sm ms-1" (click)="showZenTypeFilters = true" placement="bottom-start"
							ngbTooltip="Optional - if you want to choose specific ZEN Master site types">
							<fa-icon icon="filter" size="sm"></fa-icon>
							<!-- Set Filters -->
						</button>
					</div>

					<ng-template #receiveStaffZENMasterReportMessagesPopupContent>
						<div class="cp-popover-banner">{{
							ac.notificationPropLabels.receiveStaffZENMasterReportMessages }}
						</div>
						<div class="cp-pre-text">{{
							ac.notificationPropInfo.receiveStaffZENMasterReportMessages }}
						</div>
					</ng-template>

					<div *ngIf="showZenTypeFilters && receiveStaffZENMasterReportMessages.value && zenTypeSelections.length > 0"
						class="cp-compact-form-group ms-4">
						<label for="includedZenMasterTypes">
							{{ ac.notificationPropLabels.includedZenMasterTypes }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="includedZenMasterTypesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
						<ng-select [items]="zenTypeSelections" bindValue="value" bindLabel="label" id="includedZenMasterTypes"
							formControlName="includedZenMasterTypes" [multiple]="true" [closeOnSelect]="false"
							placeholder="Optional - otherwise get notified for all ZEN Master sites">
						</ng-select>
					</div>

					<ng-template #includedZenMasterTypesPopupContent>
						<div class="cp-popover-banner">{{ ac.notificationPropLabels.includedZenMasterTypes }}
						</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.includedZenMasterTypes }}</div>
					</ng-template>

					<!-- org meter reports -->
					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveStaffOrgMeterReportMessages"
							formControlName="receiveStaffOrgMeterReportMessages" />
						<label class="form-check-label" for="receiveStaffOrgMeterReportMessages">
							{{ ac.notificationPropLabels.receiveStaffOrgMeterReportMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveStaffOrgMeterReportMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #receiveStaffOrgMeterReportMessagesPopupContent>
						<div class="cp-popover-banner">{{
							ac.notificationPropLabels.receiveStaffOrgMeterReportMessages }}
						</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.receiveStaffOrgMeterReportMessages
							}}
						</div>
					</ng-template>

					<div *ngIf="receiveStaffOrgMeterReportMessages.value" class="cp-compact-form-group ms-4">
						<label for="orgMeterReportOrgIDs">
							{{ ac.notificationPropLabels.orgMeterReportOrgIDs }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="orgMeterReportOrgIDsPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>

						<ng-select *ngIf="orgSelections.length > 0" [items]="orgSelections" bindValue="id" bindLabel="name"
							id="orgMeterReportOrgIDs" formControlName="orgMeterReportOrgIDs" [multiple]="true" [closeOnSelect]="false"
							placeholder="Select one or more organizations">
						</ng-select>

					</div>

					<ng-template #orgMeterReportOrgIDsPopupContent>
						<div class="cp-popover-banner">{{ ac.notificationPropLabels.orgMeterReportOrgIDs }}
						</div>
						<div class="cp-pre-text">{{ ac.notificationPropInfo.orgMeterReportOrgIDs }}</div>
					</ng-template>
				</div>
				<div class="cp-form-padding"></div>

			</div>
		</div>

		<!-- <div class="bottom-bar">
			<div class="form-group mb-0" *ngIf="!saving">
				<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
					<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
				</button>
				<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
					<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
				</button>
			</div>
		</div> -->

		<div *ngIf="!loading && theForm" class="cp-form-buttons">
			<div class="cp-form-buttons-inner">
				<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
					<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
				</button>
				<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
					<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
				</button>
			</div>
		</div>

	</form>
</div>