import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';

import * as Models from 'appshared/shared-models';
import { PlatformsService } from './platforms.service';

// this thing (a resolve) will run before a route is routed
// basically, grab the items from the service

@Injectable({ providedIn: 'root' })
export class PlatformsResolverService implements Resolve<Models.Platform[]> {
	constructor(private objService: PlatformsService) { }

	resolve(): Observable<Models.Platform[]> | Observable<never> | Models.Platform[] {
		// check to see if the items have ever been loaded.  If not, load'em
		if (this.objService.loaded) {
			// console.log('in resolver - the list is alive!');
			return this.objService.getAll();
		} else {
			// console.log('in resolver - need a new list');
			return new Observable((observe: Subscriber<Models.Platform[]>) => {
				this.objService.refreshAll().subscribe((items: Models.Platform[]) => {
					observe.next(items);
					observe.complete();
				});
			});
		}
	}
}

// import { Injectable } from '@angular/core';
// import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// import * as Models from 'appshared/models/shared';
// import { PlatformsService } from './platforms.service';

// // this thing (a resolve) will run before a route is routed
// // basically, grab the recipess from the data service if empty

// @Injectable({ providedIn: 'root' })
// export class PlatformsResolverService implements Resolve<Models.Platform[]> {
// 	constructor(private objService: PlatformsService) {}

// 	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
// 		const objects = this.objService.getAll();

// 		if (! objects || objects.length === 0) {
// 			return this.objService.fetchFromDB();
// 		} else {
// 			return objects;
// 		}
// 	}
// }


