import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { RoleGuard } from './guards/role.guard';

import AppConstants from 'appshared/app-constants';

// Components
import { LoginComponent } from './components/login/login.component';
import { SelfRegistrationComponent } from './components/self-registration/self-registration.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SsoComponent } from './components/login/sso/sso.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';
import { AwsMarketplaceRegistrationComponent } from './components/aws-marketplace-registration/aws-marketplace-registration.component';
import { AzureMarketplaceRegistrationComponent } from './components/azure-marketplace-registration/azure-marketplace-registration.component';
import { GcpMarketplaceRegistrationComponent } from './components/gcp-marketplace-registration/gcp-marketplace-registration.component';

const appRoutes: Routes = [
	{ path: '', redirectTo: AppConstants.defaultLandingPage, pathMatch: 'full' },
	{ path: AppConstants.urls.sso + '/:action', component: SsoComponent },
	{ path: AppConstants.urls.login, component: LoginComponent },
	{ path: AppConstants.urls.selfregistration, component: SelfRegistrationComponent },
	{ path: AppConstants.urls.selfregistration + '/:code', component: SelfRegistrationComponent },
	{ path: AppConstants.urls.forgotpassword, component: ForgotPasswordComponent },
	{ path: AppConstants.urls.passwordreset + '/:token/:email', component: ResetPasswordComponent },
	{ path: AppConstants.urls.confirmEmail + '/:token', component: ConfirmEmailComponent },
	{ path: AppConstants.urls.unsubscribe + '/:code', component: UnsubscribeComponent },
	{ path: AppConstants.urls.notfound, component: NotFoundComponent },
	{ path: AppConstants.urls.aws_marketplaceregistration, component: AwsMarketplaceRegistrationComponent },
	{ path: AppConstants.urls.azure_marketplaceregistration, component: AzureMarketplaceRegistrationComponent },
	{ path: AppConstants.urls.gcp_marketplaceregistration, component: GcpMarketplaceRegistrationComponent },
	{
		path: AppConstants.urls.dashboard,
		loadChildren: () => import('./components/app-sections/dashboard/dashboard.module').then(m => m.DashboardModule),
		canLoad: [RoleGuard], data: { role: AppConstants.basicUserRole }
	}, {
		path: AppConstants.urls.users,
		loadChildren: () => import('./components/app-sections/users/users.module').then(m => m.UsersModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.organizations,
		loadChildren: () => import('./components/app-sections/organizations/organizations.module').then(m => m.OrganizationsModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.zencustomers,
		loadChildren: () => import('./components/app-sections/zen-customers/zen-customers.module').then(m => m.ZenCustomersModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.licensing,
		loadChildren: () => import('./components/app-sections/licensing/licensing.module').then(m => m.LicensingModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.licensingadmin,
		loadChildren: () => import('./components/app-sections/licensing-admin/licensing-admin.module').then(m => m.LicensingAdminModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.platforms,
		loadChildren: () => import('./components/app-sections/platforms/platforms.module').then(m => m.PlatformsModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.products,
		loadChildren: () => import('./components/app-sections/products/products.module').then(m => m.ProductsModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.packages,
		loadChildren: () => import('./components/app-sections/packages/packages.module').then(m => m.PackagesModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.builds,
		loadChildren: () => import('./components/app-sections/builds/builds.module').then(m => m.BuildsModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.documents,
		loadChildren: () => import('./components/app-sections/documents/documents.module').then(m => m.DocumentsModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.files,
		loadChildren: () => import('./components/app-sections/files/files.module').then(m => m.FilesModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.systemmessages,
		loadChildren: () => import('./components/app-sections/system-messages/system-messages.module').then(m => m.SystemMessagesModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.partneradmin,
		loadChildren: () => import('./components/app-sections/partner-admin/partner-admin.module').then(m => m.PartnerAdminModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.adminlogs,
		loadChildren: () => import('./components/app-sections/admin-logs/admin-logs.module').then(m => m.AdminLogsModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.downloadlogs,
		loadChildren: () => import('./components/app-sections/download-logs/download-logs.module').then(m => m.DownloadLogsModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.linklogs,
		loadChildren: () => import('./components/app-sections/link-logs/link-logs.module').then(m => m.LinkLogsModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.loginlogs,
		loadChildren: () => import('./components/app-sections/login-logs/login-logs.module').then(m => m.LoginLogsModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.emailqueue,
		loadChildren: () => import('./components/app-sections/email-queue/email-queue.module').then(m => m.EmailQueueModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.marketplace,
		loadChildren: () => import('./components/app-sections/marketplace/marketplace.module').then(m => m.MarketplaceModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.calculators,
		loadChildren: () => import('./components/app-sections/calculators/calculators.module').then(m => m.CalculatorsModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.appsettings,
		loadChildren: () => import('./components/app-sections/app-settings/app-settings.module').then(m => m.AppSettingsModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.bundles,
		loadChildren: () => import('./components/app-sections/bundles/bundles.module').then(m => m.BundlesModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.backgroundtasks,
		loadChildren: () => import('./components/app-sections/background-tasks/background-tasks.module').then(m => m.BackgroundTasksModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.quizzes,
		loadChildren: () => import('./components/app-sections/quizzes/quizzes.module').then(m => m.QuizzesModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.reports,
		loadChildren: () => import('./components/app-sections/reports/reports.module').then(m => m.ReportsModule),
		canLoad: [RoleGuard], data: { role: AppConstants.staffUserRole }
	}, {
		path: AppConstants.urls.mybuilds,
		loadChildren: () => import('./components/app-sections/my-builds/my-builds.module').then(m => m.MyBuildsModule),
		canLoad: [RoleGuard], data: { role: AppConstants.basicUserRole }
	}, {
		path: AppConstants.urls.mydocuments,
		loadChildren: () => import('./components/app-sections/my-documents/my-documents.module').then(m => m.MyDocumentsModule),
		canLoad: [RoleGuard], data: { role: AppConstants.basicUserRole }
	}, {
		path: AppConstants.urls.myfilesharing,
		loadChildren: () => import('./components/app-sections/my-file-sharing/my-file-sharing.module').then(m => m.MyFileSharingModule),
		canLoad: [RoleGuard], data: { role: AppConstants.basicUserRole }
	}, {
		path: AppConstants.urls.mykeys,
		loadChildren: () => import('./components/app-sections/my-keys/my-keys.module').then(m => m.MyKeysModule),
		canLoad: [RoleGuard], data: { role: AppConstants.basicUserRole }
	}, {
		path: AppConstants.urls.mynotifications,
		loadChildren: () => import('./components/app-sections/my-notifications/my-notifications.module').then(m => m.MyNotificationsModule),
		canLoad: [RoleGuard], data: { role: AppConstants.basicUserRole }
	}, {
		path: AppConstants.urls.mypackages,
		loadChildren: () => import('./components/app-sections/my-packages/my-packages.module').then(m => m.MyPackagesModule),
		canLoad: [RoleGuard], data: { role: AppConstants.basicUserRole }
	}, {
		path: AppConstants.urls.mypartners,
		loadChildren: () => import('./components/app-sections/my-partners/my-partners.module').then(m => m.MyPartnersModule),
		canLoad: [RoleGuard], data: { role: AppConstants.basicUserRole }
	}, {
		path: AppConstants.urls.mysettings,
		loadChildren: () => import('./components/app-sections/my-settings/my-settings.module').then(m => m.MySettingsModule),
		canLoad: [RoleGuard], data: { role: AppConstants.basicUserRole }
	}, {
		path: AppConstants.urls.myquizzes,
		loadChildren: () => import('./components/app-sections/my-quizzes/my-quizzes.module').then(m => m.MyQuizzesModule),
		canLoad: [RoleGuard], data: { role: AppConstants.basicUserRole }
	}, {
		path: AppConstants.urls.myquestions,
		loadChildren: () => import('./components/app-sections/my-questions/my-questions.module').then(m => m.MyQuestionsModule),
		canLoad: [RoleGuard], data: { role: AppConstants.basicUserRole }
	}, {
		path: '**', redirectTo: '/not-found', pathMatch: 'full'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules })],
	// imports: [RouterModule.forRoot(appRoutes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
