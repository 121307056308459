import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share, map, tap } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class MyQuizzesService {

	constructor(private http: HttpClient) {
	}

	// *************************************
	async getAll() {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.myquizzes;
			const result = await this.http.get<Models.Quiz[]>(url).toPromise();
			const arr: Models.Quiz[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
			return null;
		} // try
	}

	// *************************************
	async getOne(id: number) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.myquizzes + '/' + id;
			const result = await this.http.get<Models.Quiz>(url).toPromise();
			const obj: Models.Quiz = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
			return null;
		}
	}

	// *************************************
	async setAnswers(qResponse: Models.QuizResponse) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.myquizzes + '/' + qResponse.quiz_id + '/answers';
			const result = await this.http.put(url, qResponse).toPromise();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
			return null;
		}
	}

}
