import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';

import { ViewQueueLogsComponent } from './view-queue-logs/view-queue-logs.component';
import { ViewErrorsComponent } from './view-errors/view-errors.component';

import { UsersResolverService } from '../users/users-resolver.service';

const routes: Routes = [
	{
		path: '', component: ViewQueueLogsComponent, canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole }, resolve: [UsersResolverService]
	},
	{
		path: 'email-failures', component: ViewErrorsComponent, canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole }
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EmailQueueRoutingModule { }
