<!-- Size Detector -->
<app-size-detector></app-size-detector>
<!-- Main App Loader -->
<div class="app-loading" *ngIf="!loaded">
	<div class="logo">
		<img width="200" alt="Zixi logo" src="assets/images/zixi-white.png" />
	</div>
	<div class="dots-loader"></div>
</div>
<!-- Content Area -->
<main class="flex-container" *ngIf="loaded">
	<app-top-bar *ngIf="loggedIn"></app-top-bar>
	<div class="content-container">
		<app-navigation *ngIf="loggedIn" #navigation></app-navigation>
		<!-- Content Loader -->
		<div class="content-loading" *ngIf="isLoadingRoute | async">
			<div class="dots-loader">
				<span class="sr-only">Loading...</span>
			</div>
		</div>
		<!-- Router Outlet -->
		<div class="outlet-container" [ngClass]="{ 'd-none': isLoadingRoute | async }">
			<app-ui-alerts></app-ui-alerts>
			<router-outlet #routerOutlet="outlet"></router-outlet>
		</div>
	</div>

	<audio id='audioPlayer' src=""></audio>
</main>