<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<a href="javascript:void(0)" (click)="onCancel()">
					<fa-icon [icon]="ac.sectionIcons.products" class="cp-admin-nav-item me-1" size="sm">
					</fa-icon>
					Products
				</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				Sort Products for Documents/Downloads
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button type="button" class="btn btn-outline-secondary" (click)="loadData()">
				<fa-icon icon="sync-alt" size="sm" [spin]="loading || saving" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<div class="page-wrapper">
	<div *ngIf="loading || saving" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<div *ngIf="!loading" cdkDropList (cdkDropListDropped)="drop($event)" class="cp-general-width-limit">
		<div *ngFor="let product of products" cdkDrag class="mb-1 p-2 border">
			<fa-icon [icon]="['fas', 'sort-alt']" size="sm" class="ms-1"></fa-icon>
			{{ product.name }}
		</div>

		<!-- <div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div> -->
	</div>

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="saving">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>
		</div>
	</div>

</div>