import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class PackagesService {
	packages: Observable<Models.Package[]>;
	loaded = false;
	private debug = false;
	private _packages: ReplaySubject<Models.Package[]>;
	private dataStore: {
		packages: Models.Package[];
	};

	constructor(private http: HttpClient) {
		this.dataStore = {
			packages: []
		};
		this._packages = new ReplaySubject(1) as ReplaySubject<Models.Package[]>;
		this.packages = this._packages.asObservable();
	}

	// add/update an item in the datastore
	private updateStore(newPackage: Models.Package): void {
		const idx = this.dataStore.packages.findIndex(pkg => pkg.id === newPackage.id);
		if (idx === -1) {
			this.dataStore.packages.push(newPackage);
			return;
		} else {
			this.dataStore.packages[idx] = newPackage;
		}
	}

	// get a fresh copy of the entire set
	refreshAll(): Observable<Models.Package[]> {
		const packages$ = this.http.get<Models.Package[]>(AppConstants.apiUrl + AppConstants.apiUrls.packages).pipe(share());
		packages$.subscribe(
			data => {
				const packages: Models.Package[] = data;
				// remove ones from the store that aren't in the response (they've been deleted)
				this.dataStore.packages.forEach((existingPackage, existingIndex) => {
					const newIndex = packages.findIndex(pkg => pkg.id === existingPackage.id);
					if (newIndex === -1) this.dataStore.packages.splice(existingIndex, 1);
				});
				// add/update all the ones that came back
				packages.forEach(pkg => this.updateStore(pkg));
				this._packages.next(Object.assign({}, this.dataStore).packages);
				this.loaded = true;
			},
			error => { console.log(error); }
		);
		return packages$;
	}

	// get a fresh copy of a single item
	refreshOne(id: number): Observable<Models.Package> {
		const package$ = this.http.get<Models.Package>(AppConstants.apiUrl + AppConstants.apiUrls.packages + '/' + id).pipe(share());
		package$.subscribe(
			data => {
				const pkg: Models.Package = data;
				// add/update the one that came back
				this.updateStore(pkg);
				this._packages.next(Object.assign({}, this.dataStore).packages);
			},
			error => { console.log(error); }
		);
		return package$;
	}

	// return the whole list
	getAll(): Models.Package[] {
		return this.dataStore.packages.slice();
	}

	// grab a single item from the datastore
	getOne(id: number): Models.Package {
		return this.dataStore.packages.find(pkg => pkg.id === id);
	}

	// call back-end to add an item
	async addOne(pkg: Models.Package) {
		try {
			const result = await this.http.post<Models.Package>(AppConstants.apiUrl + AppConstants.apiUrls.packages, pkg).toPromise();
			const returnedPackage: Models.Package = result;
			this.updateStore(returnedPackage);
			this._packages.next(Object.assign({}, this.dataStore).packages);
			return returnedPackage;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update an item
	async updateOne(pkg: Models.Package) {
		try {
			const result = await this.http.put<Models.Package>(AppConstants.apiUrl + AppConstants.apiUrls.packages + '/' + pkg.id, pkg).toPromise();
			const returnedPackage: Models.Package = result;
			this.updateStore(returnedPackage);
			this._packages.next(Object.assign({}, this.dataStore).packages);
			return returnedPackage;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to delete an item
	async deleteOne(id: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl + AppConstants.apiUrls.packages + '/' + id).toPromise();
			const idx = this.dataStore.packages.findIndex(pkg => pkg.id === id);
			if (idx !== -1) this.dataStore.packages.splice(idx, 1);
			this._packages.next(Object.assign({}, this.dataStore).packages);
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// ********************************************************
	// other non-standard methods
	// ********************************************************

	// call back-end to enable/disable
	async toggleEnabled(id: number) {
		try {
			const result = await this.http.put<Models.Package>(AppConstants.apiUrl
				+ AppConstants.apiUrls.packages + '/' + id + '/toggle-enabled', {}).toPromise();
			const returnedPackage: Models.Package = result;
			this.updateStore(returnedPackage);
			this._packages.next(Object.assign({}, this.dataStore).packages);
			return returnedPackage;
		} catch (error) {
		}
	}

	// call back-end to add an activation rule
	async addOneActivationRule(par: Models.PackageActivationRule) {
		try {
			const result = await this.http.post<Models.Package>(AppConstants.apiUrl
				+ AppConstants.apiUrls.packages + '/' + par.package_id + '/activation-rules', par).toPromise();
			const returnedPackage: Models.Package = result;
			this.updateStore(returnedPackage);
			this._packages.next(Object.assign({}, this.dataStore).packages);
			return returnedPackage;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update an activation rule
	async updateOneActivationRule(par: Models.PackageActivationRule) {
		try {
			const result = await this.http.put<Models.Package>(AppConstants.apiUrl
				+ AppConstants.apiUrls.packages + '/' + par.package_id + '/activation-rules/' + par.id, par).toPromise();
			const returnedPackage: Models.Package = result;
			this.updateStore(returnedPackage);
			this._packages.next(Object.assign({}, this.dataStore).packages);
			return returnedPackage;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to delete an activation rule
	async deleteOneActivationRule(id: number, id2: number) {
		try {
			const result = await this.http.delete<Models.Package>(AppConstants.apiUrl
				+ AppConstants.apiUrls.packages + '/' + id + '/key-templates/' + id2).toPromise();
			const returnedPackage: Models.Package = result;
			this.updateStore(returnedPackage);
			this._packages.next(Object.assign({}, this.dataStore).packages);
			return returnedPackage;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to add an activation rule
	async addOneKeyTemplate(par: Models.PackageKeyTemplate) {
		try {
			const result = await this.http.post<Models.Package>(AppConstants.apiUrl
				+ AppConstants.apiUrls.packages + '/' + par.package_id + '/key-templates', par).toPromise();
			const returnedPackage: Models.Package = result;
			this.updateStore(returnedPackage);
			this._packages.next(Object.assign({}, this.dataStore).packages);
			return returnedPackage;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update an activation rule
	async updateOneKeyTemplate(par: Models.PackageKeyTemplate) {
		try {
			const result = await this.http.put<Models.Package>(AppConstants.apiUrl
				+ AppConstants.apiUrls.packages + '/' + par.package_id + '/key-templates/' + par.id, par).toPromise();
			const returnedPackage: Models.Package = result;
			this.updateStore(returnedPackage);
			this._packages.next(Object.assign({}, this.dataStore).packages);
			return returnedPackage;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to delete an activation rule
	async deleteKeyTemplate(id: number, id2: number) {
		try {
			const result = await this.http.delete<Models.Package>(AppConstants.apiUrl
				+ AppConstants.apiUrls.packages + '/' + id + '/activation-rules/' + id2).toPromise();
			const returnedPackage: Models.Package = result;
			this.updateStore(returnedPackage);
			this._packages.next(Object.assign({}, this.dataStore).packages);
			return returnedPackage;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}


	// call back-end to delete an item
	async getRegistrationUrl(id: number) {
		try {
			const result = await this.http.get<{ url: string; }>(AppConstants.apiUrl + AppConstants.apiUrls.packages + '/' + id + '/registration-url').toPromise();
			const url: string = result.url;
			return url;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}
}
