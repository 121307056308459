<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="ac.sectionIcons.quizzes" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Quizzes + Polls
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button *ngIf="canAdd" type="button" class="btn btn-outline-primary me-2" routerLink="new">
				<fa-icon [icon]="['fas', 'plus']" size="sm" ngbTooltip="New Quiz" placement="start"></fa-icon>
			</button>

			<button type="button" class="btn btn-outline-secondary" (click)="loadData()">
				<fa-icon icon="sync-alt" size="sm" [spin]="loading" ngbTooltip="Refresh" placement="start"></fa-icon>
			</button>
		</div>
	</div>
</div>

<div class="list-wrapper">

	<div *ngIf="loading" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<div *ngIf="!loading && !canAdd && quizzesToShow.length === 0" class="cp-warning-message">
		<fa-icon [icon]="['fas', 'exclamation-triangle']" size="sm"></fa-icon>
		You aren't able to manage any quizzes.
	</div>

	<table *ngIf="quizzesToShow && quizzesToShow.length !== 0" id="listtable"
		class="table table-hover table-select table-sm bordered cp-auto-width">
		<thead>
			<tr>
				<th>Status</th>
				<th>Type</th>
				<th>Name</th>
				<th class="cp-icon-header text-center">
					<span ngbTooltip="Number of Questions">#Q</span>
				</th>
				<th class="cp-icon-header text-center">
					<span ngbTooltip="Number of Respondants">#R</span>
				</th>
				<th class="cp-icon-header">
					<fa-icon [icon]="['fas', 'clock']" size="sm" ngbTooltip="Days since added" placement="start">
					</fa-icon>
				</th>
			</tr>
		</thead>
		<tbody *ngIf="quizzesToShow && !loading">
			<tr *ngFor="let item of quizzesToShow" [routerLink]="[item.id]" class="cp-pointer">
				<td>
					{{ miscTools.fetchLabel(ac.quizStatusSelections, item.status, 'value', 'shortLabel') }}
				</td>
				<td>
					{{ miscTools.fetchLabel(ac.quizTypeSelections, item.config.quizType, 'value',
					'shortLabel') }}
				</td>
				<td><a [routerLink]="[item.id]">{{ item.name }}</a></td>
				<td class="text-center">
					{{ item.config.questions.length }}
				</td>
				<td class="text-center">
					{{ item.responses.length }}
				</td>
				<td class="text-center" [title]="textTools.formatDateNiceUTC(item.added_on)">
					{{ miscTools.diffDays(now, item.added_on, true) | number }}
				</td>
			</tr>
		</tbody>
	</table>
</div>