import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject, interval, Subject } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import PopOverTools from 'appshared/popover-tools';

import { MyQuizzesService } from '../my-quizzes.service';
import { AuthService } from 'client/app/services/auth.service';
import { UsersService } from '../../users/users.service';

@Component({
	selector: 'app-my-quiz-list',
	templateUrl: './my-quiz-list.component.html',
	styleUrls: ['./my-quiz-list.component.scss']
})
export class MyQuizListComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	textTools = TextTools;
	miscTools = MiscTools;
	popOverTools = PopOverTools;
	now = new Date();

	// 'standard' list stuff
	quizzes: Models.Quiz[];
	quizzesToShow: Models.Quiz[];

	loading = true;

	// other stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	constructor(
		private myQuizzesService: MyQuizzesService,
		private usersService: UsersService,
		private authService: AuthService,
	) { }

	// ************************************************************************************
	ngOnInit(): void {
		this.loading = true;

		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.authUser = authUser;
			this.loadData();
		});
	}

	// ************************************************************************************
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ************************************************************************************
	async loadData() {
		this.loading = true;

		this.quizzes = await this.myQuizzesService.getAll();
		this.quizzesToShow = this.quizzes;

		this.loading = false;
	}


}
