import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-loading-spinner',
	template: '<div class="spinner-grow text-primary" role="status"><span class="sr-only">Loading...</span></div>'
})
export class LoadingSpinnerComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {
	}

}
