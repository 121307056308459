import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { MarketplaceRoutingModule } from './marketplace-routing.module';
import { MarketplaceLogsComponent } from './marketplace-logs/marketplace-logs.component';
import { MarketplaceInfoComponent } from './marketplace-info/marketplace-info.component';

@NgModule({
	declarations: [MarketplaceLogsComponent, MarketplaceInfoComponent],
	imports: [
		MarketplaceRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		SharedModule,
		FontAwesome,
		ClipboardModule
	]
})
export class MarketplaceModule { }
