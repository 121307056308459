<div *ngIf="product && product !== ''" class="row gx-1">

	<div *ngIf="!hasFeaturesAndLimits">
		There aren't any features or limits.
	</div>

	<div *ngIf="keyProductPropertiesToShowByType['boolean'] && keyProductPropertiesToShowByType['boolean'].length > 0" class="col-auto">
		<table class="cp-std-table cp-auto-width">
			<tr>
				<td colspan="2" class="text-center">
					Features
				</td>
			</tr>

			<tr *ngFor="let pp of keyProductPropertiesToShowByType['boolean']">
				<td *ngIf="numericPropValue(pp.property.name) === 0" class="text-nowrap text-center">
					<fa-icon icon="square"></fa-icon>
				</td>
				<td *ngIf="numericPropValue(pp.property.name) === 0">
					<del>{{ pp.property.label }}</del>
				</td>

				<td *ngIf="numericPropValue(pp.property.name) === 1" class="text-nowrap text-center">
					<fa-icon icon="check-square"></fa-icon>
				</td>
				<td *ngIf="numericPropValue(pp.property.name) === 1">
					{{ pp.property.label }}
				</td>
			</tr>
			<tr *ngFor="let pp of keyProductPropertiesToShowByType['other']">
				<td colspan="2">
					{{ pp.property.label }}: {{ stringPropValue(pp.property.name) }}
				</td>
			</tr>
		</table>
	</div>

	<div *ngIf="keyProductPropertiesToShowByType['number'] && keyProductPropertiesToShowByType['number'].length > 0" class="col-auto">

		<table class="cp-std-table cp-auto-width">
			<tr *ngIf="sharedLicenseTools.getSubsetOfLimits(keyProductPropertiesToShowByType['number'], 'in').length > 0">
				<td colspan="2" class="text-center">
					Ingress Limits
				</td>
			</tr>
			<tr *ngFor="let pp of sharedLicenseTools.getSubsetOfLimits(keyProductPropertiesToShowByType['number'], 'in')">
				<td>{{ pp.property.label }}</td>
				<td *ngIf="stringPropValue(pp.property.name) === 'unlimited'" class="text-nowrap text-center">
					<fa-icon icon="infinity" ngbTooltip="Unlimited"></fa-icon>
				</td>
				<td *ngIf="stringPropValue(pp.property.name) !== 'unlimited'" class="text-nowrap text-center">
					{{ numericPropValue(pp.property.name) | number }} {{ pp.property.units }}
				</td>
			</tr>

			<tr *ngIf="sharedLicenseTools.getSubsetOfLimits(keyProductPropertiesToShowByType['number'], 'out').length > 0">
				<td colspan="2" class="text-center">
					Egress Limits
				</td>
			</tr>
			<tr *ngFor="let pp of sharedLicenseTools.getSubsetOfLimits(keyProductPropertiesToShowByType['number'], 'out')">
				<td>{{ pp.property.label }}</td>
				<td *ngIf="stringPropValue(pp.property.name) === 'unlimited'" class="text-nowrap text-center">
					<fa-icon icon="infinity" ngbTooltip="Unlimited"></fa-icon>
				</td>
				<td *ngIf="stringPropValue(pp.property.name) !== 'unlimited'" class="text-nowrap text-center">
					{{ numericPropValue(pp.property.name) | number }} {{ pp.property.units }}
				</td>
			</tr>

			<tr *ngIf="sharedLicenseTools.getSubsetOfLimits(keyProductPropertiesToShowByType['number'], 'other').length > 0">
				<td colspan="2" class="text-center">
					Other Limits
				</td>
			</tr>
			<tr *ngFor="let pp of sharedLicenseTools.getSubsetOfLimits(keyProductPropertiesToShowByType['number'], 'other')">
				<td>{{ pp.property.label }}</td>
				<td *ngIf="stringPropValue(pp.property.name) === 'unlimited'" class="text-nowrap text-center">
					<fa-icon icon="infinity" ngbTooltip="Unlimited"></fa-icon>
				</td>
				<td *ngIf="stringPropValue(pp.property.name) !== 'unlimited'" class="text-nowrap text-center">
					{{ numericPropValue(pp.property.name) | number }} {{ pp.property.units }}
				</td>
			</tr>
		</table>
	</div>

	<div *ngIf="!hostMode && staffMode && undefinedPropNames && undefinedPropNames.length > 0" class="cp-property-list">
		Unknown/Unsupported Properties (likely legacy functionality)
		<hr />
		<div *ngFor="let prop of undefinedPropNames" class="cp-property-block">
			<span class="cp-property-label">{{ prop }}:</span>
			<span class="cp-property-value">{{ stringPropValue(prop) }}</span>
		</div>
	</div>

</div>