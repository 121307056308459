import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { MyDocumentsRoutingModule } from './my-documents-routing.module';
import { MyDocumentComponent } from './my-document/my-document.component';
import { MyDocumentsListComponent } from './my-documents-list/my-documents-list.component';


@NgModule({
	declarations: [
		MyDocumentComponent,
		MyDocumentsListComponent
	],
	imports: [
		CommonModule,
		MyDocumentsRoutingModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		ClipboardModule,
		SharedModule,
		FontAwesome
	]
})
export class MyDocumentsModule { }
