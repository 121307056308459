<div class="login-container">
	<div class="row">
		<div class="col text-center">
			<img width="160" alt="Zixi logo" src="assets/images/zixi-white.png" />
		</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card mt-3 mb-3 text-start mx-auto" [ngStyle]="{ 'max-width.px': 400 }">
				<div class="card-body">
					<div>
						<h3>Unsubscribe</h3>
						<div *ngIf="saving" class="text-center">
							<app-loading-spinner></app-loading-spinner>
						</div>

						<div *ngIf="!loading && !validCode" class="cp-form-error text-center">
							That link has expired. Please login to update your e-mail notification
							settings.<br />
							<button class="btn btn-outline-secondary" type="button" routerLink="/login">
								<fa-icon [icon]="['fas', 'sign-in-alt']"></fa-icon> Login
							</button>
						</div>

						<div *ngIf="!loading && validCode" class="text-center">
							Click the button below to unsubscibed from all E-Mail Notifications<br />
							<button class="btn btn-outline-secondary" type="button" (click)="unsubscribe()">
								<fa-icon [icon]="['fas', 'paper-plane']"></fa-icon> Unsubscribed from all E-Mail
								Notifications
							</button>
						</div>
						<!-- 
							<div class="form-group mb-2">
								<button class="btn btn-primary me-1" type="submit"
									[disabled]="!theForm.valid || saving">
									<fa-icon [icon]="['fas', 'passport']"></fa-icon> Reset Password
								</button>
								<button [disabled]="saving" class="btn btn-outline-secondary" type="button"
									(click)="onCancel()">
									<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
								</button>
							</div> -->

						<div *ngIf="supportURL && supportURL !== ''" class="form-group mb-0 small text-center">
							<a [href]="supportURL" target='_blank'>
								<fa-icon [icon]="['fas', 'life-ring']"></fa-icon> Need Help?
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>