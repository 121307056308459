import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
// import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';

import { AuthService } from 'client/app/services/auth.service';
import { LicensingAdminService } from '../licensing-admin.service';
import { UsersService } from '../../users/users.service';
import { UserGroupsService } from '../../users/user-groups.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { OrganizationGroupsService } from '../../organizations/organization-groups.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-key-template-form',
	templateUrl: './key-template-form.component.html',
	styleUrls: ['./key-template-form.component.scss']
})
export class KeyTemplateFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	id: number;
	productArg: string;

	template: Models.KeyTemplate;
	errors: string[] = [];
	pageMode = 'new';
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	product: Models.LicenseProduct = null;

	verfiedProductProperties: Models.LicenseProductProperty[] = [];

	booleanPropsCount: number = 0;
	numberPropsCount: number = 0;
	otherPropsCount: number = 0;

	otherPropSelections = {};

	productLabelForHeading: string = '';

	allOrgGroups: Models.OrganizationGroup[] = [];
	allUserGroups: Models.UserGroup[] = [];

	orgSelections: any[] = [];
	staffUserSelections: any[] = [];
	orgTypeSelections: any[] = [];
	keyTypeSelections: any[] = [];
	meterProductSelections: any[] = [];
	expiryModeSelections: any[] = [];

	// products: Models.Product[] = [];
	// products: Models.Product[] = [];

	timeUnits: any[] = [
		{ value: 'days', label: 'Days' },
		{ value: 'weeks', label: 'Weeks' },
		{ value: 'months', label: 'Months' },
		{ value: 'years', label: 'Years' }
	];

	daysOfWeekSelections: any[] = [];

	niceMeterLimit = '';

	nonCheckAllFeatureSelections: any[] = [];
	nonAllUnlimitedLimitSelections: any[] = [];

	commercialTypeSelections: any[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private usersService: UsersService,
		private userGroupsService: UserGroupsService,
		private organizationsService: OrganizationsService,
		private organizationGroupsService: OrganizationGroupsService,
		private licensingAdminService: LicensingAdminService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.productArg = params.get('product');
		});
	}

	ngOnInit(): void {
		this.initForm();
	}

	ngOnDestroy() {
	}

	async initForm() {
		if (this.id && this.id !== 0) {
			this.template = await this.licensingAdminService.getTemplate(this.id);
			if (!this.template || this.template == null || this.template.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
				return;
			} // if
			if (this.router.url.endsWith("clone")) {
				this.pageMode = 'clone';

				this.template = new Models.KeyTemplate(0, this.template.product, this.template.name,
					this.template.description, 0, null, 0, null, null, this.template.settings,
					this.template.user_ids, this.template.user_group_ids);
				this.template.name += ' (copy)';

			} else {
				this.pageMode = 'edit';
			}
		} else {
			this.pageMode = 'new';
			this.template = new Models.KeyTemplate(0, this.productArg, '', '');
			this.template.settings = new Models.KeyTemplateSettings();
		}

		// load the lic product and it's p-properties...
		this.product = await this.licensingAdminService.getProductByName(this.template.product);
		if (!this.product || this.product == null || this.product.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		} // if
		this.productLabelForHeading = this.product.label;

		// load all properties
		const allProperties: Models.LicenseProperty[] = await this.licensingAdminService.getProperties();
		const productProps: Models.LicenseProductProperty[] = await this.licensingAdminService.getProductProperties(false, this.product.id);

		// console.log('productProps');
		// console.log(JSON.stringify(productProps, null, 2));
		// console.log('this.template.settings.productProperties');
		// console.log(JSON.stringify(this.template.settings.productProperties, null, 2));

		// add any missing properties...
		for (const pp of productProps) {
			if (pp.can_be_changed === 1) {
				const idx = MiscTools.findIndexGeneric(this.template.settings.productProperties, 'property_id', pp.property_id);
				if (idx === -1)
					this.template.settings.productProperties.push(pp);
			} // if
		} // for

		this.verfiedProductProperties = [];

		// attach product and property to the pp objects if they aren't there
		for (const pp of this.template.settings.productProperties) {
			// check to see if it's still a valid product property
			const idx1 = MiscTools.findIndexGenericDouble(productProps, 'product_id', pp.product_id, 'property_id', pp.property_id);
			if (idx1 === -1) {
				console.log('cannot find product:property ' + pp.product_id + ':' + pp.property_id);
				pp.product = null;
				pp.property = null;
			} else {
				if (!pp.product) pp.product = this.product;
				if (!pp.property) {
					const idx2 = MiscTools.findIndex(allProperties, pp.property_id);
					if (idx2 !== -1)
						pp.property = allProperties[idx2];
					else
						console.log('cannot find property ' + pp.property_id);
				} // if
			} // if

			if (pp.property)
				this.verfiedProductProperties.push(pp);
		} // for

		this.verfiedProductProperties.sort((a, b) => (a.property.sort_order > b.property.sort_order
			|| (a.property.sort_order === b.property.sort_order && a.property.label > b.property.label)) ? 1 : -1);

		this.allOrgGroups = this.organizationGroupsService.getAll();
		this.allUserGroups = this.userGroupsService.getAll();

		const staff: Models.User[] = this.usersService.getForRole(AppConstants.staffUserRole);
		this.staffUserSelections = [];
		for (const user of staff) {
			if (user.is_enabled === 1 || this.template.user_ids.includes(user.id)) {
				let nameToShow = user.name;
				if (user.is_enabled === 0) nameToShow += ' (disabled)';
				this.staffUserSelections.push({ id: user.id, name: nameToShow });
			} // if
		} // staff

		const allOrgs: Models.Organization[] = this.organizationsService.getAll();
		this.orgSelections = [];
		for (const org of allOrgs) {
			if (!ValidationTools.hasFlag(org, 'no_keys')) {
				this.orgSelections.push({
					id: org.id,
					name: org.name + ' [' + org.otype + '] - ' + org.salesforce_account_owner
				});
			} // if
		} // for

		this.orgTypeSelections = [];
		for (const orgType of AppConstants.organizationTypes)
			this.orgTypeSelections.push({ value: orgType, label: AppConstants.organizationTypeLabels[orgType] });

		this.keyTypeSelections = [];
		for (const keyType of AppConstants.keyTypes)
			this.keyTypeSelections.push({ value: keyType, label: keyType });

		this.meterProductSelections = [];
		if (this.product.meterProductsArr && this.product.meterProductsArr.length > 0)
			for (const mp of this.product.meterProductsArr)
				this.meterProductSelections.push({ value: mp, label: AppConstants.meterTypeObjects[mp].label + '(' + mp + ')' });

		this.expiryModeSelections = [];
		for (const keyExpiryMode of AppConstants.keyExpiryModes)
			if (keyExpiryMode.value !== 'meter' || this.meterProductSelections.length > 0)
				this.expiryModeSelections.push(keyExpiryMode);

		this.daysOfWeekSelections = [];
		for (const dayOfWeek of AppConstants.daysOfWeek)
			this.daysOfWeekSelections.push({ value: dayOfWeek, label: dayOfWeek });

		this.commercialTypeSelections = [];
		this.commercialTypeSelections.push({
			value: '',
			label: 'None/not set'
		});

		let counter = 0;
		for (const keyCommercialType of AppConstants.keyCommercialTypes) {
			counter++;
			this.commercialTypeSelections.push({
				value: keyCommercialType.value,
				label: counter + '. ' + keyCommercialType.short_label
			});
		} // for


		// set up some presets for new templates
		if (this.pageMode === 'new') {
			// for (const keyExpiryMode of this.expiryModeSelections)
			// 	this.template.settings.expiryModes.push(keyExpiryMode.value);

			if (this.meterProductSelections.length > 0) {
				for (const meterProductSelection of this.meterProductSelections)
					this.template.settings.limitMeterProducts.push(meterProductSelection.value);

				for (const meterResetOption of AppConstants.meterResetOptions)
					this.template.settings.limitMeterReset.push(meterResetOption.value);
			} // if
		} // if

		// set some stuff (lookup lists) to null if they're empty arrays
		if (this.template.settings.defaultExpiryMode === '')
			this.template.settings.defaultExpiryMode = null;

		this.theForm = new UntypedFormGroup({
			name: new UntypedFormControl(this.template.name, [Validators.required]),
			description: new UntypedFormControl(this.template.description),
			user_ids: new UntypedFormControl(this.template.user_ids),
			user_group_ids: new UntypedFormControl(this.template.user_group_ids),
			keyTypes: new UntypedFormControl(this.template.settings.keyTypes, [Validators.required]),
			defaultKeyType: new UntypedFormControl(this.template.settings.defaultKeyType),
			requiresOrganization: new UntypedFormControl(this.template.settings.requiresOrganization),
			editOnly: new UntypedFormControl(this.template.settings.editOnly),
			// quickKey: new FormControl(this.template.settings.quickKey),
			limitOrgIDs: new UntypedFormControl(this.template.settings.limitOrgIDs),
			limitOrgGroupIDs: new UntypedFormControl(this.template.settings.limitOrgGroupIDs),
			excludeOrgIDs: new UntypedFormControl(this.template.settings.excludeOrgIDs),
			excludeOrgGroupIDs: new UntypedFormControl(this.template.settings.excludeOrgGroupIDs),
			orgTypes: new UntypedFormControl(this.template.settings.orgTypes),

			requiresSalesforceOpportunity: new UntypedFormControl(this.template.settings.requiresSalesforceOpportunity),
			salesforceOpportunityMustMatch: new UntypedFormControl(this.template.settings.salesforceOpportunityMustMatch),
			defaultActivations: new UntypedFormControl(this.template.settings.defaultActivations, [Validators.required, Validators.min(1), Validators.max(AppConstants.maxActivationMax)]),
			minActivations: new UntypedFormControl(this.template.settings.minActivations, [Validators.required, Validators.min(1), Validators.max(AppConstants.maxActivationMax)]),
			maxActivations: new UntypedFormControl(this.template.settings.maxActivations, [Validators.required, Validators.min(1), Validators.max(AppConstants.maxActivationMax)]),
			maxKeys: new UntypedFormControl(this.template.settings.maxKeys, [Validators.required, Validators.min(1), Validators.max(AppConstants.maxKeysToCreate)]),
			defaultCommercialType: new UntypedFormControl(this.template.settings.defaultCommercialType),
			defaultCommercialInfo: new UntypedFormControl(this.template.settings.defaultCommercialInfo),
			requiresCommercialType: new UntypedFormControl(this.template.settings.requiresCommercialType),
			requiresLinkedUsers: new UntypedFormControl(this.template.settings.requiresLinkedUsers),
			defaultShareWithSelf: new UntypedFormControl(this.template.settings.defaultShareWithSelf),

			expiryModes: new UntypedFormControl(this.template.settings.expiryModes, [Validators.required]),
			defaultExpiryMode: new UntypedFormControl(this.template.settings.defaultExpiryMode),
			defaultExpirationCount: new UntypedFormControl(this.template.settings.defaultExpirationCount),
			defaultExpirationUnit: new UntypedFormControl(this.template.settings.defaultExpirationUnit),
			maxExpirationCount: new UntypedFormControl(this.template.settings.maxExpirationCount),
			maxExpirationUnit: new UntypedFormControl(this.template.settings.maxExpirationUnit),
			limitMeterProducts: new UntypedFormControl(this.template.settings.limitMeterProducts),
			requiredMeterProducts: new UntypedFormControl(this.template.settings.requiredMeterProducts),
			limitMeterReset: new UntypedFormControl(this.template.settings.limitMeterReset),
			defaultMeterLimit: new UntypedFormControl(this.template.settings.defaultMeterLimit),
			warningDaysOfWeek: new UntypedFormControl(this.template.settings.warningDaysOfWeek),
			nonCheckAllFeatures: new UntypedFormControl(this.template.settings.nonCheckAllFeatures),
			nonAllUnlimitedLimits: new UntypedFormControl(this.template.settings.nonAllUnlimitedLimits),
			requiresMeterLabels: new UntypedFormControl(this.template.settings.requiresMeterLabels),
			defaultMeterLabel: new UntypedFormControl(this.template.settings.defaultMeterLabel),
		});

		this.booleanPropsCount = 0;
		this.numberPropsCount = 0;
		this.otherPropsCount = 0;

		this.nonCheckAllFeatureSelections = [];
		this.nonAllUnlimitedLimitSelections = [];

		for (const pp of this.verfiedProductProperties) {
			this.theForm.addControl('pp_' + pp.property_id + '_can_be_changed', new UntypedFormControl(pp.can_be_changed));

			if (pp.property.ptype === 'boolean') {
				this.booleanPropsCount++;

				this.nonCheckAllFeatureSelections.push({
					id: pp.property_id,
					label: pp.property.label
				});

				this.theForm.addControl('pp_' + pp.property_id + '_default_value_num', new UntypedFormControl(pp.default_value_num));
			} else if (pp.property.ptype === 'number') {
				this.numberPropsCount++;

				this.theForm.addControl('pp_' + pp.property_id + '_default_value_num', new UntypedFormControl(pp.default_value_num));
				if (pp.property.allow_unlimited === 1) {
					let defUnlimChecked = 0;
					if (pp.default_value_text === 'unlimited') defUnlimChecked = 1;
					this.theForm.addControl('pp_' + pp.property_id + '_def_unlimited', new UntypedFormControl(defUnlimChecked));

					this.nonAllUnlimitedLimitSelections.push({
						id: pp.property_id,
						label: pp.property.label
					});
				} // if

				this.theForm.addControl('pp_' + pp.property_id + '_min_value', new UntypedFormControl(pp.min_value));
				this.theForm.addControl('pp_' + pp.property_id + '_max_value', new UntypedFormControl(pp.max_value));
				if (pp.property.allow_unlimited === 1) {
					if (this.pageMode === 'new') pp.allow_unlimited = 1;
					this.theForm.addControl('pp_' + pp.property_id + '_allow_unlimited', new UntypedFormControl(pp.allow_unlimited));
				}

			} else if (pp.property.ptype === 'other') {
				this.otherPropsCount++;
				this.otherPropSelections[pp.property.name] = pp.selections.split(',');

				let vals: string[] = [];
				if (pp.default_value_text)
					vals = pp.default_value_text.split(',');

				this.otherPropSelections[pp.property.name] = pp.selections.split(',');
				let i = 0;
				for (const otherPropSelection of this.otherPropSelections[pp.property.name]) {
					const val = vals.includes(otherPropSelection);
					this.theForm.addControl(pp.property.name + i, new UntypedFormControl(val));
					i++;
				} // for
			}
		}

		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		this.template.name = this.theForm.value.name;
		this.template.description = this.theForm.value.description;
		this.template.user_ids = this.theForm.value.user_ids;
		this.template.user_group_ids = this.theForm.value.user_group_ids;
		this.template.settings.keyTypes = this.theForm.value.keyTypes;
		this.template.settings.defaultKeyType = this.theForm.value.defaultKeyType;
		this.template.settings.requiresOrganization = this.theForm.value.requiresOrganization;
		this.template.settings.editOnly = this.theForm.value.editOnly;
		// this.template.settings.quickKey = this.theForm.value.quickKey;
		this.template.settings.limitOrgIDs = this.theForm.value.limitOrgIDs;
		this.template.settings.limitOrgGroupIDs = this.theForm.value.limitOrgGroupIDs;
		this.template.settings.excludeOrgIDs = this.theForm.value.excludeOrgIDs;
		this.template.settings.excludeOrgGroupIDs = this.theForm.value.excludeOrgGroupIDs;
		this.template.settings.orgTypes = this.theForm.value.orgTypes;

		this.template.settings.requiresSalesforceOpportunity = this.theForm.value.requiresSalesforceOpportunity;
		this.template.settings.salesforceOpportunityMustMatch = this.theForm.value.salesforceOpportunityMustMatch;
		this.template.settings.defaultActivations = this.theForm.value.defaultActivations;
		this.template.settings.minActivations = this.theForm.value.minActivations;
		this.template.settings.maxActivations = this.theForm.value.maxActivations;
		this.template.settings.maxKeys = this.theForm.value.maxKeys;
		this.template.settings.requiresCommercialType = this.theForm.value.requiresCommercialType;
		this.template.settings.requiresLinkedUsers = this.theForm.value.requiresLinkedUsers;
		this.template.settings.defaultShareWithSelf = this.theForm.value.defaultShareWithSelf;

		this.template.settings.expiryModes = this.theForm.value.expiryModes;
		this.template.settings.defaultExpiryMode = this.theForm.value.defaultExpiryMode;
		this.template.settings.defaultExpirationCount = this.theForm.value.defaultExpirationCount;
		this.template.settings.defaultExpirationUnit = this.theForm.value.defaultExpirationUnit;
		this.template.settings.maxExpirationCount = this.theForm.value.maxExpirationCount;
		this.template.settings.maxExpirationUnit = this.theForm.value.maxExpirationUnit;
		this.template.settings.warningDaysOfWeek = this.theForm.value.warningDaysOfWeek;
		this.template.settings.nonCheckAllFeatures = this.theForm.value.nonCheckAllFeatures;
		this.template.settings.nonAllUnlimitedLimits = this.theForm.value.nonAllUnlimitedLimits;
		this.template.settings.defaultCommercialType = this.theForm.value.defaultCommercialType;
		this.template.settings.defaultCommercialInfo = this.theForm.value.defaultCommercialInfo;
		this.template.settings.requiresMeterLabels = this.theForm.value.requiresMeterLabels;
		this.template.settings.defaultMeterLabel = this.theForm.value.defaultMeterLabel;

		if (this.meterProductSelections.length === 0) { // no meters
			this.template.settings.limitMeterProducts = [];
			this.template.settings.requiredMeterProducts = [];
			this.template.settings.defaultMeterLimit = null;
			this.template.settings.limitMeterReset = [];
		} else {
			this.template.settings.limitMeterProducts = this.theForm.value.limitMeterProducts;
			this.template.settings.requiredMeterProducts = this.theForm.value.requiredMeterProducts;
			this.template.settings.defaultMeterLimit = +this.theForm.value.defaultMeterLimit;
			this.template.settings.limitMeterReset = this.theForm.value.limitMeterReset;
		}

		// product properties
		for (const pp of this.verfiedProductProperties) {
			pp.can_be_changed = +this.theForm.value['pp_' + pp.property_id + '_can_be_changed'];

			if (pp.property.ptype === 'boolean') {
				pp.default_value_num = +this.theForm.value['pp_' + pp.property_id + '_default_value_num'];

			} else if (pp.property.ptype === 'number') {
				if (pp.property.allow_unlimited === 1) {
					const defUnlimChecked = +this.theForm.value['pp_' + pp.property_id + '_def_unlimited'];
					if (defUnlimChecked === 1) {
						pp.default_value_text = 'unlimited';
						pp.default_value_num = 0;
					} else {
						pp.default_value_num = +this.theForm.value['pp_' + pp.property_id + '_default_value_num'];
						pp.default_value_text = '';
					}
				} else {
					pp.default_value_num = +this.theForm.value['pp_' + pp.property_id + '_default_value_num'];
					pp.default_value_text = '';
				}
				pp.min_value = +this.theForm.value['pp_' + pp.property_id + '_min_value'];
				pp.max_value = +this.theForm.value['pp_' + pp.property_id + '_max_value'];
				if (pp.property.allow_unlimited === 1)
					pp.allow_unlimited = +this.theForm.value['pp_' + pp.property_id + '_allow_unlimited'];

			} else if (pp.property.ptype === 'other') {
				const vals: string[] = [];
				let i = 0;
				for (const otherPropSelection of this.otherPropSelections[pp.property.name]) {
					if (this.theForm.value[pp.property.name + i])
						vals.push(otherPropSelection);
					i++;
				}
				pp.default_value_text = vals.join(',');
			}
		}

		this.template.settings.productProperties = this.verfiedProductProperties;

		if (this.template.settings.defaultKeyType && this.template.settings.defaultKeyType !== ''
			&& !this.template.settings.keyTypes.includes(this.template.settings.defaultKeyType))
			this.errors.push('The default type must be one of the template\'s selected types.');

		if (this.template.settings.defaultExpiryMode && this.template.settings.defaultExpiryMode !== ''
			&& !this.template.settings.expiryModes.includes(this.template.settings.defaultExpiryMode))
			this.errors.push('The default expiry mode must be one of the template\'s selected expiry modes.');

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		try {
			let retTemplate: Models.KeyTemplate;
			if (this.pageMode === 'edit')
				retTemplate = await this.licensingAdminService.updateTemplate(this.template);
			else
				retTemplate = await this.licensingAdminService.addTemplate(this.template);

			if (retTemplate) {
				this.router.navigate([AppConstants.urls.licensingadmin + '/templates/' + retTemplate.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			console.log(e);
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		if (this.pageMode === 'new')
			this.router.navigate(['../..'], { relativeTo: this.route });
		else
			this.router.navigate(['..'], { relativeTo: this.route });
	}

	getProductPropertiesByType(type: string) {
		const sublist: Models.LicenseProductProperty[] = [];
		if (this.template && this.template.settings)
			for (const pp of this.verfiedProductProperties)
				if (pp.property && pp.property.ptype === type) sublist.push(pp);
		return sublist;
	}

	chopString(str: string, length: number = 25) {
		return TextTools.chopString(str, length, '...');
	}

	niceShowUser(value: number): string {
		if (value >= 0 && value < AppConstants.keyPropShowUserValues.length)
			return AppConstants.keyPropShowUserValues[value];
		else
			return '???-' + value;
	}

	niceBoolean(value: number): string {
		if (value === 0)
			return 'No';
		else if (value === 1)
			return 'Yes';
		else
			return '???-' + value;
	}

	setMeterToMax() {
		this.theForm.controls['defaultMeterLimit'].setValue(AppConstants.maxMeterLimit);
		this.onMeterLimitChange();
	}

	onMeterLimitChange() {
		const limit = +this.theForm.value['defaultMeterLimit'];
		if (limit && !isNaN(limit))
			this.niceMeterLimit = '[' + TextTools.formattedMB(limit) + ']';
		else
			this.niceMeterLimit = '';
	}

	// get message() { return this.theForm.get('message'); }
}
