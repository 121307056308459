<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.platforms" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" routerLink="..">Platforms</a>
			</li>
			<li *ngIf="editMode" class="breadcrumb-item active" aria-current="page">
				{{ platform.name }}
			</li>
			<li *ngIf="!editMode" class="breadcrumb-item active" aria-current="page">
				New Platform
			</li>
		</ol>
	</nav>
</div>

<form *ngIf="theForm" [formGroup]="theForm">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div class="form-group">
			<label for="ptype">Type</label>
			<div class="form-check" *ngFor="let ptype of ac.platformTypes">
				<input class="form-check-input" type="radio" formControlName="ptype" name="ptype" [id]="'ptype_' + ptype" [value]="ptype" />
				<label class="form-check-label" [for]="'ptype_' + ptype">
					{{ ac.platformTypeLabels[ptype] }}</label>
			</div>
		</div>

		<div class="form-group">
			<label for="name">Name*</label>
			<input type="text" id="name" formControlName="name" class="form-control" />
		</div>

		<div class="form-group">
			<label for="filename_keyword">Filename Keyword (for auto-builds)</label>
			<input type="text" id="filename_keyword" formControlName="filename_keyword" class="form-control" />
		</div>

		<div class="form-group">
			<label for="information">Information</label>
			<textarea id="information" formControlName="information" class="form-control" rows="4"></textarea>
		</div>

		<!-- <div class="form-group mb-2 small">* Required</div>

		<div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div> -->
	</div>

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>

</form>