import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { BuildsRoutingModule } from './builds-routing.module';
import { BuildComponent } from './build/build.component';
import { BuildListComponent } from './build-list/build-list.component';
import { BuildFormComponent } from './build-form/build-form.component';
import { AutoBuildFormComponent } from './auto-build-form/auto-build-form.component';
import { BuildNotificationFormComponent } from './build-notification-form/build-notification-form.component';

@NgModule({
	declarations: [BuildComponent, BuildListComponent, BuildFormComponent, AutoBuildFormComponent, BuildNotificationFormComponent],
	imports: [
		BuildsRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		NgSelectModule,
		NgbModule,
		SharedModule,
		FontAwesome
	]
})
export class BuildsModule { }
