import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-app-settings',
	templateUrl: './app-settings.component.html',
	styleUrls: ['./app-settings.component.scss']
})
export class AppSettingsComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {
	}

}
