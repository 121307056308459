import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject, interval, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';

import { OrganizationGroupsService } from '../organization-groups.service';
import { AuthService } from 'client/app/services/auth.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-organization-group-list',
	templateUrl: './organization-group-list.component.html',
	styleUrls: ['./organization-group-list.component.scss']
})
export class OrganizationGroupListComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	textTools = TextTools;

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	// 'standard' list stuff
	loading = true;
	refreshing = false;

	items: Models.OrganizationGroup[] = [];
	itemsToShow: Models.OrganizationGroup[] = [];

	// other stuff
	private userSubscription: Subscription;
	canAdd = false;

	now = new Date();

	// new table stuff
	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = 'cp-org-groups-list';

	fieldsToShow: string[] = [];

	columnDefs: any[] = [
		{
			field: 'is_enabled',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Enabled/Disabled',
			icon: 'power-off'
		}, {
			field: 'name',
			type: 'baseText',
			sortType: 'text',
			label: 'Name',
			toolTip: null,
			icon: null
		}, {
			field: '__num_orgs',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Number of Organizations',
			icon: AppConstants.sectionIcons.organizations
		}, {
			field: '__num_products_platforms',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Number of Product/Platforms',
			icon: AppConstants.sectionIcons.platforms
		}, {
			field: '__num_builds',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Number of Direct Access Builds',
			icon: AppConstants.sectionIcons.builds
		}, {
			field: '__num_documents',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Number of Direct Access Documents',
			icon: AppConstants.sectionIcons.documents
		}, {
			field: '__days_since_add',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Days Since Added',
			icon: 'clock'
		}
	];

	constructor(
		private organizationGroupsService: OrganizationGroupsService,
		private authService: AuthService,
	) { }


	// ------------------------------------------------------------------------
	ngOnInit(): void {
		this.loading = true;

		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.canAdd = authUser && ValidationTools.checkAccess(authUser, 'manage-organization-groups');
			this.loadData();
		});
	}

	// ------------------------------------------------------------------------
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ------------------------------------------------------------------------
	async loadData(refresh: boolean = false) {
		this.loading = true;
		if (refresh)
			await this.organizationGroupsService.refreshAll().toPromise();

		this.items = this.organizationGroupsService.getAll();

		for (const item of this.items) {
			item['__num_orgs'] = item.org_ids.length;
			item['__num_products_platforms'] = item.product_platforms.length;
			item['__num_builds'] = item.build_ids.length;
			item['__num_documents'] = item.document_ids.length;
			item['__days_since_add'] = MiscTools.diffDays(this.now, item.added_on, true);
		} // for

		this.fieldsToShow = [];
		for (const cd of this.columnDefs)
			this.fieldsToShow.push(cd.field);

		this.loading = false;

		await MiscTools.delay(100);
		if (this.pagingSearchBar)
			this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'name', 'asc');
	}

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.OrganizationGroup[] = [];
		for (const item of this.items) {
			let match: boolean = true;

			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.name.toLowerCase().includes(w)
						// || (item.free_access_token && item.free_access_token.toLowerCase().includes(w))
						// || (item['__uses'] && item['__uses'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}
}
