import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { UsageDashboardComponent } from './usage-dashboard/usage-dashboard.component';
import { ChangeLogComponent } from './change-log/change-log.component';

@NgModule({
	declarations: [MainDashboardComponent, UsageDashboardComponent, ChangeLogComponent],
	imports: [
		DashboardRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		NgSelectModule,
		NgbModule,
		SharedModule,
		FontAwesome
	]
})
export class DashboardModule { }
