
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class ResetPasswordService {

	constructor(private http: HttpClient) { }

	// call back-end to add an item
	async resetPassword(email: string, token: string, password: string, password2: string) {
		try {
			const result = await this.http.post<Models.User>(AppConstants.apiUrl + AppConstants.apiUrls.auth
				+ AppConstants.apiUrls.passwordreset, { email, token, password, password2 }).toPromise();

			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}


}
