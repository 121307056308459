import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import ValidationTools from 'appshared/validation-tools';

import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { LicensingService } from '../../licensing/licensing.service';
import { AuthService } from 'client/app/services/auth.service';
import { LicensingAdminService } from '../../licensing-admin/licensing-admin.service';

@Component({
	selector: 'app-offline-add-form',
	templateUrl: './offline-add-form.component.html',
	styleUrls: ['./offline-add-form.component.scss']
})
export class OfflineAddFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;
	errors: string[] = [];
	theForm: UntypedFormGroup;

	id: number;
	activation: Models.LPActivation;

	loading = true;
	saving = false;

	licenseFormatSelections: any[] = [
		{ value: 'rlm', label: 'RLM - legacy license format supported in pre v17 systems' },
		{ value: 'zlm', label: 'ZLM - "new" license format supported in v17+ systems' },
		{ value: 'both', label: 'Both - combination license format that may be supported in legacy systems' },
	];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private licensingService: LicensingService,
		private licensingAdminService: LicensingAdminService,
		private uiAlertsService: UiAlertsService
	) {
		this.route.paramMap.subscribe((params) => {
			this.id = +params.get('id');
		});
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			if (authUser) {
				this.authUser = authUser;
				this.initForm();
			}
		});

	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async initForm() {
		this.activation = await this.licensingService.getOne(this.id);
		if (!this.activation || this.activation == null || this.activation.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		}

		if (!this.activation.type.startsWith(AppConstants.offlinePrefix)) {
			this.uiAlertsService.addMsg('This key cannot have offline activations.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.onCancel();
			return;
		} else if (this.activation.count >= this.activation.max) {
			this.uiAlertsService.addMsg('This key does not have any more activations.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.onCancel();
			return;
		} else if (!this.activation.zcp_template_id || this.activation.zcp_template_id === 0) {
			this.uiAlertsService.addMsg('This key must be tied to a template.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.onCancel();
			return;
		}

		// let's figure out access.....
		const userTemplateIDs = await this.licensingAdminService.getUserTemplateIDs();
		if (!ValidationTools.checkAccess(this.authUser, 'manage-keys')
			|| !userTemplateIDs || !userTemplateIDs.includes(this.activation.zcp_template_id)) {
			this.onCancel();
			return;
		}

		// this.theForm = new FormGroup({
		// 	hostids: new FormControl(null, [Validators.required]),
		// 	useAlternateServer: new FormControl(null),
		// });

		this.theForm = new UntypedFormGroup({
			hostids: new UntypedFormControl(null, [Validators.required]),
			format: new UntypedFormControl('rlm', [Validators.required]),
		});

		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const hostids = this.theForm.value.hostids;
		const array = hostids.match(/[^\r\n]+/g);
		const format = this.theForm.value.format;

		let useAlternateServer: boolean = false;
		// let useAlternateServer: boolean = this.theForm.value.useAlternateServer;

		try {
			const response = await this.licensingService.offlineLicensesForHosts(this.id, array, useAlternateServer, format);
			this.saving = false;
			this.onCancel();
		} catch (e) {
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
		}

		// if (numAdded === 1)
		// 	this.uiAlertsService.addMsg('Linked one key.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		// else if (numAdded > 1)
		// 	this.uiAlertsService.addMsg('Linked ' + numAdded + ' keys.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

		// if (numSkipped === 1)
		// 	this.uiAlertsService.addMsg('Ignoring one key. Maximum # of keys reached.', 'warning');
		// else if (numSkipped > 1)
		// 	this.uiAlertsService.addMsg('Ignoring ' + numSkipped + ' keys. Maximum # of keys reached.', 'warning');

		// this.saving = false;
		// this.onCancel();
	}

	onCancel() {
		this.router.navigate(['/' + AppConstants.urls.licensing, 'activation', this.id]);
	}

	get hostids() { return this.theForm.get('hostids'); }
}
