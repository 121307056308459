import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxDropzoneModule } from 'ngx-dropzone';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { MyFileSharingRoutingModule } from './my-file-sharing-routing.module';
import { FileSharingListComponent } from './file-sharing-list/file-sharing-list.component';
import { FileSharingBundleComponent } from './file-sharing-bundle/file-sharing-bundle.component';
import { AddFilesFormComponent } from './add-files-form/add-files-form.component';
import { BasicSendFormComponent } from './basic-send-form/basic-send-form.component';


@NgModule({
	declarations: [
		FileSharingListComponent,
		FileSharingBundleComponent,
		AddFilesFormComponent,
		BasicSendFormComponent
	],
	imports: [
		CommonModule,
		MyFileSharingRoutingModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		ClipboardModule,
		SharedModule,
		FontAwesome,
		NgxDropzoneModule
	]
})
export class MyFileSharingModule { }
