import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { MyKeysRoutingModule } from './my-keys-routing.module';
import { LicenseKeyListComponent } from './license-key-list/license-key-list.component';
import { LicenseKeyFormComponent } from './license-key-form/license-key-form.component';
import { AddLicenseKeysFormComponent } from './add-license-keys-form/add-license-keys-form.component';
import { ShareLicenseKeysFormComponent } from './share-license-keys-form/share-license-keys-form.component';
import { AddHostidsFormComponent } from './add-hostids-form/add-hostids-form.component';
import { HostidFormComponent } from './hostid-form/hostid-form.component';
import { LicenseMultiComponent } from './license-multi/license-multi.component';
import { SnoozeFormComponent } from './snooze-form/snooze-form.component';
import { MyOrgBillingCodesFormComponent } from './my-org-billing-codes-form/my-org-billing-codes-form.component';

@NgModule({
	declarations: [LicenseKeyListComponent, LicenseKeyFormComponent, AddLicenseKeysFormComponent,
		ShareLicenseKeysFormComponent, AddHostidsFormComponent, HostidFormComponent,
		LicenseMultiComponent, SnoozeFormComponent, MyOrgBillingCodesFormComponent],
	imports: [
		MyKeysRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		ClipboardModule,
		SharedModule,
		FontAwesome
	]
})
export class MyKeysModule { }
