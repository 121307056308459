import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { ZenCustomersRoutingModule } from './zen-customers-routing.module';
import { ZenCustomersListComponent } from './zen-customers-list/zen-customers-list.component';
import { ZenmasterFormComponent } from './zenmaster-form/zenmaster-form.component';
import { ZenCustomerComponent } from './zen-customer/zen-customer.component';
import { ZenCustomersReportsComponent } from './zen-customers-reports/zen-customers-reports.component';
import { ZenCustomersReportUsersComponent } from './zen-customers-report-users/zen-customers-report-users.component';
import { ZenCustomersReportClustersComponent } from './zen-customers-report-clusters/zen-customers-report-clusters.component';
import { ZenCustomersReportSourcesComponent } from './zen-customers-report-sources/zen-customers-report-sources.component';
import { ZenCustomersReportTargetsComponent } from './zen-customers-report-targets/zen-customers-report-targets.component';
import { ZenCustomersReportChannelsComponent } from './zen-customers-report-channels/zen-customers-report-channels.component';
import { ZenCustomersReportObjectsComponent } from './zen-customers-report-objects/zen-customers-report-objects.component';
import { ZenCustomersReportStaffComponent } from './zen-customers-report-staff/zen-customers-report-staff.component';
import { ZenmasterAdminFormComponent } from './zenmaster-admin-form/zenmaster-admin-form.component';
import { ZenmasterAddFormComponent } from './zenmaster-add-form/zenmaster-add-form.component';
import { SearchIncidentsComponent } from './search-incidents/search-incidents.component';
import { ZenmasterMarketplaceFormComponent } from './zenmaster-marketplace-form/zenmaster-marketplace-form.component';

@NgModule({
	declarations: [ZenCustomersListComponent,
		ZenCustomerComponent,
		ZenCustomersReportsComponent,
		ZenCustomersReportUsersComponent,
		ZenCustomersReportClustersComponent,
		ZenCustomersReportSourcesComponent,
		ZenCustomersReportTargetsComponent,
		ZenCustomersReportChannelsComponent,
		ZenCustomersReportObjectsComponent,
		ZenmasterFormComponent,
		ZenCustomersReportStaffComponent,
		ZenmasterAdminFormComponent,
		ZenmasterAddFormComponent,
		SearchIncidentsComponent,
		ZenmasterMarketplaceFormComponent],
	imports: [
		ZenCustomersRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		NgSelectModule,
		NgbModule,
		SharedModule,
		FontAwesome,
		ClipboardModule
	]
})
export class ZenCustomersModule { }
