import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';

@Component({
	selector: 'app-not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
	@Input() message = 'Sorry, can\'t find that!';
	// errorMessage = 'Sorry, can\'t find that!';

	constructor(private route: ActivatedRoute) { }

	ngOnInit(): void {
		this.route.data.subscribe(
			(data: Data) => {
				if (data.message && data.message !== '') {
					this.message = data.message;
				}
			}
		);
	}

}
