import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { EmailQueueRoutingModule } from './email-queue-routing.module';

import { ViewQueueLogsComponent } from './view-queue-logs/view-queue-logs.component';
import { ViewErrorsComponent } from './view-errors/view-errors.component';

@NgModule({
	declarations: [ViewQueueLogsComponent, ViewErrorsComponent],
	imports: [
		EmailQueueRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		SharedModule,
		FontAwesome
	]
})
export class EmailQueueModule { }
