import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import { RoleGuard } from 'client/app/guards/role.guard';

import { ProductsResolverService } from '../products/products-resolver.service';
import { PlatformsResolverService } from '../platforms/platforms-resolver.service';

import { MyNotificationsComponent } from './my-notifications/my-notifications.component';

const routes: Routes = [
	{
		path: '',
		component: MyNotificationsComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole },
		resolve: [ProductsResolverService, PlatformsResolverService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class MyNotificationsRoutingModule { }
