import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import { LoginService } from '../components/login/login.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

// import { ErrorService } from './../components/error/error.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private loginService: LoginService,
		private uiAlertsService: UiAlertsService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError(err => {

				if ([500].indexOf(err.status) !== -1) { // catch general server errors
					const errorTxt = err.error.error || err.error.message || err.statusText;
					this.uiAlertsService.addMsg(errorTxt, 'error', 'GENERAL');

				} else if ([401].indexOf(err.status) !== -1) { // auto logout if 401 Unauthorized response returned from api

					const url = this.route.snapshot['' + '_routerState'].url;
					// console.log('url');
					// console.log(url);
					// console.log('login');
					// console.log(AppConstants.urls.login);

					if (url !== '/' + AppConstants.urls.login) {
						// console.log('Caught error in ErrorInterceptor....');
						this.uiAlertsService.addMsg('You are not currently logged in.  Perhaps your session expired.',
							'info', 'LOGIN', true, 600);

						this.loginService.catchLastURL('ErrorInterceptor - ' + request.urlWithParams);
						this.loginService.logout(false);
						this.router.navigate([AppConstants.urls.login]);
					}

					// } else if ([403].indexOf(err.status) !== -1) { // if 403 Forbidden response returned from api
					// 	const errorTxt = err.error.error || err.error.message || err.statusText;
					// 	this.uiAlertsService.addMsg(errorTxt, 'error', 'FORBIDDEN');

				} else if ([404].indexOf(err.status) !== -1) {
					this.router.navigate([AppConstants.urls.notfound]);

				}

				// const error = err.error.error || err.error.message || err.statusText;
				// return throwError(error);
				return throwError(err);
			})
		);
	}
}
