import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';

import { AuthService } from 'client/app/services/auth.service';
import { LicensingAdminService } from '../licensing-admin.service';
import { LicensingService } from '../../licensing/licensing.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-protocol-set-form',
	templateUrl: './protocol-set-form.component.html',
	styleUrls: ['./protocol-set-form.component.scss']
})

export class ProtocolSetFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	sharedLicenseTools = SharedLicenseTools;

	// 'standard' edit stuff
	id: number;
	protocolSet: Models.ProtocolSet;
	errors: string[] = [];
	editMode = false;
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	allProtocols: string[] = [];

	protocolBlocks: any[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private licensingService: LicensingService,
		private licensingAdminService: LicensingAdminService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
		});
	}

	ngOnInit(): void {
		this.initForm();
	}

	ngOnDestroy() {
	}

	async initForm() {
		// this.products = this.productsService.getAll();

		this.allProtocols = await this.licensingService.getProtocols();


		const bxProtocols: string[] = [];
		const privProtocols: string[] = [];
		const transcodeProtocols: string[] = [];
		const mcProtocols: string[] = [];
		const zmProtocols: string[] = [];

		for (const protocol of this.allProtocols) {
			if (protocol.startsWith('mediaconnect_')) {
				mcProtocols.push(protocol);
			} else if (protocol.startsWith('zm_')) {
				zmProtocols.push(protocol);
			} else {
				if (protocol.includes('transcode'))
					transcodeProtocols.push(protocol);
				else if (protocol.startsWith('private_'))
					privProtocols.push(protocol);
				else
					bxProtocols.push(protocol);
			} // if
		} // for

		if (bxProtocols.length > 0)
			this.protocolBlocks.push({
				label: 'Broadcaster\nNon-Private',
				protocols: bxProtocols
			});

		if (privProtocols.length > 0)
			this.protocolBlocks.push({
				label: 'Broadcaster\nPrivate',
				protocols: privProtocols
			});

		if (transcodeProtocols.length > 0)
			this.protocolBlocks.push({
				label: 'Broadcaster\nTranscode',
				protocols: transcodeProtocols
			});

		if (mcProtocols.length > 0)
			this.protocolBlocks.push({
				label: 'ZEN Master Tracking\n MediaConnect',
				protocols: mcProtocols
			});

		if (zmProtocols.length > 0)
			this.protocolBlocks.push({
				label: 'ZEN Master Tracking\nNon-MediaConnect',
				protocols: zmProtocols
			});

		if (this.id && this.id !== 0) {
			this.protocolSet = MiscTools.deepClone(await this.licensingAdminService.getProtocolSet(this.id));
			if (!this.protocolSet || this.protocolSet == null || this.protocolSet.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
				return;
			} // if
			this.editMode = true;
		} else {
			this.protocolSet = new Models.ProtocolSet(0, '', '', '', '');
			this.protocolSet.protocolsArr = [];
		}

		const formParts: any = {
			name: new UntypedFormControl(this.protocolSet.name, [Validators.required]),
			description: new UntypedFormControl(this.protocolSet.description),
		};

		// add bits for each form element...
		for (const protocol of this.allProtocols) {
			const checked = this.protocolSet.protocolsArr.includes(protocol);
			formParts['protocol_' + protocol.toLowerCase().replace('-', '_')] = new UntypedFormControl(checked, [])
		} // for

		this.theForm = new UntypedFormGroup(formParts);
		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		this.protocolSet.name = this.theForm.value.name;
		this.protocolSet.description = this.theForm.value.description;

		this.protocolSet.protocolsArr = [];
		// add bits for each form element...
		for (const protocol of this.allProtocols) {
			const checked = this.theForm.value['protocol_' + protocol.toLowerCase().replace('-', '_')];
			if (checked) this.protocolSet.protocolsArr.push(protocol);
		} // for
		this.protocolSet.protocols = this.protocolSet.protocolsArr.join(',');

		if (this.protocolSet.protocolsArr.length === 0)
			this.errors.push('You must select at least one protocol.');

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		try {
			let retProtocolSet: Models.ProtocolSet;
			if (this.editMode)
				retProtocolSet = await this.licensingAdminService.updateProtocolSet(this.protocolSet);
			else
				retProtocolSet = await this.licensingAdminService.addProtocolSet(this.protocolSet);

			if (retProtocolSet) {
				this.router.navigate([AppConstants.urls.licensingadmin + '/protocol-sets/' + retProtocolSet.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	checkAllHandler(event, protocolsToSort: string[]) {
		for (const protocol of protocolsToSort) {
			this.theForm.controls['protocol_' + protocol.toLowerCase().replace('-', '_')].setValue(event.target.checked);
		} // for
	}
}
