import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class ResizeService {
  private current_size = new BehaviorSubject<number>(0);
  get getCurrentSize() {
    return this.current_size.asObservable();
  }
  setCurrentSize(val: number) {
    this.current_size.next(val);
  }
}
