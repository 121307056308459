import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';

import { MyFileSharingService } from '../my-file-sharing.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-basic-send-form',
	templateUrl: './basic-send-form.component.html',
	styleUrls: ['./basic-send-form.component.scss']
})
export class BasicSendFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;
	loading = true;

	id: number;
	errors: string[] = [];
	theForm: UntypedFormGroup;
	saving = false;

	destinationSelections: any[] = [
		{
			value: 'support',
			label: 'Zixi Customer Support'
		},
		// {
		// 	value: 'account',
		// 	label: 'Your Zixi Account Team - Account Manager and Sales Engineer(s)'
		// }
	];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private myFileSharingService: MyFileSharingService) {
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			if (authUser) {
				this.authUser = authUser;
				// this.canManage = (authUser && ValidationTools.checkAccess(authUser, 'manage-license-key-setup'));
				this.initForm();
			} // if
		});

	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async initForm() {
		const accountTeam: any[] = await this.myFileSharingService.getAccountTeam();

		for (const member of accountTeam)
			this.destinationSelections.push(
				{
					value: 'account-team-member:' + member.id,
					label: member.name + ' (' + member.role + ')'
				});


		this.theForm = new UntypedFormGroup({
			destination: new UntypedFormControl("support", [Validators.required]),
			information: new UntypedFormControl('')
		});

		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const destination = this.theForm.value.destination;
		const information = this.theForm.value.information;

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		try {
			let retBundle: Models.Bundle = await this.myFileSharingService.startBasicSend(destination, information);

			if (retBundle) {
				// this.uiAlertsService.addMsg('OK. ', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
				this.router.navigate([AppConstants.urls.myfilesharing + '/' + retBundle.id + '/files']);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}

		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}
}
