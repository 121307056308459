import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import { LoginService } from '../login.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-sso',
	templateUrl: './sso.component.html',
	styleUrls: ['./sso.component.scss']
})
export class SsoComponent implements OnInit, OnDestroy {
	private paramSub: Subscription;
	action = '';

	redirurl = '';

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private loginService: LoginService,
		private uiAlertsService: UiAlertsService) { }

	ngOnInit(): void {
		this.paramSub = this.route.params.subscribe((params: Params) => {
			this.action = params.action;

			if (this.action === 'auth') {
				this.loginService.getSSOUrl().subscribe(resp => {
					// console.log(resp.redir);
					this.redirurl = resp.redir;
					document.location.href = this.redirurl;
				}, error => {
					this.uiAlertsService.addMsg(error, 'error', 'LOGIN');
					this.router.navigate([AppConstants.urls.login]);
				});

			} else if (this.action === 'return') {
				this.redirurl = '';

				this.route.queryParams.subscribe((qparams: Params) => {
					const state = qparams.state;
					const code = qparams.code;

					this.loginService.checkSSOReturn(state, code).subscribe(authdUser => {
						// console.log('back from login service');
						// console.log(authdUser);

						// assuming it works... redir to login page or default inside page...
						if (!!authdUser && authdUser.id && authdUser.role && +authdUser.id !== 0 && authdUser.role !== '') {
							let lastUrl = this.loginService.getLastURL();

							// console.log('lastUrl from loginService = ' + lastUrl);

							if (!lastUrl || lastUrl === '' || lastUrl === '/' + AppConstants.urls.login) {
								lastUrl = AppConstants.defaultLandingPage;
							}
							this.loginService.setLastURL('');
							this.uiAlertsService.clearMsgByCode('LOGIN');

							// console.log('routing to ' + lastUrl);

							this.router.navigate([lastUrl]);
						} else {
							// console.log('routing to login');
							this.router.navigate([AppConstants.urls.login]);
						}
					}, error => {
						this.uiAlertsService.addMsg(error, 'error', 'LOGIN');
						this.router.navigate([AppConstants.urls.login]);
					});
				});
			}
		});
	}

	ngOnDestroy() {
		this.paramSub.unsubscribe();
	}
}
