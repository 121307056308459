<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.packages" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" routerLink="../..">Packages</a>
			</li>
			<li class="breadcrumb-item" aria-current="page">
				{{ package.name }}
			</li>
			<li *ngIf="editMode" class="breadcrumb-item active" aria-current="page">
				{{ packageKeyTemplate.name }}
			</li>
			<li *ngIf="!editMode" class="breadcrumb-item active" aria-current="page">
				New Key Template for Package
			</li>
		</ol>
	</nav>
</div>

<form *ngIf="theForm" [formGroup]="theForm">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div class="form-group">
			<label for="name">Name</label>
			<input type="text" id="name" formControlName="name" class="form-control" minlength="1" />
		</div>

		<div class="form-group">
			<label for="template_id">Key Template</label>
			<ng-select [items]="templates" bindValue="id" bindLabel="name" id="template_id" formControlName="template_id">
			</ng-select>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="allow_multiple" formControlName="allow_multiple" />
			<label class="form-check-label" for="allow_multiple">
				Let Users add activations when the key no longer has any more or create a new key when existing key has
				expired or reach its maximum activations.
			</label>
		</div>

		<!-- <div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div>

		<div class="cp-form-padding"></div>
 -->
	</div>

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>

</form>