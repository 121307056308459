import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import PopOverTools from 'appshared/popover-tools';

import { OrganizationsService } from '../organizations.service';
import { UsersService } from '../../users/users.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { ZenCustomersService } from '../../zen-customers/zen-customers.service';

@Component({
	selector: 'app-import-zen-master-users',
	templateUrl: './import-zen-master-users.component.html',
	styleUrls: ['./import-zen-master-users.component.scss']
})
export class ImportZenMasterUsersComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	popOverTools = PopOverTools;

	// 'standard' view stuff
	id: number;
	organization: Models.Organization;
	loading = true;
	importing = false;
	theForm: UntypedFormGroup;

	// other stuff
	allUsers: Models.User[] = [];
	contacts: Models.User[] = [];

	onboardingStatus: string[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private organizationsService: OrganizationsService,
		private zenCustomersService: ZenCustomersService,
		private usersService: UsersService) {

		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.organization = this.organizationsService.getOne(this.id);
			if (!this.organization || this.organization == null || this.organization.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
			}
			this.onboardingStatus = ValidationTools.getOrganizationIssues(this.organization);
		});
	}

	ngOnInit(): void {
		this.loadTables();
	}

	ngOnDestroy() {
	}

	async loadTables() {
		this.allUsers = this.usersService.getAll();

		// this.contacts = await this.organizationsService.fetchSalesforceContacts(this.organization.salesforce_account_id);

		this.theForm = new UntypedFormGroup({
			welcomeUser: new UntypedFormControl(true)
		});

		const zenmasters = [];
		const allZenmasters = this.zenCustomersService.getAll();
		for (const z of allZenmasters)
			if (z.zcp_org_id && z.zcp_org_id === this.id)
				zenmasters.push(z);

		this.contacts = [];

		const tmpContacts: Models.User[] = [];
		const addedEmails: string[] = [];

		for (const zm of zenmasters) {
			const zenUsers = await this.zenCustomersService.getZenUsers(zm.id);
			for (const zenUser of zenUsers) {
				if (!addedEmails.includes(zenUser.email.toLowerCase())) {
					addedEmails.push(zenUser.email.toLowerCase())
					tmpContacts.push(new Models.User(0, 0, zenUser.email.toLowerCase(), '', '', '', zenUser.name, '', ''));
				} // if
			} // for
		} // for
		this.contacts = tmpContacts;

		this.evaluateContacts();

		this.loading = false;
	}

	evaluateContacts() {
		this.contacts.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

		for (const contact of this.contacts) {
			let status: string = '';
			let userObj: Models.User = null;

			const idx = MiscTools.findIndexGeneric(this.allUsers, 'email', contact.email.toLowerCase());
			if (idx === -1) {
				status = 'no-user';
				// tbd - maybe check to see if another user is already in this org with the same name, but different e-mail

			} else {
				userObj = this.allUsers[idx];
				if (userObj.org_id && userObj.org_id === this.id) {
					if (!userObj.last_login && (!userObj.reset_password_token || userObj.reset_password_token === ''))
						status = 'imported-not-welcomed';
					else
						status = 'imported-ready';

				} else if (userObj.org_id && userObj.org_id !== 0) {
					status = 'different-organization';

				} else {
					status = 'no-organization';
				} // if
			} // if

			contact['import-status'] = status;
			contact['existing-user'] = userObj;
		} // for

	} // if

	async createAccount(contact: Models.User) {
		try {
			this.importing = true;

			const welcomeUser = this.theForm.value.welcomeUser;

			contact.org_id = this.id;
			contact.role = AppConstants.basicUserRole;
			// contact.information = 'Imported from SalesForce via UI.';

			const newUser = await this.usersService.addOne(contact, 'ZEN Master', welcomeUser);
			this.allUsers.push(newUser);

			this.evaluateContacts();

			this.organization.num_users++;
			this.onboardingStatus = ValidationTools.getOrganizationIssues(this.organization);

			this.importing = false;
			// this.uiAlertsService.addMsg('User imported.', 'info', 'adduser', false, 5);
		} catch (error) {
			this.uiAlertsService.addMsg(error.message, 'error');
			this.importing = false;
		}
	}

	async linkAccount(contact: Models.User) {
		if (!contact['existing-user']) return;
		const idx = MiscTools.findIndex(this.allUsers, contact['existing-user'].id);
		if (idx === -1) return;

		try {
			this.importing = true;

			await this.usersService.setOrganization(contact['existing-user'].id, this.id);

			this.allUsers[idx].org_id = this.id;
			this.organization.num_users++;

			this.evaluateContacts();
			this.onboardingStatus = ValidationTools.getOrganizationIssues(this.organization);

			this.importing = false;
		} catch (error) {
			this.uiAlertsService.addMsg(error.message, 'error');
			this.importing = false;
		}
	}

	async sendWelcomeMessage(contact: Models.User) {
		if (!contact['existing-user']) return;
		const idx = MiscTools.findIndex(this.allUsers, contact['existing-user'].id);
		if (idx === -1) return;

		if (confirm('Are you sure you want to send this user a welcome message?')) {
			const result = await this.usersService.sendWelcomeMessage(contact['existing-user'].id);
			if (result) {
				this.allUsers[idx] = result;

				this.uiAlertsService.addMsg(
					'A password reset token has been set for ' + result.name + ' and they\'ve been sent a \"welcome\" E-Mail.',
					'info',
					'WELCOME_MESSAGE',
					false,
					AppConstants.standardMessageAutoCloseTimeSecs
				);

				this.organization.num_welcomed++;
				this.onboardingStatus = ValidationTools.getOrganizationIssues(this.organization);
				this.evaluateContacts();
			} else {
				return false;
			}
		}
	}
}
