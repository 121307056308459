import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import ct from 'countries-and-timezones';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';

import { UsersService } from '../users.service';
import { AuthService } from 'client/app/services/auth.service';
import { ProductsService } from '../../products/products.service';
import { PlatformsService } from '../../platforms/platforms.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { MyNotificationsService } from '../../my-notifications/my-notifications.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { LicensingService } from '../../licensing/licensing.service';

@Component({
	selector: 'app-user-notifications-form',
	templateUrl: './user-notifications-form.component.html',
	styleUrls: ['./user-notifications-form.component.scss']
})
export class UserNotificationsFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	id: number;
	user: Models.User;

	showStaffSettings: boolean = false;

	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	startHourSelections: any[] = [];
	endHourSelections: any[] = [];
	tzSelections: any[] = [];
	buildProductSelections: any[] = [];
	docProductSelections: any[] = [];
	// platformSelections: any[] = [];
	expirySelections: any[] = [];
	usageSelections: any[] = [];
	savedSearchSelections: any[] = [];
	zenTypeSelections: any[] = [];
	orgSelections: any[] = [];

	showSaved = false;
	savedTimer = null;

	showBuildFilters = false;
	showDocumentFilters = false;
	showZenTypeFilters = false;


	// other stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private usersService: UsersService,
		private productsService: ProductsService,
		private platformsService: PlatformsService,
		private licensingService: LicensingService,
		private organizationsService: OrganizationsService,
		private myNotificationsService: MyNotificationsService,
		private authService: AuthService
	) {
		this.route.paramMap.subscribe((params) => {
			this.id = +params.get('id');
		});
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;

			// if (!ValidationTools.checkAccess(authUser, 'manage-keys')) {
			// 	this.onCancel();
			// 	return;
			// };

			this.loadData();
		});

	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData() {

		this.user = this.usersService.getOne(this.id);

		if (!this.user || this.user == null || this.user.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		}

		if (this.user.role !== AppConstants.basicUserRole && !ValidationTools.checkRole(this.authUser.role, AppConstants.adminUserRole)) {
			this.onCancel();
			return;
		}

		if (!this.user.notifications) {
			this.onCancel();
			return;
		}

		this.showStaffSettings = ValidationTools.checkRole(this.user.role, AppConstants.staffUserRole);

		if (!this.user.notifications.timezone || this.user.notifications.timezone === '')
			this.user.notifications.timezone = 'UTC';

		this.showBuildFilters = this.user.notifications && this.user.notifications.receiveBuildMessages
			&& (this.user.notifications.includedBuildProductIDs.length > 0
				|| this.user.notifications.excludedBuildProductIDs.length > 0
				|| this.user.notifications.includedBuildPlatformIDs.length > 0
				|| this.user.notifications.excludedBuildPlatformIDs.length > 0);

		this.showDocumentFilters = this.user.notifications && this.user.notifications.receiveDocumentMessages
			&& (this.user.notifications.includedDocumentProductIDs.length > 0
				|| this.user.notifications.excludedDocumentProductIDs.length > 0);

		// SETUP SELECTORS
		const startPrefix = AppConstants.notificationPropLabels.startHour + ' ';
		this.startHourSelections = [];
		for (let i = 1; i <= 24; i++)
			this.startHourSelections.push({ value: i, label: startPrefix + TextTools.niceHour(i) });

		const endPrefix = AppConstants.notificationPropLabels.endHour + ' ';
		this.endHourSelections = [];
		for (let i = 1; i <= 24; i++)
			this.endHourSelections.push({ value: i, label: endPrefix + TextTools.niceHour(i) });

		const countries = ct.getAllCountries();
		const timezones = ct.getAllTimezones();

		const tzCodes = await this.myNotificationsService.fetchTimezones();

		this.tzSelections = [];
		// for (const tz in timezones) {
		for (const tz of tzCodes) {
			let label = tz;
			if (timezones[tz].name) label = timezones[tz].name;
			if (timezones[tz].country)
				if (countries[timezones[tz].country])
					label += ' (' + countries[timezones[tz].country].name + ')';
				else
					label += ' (' + timezones[tz].country + ')';

			if (timezones[tz].utcOffsetStr) {
				label += ' [' + timezones[tz].utcOffsetStr;
				if (timezones[tz].dstOffsetStr && timezones[tz].dstOffsetStr !== timezones[tz].utcOffsetStr)
					label += '/' + timezones[tz].dstOffsetStr;
				label += ']';
			} // if

			this.tzSelections.push({ value: tz, label });
		} // for

		const products: Models.Product[] = this.productsService.getAll();
		products.sort((a, b) => (a.name > b.name) ? 1 : -1);

		this.buildProductSelections = [];
		for (const ptype of AppConstants.productTypes)
			for (const product of products)
				if (product.ptype === ptype
					&& !ValidationTools.hasFlag(product, 'private_only')
					&& ValidationTools.hasFlag(product, 'may_have_builds'))
					this.buildProductSelections.push({ value: product.id, label: product.name });

		this.docProductSelections = [];
		for (const ptype of AppConstants.productTypes)
			for (const product of products)
				if (product.ptype === ptype
					&& !ValidationTools.hasFlag(product, 'private_only')
					&& ValidationTools.hasFlag(product, 'may_have_documents'))
					this.docProductSelections.push({ value: product.id, label: product.name });

		// const platforms: Models.Platform[] = this.platformsService.getAll();
		// this.platformSelections = [];
		// for (const platform of platforms)
		// 	this.platformSelections.push({ value: platform.id, label: platform.name });

		const expiryPrefix = AppConstants.notificationPropLabels.expirationDays + ' ';
		const expirySuffix1 = ' week before expiration';
		const expirySuffix = ' weeks before expiration';
		this.expirySelections = [];
		for (let i = AppConstants.minLicenseWarningDays; i <= AppConstants.maxLicenseWarningDays; i += 7)
			if (i / 7 === 1)
				this.expirySelections.push({ value: i, label: expiryPrefix + (i / 7) + expirySuffix1 });
			else
				this.expirySelections.push({ value: i, label: expiryPrefix + (i / 7) + expirySuffix });

		const usagePrefix = AppConstants.notificationPropLabels.usagePercentage + ' ';
		const usageSuffix = ' percent';
		this.usageSelections = [];
		for (let i = AppConstants.minLicenseUsagePercentage; i <= AppConstants.maxLicenseUsagePercentage; i += 5)
			this.usageSelections.push({ value: i, label: usagePrefix + i + usageSuffix });

		if (this.user.notifications.keysReportFrequency == null || this.user.notifications.keysReportFrequency === '')
			this.user.notifications.keysReportFrequency = AppConstants.defaultKeysReportFrequency;

		this.savedSearchSelections = [];
		this.zenTypeSelections = [];
		this.orgSelections = [];

		if (this.showStaffSettings) {
			const savedSearches = await this.licensingService.getSavedSearchesForUser(this.id);
			for (const savedSearch of savedSearches)
				this.savedSearchSelections.push({ value: savedSearch.id, label: savedSearch.name });

			for (const zmType of AppConstants.zenMasterTypes)
				this.zenTypeSelections.push({ value: zmType, label: AppConstants.zenMasterTypeLabels[zmType] });

			const allOrgs = await this.organizationsService.justFetch();
			for (const org of allOrgs)
				if (!ValidationTools.hasFlag(org, 'no_keys'))
					this.orgSelections.push({
						id: org.id,
						name: org.name + ' [' + org.otype + '] - ' + org.salesforce_account_owner
					});
		} // if

		this.theForm = new UntypedFormGroup({
			deliveryMode: new UntypedFormControl(this.user.notifications.deliveryMode, [Validators.required]),
			startHour: new UntypedFormControl(this.user.notifications.startHour, [Validators.min(1), Validators.max(24)]),
			endHour: new UntypedFormControl(this.user.notifications.endHour, [Validators.min(1), Validators.max(24)]),
			timezone: new UntypedFormControl(this.user.notifications.timezone),

			autoSubscribe: new UntypedFormControl(this.user.notifications.autoSubscribe),

			receiveBuildMessages: new UntypedFormControl(this.user.notifications.receiveBuildMessages),
			includedBuildProductIDs: new UntypedFormControl(this.user.notifications.includedBuildProductIDs),
			excludedBuildProductIDs: new UntypedFormControl(this.user.notifications.excludedBuildProductIDs),
			includedBuildPlatformIDs: new UntypedFormControl(this.user.notifications.includedBuildPlatformIDs),
			excludedBuildPlatformIDs: new UntypedFormControl(this.user.notifications.excludedBuildPlatformIDs),

			receiveDocumentMessages: new UntypedFormControl(this.user.notifications.receiveDocumentMessages),
			includedDocumentProductIDs: new UntypedFormControl(this.user.notifications.includedDocumentProductIDs),
			excludedDocumentProductIDs: new UntypedFormControl(this.user.notifications.excludedDocumentProductIDs),

			receiveKeyExpirationMessages: new UntypedFormControl(this.user.notifications.receiveKeyExpirationMessages),
			expirationDays: new UntypedFormControl(this.user.notifications.expirationDays, [Validators.min(AppConstants.minLicenseWarningDays), Validators.max(AppConstants.maxLicenseWarningDays)]),

			receiveKeyUsageMessages: new UntypedFormControl(this.user.notifications.receiveKeyUsageMessages),
			usagePercentage: new UntypedFormControl(this.user.notifications.usagePercentage, [Validators.min(AppConstants.minLicenseUsagePercentage), Validators.max(AppConstants.maxLicenseUsagePercentage)]),

			receiveProjectedKeyUsageMessages: new UntypedFormControl(this.user.notifications.receiveProjectedKeyUsageMessages),
			projectedPercentage: new UntypedFormControl(this.user.notifications.projectedPercentage, [Validators.min(AppConstants.minLicenseUsagePercentage), Validators.max(AppConstants.maxLicenseUsagePercentage)]),

			receiveProtocolKeyUsageMessages: new UntypedFormControl(this.user.notifications.receiveProtocolKeyUsageMessages),
			protocolPercentage: new UntypedFormControl(this.user.notifications.protocolPercentage, [Validators.min(AppConstants.minLicenseUsagePercentage), Validators.max(AppConstants.maxLicenseUsagePercentage)]),

			receiveOfflineHostIDsMessages: new UntypedFormControl(this.user.notifications.receiveOfflineHostIDsMessages),

			receiveKeysReportMessages: new UntypedFormControl(this.user.notifications.receiveKeysReportMessages),
			keysReportFrequency: new UntypedFormControl(this.user.notifications.keysReportFrequency),

			receiveGenerallMessages: new UntypedFormControl(this.user.notifications.receiveGenerallMessages),


			skipEmptyReports: new UntypedFormControl(this.user.notifications.skipEmptyReports),

			receiveStaffKeyReportMessages: new UntypedFormControl(this.user.notifications.receiveStaffKeyReportMessages),
			keyReportSavedSearches: new UntypedFormControl(this.user.notifications.keyReportSavedSearches),

			receiveStaffZENMasterReportMessages: new UntypedFormControl(this.user.notifications.receiveStaffZENMasterReportMessages),
			includedZenMasterTypes: new UntypedFormControl(this.user.notifications.includedZenMasterTypes),

			receiveStaffOrgMeterReportMessages: new UntypedFormControl(this.user.notifications.receiveStaffOrgMeterReportMessages),
			orgMeterReportOrgIDs: new UntypedFormControl(this.user.notifications.orgMeterReportOrgIDs)
		});

		// daysOfWeek: new FormControl(this.user.notifications.daysOfWeek),
		if (!this.user.notifications.daysOfWeek) this.user.notifications.daysOfWeek = [];
		for (const dow of AppConstants.daysOfWeek)
			this.theForm.addControl('dow_' + dow, new UntypedFormControl(this.user.notifications.daysOfWeek.includes(dow))); this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const newSettings = MiscTools.deepClone(this.user.notifications);

		newSettings.deliveryMode = this.theForm.value.deliveryMode;
		newSettings.startHour = +this.theForm.value.startHour;
		newSettings.endHour = +this.theForm.value.endHour;
		newSettings.timezone = this.theForm.value.timezone;

		newSettings.daysOfWeek = [];
		for (const dow of AppConstants.daysOfWeek)
			if (this.theForm.value['dow_' + dow])
				newSettings.daysOfWeek.push(dow);

		newSettings.autoSubscribe = this.theForm.value.autoSubscribe;

		newSettings.receiveBuildMessages = this.theForm.value.receiveBuildMessages;
		newSettings.includedBuildProductIDs = this.theForm.value.includedBuildProductIDs;
		newSettings.excludedBuildProductIDs = this.theForm.value.excludedBuildProductIDs;
		newSettings.includedBuildPlatformIDs = this.theForm.value.includedBuildPlatformIDs;
		newSettings.excludedBuildPlatformIDs = this.theForm.value.excludedBuildPlatformIDs;

		newSettings.receiveDocumentMessages = this.theForm.value.receiveDocumentMessages;
		newSettings.includedDocumentProductIDs = this.theForm.value.includedDocumentProductIDs;
		newSettings.excludedDocumentProductIDs = this.theForm.value.excludedDocumentProductIDs;

		newSettings.receiveKeyExpirationMessages = this.theForm.value.receiveKeyExpirationMessages;
		newSettings.expirationDays = +this.theForm.value.expirationDays;

		newSettings.receiveKeyUsageMessages = this.theForm.value.receiveKeyUsageMessages;
		newSettings.usagePercentage = +this.theForm.value.usagePercentage;

		newSettings.receiveProjectedKeyUsageMessages = this.theForm.value.receiveProjectedKeyUsageMessages;
		newSettings.projectedPercentage = +this.theForm.value.projectedPercentage;

		newSettings.receiveProtocolKeyUsageMessages = this.theForm.value.receiveProtocolKeyUsageMessages;
		newSettings.protocolPercentage = +this.theForm.value.protocolPercentage;

		newSettings.receiveOfflineHostIDsMessages = this.theForm.value.receiveOfflineHostIDsMessages;

		newSettings.receiveKeysReportMessages = this.theForm.value.receiveKeysReportMessages;
		newSettings.keysReportFrequency = this.theForm.value.keysReportFrequency;

		newSettings.receiveGenerallMessages = this.theForm.value.receiveGenerallMessages;

		if (this.showStaffSettings) {
			newSettings.skipEmptyReports = this.theForm.value.skipEmptyReports;

			newSettings.receiveStaffKeyReportMessages = this.theForm.value.receiveStaffKeyReportMessages;
			newSettings.keyReportSavedSearches = this.theForm.value.keyReportSavedSearches;

			newSettings.receiveStaffZENMasterReportMessages = this.theForm.value.receiveStaffZENMasterReportMessages;
			newSettings.includedZenMasterTypes = this.theForm.value.includedZenMasterTypes;

			newSettings.receiveStaffOrgMeterReportMessages = this.theForm.value.receiveStaffOrgMeterReportMessages;
			newSettings.orgMeterReportOrgIDs = this.theForm.value.orgMeterReportOrgIDs;

		} else {
			newSettings.skipEmptyReports = false;

			newSettings.receiveStaffKeyReportMessages = false;
			newSettings.keyReportSavedSearches = [];

			newSettings.receiveStaffZENMasterReportMessages = false;
			newSettings.includedZenMasterTypes = [];

			newSettings.receiveStaffOrgMeterReportMessages = false;
			newSettings.orgMeterReportOrgIDs = [];
		} // if

		try {
			const retUser = await this.usersService.updateNotificationSettings(this.id, newSettings);
			if (retUser) {
				this.onCancel();
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['/' + AppConstants.urls.users, this.id]);
	}


	get deliveryMode() { return this.theForm.get('deliveryMode'); }
	get startHour() { return this.theForm.get('startHour'); }
	get endHour() { return this.theForm.get('endHour'); }
	get timezone() { return this.theForm.get('timezone'); }
	get autoSubscribe() { return this.theForm.get('autoSubscribe'); }
	get receiveBuildMessages() { return this.theForm.get('receiveBuildMessages'); }
	get includedBuildProductIDs() { return this.theForm.get('includedBuildProductIDs'); }
	get excludedBuildProductIDs() { return this.theForm.get('excludedBuildProductIDs'); }
	get includedBuildPlatformIDs() { return this.theForm.get('includedBuildPlatformIDs'); }
	get excludedBuildPlatformIDs() { return this.theForm.get('excludedBuildPlatformIDs'); }
	get receiveDocumentMessages() { return this.theForm.get('receiveDocumentMessages'); }
	get includedDocumentProductIDs() { return this.theForm.get('includedDocumentProductIDs'); }
	get receiveKeyExpirationMessages() { return this.theForm.get('receiveKeyExpirationMessages'); }
	get expirationDays() { return this.theForm.get('expirationDays'); }
	get receiveKeyUsageMessages() { return this.theForm.get('receiveKeyUsageMessages'); }
	get usagePercentage() { return this.theForm.get('usagePercentage'); }
	get receiveProjectedKeyUsageMessages() { return this.theForm.get('receiveProjectedKeyUsageMessages'); }
	get projectedPercentage() { return this.theForm.get('projectedPercentage'); }
	get receiveProtocolKeyUsageMessages() { return this.theForm.get('receiveProtocolKeyUsageMessages'); }
	get protocolPercentage() { return this.theForm.get('protocolPercentage'); }
	get receiveOfflineHostIDsMessages() { return this.theForm.get('receiveOfflineHostIDsMessages'); }
	get receiveKeysReportMessages() { return this.theForm.get('receiveKeysReportMessages'); }
	get keysReportFrequency() { return this.theForm.get('keysReportFrequency'); }
	get receiveGenerallMessages() { return this.theForm.get('receiveGenerallMessages'); }

	get skipEmptyReports() { return this.theForm.get('skipEmptyReports'); }
	get receiveStaffKeyReportMessages() { return this.theForm.get('receiveStaffKeyReportMessages'); }
	get keyReportSavedSearches() { return this.theForm.get('keyReportSavedSearches'); }
	get receiveStaffZENMasterReportMessages() { return this.theForm.get('receiveStaffZENMasterReportMessages'); }
	get includedZenMasterTypes() { return this.theForm.get('includedZenMasterTypes'); }
	get receiveStaffOrgMeterReportMessages() { return this.theForm.get('receiveStaffOrgMeterReportMessages'); }
	get orgMeterReportOrgIDs() { return this.theForm.get('orgMeterReportOrgIDs'); }

}
