// tslint:disable: no-string-literal

// various utility functions used by both front and back end...
import { UAParser } from 'ua-parser-js';
// import { formatDate, formatNumber } from '@angular/common';
import { parseFullName } from 'parse-full-name';
// import { DateTime } from "luxon";

import * as Models from './shared-models';
import AppConstants from './app-constants';

import MiscTools from './misc-tools';

class TextTools {
	// ************************************************************************************************
	// date/time related
	// ************************************************************************************************

	// ************************************************************************************************
	// static formatNumber = (num: number) => {
	// 	return formatNumber(num, 'en-US');
	// };

	// // ************************************************************************************************
	// static formatDate = (theDate: string | number | Date,
	// 	format: string = AppConstants.pageDateTimeFmt,
	// 	locale: string = 'en-US',
	// 	timezone: string = '') => {
	// 	if (timezone && timezone !== '')
	// 		return formatDate(theDate, format, locale, timezone);
	// 	else
	// 		return formatDate(theDate, format, locale);
	// };

	// ************************************************************************************************
	static formatNumber = (num: number) => {
		// if (num != null && !isNaN(num)) return num.toLocaleString();
		if (num != null && !isNaN(num))
			return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		else
			return '0';
	};

	// ************************************************************************************************
	static formatCount = (count: number) => {
		if (count > 999999)
			return Math.abs(count / 1000000).toFixed(1) + 'M';
		else if (count > 999)
			return Math.abs(count / 1000).toFixed(1) + 'K';

		return TextTools.formatNumber(count);
	};

	// ************************************************************************************************
	static niceHour = (hour: number): string => {
		if (hour === 0 || hour === 24)
			return 'Midnight (00:00)';
		else if (hour === 12)
			return 'Noon/Mid-day (12:00)';
		else if (hour < 12)
			return hour + ' am (' + hour.toString().padStart(2, '0') + ':00)';
		else
			return (hour - 12) + ' pm (' + hour.toString().padStart(2, '0') + ':00)';
	};

	// ************************************************************************************************
	static niceExpireText = (expDate: any): string => {
		const daysSince = MiscTools.daysSince(expDate, false);

		if (daysSince < 0)
			return ('Expires ' + TextTools.niceDaysText(expDate, true));
		else
			return ('Expired ' + TextTools.niceDaysText(expDate, true));
	};

	// ************************************************************************************************
	static niceDaysText = (theDate: any, withYears: boolean = false): string => {
		const daysSince = MiscTools.daysSince(theDate, false);
		// if (daysToExpire === 0) {
		// 	return ('today');
		// } else

		const numYears = Math.abs(daysSince / 365.25);
		let yearExtra = '';
		if (withYears && numYears > 1.25)
			yearExtra = ' (' + numYears.toFixed(1) + ' years)';

		const intAbsVal = Math.floor(Math.abs(daysSince));

		if (daysSince < 0) { // hasn't happened yet
			if (intAbsVal <= 1)
				return ('in the next day');
			else
				return ('in ' + TextTools.formatNumber(intAbsVal) + ' days' + yearExtra);
		} else {
			if (intAbsVal <= 1)
				return ('in the last day');
			else
				return (TextTools.formatNumber(intAbsVal) + ' days ago' + yearExtra);
		} // if
	};

	// ************************************************************************************************
	// get name of month 1 - january, 2 - february, etc
	static getMonthName = (monthNum: number, shortName = true): string => {
		if (monthNum < 1 || monthNum > 12) return '??';

		if (shortName)
			return AppConstants.months[monthNum - 1].substring(0, 3);
		else
			return AppConstants.months[monthNum - 1];
	};

	// ************************************************************************************************
	static formatDateTimeUTC = (theDate: any) => {
		const dateObj = new Date(theDate);
		if (!dateObj || isNaN(dateObj.getTime())) return '';

		return dateObj.getUTCFullYear().toString().padStart(4, '0')
			+ '-' + (dateObj.getUTCMonth() + 1).toString().padStart(2, '0')
			+ '-' + dateObj.getUTCDate().toString().padStart(2, '0')
			+ ' ' + dateObj.getUTCHours().toString().padStart(2, '0')
			+ ':' + dateObj.getUTCMinutes().toString().padStart(2, '0')
			+ ':' + dateObj.getUTCSeconds().toString().padStart(2, '0')
			;
	};

	// ************************************************************************************************
	static formatDateUTC = (theDate: any) => {
		const dateObj = new Date(theDate);
		if (!dateObj || isNaN(dateObj.getTime())) return '';

		return dateObj.getUTCFullYear().toString().padStart(4, '0')
			+ '-' + (dateObj.getUTCMonth() + 1).toString().padStart(2, '0')
			+ '-' + dateObj.getUTCDate().toString().padStart(2, '0')
			;
	};

	// ************************************************************************************************
	static formatDateNiceUTC = (theDate: any, shortName = true) => {
		// console.log(theDate);
		const dateObj = new Date(theDate);
		if (!dateObj || isNaN(dateObj.getTime())) return '';

		return TextTools.getMonthName(dateObj.getUTCMonth() + 1, shortName)
			+ ' ' + dateObj.getUTCDate() + ' ' + dateObj.getUTCFullYear();
	};

	// ************************************************************************************************
	static formatDateTimeNiceUTC = (theDate: any, shortName = true) => {
		// console.log(theDate);
		const dateObj = new Date(theDate);
		if (!dateObj || isNaN(dateObj.getTime())) return '';

		return TextTools.getMonthName(dateObj.getUTCMonth() + 1, shortName)
			+ ' ' + dateObj.getUTCDate() + ' ' + dateObj.getUTCFullYear()
			+ ' ' + dateObj.getUTCHours().toString().padStart(2, '0')
			+ ':' + dateObj.getUTCMinutes().toString().padStart(2, '0')
			+ ':' + dateObj.getUTCSeconds().toString().padStart(2, '0')
			;
	};

	// ************************************************************************************************
	// other stuff
	// ************************************************************************************************
	static capitalizeFirstLetter = (theText: string): string => {
		return theText.charAt(0).toUpperCase() + theText.slice(1);
	}

	// ************************************************************************************************
	static parseUserAgent = (userAgent: string, mode = ''): string => {
		if (!userAgent || userAgent === '') return '';
		const uaParser = new UAParser(userAgent);

		if (uaParser.getBrowser().name)
			if (mode === 'short')
				return uaParser.getBrowser().name + ' - ' + uaParser.getOS().name;
			else
				return uaParser.getBrowser().name + ' ' + uaParser.getBrowser().version
					+ ' - ' + uaParser.getOS().name + ' ' + uaParser.getOS().version;

		return '';
	}

	// ************************************************************************************************
	static parseUserInfo = (info: string, mode = 'name'): string => {
		const name = TextTools.getLabel(info);
		const email = TextTools.getValue(info);

		if (mode === 'name')
			return name;
		else if (mode === 'email')
			return email;
		else if (mode === 'emaildomain')
			return TextTools.getEmailDomain(email);
		else if (mode === 'both')
			if (name !== email)
				return name + ' (' + email + ')';
			else
				return email;

		return info;
	}

	// ************************************************************************************************
	// get file name (remove path)
	static getJustFile = (filepath: string): string => {
		const idx1 = filepath.lastIndexOf('/');
		const idx2 = filepath.lastIndexOf('\\');
		if (idx1 !== -1)
			return filepath.substring(idx1 + 1);
		else if (idx2 !== -1)
			return filepath.substring(idx2 + 1);
		else
			return filepath;
	};

	// ************************************************************************************************
	// get folder/directory path (remove file)
	static getJustFolder = (filepath: string): string => {
		const idx1 = filepath.lastIndexOf('/');
		const idx2 = filepath.lastIndexOf('\\');
		if (idx1 !== -1)
			return filepath.substring(0, idx1);
		else if (idx2 !== -1)
			return filepath.substring(0, idx2);
		else
			return filepath;
	};

	// ************************************************************************************************
	// get file extension
	static getExtension = (filename: string, forceLower = false): string => {
		const idx = filename.lastIndexOf('.');
		if (idx !== -1) {
			if (forceLower) {
				return filename.substring(idx + 1).toLowerCase();
			} else {
				return filename.substring(idx + 1);
			}
		}
		return '';
	};

	// ************************************************************************************************
	// get email domain
	static getEmailDomain = (email: string): string => {
		const idx = email.indexOf('@');
		if (idx !== -1)
			return email.substring(idx + 1).trim().toLowerCase();
		return '';
	};

	// ************************************************************************************************
	// check email address format
	static isEmailValid = (email: string): boolean => {
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	};

	// ************************************************************************************************
	// startsWithLetter
	static startsWithLetter = (theString: string): boolean => {
		const re = /^[a-zA-Z]/;
		return re.test(theString);
	};

	// ************************************************************************************************
	static fixAmp = (theText: any): string => {
		if (!theText) return '';
		let returnText = theText + '';
		returnText = returnText.replace(/\&amp\;/g, '&');
		return returnText;
	};

	// ************************************************************************************************
	// get everything before the splitAt (:)
	static getLabel = (theText: string, splitAt: string = ':'): string => {
		if (!theText) return '';
		const idx = theText.indexOf(splitAt);
		if (idx !== -1)
			return theText.substring(0, idx).trim();
		else
			return theText.trim();
	};

	// ************************************************************************************************
	// get everything after the splitAt (:)
	static getValue = (theText: string, splitAt: string = ':'): string => {
		if (!theText) return '';
		const idx = theText.indexOf(splitAt);
		if (idx !== -1)
			return theText.substring(idx + 1).trim();
		else
			return theText.trim();
	};

	// ************************************************************************************************
	static chopString = (text: string, chopLength: number, afterChop: string = '...'): string => {
		if (!text) return '';
		let returnText = text;
		if (text.length > chopLength)
			returnText = text.substring(0, chopLength) + afterChop;
		return (returnText);
	};

	// ************************************************************************************************
	static escapeForTitle = (text: string): string => {
		if (!text) return '';
		const returnText = text.replace('\'', '\\\'').replace('\"', '\\\"');
		return (returnText);
	};

	// ************************************************************************************************
	static formattedMB = (sizeInMegaBytes: number, forceUnit = false): string => {
		if (sizeInMegaBytes == null || sizeInMegaBytes <= 0)
			if (forceUnit)
				return '0 MB';
			else
				return '0';

		let workingSize = sizeInMegaBytes;
		let unit = 0;
		const byteUnits = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		while (workingSize >= 1000 && unit < byteUnits.length - 1) {
			workingSize = workingSize / 1000;
			unit++;
		}
		if (unit === 0)
			return Math.floor(workingSize) + ' ' + byteUnits[unit];
		else
			return Math.max(workingSize, 0.1).toFixed(1) + ' ' + byteUnits[unit];
	};

	// ************************************************************************************************
	static formattedHours = (seconds: number): string => {
		let hours: number = 0;

		const secsInMinute = 60;
		const secsInHour = 60 * secsInMinute;

		let remainder: number = seconds;
		if (remainder > secsInHour)
			hours = remainder / secsInHour;

		return Math.max(hours, 0.1).toFixed(1);
	};

	// ************************************************************************************************
	static formattedDuration = (seconds: number, short = true): string => {
		let days: number = 0;
		let hours: number = 0;
		let minutes: number = 0;

		const secsInMinute = 60;
		const secsInHour = 60 * secsInMinute;
		const secsInDay = 24 * secsInHour;

		let remainder: number = seconds;
		if (remainder > secsInDay) {
			days = Math.floor(remainder / secsInDay);
			remainder = remainder % secsInDay;
		} // if

		if (remainder > secsInHour) {
			hours = Math.floor(remainder / secsInHour);
			remainder = remainder % secsInHour;
		} // if

		if (remainder > secsInMinute) {
			minutes = Math.floor(remainder / secsInMinute);
			remainder = remainder % secsInMinute;
		} // if

		let retStr = '';
		if (short) {
			if (days > 0) retStr += TextTools.formatNumber(days) + ':';
			if (days > 0 || hours > 0) retStr += hours.toString().padStart(2, '0') + ':';
			retStr += minutes.toString().padStart(2, '0') + ':';
			retStr += remainder.toString().padStart(2, '0');
			if (retStr.startsWith('0')) retStr = retStr.substring(1);
		} else {
			if (days === 1)
				retStr += TextTools.formatNumber(days) + ' day ';
			else if (days > 1)
				retStr += TextTools.formatNumber(days) + ' days ';

			if (hours === 1)
				retStr += hours.toString() + ' hour ';
			else if (hours > 1)
				retStr += hours.toString() + ' hours ';

			if (minutes === 1)
				retStr += minutes.toString() + ' minutes ';
			else if (minutes > 1)
				retStr += minutes.toString() + ' minutes ';

			if (remainder === 1)
				retStr += remainder.toString() + ' second ';
			else if (remainder > 1)
				retStr += remainder.toString() + ' seconds ';

			retStr = retStr.trim();
		}

		return retStr;
	};

	// ************************************************************************************************
	static cleanVersion = (version: string): string => {
		if (!version) return '';

		const regex1 = /^1\.\d+\.\d+\.\d+$/g;
		const versionResults1 = version.trim().match(regex1);

		if (versionResults1 && versionResults1.length === 1)
			return version.trim().substring(2); // chop off the 1. bit

		return version;
	};

	// ************************************************************************************************
	// get acronym of words
	static acronym = (name: string, splitOnOthers = false) => {
		if (!name || name === '') return '';

		let splitName: string[] = [];
		if (splitOnOthers)
			splitName = name.replace(/-/g, ' ').replace(/_/g, ' ').split(' ');
		else
			splitName = name.split(' ');

		let accronym = '';
		for (const word of splitName)
			if (word !== '')
				accronym += word.substring(0, 1).toUpperCase();
		return accronym;
	};

	// ************************************************************************************************
	// take initials of words
	static randomString = (
		length: number,
		charSet: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'): string => {

		if (!length || length === 0 || !charSet || charSet === '') return '';
		let result = '';
		for (let i = 0; i < length; i++)
			result += charSet.charAt(Math.floor(Math.random() * charSet.length));
		return result;
	};

	// ************************************************************************************************
	static checkPasswordStrength = (password: string, combined = true) => {
		const minPasswordLength = 8;

		const combinedMsg = 'Passwords must have at least ' + minPasswordLength + ' characters,'
			+ ' contain a lower case character,'
			+ ' an upper case character,'
			+ ' a number and'
			+ ' a special character.'
			;
		// !@#$%^&*()_+-=[]{};\':"\\|,.<>?

		const upperCaseCharacters = /[A-Z]+/g;
		const lowerCaseCharacters = /[a-z]+/g;
		const numberCharacters = /[0-9]+/g;
		const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

		if (combined) {
			if (upperCaseCharacters.test(password) === false
				|| lowerCaseCharacters.test(password) === false
				|| numberCharacters.test(password) === false
				|| specialCharacters.test(password) === false
				|| password.trim().length < minPasswordLength)
				return combinedMsg;
		} else {
			if (upperCaseCharacters.test(password) === false)
				return 'Passwords must contain at least one upper case character.';

			if (lowerCaseCharacters.test(password) === false)
				return 'Passwords must contain at least one lower case character.';
			// return 'text has to contine lower case characters,current value ${value}';

			if (numberCharacters.test(password) === false)
				return 'Passwords must contain at least one number.';

			if (specialCharacters.test(password) === false)
				return 'Passwords must contain at least one special character.';

			if (password.trim().length < minPasswordLength)
				return 'Passwords must have at least ' + minPasswordLength + ' characters.';
		}


		return null;
	};

	// ************************************************************************************************
	static splitStringToNumbers = (numString: string, splitAt: string = ','): number[] => {
		const nums: number[] = [];
		if (numString && numString !== '') {
			const numsAsStrings: string[] = numString.split(splitAt);
			for (const num of numsAsStrings)
				if (num && !isNaN(+num))
					nums.push(+num);
		} // if
		return nums;
	};

	// ************************************************************************************************
	static changeNameToLastFirst = (name: string): string => {
		const parsedName = parseFullName(name);

		let first: string = '';
		let last: string = '';

		if (parsedName.last) last += ' ' + parsedName.last;
		if (parsedName.suffix) last += ' ' + parsedName.suffix;

		if (parsedName.title) first += ' ' + parsedName.title;
		if (parsedName.first) first += ' ' + parsedName.first;
		if (parsedName.middle) first += ' ' + parsedName.middle;
		if (parsedName.nick) first += ' (' + parsedName.nick + ')';

		if (last !== '' && first !== '')
			return last.trim() + ', ' + first.trim();
		else if (last !== '')
			return last.trim();
		else if (first !== '')
			return first.trim();
		else
			return name;
	};

	// ************************************************************************************************
	// Some Zixi Specific Stuff
	// ************************************************************************************************
	static niceLogAction = (action: string): string => {
		const idx = MiscTools.findIndexGeneric(AppConstants.adminLogActions, 'value', action);
		if (idx !== -1)
			return AppConstants.adminLogActions[idx].label;
		else
			return action.replace(/-/g, ' ').replace(/\_/g, ' ');
	};

	// ************************************************************************************************
	static niceLogType = (type: string): string => {
		const idx = MiscTools.findIndexGeneric(AppConstants.adminLogObjTypes, 'value', type);
		if (idx !== -1)
			return AppConstants.adminLogObjTypes[idx].label;
		else
			return type.replace(/-/g, ' ').replace(/\_/g, ' ');
	};

	// ************************************************************************************************
	static getUserPropValue = (user: Models.User | Models.AuthUser, propKey: string): string => {
		if (user && user.settings) {
			const idx = MiscTools.findIndexGeneric(user.settings, 'setting_key', propKey);
			if (idx !== -1) return user.settings[idx].setting_value;
		}
		return '';
	};

	// ************************************************************************************************
	static getZenUserType = (user: Models.ZenMasterUser): string => {
		let usertype = 'Basic';

		if (user.is_admin && user.is_admin === 1)
			usertype = 'Admin';

		if (user.is_zixi_admin && user.is_zixi_admin === 1)
			usertype += ' (Zixi Admin)';

		if (user.is_zixi_support && user.is_zixi_support === 1)
			usertype += ' (Zixi Support)';

		return usertype;
	};

	// *********************************************************************************************
	static parseZixiVersion = (version: string): Models.ZixiVersion => {
		if (!version || version.trim() === '') return null;

		const vObj: Models.ZixiVersion = new Models.ZixiVersion();

		let versionToParse = version.trim();

		// if there's a space, drop everything after the space
		const idx = versionToParse.indexOf(' ');
		if (idx !== -1) {
			vObj.suffix = versionToParse.substring(idx).trim()
			versionToParse = versionToParse.substring(0, idx).trim();
		} // if

		const splitVersion: string[] = versionToParse.trim().split('.');

		// if there are 4 parts and the first part is a 1 (one), drop it
		if (splitVersion.length === 4 && splitVersion[0] === '1') {
			splitVersion.splice(0, 1);
			vObj.startedWithOne = true;
		} // if

		if (splitVersion.length > 3) return null;

		if (splitVersion.length >= 1 && !isNaN(+splitVersion[0]))
			vObj.major = +splitVersion[0];

		if (splitVersion.length >= 2 && !isNaN(+splitVersion[1]))
			vObj.minor = +splitVersion[1];

		if (splitVersion.length >= 3 && !isNaN(+splitVersion[2]))
			vObj.build = +splitVersion[2];

		return vObj;
	} //

	// *********************************************************************************************
	// returns 1 if a > b
	// returns -1 if a < b
	// returns 0 if the same version
	static compareVersions = (versionA: string, versionB: string): number => {
		if (!versionA || versionA.trim() === '') return 0;
		if (!versionB || versionB.trim() === '') return 0;

		const vObjA = TextTools.parseZixiVersion(versionA);
		const vObjB = TextTools.parseZixiVersion(versionB);

		if (vObjA.major > vObjB.major)
			return 1;
		else if (vObjA.major < vObjB.major)
			return -1
		else
			if (vObjA.minor > vObjB.minor)
				return 1;
			else if (vObjA.minor < vObjB.minor)
				return -1
			else
				if (vObjA.build > vObjB.build)
					return 1;
				else if (vObjA.build < vObjB.build)
					return -1
				else
					return 0;
	} //

	// *********************************************************************************************
	static compareBuilds = (a: Models.Build, b: Models.Build): number => {
		if (!a || !b) return 0;
		return TextTools.compareVersions(a.version, b.version);
	} //

	// ************************************************************************************************
	static compareDownloadableBuilds = (a: Models.DownloadableBuild, b: Models.DownloadableBuild): number => {
		if (!a || !b) return 0;
		if (!a.build || !b.build) return 0;
		return TextTools.compareVersions(a.build.version, b.build.version);
	} //

	// ************************************************************************************************
	static parseFileName = (filename: string) => {
		const searchname = filename.toLowerCase();

		const extension = TextTools.getExtension(searchname);

		let info = '';

		const productPrefixes = {};
		productPrefixes['zixi_broadcaster'] = 'Zixi Broadcaster';
		productPrefixes['zixi_feeder'] = 'Zixi Feeder';
		productPrefixes['zixi_receiver'] = 'Zixi Receiver';
		productPrefixes['zixi_mediaconnect'] = 'Zixi Feeder/Receiver for MediaConnect';
		productPrefixes['zixi_video_acceleration_proxy'] = 'Zixi Video Accelerator Proxy';
		productPrefixes['zixi_vlc_plugin'] = 'Zixi VLC Plugin';
		productPrefixes['zixi_wirecast_feeder'] = 'Zixi Feeder for Wirecast';
		productPrefixes['zixi_obs_plugin_installer'] = 'Zixi Plug-In for OBS Studio';

		const platformKeywords = {};
		platformKeywords['docker'] = 'Docker';
		platformKeywords['raspberry'] = 'Raspberry PI';
		platformKeywords['win32'] = 'Windows (32-bit)';
		platformKeywords['win64'] = 'Windows (64-bit)';
		platformKeywords['win'] = 'Windows (64-bit)';
		platformKeywords['mac'] = 'Mac (64-bit)';
		platformKeywords['osx'] = 'Mac (64-bit)';
		platformKeywords['linux32'] = 'Linux (32-bit)';
		platformKeywords['linux64'] = 'Linux (64-bit)';
		platformKeywords['graviton2'] = 'ARM64 Graviton2';

		const regex1 = /1\.\d+\.\d+\.\d+/g;
		const regex2 = /\d+\.\d+\.\d+/g;
		const versionResults1 = searchname.match(regex1);
		const versionResults2 = searchname.match(regex2);

		let version = '';
		if (versionResults1 && versionResults1.length === 1) {
			version = versionResults1[0].substring(2); // chop off the 1. bit
		} else if (versionResults2 && versionResults2.length === 1) {
			version = versionResults2[0];
		} // if

		// .for_
		// ignore_

		let product = '';
		let platform = '';
		let label = '';

		if (!searchname.includes('sdk')) {
			if (searchname.startsWith('zixi_broadcaster')
				&& (searchname.includes('elemental') || searchname.includes('netinsight')
					|| searchname.includes('texel') || searchname.includes('.for_'))) {
				product = 'Special Broadcaster';
				label = TextTools.randomString(8, '0123456789abcdef') + ' - CHANGE ME';
				platform = 'Linux (64-bit)';
			} else {
				for (const prefix in productPrefixes) {
					if (searchname.startsWith(prefix)) {
						product = productPrefixes[prefix];
						break;
					} // if
				} // for
			} // if
		} // if

		if (!searchname.includes('sdk') && platform === '') {
			for (const keyword in platformKeywords) {
				if (searchname.indexOf(keyword) !== -1) {
					platform = platformKeywords[keyword];
					break;
				} // if
			} // for
		} // if

		// if no platform at this point, but there's a version and product,
		// use extension to set platform
		if (!searchname.includes('sdk') && product !== '' && version !== '' && platform === '') {
			if (extension === 'gz') {
				platform = 'Linux (64-bit)';
				info = 'No platform - using default';
			} else if (extension === 'exe') {
				platform = 'Windows (64-bit)';
				info = 'No platform - using default';
			} // if
		} // if

		return { product, platform, version, info, label };
	};

	// ************************************************************************************************
	static parseFileName2 = (filename: string, products: Models.Product[], platforms: Models.Platform[]) => {
		const searchname = filename.toLowerCase();

		const extension = TextTools.getExtension(searchname);

		let info = '';

		const regex1 = /1\.\d+\.\d+\.\d+/g;
		const regex2 = /\d+\.\d+\.\d+/g;
		const versionResults1 = searchname.match(regex1);
		const versionResults2 = searchname.match(regex2);

		let version = '';
		if (versionResults1 && versionResults1.length === 1) {
			version = versionResults1[0].substring(2); // chop off the 1. bit
		} else if (versionResults2 && versionResults2.length === 1) {
			version = versionResults2[0];
		} // if

		// .for_
		// ignore_

		let linux64Name = '';
		let windows64Name = '';

		for (const aPlatform of platforms) {
			if (aPlatform.filename_keyword === 'linux64') linux64Name = aPlatform.name;
			if (aPlatform.filename_keyword === 'win') windows64Name = aPlatform.name;
		} // for


		let product = '';
		let platform = '';
		let label = '';

		if (!searchname.includes('sdk')) { // hack special Bx
			if (searchname.startsWith('zixi_broadcaster')
				&& (searchname.includes('elemental') || searchname.includes('netinsight')
					|| searchname.includes('texel') || searchname.includes('.for_'))) {
				product = 'Special Broadcaster';
				label = TextTools.randomString(8, '0123456789abcdef') + ' - CHANGE ME';
				platform = linux64Name;
			} else {
				for (const aProduct of products) {
					if (aProduct.filename_prefix && aProduct.filename_prefix !== ''
						&& searchname.startsWith(aProduct.filename_prefix.toLowerCase())) {
						product = aProduct.name;
						break;
					} // if
				} // for
			} // if
		} // if

		if (!searchname.includes('sdk') && platform === '') {
			for (const aPlatform of platforms) {
				if (aPlatform.filename_keyword && aPlatform.filename_keyword !== ''
					&& searchname.includes(aPlatform.filename_keyword.toLowerCase())) {
					platform = aPlatform.name;
					break;
				} // if
			} // for
		} // if

		// if no platform at this point, but there's a version and product,
		// use extension to set platform
		if (!searchname.includes('sdk') && product !== '' && version !== '' && platform === '') {
			if (extension === 'gz') {
				platform = linux64Name;
				info = 'No platform - using default';
			} else if (extension === 'exe') {
				platform = windows64Name;
				info = 'No platform - using default';
			} // if
		} // if

		return { product, platform, version, info, label };
	};

	// ************************************************************************************************
	static isValidIP = (ip: string): boolean => {
		if (!ip || ip === '') return false;
		const parts: string[] = ip.trim().split('.');
		if (parts.length !== 4) return false;

		let numValidParts: number = 0;
		for (let i = 0; i < parts.length; i++)
			if (!isNaN(+parts[i]) && +parts[i] < 256 && ((i === 0 && +parts[i] >= 1) || (i > 0 && +parts[i] >= 0)))
				numValidParts++;

		if (numValidParts !== 4) return false;
		return true;
	} //


}

export default TextTools;
