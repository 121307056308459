import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';

import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { DashboardService } from '../dashboard/dashboard.service';

@Injectable({
	providedIn: 'root'
})
export class UsersService {
	users: Observable<Models.User[]>;
	loaded = false;
	private debug = false;
	private _users: ReplaySubject<Models.User[]>;
	private dataStore: {
		users: Models.User[];
	};

	private impersonateEnabled = false;

	constructor(private http: HttpClient,
		private dashboardService: DashboardService,
		private uiAlertsService: UiAlertsService) {
		this.dataStore = {
			users: []
		};
		this._users = new ReplaySubject(1) as ReplaySubject<Models.User[]>;
		this.users = this._users.asObservable();
	}

	// add/update an item in the datastore
	private updateStore(newUser: Models.User): void {
		const idx = this.dataStore.users.findIndex(user => user.id === newUser.id);
		if (idx === -1) {
			this.dataStore.users.push(newUser);
			return;
		} else {
			this.dataStore.users[idx] = newUser;
		}
	}

	enableImpersonate() {
		this.impersonateEnabled = true;
	}

	isImpersonateEnabled() {
		return this.impersonateEnabled;
	}

	// get a fresh copy of the entire set
	refreshAll(): Observable<Models.User[]> {
		this.dashboardService.checkVersion();

		const users$ = this.http.get<Models.User[]>(AppConstants.apiUrl + AppConstants.apiUrls.users).pipe(share());
		users$.subscribe(
			data => {
				const users: Models.User[] = data;
				// remove ones from the store that aren't in the response (they've been deleted)
				this.dataStore.users.forEach((existingUser, existingIndex) => {
					const newIndex = users.findIndex(user => user.id === existingUser.id);
					if (newIndex === -1) this.dataStore.users.splice(existingIndex, 1);
				});
				// add/update all the ones that came back
				users.forEach(user => this.updateStore(user));
				this._users.next(Object.assign({}, this.dataStore).users);
				this.loaded = true;
			},
			error => { /* console.log(error) */ }
		);
		return users$;
	}

	// get a fresh copy of a single item
	refreshOne(id: number): Observable<Models.User> {
		this.dashboardService.checkVersion();

		const user$ = this.http.get<Models.User>(AppConstants.apiUrl + AppConstants.apiUrls.users + '/' + id).pipe(share());
		user$.subscribe(
			data => {
				const user: Models.User = data;
				// add/update the one that came back
				this.updateStore(user);
				this._users.next(Object.assign({}, this.dataStore).users);
			},
			error => { /* console.log(error) */ }
		);
		return user$;
	}

	// return the whole list
	getAll(): Models.User[] {
		return this.dataStore.users.slice();
	}

	// return the whole list
	getForRole(role: string): Models.User[] {
		const sublist: Models.User[] = [];
		for (const user of this.dataStore.users)
			if (ValidationTools.checkRole(user.role, role))
				sublist.push(user);

		return sublist;
	}

	// grab a single item from the datastore
	getOne(id: number): Models.User {
		return this.dataStore.users.find(user => user.id === id);
	}

	// call back-end to add an item
	async addOne(user: Models.User, addMethod: string, welcomeUser: boolean = false) {
		try {
			const userToSend = MiscTools.deepClone(user);
			userToSend['addMethod'] = addMethod;
			if (welcomeUser) userToSend['welcomeUser'] = 'yes';
			const result = await this.http.post<Models.User>(AppConstants.apiUrl + AppConstants.apiUrls.users, userToSend).toPromise();
			const returnedUser: Models.User = result;
			this.updateStore(returnedUser);
			this._users.next(Object.assign({}, this.dataStore).users);
			return returnedUser;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update an item
	async updateOne(user: Models.User) {
		try {
			const result = await this.http.put<Models.User>(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + user.id, user).toPromise();
			const returnedUser: Models.User = result;
			this.updateStore(returnedUser);
			this._users.next(Object.assign({}, this.dataStore).users);
			return returnedUser;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to delete an item
	async deleteOne(id: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + id).toPromise();
			const idx = this.dataStore.users.findIndex(user => user.id === id);
			if (idx !== -1) this.dataStore.users.splice(idx, 1);
			this._users.next(Object.assign({}, this.dataStore).users);
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// ********************************************************
	// other non-standard methods
	// ********************************************************

	// call back-end to enable/disable
	async toggleEnabled(id: number) {
		try {
			const result = await this.http.put<Models.User>(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + id + '/toggle-enabled', {}).toPromise();
			const returnedUser: Models.User = result;
			this.updateStore(returnedUser);
			this._users.next(Object.assign({}, this.dataStore).users);
			return returnedUser;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async setOrganization(id: number, orgID: number) {
		try {
			const result = await this.http.put<Models.User>(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + id + '/organization', { org_id: orgID }).toPromise();
			const returnedUser: Models.User = result;
			this.updateStore(returnedUser);
			this._users.next(Object.assign({}, this.dataStore).users);
			return returnedUser;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async updatePasswordResetToken(id: number) {
		try {
			const result = await this.http.put<Models.User>(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + id + '/password-reset', {}).toPromise();
			const returnedUser: Models.User = result;
			this.updateStore(returnedUser);
			this._users.next(Object.assign({}, this.dataStore).users);
			return returnedUser;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async sendWelcomeMessage(id: number) {
		try {
			const result = await this.http.put<Models.User>(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + id + '/welcome-message', {}).toPromise();
			const returnedUser: Models.User = result;
			this.updateStore(returnedUser);
			this._users.next(Object.assign({}, this.dataStore).users);
			return returnedUser;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async setSalesforceContactID(id: number, sfID: string) {
		try {
			const result = await this.http.put<Models.User>(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + id + '/salesforce-id', { salesforce_contact_id: sfID }).toPromise();
			const returnedUser: Models.User = result;
			this.updateStore(returnedUser);
			this._users.next(Object.assign({}, this.dataStore).users);
			return returnedUser;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async setAccessTags(id: number, tags: string[]) {
		try {
			const result = await this.http.put<Models.User>(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + id + '/access', { tags }).toPromise();
			const returnedUser: Models.User = result;
			this.updateStore(returnedUser);
			this._users.next(Object.assign({}, this.dataStore).users);
			return returnedUser;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async updateNotificationSettings(id: number, settings: Models.NotificationSettings) {
		try {
			const result = await this.http.put<Models.User>(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + id + '/notification-settings', settings).toPromise();
			const returnedUser: Models.User = result;
			this.updateStore(returnedUser);
			this._users.next(Object.assign({}, this.dataStore).users);
			return returnedUser;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to set a specfic user property
	async setUserProperty(id: number, property: string, value: string) {
		try {
			const url = AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + id + '/set-property/' + encodeURIComponent(property);

			const result = await this.http.put<Models.User>(url, { value }).toPromise();
			const returnedUser: Models.User = result;
			this.updateStore(returnedUser);
			this._users.next(Object.assign({}, this.dataStore).users);
			return returnedUser;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// async getStaff() {
	// 	try {
	// 		const result = await this.http.get<Models.User[]>(AppConstants.apiUrl
	// 			+ AppConstants.apiUrls.users + '?special=staff').toPromise();
	// 		const arr: Models.User[] = result;
	// 		return arr;
	// 	} catch (error) {
	// 		MiscTools.handleBackendError(error);
	// 	}
	// }

	async getUserKeys(id: number) {
		try {
			const result = await this.http.get<Models.UserLicenseKey[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + id + '/user-keys').toPromise();
			const arr: Models.UserLicenseKey[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getUserHostIdLabels(id: number) {
		try {
			const result = await this.http.get<Models.UserHostId[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + id + '/user-hostid-labels').toPromise();
			const arr: Models.UserHostId[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get each of the user's key's active host count
	async getActiveHostCounts(id: number) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.users + '/' + id + '/active-host-counts';
			const result = await this.http.get<any>(url).toPromise();
			const arr: any = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	/*
		expects keys to be an array of {key: 'thekey', label: 'thelabel' }
	*/
	async registerKeys(id: number, keys: any[], notify = false, addMethod: string = '') {
		try {
			// console.log('addMethod=' + addMethod);
			const result = await this.http.post<Models.UserLicenseKey>(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + id + '/user-keys',
				{ keys, notify, add_method: addMethod }).toPromise();
			// const obj: Models.UserLicenseKey = result;
			// return obj;
			return;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async deregisterKey(userId: number, userKeyId: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + userId + '/user-keys/' + userKeyId).toPromise();
			// const obj: Models.UserLicenseKey = result;
			// return obj;
			return;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// pull a filtered list of users for a single org
	getUsersForOrganization(orgID: number): Models.User[] {
		const orgUsers: Models.User[] = [];
		for (const user of this.dataStore.users) {
			if (user.org_id && user.org_id === orgID) {
				orgUsers.push(user);
			}
		}
		return orgUsers;
	}

	// pull a filtered list of users for a single pkg
	getUsersForPackage(packageID: number): Models.User[] {
		const pkgUsers: Models.User[] = [];
		for (const user of this.dataStore.users) {
			if (user.package_ids.indexOf(packageID) !== -1) {
				pkgUsers.push(user);
			}
		}
		return pkgUsers;
	}

	async getUserBundles(id: number) {
		try {
			const result = await this.http.get<Models.Bundle[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + id + '/bundles').toPromise();
			const arr: Models.Bundle[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getZenStaffAccess(id: number) {
		try {
			const result = await this.http.get<any>(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + id + '/zen-staff-access').toPromise();
			const obj: any = result;
			if (obj && obj.level) return obj.level;
			return '';
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async setZenStaffAccess(id: number, level: string) {
		try {
			const result = await this.http.put<any>(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + id + '/zen-staff-access', { level }).toPromise();
			const obj: any = result;
			if (obj && obj.level) return obj.level;
			return '';
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async promoteBasicToStaff(id: number) {
		try {
			const result = await this.http.put<Models.User>(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + id + '/promote-basic-to-staff', {}).toPromise();
			const returnedUser: Models.User = result;
			this.updateStore(returnedUser);
			this._users.next(Object.assign({}, this.dataStore).users);
			return returnedUser;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getAllUserIdsForAccessTag(tag: string) {
		try {
			const result = await this.http.get<number[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/access-user-ids/' + encodeURIComponent(tag)).toPromise();
			const arr: number[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}


	// grab a single item from the datastore
	getUsersName(id: number): string {
		const theUser: Models.User = this.dataStore.users.find(user => user.id === id);
		if (theUser)
			return theUser.name;
		return 'User (id=' + id + ')';
	}

	async emailUserKeysReport(userId: number) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.users + '/' + userId + '/key-report?email=yes';
			const result = await this.http.get<any>(url).toPromise();
			this.uiAlertsService.addMsg('Your report request has been submitted. This may take several minutes.\n'
				+ 'You\'ll received an e-mail when the report is ready.\n', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async setupUserForSalesEngineerNotifications(id: number) {
		try {
			const result = await this.http.put<Models.User>(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + id + '/setup-user-with-sales-engineer-notifications', {}).toPromise();
			const returnedUser: Models.User = result;
			this.updateStore(returnedUser);
			this._users.next(Object.assign({}, this.dataStore).users);
			return returnedUser;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async setupUserForAccountOwnerNotifications(id: number) {
		try {
			const result = await this.http.put<Models.User>(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + id + '/setup-user-with-account-owner-notifications', {}).toPromise();
			const returnedUser: Models.User = result;
			this.updateStore(returnedUser);
			this._users.next(Object.assign({}, this.dataStore).users);
			return returnedUser;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getUserKeySnoozes(id: number) {
		try {
			const result = await this.http.get<Models.UserLicenseKeySnooze[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.users + '/' + id + '/user-key-snoozes').toPromise();
			const arr: Models.UserLicenseKeySnooze[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}



}
