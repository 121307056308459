<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.mybuilds" class="cp-user-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">Software</a>
			</li>
			<li *ngIf="downloadableBuild" class="breadcrumb-item active" aria-current="page">
				{{ downloadableBuild.build.prod_name }} -
				{{ downloadableBuild.build.version }}
				<span *ngIf="downloadableBuild.build.label && downloadableBuild.build.label !== ''">({{
					downloadableBuild.build.label }})</span>
			</li>
		</ol>
	</nav>

	<div *ngIf="isStaff && downloadableBuild" class="actions">
		<div class="form-inline">
			<div class="btn-group">
				<button type="button" class="btn btn-outline-secondary" [routerLink]="['/' + ac.urls.builds, downloadableBuild.build.id]">
					<fa-icon [icon]="['fas', 'glasses']" size="sm"></fa-icon>
					Staff View
				</button>
			</div>
		</div>
	</div>
</div>

<div *ngIf="!!downloadableBuild" class="page-wrapper">

	<div *ngIf="downloadableBuild.product.download_build_description && downloadableBuild.product.download_build_description !== ''"
		class="cp-light-message cp-pre-text">
		{{ downloadableBuild.product.download_build_description }}
	</div>

	<div *ngIf="!!downloadableBuild.build.release_notes && downloadableBuild.build.release_notes !== ''" class="mb-2">
		Release Notes:<br />
		<div class="cp-light-message cp-inline-release-notes-long">
			{{ downloadableBuild.build.release_notes }}
		</div>
	</div>

	<div *ngIf="downloadableBuild.build.release_notes_file_id !== 0" class="mb-2">
		<button type="button" class="cp-download-document-btn mb-1"
			(click)="openDownload(downloadableBuild.build.release_notes_file_id, 'build',downloadableBuild.build.id)">
			<fa-icon [icon]="['fas', 'file-download']"></fa-icon> Download Release Notes File
		</button>
		{{ getFileName(downloadableBuild.build.release_notes_file_id) }}
		({{ getFileSize(downloadableBuild.build.release_notes_file_id) | bytes: "1.0-0" }})
	</div>

	<div *ngIf="retiredMessage !== ''" class="cp-warning-message cp-pre-text mb-2">
		{{retiredMessage }}
	</div>

	<div *ngIf="allowDownloads" class="mb-2">
		<div
			*ngFor="let pl of miscTools.sortPlatforms(downloadableBuild.platforms, getPlatformIDsFromPlatformFiles(downloadableBuild.build.platform_files))">
			<button type="button" class="cp-download-btn mb-1 me-1"
				(click)="openDownload(getFileIDForPlatform(pl.id), 'build', downloadableBuild.build.id)">
				<fa-icon [icon]="miscTools.getPlatformIcon(pl.name)" [fixedWidth]="true"></fa-icon>
				Download
			</button>
			<button *ngIf="!downloadLink" type="button" class="btn btn-outline-warning btn-sm mb-1"
				(click)="grabDownloadInfo(getFileIDForPlatform(pl.id), 'build', downloadableBuild.build.id)"
				ngbTooltip="Command Line Interface options for downloading this file.  These options are available if you need to pull down this file to a system that doesn't have a web browswer UI."
				placement="bottom">
				<fa-icon [icon]="['fas', 'terminal']"></fa-icon>
				CLI
			</button>
			<span class="fw-bold ms-1">{{ pl.name }}</span>: {{ getFileName(getFileIDForPlatform(pl.id)) }}
			({{ getFileSize(getFileIDForPlatform(pl.id)) | bytes: "1.0-0" }})
		</div>
	</div>

	<div *ngIf="!allowDownloads" class="mb-2">
		Platforms
		<div
			*ngFor="let pl of miscTools.sortPlatforms(downloadableBuild.platforms, getPlatformIDsFromPlatformFiles(downloadableBuild.build.platform_files))">
			<fa-icon [icon]="miscTools.getPlatformIcon(pl.name)"></fa-icon>
			<!-- ({{ getFileSize(getFileIDForPlatform(pl.id)) | bytes: "1.0-0" }}) -->
			{{ pl.name }}
			<!--: {{ getFileName(getFileIDForPlatform(pl.id)) }}-->
		</div>
	</div>

	<div *ngIf="!!downloadLink" class="alert alert-secondary">
		<div>
			<span class="cp-property-label">Expires</span>
			<span class="cp-property-value">in {{ downloadLink.expiresMinutes }} minutes
				({{ downloadLink.expires| date: "medium" }})</span>
		</div>

		<div *ngFor="let sampleCommand of downloadLink.sampleCommands">
			<hr />
			<span class="cp-property-value">
				<fa-icon class="text-info" [icon]="['fas', 'copy']" ngxClipboard (click)="copyToClipboardAlert('')"
					[cbContent]="sampleCommand" ngbTooltip="Copy to Clipboard" placement="end"></fa-icon>
				{{ sampleCommand }}
			</span>
		</div>
		<hr />
		<button type="button" (click)="downloadLink = null" class="btn btn-outline-warning btn-sm mb-1">
			<fa-icon [icon]="['fas', 'times-circle']"></fa-icon> Close
		</button>
	</div>

	<div *ngIf="this.documents && this.documents.length !== 0" class="mb-2">
		Related Documents:
		<div *ngFor="let item of this.documents">
			<button *ngIf="item.document.link && item.document.link !== ''" type="button" class="cp-link-btn mb-1" (click)="openLink(item)">
				<fa-icon [icon]="['fas', 'external-link-square-alt']"></fa-icon>
				{{ item.document.label }}
			</button>
		</div>
		<div *ngFor="let item of this.documents">
			<button *ngIf="item.document.file_id && item.document.file_id !== 0" type="button" class="cp-download-document-btn mb-1"
				(click)="openDownload(item.document.file_id, 'document', item.document.id)">
				<fa-icon [icon]="['fas', 'file-download']"></fa-icon> {{ item.document.label }}
			</button>
		</div>
	</div>
</div>