import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { MyPartnersRoutingModule } from './my-partners-routing.module';
import { MyPartnersComponent } from './my-partners/my-partners.component';


@NgModule({
	declarations: [
		MyPartnersComponent
	],
	imports: [
		CommonModule,
		MyPartnersRoutingModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		ClipboardModule,
		SharedModule,
		FontAwesome
	]
})
export class MyPartnersModule { }
