import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { CalculatorsRoutingModule } from './calculators-routing.module';

import { TrafficCalcComponent } from './traffic-calc/traffic-calc.component';


@NgModule({
	declarations: [TrafficCalcComponent],
	imports: [
		CommonModule,
		CalculatorsRoutingModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		SharedModule,
		FontAwesome,
		ClipboardModule
	]
})
export class CalculatorsModule { }
