import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class GCPMarketplaceRegistrationService {

	constructor(private http: HttpClient) { }

	// call back-end to add an item
	async submitRegistration(props: any) {
		try {
			const result = await this.http.post<Models.User>(AppConstants.apiUrl
				+ AppConstants.apiUrls.auth + AppConstants.apiUrls.gcp_marketplaceregistration, props).toPromise();

			return result;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to add an item
	async getSubcriptionFromToken(token: string) {
		try {
			const url = AppConstants.apiUrl
				+ AppConstants.apiUrls.auth + AppConstants.apiUrls.gcp_marketplacetoken
				+ '?token=' + encodeURIComponent(token)
				+ '&action=getsub';

			const result = await this.http.get<any>(url).toPromise();

			return result;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

}
