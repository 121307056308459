<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.zencustomers" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">ZEN Master Sites</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				Reports
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
		</div>
	</div>
</div>

<div class="list-wrapper">

	<div class="mb-2">
		<button type="button" class="btn btn-outline-secondary me-2" routerLink="staff">
			<fa-icon [icon]="['fas', 'user']" size="sm"></fa-icon>
			<span class="ms-1">Staff Report</span>
		</button>
		View ZEN Master Sites broken down by account owner or assigned tech rep.
	</div>

	<div class="mb-2">
		<button type="button" class="btn btn-outline-secondary me-2" routerLink="users">
			<fa-icon [icon]="['fas', 'user']" size="sm"></fa-icon>
			<span class="ms-1">ZEN Master Users</span>
		</button>
		View/search Users from all ZEN Master Sites.
	</div>

	<div class="mb-2">
		<button type="button" class="btn btn-outline-secondary me-2" routerLink="clusters">
			<fa-icon [icon]="['fas', 'cloud']" size="sm"></fa-icon>
			<span class="ms-1">Broadcaster Clusters</span>
		</button>
		View/search Cloud Based Broadcaster Clusters from all ZEN Master Sites.
	</div>

	<div class="mb-2">
		<button type="button" class="btn btn-outline-secondary me-2" routerLink="objects">
			<fa-icon [icon]="['fas', 'search']" size="sm"></fa-icon>
			<span class="ms-1">Object Search</span>
		</button>
		Search for Objects (Sources, Channels, Targets) across all ZEN Master Sites by name.
	</div>

	<div class="mb-2">
		<button type="button" class="btn btn-outline-secondary me-2" routerLink="sources">
			<fa-icon [icon]="['fas', 'search']" size="sm"></fa-icon>
			<span class="ms-1">Sources Search</span>
		</button>
		Search for Sources across all ZEN Master Sites by name.
	</div>

	<div class="mb-2">
		<button type="button" class="btn btn-outline-secondary me-2" routerLink="channels">
			<fa-icon [icon]="['fas', 'search']" size="sm"></fa-icon>
			<span class="ms-1">Channels Search</span>
		</button>
		Search for Channels across all ZEN Master Sites by name.
	</div>

	<div class="mb-2">
		<button type="button" class="btn btn-outline-secondary me-2" routerLink="targets">
			<fa-icon [icon]="['fas', 'search']" size="sm"></fa-icon>
			<span class="ms-1">Targets Search</span>
		</button>
		Search for Targets across all ZEN Master Sites by name.
	</div>

	<div class="mb-2">
		<button type="button" class="btn btn-outline-secondary me-2" (click)="openReport(false)">
			<fa-icon [icon]="['fas', 'file-excel']" size="sm"></fa-icon>
			<span class="ms-1">Site Report</span>
		</button>
		Creates an Excel spreadsheet.
	</div>

	<div class="mb-2">
		<button type="button" class="btn btn-outline-secondary me-2" (click)="openReport(true)">
			<fa-icon [icon]="['fas', 'file-excel']" size="sm"></fa-icon>
			<span class="ms-1">Site Report+</span>
		</button>
		Creates an Excel spreadsheet with object counts.
	</div>

</div>