<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.licensingadmin" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Licensing
			</li>
			<li class="breadcrumb-item">
				<a href="javascript:void(0)" (click)="onCancel()">Templates</a>
			</li>
			<li *ngIf="pageMode === 'edit' || pageMode === 'clone'" class="breadcrumb-item active" aria-current="page">
				Edit Template
			</li>
			<li *ngIf="pageMode === 'new'" class="breadcrumb-item active" aria-current="page">
				New {{ productLabelForHeading }} Template
			</li>
			<li *ngIf="pageMode === 'clone'" class="breadcrumb-item active" aria-current="page">
				Clone
			</li>
		</ol>
	</nav>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-options-tab"
				aria-controls="nav-options" data-bs-target="#nav-options">
				<fa-icon [icon]="['fas', 'check-square']" size="sm"></fa-icon>
				Options
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-expiration-tab"
				aria-controls="nav-expiration" data-bs-target="#nav-expiration">
				<fa-icon [icon]="['fas', 'calendar-alt']" size="sm"></fa-icon>
				Expiry/Meters
			</button>
		</li>

		<li *ngIf="booleanPropsCount > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-features-tab"
				aria-controls="nav-features" data-bs-target="#nav-features">
				<fa-icon [icon]="['fas', 'clipboard-list-check']" size="sm"></fa-icon>
				Features
			</button>
		</li>

		<li *ngIf="numberPropsCount > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-limits-tab"
				aria-controls="nav-limits" data-bs-target="#nav-limits">
				<fa-icon [icon]="['fas', 'abacus']" size="sm"></fa-icon>
				Limits
			</button>
		</li>

		<li *ngIf="otherPropsCount > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-other-tab"
				aria-controls="nav-other" data-bs-target="#nav-other">
				<fa-icon [icon]="['fas', 'cog']" size="sm"></fa-icon>
				Other
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<form *ngIf="theForm && !loading" [formGroup]="theForm" class="tab-form">
		<div class="tab-content" id="nav-tabContent">
			<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">
				<div class="cp-general-width-limit">

					<div class="cp-compact-form-group">
						<label>Product :</label> {{ product.label }} ({{ product.name }})
					</div>

					<div class="cp-compact-form-group">
						<label for="name">
							Name*
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="This is the name staff will see when selecting a template.">
							</fa-icon>
						</label>
						<input type="text" id="name" formControlName="name" class="form-control" />
					</div>

					<div class="cp-compact-form-group">
						<label for="description">
							Description
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="This description is available to staff.  It should describe the rules laid out in the template.">
							</fa-icon>
						</label>
						<textarea id="description" formControlName="description" class="form-control" rows="4"></textarea>
					</div>

					<div class="cp-compact-form-group">
						<label for="keyTypes">
							Key Types*
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="The key types that template supports.">
							</fa-icon>
						</label>
						<ng-select [items]="keyTypeSelections" bindValue="value" bindLabel="label" id="keyTypes" formControlName="keyTypes"
							[multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

					<div class="cp-compact-form-group">
						<label for="defaultKeyType">
							Default Key Type
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Pre-selected key type for new keys">
							</fa-icon>
						</label>
						<ng-select [items]="keyTypeSelections" bindValue="value" bindLabel="label" id="defaultKeyType"
							formControlName="defaultKeyType" [multiple]="false">
						</ng-select>
					</div>

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="requiresOrganization" formControlName="requiresOrganization" />
						<label class="form-check-label" for="requiresOrganization">
							Keys must be linked to an Organization
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Keys using this template must be linked to an Organization.">
							</fa-icon>
						</label>
					</div>

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="editOnly" formControlName="editOnly" />
						<label class="form-check-label" for="editOnly">
							"Edit-Only" - new keys cannot be created with this template
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="When selected, this template can only be used with existing keys.">
							</fa-icon>
						</label>
					</div>

					<!-- <div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="quickKey" formControlName="quickKey" />
						<label class="form-check-label" for="quickKey">
							Can be used via Quick-Key
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Users can use this template to use rapidly created a key based on defaults in the template.">
							</fa-icon>
						</label>
					</div> -->

					<div class="cp-compact-form-group">
						<label for="user_ids">
							Users With Access to this Template
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Individual users that can use this template.">
							</fa-icon>
						</label>
						<ng-select [items]="staffUserSelections" bindValue="id" bindLabel="name" id="user_ids" formControlName="user_ids"
							[multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

					<div class="cp-compact-form-group">
						<label for="user_group_ids">
							User Groups With Access to this Template
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Groups of users that can use this template.">
							</fa-icon>
						</label>
						<ng-select [items]="allUserGroups" bindValue="id" bindLabel="name" id="user_group_ids"
							formControlName="user_group_ids" [multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

					<div class="cp-compact-form-group">
						<label for="limitOrgIDs">
							Limited Organizations
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Keys using this template must be linked to one of these Organizations.">
							</fa-icon>
						</label>
						<ng-select [items]="orgSelections" bindValue="id" bindLabel="name" id="limitOrgIDs" formControlName="limitOrgIDs"
							[multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

					<div class="cp-compact-form-group">
						<label for="limitOrgGroupIDs">
							Limited Organization Groups
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Keys using this template must be linked to an Organization in one of these groups.">
							</fa-icon>
						</label>
						<ng-select [items]="allOrgGroups" bindValue="id" bindLabel="name" id="limitOrgGroupIDs"
							formControlName="limitOrgGroupIDs" [multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

					<div class="cp-compact-form-group">
						<label for="excludeOrgIDs">
							Excluded Organizations
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Keys using this template cannot be linked to these Organizations.">
							</fa-icon>
						</label>
						<ng-select [items]="orgSelections" bindValue="id" bindLabel="name" id="excludeOrgIDs"
							formControlName="excludeOrgIDs" [multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

					<div class="cp-compact-form-group">
						<label for="excludeOrgGroupIDs">
							Excluded Organization Groups
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Keys using this template cannot be linked to an Organization in any of these groups.">
							</fa-icon>
						</label>
						<ng-select [items]="allOrgGroups" bindValue="id" bindLabel="name" id="excludeOrgGroupIDs"
							formControlName="excludeOrgGroupIDs" [multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

					<div class="cp-compact-form-group">
						<label for="orgTypes">
							Limited Organization Types
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Keys using this template can only be linked to Organizations with these types.">
							</fa-icon>
						</label>
						<ng-select [items]="orgTypeSelections" bindValue="value" bindLabel="label" id="orgTypes" formControlName="orgTypes"
							[multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

				</div>
			</div>

			<div class="tab-pane" role="tabpanel" id="nav-options" aria-labelledby="nav-options-tab">
				<div class="cp-general-width-limit">

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="requiresSalesforceOpportunity"
							formControlName="requiresSalesforceOpportunity" />
						<label class="form-check-label" for="requiresSalesforceOpportunity">
							Requires a Salesforce Opportunity
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Keys using this template must be linked to a Salesforce Opportunity.">
							</fa-icon>
						</label>
					</div>

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="salesforceOpportunityMustMatch"
							formControlName="salesforceOpportunityMustMatch" />
						<label class="form-check-label" for="salesforceOpportunityMustMatch">
							Salesforce Opportunity must be tied to the Organization's Salesforce Account
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="The Salesforce Opportunity must be connected to the Account that the Organization is linked to.">
							</fa-icon>
						</label>
					</div>

					<div class="cp-compact-form-group">
						<label for="defaultActivations">
							Default # of Activations
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="The default maximum number of activations pre-filled when creating a new key.">
							</fa-icon>
						</label>
						<input type="text" id="defaultActivations" formControlName="defaultActivations"
							class="form-control cp-auto-width text-end" size='8' />
					</div>

					<div class="cp-compact-form-group">
						<label for="minActivations">
							Minimum # of Activations
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Keys using this template must have a maximum number of activations at or above this amount.">
							</fa-icon>
						</label>
						<input type="text" id="minActivations" formControlName="minActivations" class="form-control cp-auto-width text-end"
							size='8' />
					</div>

					<div class="cp-compact-form-group">
						<label for="maxActivations">
							Maximum # of Activations
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Keys using this template must have a maximum number of activations at or below this amount.">
							</fa-icon>
						</label>
						<input type="text" id="maxActivations" formControlName="maxActivations" class="form-control cp-auto-width text-end"
							size='8' />
					</div>

					<div class="cp-compact-form-group">
						<label for="maxKeys">
							Maximum # of Keys to Generate at a Time
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="When using this template to generate new keys, a user can generate multiple keys below or up to this amout.">
							</fa-icon>
						</label>
						<input type="text" id="maxKeys" formControlName="maxKeys" class="form-control cp-auto-width text-end" size='8' />
					</div>

					<div class="cp-compact-form-group">
						<label for="defaultCommercialType">
							Default Commercial Type
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="The default commercial type selected for new keys using this template.">
							</fa-icon>
						</label>
						<ng-select [items]="commercialTypeSelections" bindValue="value" bindLabel="label" id="defaultCommercialType"
							formControlName="defaultCommercialType" [multiple]="false">
						</ng-select>
					</div>

					<div class="cp-compact-form-group">
						<label for="name">
							Default Commercial Info
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="The default commercial info for new keys using this template.">
							</fa-icon>
						</label>
						<input type="text" id="defaultCommercialInfo" formControlName="defaultCommercialInfo" class="form-control" />
					</div>

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="requiresCommercialType"
							formControlName="requiresCommercialType" />
						<label class="form-check-label" for="requiresCommercialType">
							Keys must have a commercial type set
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Keys using this template must have a commercial type set.">
							</fa-icon>
						</label>
					</div>

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="requiresLinkedUsers" formControlName="requiresLinkedUsers" />
						<label class="form-check-label" for="requiresLinkedUsers">
							Enabled Keys must be shared with at least one user
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Enabled keys using this template must be shared with at least one user.">
							</fa-icon>
						</label>
					</div>

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="defaultShareWithSelf" formControlName="defaultShareWithSelf" />
						<label class="form-check-label" for="defaultShareWithSelf">
							Default Value for "Add To Your Own Keys"
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Should the 'Add To Your Own Keys' box be checked by default.">
							</fa-icon>
						</label>
					</div>
				</div>
			</div>

			<div class="tab-pane" role="tabpanel" id="nav-expiration" aria-labelledby="nav-expiration-tab">
				<div class="cp-general-width-limit">
					<div class="cp-compact-form-group">
						<label for="expiryModes">
							Expiry Modes*
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Keys using this template may use one of these expiry modes.">
							</fa-icon>
						</label>
						<ng-select [items]="expiryModeSelections" bindValue="value" bindLabel="label" id="expiryModes"
							formControlName="expiryModes" [multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

					<div class="cp-compact-form-group">
						<label for="defaultExpiryMode">
							Default Expiry Mode
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="The default expiry mode selected for new keys using this template.">
							</fa-icon>
						</label>
						<ng-select [items]="expiryModeSelections" bindValue="value" bindLabel="label" id="defaultExpiryMode"
							formControlName="defaultExpiryMode" [multiple]="false">
						</ng-select>
					</div>

					<div class="cp-compact-form-group">
						<label for="defaultExpirationCount">
							Default Expiry
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="The default expiry (from today) for new keys using this template.">
							</fa-icon>
						</label>

						<div class="input-group">
							<input type="text" id="defaultExpirationCount" formControlName="defaultExpirationCount"
								class="form-control cp-auto-width text-end" style="max-width:8ch" />

							<div class="input-group-text align-middle">
								<div class="form-check ms-2" *ngFor="let timeUnit of timeUnits; let i = index">
									<input class="form-check-input" type="radio" formControlName="defaultExpirationUnit"
										name="defaultExpirationUnit" [id]="'deu_' + i" [value]="timeUnit.value" />
									<label class="form-check-label" [for]="'deu_' + i">{{ timeUnit.label }}</label>
								</div>

								<!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
									<label *ngFor="let timeUnit of timeUnits; let i = index"
										class="btn btn-secondary-outline ms-1" [for]="'deu_' + i">
										<input class="form-check-input" type="radio"
											formControlName="defaultExpirationUnit" name="defaultExpirationUnit"
											[id]="'deu_' + i" [value]="timeUnit.value" autocomplete="off" />
										{{ timeUnit.label }}
									</label>
								</div> -->
							</div>
						</div>
					</div>

					<div class="cp-compact-form-group">
						<label for="maxExpirationCount">
							Maximum Expiry
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="The maximum expiry (from the date the key was created) for keys using this template.">
							</fa-icon>
						</label>

						<div class="input-group">
							<input type="text" id="maxExpirationCount" formControlName="maxExpirationCount"
								class="form-control cp-auto-width text-end" style="max-width:8ch" />

							<div class="input-group-text align-middle">
								<div class="form-check ms-2" *ngFor="let timeUnit of timeUnits; let i = index">
									<input class="form-check-input" type="radio" formControlName="maxExpirationUnit"
										name="maxExpirationUnit" [id]="'meu_' + i" [value]="timeUnit.value" />
									<label class="form-check-label" [for]="'meu_' + i">{{ timeUnit.label }}</label>
								</div>
							</div>
						</div>
					</div>

					<div class="cp-compact-form-group">
						<label for="warningDaysOfWeek">
							Days of Week For Expiration Warning
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="If a key's expiry falls on one of these days, alert the user.">
							</fa-icon>
						</label>
						<ng-select [items]="daysOfWeekSelections" bindValue="value" bindLabel="label" id="warningDaysOfWeek"
							formControlName="warningDaysOfWeek" [multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

					<div *ngIf="meterProductSelections.length > 0" class="cp-compact-form-group">
						<label for="limitMeterProducts">
							Available Meter Type/Products
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Types of meters that can be used with keys using this template.">
							</fa-icon>
						</label>
						<ng-select [items]="meterProductSelections" bindValue="value" bindLabel="label" id="limitMeterProducts"
							formControlName="limitMeterProducts" [multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

					<div *ngIf="meterProductSelections.length > 0" class="cp-compact-form-group">
						<label for="requiredMeterProducts">
							Required Meter Type/Products
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Keys using this template must have at least one meter of this/these type(s).">
							</fa-icon>
						</label>
						<ng-select [items]="meterProductSelections" bindValue="value" bindLabel="label" id="requiredMeterProducts"
							formControlName="requiredMeterProducts" [multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

					<div *ngIf="meterProductSelections.length > 0" class="cp-compact-form-group">
						<label for="limitMeterReset">
							Meter Reset Options
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Meters with keys using this template may have these reset options.">
							</fa-icon>
						</label>
						<ng-select [items]="ac.meterResetOptions" bindValue="value" bindLabel="label" id="limitMeterReset"
							formControlName="limitMeterReset" [multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

					<div *ngIf="meterProductSelections.length > 0" class="cp-compact-form-group">
						<label for="defaultMeterLimit">
							Default Meter Limit (MB) {{ niceMeterLimit }}
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="A meter's limit will be preset to this amount">
							</fa-icon>
						</label>

						<div class="input-group">
							<input type="text" id="defaultMeterLimit" formControlName="defaultMeterLimit"
								class="form-control cp-auto-width text-end" style="max-width:24ch" (keyup)="onMeterLimitChange()" />
							<button class="btn btm-sm btn-outline-secondary" (click)="setMeterToMax()" type="button">Max</button>
						</div>
					</div>

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="requiresMeterLabels" formControlName="requiresMeterLabels" />
						<label class="form-check-label" for="requiresMeterLabels">
							Meters must have labels
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="Keys using this template that have meters must have labels set for those meters.">
							</fa-icon>
						</label>
					</div>

					<datalist id="suggestedMeterLabels">
						<option *ngFor="let suggestedMeterLabel of ac.suggestedMeterLabels" [value]="suggestedMeterLabel">
					</datalist>

					<div class="cp-compact-form-group">
						<label for="name">
							Default Meter Label
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="New keys with meters and newly added meters will have this label set.">
							</fa-icon>
						</label>
						<input type="text" id="defaultMeterLabel" formControlName="defaultMeterLabel" class="form-control"
							[attr.list]="'suggestedMeterLabels'" />
					</div>
				</div>
			</div>

			<div class="tab-pane" role="tabpanel" id="nav-features" aria-labelledby="nav-features-tab">

				<div class="cp-general-width-limit">
					<div class="cp-compact-form-group">
						<label for="nonCheckAllFeatures">
							Features Excluded from Check All
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="When editing the key, these features will not be checked if the user clicks the 'check all' button.">
							</fa-icon>
						</label>
						<ng-select [items]="nonCheckAllFeatureSelections" bindValue="id" bindLabel="label" id="nonCheckAllFeatures"
							formControlName="nonCheckAllFeatures" [multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>
				</div>

				<table *ngIf="!loading" class="cp-std-table cp-auto-width">
					<thead>
						<tr>
							<!-- <th>#</th> -->
							<th>Label (Name)</th>
							<th class="text-center">Can<br />Change</th>
							<th class="text-center">Def</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let pp of getProductPropertiesByType('boolean')">
							<td class="border-end" [title]="pp.property.label">
								{{ chopString(pp.property.label) }} ({{ pp.property.name }})
							</td>
							<td class="border-end text-center">
								<input type="checkbox" [id]="'pp_' + pp.property_id + '_can_be_changed'"
									[formControlName]="'pp_' + pp.property_id + '_can_be_changed'" />
							</td>
							<td class="border-end text-center">
								<input type="checkbox" [id]="'pp_' + pp.property_id + '_default_value_num'"
									[formControlName]="'pp_' + pp.property_id + '_default_value_num'" />
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="tab-pane" role="tabpanel" id="nav-limits" aria-labelledby="nav-limits-tab">

				<div class="cp-general-width-limit">
					<div class="cp-compact-form-group">
						<label for="nonAllUnlimitedLimits">
							Limits Excluded from Check All Unlimited
							<fa-icon [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
								ngbTooltip="When editing the key, these limits will not be set to unlimited if the user clicks the 'check all' button.">
							</fa-icon>
						</label>
						<ng-select [items]="nonAllUnlimitedLimitSelections" bindValue="id" bindLabel="label" id="nonAllUnlimitedLimits"
							formControlName="nonAllUnlimitedLimits" [multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>
				</div>

				<table *ngIf="!loading" class="cp-std-table cp-auto-width">
					<thead>
						<tr>
							<th>Label (Name)</th>
							<th>Units</th>
							<th class='text-center'>Can<br />Change</th>
							<th class='text-center'>Def.</th>
							<th class='text-center'>Def.<br />Unlim.</th>
							<th class='text-center'>Min.</th>
							<th class='text-center'>Max.</th>
							<th class='text-center'>Allow<br />Unlim.</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let pp of getProductPropertiesByType('number')">
							<td class="border-end" [title]="pp.property.label">
								{{ chopString(pp.property.label) }} ({{ pp.property.name }})
							</td>
							<td class="border-end">{{ pp.property.units }}</td>
							<td class="border-end text-center">
								<input type="checkbox" [id]="'pp_' + pp.property_id + '_can_be_changed'"
									[formControlName]="'pp_' + pp.property_id + '_can_be_changed'" />
							</td>
							<td class="border-end">
								<input type="text" class="text-end input-sm border-0" size="8"
									[id]="'pp_' + pp.property_id + '_default_value_num'"
									[formControlName]="'pp_' + pp.property_id + '_default_value_num'" />
							</td>
							<td class="border-end text-center">
								<input *ngIf="pp.property.allow_unlimited === 1" type="checkbox"
									[id]="'pp_' + pp.property_id + '_def_unlimited'"
									[formControlName]="'pp_' + pp.property_id + '_def_unlimited'" />
							</td>
							<td class="border-end text-center">
								<input type="text" class="text-end input-sm border-0" size="8" [id]="'pp_' + pp.property_id + '_min_value'"
									[formControlName]="'pp_' + pp.property_id + '_min_value'" />
							</td>
							<td class="border-end text-center">
								<input type="text" class="text-end input-sm border-0" size="8" [id]="'pp_' + pp.property_id + '_max_value'"
									[formControlName]="'pp_' + pp.property_id + '_max_value'" />
							</td>
							<td class="border-end text-center">
								<input *ngIf="pp.property.allow_unlimited === 1" type="checkbox"
									[id]="'pp_' + pp.property_id + '_allow_unlimited'"
									[formControlName]="'pp_' + pp.property_id + '_allow_unlimited'" />
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="tab-pane" role="tabpanel" id="nav-other" aria-labelledby="nav-other-tab">
				<table *ngIf="!loading" class="cp-std-table cp-auto-width">
					<thead>
						<tr>
							<th>Label (Name)</th>
							<th class='text-center'>Can<br />Change</th>
							<th class='text-center'>Def</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let pp of getProductPropertiesByType('other')">
							<td class="border-end" [title]="pp.property.label">
								{{ chopString(pp.property.label) }} ({{ pp.property.name }})
							</td>
							<td class="border-end text-center">
								<input type="checkbox" [id]="'pp_' + pp.property_id + '_can_be_changed'"
									[formControlName]="'pp_' + pp.property_id + '_can_be_changed'" />
							</td>
							<td class="border-end">
								<div *ngFor="let otherPropSelection of otherPropSelections[pp.property.name]; let i = index">
									<input type="checkbox" [formControlName]="pp.property.name + i" [id]="pp.property.name + i"
										class="me-1" />
									<label class="form-check-label" [for]="pp.property.name + i">
										{{ otherPropSelection }}
									</label>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div class="cp-form-padding"></div>

		<div *ngIf="!loading && theForm" class="cp-form-buttons">
			<div class="cp-form-buttons-inner">
				<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
					<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
				</button>
				<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
					<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
				</button>

				<span class="gj-75 ps-2">* Required</span>
			</div>
		</div>


		<!-- <div class="bottom-bar">
			<div class="form-group mb-2 small">* Required</div>
			<div class="form-group mb-0" *ngIf="!saving">
				<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
					<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
				</button>
				<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
					<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
				</button>
			</div>
		</div> -->
	</form>
</div>