import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';

import { ActivationSearchComponent } from './activation-search/activation-search.component';
import { ActivationFormComponent } from './activation-form/activation-form.component';
import { SetTemplateFormComponent } from './set-template-form/set-template-form.component';
import { OfflineSearchComponent } from './offline-search/offline-search.component';
import { OfflineAddFormComponent } from './offline-add-form/offline-add-form.component';
import { ActivationMultiComponent } from './activation-multi/activation-multi.component';
import { SearchFilterInfoComponent } from './search-filter-info/search-filter-info.component';
import { WriteAccessFormComponent } from './write-access-form/write-access-form.component';
import { CommercialFormComponent } from './commercial-form/commercial-form.component';
import { ActivationMarketplaceFormComponent } from './activation-marketplace-form/activation-marketplace-form.component';

import { OrganizationsResolverService } from '../organizations/organizations-resolver.service';
import { UsersResolverService } from '../users/users-resolver.service';

const routes: Routes = [
	{
		path: '',
		component: ActivationSearchComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService]
	}, {
		path: 'saved/:savedSearchId',
		component: ActivationSearchComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService]
	}, {
		path: 'offline',
		component: OfflineSearchComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: []
	}, {
		path: 'search-filter-info',
		component: SearchFilterInfoComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: []
	}, {
		path: 'new',
		component: ActivationFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService]
	}, {
		path: 'new/:orgID',
		component: ActivationFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService]
	}, {
		path: ':id/link-template',
		component: SetTemplateFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: []
	}, {
		path: ':id/edit',
		component: ActivationFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService]
	}, {
		path: ':id/write-access',
		component: WriteAccessFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService]
	}, {
		path: ':id/commercial',
		component: CommercialFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService]
	}, {
		path: ':id/commercial/:guess',
		component: CommercialFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [UsersResolverService]
	}, {
		path: ':id/marketplace',
		component: ActivationMarketplaceFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: []
	}, {
		path: ':id/clone',
		component: ActivationFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService]
	}, {
		path: ':id/offline',
		component: OfflineAddFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: []
	}, {
		path: ':pagemode/:objid/:filterID',
		component: ActivationMultiComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService]
	}, {
		path: ':pagemode/:objid',
		component: ActivationMultiComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService]
	}, {
		path: ':pagemode',
		component: ActivationMultiComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class LicensingRoutingModule { }
