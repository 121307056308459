import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { MyNotificationsRoutingModule } from './my-notifications-routing.module';
import { MyNotificationsComponent } from './my-notifications/my-notifications.component';


@NgModule({
	declarations: [
		MyNotificationsComponent
	],
	imports: [
		CommonModule,
		MyNotificationsRoutingModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		ClipboardModule,
		SharedModule,
		FontAwesome
	]
})
export class MyNotificationsModule { }
