
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { MyQuizzesRoutingModule } from './my-quizzes-routing.module';
import { MyQuizListComponent } from './my-quiz-list/my-quiz-list.component';
import { MyQuizViewComponent } from './my-quiz-view/my-quiz-view.component';
import { TakeQuizComponent } from './take-quiz/take-quiz.component';

@NgModule({
	declarations: [
		MyQuizListComponent,
		MyQuizViewComponent,
		TakeQuizComponent
	],
	imports: [
		MyQuizzesRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		SharedModule,
		FontAwesome,
		DragDropModule
	]
})
export class MyQuizzesModule { }
