import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';
import { FileComponent } from './file/file.component';
import { FileListComponent } from './file-list/file-list.component';
import { FileUploadFormComponent } from './file-upload-form/file-upload-form.component';
import { FileFormComponent } from './file-form/file-form.component';

import { FilesResolverService } from './files-resolver.service';
import { UsersResolverService } from '../users/users-resolver.service';

const routes: Routes = [
	{
		path: '',
		component: FileListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [FilesResolverService]
	}, {
		path: 'new',
		component: FileUploadFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole }
	}, {
		path: ':id',
		component: FileComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [FilesResolverService, UsersResolverService]
	}, {
		path: ':id/edit',
		component: FileFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [FilesResolverService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class FilesRoutingModule { }
