import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';

import { OrganizationsResolverService } from '../organizations/organizations-resolver.service';
import { UsersResolverService } from '../users/users-resolver.service';
import { UserGroupsResolverService } from '../users/user-groups-resolver.service';

import { BundleListComponent } from './bundle-list/bundle-list.component';
import { BundleComponent } from './bundle/bundle.component';
import { BundleFormComponent } from './bundle-form/bundle-form.component';

const routes: Routes = [
	{
		path: '',
		component: BundleListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService, UserGroupsResolverService]
	}, {
		path: 'new',
		component: BundleFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService, UserGroupsResolverService]
	}, {
		path: ':id',
		component: BundleComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService, UserGroupsResolverService]
	}, {
		path: ':id/edit',
		component: BundleFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService, UserGroupsResolverService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class BundlesRoutingModule { }
