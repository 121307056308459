import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share, map, tap } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class MarketplaceService {

	constructor(private http: HttpClient) {
	}


	// call back-end to get last X logs
	async getMarketplaceLogs(objectType: string, objectID: number, startDate: Date = null, endDate: Date = null) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.marketplace + '/marketplace-logs?a=b';
			if (objectType && objectType !== null) url += '&objectType=' + encodeURIComponent(objectType + '');
			if (objectID && objectID !== null) url += '&objectID=' + encodeURIComponent(objectID + '');
			if (startDate && startDate !== null) url += '&startDate=' + encodeURIComponent(startDate + '');
			if (endDate && endDate !== null) url += '&endDate=' + encodeURIComponent(endDate + '');

			const result = await this.http.get<Models.MarketplaceUsageReport[]>(url).toPromise();
			const arr: Models.MarketplaceUsageReport[] = result;

			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async manualKeyReport(keyId: number, reportStart: Date, reportEnd: Date, amountGB: number) {
		try {

			let url = AppConstants.apiUrl + AppConstants.apiUrls.marketplace + '/manual-key-usage';
			const result = await this.http.post(url, {
				id: keyId,
				amount_gb: amountGB,
				report_start: reportStart,
				report_end: reportEnd,
			}).toPromise();
			const returnedValue: any = result;
			return returnedValue;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async manualZenReport(zenId: number, reportStart: Date, reportEnd: Date, amountGB: number) {
		try {

			let url = AppConstants.apiUrl + AppConstants.apiUrls.marketplace + '/manual-zen-usage';
			const result = await this.http.post(url, {
				id: zenId,
				amount_gb: amountGB,
				report_start: reportStart,
				report_end: reportEnd,
			}).toPromise();
			const returnedValue: any = result;
			return returnedValue;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getGCPAccounts() {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.marketplace + '/gcp-accounts';
			const result = await this.http.get<any[]>(url).toPromise();
			const arr: any[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getGCPEntitlements() {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.marketplace + '/gcp-entitlements';
			const result = await this.http.get<any[]>(url).toPromise();
			const arr: any[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getAzureSubscriptions() {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.marketplace + '/azure-subscriptions';
			const result = await this.http.get<any[]>(url).toPromise();
			const arr: any[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}


}
