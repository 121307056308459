import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class BuildsService {
	builds: Observable<Models.Build[]>;
	loaded = false;
	private debug = false;
	private _builds: ReplaySubject<Models.Build[]>;
	private dataStore: {
		builds: Models.Build[];
	};

	constructor(private http: HttpClient) {
		this.dataStore = {
			builds: []
		};
		this._builds = new ReplaySubject(1) as ReplaySubject<Models.Build[]>;
		this.builds = this._builds.asObservable();
	}

	// add/update an item in the datastore
	private updateStore(newUser: Models.Build): void {
		const idx = this.dataStore.builds.findIndex(build => build.id === newUser.id);
		if (idx === -1) {
			this.dataStore.builds.push(newUser);
			return;
		} else {
			this.dataStore.builds[idx] = newUser;
		}
	}

	// get a fresh copy of the entire set
	refreshAll(): Observable<Models.Build[]> {
		const builds$ = this.http.get<Models.Build[]>(AppConstants.apiUrl + AppConstants.apiUrls.builds).pipe(share());
		builds$.subscribe(
			data => {
				const builds: Models.Build[] = data;
				// remove ones from the store that aren't in the response (they've been deleted)
				this.dataStore.builds.forEach((existingUser, existingIndex) => {
					const newIndex = builds.findIndex(build => build.id === existingUser.id);
					if (newIndex === -1) this.dataStore.builds.splice(existingIndex, 1);
				});
				// add/update all the ones that came back
				builds.forEach(build => this.updateStore(build));
				this._builds.next(Object.assign({}, this.dataStore).builds);
				this.loaded = true;
			},
			error => { /* console.log(error) */ }
		);
		return builds$;
	}

	// get a fresh copy of a single item
	refreshOne(id: number): Observable<Models.Build> {
		const build$ = this.http.get<Models.Build>(AppConstants.apiUrl + AppConstants.apiUrls.builds + '/' + id).pipe(share());
		build$.subscribe(
			data => {
				const build: Models.Build = data;
				// add/update the one that came back
				this.updateStore(build);
				this._builds.next(Object.assign({}, this.dataStore).builds);
			},
			error => { /* console.log(error) */ }
		);
		return build$;
	}

	// return the whole list
	getAll(): Models.Build[] {
		return this.dataStore.builds.slice();
	}

	// grab a single item from the datastore
	getOne(id: number): Models.Build {
		return this.dataStore.builds.find(build => build.id === id);
	}

	// call back-end to add an item
	async autoBuild(isDisabled = false, isPrivate = true, testMode = false) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.builds + '/auto-build';
			if (isDisabled)
				url += '?disabled=yes';
			else
				url += '?disabled=no';

			if (isPrivate)
				url += '&private=yes';
			else
				url += '&private=no';

			if (testMode)
				url += '&test=yes';
			else
				url += '&test=no';

			const results = await this.http.put<Models.AutoBuildResponse[]>(url, {}).toPromise();

			for (const item of results)
				if (item.buildID !== 0)
					this.refreshOne(item.buildID).subscribe();

			return results;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}


	// call back-end to add an item
	async addOne(build: Models.Build) {
		try {
			const result = await this.http.post<Models.Build>(AppConstants.apiUrl + AppConstants.apiUrls.builds, build).toPromise();
			const returnedBuild: Models.Build = result;
			this.updateStore(returnedBuild);
			this._builds.next(Object.assign({}, this.dataStore).builds);
			return returnedBuild;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update an item
	async updateOne(build: Models.Build) {
		try {
			const result = await this.http.put<Models.Build>(AppConstants.apiUrl + AppConstants.apiUrls.builds + '/' + build.id, build).toPromise();
			const returnedBuild: Models.Build = result;
			this.updateStore(returnedBuild);
			this._builds.next(Object.assign({}, this.dataStore).builds);
			return returnedBuild;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to delete an item
	async deleteOne(id: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl + AppConstants.apiUrls.builds + '/' + id).toPromise();
			const idx = this.dataStore.builds.findIndex(build => build.id === id);
			if (idx !== -1) this.dataStore.builds.splice(idx, 1);
			this._builds.next(Object.assign({}, this.dataStore).builds);
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// ********************************************************
	// other non-standard methods
	// ********************************************************

	// call back-end to enable/disable
	async toggleEnabled(id: number) {
		try {
			const result = await this.http.put<Models.Build>(AppConstants.apiUrl + AppConstants.apiUrls.builds + '/' + id + '/toggle-enabled', {}).toPromise();
			const returnedBuild: Models.Build = result;
			this.updateStore(returnedBuild);
			this._builds.next(Object.assign({}, this.dataStore).builds);
			return returnedBuild;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// get just builds for Bx with Linux 64 Bit installer file
	async fetchZenBuilds() {
		try {
			const result = await this.http.get<Models.Build[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.builds + '/zen-builds').toPromise();
			const arr: Models.Build[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchZenSitesUsingBuild(id: number) {
		try {
			const result = await this.http.get<Models.ZenMasterCustomer[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.builds + '/' + id + '/zen-customers').toPromise();
			const arr: Models.ZenMasterCustomer[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// get IDs of users that can access this build
	async fetchUserIDs(id: number) {
		try {
			const result = await this.http.get<number[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.builds + '/' + id + '/users').toPromise();
			const arr: number[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update an item
	async sendNotification(id: number, message: string) {
		try {
			const result = await this.http.put(AppConstants.apiUrl + AppConstants.apiUrls.builds + '/' + id + '/notify', { message, async: 'yes' }).toPromise();
			return;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

}
