import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { MySettingsRoutingModule } from './my-settings-routing.module';
import { MySettingsComponent } from './my-settings.component';
import { MySettingsFormComponent } from './my-settings-form/my-settings-form.component';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';
import { ChangeEmailFormComponent } from './change-email-form/change-email-form.component';

@NgModule({
	declarations: [MySettingsComponent, MySettingsFormComponent,
		ChangePasswordFormComponent, ChangeEmailFormComponent],
	imports: [
		MySettingsRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		SharedModule,
		FontAwesome
	]
})
export class MySettingsModule { }
