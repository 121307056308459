<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb bg-white h4 p-1 m-1">
			<li class="breadcrumb-item active" aria-current="page">System Settings</li>
		</ol>
	</nav>

	<div class="btn-toolbar mb-2 mb-md-0">
		<!-- 
		<div class="btn-group me-2">
			<button type="button" class="btn btn-sm btn-outline-secondary">Share</button>
			<button type="button" class="btn btn-sm btn-outline-secondary">Export</button>
		</div>
		<button type="button" class="btn btn-sm btn-outline-secondary dropdown-toggle">
			<span data-feather="calendar"></span>
			This week
		</button>
-->
	</div>
</div>

<p>app-settings works!</p>

<ul>
	<li>Place for app-wide settings that might need to be changed by an administrator</li>
	<li>Will probably have SSO info (URLs + keys) for staff logins</li>
	<li>Likely have things like clean up thresholds for log tables</li>

</ul>