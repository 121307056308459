<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.organizations" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="/organizations">Organizations</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">Groups</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button *ngIf="canAdd" type="button" class="btn btn-outline-primary me-2" routerLink="new">
				<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
				<span class="cp-action-button-text">New Group</span>
			</button>

			<button type="button" class="btn btn-outline-secondary" (click)="loadData(true)">
				<fa-icon icon="sync-alt" size="sm" [spin]="refreshing" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<div class="list-wrapper">
	<div *ngIf="loading || refreshing" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

	<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
		<thead>
			<tr>
				<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-center cp-pre-text cp-pointer"
					[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
					[hidden]="!fieldsToShow.includes(cd.field)">
					<span *ngIf="cd.label" [innerHTML]="cd.label"
						[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
						[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
					<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
				</th>
			</tr>
		</thead>

		<tbody *ngIf="itemsToShow && !loading">
			<tr *ngFor="let item of itemsToShow" [ngClass]="{'text-danger': item.is_enabled === 0}">
				<td class="cp-icon-cell">
					<fa-icon *ngIf="item.is_enabled === 1" [icon]="['fas', 'power-off']" size="sm" class="text-success"
						ngbTooltip="Enabled - Downloads available" placement="end"></fa-icon>
					<fa-icon *ngIf="item.is_enabled === 0" [icon]="['fas', 'ban']" size="sm" class="text-danger"
						ngbTooltip="Disabled - Downloads not available" placement="end"></fa-icon>
				</td>
				<td class="cp-chopped-column-long"><a [routerLink]="[item.id]">{{ item.name }}</a></td>
				<td class="text-center">{{ item.org_ids.length | number }}</td>
				<td class="text-center">{{ item.product_platforms.length | number }}</td>
				<td class="text-center">{{ item.build_ids.length | number }}</td>
				<td class="text-center">{{ item.document_ids.length | number }}</td>
				<td class="text-center" [title]="textTools.formatDateNiceUTC(item.added_on)">
					{{ item['__days_since_add'] | number }}
				</td>

			</tr>
		</tbody>
	</table>
</div>