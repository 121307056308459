<div class="mt-2">
	<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

	<div *ngIf="items.length > 0">
		<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
			<thead>
				<tr>
					<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-start cp-pre-text cp-pointer"
						[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
						[hidden]="!fieldsToShow.includes(cd.field)">
						<span *ngIf="cd.label" [innerHTML]="cd.label"
							[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
							[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
						<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
					</th>
				</tr>
			</thead>
			<tbody *ngIf="!loading && itemsToShow?.length > 0">

				<tr *ngFor="let item of itemsToShow; let i = index">
					<td class="text-nowrap">
						{{ item.added_on | date: ac.tableDateTimeFmt }}
					</td>
					<td *ngIf="showUserInfo" class="text-nowrap" [title]="textTools.escapeForTitle(item['__username'])">
						<a *ngIf="linkUser && item.added_by !== 0" [routerLink]="['/' + ac.urls.users, item.added_by]">
							{{ textTools.chopString(item['__username'], 15) }}
						</a>
						<span *ngIf="!linkUser || item.added_by === 0">
							{{ textTools.chopString(item['__username'], 15) }}
						</span>
					</td>
					<td *ngIf="showObjectInfo" class="text-nowrap">
						{{ textTools.niceLogType(item.obj_type) | titlecase }}
					</td>
					<td *ngIf="showObjectInfo" [title]="textTools.escapeForTitle(item.obj_name)" style="min-width:20ex">
						<a *ngIf="linkObject && item.obj_id !== 0 && item.action !== 'delete' && item.action !== 'purge'"
							[hidden]="!expandNames" [routerLink]="miscTools.getRoute(item.obj_type, item.action, item.obj_id)">
							{{ item.obj_name }}
						</a>
						<span *ngIf="!linkObject || item.obj_id === 0 || item.action === 'delete' || item.action === 'purge'"
							[hidden]="!expandNames">
							{{ item.obj_name }}
						</span>

						<a *ngIf="linkObject && item.obj_id !== 0 && item.action !== 'delete' && item.action !== 'purge'"
							[hidden]="expandNames" class="text-nowrap"
							[routerLink]="miscTools.getRoute(item.obj_type, item.action, item.obj_id)">
							{{ textTools.chopString(item.obj_name, 20) }}
						</a>
						<span *ngIf="!linkObject || item.obj_id === 0 || item.action === 'delete' || item.action === 'purge'"
							[hidden]="expandNames" class="text-nowrap">
							{{ textTools.chopString(item.obj_name, 20) }}
						</span>
					</td>
					<td class="text-nowrap">
						{{ textTools.niceLogAction(item.action) | titlecase }}
					</td>
					<td (click)="copyToClipboardAlert(item.information)">
						<fa-icon *ngIf="item.information && item.information !== ''" [icon]="['fas', 'copy']" ngxClipboard
							[cbContent]="item.information" ngbTooltip="Copy to Clipboard"></fa-icon>
						{{ item.information }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>

</div>