import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import { RoleGuard } from 'client/app/guards/role.guard';

import { FileSharingListComponent } from './file-sharing-list/file-sharing-list.component';
import { FileSharingBundleComponent } from './file-sharing-bundle/file-sharing-bundle.component';
import { AddFilesFormComponent } from './add-files-form/add-files-form.component';
import { BasicSendFormComponent } from './basic-send-form/basic-send-form.component';

const routes: Routes = [
	{
		path: '',
		component: FileSharingListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole },
		resolve: []
	}, {
		path: 'basic-send',
		component: BasicSendFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole },
		resolve: []
	}, {
		path: ':id',
		component: FileSharingBundleComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole },
		resolve: []
	}, {
		path: ':id/files',
		component: AddFilesFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole },
		resolve: []
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class MyFileSharingRoutingModule { }
