import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';

import { OrganizationsService } from '../organizations.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { LicensingService } from '../../licensing/licensing.service';

@Component({
	selector: 'app-link-keys-form',
	templateUrl: './link-keys-form.component.html',
	styleUrls: ['./link-keys-form.component.scss']
})
export class LinkKeysFormComponent implements OnInit {
	ac = AppConstants;

	// 'standard' edit stuff
	errors: string[] = [];
	theForm: UntypedFormGroup;

	id: number;
	organization: Models.Organization;

	userKeys: Models.UserLicenseKey[] = [];

	loading = true;
	saving = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private organizationsService: OrganizationsService,
		private licensingService: LicensingService,
		private uiAlertsService: UiAlertsService
	) {
		this.route.paramMap.subscribe((params) => {
			this.id = +params.get('id');
			this.organization = this.organizationsService.getOne(this.id);
			if (!this.organization || this.organization == null || this.organization.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
			}
		});
	}

	ngOnInit(): void {
		this.loadData();
		this.initForm();
	}

	async loadData() {
		// this.userKeys = await this.myKeysService.getUserKeys();
	}

	async initForm() {
		this.loading = false;

		this.theForm = new UntypedFormGroup({
			keyList: new UntypedFormControl(null, [Validators.required]),
		});
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const keys = this.theForm.value.keyList;

		const registerErrors = [];

		let numProcessed = 0;
		let numAdded = 0;
		let numSkipped = 0;
		// let numUpdated = 0;
		const array = keys.match(/[^\r\n]+/g);
		for (const key of array) {
			if (key.trim() !== '') {
				if (numProcessed >= AppConstants.maxKeysToRegisterPerSubmit) {
					numSkipped++;
				} else {
					numProcessed++;

					try {
						const idx = MiscTools.findIndexGeneric(this.userKeys, 'activation_key', key.trim());
						if (idx !== -1) {
							const theKey = this.userKeys[idx];

							this.uiAlertsService.addMsg(key.trim() + ' has already been linked to this Organization.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
						} else {

							// console.log('try to link ' + key.trim());
							await this.licensingService.linkKeyToOrganization(key.trim(), this.id, 'manually-linked', false);
							numAdded++;
						}

					} catch (e) {
						registerErrors.push(e.message);
					}
				}
			}
		}

		if (registerErrors.length > 0) {
			if (numAdded > 0) {
				for (const err of registerErrors)
					this.uiAlertsService.addMsg(err, 'error');
			} else {
				for (const err of registerErrors)
					this.errors.push(err);
			}
		}

		if (numAdded === 1)
			this.uiAlertsService.addMsg('Linked one key.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		else if (numAdded > 1)
			this.uiAlertsService.addMsg('Linked ' + numAdded + ' keys.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

		if (numSkipped === 1)
			this.uiAlertsService.addMsg('Ignoring one key. Maximum # of keys reached.', 'warning');
		else if (numSkipped > 1)
			this.uiAlertsService.addMsg('Ignoring ' + numSkipped + ' keys. Maximum # of keys reached.', 'warning');

		this.saving = false;
		if (this.errors.length === 0 && numAdded > 0) this.onCancel();
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	get keyList() { return this.theForm.get('keyList'); }
}
