// tslint:disable: no-string-literal

import { DateTime } from "luxon";

import AppConstants from './app-constants';


class DatetimeTools {
	// ************************************************************************************************
	static formatDate = (theDate: any,
		format: string = AppConstants.pageDateTimeFmt,
		timezone: string = '') => {

		const theDateAsDate = new Date(theDate);
		const theDateUTC = DateTime.fromJSDate(theDateAsDate);
		let dateStr: string = '';
		if (timezone && timezone !== '') {
			const timeInTZ = theDateUTC.setZone(timezone);
			dateStr = timeInTZ.toFormat(format);
		} else {
			dateStr = theDateUTC.toFormat(format);
		}

		return (dateStr);
	};



}

export default DatetimeTools;
