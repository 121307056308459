import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import PopOverTools from 'appshared/popover-tools';

import { AuthService } from 'client/app/services/auth.service';
import { BuildsService } from '../builds.service';
import { ProductsService } from '../../products/products.service';
import { FilesService } from '../../files/files.service';
import { PlatformsService } from '../../platforms/platforms.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { MyBuildsService } from '../../my-builds/my-builds.service';
import { PackagesService } from '../../packages/packages.service';
import { OrganizationGroupsService } from '../../organizations/organization-groups.service';
import { UsersService } from '../../users/users.service';
import { DownloadLogsService } from '../../download-logs/download-logs.service';
import { AdminLogsService } from '../../admin-logs/admin-logs.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { ReportsService } from '../../reports/reports.service';

import { LogsTableComponent } from 'client/app/components/shared/logs-table/logs-table.component';
import { DownloadsTableComponent } from 'client/app/components/shared/downloads-table/downloads-table.component';
import { JournalsTableComponent } from 'client/app/components/shared/journals-table/journals-table.component';

import { PopupBoxComponent } from 'client/app/components/shared/popup-box/popup-box.component';

@Component({
	selector: 'app-build',
	templateUrl: './build.component.html',
	styleUrls: ['./build.component.scss']
})
export class BuildComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	popOverTools = PopOverTools;
	textTools = TextTools;
	miscTools = MiscTools;

	@ViewChild('logsTable1') logsTable1: LogsTableComponent = null;
	@ViewChild('downloadsTable1') downloadsTable1: DownloadsTableComponent = null;
	@ViewChild('journalsTable1') journalsTable1: JournalsTableComponent = null;
	@ViewChild(PopupBoxComponent) popupBox: PopupBoxComponent = null;

	loading = true;
	// showPostLoadElements = false;

	// 'standard' view stuff
	id: number;
	build: Models.Build;

	// other stuff
	product: Models.Product;
	organizations: Models.Organization[] = [];
	groups: Models.OrganizationGroup[] = [];
	packages: Models.Package[] = [];
	platforms: Models.Platform[] = [];

	releaseNotesFile: Models.File;
	platformFiles: Models.File[] = [];

	addedByUser: Models.User = null;
	editedByUser: Models.User = null;

	downloadLogs: Models.DownloadLog[] = [];
	adminLogs: Models.AdminLog[] = [];
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	canAddJournals = false;
	journals: Models.JournalEntry[] = [];

	canSendNotifications = false;

	canEdit = false;
	canDelete = false;

	showDownloadPageLink = false;

	numFilesWithFreeLinks = 0;

	zenCustomers: Models.ZenMasterCustomer[] = [];
	// fetchZenSitesUsingBuild

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private buildsService: BuildsService,
		private productsService: ProductsService,
		private platformsService: PlatformsService,
		private filesService: FilesService,
		private organizationsService: OrganizationsService,
		private usersService: UsersService,
		private organizationGroupsService: OrganizationGroupsService,
		private packagesService: PackagesService,
		private myBuildsService: MyBuildsService,
		private downloadLogsService: DownloadLogsService,
		private reportsService: ReportsService,
		private adminLogsService: AdminLogsService
	) {

		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.build = this.buildsService.getOne(this.id);
			if (!this.build || this.build == null || this.build.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
			} else {
				this.userSubscription = this.authService.user.subscribe((authUser) => {
					this.authUser = authUser;
					if (authUser) {
						this.canAddJournals = ValidationTools.checkAccess(this.authUser, 'add-journals');
						this.canEdit = ValidationTools.checkAccess(authUser, 'manage-builds');
						this.canDelete = this.canEdit && ValidationTools.checkAccess(authUser, 'delete-builds');
						this.canSendNotifications = ValidationTools.checkAccess(authUser, 'send-build-notifications');

						this.loadData();
					}
				});

				this.buildsService.refreshOne(this.id);
			}
		});
	}

	ngOnInit(): void {
		// downloads
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData() {
		this.loading = true;

		this.product = this.productsService.getOne(this.build.product_id);

		const dBuild = await this.myBuildsService.getOneBuild(this.id);
		this.showDownloadPageLink = dBuild != null && dBuild.build.id !== 0;

		const platformIDs = [];
		for (const pp of this.build.platform_files)
			platformIDs.push(pp.platform_id);

		const allPlatforms = this.platformsService.getAll();
		this.platforms = MiscTools.sortPlatforms(allPlatforms, platformIDs);

		this.releaseNotesFile = null;
		if (this.build.release_notes_file_id && +this.build.release_notes_file_id !== 0)
			this.releaseNotesFile = this.filesService.getOne(this.build.release_notes_file_id);

		this.platformFiles = [];
		for (const platform of this.platforms) {
			const idx = MiscTools.findIndexGeneric(this.build.platform_files, 'platform_id', platform.id);
			if (idx !== -1) {
				const file = this.filesService.getOne(this.build.platform_files[idx].file_id);
				if (file) {
					if (file.free_access_token && file.free_access_token !== '') this.numFilesWithFreeLinks++;
					file['platform_name'] = platform.name;
					this.platformFiles.push(file);
				} // if
			} // if
		} // for

		this.organizations = [];
		for (const orgID of this.build.org_ids) {
			const organization = this.organizationsService.getOne(orgID);
			this.organizations.push(organization);
		}
		this.organizations.sort((a, b) => (a.name > b.name) ? 1 : -1);

		this.groups = [];
		for (const grpID of this.build.group_ids) {
			const group = this.organizationGroupsService.getOne(grpID);
			this.groups.push(group);
		}
		this.groups.sort((a, b) => (a.name > b.name) ? 1 : -1);

		this.packages = [];
		for (const pkgID of this.build.package_ids) {
			const pkg = this.packagesService.getOne(pkgID);
			if (pkg) this.packages.push(pkg);
		}
		this.packages.sort((a, b) => (a.name > b.name) ? 1 : -1);

		this.addedByUser = null;
		if (this.build.added_by && this.build.added_by !== 0)
			this.addedByUser = this.usersService.getOne(this.build.added_by);

		this.editedByUser = null;
		if (this.build.edited_by && this.build.edited_by !== 0)
			this.editedByUser = this.usersService.getOne(this.build.edited_by);

		this.zenCustomers = await this.buildsService.fetchZenSitesUsingBuild(this.id);

		this.downloadLogs = await this.downloadLogsService.getLogsForBuild(this.id);
		if (this.downloadsTable1)
			this.downloadsTable1.updateContent(this.downloadLogs, 'cp-build-view-download-logs', { showUserInfo: true, linkUser: true });

		this.adminLogs = await this.adminLogsService.getLogs(['build'], this.id);
		if (this.logsTable1)
			this.logsTable1.updateContent(this.adminLogs, 'cp-build-view-admin-logs', { showUserInfo: true, showObjectInfo: false, linkObject: false, linkUser: true });

		this.journals = await this.adminLogsService.getJournals('build', this.id);
		if (this.journalsTable1)
			this.journalsTable1.updateContent(this.journals);

		this.loading = false;
	}

	// getFileIDForPlatform(platformID: number): number {
	// 	const idx = MiscTools.findIndexGeneric(this.build.platform_files, 'platform_id', platformID);
	// 	if (idx !== -1) {
	// 		return this.build.platform_files[idx].file_id;
	// 	}
	// 	return 0;
	// }

	// getFileSize(fileID: number): number {
	// 	const idx = MiscTools.findIndex(this.files, fileID);
	// 	if (idx !== -1) {
	// 		return this.files[idx].size;
	// 	}
	// 	return 0;
	// }

	// getFileName(fileID: number): string {
	// 	const idx = MiscTools.findIndex(this.files, fileID);
	// 	if (idx !== -1) {
	// 		return this.files[idx].name;
	// 	}
	// 	return '???';
	// }

	// getFileDownloads(fileID: number): number {
	// 	const idx = MiscTools.findIndex(this.files, fileID);
	// 	if (idx !== -1) {
	// 		return +this.files[idx].downloads;
	// 	}
	// 	return 0;
	// }

	// getFileLastDownload(fileID: number): Date {
	// 	const idx = MiscTools.findIndex(this.files, fileID);
	// 	if (idx !== -1) {
	// 		return this.files[idx].last_download;
	// 	}
	// 	return null;
	// }

	// freeToken(fileID: number): string {
	// 	const idx = MiscTools.findIndex(this.files, fileID);
	// 	if (idx !== -1) {
	// 		return this.files[idx].free_access_token;
	// 	}
	// 	return '';
	// }

	// isFreeFile(fileID: number): boolean {
	// 	const idx = MiscTools.findIndex(this.files, fileID);
	// 	if (idx !== -1) {
	// 		return this.files[idx].free_access_token && this.files[idx].free_access_token !== '';
	// 	}
	// 	return false;
	// }


	freeToken(theFile: Models.File): string {
		if (theFile)
			return theFile.free_access_token;
		return '';
	}

	isFreeFile(theFile: Models.File): boolean {
		if (theFile)
			return theFile.free_access_token && theFile.free_access_token !== '';
		return false;
	}

	getOrganizationName(orgID: number): string {
		const idx = MiscTools.findIndex(this.organizations, orgID);
		if (idx !== -1) {
			return this.organizations[idx].name;
		}
		return '???';
	}

	// ------------------------------------------------------------------------
	prepDelete() {
		this.popupBox.openPopup('confirm-text', 'delete', [], 'Delete Build',
			'If you delete this Build, it will be permanently deleted and cannot be recovered.',
			null,
			{ confirmButtonText: 'Delete Build', rejectButtonText: 'Cancel', confirmText: 'delete' });
	}

	// ------------------------------------------------------------------------
	async delete() {
		this.loading = true;
		const preDeleteOrgIds: number[] = this.build.org_ids.slice();
		const preDeletePkgIds: number[] = this.build.package_ids.slice();

		const result = await this.buildsService.deleteOne(this.build.id);
		if (result) {
			// update any orgs or pkgs
			for (const orgID of preDeleteOrgIds)
				this.organizationsService.refreshOne(orgID);

			for (const pkgID of preDeletePkgIds)
				this.packagesService.refreshOne(pkgID);

			this.uiAlertsService.addMsg('The build (' + this.build.version + ') has been deleted.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

			this.router.navigate([AppConstants.urls.builds]);
		} else {
			return false;
		}
	}

	async disable() {
		if (confirm('Are you sure you want to disable this Build?')) {
			const result = await this.buildsService.toggleEnabled(this.build.id);
			if (result) {
				this.build = result;
				this.loadData();
			} else {
				return false;
			}
		}
	}

	async enable() {
		if (confirm('Are you sure you want to enable this Build?')) {
			const result = await this.buildsService.toggleEnabled(this.build.id);
			if (result) {
				this.build = result;
				this.loadData();
			} else {
				return false;
			}
		}
	}

	async openDownload(id: number, dltype: string = '', refid: number = 0) {
		const dlObj = await this.myBuildsService.getDownloadLink(id, dltype, refid);
		if (dlObj)
			window.open(dlObj.url, '_blank');
	}

	getUsersName(id: number): string {
		return this.usersService.getUsersName(id);
	}

	// *********************************************************
	async runDownloadsReport() {
		await this.reportsService.runReport('AllDownloadsReport', 'objType=build&objId=' + this.id);
	}

	// ------------------------------------------------------------------------
	// Back and Forth with components
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			popupCallBack: async (callBack: string, args: any) => {
				if (callBack === 'delete' && args.length === 0)
					this.delete();
				else if (callBack === 'XXXXX' && args.length === 1)
					console.log('args=' + args);

				else
					this.uiAlertsService.addMsg('Unknown callBack (' + callBack + ') or bad number of arguments (' + args.length + ').', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		}
	} // getParentMethod
}
