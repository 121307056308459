import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';

import { OrganizationsResolverService } from '../organizations/organizations-resolver.service';

import { ReportsListComponent } from './reports-list/reports-list.component';

const routes: Routes = [
	{
		path: '', component: ReportsListComponent, canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole }, resolve: [OrganizationsResolverService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ReportsRoutingModule { }
