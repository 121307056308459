// tslint:disable: no-string-literal

// various utility functions used by both front and back end...
// import { HttpErrorResponse } from '@angular/common/http';
// import { UAParser } from 'ua-parser-js';

import * as Models from './shared-models';
import AppConstants from './app-constants';

class MiscTools {
	// ************************************************************************************************
	static deepClone = (object: any): any => {
		return JSON.parse(JSON.stringify(object));
	};

	// ************************************************************************************************
	static handleBackendError = (errorResponse: any) => {
		let errorMessage = 'An unknown error has occured.';
		if (!!errorResponse.error && !!errorResponse.error.error) {
			errorMessage = errorResponse.error.error;
		}
		// console.log('in handle error ' + errorMessage);
		throw new Error(errorMessage);
	};

	// ************************************************************************************************
	// if the date is in the past, returns a positive number
	// if the date is in the future, a negative
	static daysSince = (someDate: any, returnInteger = true): number => {
		const now = new Date();
		return MiscTools.diffDays(now, someDate, returnInteger);
	};

	// ************************************************************************************************
	static hasExpired = (someDate: any): boolean => {
		const daysSinceExpired = MiscTools.daysSince(someDate, false);
		return (daysSinceExpired && daysSinceExpired > 0);
	};

	// // ************************************************************************************************
	// static niceExpireText = (expDate: any): string => {
	// 	const daysToExpire = MiscTools.daysSince(expDate) * -1;
	// 	if (daysToExpire >= 0)
	// 		return ('Expires ' + MiscTools.niceDaysText(expDate));
	// 	else
	// 		return ('Expired ' + MiscTools.niceDaysText(expDate));
	// };

	// // ************************************************************************************************
	// static niceDaysText = (theDate: any): string => {
	// 	const daysToExpire = MiscTools.daysSince(theDate) * -1;
	// 	if (daysToExpire === 0) {
	// 		return ('today');
	// 	} else if (daysToExpire > 0) { // hasn't expired
	// 		if (daysToExpire === 1) {
	// 			return ('tomorrow');
	// 		} else {
	// 			return (daysToExpire.toLocaleString() + ' days from now');
	// 		}
	// 	} else {
	// 		if (daysToExpire === -1)
	// 			return ('yesterday');
	// 		else
	// 			return ((daysToExpire * -1).toLocaleString() + ' days ago');
	// 	} // if
	// };

	// ************************************************************************************************
	static diffDays = (firstDate: any, secondDate: any, returnInteger = true): number => {
		if (firstDate && secondDate) {
			const theDate1 = new Date(firstDate);
			const theDate2 = new Date(secondDate);

			if (isNaN(theDate1.getTime()) && isNaN(theDate2.getTime())) return 0;
			if (isNaN(theDate1.getTime()) || isNaN(theDate2.getTime())) return null;
			if (returnInteger)
				return (Math.floor((theDate1.getTime() - theDate2.getTime()) / 1000 / 60 / 60 / 24));
			else
				return ((theDate1.getTime() - theDate2.getTime()) / 1000 / 60 / 60 / 24);

		} else if (!firstDate && !secondDate) {
			return 0;
		} // if
		return null;
	};

	// ************************************************************************************************
	static dateBackFromNow = (amount: number, unit = 'day'): Date => {
		let theDate: Date;
		if (unit === 'day') {
			theDate = new Date((new Date()).getTime() - (amount * 1000 * 60 * 60 * 24));
		} else if (unit === 'year') {
			theDate = new Date();
			theDate.setUTCFullYear(theDate.getUTCFullYear() - amount);
		} // if
		return (theDate);

		// TBD need to test this
		// return dateIntervalAdd(new Date(), amount * -1, unit);
	};

	// ************************************************************************************************
	static dateFromNow = (amount: number, unit = 'day'): Date => {
		return MiscTools.dateIntervalAdd(new Date(), amount, unit);
	};

	// ************************************************************************************************
	static dateIntervalAdd = (startDate: Date, amount: number, unit = 'day'): Date => {
		let workDate = new Date(startDate);

		if (!startDate || isNaN(workDate.getTime()))
			workDate = new Date();

		if (unit === 'minute' || unit === 'minutes') {
			workDate = new Date(workDate.getTime() + (+amount * 1000 * 60));
		} else if (unit === 'hour' || unit === 'hours') {
			workDate = new Date(workDate.getTime() + (+amount * 1000 * 60 * 60));
		} else if (unit === 'day' || unit === 'days') {
			workDate = new Date(workDate.getTime() + (+amount * 1000 * 60 * 60 * 24));
		} else if (unit === 'week' || unit === 'weeks') {
			workDate = new Date(workDate.getTime() + (+amount * 7 * 1000 * 60 * 60 * 24));
		} else if (unit === 'month' || unit === 'months') {
			let newMonth = workDate.getUTCMonth() + +amount;
			// console.log('workDate = ' + workDate);
			// console.log('curMonth = ' + workDate.getUTCMonth());
			// console.log('newMonth = ' + newMonth);
			if (newMonth >= 12) {
				const numYears = Math.floor(newMonth / 12);
				newMonth = newMonth % 12;
				// console.log('numYears = ' + numYears);
				// console.log('newMonth = ' + newMonth);
				workDate.setUTCFullYear(workDate.getFullYear() + +numYears);
				workDate.setUTCMonth(newMonth);
			} else {
				workDate.setUTCMonth(newMonth);
			}
		} else if (unit === 'year' || unit === 'years') {
			workDate.setUTCFullYear(workDate.getUTCFullYear() + +amount);
		} // if
		// console.log('workDate = ' + workDate);

		return (workDate);
	};

	// ************************************************************************************************
	static formattedTime = (seconds: number, onlyEnough = false): string => {
		let fmtedTime = '';

		let runTimeSec: number = seconds;
		let runTimeMin: number = 0;
		let runTimeHrs: number = 0;

		if (runTimeSec >= 60) runTimeMin = Math.floor(runTimeSec / 60);
		if (runTimeMin >= 60) runTimeHrs = Math.floor(runTimeMin / 60);

		runTimeMin = runTimeMin - (runTimeHrs * 60);
		runTimeSec = runTimeSec - (runTimeHrs * 60 * 60) - (runTimeMin * 60);

		if (onlyEnough) {
			if (runTimeHrs > 0)
				fmtedTime = runTimeHrs.toString().padStart(2, '0') + ":";

			if (runTimeHrs > 0 || runTimeMin > 0)
				fmtedTime += runTimeMin.toString().padStart(2, '0') + ":";
			if (fmtedTime !== '')
				fmtedTime += runTimeSec.toString().padStart(2, '0');
			else
				fmtedTime += runTimeSec.toString();
		} else {
			fmtedTime += runTimeHrs.toString().padStart(2, '0') + ":"
				+ runTimeMin.toString().padStart(2, '0') + ":"
				+ runTimeSec.toString().padStart(2, '0');
		}

		return fmtedTime;
	};

	// ************************************************************************************************
	static daysInMonth = (month: number, year: number): number => {
		if (month < 1 || month > 12) throw new Error('Invalid month - must be between 1 and 12');
		if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
			return 31;
		} else if ([4, 6, 9, 11].includes(month)) {
			return 30;
		} else { // february
			if (year % 4 !== 0)
				return 28;
			else if (year % 100 !== 0)
				return 29;
			else if (year % 400 !== 0)
				return 28;
			else
				return 29;
		} // if
	};

	// ************************************************************************************************
	static sortPlatforms = (platforms: Models.Platform[], platformIDs: number[]): Models.Platform[] => {
		if (!platforms || !platformIDs || platforms.length === 0 || platformIDs.length === 0)
			return [];

		const subList: Models.Platform[] = [];
		for (const pl of platforms)
			if (platformIDs.includes(pl.id))
				subList.push(pl);

		subList.sort((a, b) => (a.name > b.name) ? 1 : -1);
		return subList;
	};

	// ************************************************************************************************
	static getPlatformIcon = (platform: string): string[] => {
		const matchName = platform.toLowerCase();
		if (matchName.includes('windows'))
			return ['fab', 'windows'];
		else if (matchName.includes('linux'))
			return ['fab', 'linux'];
		else if (matchName.includes('docker'))
			return ['fab', 'docker'];
		else if (matchName.includes('raspberry'))
			return ['fab', 'raspberry-pi'];
		else if (matchName.includes('mac'))
			return ['fab', 'apple'];
		else if (matchName.includes('docs'))
			return ['fas', 'books'];
		else if (matchName.includes('web'))
			return ['fas', 'cloud-download-alt'];
		else if (matchName.includes('graviton2'))
			return ['fab', 'aws'];
		else
			return ['fas', 'download'];
	};

	// ************************************************************************************************
	static delay = async (ms: number) => {
		return new Promise(resolve => setTimeout(resolve, ms));

		// await new Promise(resolve => setTimeout(() => resolve(), ms))
		// 	.then(() => console.log('fired'));
	};

	// ************************************************************************************************
	static getRandomInt = () => {
		return Math.floor(Math.random() * 1_000_000_000_000);
	};

	// ************************************************************************************************
	static getRandomMaxInt = (max: number): number => {
		return Math.floor(Math.random() * Math.floor(max));
	};

	// ************************************************************************************************
	static findIndexGeneric = (arr: any[], field: string, lookup: any): number => {
		for (const idx in arr) {
			if (!!arr[idx][field] && arr[idx][field] === lookup) {
				return +idx;
			}
		}
		return -1;
	};

	// ************************************************************************************************
	static findIndex = (arr: any[], id: number): number => {
		return (MiscTools.findIndexGeneric(arr, 'id', id));
	};

	// ************************************************************************************************
	static pickItem = (arr: any[], field: string, lookup: any): any => {
		const idx = MiscTools.findIndexGeneric(arr, field, lookup);
		if (idx !== -1)
			return arr[idx];
		else
			return null;
	};

	// ************************************************************************************************
	static pickItemById = (arr: any[], id: number): any => {
		const idx = MiscTools.findIndexGeneric(arr, 'id', id);
		if (idx !== -1)
			return arr[idx];
		else
			return null;
	};

	// ************************************************************************************************
	static findIndexGenericDouble = (arr: any[], field1: string, lookup1: any, field2: string, lookup2: any): number => {
		for (const idx in arr) {
			if (!!arr[idx][field1] && arr[idx][field1] === lookup1
				&& !!arr[idx][field2] && arr[idx][field2] === lookup2) {
				return +idx;
			}
		}
		return -1;
	};

	// ************************************************************************************************
	static findIndexGenericTriple = (arr: any[], field1: string, lookup1: any, field2: string, lookup2: any, field3: string, lookup3: any): number => {
		for (const idx in arr) {
			if (!!arr[idx][field1] && arr[idx][field1] === lookup1
				&& !!arr[idx][field2] && arr[idx][field2] === lookup2
				&& !!arr[idx][field3] && arr[idx][field3] === lookup3) {
				return +idx;
			}
		}
		return -1;
	};

	// ************************************************************************************************
	static removeDuplicates = (arr: any[]) => {
		const uniqueArr: any[] = [];
		for (const ele of arr)
			if (!uniqueArr.includes(ele))
				uniqueArr.push(ele);

		return uniqueArr;
	};


	// ************************************************************************************************
	// moves item in array in place
	static moveItemInArray = (arr: any[], oldIndex: number, newIndex: number) => {
		if (newIndex >= arr.length) {
			let k = newIndex - arr.length + 1;
			while (k--) {
				arr.push(undefined);
			}
		}
		arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
	};

	// ************************************************************************************************
	// diffStringArrays
	static diffStringArrays = (oldArr: string[], newArr: string[]) => {
		const additions: string[] = [];
		const deletions: string[] = [];

		if (!oldArr) oldArr = [];
		if (!newArr) newArr = [];

		for (let i = 0; i < oldArr.length; i++)
			if (!newArr.includes(oldArr[i]))
				deletions.push(oldArr[i]);

		for (let i = 0; i < newArr.length; i++)
			if (!oldArr.includes(newArr[i]))
				additions.push(newArr[i]);

		let change = '';
		if (additions.length > 0)
			change = '\"' + additions.join('\", \"') + '\" added';

		if (additions.length > 0 && deletions.length > 0)
			change += '; ';

		if (deletions.length > 0)
			change += '\"' + deletions.join('\", \"') + '\" removed';

		if (change !== '')
			return change;
		else
			return null;
	};

	// ************************************************************************************************
	// additionsToNumberArrays
	static additionsToNumberArray = (oldArr: number[], newArr: number[]) => {
		const additions: number[] = [];

		if (!oldArr) oldArr = [];
		if (!newArr) newArr = [];

		for (let i = 0; i < newArr.length; i++)
			if (!oldArr.includes(newArr[i]))
				additions.push(newArr[i]);

		return additions;
	};

	// ************************************************************************************************
	// deletionsFromNumberArray
	static deletionsFromNumberArray = (oldArr: number[], newArr: number[]) => {
		const deletions: number[] = [];

		if (!oldArr) oldArr = [];
		if (!newArr) newArr = [];

		for (let i = 0; i < oldArr.length; i++)
			if (!newArr.includes(oldArr[i]))
				deletions.push(oldArr[i]);

		return deletions;
	};

	// ************************************************************************************************
	// assumes an array of objects with value and label props
	static fetchLabel = (options: any[], value: string, matchProperty: string = 'value', labelProperty: string = 'label'): string => {
		for (const option of options)
			if (option[matchProperty] && option[matchProperty] === value && option[labelProperty])
				return option[labelProperty];
		return value;
	};

	// ************************************************************************************************
	static getRoute = (objType: string, action: string, id: number) => {
		if (action === 'delete' || action === 'purge' || id === 0)
			return [];

		let route: string[] = null;
		switch (objType) {
			case 'activation':
				route = ['/' + AppConstants.urls.licensing, 'activation', id + ''];
				break;
			case 'authentication':
				route = ['/' + AppConstants.urls.users, id + ''];
				break;
			case 'partnership':
				route = ['/' + AppConstants.urls.partneradmin, id + ''];
				break;
			case 'system-message':
				route = ['/' + AppConstants.urls.systemmessages, id + ''];
				break;
			case 'zenmaster':
				route = ['/' + AppConstants.urls.zencustomers, id + ''];
				break;
			case 'license-product':
				route = ['/' + AppConstants.urls.licensingadmin, 'products', id + ''];
				break;
			case 'license-property':
				route = ['/' + AppConstants.urls.licensingadmin, 'properties', id + ''];
				break;
			case 'key-template':
				route = ['/' + AppConstants.urls.licensingadmin, 'templates', id + ''];
				break;
			case 'protocol-set':
				route = ['/' + AppConstants.urls.licensingadmin, 'protocol-sets', id + ''];
				break;
			case 'user-group':
				route = ['/' + AppConstants.urls.usergroups, id + ''];
				break;
			case 'organization-group':
				route = ['/' + AppConstants.urls.organizationgroups, id + ''];
				break;
			case 'quiz':
				route = ['/' + AppConstants.urls.quizzes, id + ''];
				break;

			case 'user':
			case 'organization':
			case 'platform':
			case 'product':
			case 'package':
			case 'build':
			case 'document':
			case 'file':
				route = ['/' + AppConstants.urls[objType + 's'], id + ''];
				break;
		}

		return route;
	};
}

export default MiscTools;
