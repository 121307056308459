import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share, map, tap } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

@Injectable({
	providedIn: 'root'
})
export class BackgroundTasksService {

	constructor(private http: HttpClient) {
	}

	// call back-end to get last X logs
	async getLogs(startDate: Date = null, endDate: Date = null) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.backgroundtasks + '?a=b';
			if (startDate && startDate !== null) url += '&startDate=' + encodeURIComponent(startDate + '');
			if (endDate && endDate !== null) url += '&endDate=' + encodeURIComponent(endDate + '');
			const result = await this.http.get<Models.BackgroundTask[]>(url).toPromise();
			const arr: Models.BackgroundTask[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}
}
