// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';


// const routes: Routes = [];

// @NgModule({
//   imports: [RouterModule.forChild(routes)],
//   exports: [RouterModule]
// })
// export class PackagesRoutingModule { }


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';

import { PackageComponent } from './package/package.component';
import { PackageListComponent } from './package-list/package-list.component';
import { PackageFormComponent } from './package-form/package-form.component';
import { PackageKeyTemplateFormComponent } from './package-key-template-form/package-key-template-form.component';

import { PackagesResolverService } from './packages-resolver.service';
import { PlatformsResolverService } from '../platforms/platforms-resolver.service';
import { ProductsResolverService } from '../products/products-resolver.service';
import { UsersResolverService } from '../users/users-resolver.service';
import { BuildsResolverService } from '../builds/builds-resolver.service';
import { DocumentsResolverService } from '../documents/documents-resolver.service';

const routes: Routes = [
	{
		path: '',
		component: PackageListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [PackagesResolverService]
	}, {
		path: 'new',
		component: PackageFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [PackagesResolverService, ProductsResolverService, PlatformsResolverService]
	}, {
		path: ':id',
		component: PackageComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [PackagesResolverService, PlatformsResolverService, ProductsResolverService,
			UsersResolverService, BuildsResolverService, DocumentsResolverService]
	}, {
		path: ':id/edit',
		component: PackageFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [PackagesResolverService, ProductsResolverService, PlatformsResolverService]
	}, {
		path: ':id/key-templates/new',
		component: PackageKeyTemplateFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [PackagesResolverService]
	}, {
		path: ':id/key-templates/:id2',
		component: PackageKeyTemplateFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [PackagesResolverService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PackagesRoutingModule { }
