<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.users" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" (click)="onCancel()">Users</a>
			</li>
			<li class="breadcrumb-item">
				{{ user.name }}
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				Access
			</li>
		</ol>
	</nav>
</div>

<form *ngIf="theForm" [formGroup]="theForm">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div *ngFor="let accessControlTagGrouping of ac.accessControlTagGroupings">
			{{ accessControlTagGrouping.grouping }}

			<div *ngFor="let tag of accessControlTagGrouping.tags" class="form-group form-check mb-2 mt-0 ms-4">
				<input type="checkbox" class="form-check-input" [id]="tag" [formControlName]="tag" />
				<label class="form-check-label ps-1 pe-1" [for]="tag" [ngClass]="{
						'cp-selected-secondary': !isChecked(tag) && (user.groupAccessTags.includes(tag) || user.roleAccessTags.includes(tag)),
						'cp-selected-primary': isChecked(tag)
					}">
					{{ ac.accessControlTags[tag] }}
				</label>

				<fa-icon *ngIf="user.groupAccessTags.includes(tag)" [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
					ngbTooltip="This user has this access based on a group that they are a member of">
				</fa-icon>

				<fa-icon *ngIf="user.roleAccessTags.includes(tag)" [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
					ngbTooltip="This user has this access based on their user role"></fa-icon>
			</div>
		</div>

		<!-- <div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>
		</div> -->
	</div>

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>
		</div>
	</div>

</form>