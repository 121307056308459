import { Params, ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject, interval, Subject } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';

import { ProductsService } from '../products.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-product-sort',
	templateUrl: './product-sort.component.html',
	styleUrls: ['./product-sort.component.scss']
})
export class ProductSortComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	textTools = TextTools;

	// other stuff
	private userSubscription: Subscription;

	// 'standard' list stuff
	products: Models.Product[];
	numChanges: number = 0;
	loading = true;
	saving = false;
	errors: string[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private productsService: ProductsService,
		private authService: AuthService) { }

	ngOnInit(): void {
		this.loading = true;

		this.loadData();
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData() {
		this.loading = true;

		this.products = this.productsService.getAll();
		this.products.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1);

		this.loading = false;
	}

	drop(event: CdkDragDrop<Models.LicenseProperty[]>) {
		if (event.previousIndex !== event.currentIndex) {
			MiscTools.moveItemInArray(this.products, event.previousIndex, event.currentIndex);
			this.numChanges++;
		} // if
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		try {
			let doRefresh = false;
			if (this.numChanges > 0) {
				const ids: number[] = [];
				for (const product of this.products)
					ids.push(product.id);

				await this.productsService.setSortOrder(ids, false);

				doRefresh = true;
			} // if

			if (doRefresh) this.productsService.refreshAll();
			this.uiAlertsService.addMsg('The sort order has been updated.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			this.onCancel();
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}


}
