import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { tap, catchError, take, map } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';

import { AuthService } from 'client/app/services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class MySettingsService {
	private myUser: Models.User;
	private orgUsers: Models.User[];
	private productIDs: number[];

	constructor(
		private http: HttpClient,
		private authService: AuthService) { }

	wipe() {
		this.myUser = null;
		this.orgUsers = null;
		this.productIDs = null;
	}

	async getUser(forceRefresh = false) {
		if (!this.myUser || forceRefresh)
			this.myUser = await this.fetchUser();
		return this.myUser;
	}

	async getOrgUsers(forceRefresh = false) {
		if (!this.orgUsers || forceRefresh)
			this.orgUsers = await this.fetchOrgUsers();
		return this.orgUsers.slice();
	}

	async fetchUser() {
		try {
			const result = await this.http.get<Models.User>(AppConstants.apiUrl + AppConstants.apiUrls.mysettings).toPromise();
			const obj: Models.User = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchOrgUsers() {
		try {
			const result = await this.http.get<Models.User[]>(AppConstants.apiUrl + AppConstants.apiUrls.mysettings
				+ '/org-users').toPromise();
			const arr: Models.User[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchAvailablePackages() {
		try {
			const result = await this.http.get<Models.Package[]>(AppConstants.apiUrl + AppConstants.apiUrls.mysettings
				+ '/available-packages').toPromise();
			const arr: Models.Package[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchMyPackages() {
		try {
			const result = await this.http.get<Models.Package[]>(AppConstants.apiUrl + AppConstants.apiUrls.mysettings
				+ '/user-packages').toPromise();
			const arr: Models.Package[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// update a user's settings
	async updateSettings(name: string, title: string, settings: Models.UserSetting[]) {
		try {
			const result = await this.http.put<Models.User>(AppConstants.apiUrl + AppConstants.apiUrls.mysettings
				+ '/info', { name, title, settings }).toPromise();
			const updatedUser: Models.User = result;
			const newAuthUser = new Models.AuthUser(updatedUser.id, updatedUser.email, updatedUser.name, updatedUser.role, '');
			for (const key of AppConstants.authObjectSettings) {
				const val = TextTools.getUserPropValue(updatedUser, key);
				if (val && val !== '')
					newAuthUser.settings.push(new Models.UserSetting(0, key, val));
			}
			newAuthUser.mergedAccessTags = updatedUser.mergedAccessTags;

			this.authService.setAuthUser(newAuthUser);
			this.myUser = updatedUser;
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// update a user's single settings
	async updateSetting(key: string, value: string) {
		try {
			const result = await this.http.put<Models.User>(AppConstants.apiUrl + AppConstants.apiUrls.mysettings
				+ '/setting', { key, value }).toPromise();
			const updatedUser: Models.User = result;
			const newAuthUser = new Models.AuthUser(updatedUser.id, updatedUser.email, updatedUser.name, updatedUser.role, '');
			for (const key of AppConstants.authObjectSettings) {
				const val = TextTools.getUserPropValue(updatedUser, key);
				if (val && val !== '')
					newAuthUser.settings.push(new Models.UserSetting(0, key, val));
			}
			newAuthUser.mergedAccessTags = updatedUser.mergedAccessTags;

			this.authService.setAuthUser(newAuthUser);
			this.myUser = updatedUser;
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async stageEmail(email: string) {
		try {
			const result = await this.http.put<Models.User>(AppConstants.apiUrl + AppConstants.apiUrls.mysettings
				+ '/email', { email }).toPromise();
			// const updatedUser: Models.User = result;
			// const newAuthUser = new Models.AuthUser(updatedUser.id, updatedUser.email, updatedUser.name, updatedUser.role, '');
			// this.authService.setAuthUser(newAuthUser);
			// this.user = updatedUser;
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async setPassword(currentPassword: string, newPassword: string, newPassword2: string) {
		try {
			const result = await this.http.put(AppConstants.apiUrl + AppConstants.apiUrls.mysettings
				+ '/password', { currentpassword: currentPassword, password: newPassword, password2: newPassword2 }).toPromise();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getProductIDs(forceRefresh = false) {
		if (!this.productIDs || forceRefresh)
			this.productIDs = await this.fetchProductIDs();
		return this.productIDs.slice();

	}

	async fetchProductIDs() {
		try {
			const result = await this.http.get<number[]>(AppConstants.apiUrl + AppConstants.apiUrls.mysettings
				+ '/product-ids').toPromise();
			const arr: number[] = result;
			this.productIDs = arr;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}


}
