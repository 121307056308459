<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.mykeys" class="cp-user-nav-item me-1" size="sm"></fa-icon>
				<a href="javascript:void(0)" (click)="onCancel()">License Keys</a>
			</li>
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.mykeys" class="cp-user-nav-item me-1" size="sm"></fa-icon>
				<a href="javascript:void(0)" (click)="onCancel()">{{ key }}</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">Set Label(s) for Host ID(s)</li>
		</ol>
	</nav>
</div>

<div *ngIf="loading || saving" style="text-align: center;">
	<app-loading-spinner></app-loading-spinner>
</div>

<form *ngIf="theForm" [formGroup]="theForm">
	<div class="cp-general-width-limit">
		<div class="form-group">
			<label for="pairsList">Host ID(s) and Label(s)*</label>
			<textarea id="pairsList" formControlName="pairsList" class="form-control" rows="8"></textarea>
		</div>

		<div class="form-group">
			Enter host IDs and labels (separated by a space) in the box above. One pair per line.
		</div>

		<!-- <div class="form-group mb-2 small">* Required</div>

		<div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Set Labels
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div> -->
	</div>
</form>

<div class="cp-form-padding"></div>

<div *ngIf="!loading && theForm" class="cp-form-buttons">
	<div class="cp-form-buttons-inner">
		<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
			<fa-icon [icon]="['fas', 'check']"></fa-icon> Set Labels
		</button>
		<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
			<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
		</button>

		<span class="gj-75 ps-2">* Required</span>
	</div>
</div>